import React, { Component } from 'react';
import axios,{post} from 'axios';
import config from "../../config";
import $ from 'jquery'; 

export default class MultipleImageUpload extends Component {

    constructor(props) {
        super(props)
        this.state = {
            file: '',
            fileName:'',
            fileDesc:'',
            errorName:''
        }
        this.fileObj = '';
        this.fileArray = [];

        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }
    getImages=()=>{
        let config1 = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = config.API_URL+'uploadfile/get_images.php';
        axios.get(url, config1)
        .then(response => {
            var fileArray =response.data;
            this.setState({fileArray});
        })
        //this.setState({fileArray});
    }
    handleChange=(e)=>{
        let name = e.target.name;
        this.setState({[e.target.name]:e.target.value})
        if(name == 'fileName' && e.target.value !==''){
            this.setState({errorName:''});
        }
    }
    uploadMultipleFiles(e) {
        var file  = e.target.files;
        this.setState({ file:file, selectedFile: e.target.files });
    }

    uploadFiles(e) {
        e.preventDefault()
        if(this.state.fileName !== ''){
            var file = $('.file').prop('files')[0];
			let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
            //let url1 = 'https://soogap.net/api/uploadfile/file_upload.php';
            let url1 = config.API_URL+'uploadfile/file_upload.php';
            
            var form_data = new FormData();
            form_data.append('name', this.state.fileName);
            form_data.append('description', this.state.fileDesc);
            form_data.append('file', file);
            post(url1, form_data, con)
            .then(response => {
                $('#addImagesModal').modal('hide');
                this.getImages();
                this.setState({file:'',selectedFile:''});
            })
        }else{
            this.setState({errorName:'Please enter a name'});
        }
    }
    showImageDesc(id){
		$('.image-box').removeClass('activeDiv');
        let html = $('#image'+id).html();
		$('#div-'+id).addClass('activeDiv');
		$('#ImageText').html(html);
    }
    render() {
        const {errorName, fileArray} =this.state;
        return (
            <div>
                <form>
                    <div className="row">
                        {(fileArray || []).map(url => (
                            <div className="col-md-4 image-box" id={'div-'+url.id}>
                                <img src={url.path} alt="..." style={{cursor: 'pointer'}} width="259px" height="auto" onClick={()=>this.showImageDesc(url.id)}/>
                                <div id={'image'+url.id} style={{display:'none'}}>
                                    <p>Name : {url.name}</p>
                                    <p>date : {url.created_at}</p>
                                    <p>Description : {url.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </form >
                <div className="modal" id="addImagesModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Upload Images</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                                <div className="form-group">
                                    <input type="file" className="form-control file" name="file" onChange={this.uploadMultipleFiles} />
                                </div>
                                <div className="form-group">
                                    <label>Name <span className="required">*</span></label>
                                    <input type="text" name ="fileName" className="form-control" onChange={this.handleChange} />
                                    {errorName ? <p className="alert alert-danger"> { this.state.errorName }</p> : null}
                                </div>
                                <div className="form-group">
                                    <label>Discription (limited to 150 characters)</label>
                                    <textarea className="form-control" name ="fileDesc" value={this.state.fileDesc} maxLength={150} onChange={this.handleChange}></textarea>
                                </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-block" onClick={this.uploadFiles}>Upload</button>
                          </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}