import React,{ Component } from 'react';
import { Form,Button } from 'react-bootstrap';
import config from "../../config";
import { gapi } from 'gapi-script';
//import { handleClearTempSave } from "../../Helpers/Common";
class TopFilter extends Component{
	constructor(props) {
        super(props);
        this.state ={
	        assoType:[],
	        assoDeviceType:[],
	        assoVisitType:[],
        }
	}
	handleHeaderFilter=(event)=>{
		let name = event.target.name;
		this.setState({[name]:event.target.value})
		this.props.handleHeaderFilter(event)
	}
	clearAllFilter=()=>{
		this.setState({assoTypeName:'',assoDeviceTypeName:'',assoVisitTypeName:''})
		this.props.clearAllFilter();
	}
	
	render(){
		const {assoType,assoDeviceType,assoVisitType,selectedSheet,allData,sheetData,moduleName, handleClearTempSave} = this.props;
		//console.log('moduleName->',moduleName);
		return (
			<div className="row">
				<div className="col-md-3">
					<div className="form-group">
                        <label> Type </label>
                        <Form.Control as="select" name="assoTypeName" value={this.state.assoTypeName} onChange={this.handleHeaderFilter}>
                            <option value=""> Please Select</option>
                            {assoType !== '' ?
                            	assoType.map((type, i) => (
									 <option key={i}>{type}</option>
								))
								:null
							}
                        </Form.Control>
                    </div>					
				</div>
				<div className="col-md-3">
					<div className="form-group">
                        <label> Device Type </label>
                        <Form.Control as="select" name="assoDeviceTypeName" value={this.state.assoDeviceTypeName} onChange={this.handleHeaderFilter}>
                            <option value=""> Please Select</option>
                            {assoDeviceType !== '' ?
                            	assoDeviceType.map((type, i) => (
									 <option key={i}>{type}</option>
								))
								:null
							}
                        </Form.Control>
                    </div>					
				</div>
				<div className="col-md-3">
					<div className="form-group">
                        <label> Visit Type</label>
                        <Form.Control as="select" name ="assoVisitTypeName" value={this.state.assoVisitTypeName} onChange={this.handleHeaderFilter}>
                            <option value=""> Please Select</option>
                            {assoVisitType !== '' ?
                            	assoVisitType.map((type, i) => (
									 <option key={i}>{type}</option>
								))
								:null
							}
                        </Form.Control>
                    </div>					
				</div>
				<div className="col-md-3">
					<div className="form-group">
						<Button type="button" onClick={this.clearAllFilter}>Clear Filters & Show All</Button>
						{selectedSheet && sheetData && (moduleName == 'form_builder' || moduleName == 'format_forms' || moduleName == 'test_forms' || moduleName == 'custom_config') ?
							<button className="btn btn-primary clear-forms" type="button" onClick={()=>handleClearTempSave(selectedSheet,allData)}>Clear Form without Save</button>
						:null}
					</div>					
				</div>
			</div>
		)
	}
}
export default TopFilter;