import React, { Component, PropTypes } from 'react'
import 'bootstrap';
import $ from 'jquery';
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { generatehtmlfrom,handleAuthResult,saveConfigFile } from "../../Helpers/Common";
import { tagHtml,noteHtml,knoteHtml,noteModal,knoteModal,showImageModal,attentHTML,includeModal,addUsersHTML,addUsersModal } from "../../component/ReviewApprove/Modal";
export default class PdfData extends Component {
    constructor(props){
        super(props)
    }
    classTypes(comment){
		let type = this.props.type;
        let notInclude = '';
        let parentHeader = '';
        
        if(comment[36] == 'Deleted' || comment[5].toLowerCase() == 'boxbegin' || comment[5].toLowerCase() == 'boxend' || comment[5].toLowerCase() == 'link'){
            notInclude = 'not-include';
        }
        if(comment[5].toLowerCase() == 'parent header'){
            parentHeader = ' parent-header ';
        }
		if(comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3' && comment[5].toLowerCase() != 'title' && comment[5].toLowerCase() != 'text'){
            parentHeader = ' question ';
        }
		
		if(comment[5].toLowerCase() == 'title'){
			parentHeader = ' hide-title ';
		}
		
        let className = type+'_'+comment[0]+' childDiv ' + notInclude + parentHeader;
        
		setTimeout(function(){
			
			if($('.'+type+'_'+comment[0]).find('.question').length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
				$('.'+type+'_'+comment[0]).addClass("not-include");
				$('.'+type+'_'+comment[0]).remove();
			}else{
				$('.'+type+'_'+comment[0]).removeClass("not-include");
			}
			
			if(comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3' && comment[5].toLowerCase() != 'title' && comment[5].toLowerCase() != 'text'){
				$('.'+type+'_'+comment[0]).prev().removeClass('hide-title');
			}
			
		}, 2000);
		
		setTimeout(function(){
			$( ".hide-title" ).remove();
		}, 5000);

        return className;
    }
    
    headerStyle(comment){
        let marginLeft = '';
        switch (comment[5].toLowerCase()) {
            case 'parent header':
                marginLeft = '0px';
                break;
            case 'child header 1':
                marginLeft = '20px';
                break;
            case 'child header 2':
                marginLeft = '30px';
                break;
            case 'child header 3':
                marginLeft = '40px';
                break;
            default:
                marginLeft = '50px';                   
        }
        return marginLeft;
    }
	
    subQuestions = (value,parent,id) => {
        let combVis1   = id+'-'+value.toLowerCase();
        let newString  = combVis1.replace(/[^A-Z0-9]+/ig, "");
        var divClass  = $("."+newString).attr('class');
        let subQustionData  = this.props.allData;
        let ids = [];
        $("."+id).each(function( index) {
            var ids= $(this).attr('id');
            $('.'+ids).addClass(id);
        });
        $("."+id).collapse('hide');
		
        subQustionData.map((answers, i) => {
            let combVis   = answers[7]+'-'+answers[8].toLowerCase();
            let visibility1= combVis.replace(/[^A-Z0-9]+/ig, "");
            if(visibility1 == newString) {
                $("."+newString).collapse('show');
            }
        })
    }
    
    
    getUsername(type){
		let allData = this.props.allData;
		let kioskType = [];
		let username = '';
		if(allData){
			kioskType = allData[0][119].split(',');
			kioskType.map(function(kt,i) {
				kt = kt.split(':');
				if(kt[1] == type){
					username = kt[0];
				}
			})
		}
		return username;
    }
	
    getData(comment){
        var questions ='';
        var answerType = '';
        var options = '';
        var id = comment[1]
        var location = comment[9];
        var parent = comment[2]
        questions = comment[4];
        var visibility1 = comment[8];
        var visibility = comment[7];
        var action  = comment[17];
        if(this.props.selectedOpt== 'improved' && action =='changeQformat'){
            questions =comment[23] ? comment[23] :comment[4];
            answerType = comment[24] ? comment[24] :comment[5];
            options = comment[22] ? comment[22] :comment[6];
        }else{
            answerType = comment[5];
            options = comment[6];
        }
        var field_id = comment[0];
        var label = comment[41];
		
        let rowAllData = [id,location,parent,questions,answerType,options,visibility1,visibility,field_id,label];
        return rowAllData;   
    }
    render() {
        const { kioskPreviewData,sheetData,queId,comment,moduleName,selectedOpt,allData, checkedReq, type, kioskResponse,userClassResponse} = this.props
		let username = this.getUsername(type);
		
        return (
          <div id={queId ? type+'_'+queId: ''} className={'commentss '+type} >
			{queId ?
				null
			: <h5 className="user-header"> {username} User Response</h5>}
            
			{kioskPreviewData.map((comment,i) => {
				let res = kioskResponse.get(comment[0]);
				const configRow = comment[16].split(',');
				configRow.splice(0, 9);
				
				let userClass = userClassResponse.get(comment[0]);
				
				if((((typeof userClass != "undefined" && userClass == type) || (comment[5].toLowerCase() == 'title' || comment[5].toLowerCase() == 'text')) && configRow.indexOf(type) != -1) || comment[5] == 'Parent Header' || comment[5] == 'Child Header 1' || comment[5] == 'Child Header 2' || comment[5] == 'Child Header 3'){
					
					
				return( <div id={comment[0]} key={comment[0]} style={{marginLeft:this.headerStyle(comment)}} className={this.classTypes(comment)}>
                {(() => {
                    let rowAllData=this.getData(comment,selectedOpt);
                    let question = comment[4];
					
					if(comment[9]){
						question = comment[9];
					}
					
                    let answer = generatehtmlfrom(rowAllData,this.subQuestions,this.props);
                    
                    switch (comment[5].toLowerCase()) {
                        case 'parent header':
                            return <h3 id ={type+'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{ question } </h3>;
                        case 'child header 1':
                            return <div className="child-headers">
                                        <h4 id ={type+'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{question}</h4>
                                    </div>
                        case 'child header 2':
                            return <div className="child-headers">
                                    <h5 id ={type+'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{question}</h5>       
                            </div>;
                        case 'child header 3':
                            return <div className="child-headers">
								<h6 id ={type+'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{question}</h6>    
                            </div>;
                        case 'text' :
                                return <div>
                                           { answer == '' ?
												<div className="col-md-12"><b>{question}</b> { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
												: <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
											}
                                            <div style={{padding: "10px"}} className="answer">
												{ answer }
											</div>
                                           
                                        </div>
                        case 'title' :
                            return <div>
									{ answer == '' ?
										<h5><b> { question } { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</b></h5>
									: <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>}
									<div style={{padding: "10px"}} className="answer">
										{ answer }
									</div>
								</div>
						case 'q5' :
                            return <div>
                                   <div className="col-md-12"> <span className="answer">{ answer }</span> {question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                            
                                </div>
						case 'image' :
                            return <div>
                                <div style={{padding: "10px"}} className="answer">
									<img src={res} width="259px" height="200px"/>
								</div>
								<div className="col-md-12">
                                    { question }   
                                </div>
                                            
                            </div>
						case 'video' :
                            return <div>
                                <div className="col-md-12">
                                    { question }   
                                </div>
                                <div style={{padding: "10px"}} className="answer">
									<div><b>Additional File in records</b><br />
									{ ReactHtmlParser(res) }
									</div>
								</div> 
                                            
                            </div>
						case 'inlinevideo' :
                            return <div>
                                <div className="col-md-12">
                                    { question }   
                                </div>
                                <div style={{padding: "10px"}} className="answer">
									<div><b>Additional File in records</b><br />
									{ ReactHtmlParser(res) }
									</div>
								</div> 
                                            
                            </div>
                        case 'file' :
						case 'pdfapi' :
                            let type1= '';
                            let setUrl = '';
                            let getUrl ='';
                            if(typeof res != "undefined" && res != ''){
                                getUrl = res.split(',');
                                setUrl = getUrl[0];
                                type1 = getUrl[0].split('.').pop();
                            }
                            return <div>
                                <div className="col-md-12">
                                    { question }   
                                </div>
                                <div style={{padding: "10px"}} className="answer">
									<div><b>Additional File in records</b><br />
									   <div id={'fileAns'+comment[0]}>
                                            <b>Name: </b>{getUrl[1]}<br/>
                                            <b>Description: </b>{getUrl[2]}<br/>
                                            <b>Date: </b>{getUrl[3]}
                                        </div>
									</div>
                                </div> 
                                            
                            </div>
						case 'calculation':
						case 'wcalculation':
							return (
								<div className="row" style={{padding: "10px", width:'190px'}}>
										<div className="col-md-5 cal-res-box" style={{padding: "20px", border: "1px solid", textAlign: "center" }}>
											<div className={"cal-res cal-res-" +comment[0] }>{res ? res : ''}</div>
											<div className="user-text">
											{question}
											</div>
										</div>
									
									</div>)
						case 'subjective':
							var styles = {padding: "10px", border:"1px solid #cccccc"}
							return (
									<div>
									<div className="col-md-12"><h5>Subjective</h5></div>
                                   <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                    <div style={styles} className="answer">
										{ answer }
									</div>        
                                </div>)
						case 'assessment':
							var styles = {padding: "10px", border:"1px solid #cccccc"}
							return (
									<div>
									<div className="col-md-12"><h5>Assessment</h5></div>
                                   <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                    <div style={styles} className="answer">
										{ answer }
									</div>        
                                </div>)
						case 'link':
                            return ('')
                        default:
                            return (
                                <div>
                                   <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                    <div style={{padding: "10px"}} className="answer">
										{ answer }
									</div>        
                                </div>)
                    }
                })()}
                {comment.sub 
                    && 
                    <PdfData 
                        sheetData = {sheetData}
                        checkedReq ={checkedReq}
                        kioskResponse={kioskResponse}
                        userClassResponse={userClassResponse}
                        kioskPreviewData={comment.sub}
                        queId={comment[1].toLowerCase()}
                        moduleName={moduleName} 
                        comment= {comment} 
                        allData = {allData}
                        type = {type}
                        />
                }
				</div>)
				
				}
            } )}
          </div>
        )
    }
}