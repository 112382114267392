import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import $ from 'jquery';
import 'bootstrap';
import "../../containers/App.css";
import { Form,Button } from 'react-bootstrap';
import config from "../../config";
import { gapi } from 'gapi-script';
import guid from "../../Helpers/GUID";
import axios,{post,get} from 'axios';
import {DebounceInput} from 'react-debounce-input';
import { getSheetTypeFacility, getSheetApiName } from '../../Helpers/Common';

class ManageForms extends Component{
	
	constructor(props){
        super(props)
        this.state ={
            sheetsName:[],
			csvdata: [],
			companies: [],
			csvdataConfig: [],
			mdsCsvName:'',
			fileDescription:'',
			sheetId:'',
			sheetName:'',
			token:'',
        }
		this.csvLink = React.createRef();
		this.csvLinkConfig = React.createRef();
    }
	
	componentDidMount(){
		
		let url = config.API_PDF_URL+'/login';
		let formData = new FormData();
		formData.append('username', config.API_username);
		formData.append('password', config.API_password);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			let token = response.data.token;
			this.setState({ token });
		}).catch(error => {
			console.log('error::', error);
		})
		
		this.getsheets();
	}
	
	getApiFiles = (token) => {
		return new Promise((resolve, reject) => {
		let url = config.API_PDF_URL+'/files?type_id=10';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Authorization': 'Bearer '+token
			}
		}).then(response => {
				let files = response.data.files;
				resolve(files);
			}).catch(error => {
				console.log('error::', error);

			})
		});
	}
	
	getsheets = () => {
		
		let configName = 'TTEVAL-Config Files';
		gapi.client.init({
           apiKey: config.apiKey,
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
			gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: config.spreadsheetId,
					range:configName,
				})
				.then(response => {
					let sheetVal = response.result.values;
					//console.log('sheetVal->',sheetVal);
					let sheets = [];
					sheetVal[0].map((value, i) => {
						if(i > 0){
							let configId = value[4];
							let col = [];
							sheetVal.map((val, j) => {
								let column = val[i];
								if(column){
									col.push(column);
								}
							});
							
							sheets.push(col);
						}
					});
					
					let sheetpr = [];
					
					sheets.map((sheet, i) => {
						let sht = {name:sheet[0], type:sheet[6], deviceType:sheet[7], visitType:sheet[8], mdsGuid:sheet[2], configGuid:sheet[4], date:sheet[3], facilityId:sheet[9], mdsName:sheet[1]};
						
						sheetpr.push(sht);

					});
					
					sheetpr.reverse();
					this.setState({ sheetsName:sheetpr });
					
					
					let that = this;
					setTimeout(function(){
						let token = that.state.token;
						that.getApiFiles(token).then(function(response) {
							
							if(response){
								let existMds = [];
								response.map((file,i)=>{
									let fileName = file.name.split("_");
									//let newfileName = fileName[1]+' '+fileName[2];
									let newfileName = fileName[1];
									
									sheetpr.map(function(ex_sheet,i) {
										let singleSheet = ex_sheet.name;
										//if(singleSheet.indexOf(newfileName) != -1){
										if(singleSheet == newfileName){
											$('#Config-'+ex_sheet.configGuid+' img').attr("src","api-green.png");
										}
										if(fileName[0] == 'MDS'){
											let mdsName = singleSheet.split("-");
											let sheets = that.props.sheetsName;
											sheets.map(function(sheet,i) {
												if(sheet.name == mdsName[1]){
													existMds.push(sheet.name);
												}
											})
										}
									})
								})
								var uniqueNames = [];
								$.each(existMds, function(i, el){
									if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
								});
								
								let sheetType1 = getSheetApiName(uniqueNames);
								setTimeout(function(){
									if(sheetType1){
										sheetType1.map(function(sheet,i) {
											let MdsSheetName = sheet.name;
											let sheetNam = sheet.apiName;
											response.map((file,i)=>{
												if(sheetNam == file.name){
													MdsSheetName = MdsSheetName.replaceAll(" ", "");
													$('.'+MdsSheetName+' img').attr("src","api-green.png");
												}
											})
										})
									}
								},2000);	
							}
						}, function(error) {
						  console.error("Failed!", error);
						})

					},2000);
					
				});
			});	
		}); 
        
		gapi.client.load("sheets", "v4", () => {
			let spreadsheetId = config.spreadsheetId;
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:'Company Name', 
			})
			.then(
				response => {
					const companyName = response.result.values;
					this.setState({ companies:companyName });
					
				},function(reason) {
					alert(reason.result.error.message);
				}
			);
		});

	}
	
	/* deleteSheet = (sheetId, sheetName) => {
		
		let spreadsheetId = config.pahseII_spreadsheetId;
		
		let params = {
			spreadsheetId: spreadsheetId
		};
		
		let deleteRequest = {
			"requests": [
				{
				  "deleteSheet": {
					"sheetId": sheetId,
				  }
				}
			]
		}
					
		let request = gapi.client.sheets.spreadsheets.batchUpdate(params,deleteRequest);
		request.then(
		response => {
			alert('File Deleted Successfully');
			this.deleteConfig(sheetName);
			this.getsheets();
		}, function(reason) {
			alert(reason.result.error.message);
		});
		
	} */
	
	deleteConfig = (configGuid, sheetName) => {
		let spreadsheetId = config.spreadsheetId;
		let configId = config.configsheetId;
		let configName = 'TTEVAL-Config Files';
		let params = {
			spreadsheetId: spreadsheetId
		};
		let startIndex = '';
		let endIndex = '';
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: config.spreadsheetId,
				range:configName,
			})
			.then(response => {
					let sheetVal = response.result.values;
					/* sheetVal[0].map((val, i) => {
						if(sheetName.indexOf(val) != -1){
							startIndex = i;
							endIndex = startIndex+1
						}
					}); */
					
					sheetVal[4].map((val, i) => {
						if(configGuid.indexOf(val) != -1){
							startIndex = i;
							endIndex = startIndex+1
						}
					});
					
					let deleteRequest = {
						"requests": [
							{
							  "deleteDimension": {
									"range": {
									  "sheetId": configId,
									  "dimension": "COLUMNS",
									  "startIndex": startIndex,
									  "endIndex": endIndex
									}
								}
							}
						]
					}
								
					let request = gapi.client.sheets.spreadsheets.batchUpdate(params,deleteRequest);
					request.then(
					response => {
						this.getsheets();
						alert('Config File Deleted Successfully');
					}, function(reason) {
						//alert(reason.result.error.message);
					});
				},
			);
		});	
	}
	
	downloadMsd = (sheetId, sheetName) => {
		gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: config.spreadsheetId,
                range:sheetName, 
            })
            .then(
                response => {
                    const allData = response.result.values;
					
					let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
					if(allData.length > 0){
						let mdsName = allData[1][118];
						let mdsGuid = allData[1][91];
						let Industry = allData[1][80];
						let Profession = allData[1][81];
						let Subspecialty = allData[1][82];
						let Type = allData[1][92];
						let DeviceType = allData[1][94];
						let VisitType = allData[1][95];
						let totalFields = allData.length-1;
						let facilityId = allData[1][120];
						
						let fieldData = [];
						let dates = [];
						allData.map((field,i)=>{
							if(i > 0){
								let date = new Date(field[98]);
								if(date != 'Invalid Date'){
									dates.push(date);
								}	
							}
						})
						
						var maximumDate = new Date(Math.max.apply(null, dates));
						let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
						let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
						
						let data = [
						  ["Master Data Set Name", mdsName],
						  ["Master Data Form GUID", mdsGuid],
						  ["Time date created", currentDate],
						  ["Time date Loaded into library", ""],
						  ["Date time of last data element change", newDate],
						  ["Industry", Industry],
						  ["Profession", Profession],
						  ["Subspecialty", Subspecialty],
						  ["Type", Type],
						  ["Device Type", DeviceType],
						  ["Visit Type", VisitType],
						  ["Total # of Fields", totalFields+" Fields"],
						  ["MDS_Facility_ID", facilityId],
						  ["Group ID", ''],
						  ["{eoh}"],
						  
						];
						
						allData.map((field,i)=>{
							field[12] = field[12].replaceAll('\n','')
							data.push(field);
						})
						let dataeof = [
						  ["{eof}"],
						  
						];
						data.push(dataeof);
						//let dateCsv = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');	
						let mdsCsv = 'MDS_'+ mdsName +' '+lastUpDate+'_'+totalFields+'.csv'
						this.setState({ csvdata:data, mdsCsvName:mdsCsv }, () => {
							let that = this;
							setTimeout(function(){ 
								that.csvLink.current.link.click()
							}, 2000);
						})
					}

                },function(reason) {
					alert(reason.result.error.message);
				}
            );
        });	
	}
	
	downloadConfig= (configGuid, sheetName) => {
		let spreadsheetId = config.spreadsheetId;
		let configId = config.configsheetId;
		let configName = 'TTEVAL-Config Files';
		let params = {
			spreadsheetId: spreadsheetId
		};
		let startIndex = '';
		let totalFields = '';
		let cName = '';
		let cDate = '';
		let formName = '';
		let data = [];
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: config.spreadsheetId,
				range:configName,
			})
			.then(response => {
					let sheetVal = response.result.values;
					
					/* sheetVal[0].map((val, i) => {
						if(sheetName.indexOf(val) != -1){
							startIndex = i;
						}
					}); */
					sheetVal[4].map((val, i) => {
						if(configGuid.indexOf(val) != -1){
							startIndex = i;
						}
					});
					
					if(startIndex){
						sheetVal.map((val, i) => {
							
							let column = val[startIndex];
							if(i == 0){
								cName = val[startIndex];
							}
							if(i == 3){
								cDate = val[startIndex];
							}
							if(i == 5){
								totalFields = val[startIndex].replace(' Fields','');
							}
							
							if(column){
								data.push([column]);
							}
						});
						
						//let dateCsv = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');	
						let mdsCsv = 'Config_'+ cName +'_'+cDate+'_'+totalFields+'.csv'
						
						this.setState({ csvdataConfig:data, mdsCsvConfigName:mdsCsv }, () => {
							let that = this;
							setTimeout(function(){ 
								that.csvLinkConfig.current.link.click()
							}, 2000);
						})
					}
				},
			);
		});	
	}
	
	downloadConfigJson= (configGuid, sheetName, type) => {
		
		$('#addConfigDescription').modal('hide');
		if(this.state.fileDescription == '' && type=='post'){
			$('#addConfigDescription').modal('show');
			return false;
		}
		let spreadsheetId = config.spreadsheetId;
		let configId = config.configsheetId;
		let configName = 'TTEVAL-Config Files';
	
		//let lastElement = sheetName.split(' ');
		//let configGuid = lastElement[lastElement.length-1];
		
		let params = {
			spreadsheetId: spreadsheetId
		};
		let startIndex = '';
		let totalFields = '';
		let cName = '';
		let cDate = '';
		let formName = '';
		let data = [];
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: config.spreadsheetId,
				range:configName,
			})
			.then(response => {
				let sheetVal = response.result.values;
				//console.log('sheetVal->',sheetVal);
				sheetVal[4].map((val, i) => {
					if(configGuid.indexOf(val) != -1){
						startIndex = i;
					}
				});
				
				if(startIndex){
					sheetVal.map((val, i) => {
						
						let column = val[startIndex];
						if(i == 0){
							cName = val[startIndex];
						}
						if(i == 3){
							cDate = val[startIndex];
						}
						if(i == 5){
							totalFields = val[startIndex].replace(' Fields','');
						}
						
						if(column){
							data.push(column);
						}
					});
					
					//let dateCsv = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');	
					
					let layout ={
						'Configuration Name': data[0],
						'Master Data Set Name': data[1],
						'Master Data Set GUID': data[2],
						'Time date created': data[3],
						'Config File GUID': data[4],
						'Total # of Fields': data[5],
						'Device Types': data[6],
						'Visit Types': data[7],
						'Facility ID': data[8],
						'Group ID': data[9],
						'Language': data[10],
						'Fields': [],
					}
					let fields = [];
					data.map((val, i) => {
						if(i > 11 && val != '{eof}'){
							if(val != '{eoh}'){
								
								let userArray = val.split(',');
								userArray.splice(0, 9);
								
								let uArray = userArray.filter(function(v){return v!==''});
								let kioskTypes = uArray.toString();
								kioskTypes = kioskTypes.replaceAll(',','|');

								let strArray = val.split(',');
								strArray.splice(9, 15);

								let configStr = strArray.toString();
								configStr = configStr+",["+kioskTypes+"]";
								fields.push(configStr);
							}else{
								//fields.push(val);
							}
						}
						
					})
					layout['Fields'] = fields
					let configJson = 'Config_'+ cName +'_'+cDate+'_'+totalFields;
					
					if(type=='post'){
						const file = new Blob([JSON.stringify(layout)], { type: "text/plain;charset=utf-8" });
						this.postMdsJson(configGuid, configJson, file, 'Config', cName);
						
					}else{
						this.download(JSON.stringify(layout), configJson+'.json', "text/plain;charset=utf-8");
					}
					
				}else{
					alert('Config data not exist!');
				}
			},function(reason) {
				alert(reason.result.error.message);
			});
		});
	}
	
	download(content, fileName, contentType) {
	 const a = document.createElement("a");
	 const file = new Blob([content], { type: contentType });
	 a.href = URL.createObjectURL(file);
	 a.download = fileName;
	 a.click();
	}
	
	setMsdId = (configGuid, sheetName) => {
		this.setState({configGuid,sheetName})
	}
	
	downloadMsdJson = (configGuid, sheetName, type) => {
		
		$('#addMdsDescription').modal('hide');
		if(this.state.fileDescription == '' && type=='post'){
			$('#addMdsDescription').modal('show');
			return false;
		}
							
		//var  singleSheetName = sheetName.split('-');
		gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: config.spreadsheetId,
                range:sheetName, 
            })
            .then(
                response => {
                    const allData = response.result.values;
					let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
					if(allData.length > 0){
						let mdsName = allData[1][118];
						
						let mdsGuid = allData[1][91];
						let associatedType = allData[1][92];
						let associatedForm = allData[1][93];
						let associatedDevice = allData[1][94];
						let associatedVisit = allData[1][95];
						
						let Type = allData[1][100];
						let DeviceType = allData[1][101];
						let VisitType = allData[1][102];
						let totalFields = allData.length-1;
						let Industry = allData[1][80];
						let Profession = allData[1][81];
						let Subspecialty = allData[1][82];
						
						let delegateTypes = allData[1][119];
						let facilityId = allData[1][120];
						let groupId = allData[1][121];
						
						let dates = [];
						let fields = [];
						allData.map((field,i)=>{
							if(i > 0){
								
								let date = new Date(field[98]);
								if(date != 'Invalid Date'){
									dates.push(date);
								}
								
								let notes = field[12] = field[12].replaceAll('\n','');
								notes = notes.replaceAll('<p></p>','');
								let customNotes = field[116] = field[116].replaceAll('\n','');
								customNotes = customNotes.replaceAll('<p></p>','');
								
								let preId = '';
								let parentGuid = '';
								allData.map((pre_field,i)=>{
									if(field[7] && field[7] == pre_field[0]){
										preId = pre_field[117];
									}
									
									if(pre_field[1] == field[2]){
										parentGuid = pre_field[117];
									}
								})
								
								
								
								let oldP = field[16];
								let userArray = oldP.split(',');
								userArray.splice(0, 9);
								
								let uArray = userArray.filter(function(v){return v!==''});
								let kioskTypes = uArray.toString();
								kioskTypes = kioskTypes.replaceAll(',','|');

								let strArray = oldP.split(',');
								strArray.splice(9, 15);

								let configStr = strArray.toString();
								configStr = configStr+",["+kioskTypes+"]";
								
								
								
								let fieldData = {
									"Field Name": field[0],
									//"Field #": field[1],
									"Parent #": field[2],
									"S/U": field[3],
									"Visible Text": field[4],
									"Field Type": field[5],
									"Options": field[6],
									//"Conditional Field Name": field[7],
									"Conditional Field Name": preId,
									"Conditional Option": field[8],
									
									"Output Text": field[9],
									"Position #": field[10],
									"Parent GUID": parentGuid,
									"Notes": notes,
									"Tags": field[13],
									"Include": field[14] == 'Y' ? true:false,
									"Required": field[15] == 'Y' ? true:false,
									
									"Position-Configuration": configStr,
									//"Position-Configuration": field[16],
									//"Custom Question-title-header GUID": field[35],
									"Delegate Types": "["+kioskTypes+"]",
									"Delegate Mode": field[37] == 'Y' ? true:false,
									"language": field[38],
									//"TAG-ID": field[39],
									"Shared Form": field[40],
									"Shared form ID's": field[41],
									"Value": field[48],
									"Unit": field[49],
									
									"data low limit": field[50],
									"data high limit": field[51],
									"Date Field last": field[98],
									"Image Location reference info": field[99],
									"Public Field Bank": field[111] == 'Y' ? true:false,
									"PFB=No Description": field[112],
									"Calculation Type": field[113],
									
									"Calculation Fields": field[114],
									"Company Name": field[115],
									"Custom Field Note": customNotes,
									"Field GUID": field[117],
								}
							
								const filteredObj = (obj) =>
								  Object.entries(obj)
									.filter(([_, value]) => !!value || typeof value === "boolean")
									.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
								
								fieldData = filteredObj(fieldData);
								fields.push(fieldData);
								
							}
							
							
						})
						
						var maximumDate = new Date(Math.max.apply(null, dates));
						let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
						let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
						
						let layout ={
							"CreationDate": currentDate,
							"ModifiedDate": newDate,
							"Form Name": mdsName,
							"Master Data Set GUID": mdsGuid,
							"Associated Type": associatedType,
							"Asociated Forms": associatedForm,
							"Associated Device Types": associatedDevice,
							"Associated Visit Types": associatedVisit,
							"Type Master List": Type.replaceAll('\n',''),
							"Device Type Master List": DeviceType.replaceAll('\n',''),
							"Visit Type Master List": VisitType.replaceAll('\n',''),
							"Industry": Industry,
							"Profession": Profession,
							"Subspecialty": Subspecialty,
							"MDS_Facility_ID": facilityId,
							"Group ID": groupId,
							"All Delegate Types": delegateTypes,
							'Fields': fields,
						}
						
						//let dateCsv = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
						
						let mdsJson = 'MDS_'+ mdsName +'_'+lastUpDate+'_'+totalFields;
						if(type=='post'){
							const file = new Blob([JSON.stringify(layout)], { type: "text/plain;charset=utf-8" });
							this.postMdsJson(configGuid, mdsJson, file, 'MDS', mdsName);
							this.updateMdsLastDate(mdsName, mdsJson);

						}else{
							this.download(JSON.stringify(layout), mdsJson+'.json', "text/plain;charset=utf-8");
						}
						
					}

                },function(reason) {
					alert(reason.result.error.message);
				}
            );
        });	
	}
		
	updateMdsLastDate= (mdsName, mdsJson) => {
		let accessToken = gapi.auth.getToken().access_token;
		let params = {
			access_token:accessToken,
			spreadsheetId: config.spreadsheetId,
		};
		let row = {
			"range": mdsName+'!DT2',
			"majorDimension": "ROWS",
			"values": 
			[
				[ mdsJson ]
			]
		};
		
		let batchUpdateValuesRequestBody = {
			valueInputOption: 'USER_ENTERED',
			data: row
		};
		let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
		request.then(function(response) {						
		}, function(reason) {
			alert(reason.result.error.message);
		});
	}
	
	filterByFacilityID=(event)=>{
		let fId = event.target.value;
		if(fId == 'Please Select'){
			$( ".list-group li.show" ).show();
		}else{
			$( ".list-group li.show" ).hide();
			$( ".list-group li.show" ).each(function( index ) {
				let atr = $(this).attr("data-fId");
				if(atr == fId){
					$(this).show();
				}
			});
		}
	}
	
	filterSheets=(event)=>{
        let sheetName = event.target.value;
		$(".manage-facility-id option:selected").prop("selected", false)
		if(sheetName == 'Please Select'){
			$( ".list-group li" ).addClass('show');
		}else{
			$( ".list-group li" ).removeClass('show');
			$( ".list-group li" ).addClass('hide');
			$( ".list-group li" ).each(function( index ) {
				let txt = $(this).text();
				txt = txt.split("-");
				if(txt[1] && sheetName){
					sheetName = sheetName.toLowerCase();
					let sheetName2= txt[1].toLowerCase();
					if(sheetName == sheetName2){
						$(this).removeClass('hide');
						$(this).addClass('show');
					}
				}
			});
		}
    }
	
	/* getToken = () => {
		return new Promise((resolve, reject) => {
			let url = config.API_PDF_URL+'/login';
			let formData = new FormData();
			formData.append('username', config.API_username);
			formData.append('password', config.API_password);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				let token = response.data.token;
				resolve(token);
			}).catch(error => {
				console.log('error::', error);

			})
		});
	} */
	
	postMdsJson = (configGuid, fileName, file, type, mdsName) => {
		
		let token = this.state.token;
		let url = config.API_PDF_URL+'/jsons?type_id=10';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Authorization': 'Bearer '+token
			}
		})
		.then(response => {
			let files = response.data.data.files;
			let exist = false;
			files.map((file,i)=>{
				if(fileName == file.name){
					exist = true;
				}
			})
			
			//if(exist){
				//alert('File already in the Library!');
				//$('#'+type+'-'+sheetId+' img').attr("src","api-green.png");
			//}else{
				
				let url3 = config.API_PDF_URL+'/jsons';
				let formData = new FormData();
				formData.append('json_file', file);
				formData.append('name', fileName);
				formData.append('description', this.state.fileDescription);
				formData.append('type_id', '10');
				if(type=='MDS'){
					formData.append('category_id', '45');
				}else{
					formData.append('category_id', '46');
				}
				axios({
					method: 'POST',
					url: url3,
					data: formData,
					headers: {
						'Authorization': 'Bearer '+token,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					alert('File stored Successfully!');
					if(type=='MDS'){
						mdsName = mdsName.replaceAll(" ", "");
						$('.'+mdsName+' img').attr("src","api-green.png");
					}else{
						$('#'+type+'-'+configGuid+' img').attr("src","api-green.png");
					}
					this.setState({configGuid:'',sheetName:'',fileDescription:''})
				}).catch(error => {
					console.log('error::', error);
				})
			
			//}
		}).catch(error => {
			console.log('error::', error);
		})
		
	}
	
	addDescription=(event)=>{
        this.setState({fileDescription:event.target.value})
    }
	
	closePopup(){
		$('#addDescription').modal('hide');
	}
	
	showPopup(){
		$('#addDescription').modal('show');
	}
	
	render(){
		const { sheetsName, companies } = this.state;
		var optionsSheet = ''
		let sheets = this.props.sheetsName;
		if(sheets){
            sheets.map(function(sheet,i) {
				sheetsName.map(function(ex_sheet,i) {
					let singleSheet = ex_sheet.name;
					let facilityId = ex_sheet.facilityId;
					singleSheet = singleSheet.split("-");
					if(sheet.name == singleSheet[1]){
						$(".filter-sheet option[value='"+sheet.name+"']").addClass('hasData');
					}
					$(".manage-facility-id option[value='"+facilityId+"']").addClass('hasData');
				})
            })
        }
		
		return(
			<div className="card-body manage-forms">
				<div className="row">
					<div className="col-md-3">
						<div className="form-group">
							<label> Clinical Form </label>
							<Form.Control className="filter-sheet" as="select" onChange={this.filterSheets}>
								<option className="hasData"> Please Select</option>
								{this.props.sheetsName.map((sheet, i) => (
									<option value={sheet.name}>{sheet.name}</option>
								))}
							</Form.Control>
						</div>
					</div>
					<div className="col-md-3">
						<div className="form-group">
							<label className="label-control"> Facility ID </label>
							<select className="form-control manage-facility-id" name="setFacilityID" onChange={this.filterByFacilityID}>
								<option className="hasData">Please Select</option>
								{companies.map((company, i) => (
									<option value={company}>{company}</option>
								))}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						<div className="download-csv"><span>MDS Json</span><span>Config Json</span><span>MDS.CSV</span><span>Config.CSV</span></div>
					</div>
				</div>
				<ul className="list-group manage-form">
				
				{sheetsName.map((sheet, i) => {
					let sh = sheet.name;
					sh= sh.split("-");
					let mdsN = sh[1].replaceAll(" ", "");
					return(<li className={"list-group-item show"} data-fId={sheet.facilityId} data-id={sheet.configGuid}>
					<div className="sheet-name-i">{sheet.name} </div>
					<div className="download-icon">
					
					<i className="fa fa-download mds-json" aria-hidden="true" onClick={() => { this.downloadMsdJson(sheet.configGuid, sheet.mdsName,'download') } }></i>
					
					<i id={'MDS-'+sheet.configGuid} className={"api-icon "+mdsN} onClick={() => { this.setMsdId(sheet.configGuid, sheet.mdsName) } } data-target="#addMdsDescription" data-toggle="modal" ><img src={'/api.png'} width="259px" height="200px"/></i>
					
					<i className="fa fa-download config-json" aria-hidden="true" onClick={() => { this.downloadConfigJson(sheet.configGuid, sheet.name,'download') } }></i>
					
					<i id={'Config-'+sheet.configGuid} className="api-icon" onClick={() => { this.setMsdId(sheet.configGuid, sheet.name) } } data-target="#addConfigDescription" data-toggle="modal" ><img src={'/api.png'} width="259px" height="200px"/></i>
					
					<i className="fa fa-download csv-mds-down" aria-hidden="true" onClick={() => { this.downloadMsd(sheet.configGuid, sheet.mdsName) } }></i>
					
					<i className="fa fa-download csv-config-down" aria-hidden="true" onClick={() => { this.downloadConfig(sheet.configGuid, sheet.name) } }></i>
					<i  onClick={() => { if (window.confirm('Are you sure you want to delete this sheet?')) this.deleteConfig(sheet.configGuid, sheet.name) } } className="fa fa-trash"></i>
					</div>
					</li>)
				})}
				
				</ul>
				<CSVLink
					data={this.state.csvdata}
					filename={this.state.mdsCsvName}
					className="hidden"
					ref={this.csvLink}
					target="_blank" 
				/>
				  
				 <CSVLink
					data={this.state.csvdataConfig}
					filename={this.state.mdsCsvConfigName}
					className="hidden"
					ref={this.csvLinkConfig}
					target="_blank" 
				/>
				
				<div className="modal" id={"addMdsDescription"} role="dialog">
                    <div className="modal-dialog modal-lg custom-modal mds-description-modal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Add File Description</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
								<DebounceInput element="textarea" debounceTimeout={4000} className={'form-control'} rows="3" value={this.state.fileDescription} onChange={this.addDescription} maxlength="50"/>
                          </div>
                          <div className="modal-footer">
                                <div className="image-text" id="fileText"></div>
                                <div className="popup-btn-com">
                                    <button type="button" className="btn btn-primary float-left" onClick={() => { this.downloadMsdJson(this.state.configGuid, this.state.sheetName, 'post') } }>Save</button>
                                    <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                                </div>
                          </div>

                        </div>
                    </div>
                </div>
				<div className="modal" id={"addConfigDescription"} role="dialog">
                    <div className="modal-dialog modal-lg custom-modal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Add File Description</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
								<DebounceInput element="textarea" debounceTimeout={4000} className={'form-control'} rows="3" value={this.state.fileDescription} onChange={this.addDescription} maxlength="50"/>
                          </div>
                          <div className="modal-footer">
                                <div className="image-text" id="fileText"></div>
                                <div className="popup-btn-com">
                                    <button type="button" className="btn btn-primary float-left" onClick={() => { this.downloadConfigJson(this.state.configGuid, this.state.sheetName, 'post') } }>Save</button>
                                    <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                                </div>
                          </div>

                        </div>
                    </div>
                </div>
				
			</div>
		);
	}
}

export default ManageForms;