import React, { Component } from 'react';
import $ from 'jquery';
import 'bootstrap';
import config from "../../config";
import { gapi } from 'gapi-script';
import Header from "../../component/Header/Header";
import "../../containers/App.css";
import { Form,Button } from 'react-bootstrap';
import { Dropdown1 } from "../../component/FormData/Dropdown";
import { KioskDrop } from "./KioskDrop";
import KioskData from "./KioskData";
import MultipleImageUpload from "./MultipleImageUpload";
import BasicTextFields from "../../component/MainData/InlineUpload.js";
import FileUpload from "./FileUpload";
import MainData from "../../component/MainData/MainData";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import axios,{post,get} from 'axios';
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';
import { removePrecondition, showParentField, editPrecondition} from "../../Helpers/Precondition";
import { generatehtmlfromlocation,handleAuthResult,saveConfigFile, handleSubmitReset, handleSubmitResetInc, uploadFilePath, clearSheetRows } from "../../Helpers/Common";
import guid from "../../Helpers/GUID";
import QAction from "../../component/QAction/QAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class FormBuilder extends Component{
    constructor(props){
        super(props)
        this.state={
            updatedFields:new Map(),
            checkedInc:new Map(),
            checkedReq:new Map(),
            kioskUsersData:new Map(),
            uploadedImages:[],
            editorState: EditorState.createEmpty(),
            checkedKiosk:new Map(),
            selOptions:'view_all',
            switchBtn:true,
            savedData:false,
            showMinMax:false,
            showCompany:true,
            imagePath:'',
            inlineVideo:'',
			pfbByCompany:'',
			addCompany:'',
			addTracking:'',
			newCompanyName:'',
			newTrackingCode:'',
            calculationType:'',
            kioskUsers:['CPO'],
            feedsResponse:new Map(),
            kioskResponse:new Map(),
            calFieldsId:new Map(),
            kioskPreviewData:[],
            langSheetsName:[],
			validation1:'V1',
			validation2:'V1',
			validation3:'V1',
        }
        this.fileArray = [];
        this.childImages = React.createRef();
        this.childFiles = React.createRef();
    }
	
    componentDidUpdate(){
        if(this.props.allData.length && this.state.checkedInc.size == 0){
            this.setDefaultCheckboxes();
        }
		if(this.props.hasModify){
			$('.comments').css('backgroundColor','#ececeb')
		}else{
			$('.comments').css('backgroundColor','#ffffff')
		}
		//$('.export-btn').hide();
    }
	
    componentWillReceiveProps(){
        if(this.props.expandData){
            let elm = document.getElementById(this.props.expandData);
            let x = 0;
            do {
                if(elm){
                    let parentNode = elm.parentNode;
                    if(parentNode.className.indexOf('collapse') != -1){
                        parentNode.classList.add("show");
                        let id = 'parent'+parentNode.getAttribute('id');
                        $('#'+id).find('i').removeClass('fa fa-plus-square-o fa-1x mr-2');
                        $('#'+id).find('i').addClass('fa fa-minus-square-o fa-1x mr-2');
                    }
                    if(parentNode.className.indexOf('parent-header') != -1){
                        x = 5; 
                    }
                    elm = parentNode;  
                }
                x++;
            }
            while(x < 5); 
        }/*else if(this.props.showImpData || this.props.exportSheet){
            this.setDefaultCheckboxes();
        }*/
		
    }
    
	subQuestions=(res)=>{
        this.setState({preCondVal:res})
    }
    
	saveKioskUsersType=(id)=>{
        let kioskUsersData1 = this.state.kioskUsersData;
		
        /* var scorer = {CC:0,Pat:1,Int:2,Res:3,PrA:4,PhT:5,CPO:6,PhA:7,PmD:8,}
        var final_arr1  = ['CC','Pat','Int','Res','PrA','PhT','CPO','PhA','PmD'];
        var final_arr = this.state.kioskUsers.sort(function(a,b) {
            return scorer[a]-scorer[b];
        }); */
		
		let allDatas = this.props.allData;
		let uType = [];
		let kioskType = allDatas[0][119].split(',');
		
		if(kioskType){
			kioskType.map(function(type,i) {
				type = type.split(':');
				uType.push(type[1]);
			})
		}else{
			uType = ['CPO']
		}
		
		var final_arr = this.state.kioskUsers;
		var final_arr1 = uType;
		
		
		var array=[]; 
        final_arr1.map((item,i)=>{
            if(final_arr.includes(item) == true){
                array.push(item)
            }else if(item == 'CPO'){
                array.push('CPO')
            }else{
                array.push('')
            }
        })

        kioskUsersData1.set(id,array.toString());
        let accessToken = gapi.auth.getToken().access_token;
        //let spreadsheetID = config.spreadsheetId;
		let spreadsheetID = this.props.sheetConfigId;
        let includeArr = this.state.checkedInc;
        let requiredArr = this.state.checkedReq;
        let kioskArr = this.state.checkedKiosk;
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        let updateData=[];
        let guids = '';
        let rowId ='';
        let req ='N';
        let inc = 'N';
        let kiosk ='N';
        if(requiredArr.get(id) == true){
            req = 'Y';
            inc = 'Y';
        }
        else if(includeArr.get(id) == true){
            inc = 'Y';
        }
        if(kioskArr.get(id) == true){
            kiosk = 'Y';
        }
        let userTypes = this.state.kioskUsersData.get(id)
        if(userTypes == undefined){
            userTypes = ',,,,,,,CPO,,';
        }
			
        let type = 'kiosk';
        let item  = id;
        let items = [];
        items = this.checkPreCondition(item, items, type);
        allDatas.map((rowDatas, i) => {
			let guids = rowDatas[35] ? rowDatas[35] : rowDatas[91];
			if(rowDatas[0] == id){
                rowId = (i+2);
                let row1 = {
                    "range": this.props.selectedSheet+'!AI'+rowId,
                    "majorDimension": "ROWS",
                    "values": 
                    [
                        [ rowDatas[0] + ','+guids+','+rowDatas[2] +','+ rowDatas[7] +','+ rowDatas[8] +','+ inc +','+ req +','+ rowDatas[10] +','+ kiosk+','+userTypes]
                    ]
                };
                updateData.push(row1)
            }

            items.map((item)=>{
                kioskUsersData1.set(item,array.toString());
                if(rowDatas[0] == item){
                    rowId = (i+2);
                    let row2 = {
                        "range": this.props.selectedSheet+"!AI"+rowId,
                        "majorDimension": "ROWS",
                        "values": 
                        [
                            [ rowDatas[0] + ','+guids+','+rowDatas[2] +','+ rowDatas[7] +','+ rowDatas[8] +','+ inc +','+ req +','+ rowDatas[10] +','+ kiosk+','+userTypes]
                        ]
                    };
                    updateData.push(row2)
                }

            })
        })
		
        this.setState({kioskUsersData:kioskUsersData1})
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
            alert('Data saved Successfully')
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
        this.setState({kioskUsers:['CPO']})
        this.props.updatedData();
    }
    
	exportSheetName=(event)=>{
        this.setState({setSheetName:event.target.value});
    }
	
	exportFacilityID=(event)=>{
        this.setState({setFacilityID:event.target.value});
    }
	
	exportsetGroupID=(event)=>{
        this.setState({setGroupID:event.target.value});
    }
	
	addCompany=(event)=>{
        this.setState({addCompany:event.target.value});
	}
	
	addTracking=(event)=>{
        this.setState({addTracking:event.target.value});
	}
	
	addNewCompany=()=>{
		let companyName = this.state.addCompany;
		let spreadsheetID = config.spreadsheetId;
		
		if(companyName){
			var params = {
				spreadsheetId:  spreadsheetID,
				range:'Company Name!A:A', 
				valueInputOption: "RAW", 
				insertDataOption: "INSERT_ROWS",
				resource:{
					"values": 
					[[companyName]],
					"majorDimension": "ROWS"
				  }
			};
			let that = this;
			var request = gapi.client.sheets.spreadsheets.values.append(params);
			request.then(function(response) {
				that.setState({newCompanyName:companyName});
				alert('Company added successfully!');
				$('#addComModal').modal('hide');
			}, function(reason) {
			console.error('error: ' + reason.result.error.message);
			});
		}
	}
	
	addNewTracking=()=>{
		let code = this.state.addTracking;
		let spreadsheetID = config.spreadsheetId;
		
		if(code){
			var params = {
				spreadsheetId:  spreadsheetID,
				range:'Tracking Code!A:A', 
				valueInputOption: "RAW", 
				insertDataOption: "INSERT_ROWS",
				resource:{
					"values": 
					[[code]],
					"majorDimension": "ROWS"
				  }
			};
			let that = this;
			var request = gapi.client.sheets.spreadsheets.values.append(params);
			request.then(function(response) {
				that.setState({newTrackingCode:code});
				alert('Tracking Code added successfully!');
				$('#addTrackModal').modal('hide');
			}, function(reason) {
			console.error('error: ' + reason.result.error.message);
			});
		}
	}
	
	
    getValue=(event)=>{
        let name = event.target.name;
        let res = event.target.value;
        this.setState({[event.target.name]:event.target.value});
		
		/* if(name == 'chooseAnsType' && res != ''){
			$("#saveBtn").prop('disabled', false);
		}else if(name == 'chooseAnsType' && res == ''){
			$("#saveBtn").prop('disabled', true);
		} */
		
		if(this.state.chooseAnsType == 'gridcheck' && name == 'changeAnsInpText'){
			
			if (res.indexOf(',') > -1) {
				let strArray = res.split(',');
				strArray.map((r,i)=>{
					if(r.length > 20){
						alert("'"+r+"' The option must be no more than 20 characters.");
						let newStr = res.slice(0,-1);
						$("textarea#optionInput").val(newStr);
						
					}
				})
			}else{
				if(res.length > 20){
					alert("'"+res+"' The option must be no more than 20 characters.");
					let newStr = res.slice(0,-1);
					$("textarea#optionInput").val(newStr);
				}
			}
		}
		
		if(this.state.chooseAnsType == 'weighted' && res > 100 && (name == 'weight1' || name == 'weight2' || name == 'weight3' || name == 'weight4' || name == 'weight5' || name == 'weight6' || name == 'weight7' || name == 'weight8' || name == 'weight9' || name == 'weight10')){
			$('input[name="'+name+'"]').val(0);
			this.setState({[event.target.name]:0});
			alert('Value must be less than or equal to 100.');
		}
		
		if(name == 'chooseAnsType' && res == 'timer'){
			this.setState({showMinMax:true});
			$('.validation-box').val('V12').trigger('change');
			this.setState({['validationType']:'V12'});
			
			let that = this;
			setTimeout(function(){
				$('.max-val').val('00:00:00');
				$('.min-val').val('00:00:00');
				that.setState({maxValue:'00:00:00',minValue:'00:00:00'});
			}, 2000);
		} 
		
        this.setState({showCompany:true});
		
        if(res  == 'slider' && name == 'chooseAnsType'){
            $('#videoDiv').css('display','none')
            $('#answerDiv').css('display','none')
            $('#imageDiv').css('display','none')
            $('.sliderDiv').css('display','block')
            $('#fileDiv').css('display','none')
        }else if(name == 'chooseAnsType' && res == 'image'){
            $('#videoDiv').css('display','none')
            $('#imageDiv').css('display','block')
            $('#answerDiv').css('display','none')
            $('.sliderDiv').css('display','none')
            $('#fileDiv').css('display','none')
        }else if(name == 'chooseAnsType' && res == 'video'){
            $('#videoDiv').css('display','block')
            $('#imageDiv').css('display','none')
            $('#answerDiv').css('display','none')
            $('.sliderDiv').css('display','none')
            $('#fileDiv').css('display','none')
        }else if(name == 'chooseAnsType' && res == 'file'){
            $('#videoDiv').css('display','none')
            $('#imageDiv').css('display','none')
            $('#answerDiv').css('display','none')
            $('.sliderDiv').css('display','none')
            $('#fileDiv').css('display','block')
        }else if( name == 'chooseAnsType' && (res  == 'q10' || res == 'sig' || res  == 'timer' || res  == 'q6' || res  == 'q7' || res  == 'q9' || res  == 'text' || res  == 'title' || res == 'q5' || res == 'q8' || res =='subjective' || res =='assessment')){
            $('#videoDiv').css('display','none')
            $('#answerDiv').css('display','none')
            $('#imageDiv').css('display','none')
            $('.sliderDiv').css('display','none')
            $('#fileDiv').css('display','none')
        }else if(name == 'chooseAnsType' && (res  !== 'q10' || res !== 'sig' || res  !== 'timer' || res !=='slider' || res !=='image')){
            $('#fileDiv').css('display','none')
            $('#answerDiv').css('display','block')
            $('#imageDiv').css('display','none')
            $('#videoDiv').css('display','none')
            $('.sliderDiv').css('display','none')
        }
		if(name == 'chooseAnsType' && res == 'calculation'){
			$('#calculationType, #calculationFields').css('display','block');
			$('#answerDiv, .vali-1, #addNote, #addTag').css('display','none')
		}else if(name == 'chooseAnsType' && res != 'calculation'){
			 $('.vali-1, #addNote, #addTag').css('display','block');
			 $('#calculationType, #calculationFields').css('display','none');
		}
		
		if(name == 'chooseAnsType' && res == 'wcalculation'){
			$('#wcalculation, #wcalculationFields').css('display','block');
			$('#answerDiv, .vali-1, #addNote, #addTag').css('display','none')
		}else if(name == 'chooseAnsType' && res != 'wcalculation'){
			 $('.vali-1, #addNote, #addTag').css('display','block');
			 $('#wcalculation, #wcalculationFields').css('display','none');
		}
		
		if(name == 'chooseAnsType' && res == 'grid-dropdown'){
			$('#gridDropdown').css('display','block');
			$('#answerDiv, .vali-1').css('display','none');
		}else if(name == 'chooseAnsType' && res != 'grid-dropdown'){
			$('#gridDropdown').css('display','none');
			//$('#answerDiv, .vali-1').css('display','block')
		}
		
		if(name == 'chooseAnsType' && res == 'link'){
			$('#linkType').css('display','block');
			$('#answerDiv').css('display','none');
		}else if(name == 'chooseAnsType' && res != 'link'){
			 $('#linkType').css('display','none');
		}
		
		if(name == 'chooseAnsType' && (res == 'subjective' || res == 'assessment' || res == 'link' || res == 'pdfapi' || res == 'wcalculation' || res == 'basicgrid')){
			$('.vali-1').css('display','none');
		}else if(name == 'chooseAnsType' && (res != 'subjective' || res != 'assessment' || res != 'link' || res != 'pdfapi' || res != 'wcalculation' || res != 'basicgrid')){
			$('.vali-1').css('display','block');
		}
		
		if(name == 'chooseAnsType' && (res == 'boxbegin' || res == 'boxend')){
			$('.vali-1').css('display','none');
			$('#answerDiv').css('display','none');
		}
		
		if(name == 'chooseAnsType' && res == 'inlineVideo'){
			$('#inlineVideoDiv').css('display','block');
			$('#answerDiv').css('display','none')
			
		}else if(name == 'chooseAnsType' && res != 'inlineVideo'){
			 $('#inlineVideoDiv').css('display','none');
		}
		
		if(name == 'chooseAnsType' && res == 'gridcheck'){
			$('#gridcheck').css('display','block');
			$('#answerDiv').css('display','block')
			
		}else if(name == 'chooseAnsType' && res != 'gridcheck'){
			$('#gridcheck').css('display','none');
		}
		
		if(name == 'chooseAnsType' && res == 'grid5point'){
			$('#grid').css('display','block');
			$('#answerDiv').css('display','none')
			
		}else if(name == 'chooseAnsType' && res != 'grid5point'){
			$('#grid').css('display','none');
		}
		
		if(name == 'numberRows'){
			$('.row-input').css('display','none');
			for(let i = 1; i <= res; i++){
				$('.row-'+i).css('display','block');
			}
		}
		
		if(name == 'chooseAnsType' && res == 'likert'){
			$('#likert').css('display','block');
			$('#answerDiv').css('display','none')
			
		}else if(name == 'chooseAnsType' && res != 'likert'){
			$('#likert').css('display','none');
		}
		
		if(name == 'chooseAnsType' && res == 'smart'){
			$('#noneAbove').css('display','block');
		}else if(name == 'chooseAnsType' && res != 'smart'){
			$('#noneAbove').css('display','none');
		}
		
		
		if(name == 'chooseAnsType' && res == 'weighted'){
			$('#weighted').css('display','block');
			$('#answerDiv').css('display','none')
			
		}else if(name == 'chooseAnsType' && res != 'weighted'){
			$('#weighted').css('display','none');
		}
		
		if(name == 'chooseAnsType' && res == 'basicgrid'){
			$('#basicgrid').css('display','block');
			$('#answerDiv').css('display','none')
			$('#qtext').css('display','none')
		}else if(name == 'chooseAnsType' && res != 'basicgrid'){
			$('#basicgrid').css('display','none');
			$('#qtext').css('display','block');
		}
		
		if(name == 'numberOfColumn'){
			$('.column-input').css('display','none');
			$('.dropdown-col3').css('display','none');
			for(let i = 1; i <= res; i++){
				$('.column-'+i).css('display','block');
			}
			if(res == 3){
				$('.dropdown-col3').css('display','block');
			}
		}
		
		

		if(name == 'PFB' && res == 'Y'){
			this.setState({showCompany:false});
		}
		
		let that = this;
		if(name == 'validationType' && (res == 'V3' || res == 'V4' || res == 'V5' || res == 'V6' || res == 'V9' || res == 'V11'  || res == 'V12' || res == 'V13')){
			let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).split(',')[0];
			that.setState({showMinMax:true});
			
			setTimeout(function(){
				if(res == 'V9'){
					$('.min-val').val('01/01/1920');
					$('.max-val').val(date);
					that.setState({minValue:'01/01/1920',maxValue:date});
				}else if(res == 'V11'){
					$('.max-val').val('01/01/2050');
					$('.min-val').val(date);
					that.setState({maxValue:'01/01/2050',minValue:date});
				}else if(res == 'V12' || res == 'V13'){
					$('.max-val').val('00:00:00');
					$('.min-val').val('00:00:00');
					that.setState({maxValue:'00:00:00',minValue:'00:00:00'});
				/* }else if(res == 'V13'){
					$('.max-val').val('00:0000');
					$('.min-val').val('00:0000');
					that.setState({maxValue:'00:0000',minValue:'00:0000'}); */
				}else{
					$('.max-val').val('');
					$('.min-val').val('');
				}
			}, 2000);
			
		}else if(name == 'validationType' && (res == 'V1' || res == 'V2' || res == 'V7' || res == 'V8' || res == 'V10')){
			this.setState({showMinMax:false});
			that.setState({maxValue:'',minValue:''});
		}
    }
	
	calculationFields(allData){
		
		let validationTypefilter = this.state.validationTypefilter;
		
		return (<div>
			<table className="table table-bordered">
			{allData.map((comment,i) =>{
				if((validationTypefilter && comment[48] == validationTypefilter) || (!validationTypefilter && (comment[48]== 'V3' || comment[48]== 'V4' || comment[48]== 'V5' || comment[48]== 'V6' || comment[48]== 'V7' || comment[48]== 'V8' || comment[48]== 'V12' || comment[48]== 'V13')) ){
				
					return (<tr >
						<td><input name={comment[0]} type="checkbox" onChange={this.handleCalItem} /></td>
						<td>{comment[0]}</td>
						<td>{comment[4]}</td>
						<td>{comment[48]}</td>
					</tr>)
				}
			}   
			)}
			</table>
		</div>);
		
		
	}
	
	wcalculationFields(allData){
		
		return (<div>
			<table className="table table-bordered">
			{allData.map((comment,i) =>{
				if(comment[5] == 'weighted'){
					return (<tr >
						<td><input name={comment[5]} value={comment[0]} type="Radio" onChange={this.handleCalItemWeight} /></td>
						<td>{comment[0]}</td>
						<td>{comment[4]}</td>
					</tr>)
				}
			}   
			)}
			</table>
		</div>);
	}
	
	handleCalItem = (e) => {
        const item = e.target.name;
        let isChecked = e.target.checked;
		let calFieldsId = this.state.calFieldsId;
		if(isChecked == true){
			calFieldsId.set(item, item);
		}else{
			calFieldsId.delete(item);
		}
		
		this.setState({calFieldsId:calFieldsId});
    }
	
	handleCalItemWeight = (e) => {
        const item = e.target.value;
        let isChecked = e.target.checked;
		let calFieldsId = this.state.calFieldsId;
		if(isChecked == true){
			calFieldsId.set(item, item);
		}else{
			calFieldsId.delete(item);
		}
		
		this.setState({calFieldsId:calFieldsId});
    }
	
    onEditorStateChange=(editorState)=>{
        var convertedData = editorState.getCurrentContent()
        let d =  convertToRaw(editorState.getCurrentContent()) 
        let editorSourceHTML = draftToHtml(d)
        this.setState({
            editorState,editorSourceHTML
        });
    }
    
	_uploadImageCallBack=(file)=>{
        let uploadedImages = this.state.uploadedImages;

		const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        uploadedImages.push(imageObject);
        this.setState({uploadedImages: uploadedImages})
		
		let con = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'multipart/form-data'
			}
		}
		
		let url = config.noteImageApiUrl;
		return new Promise(
		(resolve, reject) => {
			var form_data = new FormData();
			form_data.append('file', file);
			post(url, form_data, con)
			.then(response => {
				
				resolve({ data: { link: response.data } });
			})
		});				
    }
	
    handleChange=(event)=>{
        let name = event.target.value;
        this.setState({queAction:event.target.value});
        this.setState({ChangeNotes:false,ChangeTags:false,changeAnsOpt:false,changeQueText:false,changeOutputText:false,changeValiType:false,changeQueType:false,ChangeCompany:false, ChangeTracking:false});
        this.setState({[name]:true});
    }
	
	handleClearNote=()=>{
		this.setState({editorState:'',editorSourceHTML:''});
    }
    
	checkKey2(elements, parentId = '',ids ,pId, cId) {
        var branch1 = [];
        { elements.map((element,i) => {
            if(element[5] == 'Parent Header'){
                element[pId] = '';
            }  
            if (element[pId].toLowerCase() == parentId) {
                if(element[cId] == ''){
                    element[cId]='a';
                }
                var children1 = this.checkKey2(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children1) {
                    element['sub2'] = children1; 
                }
                if(!ids.includes(element['0'])){
                    branch1.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch1;    
    }
    
	showPreview=()=>{
		let configDataRow =[];
        let kioskUsers = this.state.kioskUsers
        let data = this.props.allData;
        let sampleData = this.deleteData(data);
        let mainData =[];
		let sOptions = this.state.selOptions;
        sampleData.map((rowDatas,i)=>{
            let key = rowDatas[10]
            if(rowDatas[5] == 'Parent Header'){
                mainData.push(rowDatas);
            }

			let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
            if(typeof columnName !== 'undefined' && columnName !== ''){
                configDataRow = columnName.split(',');
                configDataRow.splice(0, 9);
            }
			
            if(this.state.checkedKiosk.get(rowDatas[0]) == true && rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                mainData.push(rowDatas);
            }else if(this.state.checkedKiosk.get(rowDatas[0]) == true && configDataRow.indexOf(kioskUsers[0]) != -1){
				
				if(sOptions == 'include_only'){
					if(this.state.checkedInc.get(rowDatas[0]) == true){
						mainData.push(rowDatas);
					}
				}else if(sOptions == 'include_required'){
					if(this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == true){
						mainData.push(rowDatas);
					}
				}else if(sOptions == 'include_not_required'){
					if(this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == false){
						mainData.push(rowDatas);
					}
				}else{
					mainData.push(rowDatas);
				}
            }
					
        })
        let ids =[];
        var dbArray = this.checkKey2(mainData, '', ids, 2, 1);
		let db3 =[];
		dbArray.map((rowDatas,i)=>{
			if( rowDatas['sub2'].length != 0 ){
				db3.push(rowDatas);
			}
		})
        
		this.setState({kioskPreviewData:db3});
        if(kioskUsers.toString() == 'PhT'){
            $('.kioskPreview').css('backgroundColor','#FFCCE5')
        }else if(kioskUsers.toString() == 'CPO'){
            $('.kioskPreview').css('backgroundColor','#FFFFFF')
        }else if(kioskUsers.toString() == 'Int' || kioskUsers.toString() == 'Res' || kioskUsers.toString() == 'PrA' || kioskUsers.toString() == 'PA' ){
            $('.kioskPreview').css('backgroundColor','#B2FF66')
        }else if(kioskUsers.toString() == 'PhA' || kioskUsers.toString() == 'PmD'){
             $('.kioskPreview').css('backgroundColor','#DCDCDC')
        }else if(kioskUsers.toString() == 'Pat'){
             $('.kioskPreview').css('backgroundColor','#ccf5ff')
        }else if(kioskUsers.toString() == 'CC'){
            $('.kioskPreview').css('backgroundColor','#FFFF99')
        }
        $('#selectUsersType').modal('show')   	
    }
	
	checkPreCondition(item, items, type) {
		let that = this;
		$("."+item).find("."+type).each(function() {
			let name = $(this).attr('name')
			items.push(name);
			that.checkPreCondition(name, items, type);
		});
		return items;
    }
    
	showAddUsersModal=(id)=>{
        let allDatas = this.props.allData;
        var res = ''
        let posData
        allDatas.map((rowDatas, i) => {
            if(rowDatas[0] == id){
                if(typeof this.state.kioskUsersData.get(rowDatas[0]) !== 'undefined'){
                    posData=this.state.kioskUsersData.get(rowDatas[0]);
                }else if(this.props.selectedOpt == 'original'){
                    posData = rowDatas[16];
                }else{
                    posData = rowDatas[34] ? rowDatas[34] : rowDatas[16];
                } 
                if(typeof posData !== 'undefined' && posData != ''){
                    res = posData.split(',');
                } 
            }
        })

        if(res !== ''){
            var array = res.slice(-9);
            this.setState({kioskUsers:array})
        }else{
            this.setState({kioskUsers:['CPO']})
        }
        $('#kiosk'+id).modal('show')
    }
    
	handleInclude = (e) => {
		
		let item = e.target.name;
        this.props.allData.map((row)=>{
            if(item == row[7]){
				$('#include'+item).modal('show');
            }
        })
        let isChecked = e.target.checked;
        
        let checkedInc = this.state.checkedInc;
        let checkedReq = this.state.checkedReq;
        let elm = document.getElementById(item);
		
		let updatedFields = this.state.updatedFields;
		updatedFields.set(item, true)
		
		let type = 'inc';
		if(isChecked == true){
			let items = [];
			items = this.checkPreCondition(item, items, type);
			
			items.map((item)=>{
				$("#"+item).find(".inc").attr( 'checked', true )
				checkedInc.set(item, isChecked)

			})
			
        }else{
           let items = [];
			items = this.checkPreCondition(item, items, type);
			items.map((item)=>{
				$("#"+item).find(".inc").attr( 'checked', false )
				checkedInc.set(item, isChecked)

			})
        }
		
        let checkboxs = $('#'+item).find("input[type='checkbox']");
        if(isChecked == false){
            $.each( checkboxs, function( i,value ) {
                checkedInc.set($(this).attr('name'), isChecked)
                checkedReq.set($(this).attr('name'), isChecked)
            });
            checkedInc.set(item, isChecked)
            checkedReq.set(item, isChecked)
        }else{
            $.each( checkboxs, function( i,value ) {
                if(checkboxs.length == 3){
                    if(i == 1){
                        checkedInc.set($(this).attr('name'), isChecked)
                    }
                }
            });
            checkedInc.set(item, isChecked)
        }
        let x = 0;
        do {
            if(elm){
                let parentNode = elm.parentNode;
                
                if(parentNode.getAttribute('data-header') == 1 || parentNode.getAttribute('data-header') == 2 || parentNode.getAttribute('data-header') == 3){
                    let name = parentNode.getAttribute('id');
                    var checkedbox = false;
                    $('.inner-'+name).find('.inc:checked').each(function() {
						if($(this).is(":visible")){
							checkedbox= true;
						}
                    });
					
                    if(checkedbox == false){
                        $('input[type=checkbox][name='+name+']').prop('checked', false);
                    }
                    
                    if(checkedbox == false || isChecked == true){
                        checkedInc.set(name, isChecked)
                    }
                }
                if(parentNode.className.indexOf('parent-header') != -1){
                    x = 5; 
                }
                elm = parentNode;  
            }
            x++;
        }
        while(x < 5);
		
		this.setState({checkedInc: checkedInc});       
        this.setState({checkedReq: checkedReq});
    }
    
	handleKiosk = (e) => {
        let item = e.target.name;
        let isChecked = e.target.checked;
		let checkedKiosk = this.state.checkedKiosk;
		
		let updatedFields = this.state.updatedFields;
		updatedFields.set(item, true)
		
		checkedKiosk.set(item, isChecked)
		let type = 'kiosk';
		if(isChecked == true){
            $('#userBtnId'+item).css("display", "block"); 
			let items = [];
			items = this.checkPreCondition(item, items, type);
			items.map((item)=>{
				$("#"+item).find(".kiosk").attr( 'checked', true )
				checkedKiosk.set(item, isChecked)
			})
        }else{
           
           let items = [];
			items = this.checkPreCondition(item, items, type);
			items.map((item)=>{
				$("#"+item).find(".kiosk").attr( 'checked', false )
				checkedKiosk.set(item, isChecked)

			})
        }
        let elm = document.getElementById(item);
		let x = 0;
        do {
            if(elm){
                let parentNode = elm.parentNode;
                
                if(parentNode.getAttribute('data-header') == 1 || parentNode.getAttribute('data-header') == 2 || parentNode.getAttribute('data-header') == 3){
                    let name = parentNode.getAttribute('id');
                    var checkedbox = false;
                    $('.inner-'+name).find('.kiosk:checked').each(function() {
                        if($(this).is(":visible")){
                            checkedbox= true;
                        }
                    });
                    
                    if(checkedbox == false){
                        $('input[type=checkbox][name='+name+']').prop('checked', false);
                    }
                    
                    if(checkedbox == false || isChecked == true){
                        checkedKiosk.set(name, isChecked)
						$('#userBtnId'+name).css("display", "inline-block");
                    }
                }
                if(parentNode.className.indexOf('parent-header') != -1){
                    x = 5; 
                }
                elm = parentNode;  
            }
            x++;
        }
        while(x < 5);
        this.setState({checkedKiosk: checkedKiosk});
    }
    
	handleInsertField=(e)=>{
		
        $("#saveBtn").prop('disabled', false);
		
		let placeId = '';
		let isChecked = e.target.checked;
		if(isChecked == true){
			this.setState({placeId: e.target.name,editorState:''});
			this.setState({placePos:true,headerSection:false});
			/* $(function(){
				//$(".addNewQuestions").trigger('click');
				
			}); */
			this.addNewField();
		}else{
			this.setState({placeId: ''});
		}
    }
	
	addNewField =()=>{
		let placeId = this.state.placeId;
		if(placeId){
			this.setState({addDataDiv:true,headerSection:false,changeQueInpText:'',headerDiv:false,titleDiv:false,textDiv:false,questionDiv:false });
		}else{
			this.setState({addDataDiv:true,headerSection:true,changeQueInpText:'',headerDiv:false,titleDiv:false,textDiv:false,questionDiv:false });
		}
	}
	
	definePlace=(event)=>{
		let place = event.target.value;
		this.setState({place});
    }
    
	handleRequire = (e) => {
        let item = e.target.name;
        let isChecked = e.target.checked;
        let checkedInc = this.state.checkedInc;
        let checkedReq = this.state.checkedReq;
        let elm = document.getElementById(item);
		
		let updatedFields = this.state.updatedFields;
		updatedFields.set(item, true)
		
        if(isChecked == true){
            checkedInc.set(item, isChecked)
            checkedReq.set(item, isChecked)
        }else{
            checkedReq.set(item, isChecked)
        }
        
        let x = 0;
        do {
            if(elm){
                let parentNode = elm.parentNode;
                
                if(parentNode.getAttribute('data-header') == 1 || parentNode.getAttribute('data-header') == 2 || parentNode.getAttribute('data-header') == 3){
                    let name = parentNode.getAttribute('id');
                    var checkedbox = false;
                    
                    $('.inner-'+name).find('.inc:checked').each(function() {
                        checkedbox= true;
                    });
                    if(checkedbox == false){
                        $('input[type=checkbox][name='+name+']').prop('checked', false);
                    }
                    
                    if(checkedbox == false || isChecked == true){
                        checkedInc.set(name, isChecked)
                    }
                }
                if(parentNode.className.indexOf('parent-header') != -1){
                    x = 5; 
                }
                elm = parentNode;  
            }
            x++;
        }
        while(x < 5);
        this.setState({checkedInc: checkedInc});       
        this.setState({checkedReq: checkedReq});      
    }
    
	findHeader(data, type){
        let max_level = (type == 'Child Header 1') ? 1 : 2;
        let parent_data = [];
        let i = 0;
        do {
            data.forEach(function(item){
                if(item[5] == type){
                    if(parent_data.length == 0){
                        parent_data = item;
                    }
                    max_level = 1;
                }
            });

            if(type == 'Child Header 3'){
                type = 'Child Header 2';
            }
            else if(type == 'Child Header 2'){
                type = 'Child Header 1';
            }
            i++;
        }
        while(i < max_level);
        return parent_data;
    }
	
	/* lastHeader(data){
        let i = 0;
		let lastH = '';
		data.forEach(function(item){
			if((item[5] == 'Child Header 1' || item[5] ==  'Child Header 2' || item[5] ==  'Child Header 3') && i < 1){
				lastH = item[1];
				i++;
			}
		});
		return lastH;
    } */
	
	findChild(data, obj){
        let ob = {id:data[0], position:$('#'+data[0]).attr('data-pos'), pId:data[2], header:data[5]};
        
        obj.innerData.push(ob);
        if(data.sub){
            for(var i in data.sub){
                this.findChild(data.sub[i], obj);
            }
        }   
    }
	
    saveNewData=()=>{
		
		let optionType = this.state.chooseAnsType;
		if(!optionType){
			alert('Please select header or field type!');
			return false;
		}
		
		$("#saveBtn").prop('disabled', true);
		let PFB = 'N';
		let validationType = 'V1';

		let calculationType = this.state.calculationType;
		
		let companyName = 'All';
		if(this.state.companyName){
			companyName = this.state.companyName;
		}
		
		let trackingCodeName = '';
		if(this.state.trackingCodeName){
			trackingCodeName = this.state.trackingCodeName;
		}
		
		
		let customFieldNote = this.state.customFieldNote;
		let calFieldsId = this.state.calFieldsId;
		
		if(calFieldsId){
			let calFields = Object.fromEntries(calFieldsId.entries());
			
			let calArr = [];
			for (let prop in calFields) {
				calArr.push(calFields[prop]);
			}
			calFieldsId = calArr.join();
		}
		
		if(this.state.validationType){
			validationType = this.state.validationType;
		}
		let minValue = this.state.minValue;
		let maxValue = this.state.maxValue;
		
		if(this.state.PFB){
			PFB = this.state.PFB;
		}
		//let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		
		let allData = this.props.allData;
		
		//Insert new field start
        let changeAnsInpText='';
        let position = '';
        let parentId='';
        let parentGuid='';
		let condition = true;
		let fieldID = '';
		let field_string ='';
		let lastRow ='';
		let rowNo = ''
		let field = '';
		let field_string1 = '';
		let queLastRow='';
		let su = ''
		let last_group = [];
		let last_group1 = [];
		let last_group2 = [];
		let last_group3 = [];
		let intStr1 = '';
		let unit = '';
		let mds_guids = allData[0][91];
		let mds_name = allData[0][118];
		let guids = guid(); 
		let guids_dn = guid();
		let labelValue = '';
		let numberOfColumn = '';
		let numberOfRow = '';
		let arraignment = '';
        if(this.state.placeId){
			
            this.props.allData.map((rowData)=>{
                if(rowData[0] == this.state.placeId){
					let obj = {innerData:[]}
					if(this.state.add_text == 'child_header'){
						if(rowData[5] != 'Child Header 1' && rowData[5] != 'Child Header 2' && rowData[5] != 'Child Header 3'){
							alert('In order to add additional Child headers, you can only insert after another header.  Select an existing Header, add new Header, then move questions into correct position using the Format Forms functionality later.');
							condition = false;
						}else if(rowData[5] == optionType){
							this.findChild(rowData, obj);
							let innerData = obj.innerData;
							parentId = rowData[2];
							parentGuid = rowData[11];
							position = Number(rowData[10])+innerData.length;
							
						}else{
							parentId = rowData[1];
							parentGuid = rowData[117];
						    position = Number(rowData[10])+1;
						}
						
					}else{
						if(rowData[5] == 'Child Header 1' || rowData[5] == 'Child Header 2' || rowData[5] == 'Child Header 3'){
							parentId = rowData[1];
							parentGuid = rowData[117];
							position = Number(rowData[10])+1;
						}else{
							parentId = rowData[2];
							parentGuid = rowData[11];
							position = Number(rowData[10])+1;
						}
					}						
                }
            })
			
			let newObj = JSON.parse(JSON.stringify(allData));
			
			let data = newObj.reverse();
			
			for(let i=0; i < data.length; i++){    
				last_group1.push(data[i]);
				if(data[i][5] !== 'Child Header 1' && data[i][5] !== 'Child Header 2' && data[i][5] !== 'Child Header 3' && data[i][5] !== 'Title' && data[i][5] !== 'Text'){
					break;
				}
			}
			for(let i=0; i < data.length; i++){    
				last_group.push(data[i]);
				if(data[i][5] == 'Child Header 1'){
					break;
				}
			}
			
			let newParent = parentId.replace(/[0-9]/g, "");
			
			for(let i=0; i < data.length; i++){
				if(data[i][1].includes(newParent) || data[i][2].includes(newParent)){
					last_group2.push(data[i]);
				}
				if(data[i][5] == 'Parent Header' && data[i][1] == parentId){
					break;
				}
			}
			
			for(let i=0; i < data.length; i++){
				if(data[i][1].includes(newParent) || data[i][2].includes(newParent)){
					last_group3.push(data[i]);
				}
				if(data[i][5] == 'Child Header 1' && data[i][2] == parentId){
					break;
				}
			}
			
			let firstRow = last_group[0];
			
			queLastRow = last_group1[last_group1.length-1];
			//lastRow = last_group[last_group.length-1];
			if(this.state.add_text == 'child_header'){
				let parentRow = this.findHeader(last_group3, optionType);
				let parentRow1 = this.findHeader(last_group2, optionType);
				if(parentRow[1]){
					let intStr1 = parentRow[1].replace(/[A-Za-z$-]/g, "");
					if(intStr1 == ''){
						intStr1 = 1;
					}
					field = (parseInt(intStr1) + 1);
					field_string  =parentRow[1].replace(/[0-9]/g, "");
				}
				if(optionType == "Child Header 2"){
					if(parentRow1[5] =="Child Header 2"){
						let intStr1 = parentRow1[1].replace(/[A-Za-z$-]/g, "");
						if(intStr1 == ''){
							intStr1 = 1;
						}
						field = (parseInt(intStr1) + 1);
						field_string  =parentRow1[1].replace(/[0-9]/g, ""); 
					}else{
						field = '';
						field_string1  =parentRow[1].replace(/[0-9]/g, "");
						field_string  = field_string1+"c1";   
					}
				}else if(optionType =="Child Header 3"){
					if(parentRow1[5] =="Child Header 3"){
						let intStr1 = parentRow1[1].replace(/[A-Za-z$-]/g, "");
						if(intStr1 == ''){
							intStr1 = 1;
						}
						field = (parseInt(intStr1) + 1);
						field_string  =parentRow1[1].replace(/[0-9]/g, ""); 
					}else if(parentRow1[5] == "Child Header 2"){
						field = '';
						field_string1  =parentRow[1].replace(/[0-9]/g, "");
						field_string  = field_string1+"c1";
					}else{
						return false;
					}
				}
						   
			}else if(this.state.add_text == 'title'){
				optionType= 'Title';
				field ='t';
				let intStr1 = 1;
				if(firstRow[3] ==""){
					intStr1 = queLastRow[3].replace(/[A-Za-z$-]/g, "");
				}else{
				    intStr1 = firstRow[3].replace(/[A-Za-z$-]/g, "");
				}
				if(intStr1 == ''){
					intStr1 = 1;
				}
				su = 'u'+(parseInt(intStr1) + 1); 
			}else if(this.state.add_text == 'text'){
				optionType= 'Text';
				field ='txt';
				let intStr1 = '';
				if(firstRow[3] ==""){
					intStr1 = queLastRow[3].replace(/[A-Za-z$-]/g, "");
				}else{
				    intStr1 = firstRow[3].replace(/[A-Za-z$-]/g, "");
				}
				if(intStr1 == ''){
					intStr1 = 1;
				}
				su = 'u'+(parseInt(intStr1) + 1);				
			}else if(this.state.add_text == 'add_question'){
				optionType= this.state.chooseAnsType;
				
				let intStr1 = queLastRow[1].replace(/[A-Za-z$-]/g, "");
				if(intStr1 == ''){
					intStr1 = 1;
				}
				field = 'f'+(parseInt(intStr1) + 1);
				if(firstRow[3] ==""){
					intStr1 = queLastRow[3].replace(/[A-Za-z$-]/g, "");
				}else{
				    intStr1 = firstRow[3].replace(/[A-Za-z$-]/g, "");
				}
				if(intStr1 == ''){
					intStr1 = 1;
				}
				su = 'u'+(parseInt(intStr1) + 1); 
			}
			
			let id = firstRow[0].split('-');
			let newId = Number(id[1]) + 1;
			let ttevalId = id[0]+'-'+newId;
			//console.log('ttevalId->',ttevalId);
			if(ttevalId){
				//let spreadsheetID = config.spreadsheetId;
				let spreadsheetID = this.props.sheetConfigId;

				let userTypes = this.state.kioskUsersData.get(id)
				if(userTypes == undefined){
					userTypes = ',,,,,,,CPO,,';
				}
				var PosId =  ttevalId+','+guids+','+parentId+','+ '' +','+ '' +','+ 'N' +','+ 'N' +','+ position+','+ 'N'+ ','+ userTypes;
				var tagId = ttevalId+'-'+guids;
				fieldID = field_string+field;
				let path = '';
				if (optionType == 'image') {
					path = this.state.imagePath;
				}
				if(optionType == 'file'){
					path = this.state.filePath;
				}
				if(optionType == 'inlineVideo'){
					path = this.state.inlineVideo;
				}
				
				changeAnsInpText = this.state.changeAnsInpText;
				
				if(optionType == 'slider'){
					labelValue = this.state.sliderMinText+', '+this.state.sliderMaxText;
				}
				
				let changeOutInpText = this.state.changeOutInpText ? this.state.changeOutInpText:'';
				
				if(optionType == 'grid5point'){
					
					let sacale = [this.state.sacale1,this.state.sacale2,this.state.sacale3,this.state.sacale4,this.state.sacale5];
					let gridRows = [this.state.row1,this.state.row2,this.state.row3,this.state.row4,this.state.row5];

					sacale = sacale.filter(function(x) {
						 return x !== undefined;
					});
					gridRows = gridRows.filter(function(x) {
						 return x !== undefined;
					});
					numberOfRow = this.state.numberRows;
					let sacaleStr = sacale.join();
					let rowaStr = gridRows.join();
					labelValue = sacaleStr+'_'+rowaStr
				}
				
				if(optionType == 'likert'){
					
					let sacale = [this.state.lowestText,this.state.highestText];
					let gridRows = [this.state.row1,this.state.row2,this.state.row3,this.state.row4,this.state.row5];
				
				
					sacale = sacale.filter(function(x) {
						 return x !== undefined;
					});
					gridRows = gridRows.filter(function(x) {
						 return x !== undefined;
					});
					numberOfRow = this.state.numberRows;
					let sacaleStr = sacale.join();
					let rowaStr = gridRows.join();
					labelValue = sacaleStr+'_'+rowaStr
				}
				
				if(optionType == 'smart'){
					if(this.state.noneAbove != ''){
						changeAnsInpText = this.state.changeAnsInpText+','+this.state.noneAbove;
					}
					
				}
				
				if(optionType == 'weighted'){
					arraignment = this.state.arraignment;
					let state = this.state;
					let optionRows = [state.row1+':'+state.weight1 , state.row2+':'+state.weight2,state.row3+':'+state.weight3 , state.row4+':'+state.weight4,state.row5+':'+state.weight5 , state.row6+':'+state.weight6,state.row7+':'+state.weight7 , state.row8+':'+state.weight8,state.row9+':'+state.weight9 , state.row10+':'+state.weight10];
					
					optionRows = optionRows.filter(function(x) {
						 return x !== 'undefined:undefined';
					});
					labelValue = optionRows.join();
					numberOfRow = this.state.numberRows;
					numberOfColumn = this.state.numberOfGrid;
				}
				
				if(optionType == 'gridcheck'){
					numberOfColumn = this.state.numberRows;
				}
				
				if(optionType == 'basicgrid'){
					let optionRows = [this.state.R1,this.state.R2,this.state.R3,this.state.R4,this.state.R5,this.state.R6,this.state.R7,this.state.R8,this.state.R9,this.state.R10,this.state.R11,this.state.R12,this.state.R13,this.state.R14,this.state.R15];
					optionRows = optionRows.filter(function(x) {
						 return x !== undefined;
					});
					let rowaStr = optionRows.join();
					
					let optionColumns = [this.state.C1];
					let columnsVali = ['C1:'+this.state.validation1];
					if(this.state.C2){
						optionColumns.push(this.state.C2);
						columnsVali.push('C2:'+this.state.validation2);
						
					}
					if(this.state.C3){
						optionColumns.push(this.state.C3);
						columnsVali.push('C3:'+this.state.validation3);
					}
					
					validationType = columnsVali.join();

					optionColumns = optionColumns.filter(function(x) {
						 return x !== 'undefined:undefined';
					});
					
					let columnStr = optionColumns.join();
					
					labelValue = columnStr+'_'+rowaStr
					
					numberOfColumn = this.state.numberOfColumn;
					numberOfRow = this.state.numberRows;
				}
				
				if(optionType == 'grid-dropdown'){
					numberOfColumn = 2;
					let optionColumns = [this.state.C1,this.state.C2];
					if(this.state.numberOfColumn){
						numberOfColumn = this.state.numberOfColumn;
					}
					if(this.state.C3 && numberOfColumn == 3){
						optionColumns.push(this.state.C3);
					}
					optionColumns = optionColumns.filter(function(x) {
						 return x !== 'undefined';
					});
					let columnStr = optionColumns.join();
					
					let optionRows = [this.state.R1C1+':'+this.state.R1C2,this.state.R2C1+':'+this.state.R2C2,this.state.R3C1+':'+this.state.R3C2];
					
					if(numberOfColumn == 3){
						optionRows = [this.state.R1C1+':'+this.state.R1C2+':'+this.state.R1C3,this.state.R2C1+':'+this.state.R2C2+':'+this.state.R2C3,this.state.R3C1+':'+this.state.R3C2+':'+this.state.R3C3];
					}
					

					optionRows = optionRows.filter(function(x) {
						return x !== 'undefined:undefined:undefined';
					});
					
					numberOfRow = this.state.numberRows;
					let rows = [];
					optionRows.map((row, i) => { 
						if(i < numberOfRow){
							rows.push(row);
						}
					});
					let rowaStr = rows.join('|');
					
					
					labelValue = columnStr+'_'+rowaStr
					
				}
				
				var params = {
					spreadsheetId:  spreadsheetID,
					range:this.props.selectedSheet+'!A'+(rowNo+3)+':DO'+(rowNo+3), 
					valueInputOption: "RAW", 
					insertDataOption: "INSERT_ROWS",
					resource:{
						"values": 
						[
							[ ttevalId,fieldID,parentId,su,this.state.changeQueInpText,optionType,changeAnsInpText,'','',changeOutInpText,position,parentGuid,this.state.editorSourceHTML,this.state.addTagsText,'Y','N',PosId,'','','','','','','','','','','','','','','','Y','N','',guids,'','N','',tagId,'',labelValue,'','','','','','',validationType,unit,minValue,maxValue,'','','','','','','','','','','','','','','','','','','','','','','','','','',this.state.websiteLink,'','','','','','','','','','','','',mds_guids,'','','','','','',currentDate,path,'','','','','','','','','','','',PFB,'',calculationType,calFieldsId,companyName,customFieldNote,guids_dn,mds_name,'','','','','','',trackingCodeName,'','','','','','','','','','',numberOfColumn,numberOfRow,arraignment ]
						],
						"majorDimension": "ROWS"
					  }
				};

				var that = this;
				if(condition == true){
					var placeId = that.state.placeId
					var request = gapi.client.sheets.spreadsheets.values.append(params);
					request.then(function(response) {
						if(placeId && parentId){

							let rowId = '';
							let updateData = [];
							{ allData.map((val, i) => {
								rowId = (i+2);
								if(val[10] >= position ){
									let position2 = Number(val[10])+1;
									
									let positionConfig = val[16].split(',');
									
									positionConfig[7] = position2;
									let newPos = positionConfig.join();
									
									let row1 = {
									  range: that.props.selectedSheet+'!K'+rowId,
									  values: [
										[position2]
									  ]
									};
									
									let row2 = {
									  range: that.props.selectedSheet+'!Q'+rowId,
									  values: [
										[newPos]
									  ]
									};
									
									updateData.push(row1);
									updateData.push(row2);
								}
							})};
							
							let lastUpDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
							let totalFields = allData.length+1;
							let mdsJson = 'MDS_'+ mds_name +'_'+lastUpDate+'_'+totalFields;
							let row3 = {
								range: that.props.selectedSheet+'!DT2',
								values: 
								[
									[ mdsJson ]
								]
							};
							updateData.push(row3);
							
							let accessToken = gapi.auth.getToken().access_token;
							let params = {
								access_token:accessToken,
								spreadsheetId: spreadsheetID,
							};
							let batchUpdateValuesRequestBody = {
								valueInputOption: 'USER_ENTERED',
								data: updateData
							};
							let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
							request.then(function(response) {
								let reviewStatus = '';
								if(that.props.selDropOpt == "original"){
									reviewStatus = 'improved';
									that.props.selectReviewOpt(reviewStatus, that.props.selectedSheetId, that.props.selectedSheet, ttevalId);
								}else{
									that.props.updatedData(that.props.selectedSheet,ttevalId);
								}

								setTimeout(function(){
									that.setDefaultCheckboxes('IncAll');
								}, 6000);
								that.setState({placeId:''});						
							}, function(reason) {
								alert(reason.result.error.message);
							});
						}
						
					}, function(reason) {
					console.error('error: ' + reason.result.error.message);
					});
					this.setState({placeId:'',changeQueInpText:'',changeOutInpText:'',websiteLink:'',chooseAnsType:'',changeAnsInpText:'',editorSourceHTML:'',addTagsText:'',addNoteText:'',imagePath:'',inlineVideo:'',editorState:'',labelValue:'',})
				}
			
			}
			
			this.setState({selOptions:"view_all"});
			this.setState({addDataDiv:false,add_text:'',addTagsText:'',placePos:false,headerSection:true});
			
		}else{
			alert('Please select the place for the field!');
		}
		
		this.setState({showMinMax:false,validationType:'',minValue:'',maxValue:'',PFB:'',calculationType:'', companyName:'', trackingCodeName:'',customFieldNote:'',newCompanyName:'',newTrackingCode:'',numberOfColumn:''});
		this.setState({calFieldsId:new Map()});
		
		this.setState({buttonLabel:'',sacale1:'',sacale2:'',sacale3:'',sacale4:'',sacale5:'',numberRows:'1',numberOfGrid:'',arraignment:'provided',lowestText:'',highestText:'',validation1:'V1',validation2:'V1',validation3:'V1'});
		for(let i = 1; i <= 20; i++){
			let variable = 'row'+i;
			let variable2 = 'C'+i;
			let variable3 = 'R'+i;
			let variable4 = 'weight'+i;
			let variable5 = 'R1C'+i;
			let variable6 = 'R2C'+i;
			let variable7 = 'R3C'+i;
			that.setState({[variable]:'',[variable2]:'',[variable3]:'',[variable4]:'',[variable5]:'',[variable6]:'',[variable7]:''});
		}
    }
    
	showAllQues=()=>{
		if($("input[name='showQ']").prop("checked") == true){
            $('.pre-question').collapse('show');
			$('.pre-question').removeClass('not-include');
		}else{
            $('.pre-question').addClass('not-include');
			$('.pre-question').collapse('hide');
		}
    }
    
	saveProgData=()=>{

        let accessToken = gapi.auth.getToken().access_token;
        //let spreadsheetID = config.spreadsheetId;
        let spreadsheetID = this.props.sheetConfigId;
        
        let params1 =  {
            spreadsheetId: spreadsheetID,
            range:this.props.selectedSheet,
        };
        let allDatas = this.props.allData;
		
        let includeArr = this.state.checkedInc;
        let requiredArr = this.state.checkedReq;
        let kioskArr = this.state.checkedKiosk;
        let updatedFields = this.state.updatedFields;
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        let updateData = [];
        let allData = [];
        let rowId='';
        let guids = '';
		
        for (let i in allDatas) {
            
            let req ='N';
            let inc = 'N';
            let kiosk ='N';
            if(requiredArr.get(allDatas[i][0]) == true){
                req = 'Y';
                inc = 'Y';
            }else if(includeArr.get(allDatas[i][0]) == true){
                inc = 'Y';
            }
			
            if(kioskArr.get(allDatas[i][0]) == true){
                kiosk = 'Y';
            }
            let rowDatas = allDatas[i];
            let userTypes = this.state.kioskUsersData.get(rowDatas[0])
			
            if(userTypes == undefined){
				let oldP = rowDatas[16];
				let userArray = oldP.split(',');
				userArray.splice(0, 9);
				userTypes = userArray.toString();
            }
			
			if(rowDatas[5].toLowerCase() == 'boxbegin' || rowDatas[5].toLowerCase() == 'boxend'){
				inc = 'Y';
			}
            
			let guids = rowDatas[35] ? rowDatas[35] : rowDatas[91];
			
            rowId = parseInt(i) + 2;
			
			var tagId = rowDatas[0]+'-'+guids;
			
			let parentGuid = '';
			allDatas.map((pre_field,i)=>{
				if(pre_field[1].toLowerCase() == rowDatas[2].toLowerCase()){
					parentGuid = pre_field[117];
				}
			})
			
			let row1 = {
                "range": this.props.selectedSheet+'!AG'+rowId+":AI"+rowId,   
                "majorDimension": "ROWS",
                "values": 
                [
                    [ inc,req,rowDatas[0] + ','+guids+','+rowDatas[2] +','+ rowDatas[7] +','+ rowDatas[8] +','+ inc +','+ req +','+ rowDatas[10] +','+ kiosk+','+userTypes]
                ]
            };
            let row2 = {
                "range": this.props.selectedSheet+'!AL'+rowId+":AN"+rowId,
                "majorDimension": "ROWS",
                "values": 
                [
                    [kiosk,'',tagId]
                ]
            };
            let row3 = {
                "range": this.props.selectedSheet+'!L'+rowId,
                "majorDimension": "ROWS",
                "values": 
                [
                    [parentGuid]
                ]
            };
			
			updateData.push(row1) 
            updateData.push(row2) 
            updateData.push(row3)
			
			if(updatedFields.get(allDatas[i][0]) == true){
                let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
				let row4 = {
					range: this.props.selectedSheet+'!CU'+rowId, 
					values: [
						[ currentDate ]
					]
				};
				updateData.push(row4) 
            }
 
        }
		
		let lastUpDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		let totalFields = allDatas.length;
		let mds_name = allDatas[0][118];
		let mdsJson = 'MDS_'+ mds_name +'_'+lastUpDate+'_'+totalFields;
		
		let row5 = {
			range: this.props.selectedSheet+'!DT2',
			values: 
			[
				[ mdsJson ]
			]
		};
		updateData.push(row5)
		
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			that.setState({savedData:true});
            alert('Data Saved Successfully');
        }, function(reason) {
            alert(reason.result.error.message);
        });
    }
	
    handleNewQueChange=(event)=>{
        this.setState({[event.target.name]:event.target.value});
        if(event.target.value == "child_header"){
            this.setState({headerDiv:true,titleDiv:false,textDiv:false,questionDiv:false});
        }else if(event.target.value == "title"){
            this.setState({titleDiv:true,textDiv:false,questionDiv:false,headerDiv:false});
        }else if(event.target.value == "text"){
            this.setState({textDiv:true,headerDiv:false,titleDiv:false,questionDiv:false});
        }else if(event.target.value == "add_question"){
            this.setState({questionDiv:true,textDiv:false,headerDiv:false,titleDiv:false});
        }
        this.setState({addTagsText:'',addNoteText:'',changeAnsInpText:'',changeQueInpText:'',changeOutInpText:'',chooseAnsType:''});
    }
   
    handleSubmit=()=>{
        var btnClicked = '';
        var functions = [this.state]
        if(this.state.accessToken){
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true},handleAuthResult(this.props.sheetConfigId,btnClicked,functions,this.props.selDropOpt,this.props.updatedData,this.props.selectReviewOpt));
        }else{
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: false},handleAuthResult(this.props.sheetConfigId,btnClicked,functions,this.props.selDropOpt,this.props.updatedData,this.props.selectReviewOpt));
        }
		this.setState({validationType:'',showMinMax:false,minValue:'',maxValue:''});
    }
    
	selectKioskUsers=(event)=>{
        this.setState({kioskUsers: Array.from(event.target.selectedOptions, (item) => item.value)})
    }
    
	addPrecondition=(e)=>{
        var quesID = e.target.value;
        this.setState({queNo1:quesID});
        if(quesID !== 'undefined' ){
            var rowDatas = this.props.allData[quesID];
            if(rowDatas !== 'undefined'){
                this.setState({preCondID:rowDatas[0]});
                var id = rowDatas[1]
                var location = rowDatas[9];
                var parents = rowDatas[2]
                var questions = rowDatas[4];
                var answerType = rowDatas[5];
                var options = rowDatas[6];
                var visibility = rowDatas[8];
                var visibility1 = rowDatas[7];
                var action  = rowDatas[17];
                var field_id = rowDatas[0];
                let editPreCond = 4;
                var rowAllData = [id,location,parents,questions,answerType,options,visibility,visibility1,field_id,editPreCond];
                this.generateQuestion(rowAllData)         
            }   
        }
    }
    
	generateQuestion=(rowAllData)=>{
        if(rowAllData[9] === 3){
            this.setState({showFieldText:!this.state.showFieldText,showPreDiv:!this.state.showPreDiv,queNo1:rowAllData[10],preCondVal:rowAllData[11]});
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.displayAddData = <div id={rowAllData[0]} key={rowAllData[0]} visibility={rowAllData[6]}><h5>{question}</h5><p>{answer}</p></div>;

        }else if(rowAllData[9] === 4){
            this.setState({queNo1:rowAllData[10],preCondVal:rowAllData[11]});
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.displayAddData = <div id={rowAllData[0]} key={rowAllData[0]} visibility={rowAllData[6]}><h5>{question}</h5><p>{answer}</p></div>;
        }   
    }
    
	preConFunc=(cond_id)=>{
        this.setState({showPreDiv:true});
        if(cond_id){
            editPrecondition(cond_id,this.props.allData,this.generateQuestion);
        }
    }
		
    exportSheetData(sheetName,selDropOpt,selectedSheetId,facilityID,groupID){
        
		if(!this.state.savedData){
			alert('Please save changes first!');
			return false;
		}
		
        var currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
        var guids=guid();
        let selectSheet = this.props.selectedSheet.split('-');
        var sheetName = "P3-"+this.props.selectedSheet1+"-"+sheetName;
		
		
		let mainData = this.props.allData;
		
		saveConfigFile(sheetName,guids,currentDate,mainData,facilityID,groupID); 
		
		handleSubmitResetInc(this.props.selectedSheet,this.props.allData);
		
    }
	
    deleteData(allDatas){
        var branch= [];

        for(let i = 0; i < allDatas.length; i++){
            let row = Object.assign([], allDatas[i]);
            if(row.sub){
                delete row.sub;
            }
            branch.push(row);
        }
 
        return branch;
    }
    
	showNotInclude = () => {
		let allData = this.props.allData;
		let pfbByCompany = this.state.pfbByCompany;
		
		allData.map((rowDatas, i) => {
			if(rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
				$('#'+rowDatas[0]).addClass("not-include");
			}
		})
				
		if($("input[name='showIncludeN']").prop("checked") == true){
			
			if($("input[name='includePFB']").prop("checked") == true && $("input[name='showPFB']").prop("checked") == false){
				allData.map((rowDatas, i) => {
					if((rowDatas[14] == 'N' && pfbByCompany == '') || (rowDatas[14] == 'N' && pfbByCompany == rowDatas[115])){
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				})
			}else if($("input[name='includePFB']").prop("checked") == true && $("input[name='showPFB']").prop("checked") == true){
				allData.map((rowDatas, i) => {
					if((rowDatas[111] == 'N' && rowDatas[14] == 'N' && pfbByCompany == '') || (rowDatas[111] == 'N' && rowDatas[14] == 'N' && pfbByCompany == rowDatas[115])){
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				})
			}else{
				allData.map((rowDatas, i) => {
					if((rowDatas[111] == 'Y' && rowDatas[14] == 'N') || (rowDatas[111] == 'Y' && rowDatas[14] == 'N')){
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				})
			}
			
			{ allData.map((rowDatas, i) => {
				if(rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3'){
					let hasVisible=false;
					$('#'+rowDatas[0]).find('.question').each(function() {
						if(!$(this).hasClass("not-include")){
							hasVisible= true;
						}
					});
					if(hasVisible == false){
						$('#'+rowDatas[0]).addClass("not-include");
					}else{
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				}
			})}
		
		}else{
			allData.map((rowDatas, i) => {
				if(rowDatas[14] == 'N'){
					$('#'+rowDatas[0]).addClass("not-include");
				}else if(rowDatas[14] == 'Y' && $("input[name='includePFB']").prop("checked") == true){
					$('#'+rowDatas[0]).removeClass("not-include");
				}else if(rowDatas[14] == 'Y' && $("input[name='includePFB']").prop("checked") == false && rowDatas[111] == 'Y'){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
            })
		}
		
		
	}
	
	dataFilterPfbCompany = (event) =>{
		let val = event.target.value;
		
		if(!val){
			this.dataFilterPfb(event, this.state.selOptions);
			return false;
		}

		let allData = this.props.allData;
		let selOptions = this.state.selOptions;
		
		$("input[name='showIncludeN']").prop( "checked",false );
		allData.map((rowDatas, i) => {
			if(rowDatas[14] == 'N' && selOptions != 'view_all_pfb' && rowDatas[115] != val){
				$('#'+rowDatas[0]).addClass("not-include");
			}
		})
		
		if($("input[name='includePFB']").prop("checked") == true){
			
			if($("input[name='showPFB']").prop("checked") == true){
				allData.map((rowDatas, i) => {
					if(rowDatas[115] != val && rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
						$('#'+rowDatas[0]).addClass("not-include");
					}
					
					if((selOptions == 'view_all' && rowDatas[115] == val && rowDatas[14] == 'Y') || (rowDatas[111] == 'N' && rowDatas[115] == val && selOptions == 'view_all_pfb') || (selOptions == 'include_only' && rowDatas[115] == val && rowDatas[14] == 'Y' && this.state.checkedInc.get(rowDatas[0]) == true) || (selOptions == 'include_required' && rowDatas[115] == val && rowDatas[14] == 'Y' && this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == true)){
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				})
			}else{
				allData.map((rowDatas, i) => {
					if(rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
						$('#'+rowDatas[0]).addClass("not-include");
					}
					
					if((rowDatas[14] == 'Y' && rowDatas[111] == 'Y') || (rowDatas[111] == 'N' && rowDatas[115] == val && selOptions == 'view_all_pfb') || (selOptions == 'view_all' && rowDatas[115] == val && rowDatas[14] == 'Y') || (selOptions == 'include_only' && rowDatas[115] == val && rowDatas[14] == 'Y' && this.state.checkedInc.get(rowDatas[0]) == true) || (selOptions == 'include_required' && rowDatas[115] == val && rowDatas[14] == 'Y' && this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == true)){
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				})
			}
			
			this.setState({pfbByCompany:val});
			
		}else{
			this.setState({pfbByCompany:''});
			alert('Please include PFB=N firstly!');
		}
		
		{ allData.map((rowDatas, i) => {
			if((rowDatas[14] == 'Y' || selOptions == 'view_all_pfb') && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
				let hasVisible=false;
				$('#'+rowDatas[0]).find('.question').each(function() {
					if(!$(this).hasClass("not-include")){
						hasVisible= true;
					}
				});
				if(hasVisible == false){
					$('#'+rowDatas[0]).addClass("not-include");
				}else{
					$('#'+rowDatas[0]).removeClass("not-include");
				}
			}
		})}
			
	}
	
	dataFilterPfb = (event, optionVal) => {
		let allData = this.props.allData;
		let selOptions = optionVal;
		this.setState({pfbByCompany:''});
		if(optionVal == undefined){
			selOptions = this.state.selOptions;
		}
		$("input[name='showIncludeN']").prop( "checked",false );
		allData.map((rowDatas, i) => {
			if(rowDatas[14] == 'N' && selOptions != 'view_all_pfb'){
				$('#'+rowDatas[0]).addClass("not-include");
			}
		})
		if($("input[name='includePFB']").prop("checked") == true){
			allData.map((rowDatas, i) => {
				
				if((rowDatas[14] == 'Y' && rowDatas[111] == 'N' && selOptions == 'view_all') || (rowDatas[111] == 'N' && selOptions == 'view_all_pfb') || (rowDatas[111] == 'N' && selOptions == 'include_only' && this.state.checkedInc.get(rowDatas[0]) == true) || (rowDatas[111] == 'N' && selOptions == 'include_required' && this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == true) || (rowDatas[111] == 'N' && selOptions == 'include_not_required' && this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == false)){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
				
            })
			
			if($("input[name='showPFB']").prop("checked") == true){
				allData.map((rowDatas, i) => {
					if(rowDatas[111] == 'Y' && rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
						$('#'+rowDatas[0]).addClass("not-include");
					}
				})
			}else{
				allData.map((rowDatas, i) => {
					if((rowDatas[14] == 'Y' && rowDatas[111] == 'Y' && selOptions == 'view_all') || (rowDatas[111] == 'Y' && selOptions == 'view_all_pfb') || (rowDatas[111] == 'Y' && selOptions == 'include_only' && this.state.checkedInc.get(rowDatas[0]) == true) || (rowDatas[111] == 'Y' && selOptions == 'include_required' && this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == true) || (rowDatas[111] == 'Y' && selOptions == 'include_not_required' && this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == false)){
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				})
				
			}
        }else{
			allData.map((rowDatas, i) => {
				if(rowDatas[111] == 'N'){
					$('#'+rowDatas[0]).addClass("not-include");
				}
				if((rowDatas[14] == 'Y' && rowDatas[111] == 'Y' && selOptions == 'view_all') || (rowDatas[111] == 'Y' && selOptions == 'view_all_pfb') || (rowDatas[111] == 'Y' && selOptions == 'include_only' && this.state.checkedInc.get(rowDatas[0]) == true) || (rowDatas[111] == 'Y' && selOptions == 'include_required' && this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == true) || (rowDatas[111] == 'Y' && selOptions == 'include_not_required' && this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == false)){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
            })
			$("input[name='showPFB']").prop( "checked",false );
			
			this.setState({pfbByCompany:''});
		}
		
		if($("input[name='includePFB']").prop("checked") == true && $("input[name='showPFB']").prop("checked") == true){
			{ allData.map((rowDatas, i) => {
				if((rowDatas[14] == 'Y' || selOptions == 'view_all_pfb') && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
					let hasVisible=false;
					$('#'+rowDatas[0]).find('.question').each(function() {
						if(!$(this).hasClass("not-include")){
							hasVisible= true;
						}
					});
					if(hasVisible == false){
						$('#'+rowDatas[0]).addClass("not-include");
					}else{
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				}
			}) }
		}
	}
	
	dataFilter =(event)=>{
		let optionVal = event.target.value;
        this.setState({selOptions:optionVal});
        let allDatas = this.props.allData;
        let deleteData = this.deleteData(allDatas);
        let allData = [];
		this.setState({pfbByCompany:''});
		$("input[name='showIncludeN']").prop( "checked",false );
		{ allDatas.map((rowDatas, i) => {
			if(rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
				$('#'+rowDatas[0]). removeClass("not-include");
			}
			if(rowDatas[14] == 'N'){
				$('#'+rowDatas[0]).addClass("not-include");
			}
		})}
		
        if(optionVal == 'view_all'){
            allData = this.props.allData;
            allData.map((rowDatas, i) => {
				if(rowDatas[14] == 'Y'){
					$('#'+rowDatas[0]).removeClass("not-include");
				}					
            })
            if(this.state.placeId){
                this.props.updatedData(this.props.selectedSheet,this.state.placeId);
            }
            this.setState({addDataDiv:false});
			this.setState({placeId: '', placePos:false});
		}else if(optionVal == 'view_all_pfb'){
            this.setState({addDataDiv:false});
            { allDatas.map((rowDatas, i) => {
                if(rowDatas[111] == 'Y'){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
            })}
        }else if(optionVal == 'include_only'){
            this.setState({addDataDiv:false});
            { allDatas.map((rowDatas, i) => {
                let checkedbox= false;
                //if(rowDatas[5] !== 'Parent Header'){
                    $('#'+rowDatas[0]). addClass("not-include");
                //}
				
				if(rowDatas[5] == 'Parent Header'){
                    $('#'+rowDatas[0]).find('.inc:checked').each(function() { 
                        $('#'+rowDatas[0]). removeClass("not-include");
                    });
                }
				
                if(this.state.checkedInc.get(rowDatas[0]) == true && rowDatas[5] !== 'Parent Header' && rowDatas[111] == 'Y'){
                    $('#'+rowDatas[0]). removeClass("not-include");
                }
            })}
        }else if(optionVal == 'include_required'){
			this.setState({addDataDiv:false});
            { deleteData.map((rowDatas, i) => {
                let checkedbox= false;
                //if(rowDatas[5] !== 'Parent Header'){
                    $('#'+rowDatas[0]). addClass("not-include");
                //}
                if(this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == true && rowDatas[111] == 'Y'){
                    $('#'+rowDatas[0]). removeClass("not-include");
                }
                if(rowDatas[5] == 'Parent Header' || rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                    $('#'+rowDatas[0]).find('.req:checked').each(function() {
                        $('#'+rowDatas[0]). removeClass("not-include");
                    });
                }
            })}
        }else if(optionVal == 'include_not_required'){
			this.setState({addDataDiv:false});
            { deleteData.map((rowDatas, i) => { 
                let checkedbox= false;
                //if(rowDatas[5] !== 'Parent Header'){
                    $('#'+rowDatas[0]). addClass("not-include");
                //}
                if(this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == false && rowDatas[111] == 'Y'){
                    $('#'+rowDatas[0]). removeClass("not-include");
                }
                if(rowDatas[5] == 'Parent Header' || rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                    $('#'+rowDatas[0]).find('.req:checked').each(function() {
                        $('#'+rowDatas[0]). removeClass("not-include");
                    });
                }
            })}
        }else if(optionVal == 'add_new_questions'){
			
			let placeId = this.state.placeId;
			if(placeId){
				this.setState({addDataDiv:true,headerSection:false,changeQueInpText:'',headerDiv:false,titleDiv:false,textDiv:false,questionDiv:false });
			}else{
				this.setState({addDataDiv:true,headerSection:true,changeQueInpText:'',headerDiv:false,titleDiv:false,textDiv:false,questionDiv:false });
			}
        }
		
		{ allDatas.map((rowDatas, i) => {
			if(rowDatas[111] == 'N'){
				$('#'+rowDatas[0]). addClass("not-include");
			}
		})}
		if($("input[name='includePFB']").prop("checked") == true){
			this.dataFilterPfb(event, optionVal);
		}else if(optionVal != 'view_all'){
			allData = this.props.allData;
			{ allData.map((rowDatas, i) => {
				if((rowDatas[14] == 'Y' || optionVal == 'view_all_pfb') && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
					let hasVisible=false;
					$('#'+rowDatas[0]).find('.question').each(function() {
						if(!$(this).hasClass("not-include")){
							hasVisible= true;
						}
					});
					if(hasVisible == false){
						$('#'+rowDatas[0]).addClass("not-include");
					}else{
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				}
			})}
			
		}
		
    }
	
    getDirectoryImages=()=>{
        let config1 = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = config.API_URL+'uploadfile/get_images.php';
        axios.get(url, config1)
        .then(response => {
            var fileArray =response.data;
            this.setState({fileArray});
        })
        //this.setState({fileArray});
    }
    
    handleDelete=()=>{
        let accessToken = gapi.auth.getToken().access_token;
        let rowId = '';
        //let spreadsheetID = config.spreadsheetId;
		let spreadsheetID = this.props.sheetConfigId;
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        this.props.allData.map((valu, i) => { 
            if(valu[0] == this.state.saveQueId){
                rowId = (i+2);
            }
        })
        let deleteData = [];
        let row1 = {
            "range": this.props.selectedSheet+'!AK'+rowId,
            "majorDimension": "ROWS",
            "values": 
            [
                [ 'Deleted']
            ]
        };
        deleteData.push(row1);
        let row2 = {
            "range": this.props.selectedSheet+'!O'+rowId+':P'+rowId,
            "majorDimension": "ROWS",
            "values": 
            [
                [ 'N','N']
            ]
        };
        deleteData.push(row2);
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: deleteData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
            $('#code').modal('hide');
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
        this.props.updatedData();
    }
    
	myFunction =(rowData,btnClicked)=>{
        this.setState({ChangeNotes:false,ChangeTags:false,changeAnsOpt:false,changeQueText:false,changeOutputText:false,changeValiType:false,changeQueType:false,ChangeCompany:false,showMinMax:false});
        this.setState({changeQformat:false,showReword: false,addTagsText:'',addNoteText:'',queAction:'',inputRewordText:'',inputRewordAns:'',changeAnsInpText:'',changeQueInpText:'',changeOutInpText:'',valTypeText:'',validationType:'V1',chooseAnsType:'',chooseCompany:'',chooseTracking:'',});
        this.setState({btnClicked:btnClicked,showPreDiv:false,preCondVal:'',preCondID:'',queNo:'',preCondition:rowData[7]+'-'+rowData[8],showFieldText:true,allData:this.props.allData,selectedSheet:this.props.selectedSheet}); 
        let question   ='';
        let answerType ='';
        let option     ='';
        let addTagsText='';
        let addNoteText='';
		let companyNames = rowData[115];
		let trackingName = rowData[125];
		if(this.props.selectedOpt == 'improved'){
            question = rowData[23] ? rowData[23] :rowData[4];
            answerType = rowData[24] ? rowData[24] :rowData[5];
            option = rowData[22]  ? rowData[22] :rowData[6]
            addNoteText=rowData[18] ? rowData[18] : rowData[12];
            addTagsText= rowData[19] ? rowData[19] :rowData[13];
        }else{
            question   = rowData[4];
            answerType = rowData[5];
            option     = rowData[6];
            addNoteText=rowData[12];
            addTagsText= rowData[13];
        }
        var id = rowData[1]
        var location = rowData[9];
        var parent = rowData[2];
        var visibility = rowData[8];
        var visibility1 = rowData[7];
        var action  = rowData[17];
        var field_id = rowData[0];
        var label = rowData[41];
        var pfb = rowData[111];
        let rowAllData = [id,location,parent,question,answerType,option,visibility1,visibility,field_id,label,pfb];
        this.setState({rowAllData})
        this.props.description.map((answer, i) => {  
            if(answer[1] && answer[1].toLowerCase() === rowData[5].toLowerCase()){
               this.setState({queDescription:answer[2]}); 
            }
        })
		var valType = rowData[48];
		var valTypeText = this.props.validationType(valType);
		
		this.setState({changeOutInpText:rowData[9],validationType:valType,minValue:rowData[50],maxValue:rowData[51],valTypeText});
		if(rowData[50] && rowData[51]){
			this.setState({showMinMax:true});
		}
		
        if(rowData[5].toLowerCase() == 'text' || rowData[5].toLowerCase() == 'title' ){

            if(rowData[35] && rowData[5].toLowerCase() == 'text'){

                this.setState({customTextDiv:true,customField:true,changeQueInpText:question,chooseAnsType:answerType.toLowerCase(),chooseCompany:companyNames,chooseTracking:trackingName,customHeaderDiv:false,customTitleDiv:false})

            }else if(rowData[35] && rowData[5].toLowerCase() == 'title'){

                this.setState({customTitleDiv:true,customTextDiv:false,chooseAnsType:answerType.toLowerCase(),chooseCompany:companyNames,chooseTracking:trackingName,customHeaderDiv:false,customField:true})
            
            }
            this.setState({TextDiv:true,changeQueInpText:question,showQueAction:false,headerDiv:false,Question:question,queAction:'changeQformat'});  
            this.setState({QuestionId:rowData[0],Location:rowData[9],saveQueId:rowData[0],queType:rowData[5].toUpperCase(),addTagsText:addTagsText,addNoteText:addNoteText,QuestionShowDiv:false}); 
        
        }else if(rowData[5].toLowerCase() == 'child header 1' || rowData[5].toLowerCase() == 'child header 2' || rowData[5].toLowerCase() == 'child header 3'){
            
            if(rowData[35]){
                this.setState({customHeaderDiv:true,customField:true,customTitleDiv:false,customTextDiv:false});
            }
            this.setState({headerDiv:true,changeQueInpText:rowData[4],chooseAnsType:answerType.toLowerCase(),chooseCompany:companyNames,chooseTracking:trackingName,showQueAction:false,TextDiv:false,Question:question,queAction:'changeQformat'});
            this.setState({QuestionId:rowData[0],Location:rowData[9],saveQueId:rowData[0],queType:rowData[5].toUpperCase(),addTagsText:addTagsText,addNoteText:addNoteText,QuestionShowDiv:false}); 
        
        }else{

            if(rowData[35]){
                this.setState({customHeaderDiv:false,customField:true,changeQueInpText:question,changeAnsInpText:option,chooseAnsType:answerType.toLowerCase(),chooseCompany:companyNames,chooseTracking:trackingName,customTitleDiv:false,customTextDiv:false})
            }
            this.setState({QuestionId:rowData[0],Location:rowData[9],saveQueId:rowData[0],Question:rowData[4],queType:rowData[5].toUpperCase(),headerDiv:false,addTagsText:addTagsText,addNoteText:addNoteText,showQueAction:true,TextDiv:false,changeQueInpText:question,QuestionShowDiv:true});
        
        }
        var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
        var imgUrl  = addNoteText;
        const html = `<p>`+imgUrl+`</p>`;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
              editorState,
            });
        }
        const imageType = rowData[5].toLowerCase();
        this.setState({showImage:'/'+imageType+'.png'});
        let answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
        this.setState({Answer:answer});
        if(rowData[7]){
            this.setState({showPreCon:true,preCondition:rowData[7]+'-'+rowData[8],QueSaveId:rowData[0]});
        }else{
            this.setState({showPreCon:false,preCondition:''});
        }
        $('#code').modal('show');
    }
	
    setAllChecked=()=>{
		let checkedInc = this.state.checkedInc;
		$('.inc').prop('checked', true);
		this.props.allData.map((valu, i) => {
			checkedInc.set(valu[0], true);
		})
	}
	
	setAllUnChecked=()=>{
		let checkedInc = this.state.checkedInc;
		$('.inc').prop('checked', false);
		this.props.allData.map((valu, i) => {
			checkedInc.delete(valu[0]);
		})
	}
	
    setDefaultCheckboxes=(IncAll= null)=>{
		let checkedInc = this.state.checkedInc;
        let checkedReq = this.state.checkedReq;
		let checkedKiosk = this.state.checkedKiosk;
		if(IncAll == 'IncAll'){
            this.setState({checkedKiosk: this.state.checkedKiosk,checkedReq: this.state.checkedReq});
        }else{
            this.props.allData.map((valu, i) => {
				let isChecked ="";
				let isChecked1 ="";
				let isChecked2 ="";
				
				if(this.props.showImpData){
					if(valu[32] == 'Y'){
						isChecked =true;
					}else{
						isChecked=false
					}
					if(valu[33] == 'Y'){
						isChecked1 =true;
					}else{
						isChecked1=false
					}
				}else{
					if(valu[14] == 'Y'){
						isChecked =true;
					}else{
						isChecked=false
					}
					if(valu[15] == 'Y'){
						isChecked1 =true;
					}else{
						isChecked1=false
					}
				}
				if(IncAll == 'IncAll'){
					isChecked =true;
				}
				if(valu[16]){
					let kioskDefalt =valu[16].split(',');
					if(kioskDefalt[8] == 'Y'){
						isChecked2 = true
					}else{
						isChecked2 = false
					}
                }
                checkedKiosk.set(valu[0],isChecked2);
                checkedInc.set(valu[0], isChecked)
                checkedReq.set(valu[0], isChecked1)
            })
            this.setState({checkedKiosk: checkedKiosk,checkedReq: checkedReq,checkedInc: checkedInc});
        }
    }
	
	/* uploadVideoPath=(embedCode)=>{
        //console.log(embedCode)
        var path = $( "#videoHtmlDiv" ).val(embedCode);
        
        this.setState({videoPath:embedCode});
    } */
	
	uploadImagePath=()=>{
        var path  =$( "#imageId" ).val();
        $('#directory').modal('hide');
        this.setState({imagePath:path});
    }
    
	getImagePath=(path,id)=>{
		$( ".view-image" ).removeClass( "selected" );
		$( "#image_"+id ).addClass( "selected" );
		$( "#imageId" ).val( path );
	}
	
	uploadVideoPath=(embedCode, errorMessage)=>{
		if(!errorMessage && embedCode){
			$( "#inlineVideo").html(embedCode);
			this.setState({inlineVideo:embedCode});
		}else{
			alert(errorMessage);
		} 
    }

    render(){
        const { addDataDiv, placePos,headerSection, headerDiv, textDiv, queDescription,imagePath, filePath,customTitleDiv,switchBtn,customTextDiv, btnClicked,customField,customHeaderDiv, titleDiv,showQueAction, rowAllData,queType, questionDiv,QuestionId,showPreDiv,checkedKiosk,Location,showPreCon,editorState,fileArray,showMinMax,showCompany,newCompanyName,valTypeText,newTrackingCode } =this.state;
        
        const { sheetData,allData,setKiosk, clearAllFilter, description,selectedSheet,showDataDiv,selDropOpt,getLangData, handleHeaderFilter, langSheetsName,selectedSheet1, assoType,assoVisitType,assoDeviceType, kioskResponse,getDirectoryFiles, showSign, apiPdfs, companies, trackingCode} =this.props;
		
		var optionNumber = [1,2,3,4,5,6,7,8,9,10];
		
        let moduleName ="form_builder";
        const crop = {unit: 'px', x: 130,y: 50,width: 200,height: 200}
		let kioskType = [];
		if(allData[0]){
			kioskType = allData[0][119].split(',');
		}
		
		const Companydropdown = () =>{
			let companyName = [];
			if(allData){
				allData.map(function(val,i) {
					if(val[14] == 'Y'&& val[111] == 'N' && val[115]){
						companyName.push(val[115]);
					}
				})
			}	
			
			var uniqueNames = [];
			$.each(companyName, function(i, el){
				if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
			});
			
			var optionsHtml = uniqueNames.map(function(val,i) {
				
				return (
					<option value={val} key={i}>{val}</option>
				);
				
			})
			return <select className="form-control" value={this.state.pfbByCompany} onChange={this.dataFilterPfbCompany} >
					<option value=""> Please Select</option>
					{optionsHtml}
				</select>
			
        }
		
        return(
            <div className="card-body">
            {setKiosk ? null :
                <Header 
                    reviewSheetsName= {this.props.reviewSheetsName}
                    sheetsName = {this.props.sheetsName} 
                    onSelect={this.props.onSelect}
					mdsGuid={this.props.mdsGuid}
                    moduleName={moduleName}
                    handleHeaderFilter={handleHeaderFilter}
                    clearAllFilter ={clearAllFilter}
                    reviewVer={this.props.reviewVer}
                    showDataDiv = {showDataDiv}
                    langSheetsName = {langSheetsName}
                    selDropOpt = {selDropOpt}
                    getLangData ={getLangData}
                    selectedSheet1 ={selectedSheet1}
                    showPreview ={this.showPreview}
                    selectKioskUsers={this.selectKioskUsers}
                    kioskUsers = {this.state.kioskUsers}
                    selectReviewOpt = {this.props.selectReviewOpt}
                    handleClearTempSave = {this.props.handleClearTempSave}
                    selectedOpt = {this.props.selectedOpt}
                    selOptions = {this.state.selOptions}
                    selectedSheet = {selectedSheet}
                    sheetData = {sheetData}
                    allData = {allData}
                    showAllQues = {this.showAllQues}
                    addNewField = {this.addNewField}
                    dataFilter = {this.dataFilter}
                    dataFilterPfb = {this.dataFilterPfb}
                    showNotInclude = {this.showNotInclude}
					Companydropdown={<Companydropdown/>}
                    assoType = {assoType}
                    assoVisitType ={assoVisitType}
                    assoDeviceType ={assoDeviceType} />
            }
                {sheetData && showDataDiv? 
                        <div className="card-body1">
                        {addDataDiv ? 
                            <div className="addDataDiv">
								<div className="row">
                                    <div className="col-md-12">
                                        <h4>Add New Questions, Fields and Functions </h4>
									</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
									   <div className="radio">
                                          <label><input type="radio" name="add_text" defaultChecked={this.state.add_text === 'child_header'} value="child_header" onChange={this.handleNewQueChange} /><b>Add Headers</b></label>
                                        </div>                                      
									    {headerDiv? (
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <label>Select Header Type:</label>
                                                    <select className="form-control" name="chooseAnsType" value={this.state.chooseAnsType} onChange={this.getValue}>
                                                        <option value="">Please Select</option>
                                                        <option value='Child Header 1'>Child Header 1</option>
                                                        <option value='Child Header 2'>Child Header 2</option>
                                                        <option value='Child Header 3'>Child Header 3</option>
                                                    </select>
                                                    <label>Edit Text:</label>
                                                    <input value={this.state.changeQueInpText} name="changeQueInpText" rows="8" onChange={this.getValue} className="form-control" />
                                                </div>
                                            </div>) 
                                        : null}
                                        <div className="radio">
                                          <label><input type="radio" name="add_text" defaultChecked={this.state.add_text === 'add_question'} value="add_question" onChange={this.handleNewQueChange} /><b>Add New Field or Function</b></label>
                                        </div> 
                                        {questionDiv ? (
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <label> Choose Question Type: </label>
                                                    <select className="form-control" id="sel1" name="chooseAnsType" onChange={this.getValue}>
                                                        <option value="">Please Select</option>
                                                        <option value="q1">Q1-Predefined list of options</option>
                                                        <option value="q2">Q2-User Defined Dropdown Menu (B+)</option>
                                                        <option value="q3">Q3-Multi-select Dropdown</option>
                                                        <option value="q4">Q4-Radio Buttons</option>
                                                        <option value="q5">Q5-Single Checkbox</option>
                                                        <option value="q6">Q6-Small Blank Field</option>
                                                        <option value="q7">Q7-Large Blank Field</option>
                                                        <option value="q8">Q8-Date Selection</option>
                                                        <option value="q9">Q9-Extra Large Text Box</option>
                                                        <option value="q10">Q10-Single Check Box Plus Details</option>
                                                        <option value="q11">Q11-Multiple checkboxes select</option>
                                                        <option value="q11b">Q11b-Multiple checkboxes+</option>
                                                        <option value="title">Title</option>
                                                        <option value="text">Text</option>
                                                        <option value="timer">Timer</option>
                                                        <option value="slider">Slider</option>
                                                        <option value="image">Image</option>
                                                        <option value="video">Video Upload</option>
                                                        <option value="file">File Upload</option>
                                                        <option value="sig">Sig</option>
                                                        <option value="calculation">Calculation</option>
                                                        <option value="subjective">Subjective</option>
                                                        <option value="assessment">Assessment</option>
                                                        <option value="pdfapi">Get PDF via API</option>
                                                        <option value="link">Link</option>
                                                        <option value="boxbegin">Box-Begin</option>
                                                        <option value="boxend">Box-End</option>
                                                        <option value="inlineVideo">Inline Video</option>
                                                        <option value="basicgrid">Basic Grid 1</option>
                                                        <option value="grid5point">Grid- 5 Points</option>
                                                        <option value="gridcheck">Grid-check</option>
                                                        <option value="likert">Likert</option>
                                                        <option value="smart">Smart Multi-select</option>
                                                        <option value="weighted">Weighted Score Grid</option>
                                                        <option value="wcalculation">Weighted Value Calculations</option>
														<option value="grid-dropdown">Grid Drop Down</option>
                                                        {/*<option value="Q9">Q9-Simple Checkbox</option>
                                                        <option value="Q10">Q10-Complex Checkbox</option>
                                                        <option value="Q11">Q11-Sliding Scale Rating</option>
                                                        <option value="Q12">Q12-Grid Selection</option>
                                                        <option value="Q13">Q13-Grid Options [and/or]</option>
                                                        <option value="Q14">Q14-Grid Options [NOR]</option>
                                                        <option value="Q15">Q15-Numeric Range</option>
                                                        <option value="Q16">Q16-user definable list builder</option>*/}
                                                    </select>
                                                </div>
												
                                                <div className="col-md-8" id='linkType' style={{display:'none'}}>
                                                    <label> Website address: </label>
                                                    <input type="text" className="form-control" name="websiteLink" onChange={this.getValue} placeholder="http://"/>
                                                </div>
												
                                                <div className="col-md-8" id='qtext'>
                                                    <label> New Question Text: </label>
                                                    <textarea className="form-control" type="text" onChange={this.getValue} name="changeQueInpText"></textarea>
                                                </div>
												
												<div className="col-md-8" id='gridcheck' style={{display:'none'}}>
													<label> Number of Grid Columns </label>
													<select className="form-control number-rows" name="numberRows" onChange={this.getValue}>
                                                        <option value='2'>2</option>
														<option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                    </select>
												</div>
												
												<div className="col-md-8" id='basicgrid' style={{display:'none'}}>
													<label> Grid Title: </label>
                                                    <textarea className="form-control" type="text" onChange={this.getValue} name="changeQueInpText"></textarea>
													
													<label> Number of data Columns </label>
													 
													<select className="form-control number-rows" name="numberOfColumn" onChange={this.getValue}>
                                                        <option value='1'>1</option>
                                                        <option value='2'>2</option>
														<option value='3'>3</option>
                                                    </select>
													<div className="custom-vali"> Choose Validation Type: </div>
													<div className="column-input column-1">
														<b>Data Column 1 : </b><input type="text" className="form-control" name="C1" onChange={this.getValue} />
														<select className="form-control custom-validation" name="validation1" onChange={this.getValue}>
															<option value="V1">V1 Text</option>
															<option value="V2">V2 Text Alphabet Only (no numbers)</option>
															<option value="V3">V3 Whole +Numbers and Zero</option>
															<option value="V4">V4 Whole +Numbers no Zero</option>
															<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
															<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
														</select>
													</div>
													
													<div className="column-input column-2" style={{display:'none'}}>
														<b>Data Column 2 : </b><input type="text" className="form-control" name="C2" onChange={this.getValue} maxLength={20} />
														<select className="form-control custom-validation" name="validation2" onChange={this.getValue}>
															<option value="V1">V1 Text</option>
															<option value="V2">V2 Text Alphabet Only (no numbers)</option>
															<option value="V3">V3 Whole +Numbers and Zero</option>
															<option value="V4">V4 Whole +Numbers no Zero</option>
															<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
															<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
														</select>
													</div>
													<div className="column-input column-3" style={{display:'none'}}>
														<b>Data Column 3 : </b><input type="text" className="form-control" name="C3" onChange={this.getValue} maxLength={20} />
														<select className="form-control custom-validation" name="validation3" onChange={this.getValue}>
															<option value="V1">V1 Text</option>
															<option value="V2">V2 Text Alphabet Only (no numbers)</option>
															<option value="V3">V3 Whole +Numbers and Zero</option>
															<option value="V4">V4 Whole +Numbers no Zero</option>
															<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
															<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
														</select>
													</div>
													<br />
													<label> Number of Rows </label>
													 
													<select className="form-control number-rows" name="numberRows" onChange={this.getValue}>
                                                        <option value='1'>1</option>
                                                        <option value='2'>2</option>
														<option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                        <option value='5'>5</option>
                                                        <option value='6'>6</option>
                                                        <option value='7'>7</option>
                                                        <option value='8'>8</option>
                                                        <option value='9'>9</option>
                                                        <option value='10'>10</option>
                                                        <option value='11'>11</option>
                                                        <option value='12'>12</option>
                                                        <option value='13'>13</option>
                                                        <option value='14'>14</option>
                                                        <option value='15'>15</option>
                                                    </select>
													
													<div className="row-input row-1"><b>Field name 1 : </b><input type="text" className="form-control" name="R1" onChange={this.getValue} /></div>
													<div className="row-input row-2" style={{display:'none'}}><b>Field name 2 : </b><input type="text" className="form-control" name="R2" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-3" style={{display:'none'}}><b>Field name 3 : </b><input type="text" className="form-control" name="R3" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-4" style={{display:'none'}}><b>Field name 4 : </b><input type="text" className="form-control" name="R4" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-5" style={{display:'none'}}><b>Field name 5 : </b><input type="text" className="form-control" name="R5" onChange={this.getValue} maxLength={20} /></div>
													
													<div className="row-input row-6" style={{display:'none'}}><b>Field name 6 : </b><input type="text" className="form-control" name="R6" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-7" style={{display:'none'}}><b>Field name 7 : </b><input type="text" className="form-control" name="R7" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-8" style={{display:'none'}}><b>Field name 8 : </b><input type="text" className="form-control" name="R8" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-9" style={{display:'none'}}><b>Field name 9 : </b><input type="text" className="form-control" name="R9" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-10" style={{display:'none'}}><b>Field name 10 : </b><input type="text" className="form-control" name="R10" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-11" style={{display:'none'}}><b>Field name 11 : </b><input type="text" className="form-control" name="R11" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-12" style={{display:'none'}}><b>Field name 12 : </b><input type="text" className="form-control" name="R12" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-13" style={{display:'none'}}><b>Field name 13 : </b><input type="text" className="form-control" name="R13" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-14" style={{display:'none'}}><b>Field name 14 : </b><input type="text" className="form-control" name="R14" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-15" style={{display:'none'}}><b>Field name 15 : </b><input type="text" className="form-control" name="R15" onChange={this.getValue} maxLength={20} /></div>
													
													<br />
												</div>
												
												<div className="col-md-8" id='grid' style={{display:'none'}}>
													<label> 5 Scale Headers</label>
													<div className="grid-sacale">
														<input type="text" className="form-control" name="sacale1" onChange={this.getValue} maxLength={20} />
														<input type="text" className="form-control" name="sacale2" onChange={this.getValue} maxLength={20} />
														<input type="text" className="form-control" name="sacale3" onChange={this.getValue} maxLength={20} />
														<input type="text" className="form-control" name="sacale4" onChange={this.getValue} maxLength={20} />
														<input type="text" className="form-control" name="sacale5" onChange={this.getValue} maxLength={20} />
													</div>
													
													<label> Number of Rows </label>
													 
													<select className="form-control number-rows" name="numberRows" onChange={this.getValue}>
                                                        <option value='1'>1</option>
                                                        <option value='2'>2</option>
														<option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                        <option value='5'>5</option>
                                                    </select>
													
													<div className="row-input row-1"><b>Row 1 text : </b><input type="text" className="form-control" name="row1" onChange={this.getValue} /></div>
													<div className="row-input row-2" style={{display:'none'}}><b>Row 2 text : </b><input type="text" className="form-control" name="row2" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-3" style={{display:'none'}}><b>Row 3 text : </b><input type="text" className="form-control" name="row3" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-4" style={{display:'none'}}><b>Row 4 text : </b><input type="text" className="form-control" name="row4" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-5" style={{display:'none'}}><b>Row 5 text : </b><input type="text" className="form-control" name="row5" onChange={this.getValue} maxLength={20} /></div>
												</div>
												
												<div className="col-md-8" id='likert' style={{display:'none'}}>
													<label>Score text</label>
													<div className="grid-sacale">
														<input type="text" className="form-control" name="lowestText" onChange={this.getValue} maxLength={20} />
														<input type="text" className="form-control" name="highestText" onChange={this.getValue} maxLength={20} />
													</div>
													
													<div className="likert-row">
													<label> Number of Rows </label>
													 
													<select className="form-control number-rows" name="numberRows" onChange={this.getValue}>
                                                        <option value='1'>1</option>
                                                        <option value='2'>2</option>
														<option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                        <option value='5'>5</option>
                                                    </select>
													</div>
													
													<div className="row-input row-1"><b>Row 1 text : </b><input type="text" className="form-control" name="row1" onChange={this.getValue} /></div>
													<div className="row-input row-2" style={{display:'none'}}><b>Row 2 text : </b><input type="text" className="form-control" name="row2" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-3" style={{display:'none'}}><b>Row 3 text : </b><input type="text" className="form-control" name="row3" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-4" style={{display:'none'}}><b>Row 4 text : </b><input type="text" className="form-control" name="row4" onChange={this.getValue} maxLength={20} /></div>
													<div className="row-input row-5" style={{display:'none'}}><b>Row 5 text : </b><input type="text" className="form-control" name="row5" onChange={this.getValue} maxLength={20} /></div>
												</div>
												
												<div className="col-md-8" id='weighted' style={{display:'none'}}>
													<label>How many options?</label>
													 
													<select className="form-control number-rows" name="numberRows" onChange={this.getValue}>
                                                        <option value='2'>2</option>
														<option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                        <option value='5'>5</option>
                                                        <option value='6'>6</option>
                                                        <option value='7'>7</option>
                                                        <option value='8'>8</option>
                                                        <option value='9'>9</option>
                                                        <option value='10'>10</option>
                                                    </select>
													
													{optionNumber.map((val, i) => (
														<div className={"row-input row-"+val} style={{display: val > 2 ? 'none' : ''}} ><b>{val} </b><input type="text" className="form-control weight-txt" name={"row"+val} onChange={this.getValue} /> <input type="number" className="form-control weight-val" name={"weight"+val} onChange={this.getValue} min="0" max="100" /></div>
													))}
													
													
													<label> Number of Grid Columns </label>
													 
													<select className="form-control number-rows" name="numberOfGrid" onChange={this.getValue}>
                                                        <option value='1'>1</option>
                                                        <option value='2'>2</option>
														<option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                    </select>
													
													<label> Arrangement of options </label>
													<div><input className="order-input" type="radio" name="arraignment" value="provided" onChange={this.getValue} />In order provided</div>
													<div><input className="order-input" type="radio" name="arraignment" value="alphabetical" onChange={this.getValue} />Alphabetical</div>
													<div><input className="order-input" type="radio" name="arraignment" value="random" onChange={this.getValue} />Random</div>
													<br />
												</div>
												
												<div className="col-md-8" id='calculationType' style={{display:'none'}}>
                                                    <label> Calculation type: </label>
                                                    <select className="form-control" id="sel1" name="calculationType" onChange={this.getValue}>
                                                        <option value="">Please Select</option>
                                                        <option value="sum">Sum</option>
														<option value="avg">Average</option>
                                                        <option value="sub">Subtract A-B</option>
                                                        <option value="hight_number">Highest Number</option>
                                                        <option value="low_number">Lowest Number</option>
                                                        <option value="shortest">Shortest Time</option>
                                                        <option value="longest">Longest Time</option>
                                                    </select>
                                                </div>
												
												<div className="col-md-8" id='calculationFields' style={{display:'none'}}>
                                                    <label>Field Name's filter by type:</label>
                                                    <select className="form-control" id="sel1" name="validationTypefilter" onChange={this.getValue}>
															<option value="">Please Select</option>
															<option value="V3">V3 Whole +Numbers and Zero</option>
															<option value="V4">V4 Whole +Numbers no Zero</option>
															<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
															<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
															<option value="V7">V7 Percentage Positive Only</option>
															<option value="V8">V8 Fractions Positive Only</option>
															<option value="V12">V12	Time hh:mm:ss:ssss</option>
															<option value="V13">V13 Time ss:ssss</option>
														</select>
													<label> Select the Field Name's to include in the calculation:</label>
													{ this.calculationFields(allData) } 
                                                </div>
												
												
												<div className="col-md-8" id='gridDropdown' style={{display:'none'}}>
													
													<label> Number of data Columns </label>
													 
													<select className="form-control number-rows form-group basicgrid-col small-width" name="numberOfColumn" onChange={this.getValue}>
														<option value='2'>2</option>
														<option value='3'>3</option>
													</select>
													
													<div className="column-input column-1">
														<b>Data Column 1 : </b>
														<br />
														<input type="text" className="form-control" name="C1" onChange={this.getValue} />
														
													</div>
													
													<div className="column-input column-2">
														<b>Data Column 2 : </b>
														<br />
														<input type="text" className="form-control" name="C2" onChange={this.getValue} maxLength={20} />
														
													</div>
													<div className="column-input column-3" style={{display:'none'}}>
														<b>Data Column 3 : </b>
														<br />
														<input type="text" className="form-control" name="C3" onChange={this.getValue} maxLength={20} />
														
													</div>
													<br />
													
													<label> Number of Rows </label>
													 
													<select className="form-control form-group number-rows small-width" name="numberRows" onChange={this.getValue}>
														<option value='1'>1</option>
														<option value='2'>2</option>
														<option value='3'>3</option>
													</select>
													
													<div className="row-input row-1 grid-dropdown">
														<label>Row 1 Text : </label>
														<input type="text" className="form-control" name="R1C1" onChange={this.getValue} />
														<div className='dropdown-col2'>
														<label>Row 1 Column 2 answer option : </label>
														<input type="text" className="form-control r1c2" name="R1C2" onChange={this.getValue} /> <input className="same-for-all" name="sameAllC2" type="checkbox" onChange={this.getValue} /> Same for all
														</div>
														<div className='dropdown-col3'>
														<label>Row 1 Column 3 answer option : </label>
														<input type="text" className="form-control r1c3" name="R1C3" onChange={this.getValue} /> <input className="same-for-all" name="sameAllC3" type="checkbox" onChange={this.getValue} /> Same for all
														</div>
													</div>
													<br />
													<div className="row-input row-2 grid-dropdown">
														<label>Row 2 Text : </label>
														<input type="text" className="form-control" name="R2C1" onChange={this.getValue} />
														<label>Row 2 Column 2 answer option : </label>
														<input type="text" className="form-control rc2" name="R2C2" onChange={this.getValue} />
														<div className='dropdown-col3'>
														<label>Row 2 Column 3 answer option : </label>
														<input type="text" className="form-control rc3" name="R2C3" onChange={this.getValue} />
														</div>
													</div>
													<br />
													<div className="row-input row-3 grid-dropdown">
														<label>Row 3 Text : </label>
														<input type="text" className="form-control" name="R3C1" onChange={this.getValue} />
														<label>Row 3 Column 2 answer option : </label>
														<input type="text" className="form-control rc2" name="R3C2" onChange={this.getValue} />
														<div className='dropdown-col3'>
														<label>Row 3 Column 3 answer option : </label>
														<input type="text" className="form-control rc3" name="R3C3" onChange={this.getValue} />
														</div>
													</div>
													<br />
												</div>
												
												<div className="col-md-8" id='wcalculation' style={{display:'none'}}>
                                                    <label> Calculation type: </label>
                                                    <select className="form-control" id="sel1" name="calculationType" onChange={this.getValue}>
                                                        <option value="">Please Select</option>
                                                        <option value="wsum">Sum Weight Values</option>
														<option value="wavg">Average Weight Values</option>
                                                        <option value="wcount">Count Weight Values</option>
                                                        <option value="wrcount">Count Ratio Weight Values</option>
                                                    </select>
													
													<label> Select the Field Name to include in the calculation:</label>
													{ this.wcalculationFields(allData) }
                                                </div>
												
                                                <div className="col-md-8" id='answerDiv' style={{display:'none'}}>
                                                    <label>Answer Options (separated by comma):</label>
                                                    <textarea id="optionInput" className="form-control" name="changeAnsInpText" onChange={this.getValue} type="text" ></textarea>
                                                </div>
												
												<div className="col-md-8" id='outputTextDiv'>
                                                    <label>Output Text:</label>
                                                    <textarea id="optionInput" className="form-control" name="changeOutInpText" onChange={this.getValue} type="text" ></textarea>
                                                </div>
												
												<div className="col-md-8" id='noneAbove' style={{display:'none'}}>
                                                   <input name="noneAbove" type="checkbox" value="None of the Above" onChange={this.getValue} /> Include "None of the Above"
                                                </div>
												
                                                <div className="col-md-8 sliderDiv" style={{display:'none'}}>
                                                    <label>Enter text for minimium (only allow max 20 characters):</label>
                                                    <textarea className="form-control" name="sliderMinText"  onChange={this.getValue} type="text" maxLength={20} ></textarea>
                                                </div>
                                                <div className="col-md-8 sliderDiv" style={{display:'none'}}>
                                                    <label>Enter text for maximium(only allow max 20 characters):</label>
                                                    <textarea className="form-control" name="sliderMaxText"  onChange={this.getValue} type="text" maxLength={20} ></textarea>
                                                </div>
												
                                                <div className="col-md-8" id='imageDiv' style={{display:'none'}}>
                                                    <label>Upload An Image:</label>
                                                    <button data-target="#directory" data-toggle="modal" onClick={this.getDirectoryImages}>open directory...</button>
                                                    {imagePath !== '' ? <img src={imagePath} width="259px" height="200px"/>:null}
                                                </div>
												
												<div className="col-md-8" id='inlineVideoDiv' style={{display:'none'}}>
                                                    <label>Upload Inline Video:</label>
                                                    <BasicTextFields uploadVideoPath={this.uploadVideoPath} />
													<div id="inlineVideo"></div>
                                                </div>
												
                                                <div id="addNote" className="col-md-8">
                                                    <label>Additional Notes:</label>
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        wrapperStyle={{ border: "1px solid black", marginBottom: "20px" }}
                                                        editorStyle={{ height: "300px", padding: "10px",maxWidth:'100%',maxHeight:'100%'}}
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}
                                                        toolbar={{
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        history: { inDropdown: true },
                                                        image: { uploadCallback: this._uploadImageCallBack, alt: { present: true, mandatory: false },previewImage: true },
                                                        inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
                                                        }}
                                                    />
                                                </div>
                                                <div id="addTag" className="col-md-8">
                                                    <label>{"<<Tags>>"}</label>
                                                    <input type="text" className="form-control" name="addTagsText" onChange={this.getValue}/>
                                                </div>
												<div className="col-md-8">
													<div className="validation vali-1">
														<label> Choose Validation Type: </label>
														<select className="form-control validation-box" id="sel1" name="validationType" onChange={this.getValue}>
															<option value="V1">V1 Text</option>
															<option value="V2">V2 Text Alphabet Only (no numbers)</option>
															<option value="V3">V3 Whole +Numbers and Zero</option>
															<option value="V4">V4 Whole +Numbers no Zero</option>
															<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
															<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
															<option value="V7">V7 Percentage Positive Only</option>
															<option value="V8">V8 Fractions Positive Only</option>
															<option value="V9">V9 Dates Past</option>
															<option value="V10">V10	Dates Today Only</option>
															<option value="V11">V11	Dates Future</option>
															<option value="V12">V12	Time hh:mm:ss:ssss</option>
															<option value="V13">V13 Time ss:ssss</option>
														</select>
													</div>
													{showMinMax ?
													<div className="vali-1">
													<div className="validation">
														<label>{"Min Value:"}</label>
														<input type="text" className="form-control min-val" name="minValue" onChange={this.getValue}/>
													</div>
													<div className="validation">
														<label>{"Max Value:"}</label>
														<input type="text" className="form-control max-val" name="maxValue" onChange={this.getValue}/>
													</div>
													
													</div>
													:null}
                                                </div>
                                            </div>)
                                        :null}
										
										<div className="row" style={{marginTop:'10px'}}>
                                            <div className="col-md-3">
                                                <label> Public Field Bank: </label>
												<select className="form-control" id="sel1" name="PFB" onChange={this.getValue}>
													<option value="N">No</option>
													<option value="Y">Yes</option>
												</select>
                                            </div>
											<div className="col-md-3">
                                                <label> Include Tracking Code </label>
												<select className="form-control company-select" id="sel1" name="trackingCodeName" onChange={this.getValue}>
													<option value="">Please Select</option>
													{trackingCode.map((code, i) => (
														<option value={code}>{code}</option>
													))}
													{newTrackingCode ? 
													<option value={newTrackingCode}>{newTrackingCode}</option>
													:''}
												</select>
												<div className="col-md-company">
													<button type="button" data-target="#addTrackModal" data-toggle="modal" className="btn btn-primary mb-2">
														<i className="fas fa-plus"></i>
													</button>
												</div>
                                            </div>
                                        </div>
										{showCompany ?
										<div>
										<div className="row" style={{marginTop:'10px'}}>
                                            <div className="col-md-3">
                                                <label> Company Name: </label>
												<select className="form-control company-select" id="sel1" name="companyName" onChange={this.getValue}>
													<option value="">Please Select</option>
													{companies.map((company, i) => (
														<option value={company}>{company}</option>
													))}
													{newCompanyName ? 
													<option value={newCompanyName}>{newCompanyName}</option>
													:''}
												</select>
												<div className="col-md-company">
													<button type="button" data-target="#addComModal" data-toggle="modal" className="btn btn-primary mb-2">
														<i className="fas fa-plus"></i>
													</button>
												</div>
                                            </div>
										</div>
										<div className="row" style={{marginTop:'10px'}}>
                                           <div className="col-md-8" style={{marginTop:'10px'}}>
                                                    <label>Custom Field Note(only allow max 100 characters):</label>
                                                    <textarea className="form-control" name="customFieldNote"  onChange={this.getValue} type="text" maxLength={100} ></textarea>
                                            </div>
                                        </div>
                                        </div>
										:null}
                                        <div className="row" style={{marginTop:'10px'}}>
                                            <div className="col-md-1">
                                                <button className="btn btn-primary" id="saveBtn" onClick={this.saveNewData} >Save</button>
                                            </div>
                                            <div className="col-md-1">
                                                <button className="btn btn-danger" onClick={this.dataFilter} value="view_all">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        {setKiosk ? null :'Include'}
                                                    </div>
                                                    <div className="col-md-3">
                                                        {setKiosk ? null : 'Required'}
                                                    </div>
                                                    <div className="col-md-3">
                                                        {setKiosk ? null : 'Delegate'}
                                                    </div>
                                                    <div className="col-md-3">
                                                        {setKiosk ? null : 'Insert Field'}
                                                    </div>
                                                </div>
                                            </div>
                                            {setKiosk ? 
                                                <div className="col-md-3 offset-3">
                                                    <button className="btn btn-primary" style={{float:'right'}} onClick={()=>this.props.showKioskModal(this.state.checkedKiosk,this.props.selectedSheet1)}>End Delegate Mode</button>
                                                </div>
                                            :
                                                <div className="col-md-7 btn-box-center">
                                                    <button className="btn mr-2 mb-2" data-target="#filesModal" data-toggle="modal" onClick={()=>this.childFiles.current.getFiles()}><i className='fa fa-file' style={{fontSize:'24px'}}></i> </button>
                                                    <button className="btn mr-2 mb-2" data-target="#ImagesModal" data-toggle="modal" onClick={()=>this.childImages.current.getImages()}><i className='fas fa-images' style={{fontSize:'24px'}}></i> </button>
    												<button className="btn btn-primary mr-2 mb-2" onClick={() => { if(window.confirm('Do you want to include all?')) this.setAllChecked() }}>Include All </button>
													<button className="btn btn-danger mr-2 mb-2" onClick={() => { if(window.confirm('Do you want to exclude all?')) this.setAllUnChecked() }}>Exclude All </button>
                                                    <Button variant="danger" type="button" className="btn btn-danger mr-2 mb-2" onClick={() => { if(window.confirm('Reset will erase all work done to improve this form. Export in Review/Approve mode to save a copy of your work unless you want to permanently clear your improved data. Continue with Reset?')) handleSubmitReset(this.props.selectedSheet, this.props.selDropOpt, this.props.selectedSheetId, this.props.allData,this.props.selectReviewOpt) }}> Reset Improved</Button>
                                                    <button className="btn btn-primary mb-2" type="button"  data-target="#myModal" data-toggle="modal">Delegate Mode </button>
    													
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <MainData 
                                    sheetData = {this.props.sheetData}
                                    moduleName={moduleName} 
                                    myFunction= {this.myFunction}
                                    allData = {allData}
                                    showSign={this.props.showSign}
                                    handleInclude={this.handleInclude}
                                    handleKiosk ={this.handleKiosk}
                                    handleRequire={this.handleRequire}
                                    feedsResponse={this.state.feedsResponse}
                                    handleInsertField={this.handleInsertField}
                                    showAddUsersModal = {this.showAddUsersModal}
                                    selDropOpt = {selDropOpt}
                                    setKiosk = {this.props.setKiosk}
                                    kioskResponse={kioskResponse}
                                    getDirectoryFiles={getDirectoryFiles}
                                    selectedSheet = {selectedSheet}
                                    selectKioskUsers={this.selectKioskUsers}
                                    saveKioskUsersType = {this.saveKioskUsersType}
                                    kioskUsers = {this.state.kioskUsers}
                                    setDefaultCheckboxes = {this.setDefaultCheckboxes}
                                    selectedOpt = {this.props.selectedOpt}
                                    apiPdfs = {this.props.apiPdfs}
                                    kioskUsersData={this.state.kioskUsersData}
                                    checkedInc={this.state.checkedInc}
                                    checkedKiosk ={checkedKiosk}
                                    checkedReq={this.state.checkedReq}/>
                                <div className="row">
                                    {setKiosk ?
                                        null
                                    :
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-primary float-left export-btn" data-toggle="modal" data-target="#SaveSheetName" onClick={()=>this.props.updatedData()}>Export New Data </button>
                                            <button type="button" className="btn btn-primary float-right" onClick={this.saveProgData}>Save</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                :null}
                <div className="modal" id="SaveSheetName">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Enter Sheet Name</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
								<div className="col-md-12">
                                <label className="label-control"> Enter a sheet name </label>
                                <input type="text" name="setSheetName" value ={this.state.setSheetName} onChange={this.exportSheetName} maxLength={20} className="form-control"/>
								</div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-6">
											<div className="row">
											<div className="col-md-10">
											<label className="label-control"> Select a Facility ID </label>
											<select className="form-control facility-id" name="setFacilityID" onChange={this.exportFacilityID}>
												<option value="">Please Select</option>
												{companies.map((company, i) => (
													<option value={company}>{company}</option>
												))}
												{newCompanyName ? 
												<option value={newCompanyName}>{newCompanyName}</option>
												:''}
											</select>
											</div>
											<div className="col-md-2 add-company-export">
												<button type="button" data-target="#addComModal" data-toggle="modal" className="btn btn-primary mb-2">
													<i className="fas fa-plus"></i>
												</button>
											</div>
											</div>
										</div>
										<div className="col-md-6">
										<label className="label-control"> Enter a Group ID </label>
										<input type="text" name="setGroupID" value ={this.state.setGroupID} onChange={this.exportsetGroupID} maxLength={9} className="form-control facility-id"/>
										</div>
									</div>
								</div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary float-left" onClick={()=>this.exportSheetData(this.state.setSheetName,this.props.selDropOpt,this.props.selectedSheetId,this.state.setFacilityID,this.state.setGroupID)} data-dismiss="modal">Save</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="code" style={{display: "none"}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="col-md-12">
                                    <div className="row">
                                        <h5>Field Name: {QuestionId}</h5>
                                    </div>
                                    <div className="row">
									 <p><b>Validation Type:</b> {this.state.valTypeText}</p>
                                    </div>
                                    {/*<div className="row">
                                        <p><b>Scope:</b></p>
                                    </div>*/}
                                    <div className="row">
                                        <p><b>Type:</b> {queType ? queType:null}{queDescription ? ' - '+queDescription : null} </p>&nbsp;&nbsp;
                                        {queDescription ? <button type="button" className="btn btn-primary mb-2" id='showimage' data-toggle="modal" data-target="#myImageModal">
                                              Show Image
                                        </button>:null}
                                    </div>
                                </div>
                                {btnClicked == "edit" ?
                                <div className="col-md-12">
                                    <div className="row">
                                        <p className="mb-0"><b>Preconditions:</b>  {this.state.preCondition} {showPreCon ? (<div> <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={() => showParentField(this.state.preCondition,this.props.allData,this.generateQuestion)} data-target="#showMainField">{this.state.showFieldText? 'Show Field' : 'Hide Field'}</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => this.preConFunc(this.state.preCondition) }> Edit Precondition</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => { removePrecondition(this.props.allData,this.state.QueSaveId,this.props.selectedSheet,this.props.updatedData)}}> Remove Precondition</button> </div> ):<button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.preConFunc()}> 
                                            Add Precondition</button>}</p>
                                    </div>
                                </div>:null}
                                {showPreDiv ? ( 
                                <div className="col-md-12">
                                    <div className="col-md-12">
                                        {this.displayAddData}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Field Name:</label>
                                                <Dropdown1 
                                                    mainQueKey={this.props.mainQueKey}
                                                    queNo={this.state.queNo1} 
                                                    addPrecondition={this.addPrecondition}/>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Conditional Option:</label>
                                                <textarea className="form-control" rows="4" col="4" id="comment" value ={this.state.preCondVal} name="text" readOnly></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>):null}
                                <hr/>
                                <div>
                                    <p><b>Field Input / Output Preview</b></p>
									<div className="output-pre" id="display-data-Container">
										<div className="col-md-12">
                                        <h5>{this.state.Question}</h5>
										</div>
										<div className="col-md-12">
											<p>{this.state.Answer}</p>
										</div>
										<p><b>Output Text:</b> { this.state.Location }</p>
									</div>			
                                </div>
                            
								{customTitleDiv ? (
									<div>
											<label>Edit Title:</label>
											<textarea name="changeQueInpText" value={this.state.changeQueInpText} rows="4" onChange={this.getValue} className="form-control" />
									</div>)
								:null}
								{customTextDiv ? (
									<div>
										<label>Edit Text:</label>
										<textarea name="changeQueInpText" value={this.state.changeQueInpText} rows="4" onChange={this.getValue} className="form-control" />
									</div>)
								:null}
								{customHeaderDiv? (
									<div>
										<h5>Header : {this.state.Question}</h5>
										<label>Select Header Type:</label>
										<select className="form-control" name="chooseAnsType" value={this.state.chooseAnsType} onChange={this.getValue}>
											<option value="">Please Select</option>
											<option value='Child Header 1'>Child Header 1</option>
											<option value='Child Header 2'>Child Header 2</option>
											<option value='Child Header 3'>Child Header 3</option>
										</select>
										<label>Edit Text:</label>
										<input value={this.state.changeQueInpText} name="changeQueInpText" rows="8" onChange={this.getValue} className="form-control" />
									</div>): null}
								{customField ? (
									<div>
										<QAction
											defaultProps = {this.state} 
											handleChange={this.handleChange}
											handleClearNote={this.handleClearNote}
											rowAllData={rowAllData} 
											companies={companies} 
											trackingCode={trackingCode} 
											getValue={this.getValue}
											mainQues={this.props.mainQues}
											_uploadImageCallBack = {this._uploadImageCallBack}
											onEditorStateChange ={this.onEditorStateChange}
											queActionDataClear={this.state.queActionDataClear}
											/>
									</div>
									):null}
							</div>
                            <div className="modal-footer">
                                {btnClicked =="edit" ?
                                    <button className="btn btn-primary float-left" type="button" onClick={this.handleSubmit}>Save </button>
                                :
                                    <button className="btn btn-primary float-left" type="button" onClick={this.handleDelete}>Delete </button>}
                                <button type="button" className="btn btn-danger"  data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="myImageModal" style={{zIndex:'1051'}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Image</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <img src={this.state.showImage} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="selectUsersType">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content kioskPreview">
                            <div className="modal-header">
                                <h4 className="modal-title">Delegate Mode Preview</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                            { sheetData ? 
                               <KioskData 
                                    sheetData = {sheetData}
                                    showSign={showSign}
                                    checkedReq = {this.state.checkedReq}
                                    kioskPreviewData = {this.state.kioskPreviewData}
                                    moduleName={moduleName}
                                    allData = {allData} 
                                    kioskResponse={kioskResponse}
                                    />
                            : null }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" style={{textAlign:'left'}}>Ok </button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="myModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Do you want to go Delegate Mode ?</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <label>Select Users Class</label>
                            { sheetData ?
							<KioskDrop
                                multiple ='false'
                                selectKioskUsers={this.selectKioskUsers}
                                kioskType={kioskType}
                                kioskUsers = {this.state.kioskUsers}/>
							:null}
                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-primary float-left" onClick = {()=>this.props.goKisko(this.props.allData,this.state.kioskUsers,this.state.selOptions,this.props.selectedSheet1)} >Ok</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                          </div>

                        </div>
                    </div>
                </div>
                <div className="modal" id="ImagesModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Upload Images</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <MultipleImageUpload cropConfig={{crop:crop}} ref={this.childImages}/>
                          </div>
                          <div className="modal-footer">
                              
                                <div className="image-text" id="ImageText"></div>
                                <div className="popup-btn">
                                    <button type="button" className="btn btn-primary float-left" data-target="#addImagesModal" data-toggle="modal">Add Image</button>
                                    <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                                </div>
                            
                          
                          </div>

                        </div>
                    </div>
                </div>
                <div className="modal" id="filesModal">
                    <div className="modal-dialog modal-lg custom-modal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Upload Files</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <FileUpload cropConfig={{crop:crop}} ref={this.childFiles}/>
                          </div>
                          <div className="modal-footer">
                              
                                <div className="image-text" id="fileText"></div>
                                <div className="popup-btn">
                                    <button type="button" className="btn btn-primary float-left" data-target="#addFilesModal" data-toggle="modal">Add File</button>
                                    <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                                </div>
                            
                          
                          </div>

                        </div>
                    </div>
                </div>
				
				<div className="modal add-company" id="addComModal">
                    <div className="modal-dialog modal-lg custom-modal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Add New Company</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
								<input type="text" name="addCompany" onChange={this.addCompany} className="form-control" placeholder="Enter company name"/>
                          </div>
                          <div className="modal-footer">
                                <div className="image-text" id="fileText"></div>
                                <div className="popup-btn-com">
                                    <button type="button" className="btn btn-primary float-left" onClick={this.addNewCompany}>Save</button>
                                    <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                                </div>
                          </div>

                        </div>
                    </div>
                </div>
				
				<div className="modal add-company" id="addTrackModal">
                    <div className="modal-dialog modal-lg custom-modal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Add New Tracking Code</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
								<input type="text" name="addCompany" onChange={this.addTracking} className="form-control" placeholder="Enter Tracking Code"/>
                          </div>
                          <div className="modal-footer">
                                <div className="image-text" id="fileText"></div>
                                <div className="popup-btn-com">
                                    <button type="button" className="btn btn-primary float-left" onClick={this.addNewTracking}>Save</button>
                                    <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                                </div>
                          </div>

                        </div>
                    </div>
                </div>
                
                <div className="modal" id="directory">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Upload Images</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <form>
                                <div className="row">
                                    {(fileArray || []).map(url => (
                                        <div className="col-md-4">
                                            <img className="view-image" id={'image_'+url.id} src={url.path} alt="..." style={{cursor: 'pointer'}} width="259px" height="200px" onClick = {()=>this.getImagePath(url.path,url.id)}/>
                                        </div>
                                    ))}
									<input type="hidden" id="imageId" value="" />
                                </div>
                            </form >
                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-primary float-left" onClick={this.uploadImagePath}>Upload</button>
                            <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                          </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FormBuilder;