import React, { Fragment, useState } from 'react';

import { makeStyles }   from '@material-ui/core/styles';
import TextField        from '@material-ui/core/TextField';
import Button           from '@material-ui/core/Button';
import Grid             from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Snackbar         from '@material-ui/core/Snackbar';
import Box              from '@material-ui/core/Box';
import { Snackbar } from '@material-ui/core';

import AddIcon                from '@material-ui/icons/Add';
import FilterNoneIcon         from '@material-ui/icons/FilterNone';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';

import config from "../../config";
import axios  from 'axios';
import { CopyToClipboard }    from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '10px'
    },
    fileGrid: {
        border: '1px solid #c5c5c5',
        //borderRadius: '5px',
        margin: '10px -10px',
        //paddingBottom: '25px',
        //maxWidth: 345,
    },
    inputFile: {
        marginTop: '20px',
    },
    uploadButton: {
        borderRadius: '20px',
    },
    spinner: {
        marginLeft: '15px'
    }
}));

export default function FileUploadType(props) {
    
    const classes = useStyles();

    const [name, setName]             = useState('');
    const [description, setDescription] = useState('');
    const [fileName, setFileName]       = useState('');
    const [file, setfile]     = useState('');

    const [showSpinner, setShowSpinner]     = useState(false);
    const [showSpinner2, setShowSpinner2]   = useState(false)
    const [uploadSuccess, setUploadSuccess] = useState(false);

    //const [showUploadButton, setShowUploadButton] = useState(false);
	const fieldId = props.fieldId;
    const [showUploadButton, setShowUploadButton] = useState(true);
	
	const [fileUrl, setFileUrl] = useState(props.dataField);
	
    const uploadSubmit = () => {
        
        let formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        formData.append('description', description);
        setShowSpinner(true);
        let url = config.API_URL+'uploadfile/file_upload1.php';
        
        axios({
            method: 'POST',
            url: config.API_URL+'uploadfile/file_upload1.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            
            let data = response.data;
            
                let name =data[0]
                let description =data[1]
                let path =data[2]
                //let date =data[3]
                setFileUrl(path);
                setUploadSuccess(false);
                setShowUploadButton(true);
                props.uploadFilePath(fieldId, name, description, path, fileUrl);
                setShowSpinner2(false);
                setShowSpinner(false);
            
        })
        .catch(error => {
            setShowSpinner(false);
            console.log('error::', error);

        }) 
    }
    const showUpload = () => {
        setUploadSuccess(true);
        setShowUploadButton(false);
    }
    const handleChange = (files) => {
        setFileName(files[0].name);
        setfile(files[0]);
    }
	
	const deleteUpload = () => {

        setFileName('');
        setName('');
        setDescription('');
		props.deleteFile(fieldId);
	}
	
    return (
        <Fragment>
            <form className={classes.root} noValidate autoComplete="off">
            { showUploadButton && <div><Button id={"uploadbtn-"+fieldId} variant="contained" color="primary" onClick={() =>showUpload()}>{fileUrl ? 'Replace File' : 'Upload File'}</Button>{fileUrl ? <div><a id={"download-"+fieldId} href={fileUrl} className="file-link btn code-dialog" target="_blank" download><i className="fa fa-cloud-download" aria-hidden="true"></i></a> <a id={"open-"+fieldId} href={fileUrl} target="_blank" className="file-link btn code-dialog"><i className="fas fa-copy" aria-hidden="true"></i></a> <button id={"deletebtn-"+fieldId} type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() =>deleteUpload()}><i className="fa fa-trash"></i></button></div> : null}</div>
                }
                { uploadSuccess && <Grid container spacing={3} className={classes.fileGrid}>
                    <Grid item xs={12} className={classes.inputFile}>
                        <Button
                            variant="contained"
                            component="label"
                            color="secondary"
                            className={classes.uploadButton}
                            >
                            <AddIcon /> Select a File
                            <input
                                type="file"
                                name="file" 
                                style={{ display: "none" }}
                                onChange={ (e) => handleChange(e.target.files) }
                            />
                        </Button>
                        { fileName && (<Box component="span" display="block">{fileName}</Box>) }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="name" label="Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="description" label="Description" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() =>uploadSubmit()}>Upload File{ showSpinner && <CircularProgress disableShrink size="20px" className={classes.spinner} /> }</Button>
                    </Grid>
                </Grid> }
                
            </form>
        </Fragment>
    );
}
