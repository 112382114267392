import React, { Component } from 'react';
import config from '../../config';
import axios,{post} from 'axios';
import $ from 'jquery';
import { load, uploadFilePath, getSheetType, getSheetTypeFacility, getPdfFromApi, getSheetsById, getConfigFacilityId, getConfigData } from '../../Helpers/Common';
import { gapi } from 'gapi-script';
import TopFilter from '../Header/TopFilter'
import { Form,Button } from 'react-bootstrap';
import { KioskDrop } from "../FormBuilder/KioskDrop";
import KioskData from "../TestForm/KioskData";
import PdfData from "../TestForm/PdfData";
import MainData from "../../component/MainData/MainData";
import ClinicalTracks from '../ClinicalTracks';
import guid from "../../Helpers/GUID";
import emailjs from 'emailjs-com';
import ProgressBar from "../progressBar";
class TestForm extends Component{
    
	constructor(props) {
        super(props)
        this.state = {
            test_forms:[1,2,3],
            sheetData:[],
            sheetsName: [],
            current_user: [],
            assoType:[],
            assoDeviceType:[],
            assoVisitType:[],
            mod:'test_forms',
            kioskUsers:['CPO'],
			apiPdfs:[],
            kioskUserName:'',
            assoDeviceTypeName:'',
            assoVisitTypeName:'',
            kioskUsersData:new Map(),
            kioskResponse:new Map(),
            userClassResponse:new Map(),
            rResponse:new Map(),
            checkedKiosk:new Map(),
            checkedReq:new Map(),
            checkedFeeds:new Map(),
			checkedNeeds:new Map(),
            feedsResponse:new Map(),
            kioskPreviewData:[],
            setKiosk:false,
            kioskMode:false,
			outputText:false,
            showKioskBtn:false,
            selDropOpt:'original',
            moduleName:'test_forms',
            reviewSheetsName:[],
            userAccessSheets:[],
            reqCount: 0,
            reqCmpCount: 0,
            mdsGuid:'',
            emailMessage:'',
            emailId:'',
            errorMessage:'',
            successMessage:'',
            zoomLink:'',
			configData:[],
			//sheetConfigId:config.spreadsheetId,
			sheetConfigId:config.mdsSpreadsheetId,
			sheetConfigMod:'parentMds',
        }
		
        this.pdfArray = [];
		this.fileArray = [];
		if(localStorage.getItem('username')){
            this.username = localStorage.getItem('username');
        }else{
            this.props.history.push({
			  pathname: '/beta',
			  state: { moduleName: 'test_forms' },
			});
			
        }
        this.reviewSheetNames = this.reviewSheetNames.bind(this);
    }
	
	
	handleChangeSheet(event){
    	this.setState({renderSheet:event.target.value});
    	this.changeSheetId(event.target.value);
    }
	
	changeSheetId=(sheetMod)=>{
		/* let sheetConfigId = config.spreadsheetId;
		if(sheetMod == 'parentMds'){
			sheetConfigId = config.mdsSpreadsheetId;
		}else if(sheetMod == 'fieldSet'){
			sheetConfigId = config.childSpreadsheetId;
		} */
		let sheetConfigId = config.mdsSpreadsheetId;
		if(sheetMod == 'fieldSet'){
			sheetConfigId = config.childSpreadsheetId;
		} 
		
		this.setState({sheetConfigId,sheetConfigMod:sheetMod});
		
		
		gapi.client.init({
           apiKey: config.apiKey,
           'clientId': config.client_id,
           'scope': 'https://www.googleapis.com/auth/spreadsheets',
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
            load(this.onLoad,this.state.sheetConfigId);
        });
	}
	
    componentDidMount() {
        gapi.load("client", this.initClient);
		
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = config.API_URL+'pdf/getPdf.php';
        axios.get(url, con)
        .then(response => {
			let pdfArray = response.data;
			this.setState({pdfArray});
        })
    }
	
    initClient = () => {
        
        gapi.client.init({
           apiKey: config.apiKey,
           'clientId': config.client_id,
           'scope': 'https://www.googleapis.com/auth/spreadsheets',
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
            load(this.onLoad,this.state.sheetConfigId);
        });
		
		gapi.client.init({
		   apiKey: config.apiKey,
		   discoveryDocs: config.discoveryDocs,
		})
		.then(() => {
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: config.spreadsheetId,
					range:'User-Login',
				})
				.then(
					response => {
						let users = response.result.values;
						users.map((user,i)=>{
							if(this.username == user[0]){
								this.setState({ current_user:user });
							}
						});
						
					},
					response => {
						//alert(response.result.error.message);
						console.log('error',response.result.error);
					}
				);
			});
		
		});
		
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: config.spreadsheetId,
				range:'Company Name', 
			})
			.then(
				response => {
					const companyName = response.result.values;
					this.setState({ companies:companyName });
					
				},function(reason) {
					alert(reason.result.error.message);
				}
			);
		});
    };
    
    onLoad = (data, error) => {
		
		let user = this.state.current_user;
		
		if (data) {
            const sheetsName = data.sheetsName.slice(0,3);
            const sheetsId = data.sheetsId.slice(0,3);
            let sheetpro = [];
            let sheetsNames = data.sheetsName;
			
            { sheetsNames.map((sheet, i) => {
				
					if(typeof(user[4]) != "undefined" && user[4] != 'All' && user[4] != ''){
						let viewAbleSheet = user[4].split(',');
						if(viewAbleSheet.indexOf(sheet) == -1){
							return true;
						}
					}
					
                    let sh = {id:data.sheetsId[i], name:sheet, type:'', deviceType:'', VisitType:'',visibility:''};
                    //if(i <= config.showSheetInDrop){
                        data.sheetType.map((sheetName,i)=>{
                            if(sheetName.visibility == 'TRUE' && sheet == sheetName.name ){
                                sh = {id:data.sheetsId[i], name:sheet, type:data.sheetType[i].type, deviceType:data.sheetType[i].deviceType, visitType:data.sheetType[i].visitType, visibility:data.sheetType[i].visibility};
                            }
                        })
                        if(sh.type && sh.deviceType && sh.visitType && sh.visibility){
							sheetpro.push(sh);
						}
                    //}
            })};
			
			let visibileSheet = [];
			{ sheetpro.map((sheet, i) => {
				if(sheet.visibility == 'TRUE' && visibileSheet.length <= config.showSheetInDrop){
					visibileSheet.push(sheet);
				}
			})};
			
			
			this.setState({ sheetsName:visibileSheet });
            this.setState({ sheetsNameOrig:visibileSheet });
			if(visibileSheet[0]){
				let firstSheet = visibileSheet[0].name;
				//this.loadFilter(firstSheet);
			}
			
        } else {
            this.setState({ error });
        }
		
		let that = this;
		getPdfFromApi().then(function(response) {
			that.setState({apiPdfs:response});
		}, function(error) {
		  console.error("Failed!", error);
		})
		
    };
	
	onSelect = (sheetsName, selectedSheetId) => {
		
        let spreadsheetId = this.state.sheetConfigId;
        var that = this;
        var  singleSheetName = sheetsName;
        gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: spreadsheetId,
                range:singleSheetName, 
            })
            .then(
                response => {
					this.emptyState();
					
                    const allData = response.result.values;
					if(allData.length > 0){
						let mdsGuid = allData[1][91];
						this.setState({mdsGuid});
						
						let kioskType = allData[1][119].split(',');
						let username = this.username;
						this.setState({kioskUserName:'Practitioner',kioskUsers: ['CPO'],showKioskBtn:false,setKiosk:false});
						kioskType.map((type,i)=>{
							type = type.split(':');
							let user = username.split('-');
							if(user[0] == type[1] && (username == 'CC-1' || username == 'Res-1' || username == 'Pat-1' || username == 'MD-1' || username == 'PT-1')){
								this.setState({kioskUserName:type[0],kioskUsers: [type[1]],showKioskBtn:true,setKiosk:true});
							}
						})
						
					}
					
					allData.shift();
                    this.setState({allData});
                    this.reviewSheetNames(this.state.mod);
                    this._answer(allData)
					
					
                },function(reason) {
					alert(reason.result.error.message);
				}
            );
        });
        this.setState({selectedSheet:sheetsName,mainSheet:sheetsName,selDropOpt:'original',reviewVer:'original',selectedSheetId,orginalSelectedSheet:sheetsName});
		$('input[name=selOptions]').prop( "checked", false );
		setTimeout(function(){
			$('input[name=selOptions][value="view_all"]').prop( "checked", true );	
			//$("#viewAll").attr('checked', true).trigger('click');
			that.dataFilter(null,'view_all');
		}, 3000);
			
    }
	
    loadFilter(sheet){
		let sheetConfigId = this.state.sheetConfigId;
        let params1 =  {
            spreadsheetId: sheetConfigId,
            range:sheet+'!CW2:CY2',
        };
        var that = this;
        var assoType =[];
        var assoDeviceType =[];
        var assoVisitType =[];
            let getRequest = gapi.client.sheets.spreadsheets.values.get(params1);
            getRequest.then(function(response) {
                var getData= response.result.values;
				
                if(getData[0][0]){
                    var arr = getData[0][0].split(',');
					arr.map((t,i)=>{
                        assoType.push(t);
                    })
                    
                }
                if(getData[0][1]){
                    var arr = getData[0][1].split(',');
                    arr.map((t,i)=>{
                        assoDeviceType.push(t);
                    })
                    
                    
                        
                }
                if(getData[0][2]){
                    var arr = getData[0][2].split(',');
                    arr.map((t,i)=>{
                        assoVisitType.push(t);
                    })
                    
                            
                }
				
                that.setState({assoType,assoVisitType,assoDeviceType});
            })
    }
	
    logout = () => {
        //localStorage.removeItem('id');
        localStorage.removeItem('username');
        this.props.history.push('/beta');
    }
	
    getSheetValue=(event)=>{
        let sheetId = event.target.options[event.target.selectedIndex].dataset.id;
        this.setState({selectedSheet1:event.target.value});
        this.setState({selectedSheetId:sheetId});
        this.onSelect(event.target.value, sheetId);
    }
	
    reviewSheetNames(mod){
		
		let configName = 'TTEVAL-Config Files';
		gapi.client.init({
           apiKey: config.apiKey,
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
			gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: config.spreadsheetId,
					range:configName,
				})
				.then(response => {
					let sheetVal = response.result.values;
					let sheets = [];
					sheetVal[0].map((value, i) => {
						if(i > 0){
							let configId = value[4];
							let col = [];
							sheetVal.map((val, j) => {
								let column = val[i];
								if(column){
									col.push(column);
								}
							});
							
							sheets.push(col);
						}
					});
					
					let selectedSheet = this.state.selectedSheet;
					
					sheets.map((sheet, i) => {
						if(selectedSheet != sheet[1]){
							delete sheets[i];
						}
					})
					
					sheets = sheets.filter(function (el) {
					  return el != null;
					});
					
					let sheetpr = [];
					
					sheets.map((sheet, i) => {
						let sht = {name:sheet[0], type:sheet[6], deviceType:sheet[7], visitType:sheet[8], mdsGuid:sheet[2], configGuid:sheet[4], date:sheet[3], facilityId:sheet[9]};
						
						sheetpr.push(sht);

					});
					
					/* let filters = that.state.filters;
					let sheetpro = [];
					
					if(filters){
						sheetpro = that.filterPlainArray(sheetpr, filters);
					}else{
						sheetpro = sheetpr;
					} */
					//console.log('sheetpro->',sheetpro);
					
					this.setState({configSheets:sheets, reviewSheetsName:sheetpr, reviewSheetsNameOringn:sheetpr});
					
					//console.log('sheetpr->',sheetpr);
				});
			});	
		}); 
        
    }
	
	deleteData(allDatas){
        var branch= [];

        for(let i = 0; i < allDatas.length; i++){
            let row = Object.assign([], allDatas[i]);
            if(row.sub){
                delete row.sub;
            }
            branch.push(row);
        }
 
        return branch;
    }
    
	getRsponseValue=(Value, Parent, Id)=>{
		if(Value){
			let isValid = this.validationValue(Id, Value);
			if(!isValid){
				return false;
			}
		}

		let kioskResponse = this.state.kioskResponse;
        let userClassResponse = this.state.userClassResponse;
        if(Value){
            kioskResponse.set(Id, Value)
            userClassResponse.set(Id, this.state.kioskUsers)
        }else{  
            kioskResponse.delete(Id)    
            userClassResponse.delete(Id)    
        }
        //console.log(kioskResponse);
        this.setState({kioskResponse:kioskResponse})
        this.setState({userClassResponse:userClassResponse}) 
        
        let reqCmpCount2 =[];
        let checkedReq = this.state.checkedReq;
        
        let res = checkedReq.get(Id);
        
        let rResponse = this.state.rResponse;
        if(typeof res != "undefined"){
            
            if(Value){
                rResponse.set(Id, Value)
            }else{  
                rResponse.delete(Id)    
            }
            
            this.setState({rResponse:rResponse})
        }
		
		this.calculationResponse(Id, Value);
		this.wCalculationResponse(Id, Value);
		
		let kioskUsers = this.state.kioskUsers;

        this.state.mainData.map((rowDatas,i)=>{
			
			let configDataRow = '';
			let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
			if(columnName){
				configDataRow = columnName.split(',');
				configDataRow.splice(0, 9);
			}
			
            let cRes = this.state.rResponse.get(rowDatas[0]);
            if(typeof cRes != "undefined" && cRes != '' && configDataRow.indexOf(kioskUsers[0]) != -1){
                reqCmpCount2.push(rowDatas[0]);
            }
        })
		this.setState({reqCmpCount:reqCmpCount2.length})

    }
	
	validationValue=(Id, value)=>{
		let isValid = true;
		this.state.mainData.map((rowDatas,i)=>{
			
			let qType = rowDatas[5].toLowerCase();
			if((rowDatas[0] == Id) && (qType == 'q6' || qType == 'q7' || qType == 'q8' || qType == 'q9' || qType == 'q10' || qType == 'timer')){
				let min = rowDatas[50];
				let max = rowDatas[51];
				let ex = '';
				if(rowDatas[48]){
					switch (rowDatas[48].toLowerCase()) {
						case 'v1' :
							ex = /^[ A-Za-z0-9_@./&+-,.]*$/;
							if(ex.test(value)==true){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								$('.res_'+Id).val('');
								$('.res_'+Id).addClass('error');
								alert('No special characters allowed, only alphanumeric.');
							}
							break;
						case 'v2' :
							ex = /^[ A-Za-z_,. ]*$/;
							if(ex.test(value)==true){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								$('.res_'+Id).val('');
								$('.res_'+Id).addClass('error');
								alert('No special characters allowed, only text.');
							}
							break;
						case 'v3' :
						case 'v4' :
							ex = /^[-+]?[0-9]+\.[0-9]+$/;
							let found = value.match( ex );
							
							if(Number(value) >= Number(min) && Number(value) <= Number(max) && !found){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								$('.res_'+Id).val('');
								$('.res_'+Id).addClass('error');
								alert('The value should be greater than '+min+' and less than '+max+' and no negatives & no decimals.');
							}
							break;
						case 'v5' :
							ex = /^\d{0,11}(\.\d{0,1})?$/;
							if(Number(value) >= Number(min) && Number(value) <= Number(max) && ex.test(Number(value))==true){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								$('.res_'+Id).val('');
								$('.res_'+Id).addClass('error');
								alert('The value should be greater than '+min+' and less than '+max+ ' and only 1 decimal place.');
							}
							break;
						case 'v6' :
							ex = /^\d{0,9}(\.\d{0,2})?$/;
							if(Number(value) >= Number(min) && Number(value) <= Number(max) && ex.test(Number(value))==true){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								$('.res_'+Id).val('');
								$('.res_'+Id).addClass('error');
								alert('The value should be greater than '+min+' and less than '+max+ ' and only 2 decimal places.');
							}
							break;
						case 'v7' :
							if(Number(value) >= Number(0) && Number(value) <= Number(100)){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								$('.res_'+Id).val('');
								$('.res_'+Id).addClass('error');
								alert('The value should be greater than or equal 0% and less than or equal 100%.');
							}
							break;
						case 'v8' :
							ex = /[-]?[0-9]+[,.]?[0-9]*([\/][0-9]+[,.]?[0-9]*)*/;
							if(ex.test(value)==true){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								$('.res_'+Id).val('');
								$('.res_'+Id).addClass('error');
								alert('Please enter valid value.(e.g 1, 3/4, 7, 1/2.5)');
							}
							break;
						case 'v9' :
						
							var date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).split(',')[0];
							var dateParts = value.split("/");
							var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
							
							if(dateObject <= new Date(date)){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								alert('The Date should be less than or equal the current date.');
								setTimeout(function(){
									$('.res_'+Id).val('');
									$('.res_'+Id).addClass('error'); 
								}, 2000);
							}
							break;
						case 'v10' :
						
							var date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).split(',')[0];
							var dateParts = value.split("/");
							var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
							
							if(dateObject == new Date(date)){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								alert('The Date should be current date.');
								setTimeout(function(){
									$('.res_'+Id).val('');
									$('.res_'+Id).addClass('error'); 
								}, 2000);
							}
							break;
						case 'v11' :
						
							var date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).split(',')[0];
							var dateParts = value.split("/");
							var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
							
							if(dateObject >= new Date(date)){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								alert('The Date should be greater than or equal to current date.');
								setTimeout(function(){
									$('.res_'+Id).val('');
									$('.res_'+Id).addClass('error'); 
								}, 2000);
							}
							break;
						case 'v12' :
						case 'v13' :
							let str1 =  value.split(':');
							let str2 =  min.split(':');
							let str3 =  max.split(':');
							str1 = parseFloat(str1[0] * 3600 + str1[1] * 60 + str1[2]);
							str2 = parseFloat(str2[0] * 3600 + str2[1] * 60 + str2[2]);
							str3 = parseFloat(str3[0] * 3600 + str3[1] * 60 + str3[2]);
							
							if(Number(str1) >= Number(str2) && Number(str1) <= Number(str3)){
								isValid = true;
								$('.res_'+Id).removeClass('error');
							}else{
								isValid = false;
								$('.res_'+Id).val('');
								$('.res_'+Id).addClass('error');
								alert('The time should be greater than or equal '+min+' and less than or equal '+max+'. Please start again.');
							}
							break;
						default:
						isValid = true;
					}
				}				
			}
        })
		
		return isValid;
	}
	
	calculationResponse =(Id, value)=>{
		
		this.state.mainData.map((rowDatas,i)=>{
			if(rowDatas[5] == 'calculation' && rowDatas[14] == 'Y'){
				let calType = rowDatas[113];
				if(rowDatas[114]){
					let fields = rowDatas[114].split(',');
					let kioskResponse = this.state.kioskResponse;
					let fieldsRes = [];
					let timer = false;
					
					fields.map((field,j)=>{
						let res = kioskResponse.get(field);
						
						if(res){
							if(res.indexOf(':') != -1){
								timer = true;
								let str1 =  res.split(':');
								res = parseFloat((+str1[0]) * 60 * 60 + (+str1[1]) * 60 + (+str1[2]));
								fieldsRes.push(Number(res));
							}else{
								fieldsRes.push(Number(res));
							}
						}
					});
					
					var response = '';
					if(fieldsRes.length > 0){
						switch (calType.toLowerCase()) {
							
							case 'sum' :
								response = fieldsRes.reduce(function(a, b){
									return a + b;
								}, 0);
								break;
							case 'sub' :
								if(fieldsRes.length > 0){
									response = fieldsRes.reduce(myFunc)
									function myFunc(total, num) {
									  return total - num;
									}
								}
								break;
							case 'avg' :
								let average = fieldsRes => fieldsRes.reduce((a,b) => a + b, 0) / fieldsRes.length;
								response = average( fieldsRes );
								break;
							/* case 'max' :
								response = Math.max.apply(Math, fieldsRes);
								break;
							case 'min' :
								response = Math.min.apply(Math, fieldsRes);
								break; */
								
							case 'hight_number' :
								response = Math.max.apply(Math, fieldsRes);
								break;
							case 'low_number' :
								response = Math.min.apply(Math, fieldsRes);
								break;
							case 'shortest' :
								response = Math.min.apply(Math, fieldsRes);
								break;
							case 'longest' :
								response = Math.max.apply(Math, fieldsRes);
								break;
							default:
							response = 0;
						}
						
					}
					
					if(timer){
						let totalSeconds = response;
						let hours = Math.floor(totalSeconds / 3600);
						totalSeconds %= 3600;
						let minutes = Math.floor(totalSeconds / 60);
						let seconds = totalSeconds % 60;
						
						minutes = String(minutes).padStart(2, "0");
						hours = String(hours).padStart(2, "0");
						seconds = String(seconds).padStart(2, "0");
						
						let dec = (seconds - Math.floor(seconds)) !== 0;
						
						if(dec){	
							seconds = parseFloat(seconds).toFixed(1);
						}
						
						response = hours + ":" + minutes + ":" + seconds;
						
					}else{
						let decimal = (response - Math.floor(response)) !== 0;
						if(decimal){	
							response = response.toFixed(2);
						}
					}
					
					let userClassResponse = this.state.userClassResponse;
					if(response){
						$('.cal-res-'+rowDatas[0]).text( response );
						kioskResponse.set(rowDatas[0], response);
						userClassResponse.set(rowDatas[0], this.state.kioskUsers);
					}else{  
						kioskResponse.delete(rowDatas[0]);    
						userClassResponse.delete(rowDatas[0]);    
					}
					//console.log(kioskResponse);
					this.setState({kioskResponse:kioskResponse});
					this.setState({userClassResponse:userClassResponse});
				}

			}
			
		})
	}
	
	wCalculationResponse =(Id, value)=>{
		
		this.state.mainData.map((rowDatas,i)=>{
			if(rowDatas[5] == 'wcalculation' && rowDatas[14] == 'Y'){
				let calType = rowDatas[113];
				if(rowDatas[114]){
					let field = rowDatas[114];
					var allCheckbox = '';
					this.state.mainData.map((data,i)=>{
						if(field == data[0]){
							var options = data[6];
					
							if(options){    
								var str = options.split('_');
								allCheckbox = str[0].split(',');
							}
						}
						
					})
					
					
					let kioskResponse = this.state.kioskResponse;
					let fieldsRes = [];
					
					let res = kioskResponse.get(field);
					if(res){
						if(res.indexOf(',') != -1){
							res = res.split(',');
							res.map((val,i)=>{
								val = val.split(':');
								fieldsRes.push(Number(val[1]));
							})
							
						}else{
							
							let val = res.split(':');
							fieldsRes.push(Number(val[1]));
						}
					}
					
					var response = '';
					if(fieldsRes.length > 0){
						switch (calType.toLowerCase()) {
							
							case 'wsum' :
								response = fieldsRes.reduce(function(a, b){
									return a + b;
								}, 0);
								break;
							case 'wcount' :
								response = fieldsRes.length;
								break;
							case 'wavg' :
								let average = fieldsRes => fieldsRes.reduce((a,b) => a + b, 0) / fieldsRes.length;
								response = average( fieldsRes );
								let decimal = (response - Math.floor(response)) !== 0;
								if(decimal){	
									response = response.toFixed(2);
								}
								break;
							
							case 'wrcount' :
								response = fieldsRes.length+'/'+allCheckbox.length;
								break;
							
							default:
							response = 0;
						}
						
					}
					
					let userClassResponse = this.state.userClassResponse;
					if(response){
						$('.cal-res-'+rowDatas[0]).text( response );
						kioskResponse.set(rowDatas[0], response);
						userClassResponse.set(rowDatas[0], this.state.kioskUsers);
					}else{  
						kioskResponse.delete(rowDatas[0]);    
						userClassResponse.delete(rowDatas[0]);    
					}
					//console.log(kioskResponse);
					this.setState({kioskResponse:kioskResponse});
					this.setState({userClassResponse:userClassResponse});
				}

			}
			
		})
	}
	
    showSign=(type,id)=>{
        $('#'+type+id).css('display','block')

    }
	
	filterPlainArray(array, filters) {
	  const filterKeys = Object.keys(filters);
	  return array.filter(item => {
		// validates all filter criteria
		return filterKeys.every(key => {
		  
			if (!filters[key].length) return true;
			if (item[key] == undefined || item[key] == ''){
				item[key] = 'none'; 
			}
			
			var arr = item[key].split(',');
			var filVal = filters[key].find(filter => filter);
			if (filVal== undefined) return true;
		  
			if(arr.indexOf(filVal) !== -1){
				return true;
			}
	
		});
	  });
	}

    handleHeaderFilter=(event)=>{
        let name = event.target.name;
        let res = event.target.value;
        this.setState({[name]:event.target.value})
        var sheetpro = [];
        var sheetpro1 = [];
		
		let typeVal = '';
		
		let deviceVal = '';
		
		let visitVal = '';
		
		if(name == 'assoTypeName'){
			typeVal = event.target.value;
		}	
		else if(this.state.assoTypeName != 'undefined'){
			typeVal  = this.state.assoTypeName
		}
		
		if(name == 'assoDeviceTypeName'){
			deviceVal = event.target.value;
		}else if(this.state.assoDeviceTypeName != 'undefined'){
			deviceVal  = this.state.assoDeviceTypeName
		}
		
		if(name == 'assoVisitTypeName'){
			visitVal = event.target.value;
		}else if(this.state.assoVisitTypeName != 'undefined'){
			visitVal  = this.state.assoVisitTypeName
		}
		
		var allSheet = this.state.sheetsNameOrig;
		
		var allSheetOringm = this.state.reviewSheetsNameOringn;
		

		const filters = {
		   type: [typeVal],
		   deviceType: [deviceVal],
		   visitType: [visitVal],
		};

		sheetpro = this.filterPlainArray(allSheet, filters);
		
		if(allSheetOringm){
			sheetpro1 = this.filterPlainArray(allSheetOringm, filters);
		}
		
		this.setState({sheetsName:sheetpro,reviewSheetsName:sheetpro1, filters});

    }
	
    pasteLink=()=>{
        var link = config.zoomLink
        $('#inputLink').val(link); 
    }
    
    copyLink=()=>{
        var copyText = document.getElementById("inputLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
    }
    
    launchLink=()=>{
        var link = $('#inputLink').val();
        window.open(link);
    }
	
    clearAllFilter=()=>{
        this.setState({sheetsName:this.state.sheetsNameOrig,reviewSheetsName:this.state.reviewSheetsNameOringn})
    }
	
    getValue=(event)=>{
        $("input[type='radio']").prop("checked", false);
        let sheetId = event.target.options[event.target.selectedIndex].dataset.id;
        if(sheetId){
           this.setState({selectedSheetId:sheetId});
        }else{
            sheetId = this.state.selectedSheetId
        }
        this.setState({selectedSheetId:sheetId});
        this.setState({reviewVer:event.target.value});
        let sheetName = this.state.selectedSheet1;
        this.selectReviewOpt(event.target.value,sheetId, sheetName);
    }
	
    selectReviewOpt=(reviewStatus, selectedSheetId)=>{

        this.setState({selectedSheetId,selectedOpt:reviewStatus});
        if(reviewStatus == 'original'){
            window.location.reload(); 
        }else if(reviewStatus !== 'improved' && reviewStatus !== 'original'){
            this.setState({selectedSheet:reviewStatus,selDropOpt:'exported',exportedSelectedSheet:reviewStatus});
            var  singleSheetName = reviewStatus;
            let configSheets = this.state.configSheets;
			let configData = getConfigData(singleSheetName, configSheets);
			//console.log('configData->',configData);
			if(configData.length > 0){
				let allData = this.state.allData;
				this.setState({configData});
				allData.map((data,i)=>{
					if(configData[i]){
						if(data[0] == configData[i][0]){
							for (let j in allData) {
								if(j==10){
									data[j] = configData[i][7];
								}
								if(j==14){
									data[j] = configData[i][5];
								}
								if(j==15){
									data[j] = configData[i][6];
								}
								if(j==16){
									data[j] = configData[i].join();
								}
								if(j==37){
									data[j] = configData[i][8];
								}
							}
						}
					}else{
						data[10] = 0;
						data[14] = 'N';
					}
				})
				
				this.setState({allData});
				this._answer(allData)
			}
        }
    }
    
	selectKioskUsers=(event)=>{
        this.setState({kioskUsers: Array.from(event.target.selectedOptions, (item) => item.value)})
    }
    
	emptyState=()=>{
		
		let kioskResponse = this.state.kioskResponse;
		let userClassResponse = this.state.userClassResponse;
		let rResponse = this.state.rResponse;
		let checkedReq = this.state.checkedReq;
		this.setState({kioskResponse:new Map()});
		this.setState({userClassResponse:new Map()});
		this.setState({rResponse:new Map()});
		this.setState({checkedReq:new Map()});
		
	}
    
    handleSignIn=()=> {
        let password = this.refs.password.value
        if(password  == 'abc123'){
            let kioskUsers = this.state.kioskUsers;
            this.setState({errors:false,error:''});
            $('#loginModal').modal('hide');
            $('.card').css('backgroundColor','#fff')            
            let allData = this.state.allData;
            allData.map((rowDatas,i)=>{
                $('.headers-'+rowDatas[0]).find('i').removeClass("fa fa-minus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).find('i').addClass("fa fa-plus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).removeClass("collapsed");
                $('.inner-'+rowDatas[0]).removeClass("show");
                $('.pre-question').removeClass("show");
            })
            let t =true
            if(this.username == 'CPO-1' || this.username == 'CPO-2' || this.username == 'CPO-3' || this.username == 'CPO-4' || this.username == 'CPO-5' || this.state.setKiosk == true){
                t =false;
            }

            this.setState({setKiosk:t,kioskMode:!this.state.kioskMode,kioskUsers:['CPO'],showKioskBtn:t},()=>this._answer(allData));
            
        }else{
            this.refs.password.value = "";
            this.setState({errors:true,error:'Please enter a valid password'});
        }        
    }
	
    checkKey2(elements, parentId = '',ids ,pId, cId) {
        var branch = [];
        { elements.map((element,i) => {
            if(element[5] == 'Parent Header'){
                element[pId] = '';
            }  
            if (element[pId].toLowerCase() == parentId) {
                if(element[cId] == ''){
                    element[cId]='a';
                }
                var children = this.checkKey2(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children) {
                    element['sub'] = children; 
                }
                if(!ids.includes(element['0'])){
                    branch.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch;    
    }
	
    showAddUsersModal=(id)=>{
        let allDatas = this.state.allData;
        var res = ''
        allDatas.map((rowDatas, i) => {
            if(rowDatas[0] == id){
                let posData = rowDatas[34]
                res = posData.split(',');
            }
        })
        if(res !== ''){
            var array = res.slice(-9);
            this.setState({kioskUsers:array})
        }else{
            this.setState({kioskUsers:['CPO']})
        }
        $('#kiosk'+id).modal('show')
    }
	
    checkPreCondition(item, items, type) {
        let that = this;
        $("."+item).find("."+type).each(function() {
            let name = $(this).attr('name')
            items.push(name);
            that.checkPreCondition(name, items, type);
        });
        return items;
    }
	
    userDefined(){
        let kioskUsers = this.state.kioskUsers;
        if(this.username == 'CPO-1' || this.username == 'CPO-2' || this.username == 'CPO-3' || this.username == 'CPO-4' ||  this.username == 'CPO-5' || kioskUsers[0] == 'CPO'){
            this.setState({kioskMode:false})
        }else{
            this.setState({kioskMode:true});
        }
    }
	
    showKioskUserName=()=>{
        if(this.username == 'CC-1'){
            this.setState({kioskUserName: 'Care Coordinator'})
        }else if(this.username == 'Res-1'){
            this.setState({kioskUserName: 'Resident'})
        }else if(this.username == 'Pat-1'){
            this.setState({kioskUserName:'Practitioner Assistant'})
        }else if(this.username == 'MD-1'){
            this.setState({kioskUserName:'Physician'})
        }else if(this.username == 'PT-1'){
            this.setState({kioskUserName:'Physical Therapist'})
        }else{
            this.setState({kioskUserName:'Practitioner'})
        }
    }
	
    _answer=(allData)=>{
		let kioskUsers = this.state.kioskUsers
        let data = this.state.allData;
        let sampleData = this.deleteData(data);
        let mainDataN =[];
        let reqCmpCount2 =[];
        
        let checkedReq = this.state.checkedReq;
        sampleData.map((rowDatas,i)=>{
            let configDataRow = '';
            let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
            if(columnName){
                configDataRow = columnName.split(',');
                configDataRow.splice(0, 9);
            }
            if(rowDatas[5] == 'Parent Header'){
                mainDataN.push(rowDatas);
            }else if(rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                mainDataN.push(rowDatas);
            }else if(configDataRow.indexOf(kioskUsers[0]) != -1){
                mainDataN.push(rowDatas);
            }
            if(rowDatas[14] == 'Y' && rowDatas[15] == 'Y'){
                checkedReq.set(rowDatas[0], true)
				let rResponse = this.state.rResponse;
                let cRes = rResponse.get(rowDatas[0]);
                if(typeof cRes != "undefined" && cRes != ''){
                    reqCmpCount2.push(rowDatas[0]);
					rResponse.set(rowDatas[0], cRes);
					
                }else if(typeof rowDatas[109] != "undefined" && rowDatas[109] != ''){
					reqCmpCount2.push(rowDatas[0]);
					rResponse.set(rowDatas[0], rowDatas[109]);
				}
				this.setState({rResponse:rResponse});
            }
			var that = this;
			setTimeout(function(){
				let kioskResponse = that.state.kioskResponse;
				let userClassResponse = that.state.userClassResponse;
				if(rowDatas[14] == 'Y' && typeof rowDatas[109] != "undefined" && rowDatas[109] != ''){
					kioskResponse.set(rowDatas[0], rowDatas[109])
					userClassResponse.set(rowDatas[0], [rowDatas[107]])
				
					that.setState({kioskResponse:kioskResponse})
					that.setState({userClassResponse:userClassResponse})
				}
				if(rowDatas[14] == 'Y' && rowDatas[5] == 'image'){
					kioskResponse.set(rowDatas[0], rowDatas[99])
					userClassResponse.set(rowDatas[0], ['CPO'])
				
					that.setState({kioskResponse:kioskResponse})
					that.setState({userClassResponse:userClassResponse})
				}
				
				if(rowDatas[14] == 'Y' && rowDatas[5] == 'link'){
					kioskResponse.set(rowDatas[0], rowDatas[78])
					userClassResponse.set(rowDatas[0], ['CPO'])
				
					that.setState({kioskResponse:kioskResponse})
					that.setState({userClassResponse:userClassResponse})
				}
				
				if(rowDatas[14] == 'Y' && rowDatas[5] == 'inlineVideo'){
					kioskResponse.set(rowDatas[0], rowDatas[99])
					userClassResponse.set(rowDatas[0], ['CPO'])
				
					that.setState({kioskResponse:kioskResponse})
					that.setState({userClassResponse:userClassResponse})
				}
				
			}, 5000);
			
        })
        
        this.setState({reqCount:checkedReq.size})
        this.setState({reqCmpCount:reqCmpCount2.length})

        let ids =[];
        let mainData =[];
        {mainDataN.map((data,i)=>{
            var key = data[10];
            mainData[key] = data; 
        })}
        
        this.setState({mainData});
        
        var dbArray = this.checkKey2(mainData, '', ids, 2, 1);
        
        let db3 =[];
        dbArray.map((rowDatas,i)=>{
            if( rowDatas['sub'].length != 0 ){
                db3.push(rowDatas);
            }
        })
        this.setState({sheetData:db3});
        this.userDefined();
        //this.showKioskUserName();
        if(kioskUsers.toString() == 'PhT'){
            $('.card').css('backgroundColor','#FFCCE5')
        }else if(kioskUsers.toString() == 'CPO'){
            $('.card').css('backgroundColor','#FFFFFF')
        }else if(kioskUsers.toString() == 'Int' || kioskUsers.toString() == 'Res' || kioskUsers.toString() == 'PrA' || kioskUsers.toString() == 'PA' ){
            $('.card').css('backgroundColor','#B2FF66')
        }else if(kioskUsers.toString() == 'PhA' || kioskUsers.toString() == 'PmD'){
             $('.card').css('backgroundColor','#DCDCDC')
        }else if(kioskUsers.toString() == 'Pat'){
             $('.card').css('backgroundColor','#ccf5ff')
        }else if(kioskUsers.toString() == 'CC'){
            $('.card').css('backgroundColor','#FFFF99')
        }
        if(kioskUsers.toString() != 'CPO'){
            $('.btn-tag').hide();
        }
		let that = this;
		setTimeout(function(){
			that.showBoxBegin(mainData);
		}, 3000);

    }
	
	showBoxBegin=(mainData)=>{
		let classN = '';
		mainData.map((data,i)=>{
			if(data[5]=='boxend' || data[5].toLowerCase() == 'parent header' || data[5].toLowerCase() == 'child header 1' || data[5].toLowerCase() == 'child header 2' || data[5].toLowerCase() == 'child header 3'){
				classN = '';
			}
			
			$('#'+data[0]).addClass(classN);
			
			if(data[5]=='boxbegin' && !$('#'+data[0]).hasClass("not-include")){
				classN = 'in-box';
			}
		})
		
		mainData.map((data,i)=>{
			if(data[5].toLowerCase() == 'parent header' || data[5].toLowerCase() == 'child header 1' || data[5].toLowerCase() == 'child header 2' || data[5].toLowerCase() == 'child header 3'){
				let endlen = $( ".inner-"+data[0]+ ' .end-div').length;
				let len = $( ".inner-"+data[0]+ ' .in-box').length;
				let has = false;
				$( ".inner-"+data[0]+ ' .in-box').each(function( index ) {
					if (index === len - 1 && endlen == 0) {
						let lastChild = $( this ).attr('id');
						$('#'+lastChild).addClass('in-box-last');
					}
					
					if(!$(this).hasClass("not-include")){
						has = true;
					}
				  
				});
				
				if(has == false){
					$( ".inner-"+data[0]+ ' .begin-div').addClass('hide');
					$( ".inner-"+data[0]+ ' .end-div').addClass('hide');
				}else{
					$( ".inner-"+data[0]+ ' .begin-div').removeClass('hide');
					$( ".inner-"+data[0]+ ' .end-div').removeClass('hide');
				}
			}
		})
	}
	
    setKioskMode=(setKiosk)=>{
        let kioskUsers = this.state.kioskUsers
        let data = this.state.allData;
        let sampleData = this.deleteData(data);
        let rResponse = this.state.rResponse;
        this.setState({reqCount:0})
        this.setState({reqCmpCount:0})
        let mainDataN =[];
        let reqCount1 =[];
        let reqCmpCount1 =[];
        let sOptions = $("input[name='selOptions']:checked").val();
        if(setKiosk == true){
            this.setState({showUserBtn:false,kioskMode:true})
            sampleData.map((rowDatas,i)=>{
                let configDataRow = '';
				let columnName = rowDatas[16];
				if(columnName){
					configDataRow = columnName.split(',');
					configDataRow.splice(0, 9);
				}
                if(rowDatas[5] == 'Parent Header'){
                    mainDataN.push(rowDatas);
                }else if(rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                    mainDataN.push(rowDatas);
                }else if(configDataRow.indexOf(kioskUsers[0]) != -1){
                    if(sOptions == 'required_only'){                                
                        if (rowDatas[15] == 'Y') {
                            mainDataN.push(rowDatas);
                        }
                    }else{
                        mainDataN.push(rowDatas);
                    }
                    
                    if(rowDatas[15] == 'Y'){
                        reqCount1.push(rowDatas[0]);
                        let res = rResponse.get(rowDatas[0]);
						//console.log('rResponse',rResponse);
                        
						if((typeof res != "undefined" && res != '') || (typeof rowDatas[109] != "undefined" && rowDatas[109] != '')){
                            reqCmpCount1.push(rowDatas[0]);
                        }
                    }
                }   
            })
            
            this.setState({reqCount:reqCount1.length})
            this.setState({reqCmpCount:reqCmpCount1.length})
        
            this.setState({mainDataN})
            let ids =[];
			
			let mainData =[];
			
			{mainDataN.map((data,i)=>{
				var key = data[10];
				mainData[key] = data; 
			})}
			
            var dbArray = this.checkKey2(mainData, '', ids, 2, 1);
            let db3 =[];
            dbArray.map((rowDatas,i)=>{
                if( rowDatas['sub'].length != 0 ){
                    db3.push(rowDatas);
                }
            })
            this.setState({sheetData:db3});
			
			//console.log('kioskUsers->',kioskUsers);
        
            if(kioskUsers.toString() == 'PhT'){
                this.setState({kioskUserName:'Physical Therapist'})
                $('.card').css('backgroundColor','#FFCCE5',)
            }else if(kioskUsers.toString() == 'CPO'){
                this.setState({kioskUserName:'Practitioner'})
                $('.card').css('backgroundColor','#FFFFFF')
            }else if(kioskUsers.toString() == 'Int'){
                this.setState({kioskUserName:'Intern'})
                $('.card').css('backgroundColor','#B2FF66')

            }else if(kioskUsers.toString() == 'Res'){
                this.setState({kioskUserName: 'Resident'})
                $('.card').css('backgroundColor','#B2FF66')
            }else if(kioskUsers.toString() == 'PrA'){
                this.setState({kioskUserName:'Practitioner Assistant'})
                $('.card').css('backgroundColor','#B2FF66')
            }else if(kioskUsers.toString() == 'PhA' ){
                this.setState({kioskUserName:'Physician Assistant'})
                $('.card').css('backgroundColor','#B2FF66')
            }else if(kioskUsers.toString() == 'PmD'){
                this.setState({kioskUserName:'Physician'})
                 $('.card').css('backgroundColor','#DCDCDC')
            }else if(kioskUsers.toString() == 'Pat'){
                this.setState({kioskUserName:'Patient'})
                 $('.card').css('backgroundColor','#ccf5ff')
            }else if(kioskUsers.toString() == 'CC'){
                this.setState({kioskUserName: 'Care Coordinator'})
                $('.card').css('backgroundColor','#FFFF99')
            }
            
            if(kioskUsers.toString() != 'CPO'){
                $('.btn-tag').hide();
            }
            $('#myModal').modal('hide');
        }
        setTimeout(function(){
            //console.log(data)
            data.map((comment,i)=>{
                if(i > 0){

                    if($('.main_'+comment[0]).find('.question').length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
                        $('.main_'+comment[0]).addClass("not-include");  
                    }else{
                        $('.main_'+comment[0]).removeClass("not-include");   
                    }
                }
            });
        }, 2000);
    }
	
    goKisko=()=>{
        this.setState({setKiosk:!this.state.setKiosk},()=>this.setKioskMode(this.state.setKiosk));
    }
	
    handleEmail=(event)=>{
        this.setState({[event.target.name]:event.target.value});
    }
	
    sendEmail=()=> {
       var templateParams = {
            emailId : this.state.emailId, 
            emailMessage: this.state.emailMessage,
            zoomLink:config.zoomLink
        };
        if(this.state.emailId !== ''){
            emailjs.send('gmail','soogap',templateParams,'user_oCUNeAzhCYAWeHpvBs8kA')
            .then((result) => {
                $('#myEmailModal').modal('hide');
            }, (error) => {
                //console.log(error.text);
            });
            this.setState({emailMessage:'',emailId:'',errorMessage:'',successMessage:'Email sent successfully'});
            var that =this;
            setTimeout(function(){ 
                that.setState({successMessage:''});
            }, 4000);
        }else{
            this.setState({errorMessage:'Please enter a email address'});
        }
    }
	
    sendPdfEmail=()=> {
       var templateParams = {
            emailId : this.state.emailId, 
            emailMessage: this.state.emailMessage,
            variable_n93oryp:this.state.pdfLink
        };
        if(this.state.emailId !== ''){
            emailjs.send('gmail','template_p3v6cuv',templateParams,'user_oCUNeAzhCYAWeHpvBs8kA')
            .then((result) => {
                $('#myPdfModal').modal('hide');
            }, (error) => {
                //console.log(error.text);
            });
            this.setState({emailMessage:'',emailId:'',errorMessage:'',successMessage:'Email sent successfully'});
            var that =this;
            setTimeout(function(){ 
                that.setState({successMessage:''});
            }, 4000);
        }else{
            this.setState({errorMessage:'Please enter a email address'});
        }
    }
	
    showKioskModal=()=>{
        let checkedKiosk = this.state.checkedKiosk;
        this.refs.password.value = "";
        this.setState({checkedKioskData:checkedKiosk})
        this.setState({errors:false,error:''});
        $('#loginModal').modal('show')
    }
	
    dataFilter=(event,val)=>{
		
		let optionVal = val;
		if(event){
			optionVal = event.target.value;
		}
        let allDatas = this.state.allData;
        
        if(optionVal == 'view_all'){
            //$('.comments').find('.not-include'). removeClass("not-include");
            //$('.commentss').find('.not-include'). removeClass("not-include");
			{ allDatas.map((rowDatas, i) => {
                if(rowDatas[14] == 'Y'){
                    $('#'+rowDatas[0]).removeClass("not-include");  
                }
            })}
			
			setTimeout(function(){
				{ allDatas.map((rowDatas, i) => {
					if(rowDatas[14] == 'Y' && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
						let hasVisible=false;
						$('#'+rowDatas[0]).find('.question').each(function() {
							if(!$(this).hasClass("not-include")){
								hasVisible= true;
							}
						});
						if(hasVisible == false){
							$('#'+rowDatas[0]).addClass("not-include");
						}else{
							$('#'+rowDatas[0]).removeClass("not-include");
						}
					}
				})}
			}, 2000);
			
        }else if(optionVal == 'required_only'){
            
            { allDatas.map((rowDatas, i) => {
                
                $('#'+rowDatas[0]).addClass("not-include");
                
                if($('#'+rowDatas[0]).find('.req-only').length > 0 && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
                    $('#'+rowDatas[0]).removeClass("not-include");  
                }
                
            })}
            setTimeout(function(){
                $('.req-only').removeClass("not-include");
            }, 1000);   
            
        }else if(optionVal == 'needAttention_only'){
            
            { allDatas.map((rowDatas, i) => {
                $('#'+rowDatas[0]).addClass("not-include");
                if(rowDatas[27]){
                    $('#'+rowDatas[0]).removeClass("not-include");  
                }
            })}
			
			setTimeout(function(){
				{ allDatas.map((rowDatas, i) => {
					if(rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3'){
						let hasVisible=false;
						$('#'+rowDatas[0]).find('.question').each(function() {
							if(!$(this).hasClass("not-include")){
								hasVisible= true;
							}
						});
						if(hasVisible == false){
							$('#'+rowDatas[0]).addClass("not-include");
						}else{
							$('#'+rowDatas[0]).removeClass("not-include");
						}
					}
				})}
			}, 2000);
			
        }
		
    }
	
    idOf(i){
        return (i >= 26 ? this.idOf((i / 26 >> 0) - 1) : '') +  'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0];
    }
	
    handleSubmitSave=(selectedSheet)=>{
		
        let formType = this.state.mainSheet;
        let formName = this.state.exportedSelectedSheet ? this.state.exportedSelectedSheet : this.state.selectedSheet;
        let guids = guid();
        let userName = this.username;
        let kioskResponse = this.state.kioskResponse;
        let userClassResponse = this.state.userClassResponse;
        let feedsResponse = this.state.feedsResponse;
        let required = 0;
        this.state.mainData.map((rowDatas,i)=>{
            $('#'+rowDatas[0]).removeClass("error");
            let res = kioskResponse.get(rowDatas[0]);
            if(rowDatas[14] == 'Y' && rowDatas[15] == 'Y' && typeof res == "undefined"){
                required = 1;
                $('#'+rowDatas[0]).addClass("error");
                
            }
        })
        
        if(required > 0){
            alert('Please fill answer all required questions');
			let nav = $('.error');
			if(nav.length){
				$('html, body').animate({
					scrollTop: $('.error').offset().top - 50
				}, 2000);
			}
            return false;
        }
        
        let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
        let spreadsheetID = config.phase5spreadsheetId; 
        let params1 = {
            spreadsheetId: spreadsheetID,
        };
        var sheetVal = '';
        let updateData=[];
        gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: spreadsheetID,
                range:'Sheet1',
            })
            .then(response => {
                    sheetVal = response.result.values;
                    let length = 0;
                    if(sheetVal){
                        length = sheetVal[6].length;
                    }
                    let firstRow = formType;
                    let secondRow = formName;
                    let thirdRow = guids;
                    let fourRow = userName;
                    let fiveRow = date;
                    let columnName = this.idOf(length);
                    let row = {
                            "majorDimension": "ROWS",
                            "range": "Sheet1!"+columnName+1,
                            "values": [
                                [ firstRow ]
                            ]
                        }
                    let row1 = {
                        "range": "Sheet1!"+columnName+'2',
                        "majorDimension": "ROWS",
                        "values": [
                            [ secondRow ]
                        ]
                    }
                    let row2 = {
                        "range": "Sheet1!"+columnName+'3',
                        "majorDimension": "ROWS",
                        "values": [
                            [ thirdRow ]
                        ]
                    }
                    let row3 = {
                        "range": "Sheet1!"+columnName+'4',
                        "majorDimension": "ROWS",
                        "values": [
                            [ fourRow ]
                        ]
                    }
                    
                    let row4 = {
                        "range": "Sheet1!"+columnName+'5',
                        "majorDimension": "ROWS",
                        "values": [
                            [ fiveRow ]
                        ]
                    }
                    
                    
                    updateData.push(row);
                    updateData.push(row1);
                    updateData.push(row2);
                    updateData.push(row3);
                    updateData.push(row4);
                    
                    let st = length+6;
                    let headings = ["Field Name", "Response", "Feedback Notes", "User ID", "User Class", 'Date'];
                    
                    let j=0;
                    
                    for (let i = length; i < st; i++) {
                        let nColumn = this.idOf(i);
                        let row5 = {
                            "range": "Sheet1!"+nColumn+'7',
                            "values": [
                                [ headings[j] ]
                            ]
                        }
                        j++;
                        updateData.push(row5);
                    }
                    
                    //let sheetData = this.state.sheetData;
					let allDatas = this.state.allData;
                    
                    let newArray = [];
					
					allDatas.map((rowDatas,i)=>{
                        let feed = feedsResponse.get(rowDatas[0]);
                        let checkedFeeds = this.state.checkedFeeds;
                        let chFeeds = checkedFeeds.get(rowDatas[0]);
                        let feedNote = '';
                        if(typeof feed != "undefined" && chFeeds == true){
                            feedNote = feed; 
                        }
						let userClass = userClassResponse.get(rowDatas[0]);
						if(userClass){
							userClass = userClass[0] ? userClass[0]: '';
						}
						let row7 = [rowDatas[0],kioskResponse.get(rowDatas[0]),feedNote,this.username,userClass, fiveRow];
                        newArray.push(row7);
                    })
					
                    let n = 8
                    newArray.map((rowDatas,i)=>{
                        let j=0;
                        for (let i = length; i < st; i++) {
                            let nColumn = this.idOf(i);
                            let row8 = {
                                "range": "Sheet1!"+nColumn+n,
                                "values": [
                                    [ rowDatas[j] ]
                                ]
                            }
                            j++;
                            updateData.push(row8);
                        }
                        
                        n++;
                    })
                   
                    let batchUpdateValuesRequestBody = {
                        "valueInputOption": 'USER_ENTERED',
                        data: updateData
                    };
					let that = this;
                    let request2 = gapi.client.sheets.spreadsheets.values.batchUpdate(params1,batchUpdateValuesRequestBody);
                    request2.then(function(response) { 
                        alert('Data Saved Successfully');
						//that.handleClearTempSave(that.state.mainSheet);
                    }, function(reason) {
                        alert(reason.result.error.message);
                    }); 
                },
            );
        }); 
    }
	
	handleSubmitTempSave=(selectedSheet)=>{
		
		let kioskResponse = this.state.kioskResponse;
		let kioskUsers = this.state.kioskUsers;

        let required = 0;
        this.state.mainData.map((rowDatas,i)=>{
			let configDataRow = '';
			let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
			if(columnName){
				configDataRow = columnName.split(',');
				configDataRow.splice(0, 9);
			}
		
            $('#'+rowDatas[0]).removeClass("error");
            let res = kioskResponse.get(rowDatas[0]);
            if(rowDatas[14] == 'Y' && rowDatas[15] == 'Y' && typeof res == "undefined" && configDataRow.indexOf(kioskUsers[0]) != -1){
				required = 1;
                $('#'+rowDatas[0]).addClass("error");  
            }
        })

        if(required > 0){
            alert('Please fill answer all required questions');
			let nav = $('.error');
			if(nav.length){
				$('html, body').animate({
					scrollTop: $('.error').offset().top - 50
				}, 2000);
			}
            return false;
        }
		
		let accessToken = gapi.auth.getToken().access_token;
        //let spreadsheetID = config.spreadsheetId;
		let spreadsheetID = this.state.sheetConfigId;
        
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
		let rowId ='';
		let updateData=[];
		let allDatas = this.state.allData;
		
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
        
		allDatas.map((rowDatas,i)=>{
				
				let res = kioskResponse.get(rowDatas[0]);
				if(typeof res != "undefined"){
					rowId = (i+2);
					let row1 = {
						"range": selectedSheet+'!DD'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[[ kioskUsers[0] ]]
					};
					let row2 = {
						"range": selectedSheet+'!DE'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[[ date ]]
					};
					let row3 = {
						"range": selectedSheet+'!DF'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[[ res ]]
					};
					
					updateData.push(row1)
					updateData.push(row2)
					updateData.push(row3)
				}

        })
		
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
            alert('Data Saved Successfully')
        }, function(reason) {
            alert(reason.result.error.message);
        });
	}
	
	handleClearTempSave=(selectedSheet)=>{
		let mainSheet = this.state.mainSheet;
		let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = this.state.sheetConfigId;
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
		let rowId ='';
		let updateData=[];
		let allDatas = this.state.allData;
		allDatas.map((rowDatas,i)=>{
				
			rowId = (i+2);
			if(rowDatas[5] != 'image' || rowDatas[5] != 'link' || rowDatas[5] != 'inlineVideo'){
				let row1 = {
					range: mainSheet+'!CV'+rowId,
					values: [
						[ '' ]
					]
				};
				updateData.push(row1)
			}
			
			let row2 = {
				range: mainSheet+'!DD'+rowId+':DF'+rowId,
				values: [
					[ '','','' ]
				]
			};
			
			updateData.push(row2)
        })
		
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			that.emptyState();
			that.setState({sheetData:[]});
			
			allDatas.map((rowDatas,i)=>{
				rowDatas[99] = '';
				rowDatas[107] = '';
				rowDatas[108] = '';
				rowDatas[109] = '';
			});
			
			if(that.state.selDropOpt == 'exported'){
				that.selectReviewOpt(that.state.selectedSheet, that.state.selectedSheetId);
			}else{
				that.onSelect(that.state.selectedSheet, that.state.selectedSheetId);
			}
			alert('Data Clear Successfully');
        }, function(reason) {
            //alert(reason.result.error.message);
        });
	}
    
    showPreview=(event)=>{
        let value = event.target.value;
        if(value == 'open'){
            let data = this.state.allData;
            let mainDataS =[];
            data.map((rowDatas,i)=>{
                if(rowDatas[5] == 'Parent Header' || rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3' || rowDatas[5].toLowerCase() == 'title' || rowDatas[5].toLowerCase() == 'text'){
                    mainDataS.push(rowDatas);
                }
                let cRes = this.state.kioskResponse.get(rowDatas[0]);
                if(typeof cRes != "undefined" && cRes != ''){
                    mainDataS.push(rowDatas);
                }
				/* if(typeof rowDatas[99] != "undefined" && rowDatas[99] != '' && rowDatas[5] == 'video'){
                    mainDataS.push(rowDatas);
                } */
            })
            let ids =[];
			let mainData =[];
			
			{mainDataS.map((data,i)=>{
				var key = data[10];
				mainData[key] = data; 
			})}
			
            var dbArray2 = this.checkKey2(mainData, '', ids, 2, 1);
            let db4 =[];
            dbArray2.map((rowDatas,i)=>{
                if( rowDatas['sub'].length != 0 ){
                    db4.push(rowDatas);
                }
            })
			
            this.setState({kioskPreviewData:db4});
			
			let that = this;

            setTimeout(function(){
                $('.commentss').each(function( index ) {
                    if($(this).find('.question').length < 1){
                        $(this).remove();
                    }
                });
                
                $('.commentss input, .commentss select').attr('disabled', 'disabled');  
                $( "select option:selected" ).each(function() {
				  $(this).attr('selected', true);
				});
				$("h3, h4, h5, h6").trigger('click');
				
				let sOptions = $("input[name='selOptions']:checked").val();
				if(sOptions == 'required_only'){
					let value = 'required_only';
					that.dataFilter(event=null,value);
				}
            }, 3000);
			$('.rq-btn, .sv-btn').hide();
        }else{
            this.setState({kioskPreviewData:''});
        }
        
    }
    
    closePreview=()=>{
        $('input[name=kioskResponse]').prop( "checked", false );
        $('input[name=selOptions]').prop( "checked", false );
		$('.rq-btn, .sv-btn').show();
        this.setState({kioskPreviewData:''});
		let that = this;
		setTimeout(function(){
			that.showBoxBegin(that.state.mainData);
		}, 2000);
    }
	
	createPdf=()=>{
		
		let url = config.API_URL+'pdf/pdf.php';
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		//let dateFormat = date.split(' ');
		let formPrefix = this.state.selectedSheet.split(' ');
		let formName = formPrefix[0]+'-'+this.state.kioskUserName+'-'+date;
		let con = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'multipart/form-data'
			}
		}
		//let header = '<h1>'+formName+'</h1>';
		let signName = $("input[name=signName]").val();
		let signTitle = $("input[name=signTitle]").val();
		let signCont = $("input[name=signCont]").val();
		let signDate = $("input[name=signDate]").val();
		let signdata = '';
		
		if(signName){
			signdata = signName;
		}
		if(signTitle){
			let str2 = ', '+signTitle;
			signdata = signdata.concat(str2);
		}
		if(signCont){
			let str3 = ', '+signCont;
			signdata = signdata.concat(str3);
		}
		
		let createdDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		let html = '<div style="display: block;margin-bottom:20px"><h2>Note from evaluation for e-signature and to be placed in records systems Generated on '+createdDate+'</h2><hr></div>';
		html = html.concat($(".pdf-data").html());
		let footer = '<table style="width: 100%;margin-top: 10px;"><tbody><tr><td style="width: 50%; padding: 0px 50px 0px 0px;"><span style="text-align: left;">X</span><hr></td><td style="width: 50%; padding: 0px 50px 0px 0px"><span style="text-align: left;">X</span><hr></td></tr><tr><td style="width: 50%; padding: 0px 50px 0px 0px;text-align: center;">'+signdata+'</td><td style="width: 50%; padding: 0px 50px 0px 0px;text-align: center;">Date</td></tr></tbody></table>';
		
		let form_data = new FormData();
		form_data.append('name', formName);
		//form_data.append('header', header);
		form_data.append('footer', footer);
		form_data.append('html', html);
		post(url, form_data, con)
		.then(response => {
			//console.log('response->', response);
			window.open(response.data, "_blank")
		})
	}
	
	getDirectoryFiles=(id)=>{
        let config1 = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url =config.API_URL+'uploadfile/get_files.php';
        axios.get(url, config1)
        .then(response => {
            var fileArray =response.data;
            this.setState({fileArray,fileId:id});
        })
        
    }
	
    uploadFilePath=()=>{
        var path  =this.state.fileData;
        var fileId = this.state.fileId;
        //console.log(this.state)
        uploadFilePath(path, fileId, this.state);
        $('#directoryFiles1').modal('hide');
        this.setState({filePath:path});
    }
	
    getFilePath=(file)=>{
        //console.log(file)
        this.setState({fileData:file})
        $( ".view-file" ).removeClass( "selected" );
        $( "#file"+file.id ).addClass( "selected" );
        $( "#fileId" ).val( file );
    }
	
    pdfLinkValue=(event)=>{
        let value = event.target.value;
        this.setState({pdfLink:value})
		$('.pdf-link').attr("href", value);
	}
	
	handleNeeds = (e) => {
        const item = e.target.name;
        //this.setState({name:''});
        
        const isChecked = e.target.checked;
		if(isChecked){
			$('#needs'+item).modal('show');
		}
        this.setState(prevState => ({
            checkedNeeds: prevState.checkedNeeds.set(item, isChecked)
        }));
    }
	
	saveNeedsNote=(id,data)=>{
		let allData = this.state.allData;
		if(!data){
			let checkedNeeds = this.state.checkedNeeds;
			checkedNeeds.delete(id);
			this.setState({checkedNeeds});
		}else{
		
			let spreadsheetID = this.state.sheetConfigId;
			let accessToken = gapi.auth.getToken().access_token;
			let params = {
				access_token:accessToken,
				spreadsheetId: spreadsheetID,
			};
			let rowId ='';
			{ allData.map((valu, i) => {
				if(valu[0] === id){
					rowId = (i+2);
					valu[27] = data;
				}
			})}
			
			let batchUpdateValuesRequestBody = {
				valueInputOption: 'USER_ENTERED',
				data: [{
					"range":this.state.selectedSheet+'!AB'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[ data ]
					]
				}], 
			};
			let that = this;
			let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
			request.then(function(response) {
				that._answer(allData);
			}, function(reason) {
				alert(reason.result.error.message);
			});
			$('#needs'+id).modal('hide');
		}
    }
    
	removeNeedsNote=(id)=>{
		let allData = this.state.allData;
		let spreadsheetID = this.state.sheetConfigId;
        let rowId ='';
		{ allData.map((valu, i) => {
			if(valu[0] === id){
				rowId = (i+2);
				valu[27] = '';
			}
		})}
		
        var params = {
            spreadsheetId: spreadsheetID,
            range: this.state.selectedSheet+'!AB'+rowId,
        };
		let that = this;
        var request = gapi.client.sheets.spreadsheets.values.clear(params);
        request.then(function(response) {
			
			let checkedNeeds = that.state.checkedNeeds;
			checkedNeeds.delete(id);
			that.setState({checkedNeeds});
			
            that._answer(allData);
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        }); 
        $('#needs'+id).modal('hide');
    }
		
    handleFeeds = (e) => {
		const item = e.target.name;
        this.setState({name:''});
        const isChecked = e.target.checked;
        let checkedFeeds = this.state.checkedFeeds;
        this.setState(prevState => ({
            checkedFeeds: prevState.checkedFeeds.set(item, isChecked)
        }));
        if(isChecked == true){
            $('#feeds'+item).modal('show');
        }else{
            $('.btn-'+item).hide();
        }
    }
    
    saveFeedsNote=(id, data)=>{
        let feedsResponse = this.state.feedsResponse;
        if(data){
            feedsResponse.set(id, data)
        }
        
        this.setState({feedsResponse:feedsResponse});
        
        $('#feeds'+id).modal('hide');
    }
    
    removeFeedsNote=(id)=>{
        let feedsResponse = this.state.feedsResponse;
        if(id){
            feedsResponse.delete(id)
        }
        this.setState({feedsResponse:feedsResponse});
        $('input[name='+id+']').prop( "checked", false );
        $('#feeds'+id).modal('hide');
    }
	
	showReq = () => {
		
		let allData = this.state.allData;
		
		if($("input[name='showQ']").prop("checked") == true){
			{ allData.map((rowDatas, i) => {
				if(rowDatas[5] == 'Parent Header' || rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3' || rowDatas[15] != 'Y'){
					if (!$('#'+rowDatas[0]).hasClass ('not-include')){
						$('#'+rowDatas[0]).addClass("not-req");
					}
				}
			})}
			
			{ allData.map((rowDatas, i) => {
				if(rowDatas[5] == 'Parent Header' || rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
					$('#'+rowDatas[0]).find('.req-only').each(function() {
						$('#'+rowDatas[0]).removeClass("not-req");
					});
				}
			})}
			
            
		}else{
            { allData.map((rowDatas, i) => {
				$('#'+rowDatas[0]).removeClass("not-req");	
			})}
		}
	}
	
	keyPress(e){
        if(e.keyCode == 13){
			e.preventDefault();
        }
    }
    
	selectFormMod = (event) =>{
		let val = event.target.value;
		if(val == 'test_forms'){
			this.props.history.push('/testform');
		}else if(val == 'completed_forms'){
			this.props.history.push('/completedform');
		}else if(val == 'custom_config'){
			this.props.history.push('/configure');
		}
	}
	
	companyFilter = (event) =>{
		let val = event.target.value;
		let reviewSheetsName = this.state.reviewSheetsNameOringn;
		if(val != 'Please Select'){
			let sheetpr = [];
			reviewSheetsName.map((sheet, i) => {
				if(sheet.facilityId == val){
					sheetpr.push(sheet);
				}
				
			});
			this.setState({reviewSheetsName:sheetpr});
		}else{
			this.setState({reviewSheetsName:reviewSheetsName});
		}
	}
	
	showOutputText=()=>{
		let outputText = this.state.outputText;
		if($("input[name='showOutputText']").prop("checked") == true){
			this.setState({outputText:true});
		}else{
			this.setState({outputText:false});
		}
	}
	
    render(){
		const {allData,sheetData,moduleName,checkedKiosk,setKiosk, assoType,assoVisitType,assoDeviceType,showKioskBtn, kioskMode, kioskUserName,kioskResponse,errorMessage,successMessage, feedsResponse, userClassResponse, pdfArray, fileArray, mdsGuid, companies} =this.state
		
		var optionsPdf = ''
		if(pdfArray){
            var optionsPdf = pdfArray.map(function(data,i) {
                return (
                    <option value={data.path} key={i}>{data.name}</option>
                );
            })
        }
		
		let kioskType = [];
		let uType = [];
		if(allData){
			kioskType = allData[0][119].split(',');
			
			kioskType.map(function(type,i) {
				type = type.split(':');
				if(type[1]){
					uType.push(type[1]);
				}
			})
		}
		if(uType.length == 0){
			uType = ['CPO'];
		}
														
        //var uType  = ['CaC','Pat','Int','Res','PrA','PhT','PhA','PmD','CPO'];
		
        if (this.state.test_forms.length) {
            return (
            <div className="container mt-5 mb-5">
				<div className="login-user-top">{'Logged in as: '+localStorage.getItem('username')}</div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                            { kioskMode ? 
                                <div className="row">
                                    <div className="col-md-12" style={{textAlign:'center'}}>
                                        <h4>You are in {kioskUserName} Delegate mode</h4>
                                    </div>
                                </div>:
                                <div className="row">
                                
                                    <div className="col-md-6">
                                        <h4>Clinical Tracks Form Testing </h4>
                                    </div>
                            
                                    <div className="col-md-6">
										<select className="form-control top-mod-select-n" value={this.state.renderSheet} onChange={this.handleChangeSheet.bind(this)}>
											{/*<option value="soogap">Soogap</option>*/}
											<option value="parentMds">Parent MDS</option>
											<option value="fieldSet">Field Set</option>
											
										</select>
										<select className="form-control select-new-mod" value={moduleName} name="forms" onChange={this.selectFormMod}>
											<option value="test_forms">Test Form</option>
											<option value="completed_forms">View Completed Forms</option>
											<option value="custom_config">Configure Layouts</option>
										</select>
										<a className="nav-link btn btn-outline-info sign-out-btn" onClick={this.logout}>Sign Out</a>
                                    </div>
                                </div>}
                            </div> 

                            <div className="card-body">
                            { kioskMode ? null :
                            <TopFilter 
                                handleHeaderFilter={this.handleHeaderFilter} 
                                handleClearTempSave={this.handleClearTempSave} 
                                clearAllFilter={this.clearAllFilter}
                                assoType = {assoType}
								selectedSheet = {this.state.mainSheet}
								sheetData = {sheetData}
								allData = {allData}
								moduleName = {moduleName}
                                assoVisitType ={assoVisitType}
                                assoDeviceType ={assoDeviceType}
                                />}
                            { kioskMode ? 
                                <div className="row">
                                    <div className="col-md-12" style={{textAlign:'right'}}>
                                        <span> <label><input type="radio" name="kioskResponse" value="open" onChange={this.showPreview}/> View completed Delegate Response &nbsp; </label></span> 
										<span className="rq-btn"> <label className="showAllSwitch">
										<input className="slide-checkbox" type="checkbox" name="showQ" onClick={this.showReq}/>
										<span className="slider round showAllSlide"></span>
										</label> <span> View Required &nbsp;  </span>  </span> 
										
										<span><button className="btn btn-primary" onClick={()=>this.showKioskModal()}>End Delegate Mode</button></span> 
										
										
                                    </div>
                                </div>:
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> Clinical Form </label>
                                            <Form.Control as="select" value={this.state.selectedSheet1} onChange={this.getSheetValue}>
                                                <option> Please Select</option>
                                                {this.state.sheetsName.map((sheet, i) => (
                                                    <option data-id={sheet.id} key={i}>{sheet.name}</option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> Test Form Version </label>
                                             
                                                <Form.Control id="test-form-select" as="select" value={this.state.reviewVer} onChange={this.getValue}>
                                                    <option value="original">Master Data Set</option>
                                                    {this.state.reviewSheetsName.map((sheet, i) => {
														let user = this.state.current_user;
														let fId = user[3];
														if(fId != 'All'){
															if(sheet.facilityId == fId){
																return <option data-id={sheet.id} key={i}>{sheet.name}</option>
															}
														}else{
															return <option data-id={sheet.id} key={i}>{sheet.name}</option>
														}
                                                    })}
                                                </Form.Control>
                                        </div>
                                    </div>
                                    
									{sheetData.length>0 && localStorage.getItem('username') == 'admin' ? 
									<div className="col-md-3">
										<div className="form-group">
											<label> Company Filter</label>
											<Form.Control as="select" onChange={this.companyFilter}>
												<option> Please Select</option>
												{companies.map((company, i) => (
													<option value={company}>{company}</option>
												))}
											</Form.Control>
										</div>
										
										
									</div>:<div className="col-md-3"></div>}
									{showKioskBtn || sheetData.length == 0 ? 

									   null

									: 

									<div className="col-md-3"  style={{marginTop:'20px'}}>
									{setKiosk ? 
										<div className="col-md-3">
											<button className="btn btn-primary" onClick={()=>this.showKioskModal()}>End Delegate Mode</button>
										</div>
									:
										<div className="col-md-3">
											<button className="btn btn-primary" type="button" data-target="#myModal" data-toggle="modal">Delegate Mode </button>
										</div>
									}
									<label><input type="radio" name="kioskResponse" value="open" onChange={this.showPreview}/>View completed Delegate Response</label>
									
									</div>}
                                    
                                </div>}
                                {kioskMode ? null :
                                <div>
								<div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-1">
                                                <button type="button" className="btn btn-primary mb-2" onClick={this.pasteLink} > <i className="fa fa-video-camera" aria-hidden="true"></i></button>
                                            </div>
											<div className="col-md-11">
                                                 <input id="inputLink" type="text" className="form-control"/>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="button" className="btn btn-primary mb-2" onClick={this.copyLink}><i className="fas fa-copy"></i> </button> &nbsp;
                                        <button type="button" className="btn btn-primary mb-2" onClick={this.launchLink}><i className="fas fa-external-link-alt"></i></button> &nbsp;
                                        <button type="button" className="btn btn-primary mb-2" data-toggle="modal" data-target="#myEmailModal"><i className="fa fa-envelope"></i></button>
                                    </div>
									{sheetData.length>0 ? 
									<div className="col-md-3">
										<div className="radio">
										  <label><input id="viewAll" type="radio" value="view_all" name="selOptions" onChange={ this.dataFilter } />View All(Default)</label>
										</div>
										<div className="radio">
										  <label><input type="radio" value="required_only" name="selOptions" onChange={ this.dataFilter } />View Required </label>
										</div>
										
										{this.state.kioskPreviewData.length > 0 ?
											<div>
											<Button type="button" onClick={()=> this.closePreview()} >Return to full form view </Button>
											</div>
										:null}
                                    </div>:null}
                                    
                                </div>
								<div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-1">
                                                <button type="button" className="btn btn-primary mb-2"> <i className="fas fa-file-pdf"></i></button>
                                            </div>
                                            <div className="col-md-11">
													<select className="form-control" onChange={this.pdfLinkValue}>
														<option value="#"> Please Select</option>
                                                        {optionsPdf}
													</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <a href="#" target="_blank" className="pdf-link btn btn-primary mb-2" onClick={this.pdfLink}><i class="fa fa-cloud-download" aria-hidden="true"></i></a> &nbsp;
                                        <button type="button" className="btn btn-primary mb-2" data-toggle="modal" data-target="#myPdfModal"><i className="fa fa-envelope"></i></button>
                                    </div>
                                    
									{sheetData.length > 0 ?
									<div className="col-md-3">									
										<div className="radio">
										  <label><input type="radio" value="needAttention_only" name="selOptions" onChange={ this.dataFilter } />Need Attention Only</label>
										</div>
										<div className="show-output-text">
										<label className="showAllSwitch">
											<input type="checkbox" name="showOutputText" onClick={this.showOutputText}/>
											<span className="slider round showAllSlide"></span>
										</label> Show Output Text
										</div>
									</div>
									:null}
									
                                </div>
								</div>
								}
                                <hr/>
                                { sheetData.length>0 && this.state.kioskPreviewData.length < 1 ?
                                    <ProgressBar completed={this.state.reqCmpCount} total={this.state.reqCount} />
                                :null}
                                { this.state.kioskPreviewData.length < 1 ?
                                    <MainData 
                                        username = {this.username}
                                        selectedSheet= {this.state.selectedSheet}
                                        selDropOpt = {this.state.selDropOpt}
                                        sheetData = {sheetData}
                                        kioskPreviewData = {this.state.kioskPreviewData}
                                        kioskResponse={kioskResponse}
                                        kioskUserName={kioskUserName}
                                        feedsResponse={this.state.feedsResponse}
                                        assoDeviceTypeName={this.state.assoDeviceTypeName}
                                        assoVisitTypeName={this.state.assoVisitTypeName}
                                        getDirectoryFiles={this.getDirectoryFiles}
										apiPdfs = {this.state.apiPdfs}
                                        moduleName={moduleName}
                                        showSign={this.showSign}
                                        handleFeeds={this.handleFeeds}
                                        handleNeeds={this.handleNeeds}
										saveNeedsNote = {this.saveNeedsNote}
										removeNeedsNote = {this.removeNeedsNote}
                                        saveFeedsNote = {this.saveFeedsNote}
                                        removeFeedsNote = {this.removeFeedsNote}
                                        checkedFeeds={this.state.checkedFeeds}
                                        checkedNeeds={this.state.checkedNeeds}
										selectedOpt = {this.state.selDropOpt}
                                        allData = {allData}
                                        checkedKiosk={this.state.checkedKiosk}
                                        checkedReq ={this.state.checkedReq}
                                        getRsponseValue ={this.getRsponseValue}
										current_user ={this.state.current_user}
										sheetConfigId={this.state.sheetConfigId}
										outputText={this.state.outputText}
                                    />
                                :null}
                                {sheetData.length > 0 && this.state.kioskPreviewData.length > 0 ?
                                <div>
								<div className="kiosk-data">
                                {uType.map((type,i) => (
                                    
                                        <KioskData 
                                            sheetData = {sheetData}
                                            checkedReq = {this.state.checkedReq}
                                            kioskPreviewData = {this.state.kioskPreviewData}
                                            moduleName={moduleName}
                                            kioskResponse={kioskResponse}
                                            userClassResponse={userClassResponse}
                                            allData = {allData}
                                            getRsponseValue ={this.getRsponseValue}
                                            type = {type}
                                        />
                                ))}
                                </div>
								<div className="pdf-data">
                                {uType.map((type,i) => (
                                    
                                        <PdfData 
                                            sheetData = {sheetData}
                                            checkedReq = {this.state.checkedReq}
                                            kioskPreviewData = {this.state.kioskPreviewData}
                                            moduleName={moduleName}
                                            kioskResponse={kioskResponse}
                                            userClassResponse={userClassResponse}
                                            allData = {allData}
                                            getRsponseValue ={this.getRsponseValue}
                                            type = {type}
                                        />
                                ))}
                                </div>
								</div>
                                :null}
                                {moduleName == 'test_forms' && sheetData.length>0 ? 
                                <div className="row">
                                    <div className="col-md-12">
                                    {sheetData.length > 0 && this.state.kioskPreviewData.length > 0 ?
                                        <div>
                                        <Button type="button" className="btn btn-danger float-right" onClick={()=> this.createPdf()}><i className="fas fa-file-pdf"></i> </Button>
										<Button type="button" className="float-right" onClick={()=> this.closePreview()} >Return to full form view </Button>
										</div>
                                    :null}
									{kioskMode ? 
									<Button type="button" className="float-right sv-btn" onClick={()=> this.handleSubmitTempSave(this.state.mainSheet)} >Save </Button>									
									:
                                        <Button type="button" className="float-right" onClick={()=> this.handleSubmitSave(this.state.mainSheet)} >Save </Button>
									}
                                    </div>
                                </div>
                                :null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="myModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Do you want to go Delegate Mode ?</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <label>Select Users Class</label>
                            { sheetData ?
                            <KioskDrop
                                multiple ='false'
                                selectKioskUsers={this.selectKioskUsers}
                                kioskType={kioskType}
                                kioskUsers = {this.state.kioskUsers}/>:null}
                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-primary float-left" onClick = {()=>this.goKisko(this.state.allData,this.state.kioskUsers)} >Ok</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                          </div>

                        </div>
                    </div>
                </div>
                <div className="modal" id="loginModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Enter a password</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <form>
                                <div className="modal-body">      
                                    <input type="password" className="form-control" ref="password" placeholder="enter a password" onKeyDown={this.keyPress} />
                                    { this.state.errors ?
                                    <p className=" alert-danger"> { this.state.error } </p>:null }                                    
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary float-left" onClick={this.handleSignIn}>Ok</button>
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="myEmailModal" role="dialog">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Send Link</h5>
                        </div>
                        <div className="modal-body">
                            <div>
                                <label>Enter Email address to invite to telehealth visit:</label>
                                <input className="form-control" type="email" name="emailId" value={this.state.emailId} onChange={this.handleEmail}/>
                                { errorMessage ?
                                <div className="alert alert-danger" role="alert">
                                    {this.state.errorMessage}
                                </div>:null}

                            </div>
                            <div>
                                <label>Include message to send with link:</label>
                                <textarea className="form-control" type="text" name ="emailMessage" value={this.state.emailMessage} onChange={this.handleEmail}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary float-left" onClick={this.sendEmail}>Send Link</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                      
                    </div> 
                </div>
                <div className="modal fade" id="myPdfModal" role="dialog">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Send Medical Records</h5>
                        </div>
                        <div className="modal-body">
                            <div>
                                
                                <b>To: </b><input className="form-control" type="email" name="emailId" value={this.state.emailId} onChange={this.handleEmail}/>
                                { errorMessage ?
                                <div className="alert alert-danger" role="alert">
                                    {this.state.errorMessage}
                                </div>:null}

                            </div>
                            <div>
                                <label><b> Message:</b></label>
                                <textarea className="form-control" type="text" name ="emailMessage" value={this.state.emailMessage} onChange={this.handleEmail}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary float-left" onClick={this.sendPdfEmail}>Send</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                        </div>
                      </div>
                      
                    </div> 
                </div>
                <div className="modal" id="directoryFiles1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Upload File</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <form>
                                <div className="row">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">File</th>
                                                <th scope="col">Path</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                        {(fileArray || []).map(url => (
                                            <tr className="view-file" id={'file'+url.id}  onClick = {()=>this.getFilePath(url)}>
                                                <td scope="row">{url.id}</td>
                                                <td scope="row">{url.name}</td>
                                                <td scope="row">{url.path}</td>
                                                <td>{url.description}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <input type="hidden" id="fileId" value="" />
                                    </table>
                                </div>
                            </form >
                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-primary float-left" onClick={this.uploadFilePath}>Save</button>
                            <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                          </div>

                        </div>
                    </div>
                </div>
            </div>
        );
        }
        else{
            return (<div className="card-body">Loading...</div>)
        }
    }
}

export default TestForm;