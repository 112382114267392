import React, { Component, PropTypes } from 'react';
import 'bootstrap';
import $ from 'jquery';
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { generatehtmlfrom } from "../../Helpers/Common";
export default class ViewPdfData extends Component {
    constructor(props){
        super(props)
    }
	
    classTypes(comment){
        let notInclude = '';
        let parentHeader = '';
        let type = 'view';
        if(comment[36] == 'Deleted' || comment[5].toLowerCase() == 'boxbegin' || comment[5].toLowerCase() == 'boxend' || comment[5].toLowerCase() == 'link'){
            notInclude = 'not-include';
        }
        if(comment[5].toLowerCase() == 'parent header'){
            parentHeader = ' parent-header ';
        }
		if(comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3' && comment[5].toLowerCase() != 'title' && comment[5].toLowerCase() != 'text'){
            parentHeader = ' question ';
        }
		
		if(comment[5].toLowerCase() == 'title'){
			parentHeader = ' hide-title ';
		}
		
        let className = type+'_'+comment[0]+' childDiv ' + notInclude + parentHeader;
		
		setTimeout(function(){
			
			if($('.'+type+'_'+comment[0]).find('.question').length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
				$('.'+type+'_'+comment[0]).addClass("not-include empty-element");
				//$('.'+type+'_'+comment[0]).remove();
				$('.'+type+'_'+comment[0]).css('display','none');
			}else{
				$('.'+type+'_'+comment[0]).removeClass("not-include");
				$('.'+type+'_'+comment[0]).css('display','block');
			}
			
			if(comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3' && comment[5].toLowerCase() != 'title' && comment[5].toLowerCase() != 'text'){
				$('.'+type+'_'+comment[0]).prev().removeClass('hide-title');
			}
			
		}, 2000);
		
		setTimeout(function(){
			$('.'+type+'_'+comment[0]+" .hide-title" ).css('display','none');
		}, 4000);
		
        return className;
    }
    
    headerTypes(comment){
        let header = '';
        switch (comment[5].toLowerCase()) {
            case 'parent header':
                header = 'parent';
                break;
            case 'child header 1':
                header = 1;
                break;
            case 'child header 2':
                header = 2;
                break;
            case 'child header 3':
                header = 3;
                break;
            default:
                header = 'q';                   
        }
        return header;
    }
	
    subQuestions = (value,parent,id) => {
        let combVis1   = id+'-'+value.toLowerCase();
        let newString  = combVis1.replace(/[^A-Z0-9]+/ig, "");
        var divClass  = $("."+newString).attr('class');
        let subQustionData  = this.props.allData;
        let ids = [];
        $("."+id).each(function( index) {
            var ids= $(this).attr('id');
            $('.'+ids).addClass(id);
        });
        $("."+id).collapse('hide');
		subQustionData.map((answers, i) => {
            let combVis   = answers[7]+'-'+answers[8].toLowerCase();
            let visibility1= combVis.replace(/[^A-Z0-9]+/ig, "");
            if(visibility1 == newString) {
                $("."+newString).collapse('show');
            }
        })
    }
    
    getData(comment){
        var questions ='';
        var answerType = '';
        var options = '';
        var id = comment[1]
        var location = comment[9];
        var parent = comment[2]
        questions = comment[4];
        var visibility1 = comment[8];
        var visibility = comment[7];
        var action  = comment[17];
        if(this.props.selectedOpt== 'improved' && action =='changeQformat'){
            questions =comment[23] ? comment[23] :comment[4];
            answerType = comment[24] ? comment[24] :comment[5];
            options = comment[22] ? comment[22] :comment[6];
        }else{
            answerType = comment[5];
            options = comment[6];
        }
        var field_id = comment[0];
		var label = comment[41];
        let rowAllData = [id,location,parent,questions,answerType,options,visibility1,visibility,field_id,label];
        return rowAllData;   
    }
	
    render() {
        const { kioskPreviewData,sheetData,queId,comment,moduleName,selectedOpt,allData, checkedReq, kioskResponse,userClassResponse} = this.props
		
		return (
           <div id={queId ? queId: ''} className={queId ? 'view-'+ comment[0] : 'commentss'} >
			
			{sheetData.map((comment,i) => {
				let res = kioskResponse.get(comment[0]);
				
				let type = 'view';
				return( <div id={comment[0]} data-pos={comment[10]} key={comment[0]} data-header={this.headerTypes(comment)} className={this.classTypes(comment)}>
                {(() => {
                    let rowAllData=this.getData(comment,selectedOpt);
                    let question = comment[4];
					if(comment[9]){
						question = comment[9];
					}
                    let answer = generatehtmlfrom(rowAllData,this.subQuestions,this.props);
                    
                    switch (comment[5].toLowerCase()) {
                        case 'parent header':
                            return <h3 id ={type+'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{ question } </h3>;
                        case 'child header 1':
                            return <div className="child-headers">
                                        <h4 id ={type+'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{question}</h4>
                                    </div>
                        case 'child header 2':
                            return <div className="child-headers">
                                    <h5 id ={type+'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{question}</h5>       
                            </div>;
                        case 'child header 3':
                            return <div className="child-headers">
								<h6 id ={type+'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{question}</h6>    
                            </div>;
                        case 'text' :
                                return <div>
                                           { answer == '' ?
												<div className="col-md-12"><b>{question}</b> { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
												: <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
											}
                                            <div style={{padding: "10px"}} className="answer">
												{ answer }
											</div>
                                           
                                        </div>
                        case 'title' :
                            return <div>
									{ answer == '' ?
										<h5><b> { question } { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</b></h5>
									: <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>}
									<div style={{padding: "10px"}} className="answer">
										{ answer }
									</div>
								</div>
						case 'q5' :
                            return <div>
                                   <div className="col-md-12"> <span className="answer">{ answer }</span> {question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                            
                                </div>
						case 'image' :
                            return <div>
                                <div style={{padding: "10px"}} className="answer">
									<img src={res} width="259px" height="200px"/>
								</div>
								<div className="col-md-12">
                                    { question }   
                                </div>
                                            
                            </div>
						case 'video' :
                            return <div>
                                <div className="col-md-12">
                                    { question }   
                                </div>
                                <div style={{padding: "10px"}} className="answer">
									<div><b>Additional File in records</b><br />
									{ ReactHtmlParser(res) }
									</div>
								</div> 
                                            
                            </div>
						case 'inlinevideo' :
                            return <div>
                                <div className="col-md-12">
                                    { question }   
                                </div>
                                <div style={{padding: "10px"}} className="answer">
									<div><b>Additional File in records</b><br />
									{ ReactHtmlParser(res) }
									</div>
								</div> 
                                            
                            </div>
                        case 'file' :
						case 'pdfapi' :
                            let type1= '';
                            let setUrl = '';
                            let getUrl ='';
                            if(typeof res != "undefined" && res != ''){
                                getUrl = res.split(',');
                                setUrl = getUrl[0];
                                type1 = getUrl[0].split('.').pop();
                            }
                            return <div>
                                <div className="col-md-12">
                                    { question }   
                                </div>
                                <div style={{padding: "10px"}} className="answer">
									<div><b>Additional File in records</b><br />
									   <div id={'fileAns'+comment[0]}>
                                            <b>Name: </b>{getUrl[1]}<br/>
                                            <b>Description: </b>{getUrl[2]}<br/>
                                            <b>Date: </b>{getUrl[3]}
                                        </div>
									</div>
                                </div> 
                                            
                            </div>
						case 'calculation':
						case 'wcalculation':
							return (
								<div className="row" style={{padding: "10px", width:'190px'}}>
										<div className="col-md-5 cal-res-box" style={{padding: "20px", border: "1px solid", textAlign: "center" }}>
											<div className={"cal-res cal-res-" +comment[0] }>{res ? res : ''}</div>
											<div className="user-text">
											{question}
											</div>
										</div>
									
									</div>)
						case 'subjective':
							var styles = {padding: "10px", border:"1px solid #cccccc"}
							return (
									<div>
									<div className="col-md-12"><h5>Subjective</h5></div>
                                   <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                    <div style={styles} className="answer">
										{ answer }
									</div>        
                                </div>)
						case 'assessment':
							var styles = {padding: "10px", border:"1px solid #cccccc"}
							return (
									<div>
									<div className="col-md-12"><h5>Assessment</h5></div>
                                   <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                    <div style={styles} className="answer">
										{ answer }
									</div>        
                                </div>)
						case 'link':
                            return ('')
                        default:
                            return (
                                <div>
                                   <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                    <div style={{padding: "10px"}} className="answer">
										{ answer }
									</div>        
                                </div>)
                    }
                })()}
                {comment.sub 
                    && 
                    <ViewPdfData 
                        sheetData={comment.sub}
                        checkedReq ={checkedReq}
                        kioskResponse={kioskResponse}
                        userClassResponse={userClassResponse}
                        queId={comment[1].toLowerCase()}
                        moduleName={moduleName} 
                        comment= {comment} 
                        allData = {allData}
                        />
                }
				</div>)
            } )}
          </div>
        )
    }
}