import React, { Component } from 'react';
import config from '../../config';
import axios,{post} from 'axios';
import $ from 'jquery';
import { load, uploadFilePath, getSheetType, getSheetTypeFacility, getPdfFromApi, getSheetsById, handleSubmitResetInc, saveConfigFile, getConfigData } from '../../Helpers/Common';
import { gapi } from 'gapi-script';
import TopFilter from '../Header/TopFilter'
import { Form,Button } from 'react-bootstrap';
import { KioskDrop } from "../FormBuilder/KioskDrop";
import KioskData from "../TestForm/KioskData";
import KioskDataView from "../FormBuilder/KioskData";
import PdfData from "../TestForm/PdfData";
import MainData from "../../component/MainData/MainData";
import ClinicalTracks from '../ClinicalTracks';
import guid from "../../Helpers/GUID";
import emailjs from 'emailjs-com';
import ProgressBar from "../progressBar";
class ConfigureLayouts extends Component{
    
	constructor(props) {
        super(props)
        this.state = {
            test_forms:[1,2,3],
            sheetData:[],
            sheetsName: [],
            exportData: [],
            current_user: [],
			selOptions:'view_all',
            assoType:[],
            assoDeviceType:[],
            assoVisitType:[],
            mod:'custom_config',
            kioskUsers:['CPO'],
			apiPdfs:[],
            kioskUserName:'',
            assoDeviceTypeName:'',
            assoVisitTypeName:'',
            kioskUsersData:new Map(),
            kioskResponse:new Map(),
            userClassResponse:new Map(),
            rResponse:new Map(),
			checkedInc:new Map(),
            checkedKiosk:new Map(),
            checkedReq:new Map(),
            checkedFeeds:new Map(),
            feedsResponse:new Map(),
            kioskPreviewData:[],
            setKiosk:false,
            kioskMode:false,
            showKioskBtn:false,
            selDropOpt:'original',
            moduleName:'custom_config',
            reviewSheetsName:[],
            userAccessSheets:[],
            reqCount: 0,
            reqCmpCount: 0,
            mdsGuid:'',
            emailMessage:'',
            emailId:'',
            errorMessage:'',
            successMessage:'',
            zoomLink:'',
			newCompanyName:'',
			companies:[],
			allPos: [],
			sheetConfigId:config.spreadsheetId,
			configData: [],
        }
        this.pdfArray = [];
		this.fileArray = [];
        if( localStorage.getItem('username')){
            this.username = localStorage.getItem('username');
        }else{
            this.props.history.push({
			  pathname: '/beta',
			  state: { moduleName: 'custom_config' },
			});
        }
        this.reviewSheetNames = this.reviewSheetNames.bind(this);
    }
    
	componentDidMount() {
        /* if(this.username == 'CC-1'){
            this.setState({kioskUsers: ['CaC'],showKioskBtn:true,setKiosk:true})
        }else if(this.username == 'Res-1'){
            this.setState({kioskUsers: ['Res'],showKioskBtn:true,setKiosk:true})
        }else if(this.username == 'Pat-1'){
            this.setState({kioskUsers: ['PhA'],showKioskBtn:true,setKiosk:true})
        }else if(this.username == 'MD-1'){
            this.setState({kioskUsers: ['PmD'],showKioskBtn:true,setKiosk:true})
        }else if(this.username == 'PT-1'){
            this.setState({kioskUsers: ['PhT'],showKioskBtn:true,setKiosk:true})
        }else{
            this.setState({kioskUsers: ['CPO'],showKioskBtn:false,setKiosk:false})
        } */
        gapi.load("client", this.initClient);
		
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = config.API_URL+'pdf/getPdf.php';
        axios.get(url, con)
        .then(response => {
			let pdfArray = response.data;
			this.setState({pdfArray});
        })
    }
	
	componentDidUpdate(){
		if(this.state.allData && this.state.checkedInc.size == 0){
			this.setDefaultCheckboxes();
        }
    }
	
	setDefaultCheckboxes=(IncAll= null)=>{
        let checkedInc = this.state.checkedInc;
        let checkedReq = this.state.checkedReq;
		let checkedKiosk = this.state.checkedKiosk;
		if(IncAll == 'IncAll'){
            this.setState({checkedKiosk: this.state.checkedKiosk,checkedReq: this.state.checkedReq});
        }else{
            this.state.allData.map((valu, i) => {
				let isChecked ="";
				let isChecked1 ="";
				let isChecked2 ="";
				
				if(valu[14] == 'Y'){
					isChecked =true;
				}else{
					isChecked=false
				}
				if(valu[15] == 'Y'){
					isChecked1 =true;
				}else{
					isChecked1=false
				}
				if(IncAll == 'IncAll'){
					isChecked =true;
				}
				if(valu[16]){
					let kioskDefalt =valu[16].split(',');
					if(kioskDefalt[8] == 'Y'){
						isChecked2 = true
					}else{
						isChecked2 = false
					}
                }
                checkedKiosk.set(valu[0],isChecked2);
                checkedInc.set(valu[0], isChecked)
                checkedReq.set(valu[0], isChecked1)
            })
			
            this.setState({checkedKiosk: checkedKiosk,checkedReq: checkedReq,checkedInc: checkedInc});
        }
    }
	
    initClient = () => {
        
        gapi.client.init({
           apiKey: config.apiKey,
           'clientId': config.client_id,
           'scope': 'https://www.googleapis.com/auth/spreadsheets',
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
            load(this.onLoad,this.state.sheetConfigId);
        });
		
		gapi.client.init({
		   apiKey: config.apiKey,
		   discoveryDocs: config.discoveryDocs,
		})
		.then(() => {
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: config.spreadsheetId,
					range:'User-Login',
				})
				.then(
					response => {
						let users = response.result.values;
						users.map((user,i)=>{
							if(this.username == user[0]){
								this.setState({ current_user:user });
							}
						});
						
					},
					response => {
						//alert(response.result.error.message);
						console.log('error',response.result.error);
					}
				);
			});
		
		});
    };
    
	deleteData(allDatas){
        var branch= [];

        for(let i = 0; i < allDatas.length; i++){
            let row = Object.assign([], allDatas[i]);
            if(row.sub){
                delete row.sub;
            }
            branch.push(row);
        }
 
        return branch;
    }

    onLoad = (data, error) => {
		
		let user = this.state.current_user;

		if (data) {
            const sheetsName = data.sheetsName.slice(0,3);
            const sheetsId = data.sheetsId.slice(0,3);
            let sheetpro = [];
            let sheetsNames = data.sheetsName;
			
            { sheetsNames.map((sheet, i) => {
				
					if(typeof(user[4]) != "undefined" && user[4] != 'All' && user[4] != ''){
						let viewAbleSheet = user[4].split(',');
						if(viewAbleSheet.indexOf(sheet) == -1){
							return true;
						}
					}
					
                    let sh = {id:data.sheetsId[i], name:sheet, type:'', deviceType:'', VisitType:'',visibility:''};
                    //if(i <= config.showSheetInDrop){
                        data.sheetType.map((sheetName,j)=>{
                            if(sheet == sheetName.name){
                                sh = {id:data.sheetsId[j], name:sheet, type:data.sheetType[j].type, deviceType:data.sheetType[j].deviceType, visitType:data.sheetType[j].visitType, visibility:data.sheetType[j].visibility};
                            }
                        })
						
						if(sh.type && sh.deviceType && sh.visitType && sh.visibility){
							sheetpro.push(sh);
						}
                   //}
            })};
			
			let visibileSheet = [];
			{ sheetpro.map((sheet, i) => {
				if(sheet.visibility == 'TRUE' && visibileSheet.length <= config.showSheetInDrop){
					visibileSheet.push(sheet);
				}
			})};

			this.setState({ sheetsName:visibileSheet });
            this.setState({ sheetsNameOrig:visibileSheet });
			let firstSheet = visibileSheet[0].name;
			this.loadFilter(firstSheet);
			
        } else {
            this.setState({ error });
        }
		
		let that = this;
		getPdfFromApi().then(function(response) {
			that.setState({apiPdfs:response});
		}, function(error) {
		  console.error("Failed!", error);
		})
    };
	
    loadFilter(sheet){
        let params1 =  {
            spreadsheetId: config.spreadsheetId,
            range:sheet+'!CW2:CY2',
        };
        var that = this;
        var assoType =[];
        var assoDeviceType =[];
        var assoVisitType =[];
            let getRequest = gapi.client.sheets.spreadsheets.values.get(params1);
            getRequest.then(function(response) {
                var getData= response.result.values;
				
                if(getData[0][0]){
                    var arr = getData[0][0].split(',');
					arr.map((t,i)=>{
                        assoType.push(t);
                    })
                    
                }
                if(getData[0][1]){
                    var arr = getData[0][1].split(',');
                    arr.map((t,i)=>{
                        assoDeviceType.push(t);
                    })
                    
                    
                        
                }
                if(getData[0][2]){
                    var arr = getData[0][2].split(',');
                    arr.map((t,i)=>{
                        assoVisitType.push(t);
                    })
                    
                            
                }
				
                that.setState({assoType,assoVisitType,assoDeviceType});
            })
    }
	
    logout = () => {
        //localStorage.removeItem('id');
        localStorage.removeItem('username');
        this.props.history.push('/beta');
    }
	
    getSheetValue=(event)=>{
        let sheetId = event.target.options[event.target.selectedIndex].dataset.id;
        this.setState({selectedSheet1:event.target.value});
        this.setState({selectedSheetId:sheetId});
        this.onSelect(event.target.value, sheetId);
    }
	
    reviewSheetNames(mod){
		
		let configName = 'TTEVAL-Config Files';
		gapi.client.init({
           apiKey: config.apiKey,
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
			gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: config.spreadsheetId,
					range:configName,
				})
				.then(response => {
					let sheetVal = response.result.values;
					let sheets = [];
					sheetVal[0].map((value, i) => {
						if(i > 0){
							let configId = value[4];
							let col = [];
							sheetVal.map((val, j) => {
								let column = val[i];
								if(column){
									col.push(column);
								}
							});
							
							sheets.push(col);
						}
					});
					
					let selectedSheet = this.state.selectedSheet;
					
					sheets.map((sheet, i) => {
						if(selectedSheet != sheet[1]){
							delete sheets[i];
						}
					})
					
					sheets = sheets.filter(function (el) {
					  return el != null;
					});
					
					let sheetpr = [];
					
					sheets.map((sheet, i) => {
						let sht = {name:sheet[0], type:sheet[6], deviceType:sheet[7], visitType:sheet[8], mdsGuid:sheet[2], configGuid:sheet[4], date:sheet[3], facilityId:sheet[9]};
						
						sheetpr.push(sht);

					});
					
					let filters = this.state.filters;
					let sheetpro = [];
					
					if(filters){
						sheetpro = this.filterPlainArray(sheetpr, filters);
					}else{
						sheetpro = sheetpr;
					} 
					this.setState({configSheets:sheets, reviewSheetsName:sheetpro, reviewSheetsNameOringn:sheetpro});
					
					//console.log('sheetpr->',sheetpr);
				});
			});	
		}); 
        
    }
	
    showSign=(type,id)=>{
        $('#'+type+id).css('display','block')

    }
	
	filterPlainArray(array, filters) {
	  const filterKeys = Object.keys(filters);
	  return array.filter(item => {
		// validates all filter criteria
		return filterKeys.every(key => {
		  
			if (!filters[key].length) return true;
			if (item[key] == undefined || item[key] == ''){
				item[key] = 'none'; 
			}
			
			var arr = item[key].split(',');
			var filVal = filters[key].find(filter => filter);
			if (filVal== undefined) return true;
		  
			if(arr.indexOf(filVal) !== -1){
				return true;
			}
	
		});
	  });
	}

    handleHeaderFilter=(event)=>{
        let name = event.target.name;
        let res = event.target.value;
        this.setState({[name]:event.target.value})
        var sheetpro = [];
        var sheetpro1 = [];
		
		let typeVal = '';
		
		let deviceVal = '';
		
		let visitVal = '';
		
		if(name == 'assoTypeName'){
			typeVal = event.target.value;
		}	
		else if(this.state.assoTypeName != 'undefined'){
			typeVal  = this.state.assoTypeName
		}
		
		if(name == 'assoDeviceTypeName'){
			deviceVal = event.target.value;
		}else if(this.state.assoDeviceTypeName != 'undefined'){
			deviceVal  = this.state.assoDeviceTypeName
		}
		
		if(name == 'assoVisitTypeName'){
			visitVal = event.target.value;
		}else if(this.state.assoVisitTypeName != 'undefined'){
			visitVal  = this.state.assoVisitTypeName
		}
		
		var allSheet = this.state.sheetsNameOrig;
		
		var allSheetOringm = this.state.reviewSheetsNameOringn;
		

		const filters = {
		   type: [typeVal],
		   deviceType: [deviceVal],
		   visitType: [visitVal],
		};

		sheetpro = this.filterPlainArray(allSheet, filters);
		
		if(allSheetOringm){
			sheetpro1 = this.filterPlainArray(allSheetOringm, filters);
		}
		
		this.setState({sheetsName:sheetpro,reviewSheetsName:sheetpro1, filters});

    }
   
    clearAllFilter=()=>{
        this.setState({sheetsName:this.state.sheetsNameOrig,reviewSheetsName:this.state.reviewSheetsNameOringn})
    }
	
    getValue=(event)=>{
        $("input[type='radio']").prop("checked", false);
        let sheetId = event.target.options[event.target.selectedIndex].dataset.id;
        /* if(sheetId){
           this.setState({selectedSheetId:sheetId});
        }else{
            sheetId = this.state.selectedSheetId
        }
        this.setState({selectedSheetId:sheetId}); */
        this.setState({reviewVer:event.target.value});
        let sheetName = this.state.selectedSheet1;
        this.selectReviewOpt(event.target.value,sheetId, sheetName);
    }
	
    selectReviewOpt=(reviewStatus, selectedSheetId)=>{
        //this.setState({selectedSheetId,selectedOpt:reviewStatus});
        if(reviewStatus == 'original'){
            window.location.reload(); 
        }else if(reviewStatus !== 'improved' && reviewStatus !== 'original'){
            this.setState({selDropOpt:'exported',exportedSelectedSheet:reviewStatus});
            var  singleSheetName = reviewStatus;
            
			let configSheets = this.state.configSheets;
			let configData = getConfigData(singleSheetName, configSheets);
			//console.log('configData->',configData);
			if(configData.length > 0){
				let allData = this.state.allData;
				this.setState({configData});
				allData.map((data,i)=>{
					if(configData[i]){
						if(data[0] == configData[i][0]){
							for (let j in allData) {
								if(j==10){
									data[j] = configData[i][7];
								}
								if(j==14){
									data[j] = configData[i][5];
								}
								if(j==15){
									data[j] = configData[i][6];
								}
								if(j==16){
									data[j] = configData[i].join();
								}
								if(j==37){
									data[j] = configData[i][8];
								}
							}
						}
					}else{
						data[10] = 0;
						data[14] = 'N';
					}
				})
				
				this.setState({allData});
				this._answer(allData)
				
				let that = this;
				setTimeout(function(){
					that.setDefaultCheckboxes();
				}, 3000);
			}
        }
    }
	
    selectKioskUsers=(event)=>{
        this.setState({kioskUsers: Array.from(event.target.selectedOptions, (item) => item.value)})
    }
	
    onSelect = (sheetsName, selectedSheetId) => {
		
        let spreadsheetId = config.spreadsheetId;
        var that = this;
        var  singleSheetName = sheetsName;
        gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: spreadsheetId,
                range:singleSheetName, 
            })
            .then(
                response => {
					this.emptyState();
					
                    const allData = response.result.values;
					if(allData.length > 0){
						let mdsGuid = allData[1][91];
						this.setState({mdsGuid});
						
						let kioskType = allData[1][119].split(',');
						let username = this.username;
						this.setState({kioskUserName:'Practitioner',kioskUsers: ['CPO'],showKioskBtn:false,setKiosk:false});
						kioskType.map((type,i)=>{
							type = type.split(':');
							let user = username.split('-');
							if(user[0] == type[1] && (username == 'CC-1' || username == 'Res-1' || username == 'Pat-1' || username == 'MD-1' || username == 'PT-1')){
								this.setState({kioskUserName:type[0],kioskUsers: [type[1]],showKioskBtn:true,setKiosk:true});
							}
						})
						
					}
                    allData.shift();
                    this.setState({allData});
                    this.reviewSheetNames(this.state.mod);
                    this._answer(allData)
					
					
                },function(reason) {
					alert(reason.result.error.message);
				}
            );
        });
        this.setState({selectedSheet:sheetsName,mainSheet:sheetsName,selDropOpt:'original',reviewVer:'original',selectedSheetId,orginalSelectedSheet:sheetsName});
		
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:'Company Name', 
			})
			.then(
				response => {
					const companyName = response.result.values;
					this.setState({ companies:companyName });
					
				},function(reason) {
					alert(reason.result.error.message);
				}
			);
		});
    }
	
	emptyState=()=>{
		
		let kioskResponse = this.state.kioskResponse;
		let userClassResponse = this.state.userClassResponse;
		let rResponse = this.state.rResponse;
		let checkedReq = this.state.checkedReq;
		this.setState({kioskResponse:new Map()});
		this.setState({userClassResponse:new Map()});
		this.setState({rResponse:new Map()});
		this.setState({checkedReq:new Map()});
		
	}
    
    handleSignIn=()=> {
        let password = this.refs.password.value
        if(password  == 'abc123'){
            let kioskUsers = this.state.kioskUsers;
            this.setState({errors:false,error:''});
            $('#loginModal').modal('hide');
            $('.card').css('backgroundColor','#fff')            
            let allData = this.state.allData;
            allData.map((rowDatas,i)=>{
                $('.headers-'+rowDatas[0]).find('i').removeClass("fa fa-minus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).find('i').addClass("fa fa-plus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).removeClass("collapsed");
                $('.inner-'+rowDatas[0]).removeClass("show");
                $('.pre-question').removeClass("show");
            })
            let t =true
            if(this.username == 'CPO-1' || this.username == 'CPO-2' || this.username == 'CPO-3' || this.username == 'CPO-4' || this.username == 'CPO-5' || this.state.setKiosk == true){
                t =false;
            }

            this.setState({setKiosk:t,kioskMode:!this.state.kioskMode,kioskUsers:['CPO'],showKioskBtn:t},()=>this._answer(allData));
            
        }else{
            this.refs.password.value = "";
            this.setState({errors:true,error:'Please enter a valid password'});
        }        
    }
    
	checkKey2(elements, parentId = '',ids ,pId, cId) {
        var branch = [];
        { elements.map((element,i) => {
            if(element[5] == 'Parent Header'){
                element[pId] = '';
            }  
            if (element[pId].toLowerCase() == parentId) {
                if(element[cId] == ''){
                    element[cId]='a';
                }
                var children = this.checkKey2(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children) {
                    element['sub'] = children; 
                }
                if(!ids.includes(element['0'])){
                    branch.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch;    
    }
	
	checkKey3(elements, parentId = '',ids ,pId, cId) {
        var branch1 = [];
        { elements.map((element,i) => {
            if(element[5] == 'Parent Header'){
                element[pId] = '';
            }  
            if (element[pId].toLowerCase() == parentId) {
                if(element[cId] == ''){
                    element[cId]='a';
                }
                var children1 = this.checkKey3(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children1) {
                    element['sub2'] = children1; 
                }
                if(!ids.includes(element['0'])){
                    branch1.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch1;    
    }
	
    showAddUsersModal=(id)=>{
        let allDatas = this.state.allData;
        var res = ''
        allDatas.map((rowDatas, i) => {
            if(rowDatas[0] == id){
                let posData = rowDatas[34]
                res = posData.split(',');
            }
        })
        if(res !== ''){
            var array = res.slice(-9);
            this.setState({kioskUsers:array})
        }else{
            this.setState({kioskUsers:['CPO']})
        }
        $('#kiosk'+id).modal('show')
    }
	
    checkPreCondition(item, items, type) {
        let that = this;
        $("."+item).find("."+type).each(function() {
            let name = $(this).attr('name')
            items.push(name);
            that.checkPreCondition(name, items, type);
        });
        return items;
    }
    
	userDefined(){
        let kioskUsers = this.state.kioskUsers;
        if(this.username == 'CPO-1' || this.username == 'CPO-2' || this.username == 'CPO-3' || this.username == 'CPO-4' ||  this.username == 'CPO-5' || kioskUsers[0] == 'CPO'){
            this.setState({kioskMode:false})
        }else{
            this.setState({kioskMode:true});
        }
    }
	
    showKioskUserName=()=>{
        if(this.username == 'CC-1'){
            this.setState({kioskUserName: 'Care Coordinator'})
        }else if(this.username == 'Res-1'){
            this.setState({kioskUserName: 'Resident'})
        }else if(this.username == 'Pat-1'){
            this.setState({kioskUserName:'Practitioner Assistant'})
        }else if(this.username == 'MD-1'){
            this.setState({kioskUserName:'Physician'})
        }else if(this.username == 'PT-1'){
            this.setState({kioskUserName:'Physical Therapist'})
        }else{
            this.setState({kioskUserName:'Practitioner'})
        }
    }
    
	_answer=(allData)=>{
		let kioskUsers = this.state.kioskUsers
        let data = this.state.allData;
        //let sampleData = this.deleteData(data);
        let mainDataN =[];
        let reqCmpCount2 =[];
        
        let checkedReq = this.state.checkedReq;
        data.map((rowDatas,i)=>{
            let configDataRow = '';
            let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
            if(columnName){
                configDataRow = columnName.split(',');
                configDataRow.splice(0, 9);
            }
            if(rowDatas[5] == 'Parent Header'){
                mainDataN.push(rowDatas);
            }else if(rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                mainDataN.push(rowDatas);
            }else if(configDataRow.indexOf(kioskUsers[0]) != -1){
                mainDataN.push(rowDatas);
            }    
        })
        
        let ids =[];
        let mainData =[];
        {mainDataN.map((data,i)=>{
            var key = data[10];
            mainData[key] = data; 
        })}
        
        this.setState({mainData});
        
        var dbArray = this.checkKey2(mainData, '', ids, 2, 1);
        
        let db3 =[];
        dbArray.map((rowDatas,i)=>{
            if( rowDatas['sub'].length != 0 ){
                db3.push(rowDatas);
            }
        })
        this.setState({sheetData:db3});
        this.userDefined();
        //this.showKioskUserName();
        if(kioskUsers.toString() == 'PhT'){
            $('.card').css('backgroundColor','#FFCCE5')
        }else if(kioskUsers.toString() == 'CPO'){
            $('.card').css('backgroundColor','#FFFFFF')
        }else if(kioskUsers.toString() == 'Int' || kioskUsers.toString() == 'Res' || kioskUsers.toString() == 'PrA' || kioskUsers.toString() == 'PA' ){
            $('.card').css('backgroundColor','#B2FF66')
        }else if(kioskUsers.toString() == 'PhA' || kioskUsers.toString() == 'PmD'){
             $('.card').css('backgroundColor','#DCDCDC')
        }else if(kioskUsers.toString() == 'Pat'){
             $('.card').css('backgroundColor','#ccf5ff')
        }else if(kioskUsers.toString() == 'CC'){
            $('.card').css('backgroundColor','#FFFF99')
        }
        if(kioskUsers.toString() != 'CPO'){
            $('.btn-tag').hide();
        }
		let that = this;
    }
	
    setKioskMode=(setKiosk)=>{
        let kioskUsers = this.state.kioskUsers
        let data = this.state.allData;
        let sampleData = this.deleteData(data);
        let rResponse = this.state.rResponse;
        this.setState({reqCount:0})
        this.setState({reqCmpCount:0})
        let mainDataN =[];
        let reqCount1 =[];
        let reqCmpCount1 =[];
        let sOptions = $("input[name='selOptions']:checked").val();
        if(setKiosk == true){
            this.setState({showUserBtn:false,kioskMode:true})
            sampleData.map((rowDatas,i)=>{
                let configDataRow = '';
				let columnName = rowDatas[16];
				if(columnName){
					configDataRow = columnName.split(',');
					configDataRow.splice(0, 9);
				}
                if(rowDatas[5] == 'Parent Header'){
                    mainDataN.push(rowDatas);
                }else if(rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                    mainDataN.push(rowDatas);
                }else if(configDataRow.indexOf(kioskUsers[0]) != -1){
                    if(sOptions == 'required_only'){                                
                        if (rowDatas[15] == 'Y') {
                            mainDataN.push(rowDatas);
                        }
                    }else{
                        mainDataN.push(rowDatas);
                    }
                    
                    if(rowDatas[15] == 'Y'){
                        reqCount1.push(rowDatas[0]);
                        let res = rResponse.get(rowDatas[0]);
						//console.log('rResponse',rResponse);
                        
						if((typeof res != "undefined" && res != '') || (typeof rowDatas[109] != "undefined" && rowDatas[109] != '')){
                            reqCmpCount1.push(rowDatas[0]);
                        }
                    }
                }   
            })
            
            this.setState({reqCount:reqCount1.length})
            this.setState({reqCmpCount:reqCmpCount1.length})
        
            this.setState({mainDataN})
            let ids =[];
			
			let mainData =[];
			
			{mainDataN.map((data,i)=>{
				var key = data[10];
				mainData[key] = data; 
			})}
			
            var dbArray = this.checkKey2(mainData, '', ids, 2, 1);
            let db3 =[];
            dbArray.map((rowDatas,i)=>{
                if( rowDatas['sub'].length != 0 ){
                    db3.push(rowDatas);
                }
            })
            this.setState({sheetData:db3});
        
            if(kioskUsers.toString() == 'PhT'){
                this.setState({kioskUserName:'Physical Therapist'})
                $('.card').css('backgroundColor','#FFCCE5',)
            }else if(kioskUsers.toString() == 'CPO'){
                this.setState({kioskUserName:'Practitioner'})
                $('.card').css('backgroundColor','#FFFFFF')
            }else if(kioskUsers.toString() == 'Int'){
                this.setState({kioskUserName:'Intern'})
                $('.card').css('backgroundColor','#B2FF66')

            }else if(kioskUsers.toString() == 'Res'){
                this.setState({kioskUserName: 'Resident'})
                $('.card').css('backgroundColor','#B2FF66')
            }else if(kioskUsers.toString() == 'PrA'){
                this.setState({kioskUserName:'Practitioner Assistant'})
                $('.card').css('backgroundColor','#B2FF66')
            }else if(kioskUsers.toString() == 'PhA' ){
                this.setState({kioskUserName:'Physician Assistant'})
                $('.card').css('backgroundColor','#B2FF66')
            }else if(kioskUsers.toString() == 'PmD'){
                this.setState({kioskUserName:'Physician'})
                 $('.card').css('backgroundColor','#DCDCDC')
            }else if(kioskUsers.toString() == 'Pat'){
                this.setState({kioskUserName:'Patient'})
                 $('.card').css('backgroundColor','#ccf5ff')
            }else if(kioskUsers.toString() == 'CC'){
                this.setState({kioskUserName: 'Care Coordinator'})
                $('.card').css('backgroundColor','#FFFF99')
            }
            
            if(kioskUsers.toString() != 'CPO'){
                $('.btn-tag').hide();
            }
            $('#myModal').modal('hide');
        }
        setTimeout(function(){
            //console.log(data)
            data.map((comment,i)=>{
                if(i > 0){

                    if($('.main_'+comment[0]).find('.question').length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
                        $('.main_'+comment[0]).addClass("not-include");  
                    }else{
                        $('.main_'+comment[0]).removeClass("not-include");   
                    }
                }
            });
        }, 2000);
    }
	
    goKisko=()=>{
        this.setState({setKiosk:!this.state.setKiosk},()=>this.setKioskMode(this.state.setKiosk));
    }
	
    showKioskModal=()=>{
        let checkedKiosk = this.state.checkedKiosk;
        this.refs.password.value = "";
        this.setState({checkedKioskData:checkedKiosk})
        this.setState({errors:false,error:''});
        $('#loginModal').modal('show')
    }
    
	dataFilter =(event)=>{
        let optionVal = event.target.value;
        this.setState({selOptions:optionVal});
        let allDatas = this.state.allData;
        let deleteData = this.deleteData(allDatas);
        let allData = [];
		let inc = '';
		this.setState({pfbByCompany:''});
		$("input[name='showIncludeN']").prop( "checked",false );
		
        if(optionVal == 'view_all'){
            allData = this.state.allData;
            allData.map((rowDatas, i) => {
				$('#'+rowDatas[0]).removeClass("not-include");				
            })
		}else if(optionVal == 'include_only'){
            this.setState({addDataDiv:false});
            { allDatas.map((rowDatas, i) => {
                let checkedbox= false;
                $('#'+rowDatas[0]).addClass("not-include");
				if(rowDatas[5] == 'Parent Header'){
                    $('#'+rowDatas[0]).find('.inc:checked').each(function() { 
                        $('#'+rowDatas[0]).removeClass("not-include");
                    });
                }
                if(this.state.checkedInc.get(rowDatas[0]) == true && rowDatas[5] !== 'Parent Header'){
                    $('#'+rowDatas[0]).removeClass("not-include");
                }
            })}
            
        }else if(optionVal == 'include_required'){
            this.setState({addDataDiv:false});
            { deleteData.map((rowDatas, i) => {
                let checkedbox= false;
                $('#'+rowDatas[0]). addClass("not-include");
                if(this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == true){
                    $('#'+rowDatas[0]).removeClass("not-include");
                }
                if(rowDatas[5] == 'Parent Header' || rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                    $('#'+rowDatas[0]).find('.req:checked').each(function() {
                        $('#'+rowDatas[0]).removeClass("not-include");
                    });
                }
            })}
        }else if(optionVal == 'include_not_required'){
			this.setState({addDataDiv:false});
            { deleteData.map((rowDatas, i) => { 
                let checkedbox= false;
                $('#'+rowDatas[0]).addClass("not-include");
                if(this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == false){
                    $('#'+rowDatas[0]).removeClass("not-include");
                }
                if(rowDatas[5] == 'Parent Header' || rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                    $('#'+rowDatas[0]).find('.req:checked').each(function() {
                        $('#'+rowDatas[0]).removeClass("not-include");
                    });
                }
            })} 
        }
		
		if($("input[name='includePFB']").prop("checked") == true){
			this.dataFilterPfb(event, optionVal);
		}else{
			allData = this.state.allData;
			{ allData.map((rowDatas, i) => {
				if((rowDatas[14] == 'Y' || optionVal == 'view_all_pfb') && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
					let hasVisible=false;
					$('#'+rowDatas[0]).find('.question').each(function() {
						if(!$(this).hasClass("not-include")){
							hasVisible= true;
						}
					});
					if(hasVisible == false){
						$('#'+rowDatas[0]).addClass("not-include");
					}else{
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				}
			})}	
		}
    }
	
	showNotInclude = () => {
		let allData = this.state.allData;
		this.setState({selOptions:''});
		allData.map((rowDatas, i) => {
			if(rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
				$('#'+rowDatas[0]).addClass("not-include");
			}
		})
				
		if($("input[name='showIncludeN']").prop("checked") == true){
			allData.map((rowDatas, i) => {
				if(rowDatas[14] == 'N'){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
			})
		}else{
			allData.map((rowDatas, i) => {
				if(rowDatas[14] == 'N'){
					$('#'+rowDatas[0]).addClass("not-include");
				}else{
					$('#'+rowDatas[0]).removeClass("not-include");
				}
            })
		}
		
		{ allData.map((rowDatas, i) => {
			if(rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3'){
				let hasVisible=false;
				$('#'+rowDatas[0]).find('.question').each(function() {
					if(!$(this).hasClass("not-include")){
						hasVisible= true;
					}
				});
				if(hasVisible == false){
					$('#'+rowDatas[0]).addClass("not-include");
				}else{
					$('#'+rowDatas[0]).removeClass("not-include");
				}
			}
		})}
	}
	
	showAllQues=()=>{
		if($("input[name='showQ']").prop("checked") == true){
            $('.pre-question').collapse('show');
			$('.pre-question').removeClass('not-include');
		}else{
            $('.pre-question').addClass('not-include');
			$('.pre-question').collapse('hide');
		}
    }
	
	getDirectoryFiles=(id)=>{
        let config1 = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url =config.API_URL+'uploadfile/get_files.php';
        axios.get(url, config1)
        .then(response => {
            var fileArray =response.data;
            this.setState({fileArray,fileId:id});
        })
        
    }
    
	uploadFilePath=()=>{
        var path  =this.state.fileData;
        var fileId = this.state.fileId;
        //console.log(this.state)
        uploadFilePath(path, fileId, this.state);
        $('#directoryFiles1').modal('hide');
        this.setState({filePath:path});
    }
	
    getFilePath=(file)=>{
        //console.log(file)
        this.setState({fileData:file})
        $( ".view-file" ).removeClass( "selected" );
        $( "#file"+file.id ).addClass( "selected" );
        $( "#fileId" ).val( file );
    }
    
    handleFeeds = (e) => {
        const item = e.target.name;
        this.setState({name:''});
        const isChecked = e.target.checked;
        let checkedFeeds = this.state.checkedFeeds;
        this.setState(prevState => ({
            checkedFeeds: prevState.checkedFeeds.set(item, isChecked)
        }));
        if(isChecked == true){
            $('#feeds'+item).modal('show');
        }else{
            $('.btn-'+item).hide();
        }
    }
    
    saveFeedsNote=(id, data)=>{
        let feedsResponse = this.state.feedsResponse;
        if(data){
            feedsResponse.set(id, data)
        }
        
        this.setState({feedsResponse:feedsResponse});
        
        $('#feeds'+id).modal('hide');
    }
    
    removeFeedsNote=(id)=>{
        let feedsResponse = this.state.feedsResponse;
        if(id){
            feedsResponse.delete(id)
        }
        this.setState({feedsResponse:feedsResponse});
        $('input[name='+id+']').prop( "checked", false );
        $('#feeds'+id).modal('hide');
    }

	keyPress(e){
        if(e.keyCode == 13){
			e.preventDefault();
        }
    }
    
	selectFormMod = (event) =>{
		let val = event.target.value;
		if(val == 'test_forms'){
			this.props.history.push('/testform');
		}else if(val == 'completed_forms'){
			this.props.history.push('/completedform');
		}else if(val == 'custom_config'){
			this.props.history.push('/configure');
		}
	}
	
	setAllChecked=()=>{
		let checkedInc = this.state.checkedInc;
		$('.inc').prop('checked', true);
		this.state.allData.map((valu, i) => {
			checkedInc.set(valu[0], true);
		})
	}
	
	setAllUnChecked=()=>{
		let checkedInc = this.state.checkedInc;
		$('.inc').prop('checked', false);
		this.state.allData.map((valu, i) => {
			checkedInc.delete(valu[0]);
		})
	}
	
	handleInclude = (e) => {
        let item = e.target.name;
        this.state.allData.map((row)=>{
            if(item == row[7]){
				$('#include'+item).modal('show');
            }
        })
        let isChecked = e.target.checked;
        let checkedInc = this.state.checkedInc;
        let checkedReq = this.state.checkedReq;
        let elm = document.getElementById(item);
		
		let type = 'inc';
		if(isChecked == true){
			let items = [];
			items = this.checkPreCondition(item, items, type);
			
			items.map((item)=>{
				$("#"+item).find(".inc").attr( 'checked', true )
				checkedInc.set(item, isChecked)

			})
			
        }else{
           let items = [];
			items = this.checkPreCondition(item, items, type);
			items.map((item)=>{
				$("#"+item).find(".inc").attr( 'checked', false )
				checkedInc.set(item, isChecked)

			})
        }
		
        let checkboxs = $('#'+item).find("input[type='checkbox']");
        if(isChecked == false){
            $.each( checkboxs, function( i,value ) {
                checkedInc.set($(this).attr('name'), isChecked)
                checkedReq.set($(this).attr('name'), isChecked)
            });
            checkedInc.set(item, isChecked)
            checkedReq.set(item, isChecked)
        }else{
            $.each( checkboxs, function( i,value ) {
                if(checkboxs.length == 3){
                    if(i == 1){
                        checkedInc.set($(this).attr('name'), isChecked)
                    }
                }
            });
            checkedInc.set(item, isChecked)
        }
        let x = 0;
        do {
            if(elm){
                let parentNode = elm.parentNode;
                
                if(parentNode.getAttribute('data-header') == 1 || parentNode.getAttribute('data-header') == 2 || parentNode.getAttribute('data-header') == 3){
                    let name = parentNode.getAttribute('id');
                    var checkedbox = false;
                    $('.inner-'+name).find('.inc:checked').each(function() {
						if($(this).is(":visible")){
							checkedbox= true;
						}
                    });
					
                    if(checkedbox == false){
                        $('input[type=checkbox][name='+name+']').prop('checked', false);
                    }
                    
                    if(checkedbox == false || isChecked == true){
                        checkedInc.set(name, isChecked)
                    }
                }
                if(parentNode.className.indexOf('parent-header') != -1){
                    x = 5; 
                }
                elm = parentNode;  
            }
            x++;
        }
        while(x < 5);
		
		this.setState({checkedInc: checkedInc});       
        this.setState({checkedReq: checkedReq});
    }
	
	handleRequire = (e) => {
        let item = e.target.name;
        let isChecked = e.target.checked;
        let checkedInc = this.state.checkedInc;
        let checkedReq = this.state.checkedReq;
        let elm = document.getElementById(item);
        if(isChecked == true){
            checkedInc.set(item, isChecked)
            checkedReq.set(item, isChecked)
        }else{
            checkedReq.set(item, isChecked)
        }
        
        let x = 0;
        do {
            if(elm){
                let parentNode = elm.parentNode;
                
                if(parentNode.getAttribute('data-header') == 1 || parentNode.getAttribute('data-header') == 2 || parentNode.getAttribute('data-header') == 3){
                    let name = parentNode.getAttribute('id');
                    var checkedbox = false;
                    
                    $('.inner-'+name).find('.inc:checked').each(function() {
                        checkedbox= true;
                    });
                    if(checkedbox == false){
                        $('input[type=checkbox][name='+name+']').prop('checked', false);
                    }
                    
                    if(checkedbox == false || isChecked == true){
                        checkedInc.set(name, isChecked)
                    }
                }
                if(parentNode.className.indexOf('parent-header') != -1){
                    x = 5; 
                }
                elm = parentNode;  
            }
            x++;
        }
        while(x < 5);
        this.setState({checkedInc: checkedInc});       
        this.setState({checkedReq: checkedReq});      
    }
	
	handleKiosk = (e) => {
        let item = e.target.name;
        let isChecked = e.target.checked;
		let checkedKiosk = this.state.checkedKiosk;
		
		checkedKiosk.set(item, isChecked)
		let type = 'kiosk';
		if(isChecked == true){
            $('#userBtnId'+item).css("display", "block"); 
			let items = [];
			items = this.checkPreCondition(item, items, type);
			items.map((item)=>{
				$("#"+item).find(".kiosk").attr( 'checked', true )
				checkedKiosk.set(item, isChecked)
			})
        }else{
           
           let items = [];
			items = this.checkPreCondition(item, items, type);
			items.map((item)=>{
				$("#"+item).find(".kiosk").attr( 'checked', false )
				checkedKiosk.set(item, isChecked)

			})
        }
        let elm = document.getElementById(item);
		let x = 0;
        do {
            if(elm){
                let parentNode = elm.parentNode;
                
                if(parentNode.getAttribute('data-header') == 1 || parentNode.getAttribute('data-header') == 2 || parentNode.getAttribute('data-header') == 3){
                    let name = parentNode.getAttribute('id');
                    var checkedbox = false;
                    $('.inner-'+name).find('.kiosk:checked').each(function() {
                        if($(this).is(":visible")){
                            checkedbox= true;
                        }
                    });
                    
                    if(checkedbox == false){
                        $('input[type=checkbox][name='+name+']').prop('checked', false);
                    }
                    
                    if(checkedbox == false || isChecked == true){
                        checkedKiosk.set(name, isChecked)
						$('#userBtnId'+name).css("display", "inline-block");
                    }
                }
                if(parentNode.className.indexOf('parent-header') != -1){
                    x = 5; 
                }
                elm = parentNode;  
            }
            x++;
        }
        while(x < 5);
        this.setState({checkedKiosk: checkedKiosk});
    }
	
	showAddUsersModal=(id)=>{
        let allDatas = this.state.allData;
        var res = ''
        let posData
        allDatas.map((rowDatas, i) => {
            if(rowDatas[0] == id){
                if(typeof this.state.kioskUsersData.get(rowDatas[0]) !== 'undefined'){
                    posData=this.state.kioskUsersData.get(rowDatas[0]);
                }else if(this.state.selDropOpt == 'original'){
                    posData = rowDatas[16];
                }else{
                    posData = rowDatas[34] ? rowDatas[34] : rowDatas[16];
                } 
                if(typeof posData !== 'undefined' && posData != ''){
                    res = posData.split(',');
                } 
            }
        })

        if(res !== ''){
            var array = res.slice(-9);
            this.setState({kioskUsers:array})
        }else{
            this.setState({kioskUsers:['CPO']})
        }
        $('#kiosk'+id).modal('show')
    }
	
	saveKioskUsersType=(id)=>{
		
        let kioskUsersData1 =this.state.kioskUsersData;
		
        /* var scorer = {CaC:0,Pat:1,Int:2,Res:3,PrA:4,PhT:5,CPO:6,PhA:7,PmD:8,}
        var final_arr1  = ['CaC','Pat','Int','Res','PrA','PhT','CPO','PhA','PmD'];
        var final_arr = this.state.kioskUsers.sort(function(a,b) {
            return scorer[a]-scorer[b];
        }); */
		
		let allDatas = this.state.allData;
		let uType = [];
		let kioskType = allDatas[0][119].split(',');
		
		if(kioskType){
			kioskType.map(function(type,i) {
				type = type.split(':');
				uType.push(type[1]);
			})
		}else{
			uType = ['CPO']
		}
		
		var final_arr = this.state.kioskUsers;
		var final_arr1 = uType;
		
		
        var array=[]; 
        final_arr1.map((item,i)=>{
            if(final_arr.includes(item) == true){
                array.push(item)
            }else if(item == 'CPO'){
                array.push('CPO')
            }else{
                array.push('')
            }
        })
        kioskUsersData1.set(id,array.toString());
        let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = "";
		spreadsheetID = config.spreadsheetId;
        
		if(this.state.formData){
			allDatas = this.state.formData;
		}
		
        let includeArr = this.state.checkedInc;
        let requiredArr = this.state.checkedReq;
        let kioskArr = this.state.checkedKiosk;
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        let updateData=[];
        let guids = '';
        let rowId ='';
        let req ='N';
        let inc = 'N';
        let kiosk ='N';
        if(requiredArr.get(id) == true){
            req = 'Y';
            inc = 'Y';
        }
        else if(includeArr.get(id) == true){
            inc = 'Y';
        }
        if(kioskArr.get(id) == true){
            kiosk = 'Y';
        }
		//console.log('kioskUsers->',this.state.kioskUsers);
        let userTypes = this.state.kioskUsersData.get(id)
        if(userTypes == undefined){
            userTypes = ',,,,,,,CPO,,';
        }
			
        let type = 'kiosk';
        let item  = id;
        let items = [];
        items = this.checkPreCondition(item, items, type);
         allDatas.map((rowDatas, i) => {
			
			if(rowDatas[35]){
				guids = rowDatas[35];
			}else{
				guids = rowDatas[91];
			}
			//let validationType = rowDatas[48];
			
            if(rowDatas[0] == id){
                rowId = (i+2);
                let row1 = {
                    "range": this.state.mainSheet+'!AI'+rowId,
                    "majorDimension": "ROWS",
                    "values": 
                    [
                        [ rowDatas[0] + ','+guids+','+rowDatas[2] +','+ rowDatas[7] +','+ rowDatas[8] +','+ inc +','+ req +','+ rowDatas[10] +','+ kiosk+','+userTypes]
                    ]
                };
                updateData.push(row1)
            }

            items.map((item)=>{
                kioskUsersData1.set(item,array.toString());
                if(rowDatas[0] == item){
                    rowId = (i+2);
                    let row2 = {
                        "range": this.state.mainSheet+"!AI"+rowId,
                        "majorDimension": "ROWS",
                        "values": 
                        [
                            [ rowDatas[0] + ','+guids+','+rowDatas[2] +','+ rowDatas[7] +','+ rowDatas[8] +','+ inc +','+ req +','+ rowDatas[10] +','+ kiosk+','+userTypes]
                        ]
                    };
                    updateData.push(row2)
                }

            })
        })

        this.setState({kioskUsersData:kioskUsersData1})
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
            alert('Data saved Successfully')
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
        this.setState({kioskUsers:['CPO']})
        this.updatedData();
    }
	
	updatedData=()=>{
		
        let spreadsheetID = config.spreadsheetId; 
        let sheetName = this.state.mainSheet;
		gapi.client.load("sheets", "v4", () => {
        gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: spreadsheetID,
                range:sheetName, 
            })
            .then(
                response => {
                    const allData = response.result.values;
                    allData.shift();
					if(this.state.configData.length > 0){
						let configData = this.state.configData;
						allData.map((data,i)=>{
							if(configData[i]){
								if(data[0] == configData[i][0]){
									for (let j in allData) {
										if(j==10){
											data[j] = configData[i][7];
										}
										if(j==14){
											data[j] = configData[i][5];
										}
										if(j==15){
											data[j] = configData[i][6];
										}
										if(j==16){
											data[j] = configData[i].join();
										}
										if(j==37){
											data[j] = configData[i][8];
										}
									}
								}
							}else{
								data[10] = 0;
								data[14] = 'N'
							}
							
						})
					}else if(this.state.formData){
						let formData = this.state.formData;
						allData.map((data,i)=>{
							if(formData[i]){
								if(data[0] == formData[i][0]){
									for (let j in allData) {
										if(j==10){
											data[j] = formData[i][10];
										}
									}
								}
							}
							
						})
					}
					
                    this.setState({allData});
                    this._answer(allData);
                },
            );
        });
    }
	
	showPreview=()=>{
		let configDataRow =[];
        let kioskUsers = this.state.kioskUsers
        let data = this.state.allData;
        let sampleData = this.deleteData(data);
        let mainData =[];
		let sOptions = this.state.selOptions;
        sampleData.map((rowDatas,i)=>{
            let key = rowDatas[10]
            if(rowDatas[5] == 'Parent Header'){
                mainData.push(rowDatas);
            }

			let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
            if(typeof columnName !== 'undefined' && columnName !== ''){
                configDataRow = columnName.split(',');
                configDataRow.splice(0, 9);
            }
			
            if(this.state.checkedKiosk.get(rowDatas[0]) == true && rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                mainData.push(rowDatas);
            }else if(this.state.checkedKiosk.get(rowDatas[0]) == true && configDataRow.indexOf(kioskUsers[0]) != -1){
				
				if(sOptions == 'include_only'){
					if(this.state.checkedInc.get(rowDatas[0]) == true){
						mainData.push(rowDatas);
					}
				}else if(sOptions == 'include_required'){
					if(this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == true){
						mainData.push(rowDatas);
					}
				}else if(sOptions == 'include_not_required'){
					if(this.state.checkedInc.get(rowDatas[0]) == true && this.state.checkedReq.get(rowDatas[0]) == false){
						mainData.push(rowDatas);
					}
				}else{
					mainData.push(rowDatas);
				}
            }
					
        })
        let ids =[];
        var dbArray = this.checkKey3(mainData, '', ids, 2, 1);
		let db3 =[];
		dbArray.map((rowDatas,i)=>{
			if( rowDatas['sub2'].length != 0 ){
				db3.push(rowDatas);
			}
		})
        //console.log('db3->',db3);
		this.setState({kioskPreviewData:db3});
        if(kioskUsers.toString() == 'PhT'){
            $('.kioskPreview').css('backgroundColor','#FFCCE5')
        }else if(kioskUsers.toString() == 'CPO'){
            $('.kioskPreview').css('backgroundColor','#FFFFFF')
        }else if(kioskUsers.toString() == 'Int' || kioskUsers.toString() == 'Res' || kioskUsers.toString() == 'PrA' || kioskUsers.toString() == 'PA' ){
            $('.kioskPreview').css('backgroundColor','#B2FF66')
        }else if(kioskUsers.toString() == 'PhA' || kioskUsers.toString() == 'PmD'){
             $('.kioskPreview').css('backgroundColor','#DCDCDC')
        }else if(kioskUsers.toString() == 'Pat'){
             $('.kioskPreview').css('backgroundColor','#ccf5ff')
        }else if(kioskUsers.toString() == 'CaC'){
            $('.kioskPreview').css('backgroundColor','#FFFF99')
        }
        $('#selectUsersType').modal('show')   	
    }
	
	showSign=(type,id)=>{
        $('#'+type+id).css('display','block')
    }
	
	saveProgData=()=>{
        let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = config.spreadsheetId;
        
        let params1 =  {
            spreadsheetId: spreadsheetID,
            range:this.state.selectedSheet,
        };
        let allDatas = this.state.allData;
		console.log('allDatas',allDatas);
        let includeArr = this.state.checkedInc;
        let requiredArr = this.state.checkedReq;
        let kioskArr = this.state.checkedKiosk;
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        let updateData = [];
        let allData = [];
        let rowId='';
        let guids = '';

        for (let i in allDatas) {
            
            let req ='N';
            let inc = 'N';
            let kiosk ='N';
            if(requiredArr.get(allDatas[i][0]) == true){
                req = 'Y';
                inc = 'Y';
            }
            else if(includeArr.get(allDatas[i][0]) == true){
                inc = 'Y';
            }
            if(kioskArr.get(allDatas[i][0]) == true){
                kiosk = 'Y';
            }
            let rowDatas = allDatas[i];
            let userTypes = this.state.kioskUsersData.get(rowDatas[0])
			
            if(userTypes == undefined){
				let oldP = rowDatas[16];
				let userArray = oldP.split(',');
				userArray.splice(0, 9);
				userTypes = userArray.toString();
            }
			
			if(rowDatas[35]){
                guids = rowDatas[35];
            }else{
				guids = rowDatas[91];
			}
            rowId = parseInt(i) + 2;
			
			var tagId = rowDatas[0]+'-'+guids;

            let row1 = {
                "range": this.state.mainSheet+'!AG'+rowId+":AI"+rowId,   
                "majorDimension": "ROWS",
                "values": 
                [
                    [ inc,req,rowDatas[0] + ','+guids+','+rowDatas[2] +','+ rowDatas[7] +','+ rowDatas[8] +','+ inc +','+ req +','+ rowDatas[10] +','+ kiosk+','+userTypes]
                ]
            };
            let row2 = {
                "range": this.state.mainSheet+'!AL'+rowId+":AN"+rowId,
                "majorDimension": "ROWS",
                "values": 
                [
                    [kiosk,'',tagId]
                ]
            };
			updateData.push(row1); 
            //updateData.push(row2);
			
			//if(rowDatas['updated']){
				
				let row3 = {
					"range": this.state.mainSheet+'!AD'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[rowDatas[2]]
					]
				};
				
				let row4 = {
					"range": this.state.mainSheet+'!AF'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[rowDatas[10]]
					]
				};
				
				updateData.push(row3);
				updateData.push(row4);
			//}
 
        }
		
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
		//console.log('updateData->',updateData);
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			that.setState({savedData:true});
            alert('Data Saved Successfully');
        }, function(reason) {
            alert(reason.result.error.message);
        });
    }
	
	exportSheetName=(event)=>{
        this.setState({setSheetName:event.target.value});
    }
	
	exportFacilityID=(event)=>{
        this.setState({setFacilityID:event.target.value});
    }
	
	exportsetGroupID=(event)=>{
        this.setState({setGroupID:event.target.value});
    }
	
	addCompany=(event)=>{
        this.setState({addCompany:event.target.value});
	}
	
	addNewCompany=()=>{
		let companyName = this.state.addCompany;
		let spreadsheetID = config.spreadsheetId;
		
		if(companyName){
			var params = {
				spreadsheetId:  spreadsheetID,
				range:'Company Name!A:A', 
				valueInputOption: "RAW", 
				insertDataOption: "INSERT_ROWS",
				resource:{
					"values": 
					[[companyName]],
					"majorDimension": "ROWS"
				  }
			};
			let that = this;
			var request = gapi.client.sheets.spreadsheets.values.append(params);
			request.then(function(response) {
				that.setState({newCompanyName:companyName});
				alert('Company added successfully!');
				$('#addComModal').modal('hide');
			}, function(reason) {
			console.error('error: ' + reason.result.error.message);
			});
		}
	}
	
	
	exportSheetData(sheetName,selDropOpt,selectedSheetId,facilityID,groupID){
        
		if(!this.state.savedData){
			alert('Please save changes first!');
			return false;
		}
		
        let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
        let guids=guid();
		
        var sheetName = "P7-"+this.state.selectedSheet1+"-"+sheetName;
		
		let mainData = this.state.allData;
        saveConfigFile(sheetName, guids, currentDate,mainData,facilityID,groupID); 
        handleSubmitResetInc(this.state.selectedSheet,mainData);
		
    }
	
	/* exportSheetData(sheetName,selDropOpt,selectedSheetId,facilityID,groupID){
        
		if(!this.state.savedData){
			alert('Please save changes first!');
			return false;
		}
		
        let spreadsheetID = config.spreadsheetId;
        var currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
        var guids=guid();
        //let selectSheet = this.props.selectedSheet.split('-');
        var latestSheetName = "P7-"+this.state.selectedSheet1+"-"+sheetName;
        let params = {
            spreadsheetId: spreadsheetID,
            sheetId: selectedSheetId,
        };
        
        let copySheetToAnotherSpreadsheetRequestBody = {
            destinationSpreadsheetId: config.pahseII_spreadsheetId,
        };
        let that=this;
        let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
            request.then(
                response => {
                    let params1 =  {
                        spreadsheetId: config.pahseII_spreadsheetId,
                    };
                    let getRequest = gapi.client.sheets.spreadsheets.get(params1);
                    getRequest.then(function(response) {
                        let length = response.result.sheets.length;
                        let lastSheetId = response.result.sheets[length-1].properties.sheetId;
                        let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
                        let requests ={
                            "requests": [
                                {
                                  "updateSheetProperties": {
                                    "properties": {
                                      "sheetId": lastSheetId,
                                      "title": latestSheetName+" "+currentDate+" "+guids,
                                    },
                                    "fields": "title"
                                  }
                                }
                            ]
                        }
                        let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
                        renameRequest.then(function(response) {
                            let getRequest = gapi.client.sheets.spreadsheets.get(params1);
                            getRequest.then(function(response) {
                                let lastSheetName = response.result.sheets[length-1].properties.title;
                                let getsheetdata = gapi.client.sheets.spreadsheets.values.get({
									spreadsheetId: config.pahseII_spreadsheetId,
									range:lastSheetName, 
								})
                                getsheetdata.then(
									response => {
									const allData = response.result.values;
									allData.shift();       
                                        
                                    let accessToken = gapi.auth.getToken().access_token;
                                    let params = {
                                        access_token:accessToken,
                                        spreadsheetId: config.pahseII_spreadsheetId,
                                    };
									let allDatas = that.state.allData;
                                    let rowId ='';
                                    let update_rows = [];
                                    let clear_rows = [];
                                    allData.map((valu, i) => {
                                        let question = valu[23] ? valu[23] : valu[4];
                                        let answerType = valu[24]? valu[24] : valu[5];
                                        let options = valu[22]? valu[22] : valu[6]; 
                                        let notes = valu[18] ? valu[18]:valu[12];
                                        let tags = valu[19] ? valu[19]:valu[13];
                                        let includeOri = valu[32] ? valu[32]:valu[14];
                                        let requireOri = valu[33] ? valu[33]:valu[15];
                                        let position = valu[31] ? valu[31]:valu[10];
                                        let parentId = valu[29] ? valu[29]:valu[2];
                                        
										
										let parentGuid = '';
										allDatas.map((pre_field,i)=>{
											if(pre_field[1].toLowerCase() == parentId.toLowerCase()){
												parentGuid = pre_field[117];
											}
										})
										
                                        rowId = (i+2);
                                        let row1 = {
                                            range: lastSheetName+'!E'+rowId+':I'+rowId,   // Update single cell
                                            values: [
                                                [ question,answerType,options,valu[7],valu[8] ]
                                            ]
                                        };
                                        let row2 = {
                                            range: lastSheetName+'!M'+rowId+':Q'+rowId,   // Update single cell
                                            values: [
                                                [ notes,tags,includeOri,requireOri,valu[34] ]
                                            ]
                                        };
										
										let row3 = {
                                            range: lastSheetName+'!K'+rowId,   // Update single cell
                                            values: [
                                                [ position ]
                                            ]
                                        };
										
										let row4 = {
                                            range: lastSheetName+'!C'+rowId,   // Update single cell
                                            values: [
                                                [ parentId ]
                                            ]
                                        };
										
										let row5 = {
                                            range: lastSheetName+'!L'+rowId,   // Update single cell
                                            values: [
                                                [ parentGuid ]
                                            ]
                                        };
										
										let row6 = {
                                            range: lastSheetName+'!R'+rowId+':AJ'+rowId,   // Update single cell
                                            values: [
                                                [ '','','','','','','','','','','','','','','','','','','' ]
                                            ]
                                        };
										
										let row7 = {
                                            range: lastSheetName+'!DS'+rowId,   // Update single cell
                                            values: [
                                                [ facilityID ]
                                            ]
                                        };
                                        
                                        update_rows.push(row1);
                                        update_rows.push(row2);
                                        update_rows.push(row3);   
                                        update_rows.push(row4);   
                                        update_rows.push(row5);   
                                        update_rows.push(row6);   
                                        update_rows.push(row7);   
                                           
                                    })
                                    let batchUpdateValuesRequestBody = {
                                        valueInputOption: 'USER_ENTERED',
                                        data: update_rows
                                    }; 
                                    let request2 = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
                                    request2.then(function(response) {
                                        let mainData = that.state.allData;
                                        saveConfigFile(that.state.selectedSheet, that.state.selDropOpt, that.state.reviewVer, latestSheetName, guids, currentDate,mainData,facilityID,groupID); 
                                        handleSubmitResetInc(that.state.selectedSheet,that.state.allData);
                                    }, function(reason) {
                                        alert(reason.result.error.message);
                                    });
                                    
                                })
                            });
                        }, function(reason) {
                            alert(reason.result.error.message);
                        });
                    }, function(reason) {
                        alert(reason.result.error.message);
                    });

                }, function(reason) {
                alert(reason.result.error.message);
        }); 
    } */
	
	checkMove = (val) => {
		$('.move-input').each(function () {
            
            if(val != $(this).val()){
                $(this).prop("checked", false);
            }
        });
        if(!this.state.formData){
			this.setState({formData:this.state.allData});
		}
        
        $('.move-bottons').css('display', 'block');
    }
	
	checkKey(elements, parentId = '',ids ,pId, cId) {
        var branch = [];
        { elements.map((element,i) => {
            if(element[pId] == 'TT Eval'){
                element[pId] = '';
            }
            if (element[pId].toLowerCase() == parentId) {
                var children = this.checkKey(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children) {
                    element['sub'] = children; 
                }
                if(!ids.includes(element['0'])){
                    branch.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch;    
    }
	
	updateArray = (formData) => {
        let allData2 =[];
        {formData.map((data,i)=>{
            var key = data[10];
            allData2[key] = data;
        })}
        var ids =[];
        this.checkKey(allData2, '', ids, 2, 1); 
    }
	
	findChild(data, obj, part, movedwith=false){
		
		let ob = {id:data[0], position:$('#'+data[0]).attr('data-pos'), pId:data[2], header:data[5]};
        if(movedwith){
            obj.movedWithData.push(ob);
        }
        else{
            obj.movedData.push(ob);
        }
        
        if(part == 2){
            return;
        }
        
        if(part == 3 && !movedwith){
            return;
        }
        
        if(data.sub){
            for(var i in data.sub){
                this.findChild(data.sub[i], obj, part, movedwith);
            }
        }   
    }
	
    setPosition = (data, start_position, obj, preId, pId, part) => {
        let pos = 0;
        let positionId = '';
        let changePos = this.state.allPos;
        { data.map((md, i) => {
            pos = start_position + i;
            positionId = md['pId'];
            let ob2 = {id:md['id'], position:pos, pId:md['pId'], header:md['header']};
            obj.push(ob2);
            $('#'+md['id']).attr("data-pos",pos);
            if(part == 3){
                let customArray = ["Child Header 1","Child Header 2","Child Header 3","Child Header 4"];
                
                if (pId && $.inArray(md['header'], customArray)!='-1') {
                    positionId = pId;
                    pId = '';
                }
            }
            if(pId){
                positionId = pId;
            }
            if(md['id'] == preId){
                positionId = md['pId'];
            }
            changePos[md['id']] = {pos:start_position + i, pId:positionId};
            
        })}
        
        this.setState({allPos:changePos});
        return pos;
    }
	
	movePostion = (type) => {
        
        let pId, allPos, preId, elementId, tempPos, part = 1, preDiv2, innerLength = 1, header, header2, preItem;
        
        $('.move-input').each(function () {
            
            if($(this).prop("checked") == true){
                
                elementId = $(this).val();
                
                let parentId = $('#'+elementId).parent().attr('id');
            
                let itemlist = $('#'+parentId);
        
                let len = $(itemlist).children().length -1;
				
                let selected = $("#"+elementId).index();
				
				header = $("#"+elementId).attr('data-header');
				
				/* if(type == 'down'){
					preItem = $(itemlist).children().eq(selected+1).attr('id');
					if($("#"+preItem).hasClass("begin-div")){
						$("#"+elementId).addClass("in-box");
					}else if($("#"+preItem).hasClass("end-div")){
						$("#"+elementId).removeClass("in-box");
					}
				}else{
					preId = $(itemlist).children().eq(selected-1).attr('id');
					if($("#"+preId).hasClass("begin-div")){
						$("#"+elementId).removeClass("in-box");
					}else if($("#"+preId).hasClass("end-div")){
						$("#"+elementId).addClass("in-box");
					}
				} */
				
                if(type == 'down')
                {
                    preItem = $(itemlist).children().eq(selected+1).attr('id');
					header2 = $("#"+preItem).attr('data-header');
					
					if((selected < len && header && header <= header2) || (selected < len && !header && !header2) || (selected < len && header == 1) )
                    {
                        preId = $(itemlist).children().eq(selected+1).attr('id');
						
						$($(itemlist).children().eq(selected+1)).after($($(itemlist).children().eq(selected)));
                        selected=selected+1;
						
                    }else{
						
                        part = 2;
                        if(header){
                            part = 3;
                            let inner = $("#"+elementId+" .childDiv").length;
							innerLength = Number(innerLength) + inner   
                        }
                        let id1 = Number($(itemlist).children().eq(selected).attr('data-pos')) + Number(innerLength);
                        let id2 = Number(id1) + 1;
                        preDiv2 = $("[data-pos="+id2+"]").attr('id');
						preId = $("[data-pos="+id1+"]").attr('id');
						
						if(preId){
							
                            if(!$('#'+preId).hasClass( "not-include" ) && $('.inner-'+preId).is(":hidden")){
								$(".headers-"+preId).trigger( "click" );
							}
								
                            if(preDiv2){
                                pId = $('#'+preDiv2).parent().attr('id');
								if(!$('#'+pId).hasClass( "not-include" )){
									$('#'+pId).collapse('show');
								}
                                $("#"+preDiv2).before($("#"+elementId));
                            }else{
								$('#'+preId+" .childMain").append($("#"+elementId));
							}
                        }
                    }
                    
                }else{
					
                    //preItem = $(itemlist).children().eq(selected-1).attr('id');
					//header2 = $("#"+preItem).attr('data-header');
					if(selected > 0)
                    {
                        preId = $(itemlist).children().eq(selected-1).attr('id');
						$($(itemlist).children().eq(selected-1)).before($($(itemlist).children().eq(selected)));
                        selected=selected-1;
                        
                    }else{
						
                        part = 2
                        let innerLength = 1;
                        header = $("#"+elementId).attr('data-header');
						let id1 = Number($(itemlist).children().eq(selected).attr('data-pos')) - 1;
						let id2 = Number(id1) - 1;
						
						preDiv2 = $("[data-pos="+id2+"]").attr('id');
						
						if(header){
                            part = 3
                            let elm = document.getElementById($("[data-pos="+id2+"]").attr('id'));
                            let parent;
                            let find = false;
                            let x = 0;
                            do {
								if(elm && elm.parentNode){
									let parentNode = elm.parentNode;
									let cls = parentNode.className;
									if(cls != undefined && cls.indexOf('parent-header') != -1){
										preDiv2 = $("[data-pos="+id2+"]").attr('id');
										find = true;
									}
									else if(parentNode && parentNode.getAttribute("data-header") == header){
										find = true;
										parent = parentNode;
									}
									else{
										elm = parentNode;
									}
								}
                                
                                x++;
                                
                            } while (find != true && x < 10);
                            
							if(parent){
								preDiv2 = parent.getAttribute("id");
							}
                        }
						
						let p = $("#"+preDiv2).attr('data-pos');
						let predh = $("#"+preDiv2).attr('data-header');
						
						
						if(preDiv2 && p > 1){
                            
                            preId = $("[data-pos="+id1+"]").attr('id');
							let preIdh = $("#"+preId).attr('data-header');
							
							if(preId){
                                pId = $('#'+preDiv2).parent().attr('id');
                                let pIdDiv = $('#'+pId).parent().attr('id');
								if(pIdDiv && $('#'+pId).is(":hidden") && !$('#'+pIdDiv).hasClass( "not-include" )){
									$(".headers-"+pIdDiv).trigger( "click" );
								}
								
								if((predh && preIdh && !header) || (header > 1 && predh == 'parent') || (header > 2 && preIdh == 2 ) ){
									$("#"+preId).before($("#"+elementId));
								}else{
									$("#"+preDiv2).after($("#"+elementId));
								}
                            }
                        }  
                    }
                }
            }
        });
 
        let formData = this.state.formData;
       
		if(!preId){
            return false;
        }
		
        let obj = {movedData:[], movedWithData:[]}
        
        { formData.map((data, i) => { 
            let field = '';
            if(data[0] == preId){
                if(data.sub){
                    this.findChild(data, obj, part, false);
                }
            }
            
            if(data[0] == elementId){
                this.findChild(data, obj, part, true);
            } 
        })}
        
        
        let movedData = obj.movedData;
        let movedWithData = obj.movedWithData;

        let pos = '';
        let obj2 = {pos1:[], pos2:[]}
        
        if(type == 'up'){
            let start_pos = Number(movedData[0].position);
            let last_pos = this.setPosition(movedWithData, start_pos, obj2.pos1, preId, pId, part);
            this.setPosition(movedData, last_pos+1, obj2.pos2, preId, pId, part);
        }
        else{
            let start_pos = Number(obj.movedWithData[0].position)
            let last_pos = this.setPosition(movedData, start_pos, obj2.pos1, preId, pId, part);
            this.setPosition(movedWithData, last_pos+1, obj2.pos2, preId, pId, part);
        }
        
        let positions = this.state.allPos;
        let posArray = Object.keys(positions).map(function(key) {
          return [key, positions[key]];
        });
        
        let newArr = []; 
        formData.forEach(function(e,i){
            let el = Object.assign([], e);
            let p =  posArray.filter(function(pos) {
                return pos[0] == el[0];
            });
            if(p.length){
                el[10] = p[0][1].pos;
                el[2] = p[0][1].pId;
                el['updated'] = true;
            }
            newArr.push(el);    
        });
    
        this.updateArray(newArr);
        this.setState({formData:newArr, allData:newArr});
		
		if($('#'+preId).is(":hidden")){
			this.movePostion(type);
		}else if(type == 'up' && !header && preDiv2 != undefined && $('#'+preDiv2).is(":hidden") && $('#'+pId).is(":hidden")){
			this.movePostion(type);
		}
	
    }
	
	handleClearTempSave=(selectedSheet)=>{
		let mainSheet = this.state.mainSheet;
		let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = config.spreadsheetId;
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
		let rowId ='';
		let updateData=[];
		let allDatas = this.state.allData;
		allDatas.map((rowDatas,i)=>{
				
			rowId = (i+2);
			if(rowDatas[5] != 'image' || rowDatas[5] != 'link' || rowDatas[5] != 'inlineVideo'){
				let row1 = {
					range: mainSheet+'!CV'+rowId,
					values: [
						[ '' ]
					]
				};
				updateData.push(row1)
			}
			
			let row2 = {
				range: mainSheet+'!DD'+rowId+':DF'+rowId,
				values: [
					[ '','','' ]
				]
			};
			
			updateData.push(row2)
        })
		
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			that.emptyState();
			that.setState({sheetData:[]});
			
			allDatas.map((rowDatas,i)=>{
				rowDatas[99] = '';
				rowDatas[107] = '';
				rowDatas[108] = '';
				rowDatas[109] = '';
			});
			
			if(that.state.selDropOpt == 'exported'){
				that.selectReviewOpt(that.state.exportedSelectedSheet, that.state.selectedSheetId);
			}else{
				that.onSelect(that.state.selectedSheet, that.state.selectedSheetId);
			}
			alert('Data Clear Successfully');
        }, function(reason) {
            //alert(reason.result.error.message);
        });
	}
	
	
    render(){
		const {allData,sheetData,moduleName,checkedKiosk,setKiosk, assoType,assoVisitType,assoDeviceType,showKioskBtn, kioskMode, kioskUserName,kioskResponse,errorMessage,successMessage, feedsResponse, userClassResponse, pdfArray, fileArray, mdsGuid, kioskUsers, companies, newCompanyName} =this.state
		
		
		var optionsPdf = ''
		if(pdfArray){
            var optionsPdf = pdfArray.map(function(data,i) {
                return (
                    <option value={data.path} key={i}>{data.name}</option>
                );
            })
        }
		
		let kioskType = [];
		let uType = [];
		if(allData){
			kioskType = allData[0][119].split(',');
			
			kioskType.map(function(type,i) {
				type = type.split(':');
				uType.push(type[1]);
			})
		}
														
        //var uType  = ['CaC','Pat','Int','Res','PrA','PhT','PhA','PmD','CPO'];
		
        if (this.state.test_forms.length) {
            return (
            <div className="container mt-5 mb-5">
                <div className="login-user-top">{'Logged in as: '+localStorage.getItem('username')}</div>
				<div className="row">
                     <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                            { kioskMode ? 
                                <div className="row">
                                    <div className="col-md-12" style={{textAlign:'center'}}>
                                        <h4>You are in {kioskUserName} Delegate mode</h4>
                                    </div>
                                </div>:
                                <div className="row">
                                
                                    <div className="col-md-7">
                                        <h4>Clinical Tracks Form Design - Configure Layouts </h4>
                                    </div>
                            
                                    <div className="col-md-5">
											<select className="form-control select-new-mod" value={moduleName} name="forms" onChange={this.selectFormMod}>
												<option value="test_forms">Test Form</option>
												<option value="completed_forms">View Completed Forms</option>
												<option value="custom_config">Configure Layouts</option>
											</select>
											<a className="nav-link btn btn-outline-info sign-out-btn" onClick={this.logout}>Sign Out</a>
                                    </div>
                                </div>}
                            </div> 

                            <div className="card-body">
                            { kioskMode ? null :
                            <TopFilter 
                                handleHeaderFilter={this.handleHeaderFilter}
								handleClearTempSave={this.handleClearTempSave}								
                                clearAllFilter={this.clearAllFilter}
                                assoType = {assoType}
								selectedSheet = {this.state.selectedSheet}
								sheetData = {sheetData}
								allData = {allData}
								moduleName = {moduleName}
                                assoVisitType ={assoVisitType}
                                assoDeviceType ={assoDeviceType}
                                />}
								{ kioskMode ? null:
                                <div className="row">
									<div className="col-md-9">
										<div className="row">
											<div className="col-md-4">
												<div className="form-group">
													<label> Clinical Form </label>
													<Form.Control as="select" value={this.state.selectedSheet1} onChange={this.getSheetValue}>
														<option> Please Select</option>
														{this.state.sheetsName.map((sheet, i) => (
															<option data-id={sheet.id} key={i}>{sheet.name}</option>
														))}
													</Form.Control>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label> Test Form Version </label>
													 
														<Form.Control id="test-form-select" as="select" value={this.state.reviewVer} onChange={this.getValue}>
															<option value="original">Master Data Set</option>
															{this.state.reviewSheetsName.map((sheet, i) => {
																let user = this.state.current_user;
																let fId = user[3];
																if(fId != 'All'){
																	if(sheet.facilityId == fId){
																		return <option data-id={sheet.id} key={i}>{sheet.name}</option>
																	}
																}else{
																	return <option data-id={sheet.id} key={i}>{sheet.name}</option>
																}
															})}
														</Form.Control>
												</div>
											</div>
										</div>
										
										{!kioskMode && sheetData.length>0 ?
										<div className="row">
											<div className='move-bottons' style={{display:'none'}}>
												<p onClick = {() => this.movePostion('up')}><i className="fa fa-arrow-up"></i></p>
												<p onClick = {() => this.movePostion('down')}><i className="fa fa-arrow-down"></i></p>
											</div>
											<div className="col-md-8">
												<KioskDrop 
													selectKioskUsers={this.selectKioskUsers}
													kioskType={kioskType}
													kioskUsers = {this.state.kioskUsers}
												/>
											</div>
											<div className="col-md-2 btn-box">
												<button type="button" className="btn btn-primary" onClick={this.showPreview}>Preview </button>
											</div>
										</div>
										:null}
									</div>
									{!kioskMode && sheetData.length>0 ?
									<div className="col-md-3 filter-sidebar-2 filter-sidebar-scroll">
										<div className="row">
											<div className="col-md-12">
												<div className="radio">
												  <label><input type="radio" checked={this.state.selOptions == 'view_all'} value="view_all" name="selOptions" onChange={this.dataFilter}/>View All(Default)</label>
												</div>
												<div className="radio">
												  <label><input type="radio" checked={this.state.selOptions == 'include_only'} value="include_only" name="selOptions" onChange={this.dataFilter}/>View All Included</label>
												</div>
												<div className="radio">
												  <label><input type="radio" checked={this.state.selOptions == 'include_required'} value="include_required" name="selOptions" onChange={this.dataFilter}/>View Included and Required</label>
												</div>
												<div className="radio">
												  <label><input type="radio" checked={this.state.selOptions == 'include_not_required'} value="include_not_required" name="selOptions" onChange={this.dataFilter}/>View Included But Not Required</label>
												</div>
												<div className="form-filter">
													<label className="showAllSwitch">
													<input type="checkbox" name="showIncludeN" onClick={this.showNotInclude}/>
													<span className="slider round showAllSlide"></span>
													</label> Show Only Not Included							
												</div>
												<div className="form-filter">
													<label className="showAllSwitch">
													<input type="checkbox" name="showQ" onClick={this.showAllQues}/>
													<span className="slider round showAllSlide"></span>
													</label> Show all questions with conditional requirements.
												</div>
											</div>
										</div>
									</div>
									:null}
                                </div>
								}
								
								{sheetData.length>0 ? 
									<div className="row">
										<div className="col-md-12">
											<div className="row">
												<div className="col-md-6">
													<div className="row">
														<div className="col-md-2">
															{setKiosk ? null :'Include'}
														</div>
														<div className="col-md-3">
															{setKiosk ? null : 'Required'}
														</div>
														<div className="col-md-3">
															{setKiosk ? null : 'Delegate'}
														</div>
														<div className="col-md-4">
															{setKiosk ? null : 'Change Position'}
														</div>
													</div>
												</div>
												{setKiosk ? 
													<div className="col-md-3 offset-3">
														<button className="btn btn-primary" style={{float:'right'}} onClick={()=>this.showKioskModal()}>End Delegate Mode</button>
													</div>
												:
													<div className="col-md-6 btn-box-center">
														<button className="btn btn-primary mr-2 mb-2" onClick={() => { if(window.confirm('Do you want to include all?')) this.setAllChecked() }}>Include All </button>
														<button className="btn btn-danger mr-2 mb-2" onClick={() => { if(window.confirm('Do you want to exclude all?')) this.setAllUnChecked() }}>Exclude All </button>
														
														<button className="btn btn-primary mb-2" type="button"  data-target="#myModal" data-toggle="modal">Delegate Mode </button>
															
													</div>
												}
											</div>
										</div>
									</div>
								:null}
								{ sheetData.length>0 ?
									<hr />
								:null}
                                { sheetData.length>0 ?
                                    <MainData 
                                        username = {this.username}
                                        selectedSheet= {this.state.selectedSheet}
                                        selDropOpt = {this.state.selDropOpt}
                                        sheetData = {sheetData}
                                        kioskPreviewData = {this.state.kioskPreviewData}
                                        kioskResponse={kioskResponse}
                                        kioskUserName={kioskUserName}
                                        feedsResponse={this.state.feedsResponse}
                                        assoDeviceTypeName={this.state.assoDeviceTypeName}
                                        assoVisitTypeName={this.state.assoVisitTypeName}
                                        getDirectoryFiles={this.getDirectoryFiles}
										apiPdfs = {this.state.apiPdfs}
                                        moduleName={moduleName}
                                        showSign={this.showSign}
                                        handleFeeds={this.handleFeeds}
                                        saveFeedsNote = {this.saveFeedsNote}
                                        removeFeedsNote = {this.removeFeedsNote}
                                        checkedFeeds={this.state.checkedFeeds}
										selectedOpt = {this.state.selDropOpt}
										checkedInc={this.state.checkedInc}
                                        allData = {allData}
                                        checkedKiosk={this.state.checkedKiosk}
                                        checkedReq ={this.state.checkedReq}
										handleInclude={this.handleInclude}
										handleKiosk ={this.handleKiosk}
										handleRequire={this.handleRequire}
										showAddUsersModal = {this.showAddUsersModal}
										saveKioskUsersType = {this.saveKioskUsersType}
										selectKioskUsers={this.selectKioskUsers}
										setDefaultCheckboxes = {this.setDefaultCheckboxes}
										kioskUsers = {this.state.kioskUsers}
										checkMove = {this.checkMove}
                                    />
                                :null}
                                
                                {sheetData.length>0 ? 
                                <div className="row">
                                    {setKiosk ? null:
										<div className="col-md-12">
											<button type="button" className="btn btn-primary float-left export-btn" data-toggle="modal" data-target="#SaveSheetName" onClick={()=>this.updatedData()}>Export New Data </button>
											<button type="button" className="btn btn-primary float-right" onClick={this.saveProgData}>Save</button>
										</div>
									}
                                </div>
                                :null}
                            </div>
                        </div>
                    </div>
                </div>
				<div className="modal" id="SaveSheetName">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Enter Sheet Name</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
								<div className="col-md-12">
                                <label className="label-control"> Enter a sheet name </label>
                                <input type="text" name="setSheetName" value ={this.state.setSheetName} onChange={this.exportSheetName} maxLength={20} className="form-control"/>
								</div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-6">
											<div className="row">
											<div className="col-md-10">
											<label className="label-control"> Select a Facility ID </label>
											<select className="form-control facility-id" name="setFacilityID" onChange={this.exportFacilityID}>
												<option value="">Please Select</option>
												{companies.map((company, i) => (
													<option value={company}>{company}</option>
												))}
												{newCompanyName ? 
												<option value={newCompanyName}>{newCompanyName}</option>
												:''}
											</select>
											</div>
											<div className="col-md-2 add-company-export">
												<button type="button" data-target="#addComModal" data-toggle="modal" className="btn btn-primary mb-2">
													<i className="fas fa-plus"></i>
												</button>
											</div>
											</div>
										</div>
										<div className="col-md-6">
										<label className="label-control"> Enter a Group ID </label>
										<input type="text" name="setGroupID" value ={this.state.setGroupID} onChange={this.exportsetGroupID} maxLength={9} className="form-control facility-id"/>
										</div>
									</div>
								</div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary float-left" onClick={()=>this.exportSheetData(this.state.setSheetName,this.state.selDropOpt,this.state.selectedSheetId,this.state.setFacilityID,this.state.setGroupID)} data-dismiss="modal">Save</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
				
				<div className="modal add-company" id="addComModal">
                    <div className="modal-dialog modal-lg custom-modal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Add New Company</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
								<input type="text" name="addCompany" onChange={this.addCompany} className="form-control" placeholder="Enter company name"/>
                          </div>
                          <div className="modal-footer">
                                <div className="image-text" id="fileText"></div>
                                <div className="popup-btn-com">
                                    <button type="button" className="btn btn-primary float-left" onClick={this.addNewCompany}>Save</button>
                                    <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                                </div>
                          </div>

                        </div>
                    </div>
                </div>
				
				<div className="modal" id="selectUsersType">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content kioskPreview">
                            <div className="modal-header">
                                <h4 className="modal-title">Delegate Mode Preview</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                            { sheetData ? 
                               <KioskDataView 
                                    sheetData = {sheetData}
                                    showSign={this.showSign}
                                    checkedReq = {this.state.checkedReq}
                                    kioskPreviewData = {this.state.kioskPreviewData}
                                    moduleName={moduleName}
                                    allData = {allData} 
                                    kioskResponse={kioskResponse}
                                    />
                            : null }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" style={{textAlign:'left'}}>Ok </button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="myModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Do you want to go Delegate Mode ?</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <label>Select Users Class</label>
                            { sheetData ?
                            <KioskDrop
                                multiple ='false'
                                selectKioskUsers={this.selectKioskUsers}
                                kioskType={kioskType}
                                kioskUsers = {this.state.kioskUsers}/>
							:null}
                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-primary float-left" onClick = {()=>this.goKisko(this.state.allData,this.state.kioskUsers)} >Ok</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                          </div>

                        </div>
                    </div>
                </div>
                <div className="modal" id="loginModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Enter a password</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <form>
                                <div className="modal-body">      
                                    <input type="password" className="form-control" ref="password" placeholder="enter a password" onKeyDown={this.keyPress} />
                                    { this.state.errors ?
                                    <p className=" alert-danger"> { this.state.error } </p>:null }                                    
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary float-left" onClick={this.handleSignIn}>Ok</button>
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                <div className="modal" id="directoryFiles1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Upload File</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <form>
                                <div className="row">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">File</th>
                                                <th scope="col">Path</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                        {(fileArray || []).map(url => (
                                            <tr className="view-file" id={'file'+url.id}  onClick = {()=>this.getFilePath(url)}>
                                                <td scope="row">{url.id}</td>
                                                <td scope="row">{url.name}</td>
                                                <td scope="row">{url.path}</td>
                                                <td>{url.description}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <input type="hidden" id="fileId" value="" />
                                    </table>
                                </div>
                            </form >
                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-primary float-left" onClick={this.uploadFilePath}>Save</button>
                            <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                          </div>

                        </div>
                    </div>
                </div>
            </div>
        );
        }
        else{
            return (<div className="card-body">Loading...</div>)
        }
    }
}

export default ConfigureLayouts;