import React, { Component } from 'react';
import axios,{post} from 'axios';
import config from "../../config";
import $ from 'jquery'; 

export default class FileUpload extends Component {

    constructor(props) {
        super(props)
        this.state = {
            file: '',
            fileName:'',
            fileDesc:'',
            errorName:'',
            progress:'',
        }
        this.fileObj = '';
        this.fileArray = [];

        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }
    getFiles=()=>{
        
        let config1 = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = config.API_URL+'uploadfile/get_files.php';
        axios.get(url, config1)
        .then(response => {
            var fileArray =response.data;
            this.setState({fileArray});
        })
        //this.setState({fileArray});
    }
    handleChange=(e)=>{
        let name = e.target.name;
        this.setState({[e.target.name]:e.target.value})
        if(name == 'fileName' && e.target.value !==''){
            this.setState({errorName:''});
        }
    }
    uploadMultipleFiles(e) {
        var file  = e.target.files;
        this.setState({ file:file, selectedFile: e.target.files });
    }

    uploadFiles=(e)=>{
        e.preventDefault()
        if(this.state.fileName !== ''){
            var file = $('.fileupload').prop('files')[0];
			const config1 = {
                onUploadProgress: function(progressEvent) {
                //console.log('progressEvent',progressEvent)
                  var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                  console.log(percentCompleted)
                  //this.setState({progress:percentCompleted})
                }
              }
            let url = config.API_URL+'uploadfile/file_upload1.php';
            
            var form_data = new FormData();
            form_data.append("name", this.state.fileName);
            form_data.append("description", this.state.fileDesc);
            form_data.append("file", file);
            //var test = form_data.get('file')
            //console.log(test)
            post(url, form_data, config1)
            .then(response => {
                $('#addFilesModal').modal('hide');
                this.getFiles();
                this.setState({file:'',selectedFile:''});
            })
        }else{
            this.setState({errorName:'Please enter a name'});
        }
    }
    
    render() {
        const {errorName, fileArray, progress} =this.state;
        return (
            <div>
                <form>
                    <div className="row">
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">File</th>
                                    <th scope="col">Path</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(fileArray || []).map(url => (
                                <tr>
                                    <td scope="row">{url.id}</td>
                                    <td scope="row">{url.name}</td>
                                    <td scope="row">{url.path}</td>
                                    <td>{url.description}</td>
                                    <td><a className="btn btn-primary" href={url.path} target="_blank">Open</a> <a className="btn btn-primary" href={url.path} download={url.name}>Download</a></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </form >
                <div className="modal" id="addFilesModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Upload Files</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                                <div className="form-group">
                                    <input type="file" className="form-control fileupload" name="file" onChange={this.uploadMultipleFiles} />
                                </div>
                                <div className="progress">
                                    <div
                                      className="progress-bar progress-bar-info progress-bar-striped"
                                      role="progressbar"
                                      aria-valuenow={progress}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{ width: progress + "%" }}
                                    >
                                      {progress}%
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Name <span className="required">*</span></label>
                                    <input type="text" name ="fileName" className="form-control" onChange={this.handleChange} />
                                    {errorName ? <p className="alert alert-danger"> { this.state.errorName }</p> : null}
                                </div>
                                <div className="form-group">
                                    <label>Discription (limited to 150 characters)</label>
                                    <textarea className="form-control" name ="fileDesc" value={this.state.fileDesc} maxLength={150} onChange={this.handleChange}></textarea>
                                </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-block" onClick={this.uploadFiles}>Upload</button>
                          </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}