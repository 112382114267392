import $ from 'jquery';
import 'bootstrap';
import React, { Component } from "react";
import { gapi } from 'gapi-script';
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import config from "../../config";
import axios,{post,get} from 'axios';
import {Dropdown1} from './Dropdown';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';
import { removePrecondition, showParentField, editPrecondition} from "../../Helpers/Precondition";
import QAction from "../../component/QAction/QAction";
import Header from "../../component/Header/Header";
import {generatehtmlfromlocation, handleAuthResult, handleSubmitReset} from "../../Helpers/Common";

class EditFormData extends Component {
    
	constructor(props) {
        super(props);
        this.state ={
            queNo:'',
            mainQueKey:[],
            uploadedImages:[],
            editorState: EditorState.createEmpty(),
            queDescription:'',
            mod:'edit_form_data',
        }
		
    }
	
    subQuestions=(res)=>{
        this.setState({preCondVal:res})
    }
	
    setQuestionNo(event){
        this.setState({queNo: event.target.value});
        this._answer(event.target.value)
    }
	
    handleChange=(event)=>{ 
        $('html, body').animate({scrollTop:$(document).height()}, 'fast');
        let name = event.target.value;
        this.setState({queAction:event.target.value});
        this.setState({ChangeNotes:false,ChangeTags:false,changeAnsOpt:false,changeQueText:false,changeOutputText:false,changeValiType:false,changeQueType:false,ChangeCompany:false,ChangeTracking:false});
        this.setState({[name]:true});
    }
	
    handleSubmit(event) {
       var btnClicked = event.target.value;
       var functions = [this.state,this.goToNext,this.goToPrevious];
	   let sheetConfigId = this.props.sheetConfigId;
       if(this.state.accessToken){
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true},handleAuthResult(sheetConfigId,btnClicked,functions,this.props.selDropOpt,this.props.updatedData));
        }else{
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: false},handleAuthResult(sheetConfigId,btnClicked,functions,this.props.selDropOpt,this.props.updatedData));
        }
    }
	
	handleClearNote=()=>{
		this.setState({editorState:'',editorSourceHTML:''});
		$("#slider").trigger( "click" );
		//var queNo = this.state.queNo;
        //this._answer(queNo);
    }
	
    getModifiedData=(rowDatas,review)=>{
        let question ='';
        let answerType='';
        let option ='';
        var id = rowDatas[1]
        var location = rowDatas[9];
        var parent = rowDatas[2]
        var visibility1 = rowDatas[8];
        var visibility = rowDatas[7];
        var action  = rowDatas[17];
        var field_id = rowDatas[0];
        if(rowDatas[7] && rowDatas[8]){
            this.setState({showPreCon:true,preCondition:rowDatas[7]+'-'+rowDatas[8]});
        }else{
            this.setState({preCondition:'',showPreCon:false});
        }
        if(typeof rowDatas[18] !== 'undefined' && rowDatas[18] !== ''){
            this.setState({noteColor:'4px solid #00ccff',noteText:'Improved Note',addNote:rowDatas[18]});
        }else{
            this.setState({noteText:'',noteColor:'',addNote:''});
        }
        if(typeof rowDatas[19] !== 'undefined' && rowDatas[19] !== ''){
            this.setState({tagColor:'4px solid #00ccff',tagText:'Improved Tag',addTags:rowDatas[19]});
        }else{
            this.setState({tagColor:'',tagText:'',addTags:''});
        }
        if(action === 'changeQformat'){
            question = rowDatas[23] ? rowDatas[23] :rowDatas[4];
            answerType = rowDatas[24] ? rowDatas[24] :rowDatas[5];
            option = rowDatas[22] ? rowDatas[22] :rowDatas[6]; 
            this.setState({color:'4px solid green',status:'New Question Format/Reword',selectReview:'changeQformat'});
        }else if(action === 'Keep as is'){
            question = rowDatas[4];
            answerType = rowDatas[5];
            option = rowDatas[6];
            this.setState({color:'4px solid green'});
            this.setState({status:'Kept as is',selectReview:'Keep as is'});
        }else if(action === 'Reword'){
            question = rowDatas[20];
            answerType = rowDatas[5];
            option = rowDatas[21];
            this.setState({color:'4px solid green'});
            this.setState({status:'Original Question Format/Reword'});
        }else if(action === 'Complex'){
            question = rowDatas[4];
            answerType = rowDatas[5];
            option = rowDatas[6];
            this.setState({color:'4px solid yellow'});
            this.setState({status:'Complex',selectReview:'Complex'});
        }else if(action === 'Discard'){
            question = rowDatas[4];
            answerType = rowDatas[5];
            option = rowDatas[6];
            this.setState({color:'4px solid red'});
            this.setState({status:'Discard',selectReview:'Discard'});
        }else{
            question = rowDatas[4];
            answerType = rowDatas[5];
            option = rowDatas[6];
            this.setState({color:'4px solid black'});
            this.setState({status:'Original',selectReview:'original'});
        }
        let rowAllData = [id,location,parent,question,answerType,option,visibility1,visibility,field_id,review];
        return rowAllData;
    }
    
	addPrecondition=(e)=>{
        var quesID = e.target.value;
        this.setState({queNo1:quesID});
        if(quesID !== 'undefined' ){
            var rowDatas = this.props.allData[quesID];
            if(rowDatas !== 'undefined'){
                this.setState({preCondID:rowDatas[0]});
                var id         = rowDatas[1]
                var location   = rowDatas[9];
                var parent     = rowDatas[2]
                var questions  = rowDatas[4];
                var answerType = rowDatas[5];
                var options    = rowDatas[6];
                var visibility1 = rowDatas[8];
                var visibility = rowDatas[7];
                var action     = rowDatas[16];
                var field_id   = rowDatas[0];
                var addPreCond = 4;
                var rowAllData = [id,location,parent,questions,answerType,options,visibility1,visibility,field_id,addPreCond];
                this.setState({rowAllData});
                this.generateQuestion(rowAllData)         
            }   
        }
    }
	
	setAsGroup=(event)=>{
		
		let name = event.target.name;
		let res = event.target.value;
		let isChecked = event.target.checked;
		if(isChecked){
			this.setState({[event.target.name]:event.target.value});
		}else{
			this.setState({[event.target.name]:''});
		}
		
	}
	
    getValue=(event)=>{
		
		let name = event.target.name;
		let res = event.target.value;
        this.setState({[event.target.name]:event.target.value, modifyQuestions:res});
		
		let that = this;
		
		if(name == 'validationType' && (res == 'V3' || res == 'V4' || res == 'V5' || res == 'V6' || res == 'V9' || res == 'V11'  || res == 'V12' || res == 'V13')){
			let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).split(',')[0];
			that.setState({showMinMax:true});
			setTimeout(function(){
				if(res == 'V9'){
					$('.min-val').val('01/01/1920');
					$('.max-val').val(date);
					that.setState({minValue:'01/01/1920',maxValue:date});
				}else if(res == 'V11'){
					$('.max-val').val('01/01/2050');
					$('.min-val').val(date);
					that.setState({maxValue:'01/01/2050',minValue:date});
				}else if(res == 'V12' || res == 'V13'){
					$('.max-val').val('00:00:00');
					$('.min-val').val('00:00:00');
					that.setState({maxValue:'00:00:00',minValue:'00:00:00'});
				}else{
					$('.max-val').val('');
					$('.min-val').val('');
				}
			}, 2000);
			
		}else if(name == 'validationType' && (res == 'V1' || res == 'V2' || res == 'V7' || res == 'V8' || res == 'V10')){
			this.setState({showMinMax:false});
			that.setState({maxValue:'',minValue:''});
		}
    }
	
    onEditorStateChange=(editorState)=>{
        var convertedData = editorState.getCurrentContent()
        let d =  convertToRaw(editorState.getCurrentContent()) 
        let editorSourceHTML = draftToHtml(d)
        this.setState({
            editorState,editorSourceHTML
        });
    }
	
    _uploadImageCallBack=(file)=>{
        let uploadedImages = this.state.uploadedImages;

        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        uploadedImages.push(imageObject);
        this.setState({uploadedImages: uploadedImages})
        let con = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'multipart/form-data'
			}
		}
		
		let url = config.noteImageApiUrl;
		return new Promise(
		(resolve, reject) => {
			var form_data = new FormData();
			form_data.append('file', file);
			post(url, form_data, con)
			.then(response => {
				resolve({ data: { link: response.data } });
			})
		});
    }
	
    showPreview = () =>{
        var changeQueInpText = this.state.changeQueInpText
        var changeOutInpText = this.state.changeOutInpText
        var validationType = this.state.validationType
        var changeAnsInpText = this.state.changeAnsInpText
        var chooseAnsType = this.state.chooseAnsType
        var chooseCompany = this.state.chooseCompany
        var chooseTracking = this.state.chooseTracking
        var addNoteText = this.state.addNoteText
        
        var addTagsText = this.state.addTagsText
        var rowAllData = this.state.selectData;
        if(chooseAnsType){
            rowAllData[4] = chooseAnsType;
            rowAllData[5] = changeAnsInpText;          
        }else{
            this.setState({question:rowAllData[3]});
        }
        this.props.description.map((answer, i) => {  
            if(answer[1] == rowAllData[4]){
               this.setState({quePrevDescription:answer[2]}); 
            }else{
                this.setState({quePrevDescription:''});
            }
        })
        this.setState({QuestionId:rowAllData[8]})
        var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
        var imgUrl  = addNoteText;
        var changeAnsInpHtml = '';
        if(this.state.chooseAnsType !== 'Title' || this.state.chooseAnsType !== 'Text' ){
            changeAnsInpHtml = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
        }
        
        this.setState({changeQueInpText,changeOutInpText,validationType,changeAnsInpHtml,addPreNoteText:imgUrl,addPreTagsText:addTagsText});
    }
    
	preOriginal=()=>{
        var queNo = this.state.queNo;
        var preOriginal = 2;
        if(queNo !== 'undefined' ){
            var rowDatas = this.props.mainQues[queNo];
            if(rowDatas !== 'undefined'){
                var id = rowDatas[1]
                var location = rowDatas[9];
                var parent = rowDatas[2]
                var questions = rowDatas[4];
                var answerType = rowDatas[5];
                var options = rowDatas[6];
                var visibility1 = rowDatas[8];
                var visibility = rowDatas[7];
                var action  = rowDatas[17];
                var field_id = rowDatas[0];
                this.setState({precolor:'4px solid black',preStatus:'Original'});
                if(rowDatas[7] && rowDatas[8]){
                    this.setState({showPreCon:true,preCondition:rowDatas[7]+'-'+rowDatas[8]});
                }else{
                    this.setState({preCondition:'',showPreCon:false});
                }
                var addNoteText = rowDatas[12];
                var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
                var imgUrl  = addNoteText;
                this.setState({addNoteText:addNoteText,addTagsText:rowDatas[13]});
                var rowAllData = [id,location,parent,questions,answerType,options,visibility1,visibility,field_id,preOriginal];
                this.generateQuestion(rowAllData)         
            }   
        }
    }
    
	showReview=(params)=>{
        var review = 1;
        this.setState({nextValue:params,queAction:'',rewordInpText:'',rewordInpAns:'',changeAnsInpText:'',changeQueInpText:'',changeOutInpText:'',changeAnsInpHtml:'',chooseAnsType:'',chooseCompany:'',chooseTracking:'',addNote:'',addTags:'',showReword: false,changeQformat:false,validationType:''});
        if(params !== 'undefined' ){
            var rowDatas = this.state.mainQues12[params];
            if(typeof rowDatas !== 'undefined'){
                var rowAllData = this.getModifiedData(rowDatas,review);
                this.setState({rowAllData});
                this.generateQuestion(rowAllData)
            }
        }
        $('#reviewModal').modal('show')
    }
    
	selectReviewData=(event)=>{
        var j = 0;
        var selectedOption = event.target.value;
        if(selectedOption == 'original'){
            selectedOption = '';
        }
        let mainQues12= [];
        this.setState({selectReview:event.target.value})
        this.state.allData.map((answer, i) => {  
            if(answer[5] !== 'Parent Header' && answer[5] !== 'Child Header 1' &&  answer[5] !== 'Child Header 2' && answer[5] !== 'Child Header 3' &&  answer[17] == selectedOption){
                mainQues12.push(answer)
            }
        })
        this.setState({nextValue:j});
        this.setState({mainQues12},()=>this.showReview(j));
    }
    
	getNewCondition=()=>{
        this.props.updatedData();
        this.setState({showPreCon:false,preCondition:''});
    }
    
	getSheetData=()=>{
        let spreadsheetID = this.props.sheetConfigId; 
        /* if(this.props.selDropOpt == "original"){
            spreadsheetID = config.spreadsheetId;
        }else{
            spreadsheetID = config.pahseII_spreadsheetId;
        } */
        let sheetName = this.props.selectedSheet;
       
        gapi.client.load("sheets", "v4", () => {
        gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: spreadsheetID,
                range:sheetName, 
            })
            .then(
                response => {
                    const allData = response.result.values;
                    allData.shift();
                    this.setState({ allData });
                },
            );
        });
    }
    
	reviewMode=() => {
        this.getSheetData();
        var mainQues12 = [];
        var mainQueKey = [];
        var selectedOption = '';
        var j=0;
        this.state.allData.map((answer, i) => {
            if(this.state.queNo == i){
                selectedOption = answer[17]
                j =i;
            }
        })
        this.state.allData.map((answer, i) => {  
            if(selectedOption === ''){
                if(answer[5] !== 'Parent Header' && answer[5] !== 'Child Header 1' &&  answer[5] !== 'Child Header 2' && answer[5] !== 'Child Header 3' &&  answer[17] == ''){
                    mainQues12[i] = answer;
                    var key =  i+'-'+answer[0];
                    mainQueKey.push(key)
                }
            }else{
                if(answer[5] !== 'Parent Header' && answer[5] !== 'Child Header 1' &&  answer[5] !== 'Child Header 2' && answer[5] !== 'Child Header 3' &&  answer[17] == selectedOption){
                    mainQues12[i] = answer;
                    var key =  i+'-'+answer[0];
                    mainQueKey.push(key)
                }
            }
        })
        var that = this
        setTimeout(function(){ 
            that.setState({nextValue:j});
            that.setState({mainQues12},()=>that.showReview(j));                             

        }, 4000);
    }
    
	setQuestionNo = (event) =>{
        this.setState({queNo: event.target.value});
        this._answer(event.target.value); 
    }
	
    sliderUpdate=()=>{
        var allData = this.props.allData;
        var queNo = this.state.queNo;
        var slideVal = $("#slider").is(':checked');
        this.setState({isChecked:slideVal});
        this._answer(queNo);
    }
    
	componentWillReceiveProps(newprops){
        //if(this.props.mainQues.length && this.state.queNo == 2){
		let that = this;
		setTimeout(function(){
			if(that.props.mainQues.length){
				that. _answer(that.state.queNo,newprops.selectedOpt);
			}
		}, 2000);
    }
	
    preConFunc=(cond_id)=>{
        this.setState({showPreDiv:true,preCondVal:''});
        if(cond_id){
            editPrecondition(cond_id,this.props.allData,this.generateQuestion);
        }
    }
	
	_answer=(params,statusOpt = null)=>{
		let keys = Object.keys(this.props.mainQues);
		var n = keys.includes(params);
		if(params == '' || n == false){
			params = keys[0];
		}
		this.setState({ChangeNotes:false,ChangeTags:false,changeAnsOpt:false,changeQueText:false,changeOutputText:false,changeValiType:false,changeQueType:false,ChangeCompany:false,ChangeTracking:false,addNoteText1:'',addTagsText1:''});
        this.setState({showPreDiv:false,embedCode:false,changeQformat:false,showReword: false,queAction:'',changeAnsInpText:'',changeQueInpText:'',changeOutInpText:'',chooseAnsType:'',queDescription:'',chooseCompany:'',chooseTracking:'',validationType:'',minValue:'',maxValue:'', modifyQuestions:''});
        this.setState({queActionDataClear:false,selectedSheet:this.props.selectedSheet,allData:this.props.allData,preCondVal:'',showPreDiv:false,queNo:params});
        var slideVal =$("#slider").is(':checked');
        if(slideVal || statusOpt == 'improved'){
            if(params !== 'undefined' ){
                var rowDatas = this.props.mainQues[params];
                if(rowDatas !== 'undefined'){
					this.setState({rowAllData:rowDatas,saveQueId:rowDatas[0],showDiv:true});
                    var id = rowDatas[1]
                    var location = rowDatas[9];
                    var parent = rowDatas[2]
                    var questions = rowDatas[4];
                    var outputText = rowDatas[9];
                    var answerType = rowDatas[5];
                    var companyName = rowDatas[115];
                    var options = rowDatas[6];
                    var visibility1 = rowDatas[8];
                    var visibility = rowDatas[7];
                    var action  = rowDatas[17];
                    var field_id = rowDatas[0];
					var asGroup = '';
					if(qType == 'parent header'){
						asGroup = rowDatas[126];
					}else{
						asGroup = rowDatas[127];
					}
                    
					var valType = rowDatas[48];
					var valTypeText = this.props.validationType(valType);
					
                    if(rowDatas[7] && rowDatas[8]){
                        this.setState({showPreCon:true,preCondition:rowDatas[7]+'-'+rowDatas[8]});
                    }else{
                        this.setState({preCondition:'',showPreCon:false});
                    }
                    if(typeof rowDatas[18] !== 'undefined' && rowDatas[18] !== ''){
                        var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
                        let addNoteText = rowDatas[18]; 
                        var imgUrl  = addNoteText;
                        this.setState({noteColor:'4px solid #9900ff',noteText:'Improved Note',addNoteText1:imgUrl});
                        const html = `<p>`+imgUrl+`</p>`;
                        const contentBlock = htmlToDraft(html);
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            const editorState = EditorState.createWithContent(contentState);
                            this.setState({
                              editorState,
                            });
                        }
                    }else if(typeof rowDatas[12] !== 'undefined' && rowDatas[12] !== ''){
                        var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
                        let addNoteText = rowDatas[12]; 
                        var imgUrl  = addNoteText;
                        const html = `<p>`+imgUrl+`</p>`;
                        const contentBlock = htmlToDraft(html);
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            const editorState = EditorState.createWithContent(contentState);
                            this.setState({
                              editorState,
                            });
                        }
                        this.setState({noteColor:'4px solid #9900ff',noteText:'Original Note',addNoteText1:imgUrl});
                    }else{
                        this.setState({noteColor:'',noteText:'',addNoteText1:'',editorState:''});
                    }
                    if(typeof rowDatas[19] !== 'undefined' && rowDatas[19] !== ''){
                        this.setState({tagColor:'4px solid #00ccff',tagText:'Improved Tag',addTagsText1:rowDatas[19]});

                    }else if(typeof rowDatas[13] !== 'undefined' && rowDatas[13] !== ''){
                        this.setState({tagColor:'4px solid #00ccff',tagText:'Improved Tag',addTagsText1:rowDatas[13]});

                    }else{
                        this.setState({tagColor:'',tagText:'',addTagsText1:''});
                    }
                    if(action === 'changeQformat'){
                        var questions = rowDatas[23] ? rowDatas[23] :rowDatas[4];
                        var answerType = rowDatas[24] ? rowDatas[24] :rowDatas[5];
                        var options = rowDatas[22]  ? rowDatas[22] :rowDatas[6]
                        this.setState({color:'4px solid green',status:'New Question Format/Reword'});
                    }else if(action === 'Keep as is'){
                        var questions = rowDatas[4];
                        var answerType = rowDatas[5];
                        var options = rowDatas[6];
                        this.setState({color:'4px solid green',status:'Kept as is'});
                    }else if(action === 'Complex'){
                        var questions = rowDatas[4];
                        var answerType = rowDatas[5];
                        var options = rowDatas[6];
                        this.setState({color:'4px solid yellow',status:'Complex'});
                        
                    }else if(action === 'Discard'){
                        var questions = rowDatas[4];
                        var answerType = rowDatas[5];
                        var options = rowDatas[6];
                        this.setState({color:'4px solid red',status:'Discard'});
                        
                    }else{
                        var questions = rowDatas[4];
                        var answerType = rowDatas[5];
                        var options = rowDatas[6];
                        this.setState({color:'4px solid black',status:'Original'});
                    }                
                    var rowAllData = [id,location,parent,questions,answerType,options,visibility1,visibility,field_id];
                    this.setState({changeQueInpText:questions,changeOutInpText:outputText,chooseAnsType:answerType.toLowerCase(),chooseCompany:companyName,changeAnsInpText:options,valTypeText:valTypeText,validationType:valType,minValue:rowDatas[50],maxValue:rowDatas[51],asGroup,chooseTracking:trackingName})
                    this.generateQuestion(rowAllData)
					
					if(rowDatas[50] && rowDatas[51]){
						this.setState({showMinMax:true});
					}
                }
            }
        }else{
			
            var rowDatas = this.props.mainQues[params];
			//console.log('rowDatas->',rowDatas);
			if(rowDatas !== 'undefined'){
				this.setState({rowAllData:rowDatas,saveQueId:rowDatas[0],showDiv:true});
				var id = rowDatas[1]
				var location = rowDatas[9];
				var parent = rowDatas[2]
				var questions = rowDatas[4];
				var answerType = rowDatas[5];
				var companyName = rowDatas[115];
				var options = rowDatas[6];
				var visibility1 = rowDatas[8];
				var visibility = rowDatas[7];
				var action  = rowDatas[17];
				var field_id = rowDatas[0];
				var valType = rowDatas[48];
				var qType=rowDatas[5].toLowerCase();
				var trackingName = rowDatas[125];
				var asGroup = rowDatas[127];
				if(qType == 'parent header'){
					asGroup = rowDatas[126];
				}
				var valTypeText = this.props.validationType(valType);
				
				if(rowDatas[50] && rowDatas[51]){
					this.setState({showMinMax:true});
				}
					
				this.setState({changeQueInpText:questions,changeOutInpText:location,chooseAnsType:answerType.toLowerCase(),chooseCompany:companyName,changeAnsInpText:options,valTypeText:valTypeText,validationType:valType,minValue:rowDatas[50],maxValue:rowDatas[51],asGroup,chooseTracking:trackingName})
				if(rowDatas[7] && rowDatas[8]){
					this.setState({showPreCon:true,preCondition:rowDatas[7]+'-'+rowDatas[8]});
				}else{
					this.setState({preCondition:'',showPreCon:false}); 
				}
				this.setState({precolor:'4px solid black',preStatus:'Original',status:'Original',color:'4px solid black'});

				if(typeof rowDatas[12] !== 'undefined' && rowDatas[12] !== ''){
					var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
					let addNoteText = rowDatas[12];
					var imgUrl  = addNoteText;
					const html = `<p>`+imgUrl+`</p>`;
					const contentBlock = htmlToDraft(html);

					if (contentBlock) {
						const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
						const editorState = EditorState.createWithContent(contentState);
						this.setState({
						  editorState,
						});
					}
					this.setState({noteColor:'4px solid #9900ff',noteText:'Original Note',addNoteText1:imgUrl,addNoteText:addNoteText,});
				}else{
					this.setState({noteColor:'',noteText:'',addNoteText1:'', editorState:''});
				}
				if(rowDatas[13] !== ''){
					this.setState({tagColor:'4px solid #9900ff',tagText:'Original Tag',addTagsText1:rowDatas[13],addTagsText:rowDatas[13]});
				}else{
					this.setState({tagColor:'',tagText:'',addTagsText1:'',addTagsText:''});
				}
				var rowAllData = [id,location,parent,questions,answerType,options,visibility1,visibility,field_id];
				this.generateQuestion(rowAllData);
			}
        }
    }
    
	getQueType(type,id){
        switch(type){
            case 'sig':
                return <i className="fas fa-edit" onClick={()=>this.props.showSign(type,id)}></i>
            break;
            case 'q10':
                return <input type="checkbox" onClick={()=>this.props.showSign(type,id)}/>
            break;
            default:
                return null
        }
    }
    
	generateQuestion=(rowAllData)=>{
		this.setState({selectData:rowAllData,qType:rowAllData[4].toLowerCase()});
        if(rowAllData[9] === 1){
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.setState({ReQuestionId:rowAllData[8],ReLocation:rowAllData[1],queReType:rowAllData[4].toUpperCase()});
            this.displayData1 = <div id={rowAllData[0]} key={rowAllData[0]} visibility1={rowAllData[6]}><h5>{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{question}</h5><p>{answer}</p></div>;
            this.props.description.map((answer, i) => {  
                if(answer[1] == rowAllData[4].toUpperCase()){
                   this.setState({RequeDescription:answer[2]}); 
                }
            })
            const imageType = rowAllData[4].toLowerCase();
            this.setState({showImage:'/'+imageType+'.png'});
        }else if(rowAllData[9] === 2){
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.setState({queType:rowAllData[4].toUpperCase(),QueSaveId:rowAllData[8],QuestionId:rowAllData[8]});
            this.displayData2 = <div id={rowAllData[0]} key={rowAllData[0]} visibility1={rowAllData[6]}><h5>{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{question}</h5><p>{answer}</p></div>;
            this.props.description.map((answer, i) => {  
                if(answer[1] == rowAllData[4].toUpperCase()){
                   this.setState({queDescription:answer[2]});
                   this.setState({hidebtn:true}); 
                }
            })
            const imageType = rowAllData[4].toLowerCase();
            this.setState({showImage:'/'+imageType+'.png'});
        }else if(rowAllData[9] == 3){
            
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.setState({QueSaveId:rowAllData[8],precolor:'4px solid black',preStatus:'Original',ShowMainQueId:rowAllData[8],ShowMainQueLoc:rowAllData[1],ShowMainQueType:rowAllData[4].toUpperCase()});
        
            this.displayMainData = <div id={rowAllData[0]} key={rowAllData[0]} visibility1={rowAllData[6]}><h5>{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{question}</h5><p>{answer}</p></div>;
            this.props.description.map((answer, i) => {  
                if(answer[1] == rowAllData[4].toUpperCase()){
                   this.setState({ShowMainQueDes:answer[2]});
                }
            })
            const imageType = rowAllData[4].toLowerCase();
            this.setState({showImage:'/'+imageType+'.png'});
        }else if(rowAllData[9] == 4){
            this.setState({QueSaveId:rowAllData[8],queNo1:rowAllData[10],preCondVal:rowAllData[11]});
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.displayAddData = <div id={rowAllData[0]} key={rowAllData[0]} visibility1={rowAllData[6]}><h5>{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{question}</h5><p>{answer}</p></div>;
        }else{
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.setState({QueSaveId:rowAllData[8],QuestionId:rowAllData[8],Location:rowAllData[1],queType:rowAllData[4].toUpperCase(),qType:rowAllData[4].toLowerCase()});
            this.displayData = <div id={rowAllData[0]} key={rowAllData[0]} visibility1={rowAllData[6]}><h5>{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{question}</h5><p>{answer}</p></div>;
            if(this.props.description != undefined){
                this.props.description.map((answer, i) => {  
                    if(answer[1] == rowAllData[4].toUpperCase()){
                       this.setState({queDescription:answer[2],hidebtn:true}); 
                    }
                })  
            }
            const imageType = rowAllData[4].toLowerCase();
            this.setState({showImage:'/'+imageType+'.png'});
        }
    }
	
    goToNext = () => {
		
        var nextVal =$('#selectionChamp option:selected').next().attr('selected', 'selected').val();
        if(nextVal){
            this.setState({queNo:nextVal});
            this._answer(nextVal) ;     
        }
        this.setState({queActionDataClear:true});
    }
    
	goToPrevious = () => {
        var preVal =$('#selectionChamp option:selected').prev().attr('selected', 'selected').val();
        if(preVal){
            this.setState({queNo:preVal})
            this._answer(preVal)
        }
        this.setState({queActionDataClear:true});
    }
    
	goToNextReview = () => {
        var arrayLength = this.state.mainQues12.length;
        var nextReValue = this.state.nextValue+1;
        var k = nextReValue % arrayLength;
        this.showReview(k)
        this.setState({queActionDataClear:true});  
    }
    
	goToPreviousReview = () => {
        var nextValue = this.state.nextValue;
        if(nextValue === 0){
            var  nextValue = this.state.mainQues12.length;
        }
        var nextReValue = nextValue-1;
        this.showReview(nextReValue)
        this.setState({queActionDataClear:true});
    }
    
    render() {
        const { sheetsName,showReword,showImageBtn,rowAllData,chooseAnsType, showChangeFormatDrop,changeQformat, error,allData,queNo,mainQueKey,showDiv,getHtml,changeAnsInpText,changeQueInpText,changeOutInpText,valTypeText,validationType,QuestionId,Location,inputRewordText,inputRewordAns,status,hidebtn,queReType,ReLocation,ReQuestionId,mainQues12key,isChecked,phase,addTagsText,noteText,noteColor,tagsText,tagColor,preCondition,ShowMainQueType,ShowMainQueLoc,ShowMainQueId,ShowMainQueDes,showPreCon,queDescription,preCondVal,showPreDiv,embedCode,chooseCompany,chooseTracking } = this.state;
        const {showDataDiv, handleHeaderFilter,clearAllFilter,assoType,assoVisitType,assoDeviceType,companies,trackingCode} = this.props;
		
        const style    = {border: this.state.color,textAlign:'center'};
        const noteStyle= {border: this.state.noteColor,textAlign:'center'};
        const tagStyle = {border: this.state.tagColor,textAlign:'center'};
        const prestyle = {border: this.state.precolor,textAlign:'center'};
        let moduleName="edit_form_data";
		let qtype = this.state.qType;
        const Dropdown = () =>{
            var optionsHtml = this.props.mainQueKey.map(function(name,i) {
                var value = name.split('-');
                return (
                    <option value={value[0]} key={i}>{i+1}-{value[1]+'-'+value[2] }</option>
                );
            })
            return <select className="form-control" id="selectionChamp" value={this.state.queNo} onChange={this.setQuestionNo}>
                    <option value=""> Please Select</option>
                    {optionsHtml}
                </select>
        }
		
		const Companydropdown = () =>{
			let companyName = [];
			if(allData){
				allData.map(function(val,i) {
					if(val[111] == 'N' && val[115]){
						companyName.push(val[115]);
					}
				})
			}	
			
			var uniqueNames = [];
			$.each(companyName, function(i, el){
				if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
			});
			
			var optionsHtml = uniqueNames.map(function(val,i) {
				
				return (
					<option value={val} key={i}>{val}</option>
				);
				
			})
			return <select className="form-control" value={this.props.pfbByCompany} onChange={this.props.showPfbByCompany} >
					<option value=""> Please Select</option>
					{optionsHtml}
				</select>
			
        }
		
        return (
            <div className="card-body">
                <Header 
                    reviewSheetsName= {this.props.reviewSheetsName}
                    sheetsName = {this.props.sheetsName} 
                    selectedSheet1 ={this.props.selectedSheet1}
                    onSelect={this.props.onSelect}
                    showDataDiv = {showDataDiv}
                    reviewVer={this.props.reviewVer}
                    handleHeaderFilter={handleHeaderFilter}
                    showPfbQues={this.props.showPfbQues}
                    includePfbQues={this.props.includePfbQues}
                    assoType = {assoType}
                    assoVisitType ={assoVisitType}
                    assoDeviceType ={assoDeviceType} 
                    clearAllFilter = {clearAllFilter}
                    selectReviewOpt = {this.props.selectReviewOpt}
                    Dropdown={<Dropdown/>}
                    Companydropdown={<Companydropdown/>}
                    func = {this._answer}
                    moduleName = "edit_form_data"/>
                {showDiv && showDataDiv ?
                    <div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-7">
                                    <h5>Field Name: {QuestionId}</h5>
                                </div>
								
                                <div className="col-md-5">
                                    <p style={style}> {this.state.status}</p>
								</div>
                            </div>
							
							<div className="row">
                                <div className="col-md-7">
                                    <p><b>Validation Type:</b> {this.state.valTypeText}</p>
                                </div>
								
                                <div className="col-md-5">
                                    <p style={noteStyle}> {this.state.noteText}</p>
                                </div>
                            </div>
							
                            
                            <p className="mb-0"><b>Preconditions:</b>  {this.state.preCondition} {showPreCon ? (<div> <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={() => showParentField(this.state.preCondition,this.props.allData,this.generateQuestion)} data-target="#showMainField">
                                Show Field</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => this.preConFunc(this.state.preCondition) }> Edit Precondition</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => removePrecondition(this.props.allData,this.state.QueSaveId,this.props.selectedSheet,this.getNewCondition,this.props.selDropOpt)}> Remove Precondition</button> </div> ):<button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.preConFunc()}> 
                                Add Precondition</button>}</p>
                            
                        </div>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                        {showPreDiv ? (
                            <div className="col-md-8">
                                {this.displayAddData}
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Field Name:</label>
                                        <Dropdown1 
                                            mainQueKey={this.props.mainQueKey} 
                                            queNo={this.state.queNo1} 
                                            addPrecondition={this.addPrecondition}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Conditional Option:</label>
                                        <textarea className="form-control" rows="4" col="4" id="comment" value ={this.state.preCondVal} name="text" readOnly></textarea>
                                    </div>
                                </div>
                            </div>):null}
                        <div className="col-md-10">
                            <div className="form-group">
                                <div id="mainContainer">
                                    <div className="row">
                                        <div className="col-md-6"><p><b>Question Type:</b> {queDescription ? this.state.queType+' - '+this.state.queDescription :this.state.queType} &nbsp;&nbsp;
                                        {hidebtn ? (
                                        <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={this.showImage} data-target="#myImageModal">
                                              Show Image
                                        </button>
                                         ):null}   
                                        </p>
										</div>
										{qtype == 'child header 1' ||  qtype == 'child header 2' || qtype == 'child header 3' ||  qtype == 'parent header' ?
										<div className="col-md-6"><input type="checkbox" value='Y' checked={this.state.asGroup} className="asGroup" name="asGroup" onChange={this.setAsGroup}/> Available as a Group</div>
										:null}
                                    </div>
									<p><b>Field Input / Output Preview</b></p>
                                    <div className="output-pre" id="display-data-Container">
									{this.displayData}
									{Location ?
									 <p><b>Output Text:</b> { Location }</p>
									:null}
                                    </div>
									{qtype == 'title' || qtype == 'text' || qtype == 'child header 1' ||  qtype == 'child header 2' || qtype == 'child header 3' ||  qtype == 'parent header' ?
										<div className="edit-header-tile">
											<label>Edit Text:</label>
											<textarea name="changeQueInpText" value={this.state.modifyQuestions ? this.state.modifyQuestions : this.state.changeQueInpText} rows="4" onChange={this.getValue} className="form-control" />
										</div>
									:null}
                                </div>
								
                            </div>
                        </div>
                        <div className="col-md-12">
                            <hr/>
                        </div>
                        <div className="col-md-12 video-section">
                            <label><b>Additional Notes:</b></label>
                            <p>{ ReactHtmlParser(this.state.addNoteText1) }</p>
                        </div>
                        <div className="col-md-12">
                            <b>{'<<Tags>> : '}</b>{this.state.addTagsText1}
                            
                        </div>
						{chooseTracking ?
						<div className="col-md-12">
							<br/><p><b>Tracking Field:</b> { chooseTracking }</p>
						</div>
						:null}
                        <div className="col-md-12">
                            <hr/>
                        </div>
                        <div className="col-md-12">
						
						{qtype != 'title' && qtype != 'text' && qtype != 'child header 1' &&  qtype != 'child header 2' && qtype != 'child header 3' &&  qtype != 'parent header' ?
						
                            <QAction 
                                ref="QAction"
                                defaultProps = {this.state}
                                handleChange={this.handleChange}
                                handleClearNote={this.handleClearNote}
                                rowAllData={rowAllData} 
                                companies={companies} 
                                trackingCode={trackingCode} 
                                getValue={this.getValue} 
                                _uploadImageCallBack = {this._uploadImageCallBack}
                                onEditorStateChange ={this.onEditorStateChange}
                                queActionDataClear={this.state.queActionDataClear} 
                                mainQues={this.props.mainQues}
                                mod={this.state.mod} />
						:null}
                        </div>
                        <div className="col-md-12 btn-box-bottom">
                            <Button variant="primary" type="button" onClick={this.goToPrevious}> Back</Button>&nbsp;&nbsp;
                            <Button variant="primary" type="button" onClick={this.goToNext}> Next </Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" type="button" onClick={this.preOriginal} data-toggle="modal" data-target="#myOriginalModal"> Preview Original</Button>&nbsp;&nbsp;
                            <button type="button" onClick={this.showPreview.bind(this)} className="btn btn-primary" data-toggle="modal" data-target="#myModal">
                              Preview Changes
                            </button>&nbsp;&nbsp;
                            <Button variant="success" type="button" value="save" onClick={this.handleSubmit.bind(this)}>Save </Button>&nbsp;&nbsp;
                            Show Original &nbsp;
                            <label className="switch">
                              <input type="checkbox" defaultChecked={this.state.isChecked} id="slider" onClick={this.sliderUpdate}/>
                              <span className="slider round"></span>
                            </label> Show Latest Version
                            
                        </div>
                        <div className="col-md-12 bottom-btn btn-box-bottom">
                            <Button variant="primary" type="button" value="previous" onClick={this.handleSubmit.bind(this)}> Save & Previous</Button> &nbsp;&nbsp;
                            <Button variant="primary" type="button" value="next" onClick={this.handleSubmit.bind(this)}>Save & Next </Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" type="button" onClick={this.goToNext}> Skip & Next </Button> &nbsp;&nbsp; 
                            <button type="button" id={this.state.queNo} onClick={this.reviewMode} className="btn btn-primary">
                              Review Mode
                            </button>
							&nbsp;&nbsp; 
                            <button type="button" id={this.state.queNo} value="accept_improved" onClick={this.handleSubmit.bind(this)} className="btn btn-primary">
                              Accept Improved Changes
                            </button>
                            &nbsp;&nbsp;
                            <Button variant="danger" type="button" className="float-right" onClick={() => { if(window.confirm('Reset will erase all work done to improve this form. Export in Review/Approve mode to save a copy of your work unless you want to permanently clear your improved data. Continue with Reset?')) handleSubmitReset(this.props.selectedSheet, this.props.selDropOpt, this.props.selectedSheetId, this.props.allData) }}> Reset Improved</Button>
                        </div>
                        
                        
                    </div>
                :null }
                <div className="modal" id="myImageModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Image</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <img src={this.state.showImage} /> 
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="showMainField">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Parent Questions</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5>Field Name: {ShowMainQueId}</h5>
                                    </div>
                                    <div className="col-md-4">
                                    <p style={prestyle}> {this.state.preStatus}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
										<p><b>Validation Type:</b> {this.state.valTypeText}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={noteStyle}> {this.state.noteText}</p>
                                    </div>
                                </div>    
                                <p><b>Scope:</b></p>
                                <p><b>Type:</b> {this.state.queType} {this.state.queDescription ? '- '+this.state.queDescription :null} </p>
                                </div>
                                <div className="col-md-12">
                                    <hr/>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div id="mainContainer">
                                            <div>
                                                <p><b>Question Type:</b> {ShowMainQueType}{this.state.ShowMainQueDes ? ' - '+this.state.ShowMainQueDes:null} &nbsp;&nbsp;
                                                {hidebtn ? (
                                                <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={this.showImage} data-target="#myImageModal">
                                                      Show Image
                                                </button>
                                                 ):null}   
                                                </p>
                                            </div>
                                            <div id="display-data-Container">
                                            {this.displayMainData}
                                            </div>
                                        </div>
                                        <div className="output-txt">
											{ShowMainQueLoc ?
											<p><b>Output Text:</b> { ShowMainQueLoc }</p>
											:null}
                                        </div>
										
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="myOriginalModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Preview Original</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5>Question ID: {QuestionId}</h5>
                                    </div>
                                    <div className="col-md-4">
                                    <p style={prestyle}> {this.state.preStatus}</p>
                                    </div>
                                </div>
                                <div className="row">
									<div className="col-md-8">
										<p><b>Validation Type:</b> {this.state.valTypeText}</p>
									</div>
                                    <div className="col-md-4">
                                        <p style={noteStyle}> {this.state.noteText}</p>
                                    </div>
                                </div>    
                                <p><b>Scope:</b></p>
                                <p><b>Type:</b> {this.state.queType}{this.state.queDescription ? ' - '+this.state.queDescription :null} </p>
                                <p className="mb-0"><b>Preconditions:</b>  {this.state.preCondition} {showPreCon ? (<div> <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={() => showParentField(this.state.preCondition,this.props.allData,this.generateQuestion)} data-target="#showMainField">
                                    Show Field</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => this.preConFunc(this.state.preCondition) }> Edit Precondition</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => { removePrecondition(this.props.allData,this.state.QueSaveId,this.state.selectedSheet,this.updatedData(),this.props.selDropOpt) }}> Remove Precondition</button> </div> ):<button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.preConFunc()}> 
                                    Add Precondition</button>}</p>
                                </div>
                                <div className="col-md-12">
                                    <hr/>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div id="mainContainer">
                                            <div>
                                                <p><b>Question Type:</b> {this.state.queType} {this.state.queDescription ? ' - '+this.state.queDescription :null} &nbsp;&nbsp;
                                                {hidebtn ? (
                                                <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={this.showImage} data-target="#myImageModal">
                                                      Show Image
                                                </button>
                                                 ):null}   
                                                </p>
                                            </div>
                                            <p><b>Field Input / Output Preview</b></p>
											<div className="output-pre" id="display-data-Container">
												{this.displayData2}
												{Location ?
												<p><b>Output Text:</b> { Location }</p>
												:null}
											</div>
                                        </div>
										
										
										
                                    </div>
                                    <div className="form-group 12">
                                        <label>Additional Notes:</label>
                                        <p>
                                            { ReactHtmlParser(this.state.addNoteText) }
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <b>{'<<Tags>> : '}</b>{this.state.addTagsText1}
                                    </div>
									{chooseTracking ?
									<div className="col-md-12">
										<br/><p><b>Tracking Field:</b> { chooseTracking }</p>
									</div>
									:null}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="reviewModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="modal-title">Review Mode</h4>
                                    </div>
                                    <div className="col-md-6 float-right">
                                    <p> 
                                        <select className="form-control" id="selectReview" value={this.state.selectReview} onChange={this.selectReviewData}>
                                            <option value="original">Original</option>
                                            <option value="Keep as is"> Keep Question Format and Text As Is</option>
                                            <option value="changeQformat">New Question Format/Reword</option>
                                            <option value="Reword">Original Question Format/Reword</option>
                                            <option value="Complex">Complex</option>
                                            <option value="Discard">Discard</option>
                                        </select>
                                    </p>
                                    </div>
                                    
                                </div>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5 key={this.state.mainQues12key} >Question ID: {ReQuestionId}</h5>
											<p><b>Validation Type:</b> {this.state.valTypeText}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p style={style}> {this.state.status}</p>
                                        </div>
                                    </div>
                                    
                                    <p><b>Type:</b> {this.state.queReType}{this.state.RequeDescription ? '- '+this.state.RequeDescription : null} </p>
                                    <p className="mb-0"><b>Preconditions:</b>  {this.state.preCondition} {showPreCon ? (<div> <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={() => showParentField(this.state.preCondition,this.props.allData,this.generateQuestion)} data-target="#showMainField">
                                        Show Field</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => this.preConFunc(this.state.preCondition) }> Edit Precondition</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => { removePrecondition(this.props.allData,this.state.QueSaveId,this.state.selectedSheet,this.updatedData(),this.props.selDropOpt) }}> Remove Precondition</button> </div> ):<button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.preConFunc()}> 
                                        Add Precondition</button>}</p>
                                </div>
                                <div className="col-md-12">
                                    <hr/>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div id="mainContainer">
                                            <div id="display-data-Container">
                                                <p><b>Question Type:</b> {chooseAnsType ? this.state.chooseAnsType : this.state.queReType} - {this.state.RequeDescription}</p>
                                            </div>
                                            
											<p><b>Field Input / Output Preview</b></p>
											<div className="output-pre" id="display-data-Container">
												{this.displayData1}
												{Location ?
												<p><b>Output Text:</b> { Location }</p>
												:null}
											</div>
											
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <Button variant="primary" type="button" onClick={this.goToPreviousReview}> Previous </Button>
                                <Button variant="primary" type="button" onClick={this.goToNextReview}>  Next </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="myModal">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Preview</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <div className="col-md-12">
                                        <p><b>Field Name:</b> {QuestionId}</p>
                                        <p><b>Validation Type:</b> {this.state.valTypeText}</p>
                                        <p className="mb-0"><b>Preconditions:</b>  {this.state.preCondition} {showPreCon ? (<div> <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={() => showParentField(this.state.preCondition,this.props.allData,this.generateQuestion)} data-target="#showMainField">
                                            Show Field</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => this.preConFunc(this.state.preCondition) }> Edit Precondition</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => { removePrecondition(this.props.allData,this.state.QueSaveId,this.state.selectedSheet,this.updatedData(),this.props.selDropOpt) }}> Remove Precondition</button> </div> ):<button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.preConFunc()}> 
                                            Add Precondition</button>}</p>
                                    </div>
                                    <div className="col-md-12">
                                        <hr/>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div id="mainContainer">
                                                <div id="display-data-Container">
                                                    <p><b>Question Type:</b> {chooseAnsType ? this.state.chooseAnsType : this.state.queType} {this.state.quePrevDescription ? ' - '+this.state.quePrevDescription :null} </p>
													
														<p><b>Field Input / Output Preview</b></p>
														<div className="output-pre">
															<h5>{this.state.changeQueInpText ? this.state.changeQueInpText : this.state.question}</h5>
															<p>{this.state.changeAnsInpHtml}</p>
														  
															<p><b>Output Text:</b> {this.state.changeOutInpText ? this.state.changeOutInpText : Location}</p>
														</div>
													  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 form-group 13">
                                        <label>Additional Notes:</label>
                                        <p>
                                            { ReactHtmlParser(this.state.addPreNoteText) }
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <b>{'<<Tags>> : '}</b>{this.state.addTagsText1}
                                    </div>
									{chooseTracking ?
									<div className="col-md-12">
										<br/><p><b>Tracking Field:</b> { chooseTracking }</p>
									</div>
									:null}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                </div>  
            </div>            
        );
    }
}
export default EditFormData;