//for Local
/*export default {
  apiKey: "AIzaSyBcTq5WsQgaBl_ogf7tq-i6RxmFFE5Vkvo",
  discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  spreadsheetId: "1vNm4yr6UT3d_XTwc9m9kWD30DYCwHDAez9W_nYgw9cM",
  mdsSpreadsheetId: "1WF1HzPZsa1sSzCr0edVtA7_lP6It6Q1v-TJHAO2IAwY",
  childSpreadsheetId: "1UP9LqcwNlybSVRhDrKfoS8SFGKs_3LmFBUjd8UH5vUk",
  client_id:'735173221478-d0fp6l9h8e2aab0fufi7clpt2k0eh2kq.apps.googleusercontent.com',
  pahseII_spreadsheetId: "1zQxOFYvGDPWL1iy5x4QdfntY2S4MPnevCeLFoPhgExg",
  language_spreadsheetId:"1H67psh7D23o70Rsg4beOcKqNpgcHAucu__Y5jlbFZpU",
  API_URL : "https://soogap.info/api/",
  zoomLink:'https://zoom.us/my/soogapmd1',
  showSheetInDrop:9,
  phase5spreadsheetId: "1njFGZpG7zdIaDYTObXUo3sqr7-ucQKFIFJ1dIsbbqQo",
  noteImageApiUrl: 'http://localhost/pdf/file_upload.php',
  API_vimeo : "https://soogap.info/api/vimeo/public/v1/",
  API_PDF_URL:"https://library.fyles.link/file/api/v1",
  configsheetId:'1554121118',
  configsheetId2:'39022275',
  API_username:'JsonUser',
  API_password:'w$6%2jq)Mj',
};*/

//For Soogap.info
export default {
  apiKey: "AIzaSyAC8f5DCNzF_gaYDTLjXtBuaIjXxIk3-js",
  discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  spreadsheetId: "1FeWeL78cabaaAAFrKHRPa0h-y0A_hq_8RSWfxBNKuGM",
  mdsSpreadsheetId: "1WF1HzPZsa1sSzCr0edVtA7_lP6It6Q1v-TJHAO2IAwY",
  childSpreadsheetId: "1UP9LqcwNlybSVRhDrKfoS8SFGKs_3LmFBUjd8UH5vUk",
  client_id:"965034141395-9l61ucj2nrf9cgvupnkfiq9kdfutrios.apps.googleusercontent.com",
  language_spreadsheetId:"1H67psh7D23o70Rsg4beOcKqNpgcHAucu__Y5jlbFZpU",
  pahseII_spreadsheetId: "1zQxOFYvGDPWL1iy5x4QdfntY2S4MPnevCeLFoPhgExg",
  API_URL : "https://soogap.info/api/",
  zoomLink:'https://zoom.us/my/soogapmd1',
  showSheetInDrop:9,
  phase5spreadsheetId: "1njFGZpG7zdIaDYTObXUo3sqr7-ucQKFIFJ1dIsbbqQo",
  API_vimeo : "https://soogap.info/api/vimeo/public/v1/",
  noteImageApiUrl: 'https://soogap.info/api/notefile/file_upload.php',
  API_PDF_URL:"https://library.fyles.link/file/api/v1",
  configsheetId:'1781592346',
  configsheetId2:'1455143890',
  API_username:'JsonUser',
  API_password:'w$6%2jq)Mj',
};

//for soogap.net
/*export default {
  apiKey: "AIzaSyAcdGYazdHhqbIvs1vfzHO95VJC72_CxRw",
  discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  spreadsheetId: "1Jy73jY6o2CCoXEO2rhnJNJc3h7OXxdfsbLw2jLHAA4o",
  client_id:"111190652983-vhuqul4th0pcd41bs551ugom5j33c91k.apps.googleusercontent.com",
  language_spreadsheetId:"1H67psh7D23o70Rsg4beOcKqNpgcHAucu__Y5jlbFZpU",
  pahseII_spreadsheetId: "1zQxOFYvGDPWL1iy5x4QdfntY2S4MPnevCeLFoPhgExg",
  API_URL : "https://soogap.net/api/",
  zoomLink:'https://zoom.us/my/soogapmd1',
  showSheetInDrop:11,
  phase5spreadsheetId: "1njFGZpG7zdIaDYTObXUo3sqr7-ucQKFIFJ1dIsbbqQo",
  API_vimeo : "https://soogap.net/api/vimeo/public/v1/",
  noteImageApiUrl: 'https://soogap.net/api/notefile/file_upload.php',
};*/
