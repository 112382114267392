import React, { Component } from 'react';
import $ from 'jquery';
import 'bootstrap';
import "../../containers/App.css";
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import config from "../../config";
import { gapi } from 'gapi-script';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import KioskData from "../FormBuilder/KioskData";
import { KioskDrop } from "../FormBuilder/KioskDrop";    
import { getSheetsIdByName } from "../../Helpers/Common";
import { Dropdown1 } from "../../component/FormData/Dropdown";
import { removePrecondition, showParentField, editPrecondition} from "../../Helpers/Precondition";
import { generatehtmlfromlocation,handleAuthResult,saveConfigFile } from "../../Helpers/Common";
import MainData from "../../component/MainData/MainData";
import Header from "../../component/Header/Header";
import QAction from "../../component/QAction/QAction";
import guid from "../../Helpers/GUID";
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';


class FormatForm extends Component{
    
    constructor(props){
        super(props)
        this.state ={
            sheetData:[],
            rowAllData:'',
            kioskUsers:['CPO'],
            checkedKiosk:new Map(),
            kioskUsersData:new Map(),
            kioskResponse:new Map(),
            feedsResponse:new Map(),
			pfbByCompany:'',
			selOptions:'view_all',
            queType:'',
			addCompany:'',
			newCompanyName:'',
            checkedReq:new Map(),
            addNoteText:'',
            addTagsText:'',
			allPos: [],
            moduleName:'format_forms',
            kioskPreviewData:[],
        }

    }
    
    subQuestions=(res)=>{
        this.setState({preCondVal:res})
    }
	
    getValue=(event)=>{
        this.setState({[event.target.name]:event.target.value});
    }
	
	exportFacilityID=(event)=>{
        this.setState({setFacilityID:event.target.value});
    }
	
	exportsetGroupID=(event)=>{
        this.setState({setGroupID:event.target.value});
    }
	
	saveKioskUsersType=(id)=>{
        let kioskUsersData1 =this.state.kioskUsersData;
        
		/* var scorer = {CaC:0,Pat:1,Int:2,Res:3,PrA:4,PhT:5,CPO:6,PhA:7,PmD:8,}
        var final_arr1  = ['CaC','Pat','Int','Res','PrA','PhT','CPO','PhA','PmD'];
        var final_arr = this.state.kioskUsers.sort(function(a,b) {
            return scorer[a]-scorer[b];
        }); */
		
		let allDatas = this.props.allData;
		let uType = [];
		let kioskType = allDatas[0][119].split(',');
		
		if(kioskType){
			kioskType.map(function(type,i) {
				type = type.split(':');
				uType.push(type[1]);
			})
		}else{
			uType = ['CPO']
		}
		
		var final_arr = this.state.kioskUsers;
		var final_arr1 = uType;
		
		
        var array=[]; 
        final_arr1.map((item,i)=>{
            if(final_arr.includes(item) == true){
                array.push(item)
            }else if(item == 'CPO'){
                array.push('CPO')
            }else{
                array.push('')
            }
        })
        kioskUsersData1.set(id,array.toString());
        let t = gapi.auth.getToken()
        let accessToken = gapi.auth.getToken().access_token;
        //let spreadsheetID = config.spreadsheetId;
        let spreadsheetID = this.props.sheetConfigId;
		
		
		if(this.state.formData){
			allDatas = this.state.formData;
		}

        let kioskArr = this.state.checkedKiosk;
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        let updateData=[];
        let rowId ='';
        let kiosk ='N';
        if(kioskArr.get(id) == true){
            kiosk = 'Y';
        }
        let userTypes = this.state.kioskUsersData.get(id)
        if(userTypes == undefined){
            userTypes = ',,,,,,,CPO,,';
        }
        let type = 'kiosk';
        let item  = id;
        let items = [];
        items = this.checkPreCondition(item, items, type);
        allDatas.map((rowDatas, i) => { 
			let guids = rowDatas[35] ? rowDatas[35] : rowDatas[91];
            if(rowDatas[0] == id){
                rowId = (i+2);
                let row1 = {
                    "range": this.props.selectedSheet+'!AI'+rowId,
                    "majorDimension": "ROWS",
                    "values": 
                    [
                        [ rowDatas[0] + ','+guids+','+rowDatas[2] +','+ rowDatas[7] +','+ rowDatas[8] +','+ rowDatas[32] +','+ rowDatas[33] +','+ rowDatas[10] +','+ kiosk+','+userTypes]
                    ]
                };
                updateData.push(row1)
            }

            items.map((item)=>{
                kioskUsersData1.set(item,array.toString());
                if(rowDatas[0] == item){
                    rowId = (i+2);
                    let row2 = {
                        "range": this.props.selectedSheet+"!AI"+rowId,
                        "majorDimension": "ROWS",
                        "values": 
                        [
                            [ rowDatas[0] + ','+guids+','+rowDatas[2] +','+ rowDatas[7] +','+ rowDatas[8] +','+ rowDatas[32] +','+ rowDatas[33] +','+ rowDatas[10] +','+ kiosk+','+userTypes]
                        ]
                    };
                    updateData.push(row2)
                }

            })
        })
		
        this.setState({kioskUsersData:kioskUsersData1})
		
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
            alert('Data saved Successfully')
        }, function(reason) {
            alert(reason.result.error.message);
        });
        this.setState({kioskUsers:['CPO']})
        this.props.updatedData();
    }
    
	addPrecondition=(e)=>{
        var quesID = e.target.value;
        this.setState({queNo1:quesID});
        if(quesID !== 'undefined' ){
            var rowDatas = this.props.allData[quesID];
            if(rowDatas !== 'undefined'){
                this.setState({preCondID:rowDatas[0]});
                var id         = rowDatas[1]
                var location   = rowDatas[9];
                var parent     = rowDatas[2]
                var questions  = rowDatas[4];
                var answerType = rowDatas[5];
                var options    = rowDatas[6];
                var visibility = rowDatas[8];
                var action     = rowDatas[16];
                var field_id   = rowDatas[0];
                var rowAllData = [id,location,parent,questions,answerType,options,visibility];
                this.generateQuestion(rowAllData)         
            }   
        }
    }
	
	selectKioskUsers=(event)=>{
        this.setState({kioskUsers: Array.from(event.target.selectedOptions, (item) => item.value)})
    }
    
	generateQuestion=(rowAllData)=>{
        if(rowAllData[8] === 3){
            this.setState({showFieldText:!this.state.showFieldText,showPreDiv:!this.state.showPreDiv,queNo1:rowAllData[9],preCondVal:rowAllData[10]});
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions, this.props);
            this.displayAddData = <div id={rowAllData[0]} key={rowAllData[0]} visibility={rowAllData[6]}><h5>{question}</h5><p>{answer}</p></div>;

        }else if(rowAllData[8] === 4){
            this.setState({queNo1:rowAllData[9],preCondVal:rowAllData[10]});
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.displayAddData = <div id={rowAllData[0]} key={rowAllData[0]} visibility={rowAllData[6]}><h5>{question}</h5><p>{answer}</p></div>;
        }   
    }
    
	showAddUsersModal=(id)=>{
        let allDatas = this.props.allData;
        var res = ''
        let posData
        allDatas.map((rowDatas, i) => {
            if(rowDatas[0] == id){
                if(typeof this.state.kioskUsersData.get(rowDatas[0]) !== 'undefined'){
                    posData=this.state.kioskUsersData.get(rowDatas[0]);
                }else if(this.props.selectedOpt == 'original'){
                    posData = rowDatas[16];
                }else{
                    posData = rowDatas[34] ? rowDatas[34] : rowDatas[16];
                } 
                if(typeof posData !== 'undefined' && posData != ''){
                    res = posData.split(',');
                } 
            }
        })

        if(res !== ''){
            var array = res.slice(-9);
            this.setState({kioskUsers:array})
        }else{
            this.setState({kioskUsers:['CPO']})
        }
        $('#kiosk'+id).modal('show')
    }
    
	componentDidUpdate(){
        if(this.props.allData.length && this.state.checkedKiosk.size == 0){
            this.setDefaultCheckboxes();
        }
		
		if(this.props.hasModify){
			$('.comments').css('backgroundColor','#e0e0e0')
		}else{
			$('.comments').css('backgroundColor','#ffffff')
		}
    }
    
	setDefaultCheckboxes=()=>{
		let checkedKiosk = this.state.checkedKiosk;
        this.props.allData.map((valu, i) => {
            let isChecked2 ="";
            if(valu[16]){
                let kioskDefalt =valu[16].split(',');
                if(kioskDefalt[8] == 'Y'){
                    isChecked2 = true
                }else{
                    isChecked2 = false
                }
                checkedKiosk.set(valu[0],isChecked2);
            }
        })
        this.setState({checkedKiosk: checkedKiosk});
    }
	
	handleKiosk = (e) => {
        let item = e.target.name;
        let isChecked = e.target.checked;
        let checkedKiosk = this.state.checkedKiosk;
        
        checkedKiosk.set(item, isChecked)
        let type = 'kiosk';
        if(isChecked == true){
            $('#userBtnId'+item).css("display", "block"); 
            let items = [];
            items = this.checkPreCondition(item, items, type);
            items.map((item)=>{
                $("#"+item).find(".kiosk").attr( 'checked', true )
                checkedKiosk.set(item, isChecked)
            })
        }else{
           
           let items = [];
            items = this.checkPreCondition(item, items, type);
            items.map((item)=>{
                $("#"+item).find(".kiosk").attr( 'checked', false )
                checkedKiosk.set(item, isChecked)

            })
        }
        let elm = document.getElementById(item);
        let x = 0;
        do {
            if(elm){
                let parentNode = elm.parentNode;
                
                if(parentNode.getAttribute('data-header') == 1 || parentNode.getAttribute('data-header') == 2 || parentNode.getAttribute('data-header') == 3){
                    let name = parentNode.getAttribute('id');
                    var checkedbox = false;
                    $('.inner-'+name).find('.kiosk:checked').each(function() {
                        if($(this).is(":visible")){
                            checkedbox= true;
                        }
                    });
                    
                    if(checkedbox == false){
                        $('input[type=checkbox][name='+name+']').prop('checked', false);
                    }
                    
                    if(checkedbox == false || isChecked == true){
                        checkedKiosk.set(name, isChecked)
                        $('#userBtnId'+name).css("display", "inline-block");
                    }
                }
                if(parentNode.className.indexOf('parent-header') != -1){
                    x = 10; 
                }
                elm = parentNode;  
            }
            x++;
        }
        while(x < 10);
        this.setState({checkedKiosk: checkedKiosk});
    }
    
	preConFunc=(cond_id)=>{
        this.setState({showPreDiv:true});
        if(cond_id){
            editPrecondition(cond_id,this.props.allData,this.generateQuestion);
        }
    }
    
    myFunction =(rowData)=>{
        this.setState({ChangeNotes:false,ChangeTags:false,changeAnsOpt:false,changeQueText:false,changeQueType:false});
        this.setState({showPreDiv:false,changeQformat:false,addTagsText:'',addNoteText:'',queAction:'',changeAnsInpText:'',changeQueInpText:'',chooseAnsType:'',valTypeText:''});
        this.setState({showPreDiv:false,preCondVal:'',preCondID:'',queNo:'',preCondition:rowData[7]+'-'+rowData[8],showFieldText:true,allData:this.props.allData,selectedSheet:this.props.selectedSheet}); 
        let question   ='';
        let answerType ='';
        let option     ='';
        let addTagsText='';
        let addNoteText='';
        let action = rowData[17];
        if(this.props.selectedOpt == 'improved'){
            question = rowData[23] ? rowData[23] :rowData[4];
            answerType = rowData[24] ? rowData[24] :rowData[5];
            option = rowData[22]  ? rowData[22] :rowData[6]
            addNoteText=rowData[18] ? rowData[18] : rowData[12];
            addTagsText= rowData[19] ? rowData[19] :rowData[13];
        }else{
            question   = rowData[4];
            answerType = rowData[5];
            option     = rowData[6];
            addNoteText=rowData[12];
            addTagsText= rowData[13];
        }
		
		var valType = rowData[48];
		var valTypeText = this.props.validationType(valType);
        this.setState({valTypeText});
        var id = rowData[1]
        var location = rowData[9];
        var parent = rowData[2]
        var visibility1 = rowData[8];
        var visibility = rowData[7];
        var field_id = rowData[0];
        let rowAllData = [id,location,parent,question,answerType,option,visibility1,visibility,field_id];
		
        this.props.description.map((answer, i) => {
			if(answer[1] && answer[1].toLowerCase() === rowData[5].toLowerCase()){
               this.setState({queDescription:answer[2]}); 
            }
        })
		
		
        if(rowData[5].toLowerCase() == 'text' || rowData[5].toLowerCase() == 'title' ){
            this.setState({TextDiv:true,queDescription:'',changeQueInpText:rowData[4],showQueAction:true,headerDiv:false,Question:question,queAction:'changeQformat'});  
            this.setState({QuestionId:rowData[0],Location:rowData[9],saveQueId:rowData[0],queType:answerType.toUpperCase(),addTagsText:addTagsText,addNoteText:addNoteText,QuestionShowDiv:false}); 
        } else if(rowData[5].toLowerCase() == 'child header 1' || rowData[5].toLowerCase() == 'child header 2' || rowData[5].toLowerCase() == 'child header 3'){
            this.setState({headerDiv:true,changeQueInpText:rowData[4],showQueAction:false,TextDiv:false,Question:question,queDescription:'',queAction:'changeQformat'});
            this.setState({QuestionId:rowData[0],Location:rowData[9],saveQueId:rowData[0],queType:answerType.toUpperCase(),addTagsText:addTagsText,addNoteText:addNoteText,QuestionShowDiv:false}); 
        } else{
            this.setState({QuestionId:rowData[0],Location:rowData[9],saveQueId:rowData[0],Question:question,queType:answerType.toUpperCase(),headerDiv:false,addTagsText:addTagsText,addNoteText:addNoteText,showQueAction:true,TextDiv:false,QuestionShowDiv:true});
        }
        var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
        var imgUrl  = addNoteText;
        const html = `<p>`+imgUrl+`</p>`;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
              editorState,
            });
        }
        this.setState({addNoteText:addNoteText,addTagsText:addTagsText});
        const imageType = rowData[5].toLowerCase();
        this.setState({showImage:'/'+imageType+'.png'});
        let answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
        this.setState({Answer:answer});
        if(rowData[7]){
            this.setState({showPreCon:true,preCondition:rowData[7]+'-'+rowData[8],QueSaveId:rowData[0]});
        }else{
            this.setState({showPreCon:false,preCondition:''});
        }
        $('#code').modal('show');
    }
    
	checkKey(elements, parentId = '',ids ,pId, cId) {
        var branch = [];
        { elements.map((element,i) => {
            if(element[pId] == 'TT Eval'){
                element[pId] = '';
            }
            if (element[pId].toLowerCase() == parentId) {
                var children = this.checkKey(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children) {
                    element['sub'] = children; 
                }
                if(!ids.includes(element['0'])){
                    branch.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch;    
    }
    
	updateArray = (formData) => {
        let allData2 =[];
        {formData.map((data,i)=>{
            var key = data[10];
            allData2[key] = data;
        })}
        var ids =[];
        this.checkKey(allData2, '', ids, 2, 1); 
    }
    
    handleSubmit=()=>{
        var btnClicked = '';
        var functions = [this.state]
        if(this.state.accessToken){
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true},handleAuthResult(this.props.sheetConfigId,btnClicked,functions,this.props.updatedData,this.props.selectReviewOpt));
        }else{
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: false},handleAuthResult(this.props.sheetConfigId,btnClicked,functions,this.props.updatedData,this.props.selectReviewOpt));
        }
    }
    
    handleSubmitReset = () => {
		let accessToken = gapi.auth.getToken().access_token;
		//let spreadsheetId = config.spreadsheetId;
		let spreadsheetId = this.props.sheetConfigId;
		let selectedSheet = this.props.selectedSheet;
		let params1 =  {
			spreadsheetId: spreadsheetId,
			range:selectedSheet,
		};
		
		let that = this;

		let newRequest = gapi.client.sheets.spreadsheets.values.get(params1);
		newRequest.then(function(response) {
			let data = response.result.values;
			
			let newArrr = []; 
			data.forEach(function(e,i){
				let el = Object.assign([], e);
				if(i >0){
					for(let ind = 17; ind < 35; ind++){
						el[ind] = '';
					}
				}
				newArrr.push(el);    
			});
			
			let params = {
				access_token:accessToken,
				spreadsheetId: spreadsheetId,
			};
			let batchUpdateValuesRequestBody = {
				valueInputOption: 'USER_ENTERED',
				data: [{
					"range": selectedSheet,
					"majorDimension": "DIMENSION_UNSPECIFIED",
					"values":newArrr ,
				}], 
			};
			
			var updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params, batchUpdateValuesRequestBody);
			updateRequest.then(
				response => {
					that.props.updatedData();
					$('.comments').css('backgroundColor','#ffffff')
				}, function(reason) {
				
				console.error('error: ' + reason.result.error.message);
			});
			
		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});
		
		//this.props.updatedData();
	}
    
	checkKey2(elements, parentId = '',ids ,pId, cId) {
        var branch1 = [];
        { elements.map((element,i) => {
            if(element[5] == 'Parent Header'){
                element[pId] = '';
            }  
            if (element[pId].toLowerCase() == parentId) {
                if(element[cId] == ''){
                    element[cId]='a';
                }
                var children1 = this.checkKey2(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children1) {
                    element['sub2'] = children1; 
                }
                if(!ids.includes(element['0'])){
                    branch1.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch1;    
    }
    
	deleteData(allDatas){
        var branch= [];

        for(let i = 0; i < allDatas.length; i++){
            let row = Object.assign([], allDatas[i]);
            if(row.sub){
                delete row.sub;
            }
            branch.push(row);
        }
 
        return branch;
    }
	
	showPreview=()=>{
        let configDataRow =[];
        let kioskUsers = this.state.kioskUsers
        let data = this.props.allData;
        let sampleData = this.deleteData(data);
		let sOptions = this.state.selOptions;
        let mainData =[];
        sampleData.map((rowDatas,i)=>{
            let key = rowDatas[10]
            if(rowDatas[5] == 'Parent Header'){
                mainData.push(rowDatas);
            }
            let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
            if(typeof columnName !== 'undefined' && columnName !== ''){
                configDataRow = columnName.split(',');
                configDataRow.splice(0, 9);
            }
            
            if(this.state.checkedKiosk.get(rowDatas[0]) == true && rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                mainData.push(rowDatas);
            }else if(this.state.checkedKiosk.get(rowDatas[0]) == true && configDataRow.indexOf(kioskUsers[0]) != -1){
                if(sOptions == 'required_only'){
					if (rowDatas[15] == 'Y') {
						mainData.push(rowDatas);
					}
				}else{
					mainData.push(rowDatas);
				}
            }
                    
        })
        let ids =[];
        var dbArray = this.checkKey2(mainData, '', ids, 2, 1);
        let db3 =[];
        dbArray.map((rowDatas,i)=>{
            if( rowDatas['sub2'].length != 0 ){
                db3.push(rowDatas);
            }
        })
        
        this.setState({kioskPreviewData:db3});
        if(kioskUsers.toString() == 'PhT'){
            $('.kioskPreview').css('backgroundColor','#FFCCE5')
        }else if(kioskUsers.toString() == 'CPO'){
            $('.kioskPreview').css('backgroundColor','#FFFFFF')
        }else if(kioskUsers.toString() == 'Int' || kioskUsers.toString() == 'Res' || kioskUsers.toString() == 'PrA' || kioskUsers.toString() == 'PA' ){
            $('.kioskPreview').css('backgroundColor','#B2FF66')
        }else if(kioskUsers.toString() == 'PhA' || kioskUsers.toString() == 'PmD'){
             $('.kioskPreview').css('backgroundColor','#DCDCDC')
        }else if(kioskUsers.toString() == 'Pat'){
             $('.kioskPreview').css('backgroundColor','#ccf5ff')
        }else if(kioskUsers.toString() == 'CC'){
            $('.kioskPreview').css('backgroundColor','#FFFF99')
        }
        $('#selectUsersType').modal('show')
            
    }
	
	exportSheetData(sheetName,facilityID,groupID){
		
		//let spreadsheetId = config.pahseII_spreadsheetId;
		//let sheet_Id = this.state.formatSheet_Id;
		//let newPositions = this.state.formData;
		
		var sheetName = "P4-"+this.props.selectedSheet+"-"+sheetName;
		
		if(this.state.formData){
			var currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
			var guids=guid();
			let selectSheet = this.props.selectedSheet.split('-');
		   
			
			let mainData = this.props.allData;
			this.saveConfigFile(sheetName,guids,currentDate,mainData,facilityID,groupID); 
		}else{
			alert('Please move field position and save changes first to export new version.');
		}
		this.setState({newCompanyName:''});
		
		
	}
	
	idOf(i){
        return (i >= 26 ? this.idOf((i / 26 >> 0) - 1) : '') +  'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0];
    }
	
	checkPreCondition(item, items, type) {
        let that = this;
        $("."+item).find("."+type).each(function() {
            let name = $(this).attr('name')
            items.push(name);
            that.checkPreCondition(name, items, type);
        });
        return items;
    }
	
	saveConfigFile =(sheetName,guids,currentDate,mainData,facilityID,groupID)=>{
        
        let params1 = {
            spreadsheetId: config.spreadsheetId,
        };
        var sheetVal = '';
        let updateData=[];
        gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: config.spreadsheetId,
                range:'TTEVAL-Config Files',
            })
            .then(response => {
                    sheetVal = response.result.values;
					let length = 0;
					if(sheetVal){
						length = sheetVal[0].length;
					}
                    
					let firstRow = sheetName;
					let mdsName = mainData[0][118];
					let secondRow = mainData[0][91];
					let thirdRow = currentDate;
					let fourRow = guids;
					let fifthRow = mainData.length+' Fields';
					let sixthRow = mainData[0][92];
					let seventhRow = mainData[0][94];
					let eighthRow = mainData[0][95];
					let ninthRow = facilityID ? facilityID : 'All';
					let tenthRow = groupID ? groupID : '-';
					let elevenRow = 'English';
				
                    let columnName = this.idOf(length);
					
                    let row1 = {
                        "majorDimension": "ROWS",
                        "range": "TTEVAL-Config Files!"+columnName+1,
                        "values": [
                            [ firstRow ]
                        ]
                    }
					
					let row2 = {
						"range": "TTEVAL-Config Files!"+columnName+'2',
						"majorDimension": "ROWS",
						"values": [
							[ mdsName ]
						]
					}
					let row3 = {
						"range": "TTEVAL-Config Files!"+columnName+'3',
						"majorDimension": "ROWS",
						"values": [
							[ secondRow ]
						]
					}
					let row4 = {
						"range": "TTEVAL-Config Files!"+columnName+'4',
						"majorDimension": "ROWS",
						"values": [
							[ thirdRow ]
						]
					}
					let row5 = {
						"range": "TTEVAL-Config Files!"+columnName+'5',
						"majorDimension": "ROWS",
						"values": [
							[ fourRow ]
						]
					}
					
					let row6 = {
						"range": "TTEVAL-Config Files!"+columnName+'6',
						"majorDimension": "ROWS",
						"values": [
							[ fifthRow ]
						]
					}
					
					let row7 = {
						"range": "TTEVAL-Config Files!"+columnName+'7',
						"majorDimension": "ROWS",
						"values": [
							[ sixthRow ]
						]
					}
					
					let row8 = {
						"range": "TTEVAL-Config Files!"+columnName+'8',
						"majorDimension": "ROWS",
						"values": [
							[ seventhRow ]
						]
					}
					let row9 = {
						"range": "TTEVAL-Config Files!"+columnName+'9',
						"majorDimension": "ROWS",
						"values": [
							[ eighthRow ]
						]
					}
					let row10 = {
						"range": "TTEVAL-Config Files!"+columnName+'10',
						"majorDimension": "ROWS",
						"values": [
							[ ninthRow ]
						]
					}
					let row11 = {
						"range": "TTEVAL-Config Files!"+columnName+'11',
						"majorDimension": "ROWS",
						"values": [
							[ tenthRow ]
						]
					}
					
					let row12 = {
						"range": "TTEVAL-Config Files!"+columnName+'12',
						"majorDimension": "ROWS",
						"values": [
							[ elevenRow ]
						]
					}
					
					let row13 = {
						"range": "TTEVAL-Config Files!"+columnName+'13',
						"majorDimension": "ROWS",
						"values": [
							[ '{eoh}' ]
						]
					}
					
					updateData.push(row1);
					updateData.push(row2);
					updateData.push(row3);
					updateData.push(row4);
					updateData.push(row5);
					updateData.push(row6);
					updateData.push(row7);
					updateData.push(row8);
					updateData.push(row9);
					updateData.push(row10);
					updateData.push(row11);
					updateData.push(row12);
					updateData.push(row13);
					
                    let j = 14
					
                    mainData.map((rowDatas,i)=>{
						let configP = rowDatas[34] ? rowDatas[34] : rowDatas[16];
						if(configP){
							let row14 = {
								"range": "TTEVAL-Config Files!"+columnName+j,
								"majorDimension": "ROWS",
								"values": [
									[ configP ]
								]
							}
							updateData.push(row14);
						}
                        j++;
                    })

					let lastrow = {
						"range": "TTEVAL-Config Files!"+columnName+j,
						"majorDimension": "ROWS",
						"values": [
							[ '{eof}' ]
						]
					}
					updateData.push(lastrow);
					
                    let batchUpdateValuesRequestBody = {
                        "valueInputOption": 'USER_ENTERED',
                        data: updateData
                    }; 
					let that = this;
                    let request2 = gapi.client.sheets.spreadsheets.values.batchUpdate(params1,batchUpdateValuesRequestBody);
                    request2.then(function(response) { 
                        alert('TEVAL-Config Files Data Saved Successfully');
						that.handleSubmitReset();
						setTimeout(function(){
							window.location.reload();
						}, 3000);
                    }, function(reason) {
                        alert(reason.result.error.message);
                    }); 
                },
            );
        });
    }
	
	/* updateFormat = (that, spreadsheetId, formName, selectedSheet, tempArray, accessToken) => { 
		
		let selectedOpt = this.props.selectedOpt;
		let spreadsheetId2 = config.pahseII_spreadsheetId
		
		getSheetsIdByName(spreadsheetId2, formName).then(function(response) {
		  that.setState({ formatSheet_Id:response });
		}, function(error) {
		  console.error("Failed!", error);
		})

		if(selectedOpt == 'improved'){
			spreadsheetId = spreadsheetId2;
			selectedSheet = formName;
		}

		var params1 =  {
			spreadsheetId: spreadsheetId,
			range:selectedSheet,
		};
		var newRequest = gapi.client.sheets.spreadsheets.values.get(params1);
		newRequest.then(function(response) {
			let data = response.result.values;
			
			let newArrr = []; 
			data.forEach(function(e,i){
				let el = Object.assign([], e);
				let p =  tempArray.filter(function(pos) {
					return pos['id'] == el[0];
				});

				if(p.length){
					el[2] =  p[0]['parentId'];
					el[10] = p[0]['position'];
					el[11] = p[0]['parentGuid'];
					el[16] = p[0]['config_position'];
				}
				if(i >0){
					for(let ind = 17; ind < 35; ind++){
						el[ind] = '';
					}
				}
				newArrr.push(el);    
			});
			
			let params = {
				access_token:accessToken,
				spreadsheetId: spreadsheetId2,
			};
			let batchUpdateValuesRequestBody = {
				valueInputOption: 'USER_ENTERED',
				data: [{
					"range": formName,
					"majorDimension": "DIMENSION_UNSPECIFIED",
					"values":newArrr ,
				}], 
			};

			var updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params, batchUpdateValuesRequestBody);
			updateRequest.then(
			response => {
				alert('Data Saved Successfully');
			}, function(reason) {
				alert(reason.result.error.message);
			});
			
		}, function(reason) {
			alert(reason.result.error.message);
		});
	} */
	
    handleSubmitSave = () => {
        
        let rowId = '';
        let parentId = '';
        let conditionalId = '';
        let config_position = '';
        let conditionalOption = '';
        let position ='';
        let guid ='';
        let kiosk ='';
        let include ='';
        let sheetsName ='';
        let required = '';        
        let newPositions = this.state.formData;
        let data = [];
		//let spreadsheetId = config.spreadsheetId;
		let spreadsheetId = this.props.sheetConfigId;
		let selectedSheet = this.props.selectedSheet;
		if(newPositions){
			{ newPositions.map((val, i) => {
				let userTypes = this.state.kioskUsersData.get(val[0])
                if(userTypes == undefined){
					let oldP = val[16];
					let userArray = oldP.split(',');
					userArray.splice(0, 9);
					userTypes = userArray.toString();
                }
			
				//if(val['updated']){
					rowId = (i+2);
					parentId = val[2];
					conditionalId = val[7];
					conditionalOption = val[8];
					position = val[10];
					guid = val[35] ? val[35] : val[91];
					//kiosk = val[37] ? val[37] : '';
					include = val[14];
					required = val[15];
					
					let kioskArr = this.state.checkedKiosk;
					let kiosk ='N';
					if(kioskArr.get(val[0]) == true){
						kiosk = 'Y';
					}
					
					let parentGuid = '';
					newPositions.map((pre_field,i)=>{
						if(pre_field[1].toLowerCase() == val[2].toLowerCase()){
							parentId = pre_field[1];
							parentGuid = pre_field[117];
						}
					})
					
					config_position = val[0] + ','+ guid +','+ parentId +','+ conditionalId +','+ conditionalOption +','+ include +','+ required +','+ position+','+ kiosk+','+userTypes;
					let row1 = {
					  range: selectedSheet+'!AI'+rowId, 
					  values: [
						[config_position]
					  ]
					}
					let row2 = {
					  range: selectedSheet+'!AD'+rowId,
					  values: [
						[parentId]
					  ]
					}
					let row3 = {
					  range: selectedSheet+'!AF'+rowId,
					  values: [
						[position]
					  ]
					};
					
					/* let row4 = {
					  range: selectedSheet+'!L'+rowId,
					  values: [
						[parentGuid]
					  ]
					}; */
					
						
					data.push(row1);
					data.push(row2);
					data.push(row3);
					//data.push(row4);
					
				//}
				
			})}
		}
		
		
			
		let accessToken = gapi.auth.getToken().access_token;
		
		this.setState({accessToken:accessToken});
		let params = {
			access_token:accessToken,
			spreadsheetId: spreadsheetId,
		};
		let batchUpdateValuesRequestBody = {
			valueInputOption: 'USER_ENTERED',
			data: data
		};
		var that = this;
		let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
		request.then(function(response) {
			alert('Data Saved Successfully');
				
		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});

    }
	
    checkMove = (val) => {
        $('.move-input').each(function () {
            
            if(val != $(this).val()){
                $(this).prop("checked", false);
            }
        });
        
		if(!this.state.formData){
			this.setState({formData:this.props.allData});
		}
        
        $('.move-bottons').css('display', 'block');
    }
    
	findChild(data, obj, part, movedwith=false){
        let ob = {id:data[0], position:$('#'+data[0]).attr('data-pos'), pId:data[2], header:data[5]};
        if(movedwith){
            obj.movedWithData.push(ob);
        }
        else{
            obj.movedData.push(ob);
        }
        
        if(part == 2){
            return;
        }
        
        if(part == 3 && !movedwith){
            return;
        }
        
        if(data.sub){
            for(var i in data.sub){
                this.findChild(data.sub[i], obj, part, movedwith);
            }
        }   
    }
	
    setPosition = (data, start_position, obj, preId, pId, part) => {
        let pos = 0;
        let positionId = '';
        let changePos = this.state.allPos;
        { data.map((md, i) => {
            pos = start_position + i;
            positionId = md['pId'];
            let ob2 = {id:md['id'], position:pos, pId:md['pId'], header:md['header']};
            obj.push(ob2);
            $('#'+md['id']).attr("data-pos",pos);
            if(part == 3){
                let customArray = ["Child Header 1","Child Header 2","Child Header 3","Child Header 4"];
                
                if (pId && $.inArray(md['header'], customArray)!='-1') {
                    positionId = pId;
                    pId = '';
                }
            }
            if(pId){
                positionId = pId;
            }
            if(md['id'] == preId){
                positionId = md['pId'];
            }
            changePos[md['id']] = {pos:start_position + i, pId:positionId};
            
        })}
        
        this.setState({allPos:changePos});
        return pos;
    }
	
    movePostion = (type) => {
        
        let pId, allPos, preId, elementId, tempPos, part = 1, preDiv2, innerLength = 1, header, header2, preItem;
        
        $('.move-input').each(function () {
            
            if($(this).prop("checked") == true){
                
                elementId = $(this).val();
                
                let parentId = $('#'+elementId).parent().attr('id');
            
                let itemlist = $('#'+parentId);
        
                let len = $(itemlist).children().length -1;
				
                let selected = $("#"+elementId).index();
				
				header = $("#"+elementId).attr('data-header');
				
                if(type == 'down')
                {
                    preItem = $(itemlist).children().eq(selected+1).attr('id');
					header2 = $("#"+preItem).attr('data-header');
					
					if((selected < len && header && header <= header2) || (selected < len && !header && !header2) || (selected < len && header == 1) )
                    {
                        preId = $(itemlist).children().eq(selected+1).attr('id');
						
						$($(itemlist).children().eq(selected+1)).after($($(itemlist).children().eq(selected)));
                        selected=selected+1;
						
                    }else{
						
                        part = 2;
                        if(header){
                            part = 3;
                            let inner = $("#"+elementId+" .childDiv").length;
							innerLength = Number(innerLength) + inner   
                        }
                        let id1 = Number($(itemlist).children().eq(selected).attr('data-pos')) + Number(innerLength);
                        let id2 = Number(id1) + 1;
                        preDiv2 = $("[data-pos="+id2+"]").attr('id');
						preId = $("[data-pos="+id1+"]").attr('id');
						
						if(preId){
							
                            if(!$('#'+preId).hasClass( "not-include" ) && $('.inner-'+preId).is(":hidden")){
								$(".headers-"+preId).trigger( "click" );
							}
								
                            if(preDiv2){
                                pId = $('#'+preDiv2).parent().attr('id');
								if(!$('#'+pId).hasClass( "not-include" )){
									$('#'+pId).collapse('show');
								}
                                $("#"+preDiv2).before($("#"+elementId));
                            }else{
								$('#'+preId+" .childMain").append($("#"+elementId));
							}
                        }
                    }
                    
                }else{
					
                    //preItem = $(itemlist).children().eq(selected-1).attr('id');
					//header2 = $("#"+preItem).attr('data-header');
					if(selected > 0)
                    {
                        preId = $(itemlist).children().eq(selected-1).attr('id');
						$($(itemlist).children().eq(selected-1)).before($($(itemlist).children().eq(selected)));
                        selected=selected-1;
                        
                    }else{
						
                        part = 2
                        let innerLength = 1;
                        header = $("#"+elementId).attr('data-header');
						let id1 = Number($(itemlist).children().eq(selected).attr('data-pos')) - 1;
						let id2 = Number(id1) - 1;
						
						preDiv2 = $("[data-pos="+id2+"]").attr('id');
						
						if(header){
                            part = 3
                            let elm = document.getElementById($("[data-pos="+id2+"]").attr('id'));
                            let parent;
                            let find = false;
                            let x = 0;
                            do {
								if(elm && elm.parentNode){
									let parentNode = elm.parentNode;
									let cls = parentNode.className;
									if(cls != undefined && cls.indexOf('parent-header') != -1){
										preDiv2 = $("[data-pos="+id2+"]").attr('id');
										find = true;
									}
									else if(parentNode && parentNode.getAttribute("data-header") == header){
										find = true;
										parent = parentNode;
									}
									else{
										elm = parentNode;
									}
								}
                                
                                x++;
                                
                            } while (find != true && x < 10);
                            
							if(parent){
								preDiv2 = parent.getAttribute("id");
							}
                        }
						
						let p = $("#"+preDiv2).attr('data-pos');
						let predh = $("#"+preDiv2).attr('data-header');
						
						
						if(preDiv2 && p > 1){
                            
                            preId = $("[data-pos="+id1+"]").attr('id');
							let preIdh = $("#"+preId).attr('data-header');
							
							if(preId){
                                pId = $('#'+preDiv2).parent().attr('id');
                                let pIdDiv = $('#'+pId).parent().attr('id');
								if(pIdDiv && $('#'+pId).is(":hidden") && !$('#'+pIdDiv).hasClass( "not-include" )){
									$(".headers-"+pIdDiv).trigger( "click" );
								}
								
								if((predh && preIdh && !header) || (header > 1 && predh == 'parent') || (header > 2 && preIdh == 2 ) ){
									$("#"+preId).before($("#"+elementId));
								}else{
									$("#"+preDiv2).after($("#"+elementId));
								}
                            }
                        }  
                    }
                }
            }
        });
 
        let formData = this.state.formData;
       
		if(!preId){
            return false;
        }
		
        let obj = {movedData:[], movedWithData:[]}
        
        { formData.map((data, i) => { 
            let field = '';
            if(data[0] == preId){
                if(data.sub){
                    this.findChild(data, obj, part, false);
                }
            }
            
            if(data[0] == elementId){
                this.findChild(data, obj, part, true);
            } 
        })}
        
        
        let movedData = obj.movedData;
        let movedWithData = obj.movedWithData;

        let pos = '';
        let obj2 = {pos1:[], pos2:[]}
        
        if(type == 'up'){
            let start_pos = Number(movedData[0].position);
            let last_pos = this.setPosition(movedWithData, start_pos, obj2.pos1, preId, pId, part);
            this.setPosition(movedData, last_pos+1, obj2.pos2, preId, pId, part);
        }
        else{
            let start_pos = Number(obj.movedWithData[0].position)
            let last_pos = this.setPosition(movedData, start_pos, obj2.pos1, preId, pId, part);
            this.setPosition(movedWithData, last_pos+1, obj2.pos2, preId, pId, part);
        }
        
        let positions = this.state.allPos;
        let posArray = Object.keys(positions).map(function(key) {
          return [key, positions[key]];
        });
        
        let newArr = []; 
        formData.forEach(function(e,i){
            let el = Object.assign([], e);
            let p =  posArray.filter(function(pos) {
                return pos[0] == el[0];
            });
            if(p.length){
                el[10] = p[0][1].pos;
                el[2] = p[0][1].pId;
                el['updated'] = true;
            }
            newArr.push(el);    
        });
    
        this.updateArray(newArr);
        this.setState({formData:newArr});
		
		if($('#'+preId).is(":hidden")){
			this.movePostion(type);
		}else if(type == 'up' && !header && preDiv2 != undefined && $('#'+preDiv2).is(":hidden") && $('#'+pId).is(":hidden")){
			this.movePostion(type);
		}
		/* if( $('#'+preId).is(":hidden") || $('#'+preId).hasClass( "not-include" ) || (!header && preDiv2 != undefined && $('#'+preDiv2).is(":hidden")) ){
			this.movePostion(type);
        } */
        
    }
	
	dataFilterPfbCompany = (event) =>{
		let val = event.target.value;
		let allData = this.props.allData;
		let selOptions = this.state.selOptions;
		if($("input[name='includePFB']").prop("checked") == true){
			
			allData.map((rowDatas, i) => {
				if(val){
					
					if($("input[name='showPFB']").prop("checked") == true){
						
						if(rowDatas[115] != val && rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
							$('#'+rowDatas[0]).addClass("not-include");
						}
						
						if((selOptions == 'view_all' && rowDatas[115] == val && rowDatas[14] == 'Y') || (selOptions == 'required_only' && rowDatas[115] == val && rowDatas[14] == 'Y' && rowDatas[15] == 'Y')){
							$('#'+rowDatas[0]).removeClass("not-include");
						}
						
					}else{
						if(rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
							$('#'+rowDatas[0]).addClass("not-include");
						}
						
						if((rowDatas[14] == 'Y' && rowDatas[111] == 'Y') || (selOptions == 'view_all' && rowDatas[115] == val && rowDatas[14] == 'Y') || (selOptions == 'required_only' && rowDatas[115] == val && rowDatas[14] == 'Y' && rowDatas[15] == 'Y')){
							$('#'+rowDatas[0]).removeClass("not-include");
						}
					}
					
				}else{
					if($("input[name='showPFB']").prop("checked") == true){
						
						if((rowDatas[14] == 'Y' && rowDatas[111] == 'N' && selOptions == 'view_all') || (rowDatas[111] == 'N' && selOptions == 'required_only' && rowDatas[15] == 'Y')){
							$('#'+rowDatas[0]).removeClass("not-include");
						}
						
					}else{
						if((rowDatas[14] == 'Y' && selOptions == 'view_all') || (rowDatas[14] == 'Y' && selOptions == 'required_only' && rowDatas[15] == 'Y')){
							$('#'+rowDatas[0]).removeClass("not-include");
						}
					}
				}
			})
			
			this.setState({pfbByCompany:val});
			
		}else{
			this.setState({pfbByCompany:''});
			alert('Please include PFB=N firstly!');
		}
		
		{ allData.map((rowDatas, i) => {
			if(rowDatas[14] == 'Y' && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
				let hasVisible=false;
				$('#'+rowDatas[0]).find('.question').each(function() {
					if(!$(this).hasClass("not-include")){
						hasVisible= true;
					}
				});
				if(hasVisible == false){
					$('#'+rowDatas[0]).addClass("not-include");
				}else{
					$('#'+rowDatas[0]).removeClass("not-include");
				}
			}
		})}
	}
	
	dataFilterPfb = (event, optionVal) => {
		let allData = this.props.allData;
		let selOptions = optionVal;
		if(optionVal == undefined){
			selOptions = this.state.selOptions;
		}
		this.setState({pfbByCompany:''});
		if($("input[name='includePFB']").prop("checked") == true){
			allData.map((rowDatas, i) => {
				
				if((rowDatas[14] == 'Y' && rowDatas[111] == 'N' && selOptions == 'view_all') || (rowDatas[111] == 'N' && selOptions == 'required_only' && rowDatas[15] == 'Y')){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
				
            })
			
			if($("input[name='showPFB']").prop("checked") == true){
				allData.map((rowDatas, i) => {
					if(rowDatas[111] == 'Y' && rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
						$('#'+rowDatas[0]).addClass("not-include");
					}
				})
			}else{
				allData.map((rowDatas, i) => {
					if((rowDatas[14] == 'Y' && rowDatas[111] == 'Y' && selOptions == 'view_all') || (rowDatas[14] == 'Y' && rowDatas[111] == 'Y' && selOptions == 'required_only' && rowDatas[15] == 'Y')){
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				})
			}
        }else{
			allData.map((rowDatas, i) => {
				if(rowDatas[111] == 'N'){
					$('#'+rowDatas[0]).addClass("not-include");
				}
				if((rowDatas[14] == 'Y' && rowDatas[111] == 'Y' && selOptions == 'view_all') || (rowDatas[14] == 'Y' && rowDatas[111] == 'Y' && selOptions == 'required_only' && rowDatas[15] == 'Y')){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
            })
			$("input[name='showPFB']").prop( "checked",false );
			this.setState({pfbByCompany:''});
		}
		
		{ allData.map((rowDatas, i) => {
			if(rowDatas[14] == 'Y' && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
				let hasVisible=false;
				$('#'+rowDatas[0]).find('.question').each(function() {
					if(!$(this).hasClass("not-include")){
						hasVisible= true;
					}
				});
				if(hasVisible == false){
					$('#'+rowDatas[0]).addClass("not-include");
				}else{
					$('#'+rowDatas[0]).removeClass("not-include");
				}
			}
		})}
		
	}
	
	dataFilter=(event)=>{
        let optionVal = event.target.value;
		this.setState({selOptions:optionVal});
        let allDatas = this.props.allData;
        this.setState({pfbByCompany:''});
        if(optionVal == 'view_all'){
			{ allDatas.map((rowDatas, i) => {
				if(rowDatas[14] == 'Y' && rowDatas[111] == 'Y'){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
			})}
			//$('.comments').find('.not-include'). removeClass("not-include");
        }else if(optionVal == 'required_only'){
			
			{ allDatas.map((rowDatas, i) => {
				
				$('#'+rowDatas[0]).addClass("not-include");
				
				if(rowDatas[14] == 'Y' && rowDatas[15] == 'Y' && rowDatas[111] == 'Y'){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
				
				if(rowDatas[14] == 'Y' && rowDatas[111] == 'Y' && $('#'+rowDatas[0]).find('.req-only').length > 0 && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
					$('#'+rowDatas[0]).removeClass("not-include");	
				}

			})}
			
			/* setTimeout(function(){
				$('.req-only').removeClass("not-include");
			}, 1000); */	
            
        }
		
		if($("input[name='includePFB']").prop("checked") == true){
			this.dataFilterPfb(event, optionVal);
		}else{
			
			{ allDatas.map((rowDatas, i) => {
				if(rowDatas[14] == 'Y' && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
					let hasVisible=false;
					$('#'+rowDatas[0]).find('.question').each(function() {
						if(!$(this).hasClass("not-include")){
							hasVisible= true;
						}
					});
					if(hasVisible == false){
						$('#'+rowDatas[0]).addClass("not-include");
					}else{
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				}
			})}
			
		}
    }
	
	addCompany=(event)=>{
        this.setState({addCompany:event.target.value});
	}
	
	addNewCompany=()=>{
		let companyName = this.state.addCompany;
		let spreadsheetID = config.spreadsheetId;
		
		if(companyName){
			var params = {
				spreadsheetId:  spreadsheetID,
				range:'Company Name!A:A', 
				valueInputOption: "RAW", 
				insertDataOption: "INSERT_ROWS",
				resource:{
					"values": 
					[[companyName]],
					"majorDimension": "ROWS"
				  }
			};
			let that = this;
			var request = gapi.client.sheets.spreadsheets.values.append(params);
			request.then(function(response) {
				that.setState({newCompanyName:companyName});
				alert('Company added successfully!');
				$('#addComModal').modal('hide');
			}, function(reason) {
			console.error('error: ' + reason.result.error.message);
			});
		}
	}
    
    
    render(){
        const { QuestionId,Location,queType,showReword,showPreDiv,showPreCon,changeQformat,checkedKiosk, showImageBtn,moduleName,rowAllData, newCompanyName}= this.state;
        const { sheetData,allData,description,selectedSheet,selectedSheetId, formatSheet_Id,showDataDiv,setKiosk,handleHeaderFilter,clearAllFilter,kioskResponse, assoType,assoVisitType,assoDeviceType, getDirectoryFiles, apiPdfs, companies} =this.props;
		
		let kioskType = [];
		if(allData.length > 0){
			kioskType = allData[0][119].split(',');
		}
		
		const Companydropdown = () =>{
			let companyName = [];
			if(allData){
				allData.map(function(val,i) {
					if(val[111] == 'N' && val[115]){
						companyName.push(val[115]);
					}
				})
			}	
			
			var uniqueNames = [];
			$.each(companyName, function(i, el){
				if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
			});
			
			var optionsHtml = uniqueNames.map(function(val,i) {
				
				return (
					<option value={val} key={i}>{val}</option>
				);
				
			})
			return <select className="form-control" value={this.state.pfbByCompany} onChange={this.dataFilterPfbCompany} >
					<option value=""> Please Select</option>
					{optionsHtml}
				</select>
			
        }

		
        return(
            <div className="card-body">
            {setKiosk ? null :
            <Header 
                reviewSheetsName= {this.props.reviewSheetsName}
                reviewVer={this.props.reviewVer}
                sheetsName = {this.props.sheetsName}
				mdsGuid={this.props.mdsGuid}
				selectReviewOpt = {this.props.selectReviewOpt}
                selectedOpt = {this.props.selectedOpt}
                selectedSheet1 ={this.props.selectedSheet1}
                handleHeaderFilter={handleHeaderFilter}
                clearAllFilter ={clearAllFilter}				
                onSelect={this.props.onSelect}
                sheetData = {sheetData}
				allData = {allData}
				handleClearTempSave = {this.props.handleClearTempSave}
				showDataDiv = {this.props.showDataDiv}
                moduleName={moduleName}
                hasModify={this.props.hasModify}
                selectedSheetId = {selectedSheetId}
                selectedSheet = {selectedSheet}
				selOptions = {this.state.selOptions}
				dataFilter = {this.dataFilter}
				dataFilterPfb = {this.dataFilterPfb}
				Companydropdown={<Companydropdown/>}
				assoType = {assoType}
                assoVisitType ={assoVisitType}
                assoDeviceType ={assoDeviceType}
			/>
			}	
            {sheetData && showDataDiv ? 
                <div className="card-body1">
                <div className='move-bottons' style={{display:'none'}}>
                    <p onClick = {() => this.movePostion('up')}><i className="fa fa-arrow-up"></i></p>
                    <p onClick = {() => this.movePostion('down')}><i className="fa fa-arrow-down"></i></p>
                </div>
                {setKiosk ? null :
                <div className="row">
                    <div className="col-md-6">
                        <KioskDrop 
                            selectKioskUsers={this.selectKioskUsers}
                            kioskType={kioskType}
                            kioskUsers = {this.state.kioskUsers}
                        />
                    </div>
                    <div className="col-md-2">
                        <button type="button" className="btn btn-primary" onClick={this.showPreview}>Preview </button>
                    </div>
                </div>}
                <br/>
                <div className="row">
                    <div className="col-md-1">
                        Select
                    </div>
                    <div className="col-md-1">
                        kiosk
                    </div>
                    <div className="col-md-4 offset-6">
                        {setKiosk ? 
                            <button className="btn btn-primary" style={{float:'right'}} onClick={()=>this.props.showKioskModal(this.state.checkedKiosk)}>End Kiosk Mode</button>
                        :
                            
                            <button className="btn btn-primary" style={{float:'right'}} type="button" data-target="#myModal" data-toggle="modal">Kiosk Mode </button>
                            
                        }  
                    </div>
                </div>
                <hr/>
                <MainData 
                    sheetData = {this.props.sheetData}
                    moduleName="format_forms"
                    myFunction= {this.myFunction}
                    checkMove = {this.checkMove}
					selectedOpt = {this.props.selectedOpt}
					allData = {allData}
                    getDirectoryFiles={getDirectoryFiles}
                    kioskResponse={kioskResponse}
                    showSign={this.props.showSign}
                    feedsResponse={this.state.feedsResponse}
                    checkedReq={this.state.checkedReq}
					selDropOpt = {this.props.selDropOpt}
					selectedSheet = {selectedSheet}
					apiPdfs = {this.props.apiPdfs}
                    setKiosk = {this.props.setKiosk}
                    selectKioskUsers={this.selectKioskUsers}
                    saveKioskUsersType = {this.saveKioskUsersType}
                    kioskUsers = {this.state.kioskUsers}
                    kioskUsersData={this.state.kioskUsersData}
                    checkedKiosk ={checkedKiosk}
                    handleKiosk = {this.handleKiosk}
                    showAddUsersModal = {this.showAddUsersModal}
					//movePostion = {this.movePostion} 
                    />
                
				<div className="row">
					<div className="col-md-12">
						<Button type="button" className="float-left" data-toggle="modal" data-target="#SaveSheetName" onClick={()=>this.props.updatedData()}>Export New Data </Button>
						<Button type="button" className="float-right" onClick={this.handleSubmitReset} >Reset</Button>
						<Button type="button" className="float-right" onClick={this.handleSubmitSave} >Save </Button>
					</div>
				</div>
                </div>
                
            :null}
				<div className="modal" id="SaveSheetName">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Enter Sheet Name</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<div className="col-md-12">
                                <label className="label-control"> Enter a sheet name </label>
                                <input type="text" name="setSheetName" value ={this.state.setSheetName} onChange={this.getValue} maxLength={20} className="form-control"/>
								</div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-6">
											<div className="row">
											<div className="col-md-10">
											<label className="label-control"> Select a Facility ID </label>
											<select className="form-control facility-id" name="setFacilityID" onChange={this.exportFacilityID}>
												<option value="">Please Select</option>
												{companies.map((company, i) => (
													<option value={company}>{company}</option>
												))}
												{newCompanyName ? 
												<option value={newCompanyName}>{newCompanyName}</option>
												:''}
											</select>
											</div>
											<div className="col-md-2 add-company-export">
												<button type="button" data-target="#addComModal" data-toggle="modal" className="btn btn-primary mb-2">
													<i className="fas fa-plus"></i>
												</button>
											</div>
											</div>
										</div>
										<div className="col-md-6">
										<label className="label-control"> Enter a Group ID </label>
										<input type="text" name="setGroupID" value ={this.state.setGroupID} onChange={this.exportsetGroupID} maxLength={9} className="form-control facility-id"/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=> this.exportSheetData(this.state.setSheetName,this.state.setFacilityID,this.state.setGroupID)} data-dismiss="modal">Save</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal" id="code" style={{display: "none"}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="col-md-12">
                                    <div className="row">
                                        <h5>Field Name: {QuestionId}</h5>
                                    </div>
                                    {<div className="row">
                                        <p><b>Validation Type:</b> {this.state.valTypeText}</p>
									</div>}
                                   <div className="row">
								   {/*<p><b>Type:</b> {queType ? this.state.queType+' - '+ this.state.queDescription : null} </p>&nbsp;&nbsp;*/}
                                        <p><b>Type:</b> {queType ? this.state.queType : null} </p>&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="mb-0"><b>Preconditions:</b>  {this.state.preCondition} {showPreCon ? (<div> <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={() => showParentField(this.state.preCondition,this.props.allData,this.generateQuestion)} data-target="#showMainField">{this.state.showFieldText? 'Show Field' : 'Hide Field'}</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => this.preConFunc(this.state.preCondition) }> Edit Precondition</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => { removePrecondition(this.props.allData,this.state.QueSaveId,this.props.selectedSheet,this.props.updatedData)}}> Remove Precondition</button> </div> ):<button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.preConFunc()}> 
                                            Add Precondition</button>}</div>
                                    </div>
                                </div>
                                <hr/>
                                {showPreDiv ? ( 
                                <div className="col-md-12">
                                   
                                    <div className="col-md-12">
                                        {this.displayAddData}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Field Name:</label>
                                                <Dropdown1 
                                                    mainQueKey={this.props.mainQueKey}
                                                    queNo={this.state.queNo1} 
                                                    addPrecondition={this.addPrecondition}/>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Conditional Option:</label>
                                                <textarea className="form-control" rows="4" col="4" id="comment" value ={this.state.preCondVal} name="text" readOnly></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>):null}
                                
                                <div>
                                    <p><b>Field Input / Output Preview</b></p>
									<div className="output-pre" id="display-data-Container">
										<div>
										<h5>{this.state.Question}</h5>
										</div>
										<div>
											<p>{this.state.Answer}</p>
										</div>
										<p><b>Output Text:</b> { this.state.Location }</p>
									</div>
									
                                    <div className="col-md-12 form-group">
                                        <label>Additional Notes:</label>
                                        <p>
                                            { ReactHtmlParser(this.state.addNoteText) }
                                        </p>
                                    </div>
                                    <div className="col-md-12 form-group">
                                         <div className="col-md-8 form-group">
                                            <label>{'<<Tags>> :'}</label>
                                            <input type="text" className="form-control" name="addTagsText" defaultValue={this.state.addTagsText}/>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary float-left" type="button" onClick={this.handleSubmit} style={{textAlign:'left'}} >Save </button>
                                <button type="button" className="btn btn-danger"  data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="myModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Do you want to go Kiosk Mode ?</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <label>Select Users Class</label>
                            { sheetData ?
                            <KioskDrop
                                multiple ='false'
                                selectKioskUsers={this.selectKioskUsers}
                                kioskType={kioskType}
                                kioskUsers = {this.state.kioskUsers}/>:null}
                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-primary float-left" onClick = {()=>this.props.goKisko(this.props.allData,this.state.kioskUsers,this.state.selOptions, this.props.selectedSheet1)} >Ok</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                          </div>

                        </div>
                    </div>
                </div>
				
				<div className="modal add-company" id="addComModal">
                    <div className="modal-dialog modal-lg custom-modal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Add New Company</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
								<input type="text" name="addCompany" onChange={this.addCompany} className="form-control" placeholder="Enter company name"/>
                          </div>
                          <div className="modal-footer">
                                <div className="image-text" id="fileText"></div>
                                <div className="popup-btn-com">
                                    <button type="button" className="btn btn-primary float-left" onClick={this.addNewCompany}>Save</button>
                                    <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                                </div>
                          </div>

                        </div>
                    </div>
                </div>
				
                <div className="modal" id="selectUsersType">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content kioskPreview">
                            <div className="modal-header">
                                <h4 className="modal-title">Kiosk Mode Preview</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                            { sheetData ? 
                               <KioskData 
                                    sheetData = {sheetData}
                                    checkedReq = {this.state.checkedReq}
                                    kioskPreviewData = {this.state.kioskPreviewData}
                                    moduleName={moduleName}
                                    allData = {allData} 
                                    kioskResponse={kioskResponse}
                                    />
                            : null }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" style={{textAlign:'left'}}>Ok </button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FormatForm;