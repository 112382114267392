import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import $ from 'jquery';
import 'bootstrap';
import "../../containers/App.css";
import { Form,Button } from 'react-bootstrap';
import config from "../../config";
import { gapi } from 'gapi-script';
import guid from "../../Helpers/GUID";
import axios,{post,get} from 'axios';
import {DebounceInput} from 'react-debounce-input';
import { getSheetTypeFacility, getSheetApiName } from '../../Helpers/Common';

class JsonFiles extends Component{
	constructor(props){
        super(props)
        this.state ={
            sheetsName:[],
			csvdata: [],
			companies: [],
			csvdataConfig: [],
			mdsCsvName:'',
			fileDescription:'',
			sheetId:'',
			sheetName:'',
			token:'',
			files:[],
        }
		this.csvLink = React.createRef();
		this.csvLinkConfig = React.createRef();
    }
	
	componentDidMount(){
		
		let url = config.API_PDF_URL+'/login';
		let formData = new FormData();
		formData.append('username', config.API_username);
		formData.append('password', config.API_password);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			let token = response.data.token;
			this.setState({ token });
			
			let url = config.API_PDF_URL+'/jsons?type_id=10';
			axios({
				method: 'GET',
				url: url,
				headers: {
					'Authorization': 'Bearer '+token
				}
			}).then(response => {
				let files = response.data.data.files;
				this.setState({ files });
			}).catch(error => {
				console.log('error::', error);

			})
			
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	
	filterSheets=(event)=>{
        let sheetName = event.target.value;
		if(sheetName == 'Please Select'){
			$( ".json-list-tr" ).addClass('show');
		}else{
			$( ".json-list-tr" ).removeClass('show');
			$( ".json-list-tr" ).addClass('hide');
			$( ".json-list-tr" ).each(function( index ) {
				let txt = $(this).attr('data-name');
				let catId = $(this).attr('data-cat');
				console.log('txt',txt);
				console.log('catId',catId);
				if(txt.indexOf('_') != -1){
					txt = txt.split("_");
				}
				if(catId == 46){
					if(txt[1].indexOf('-') != -1){
						txt = txt[1].split("-");
					}
				}
				if(txt[1] && sheetName){
					sheetName = sheetName.toLowerCase();
					let sheetName2= txt[1].toLowerCase();
					if(sheetName == sheetName2){
						$(this).removeClass('hide');
						$(this).addClass('show');
					}
				}
			});
		}
    }
	
	filterSheetsCat=(event)=>{
        let fileCat = event.target.value;
		if(fileCat == 'Please Select'){
			$( ".json-list-tr" ).removeClass('not-cat');
		}else{
			$( ".json-list-tr" ).addClass('not-cat');
			$( ".json-list-tr" ).each(function( index ) {
				let catId = $(this).attr('data-cat');
				if(fileCat == catId){
					$(this).removeClass('not-cat');
				}
			});
		}
    }
	
	download(content, fileName, contentType) {
		const a = document.createElement("a");
		const file = new Blob([content], { type: contentType });
		a.href = URL.createObjectURL(file);
		a.download = fileName;
		a.click();
	}
	
	
	downloadJson = (file) => {
		let url = config.API_PDF_URL+'/jsonFile/'+file;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Authorization': 'Bearer '+this.state.token
			}
		}).then(response => {
			let data = response.data;
			if(data){
				let newFile = file.replace('.enc','')
				this.download(JSON.stringify(data), newFile, "text/plain;charset=utf-8");
			}
		}).catch(error => {
			console.log('error::', error);

		})
	}
	
	importJson = (file,name,type) => {
		alert(name);
		
		let url = config.API_PDF_URL+'/jsonFile/'+file;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Authorization': 'Bearer '+this.state.token
			}
		}).then(response => {
			let data = response.data;
			console.log('data->',data);
			if(data){
				//let newFile = file.replace('.enc','')
				//this.download(JSON.stringify(data), newFile, "text/plain;charset=utf-8");
			}
		}).catch(error => {
			console.log('error::', error);

		})
		
	}
	
	render(){
		const { sheetsName, companies, files} = this.state;
		var optionsSheet = ''
		let sheets = this.props.sheetsName;
		if(sheets &&  files.length > 0){
            sheets.map(function(sheet,i) {
				files.map(function(file,i) {
					let singleSheet = file.name;
					if(singleSheet.indexOf('_') != -1){
						singleSheet = singleSheet.split("_");
					}
					if(file.category_id == 46){
						if(singleSheet[1].indexOf('-') != -1){
							singleSheet = singleSheet[1].split("-");
						}
					}
					if(sheet.name == singleSheet[1]){
						$(".filter-sheet option[value='"+sheet.name+"']").addClass('hasData');
					}
				})
            })
        }
		
		return(
			<div className="card-body manage-forms">
			<div className="row">
					<div className="col-md-4">
						<div className="form-group">
							<label> Clinical Form </label>
							<Form.Control className="filter-sheet" as="select" onChange={this.filterSheets}>
								<option className="hasData"> Please Select</option>
								{this.props.sheetsName.map((sheet, i) => (
									<option value={sheet.name}>{sheet.name}</option>
								))}
							</Form.Control>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group">
							<label> Category </label>
							<Form.Control className="filter-sheet-cat" as="select" onChange={this.filterSheetsCat}>
								<option> Please Select</option>
								<option value="45">MDS</option>
								<option value="46">Config File</option>
							</Form.Control>
						</div>
					</div>
			</div>
				{ files.length > 0 ?
				<table className="table json-list">
				  <thead className="thead-light">
					<tr>
					  <th scope="col">File Name</th>
					  <th scope="col">Description</th>
					  <th scope="col">Type</th>
					  <th scope="col">Category</th>
					  <th style={{width:'120px'}} scope="col"></th>
					</tr>
				  </thead>
				  <tbody>
					
						{files.map((file, i) => {
							return(<tr className="json-list-tr" data-name={file.name} data-cat={file.category_id}>
								  <th scope="row">{file.name}</th>
								  <td>{file.description}</td>
								  <td>{file.type}</td>
								  <td>{file.category}</td>
								  <td><a onClick={() => { this.importJson(file.file, file.name, file.type) } }><i className="fas fa-file-import api-json"></i></a><a onClick={() => { this.downloadJson(file.file) } }><i className="fa fa-download api-json" aria-hidden="true" ></i></a></td>
							</tr>)
						})}

				  </tbody>
				</table>
				:null}
			</div>
		);
	}
}

export default JsonFiles;