import React, { Component, PropTypes } from 'react';
import 'bootstrap';
import $ from 'jquery';
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import STLViewer from 'ftn-stl-viewer'
import { generatehtmlfromlocation,handleAuthResult,saveConfigFile } from "../../Helpers/Common";
import { tagHtml,noteHtml,knoteHtml,noteModal,knoteModal,showImageModal,attentHTML,includeModal,addUsersHTML,addUsersModal } from "../../component/ReviewApprove/Modal";
export default class ViewKioskData extends Component {
    constructor(props){
        super(props)
        this.state = {
            checkedNeeds:new Map(),
            checkedApprove:new Map(),
        }
    }
    classTypes(comment){
        let notInclude = '';
        let parentHeader = '';
        if(comment[5] !== 'Parent Header' && comment[14] == 'N' && this.props.moduleName == 'format_forms'){
            notInclude = 'not-include';
        }
        if(comment[36] == 'Deleted'){
            notInclude = 'not-include';
        }
        if(comment[5].toLowerCase() == 'parent header'){
            parentHeader = ' parent-header ';
        }
		if(comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3' && comment[5].toLowerCase() != 'title' && comment[5].toLowerCase() != 'text'){
            
			if(comment[15] == 'Y'){
				parentHeader = ' question req-only';
			}else{
				parentHeader = ' question';
			}
			
        }
		
		if(comment[5].toLowerCase() == 'title'){
			parentHeader = ' hide-title ';
		}
		
        let className = comment[0]+' childDiv ' + notInclude + parentHeader;
        if(comment[7] && comment[8]){

            let combVis   = comment[7]+'-'+comment[8].toLowerCase();
            let visibility1= combVis.replace(/[^A-Z0-9]+/ig, "");
            //className = comment[7]+" collapse "+visibility1+ " pre-question " + className; 
        }
		setTimeout(function(){
			
			if($('.'+comment[0]).find('.question').length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
				$('.'+comment[0]).addClass("not-include");
			}else{
				$('.'+comment[0]).removeClass("not-include");
			}
			
			if(comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3' && comment[5].toLowerCase() != 'title' && comment[5].toLowerCase() != 'text'){
				$("#"+comment[0]).prev().removeClass('hide-title');
			}
			
		}, 2000);
		
        return className;
    }
    
    headerTypes(comment){
        let header = '';
        switch (comment[5].toLowerCase()) {
            case 'parent header':
                header = 'parent';
                break;
            case 'child header 1':
                header = 1;
                break;
            case 'child header 2':
                header = 2;
                break;
            case 'child header 3':
                header = 3;
                break;
            default:
                header = 'q';                   
        }
        return header;
    }
	
	toggle(id){
		if(!$('#parent'+id.toLowerCase()).prop('disabled')){
			$('#parent'+id.toLowerCase()).find('i:first').toggleClass('fa fa-plus-square-o fa-1x mr-2  fa fa-minus-square-o fa-1x mr-2');
		}
		$('#parent'+id.toLowerCase()).prop('disabled', true);
		setTimeout(function(){
			$('#parent'+id.toLowerCase()).prop('disabled', false);
		}, 1000);
    }

    subQuestions = (value,parent,id) => {
        let combVis1   = id+'-'+value.toLowerCase();
        let newString  = combVis1.replace(/[^A-Z0-9]+/ig, "");
        var divClass  = $("."+newString).attr('class');
        let subQustionData  = this.props.allData;
        let ids = [];
        $("."+id).each(function( index) {
            var ids= $(this).attr('id');
            $('.'+ids).addClass(id);
        });
        $("."+id).collapse('hide');
		
        //$("."+id+" .answer").find("input:radio:checked").prop('checked',false);
        //$("."+id+" .answer").find("option:selected").prop('selected',false);
        //$("."+id+" .answer").find("input:checkbox:checked").prop('checked',false);
        //$("."+id+" .answer").find("input:text").val('');
		
        subQustionData.map((answers, i) => {
            let combVis   = answers[7]+'-'+answers[8].toLowerCase();
            let visibility1= combVis.replace(/[^A-Z0-9]+/ig, "");
            if(visibility1 == newString) {
                $("."+newString).collapse('show');
            }
        })
    }
    
    showStar(comment){
        let iTag
        this.props.allData.map((valu, i) => {
            if(comment[0] === valu[7] &&  comment[7]){
                iTag = <span><i className="fa fa-star" style={{fontSize:'20px',color:'yellow'}} data-toggle="modal" data-target={"#kshowStarModalYellow"+comment[0]}></i><i className="fa fa-star" style={{fontSize:'20px',color:'blue'}} data-toggle="modal" data-target={"#kshowStarModalBlue"+comment[0]}></i></span>
            }else if(comment[0] === valu[0] && comment[7] === valu[7] && comment[8]){
                iTag = <i className="fa fa-star" style={{fontSize:'20px',color:'blue'}} data-toggle="modal" data-target={"#kshowStarModalBlue"+comment[0]} ></i>
            }else if(comment[0] === valu[7]){
                iTag = <i className="fa fa-star" style={{fontSize:'20px',color:'yellow'}} data-toggle="modal" data-target={"#kshowStarModalYellow"+comment[0]} ></i>
            }
        })
        return iTag
    }
    
    getData(comment){
        var questions ='';
        var answerType = '';
        var options = '';
        var id = comment[1]
        var location = comment[9];
        var parent = comment[2]
        questions = comment[4];
        var visibility1 = comment[8];
        var visibility = comment[7];
        var action  = comment[17];
        if(this.props.selectedOpt== 'improved' && action =='changeQformat'){
            questions =comment[23] ? comment[23] :comment[4];
            answerType = comment[24] ? comment[24] :comment[5];
            options = comment[22] ? comment[22] :comment[6];
        }else{
            answerType = comment[5];
            options = comment[6];
        }
        var field_id = comment[0];
		var label = comment[41];
        let rowAllData = [id,location,parent,questions,answerType,options,visibility1,visibility,field_id,label];
        return rowAllData;   
    }
    render() {
        const { kioskPreviewData,sheetData,queId,comment,moduleName,selectedOpt,allData, checkedReq, kioskResponse,userClassResponse} = this.props
		console.log('sheetData->',sheetData);
		return (
          <div id={queId ? queId: ''} className={queId ? 'inners-'+ comment[0] +' childMain col-md-11 offset-1 collapse '+queId : 'comments '} >
			
			{sheetData.map((comment,i) => {
				let res = kioskResponse.get(comment[0]);
				
				return( <div id={comment[0]} data-pos={comment[10]} key={comment[0]} data-header={this.headerTypes(comment)} className={this.classTypes(comment)}>
                {(() => {
                    let rowAllData=this.getData(comment,selectedOpt);
                    let answer = '';
                    let question = '';
                    if(selectedOpt == 'improved'){
                        let action = comment[17];
                        if(action === 'changeQformat'){
                            question = comment[23] ? comment[23] : comment[4];
                            answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
                        }else{
                            question = comment[4];
                            answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
                        }
                    }else{
                        question = comment[4];
                        answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
                    }
                    
                    switch (comment[5].toLowerCase()) {
                        case 'parent header':
                            return <h3 data-target={'.'+comment[1].toLowerCase()} id ={'Parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} onClick={this.toggle.bind(this,comment[1])} className={'headers-'+comment[0]}> <i className="fa fa-plus-square-o fa-1x mr-2"></i>{ question } {this.showStar(comment)}</h3>;
                        case 'child header 1':
                            return <div className="child-headers">
                                        <div className="row">
                                            <div className="col-md-8 offset-1">
                                                <h4 data-target={'.'+comment[1].toLowerCase()} id ={'Parent'+comment[1].toLowerCase()} onClick={this.toggle.bind(this,comment[1])} data-toggle="collapse" className={'headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>
                                                    {question}{this.showStar(comment)}
                                                </h4>
                                            </div>
                                            
                                        </div>
                                    </div>
                        case 'child header 2':
                            return <div className="child-headers">
                                    <div className="row">
                                            <div className="col-md-8 offset-1">
                                                <h5 data-target={'.'+comment[1].toLowerCase()} id ={'Parent'+comment[1].toLowerCase()} onClick={this.toggle.bind(this,comment[1])} data-toggle="collapse" className={'headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question}{this.showStar(comment)}</h5>
                                            </div>
                                            
                                    </div>  
                            </div>;
                        case 'child header 3':
                            return <div className="child-headers">
                             <div className="row">
                                    <div className="col-md-8 offset-1">
                                        <h6 data-target={'.'+comment[1].toLowerCase()} id ={'Parent'+comment[1].toLowerCase()} onClick={this.toggle.bind(this,comment[1])} data-toggle="collapse" className={'headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question}{this.showStar(comment)}</h6>
                                    </div>
                                    
                                </div>
                            </div>;
                        case 'image' :
                            return <div>
                                <div className="row">
                                    <div className="col-md-3"></div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-9' : 'col-md-6'}>
                                        { comment[99] == '' ?
                                            <h5><b> <img src={comment[99]} width="259px" height="200px"/> {this.showStar(comment)} { comment[15] == 'Y'|| checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</b></h5>
                                        : <div className="col-md-12"><img src={comment[99]} width="259px" height="200px"/> {this.showStar(comment)}{ comment[15] == 'Y'|| checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>}
                                            
                                    </div>
                                    
                                </div>
                                <div className="row">
                                        <div className="col-md-12">
                                            <div className="row"> 
                                                <div className={moduleName == 'test_forms' ? "col-md-12 answer" : "col-md-9 offset-md-3 offset-sm-0  answer"} >
                                                    { question }
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                            </div>
						case 'inlinevideo' :
                            return <div>
                                <div className="row">
                                    <div className="col-md-3"></div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-7' : 'col-md-6'}>
										{ ReactHtmlParser(comment[99]) }   {this.showStar(comment)}
                                    </div>
                                </div>
                                <div className="row">
									<div className="col-md-9 offset-3">
										<div className="row"> 
											<div className={moduleName == 'test_forms' ? "col-md-12 answer" : "col-md-9 offset-md-3 offset-sm-0  answer"} >
												{ question }
											</div> 
										</div>
									</div>
								</div>
                            </div>
                        case 'text' :
                                return <div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                    { answer == '' ?
                                                        <div className="col-md-12"><b>{question}</b> {this.showStar(comment)} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                                        : <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                                    }
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row"> 
                                                        <div className="col-md-9 offset-3 answer">
                                                            { answer }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                        case 'title' :
                            return <div>
                                <div className="row">
                                    <div className="col-md-3">
                                        
                                    </div>
                                    <div className="col-md-6">
                                        { answer == '' ?
                                            <h5><b> { question } {this.showStar(comment)} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</b></h5>
                                        : <div className="col-md-12">{question} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>}
                                    </div>
                                    
                                </div>
                                <div className="row">
                                        <div className="col-md-12">
                                            <div className="row"> 
                                                <div className="col-md-9 offset-3 answer">
                                                    { answer }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
						case 'video' :
                            return <div>
                                <div className="row">
                                    <div className='col-md-6 offset-3'>
                                        { question }
                                    </div>
                                    
                                </div>
                                <div className="row">
                                        <div className="col-md-12">
                                            <div className="row"> 
                                                <div className="col-md-9 offset-3 answer">
                                                    { ReactHtmlParser(res) } 
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        case 'file' :
						case 'pdfapi' :
                            let type1= '';
                            let setUrl = '';
                            let getUrl ='';
                            if(typeof res != "undefined" && res != ''){
                                getUrl = res.split(',');
                                setUrl = getUrl[0];
                                type1 = getUrl[0].split('.').pop();
                            }
                            return <div>
                                <div className="row">
                                    <div className='col-md-6 offset-3' >
                                        { question }
                                    </div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                            <div className="row"> 
                                                <div className="col-md-9 offset-3 answer">
                                                    { typeof res != "undefined" ?
                                                    <div>
                                                    { type1.toLowerCase() === 'jpeg' || type1 === 'jpg' ||  type1 === 'png' || type1 === 'gif' ?
                                                            <div id={'fileHtmlDiv'+comment[0]}><img src={""+setUrl+""} width="259px" height="200px"/></div>
                                                        :
                                                     type1 == 'stl' ?
                                                            <div id={'fileHtmlDiv'+comment[0]}>
                                                                <iframe width="100%" height="100%" src={"https://www.viewstl.com/?embedded&url="+setUrl+"&edges=yes&noborder=yes"}></iframe>
                                                            </div>
                                                        :
                                                        <div id={'fileHtmlDiv'+comment[0]}><iframe width="100%" height="100%" src={setUrl}></iframe></div>
                                                    }
                                                    
                                                    <div id={'fileAns'+comment[0]}>
                                                        <b>Name: </b>{getUrl[1]}<br/>
                                                        <b>Description: </b>{getUrl[2]}<br/>
                                                        <b>Date: </b>{getUrl[3]}
                                                    </div>
                                                    </div>
                                                    : <div>
                                                        <div id={'fileHtmlDiv'+comment[0]}></div>
                                                        <div id={'fileAns'+comment[0]}></div>
                                                    </div>
                                                }
                                                </div> 
                                            </div>
                                        </div>
                                </div>
                            </div>
						case 'q5' :
                            return <div>
                                    <div className="row">
                                        <div className="col-md-3">
                                           
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12"><span className="answer">{ answer }</span> {question}{this.showStar(comment)} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                            
                                            </div>
                                        </div>
                                         
                                    </div>
                                </div>
						case 'calculation':
						case 'wcalculation':
							return (
							<div>
								<div className="row">
									<div className="col-md-3"></div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-5 cal-res-box">
												<div className={"cal-res cal-res-" +comment[0] }>{res ? res : ''}</div>
												<div className="user-text">
												{question}
												</div>
											</div>
										
										</div>
									</div>
								</div>
								 
							</div>)
						case 'subjective':
							return (
									<div>
										<div className="row">
											<div className="col-md-3">
											   
											</div>
											<div className="col-md-6">
												<div className="row">
													<div className="col-md-12"><h5>Subjective</h5></div>
													<div className="col-md-12">{question}{this.showStar(comment)} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
												</div>
											</div>
											  
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="row"> 
													<div className="col-md-9 offset-3 answer">
														<textarea className="form-control" rows="10">{ res }</textarea>
													</div>
												</div>
											</div>
										</div>
									</div>)
						case 'assessment':
							return (
									<div>
										<div className="row">
											<div className="col-md-3">
											   
											</div>
											<div className="col-md-6">
												<div className="row">
													<div className="col-md-12"><h5>Assessment</h5></div>
													<div className="col-md-12">{question}{this.showStar(comment)} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
												</div>
											</div>
											  
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="row"> 
													<div className="col-md-9 offset-3 answer">
														<textarea className="form-control" rows="10">{ res }</textarea>
													</div>
												</div>
											</div>
										</div>
									</div>)
						case 'link':
						return (
							<div>
								<div className="row">
									<div className="col-md-3"></div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-12"><a href={comment[78]} target="_blank">{ question } {this.showStar(comment)}  <i className="fas fa-external-link-alt" aria-hidden="true"></i></a></div>
										
										</div>
									</div>
								</div>
								
							</div>)
						case 'begin':
						return (
							<div className="full-width">
							</div>)
						case 'end':
						return (
							<div className="full-width">
							</div>)
						
                        default:
                            return (
                                <div>
                                    <div className="row">
                                        <div className="col-md-3">
                                           
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12">{question}{this.showStar(comment)} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                            
                                            </div>
                                        </div>
                                          
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row"> 
                                                <div className="col-md-9 offset-3 answer">
                                                    { answer }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                    }
                })()}
                {comment.sub 
                    && 
                    <ViewKioskData 
                        sheetData={comment.sub}
                        checkedReq ={checkedReq}
                        kioskResponse={kioskResponse}
                        userClassResponse={userClassResponse}
                        queId={comment[1].toLowerCase()}
                        moduleName={moduleName} 
                        comment= {comment} 
                        allData = {allData}
                        />
                }
				</div>)
            } )}
          </div>
        )
    }
}