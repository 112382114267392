import React, { Component } from 'react';

class NoteTemplate extends Component{
	constructor(props){
		super(props)
	}
	render(){
		return(
			<div>
			NoteTemplate
			</div>
		)
	}
}

export default NoteTemplate;