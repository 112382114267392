import React,{ Component } from 'react';
import { Form,Button } from 'react-bootstrap';

class MainHeader extends Component {
	constructor(props) {
        super(props);
	 	this.state ={

	 	}
    }
   	handleChange(event){
    	this.setState({renderComponent:event.target.value});
    	this.props.getModule(event.target.value);
    }
	
	handleChangeSheet(event){
    	this.setState({renderSheet:event.target.value});
    	this.props.changeSheetId(event.target.value);
    }
	
	logout = () => {
        //localStorage.removeItem('id');
        localStorage.removeItem('username');
        window.location = '/beta';
    }
	
    render(){
        const { setKiosk,kioskUserName } =this.props;
    	return (
        <div className="card-header">
            <div className="row">
            {setKiosk ? 
                <div className="col-md-12" style={{textAlign:'center'}}>
                    <h4>You are in {kioskUserName} Delegate Mode</h4>
                </div>
            :
                <div className="col-md-7">
                    <h4>Clinical Tracks Form Design - Question Review Tool</h4>
                </div>
            }
                {setKiosk ? null :
                <div className="col-md-5">
				<select className="form-control top-mod-select-n" value={this.state.renderSheet} onChange={this.handleChangeSheet.bind(this)}>
                    {/*<option value="soogap">Soogap</option>*/}
                    <option value="parentMds">Parent MDS</option>
                    <option value="fieldSet">Field Set</option>
                    
                </select>
                <select className="form-control top-mod-select" value={this.state.renderComponent} onChange={this.handleChange.bind(this)}>
                    <option value="edit_form_data">Edit Form Data</option>
                    <option value="review_approve">Review/Approve</option>
                    <option value="form_builder">Form Builder</option>
                    <option value="format_forms">Format Forms</option>
                    <option value="test_forms">Test Forms</option>
                    {/*<option value="completed_forms">View Completed Forms</option>
					<option value="custom_config">Configure Layouts</option>
                    <option value="build_note_template">Build Notes Templates</option>*/}
					<option value="manage_forms">Manage Forms</option>
					<option value="json_files">API Json Files</option>
					<option value="mds_forms">MDS Forms</option>
                </select>
				<a className="nav-link btn btn-outline-info sign-out-btn" onClick={this.logout}>Sign Out</a>
                </div>}
            </div>
        </div>            
    )}
	
}

export default MainHeader;