import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import $ from 'jquery';
import 'bootstrap';
import "../../containers/App.css";
import { Form,Button } from 'react-bootstrap';
import config from "../../config";
import { gapi } from 'gapi-script';
import guid from "../../Helpers/GUID";
import axios,{post,get} from 'axios';
import {DebounceInput} from 'react-debounce-input';
import { getSheetTypeFacility, getSheetApiName } from '../../Helpers/Common';

class MdsForms extends Component{
	constructor(props){
        super(props)
        this.state ={
            sheetsName:[],
			csvdata: [],
			companies: [],
			csvdataConfig: [],
			mdsCsvName:'',
			fileDescription:'',
			sheetId:'',
			sheetName:'',
			token:'',
			mdsForms:[],
			configMod:'parentMds',
        }
		this.csvLink = React.createRef();
		this.csvLinkConfig = React.createRef();
    }
	
	componentDidMount(){
		this.getSheets();
	}
	
	componentDidUpdate(){
		if(this.props.sheetConfigMod != this.state.configMod){
			this.getSheets();
		}
	}

	getSheets=()=>{
		
		let spreadsheetId = this.props.sheetConfigId;
		let sheetConfigMod = this.props.sheetConfigMod;
		this.setState({configMod:sheetConfigMod});
		gapi.client.init({
           apiKey: config.apiKey,
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
            gapi.client.load("sheets", "v4", () => {
                gapi.client.sheets.spreadsheets.get({
					spreadsheetId: spreadsheetId, 
				})
				.then(
					response => {
						const result = response.result.sheets;
						
						let sheets = result.map((sheet) =>{
							/* if(sheet.properties.gridProperties.columnCount > 120 && sheet.properties.gridProperties.columnCount < 150){
								return sheet.properties.title
							} */
							
							if(sheet.properties.title != 'Temp MDS' && sheet.properties.gridProperties.rowCount > 1 && sheet.properties.gridProperties.columnCount > 120 && sheet.properties.gridProperties.columnCount < 150){
								return sheet.properties.title
							}
						});
						
						sheets = sheets.filter(function(x) {
							 return x !== undefined;
						});
						
						let sheetArray = [];
						sheets.map((sheet,i)=>{
							//gapi.client.load("sheets", "v4", () => {
								gapi.client.sheets.spreadsheets.values
								.get({
									spreadsheetId: spreadsheetId,
									range:sheet, 
								})
								.then(
									response => {
										const allData = response.result.values;
										//console.log('allData->',allData);
										let visibility = false;
										let soogapVisibility = false;
										let fields = allData.length-1; 
										
										let dates = [];
										visibility = allData[1][128];
										soogapVisibility = allData[1][141];
										allData.map((field,i)=>{
											if(i > 0){
												let date = new Date(field[98]);
												
												if(date != 'Invalid Date'){
													dates.push(date);
												}	
											}
										})
										
										var maximumDate = new Date(Math.max.apply(null, dates));
										let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
										let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
										
										/* if((sheetConfigMod != 'soogap' && soogapVisibility) || sheetConfigMod == 'soogap'){
											let str = [sheet,lastUpDate,fields,visibility,soogapVisibility];
											sheetArray.push(str)
										} */
										if(soogapVisibility){
											let str = [sheet,lastUpDate,fields,visibility,soogapVisibility];
											sheetArray.push(str)
										}
										this.setState({mdsForms:sheetArray});
									},function(reason) {
										alert(reason.result.error.message);
									}
								);
							//});
						})
						//console.log('sheetArray->',sheetArray);
						
						/* if(sheetConfigMod == 'parentMds'){
							let that = this;
							setTimeout(function(){
								that.setState({mdsForms:sheetArray});
							}, 2000);
						}else{
							this.setState({mdsForms:sheetArray});
						} */
						
						//console.log('sheetArray->',sheetArray);
						
					},
				);
			});
        });
	}

	setVisibility=(event)=>{
		let spreadsheetID = this.props.sheetConfigId;
		let fileName = event.target.name;
		//let spreadsheetID = config.spreadsheetId;
		let visibility = '';
		if(event.target.checked){
			visibility = 'TRUE';
		}
		var params = {
			spreadsheetId:  spreadsheetID,
		};
		let data = [];
		let newRow = {
			range: fileName+'!DY2',
			values: 
			[
				[ visibility ]
			]
		};
		data.push(newRow)
		
		let batchUpdateValuesRequestBody = {
			valueInputOption: 'USER_ENTERED',
			data: data,
		};
	
		let request1 = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
		request1.then(function(response) {
		}, function(reason) {
		console.error('error: ' + reason.result.error.message);
		});

	}
	
	render(){
		const { mdsForms} = this.state;
		const { sheetConfigMod,sheetConfigId} = this.props;
		
		return(
			<div className="card-body manage-forms">
			<div className="row">
					
			</div>
			{ mdsForms.length > 0 ?
				<table className="table json-list">
				  <thead className="thead-light">
					<tr>
					  <th scope="col"></th>
					  <th scope="col">Master Data Set Name</th>
					  <th scope="col">Date time of last data element change</th>
					  <th scope="col">Total # of Fields</th>
					</tr>
				  </thead>
				  <tbody>
					{mdsForms.map((file, i) => {
						return(<tr key={i} className="json-list-tr">
							  <th scope="row"><input type="checkbox" name={file[0]} defaultChecked={file[3]} onChange={this.setVisibility} /></th>
							  <td>{file[0]}</td>
							  <td>{file[1]}</td>
							  <td>{file[2]}</td> 
						</tr>)
					})}
						
				  </tbody>
				</table>
			:null}	
			</div>
		);
	}
}

export default MdsForms;