import React from "react";
import Progress from 'react-bootstrap/ProgressBar';

const ProgressBar = (props) => {
  const { completed, total} = props;
  let progress_value = completed*100/total;
  return (
    <div>
      <Progress now={progress_value} /> <span className="pro-text">{completed}/{total}</span>
    </div>
  );
};

export default ProgressBar;