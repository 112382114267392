import React, { Component } from 'react';
import config from '../../config';
import axios,{post} from 'axios';
import $ from 'jquery';
import { load, uploadFilePath, getSheetType, getSheetTypeFacility, getPdfFromApi, getConfigResponse, getSheetsIdByName, getConfigData } from '../../Helpers/Common';
import { gapi } from 'gapi-script';
import TopFilter from '../Header/TopFilter'
import { Form,Button } from 'react-bootstrap';
import ViewKioskData from "../TestForm/ViewKioskData";
import KioskData from "../TestForm/KioskData";
import PdfData from "../TestForm/PdfData";
import ViewPdfData from "../TestForm/ViewPdfData";
import MainData from "../../component/MainData/MainData";
import ClinicalTracks from '../ClinicalTracks';
import guid from "../../Helpers/GUID";
import emailjs from 'emailjs-com';
import ProgressBar from "../progressBar";
class CompletedForm extends Component{
    constructor(props) {
        super(props)
        this.state = {
			sheetConfigId:config.spreadsheetId,
            test_forms:[1,2,3],
            sheetData:[],
            sheetsName: [],
            assoType:[],
            assoDeviceType:[],
            assoVisitType:[],
			current_user: [],
            mod:'completed_forms',
            kioskUsers:['CPO'],
			apiPdfs:[],
            kioskUserName:'',
            assoDeviceTypeName:'',
            assoVisitTypeName:'',
            kioskUsersData:new Map(),
            kioskResponse:new Map(),
            userClassResponse:new Map(),
            rResponse:new Map(),
            checkedKiosk:new Map(),
            checkedReq:new Map(),
            checkedFeeds:new Map(),
            feedsResponse:new Map(),
            kioskPreviewData:[],
            setKiosk:false,
            kioskMode:false,
            showKioskBtn:false,
            selDropOpt:'original',
            moduleName:'completed_forms',
            reviewSheetsName:[],
            reqCount: 0,
            reqCmpCount: 0,
            mdsGuid:'',
            emailMessage:'',
            emailId:'',
            errorMessage:'',
            successMessage:'',
            zoomLink:'',
			savedResponse:'',
			viewResponse:'',
        }
        this.pdfArray = [];
		this.fileArray = [];
        if( localStorage.getItem('username')){
            this.username = localStorage.getItem('username');
        }else{
            this.props.history.push({
			  pathname: '/beta',
			  state: { moduleName: 'completed_forms' },
			});
        }
        this.reviewSheetNames = this.reviewSheetNames.bind(this);
    }
	
    componentDidMount() {
        /* if(this.username == 'CC-1'){
            this.setState({kioskUsers: ['CaC'],showKioskBtn:true,setKiosk:true})
        }else if(this.username == 'Res-1'){
            this.setState({kioskUsers: ['Res'],showKioskBtn:true,setKiosk:true})
        }else if(this.username == 'Pat-1'){
            this.setState({kioskUsers: ['PhA'],showKioskBtn:true,setKiosk:true})
        }else if(this.username == 'MD-1'){
            this.setState({kioskUsers: ['PmD'],showKioskBtn:true,setKiosk:true})
        }else if(this.username == 'PT-1'){
            this.setState({kioskUsers: ['PhT'],showKioskBtn:true,setKiosk:true})
        }else{
            this.setState({kioskUsers: ['CPO'],showKioskBtn:false,setKiosk:false})
        } */
        gapi.load("client", this.initClient);
		
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = config.API_URL+'pdf/getPdf.php';
        axios.get(url, con)
        .then(response => {
			let pdfArray = response.data;
			this.setState({pdfArray});
        })
    }
	
    initClient = () => {
        
        gapi.client.init({
           apiKey: config.apiKey,
           'clientId': config.client_id,
           'scope': 'https://www.googleapis.com/auth/spreadsheets',
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
            load(this.onLoad,this.state.sheetConfigId);
        });
		
		gapi.client.init({
		   apiKey: config.apiKey,
		   discoveryDocs: config.discoveryDocs,
		})
		.then(() => {
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: config.spreadsheetId,
					range:'User-Login',
				})
				.then(
					response => {
						let users = response.result.values;
						users.map((user,i)=>{
							if(this.username == user[0]){
								this.setState({ current_user:user });
							}
						});
						
					},
					response => {
						//alert(response.result.error.message);
						console.log('error',response.result.error);
					}
				);
			});
		
		});
		
		let that = this;
		getConfigResponse().then(function(response) {
			that.setState({savedResponse:response});
		}, function(error) {
		  console.error("Failed!", error);
		})
		
    };
	
    deleteData(allDatas){
        var branch= [];

        for(let i = 0; i < allDatas.length; i++){
            let row = Object.assign([], allDatas[i]);
            if(row.sub){
                delete row.sub;
            }
            branch.push(row);
        }
 
        return branch;
    }
    
    onLoad = (data, error) => {
		let user = this.state.current_user;
		if (data) {
            const sheetsName = data.sheetsName.slice(0,3);
            const sheetsId = data.sheetsId.slice(0,3);
            let sheetpro = [];
            let sheetsNames = data.sheetsName;
			
            { sheetsNames.map((sheet, i) => {
				
					if(typeof(user[4]) != "undefined" && user[4] != 'All' && user[4] != ''){
						let viewAbleSheet = user[4].split(',');
						if(viewAbleSheet.indexOf(sheet) == -1){
							return true;
						}
					}
					
                    let sh = {id:data.sheetsId[i], name:sheet, type:'', deviceType:'', VisitType:'',visibility:''};
                    //if(i <= config.showSheetInDrop){
                        data.sheetType.map((sheetName,i)=>{
                            if(sheetName.visibility == 'TRUE' && sheet == sheetName.name ){
                                sh = {id:data.sheetsId[i], name:sheet, type:data.sheetType[i].type, deviceType:data.sheetType[i].deviceType, visitType:data.sheetType[i].visitType, visibility:data.sheetType[i].visibility};
                            }
                        })
                        if(sh.type && sh.deviceType && sh.visitType && sh.visibility){
							sheetpro.push(sh);
						}
                    //}
            })};
			
			let visibileSheet = [];
			{ sheetpro.map((sheet, i) => {
				if(sheet.visibility == 'TRUE' && visibileSheet.length <= config.showSheetInDrop){
					visibileSheet.push(sheet);
				}
			})};
			
			this.setState({ sheetsName:visibileSheet });
            this.setState({ sheetsNameOrig:visibileSheet });

        } else {
            this.setState({ error });
        }
		
		let that = this;
		getPdfFromApi().then(function(response) {
			that.setState({apiPdfs:response});
		}, function(error) {
		  console.error("Failed!", error);
		})
    };
	
    logout = () => {
        //localStorage.removeItem('id');
        localStorage.removeItem('username');
        this.props.history.push('/beta');
    }
	
    getSheetValue=(event)=>{
        let sheetId = event.target.options[event.target.selectedIndex].dataset.id;
        this.setState({selectedSheet1:event.target.value});
        this.setState({selectedSheetId:sheetId});
        this.onSelect(event.target.value, sheetId);
    }
	
    reviewSheetNames(mod){
		
		let configName = 'TTEVAL-Config Files';
		gapi.client.init({
           apiKey: config.apiKey,
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
			gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: config.spreadsheetId,
					range:configName,
				})
				.then(response => {
					let sheetVal = response.result.values;
					let sheets = [];
					sheetVal[0].map((value, i) => {
						if(i > 0){
							let configId = value[4];
							let col = [];
							sheetVal.map((val, j) => {
								let column = val[i];
								if(column){
									col.push(column);
								}
							});
							
							sheets.push(col);
						}
					});
					
					let selectedSheet = this.state.selectedSheet;
					if(selectedSheet){
						sheets.map((sheet, i) => {
							if(selectedSheet != sheet[1]){
								delete sheets[i];
							}
						})
					}

					sheets = sheets.filter(function (el) {
					  return el != null;
					});
					
					let sheetpr = [];
					
					sheets.map((sheet, i) => {
						let sht = {name:sheet[0], type:sheet[6], deviceType:sheet[7], visitType:sheet[8], mdsGuid:sheet[2], configGuid:sheet[4], date:sheet[3], facilityId:sheet[9]};
						
						sheetpr.push(sht);

					});
					console.log('sheets->',sheets);
					this.setState({configSheets:sheets, reviewSheetsName:sheetpr, reviewSheetsNameOringn:sheetpr});
				});
			});	
		}); 
        
    }

    getValue=(event)=>{
        $("input[type='radio']").prop("checked", false);
        let sheetId = event.target.options[event.target.selectedIndex].dataset.id;
        if(sheetId){
           this.setState({selectedSheetId:sheetId});
        }else{
            sheetId = this.state.selectedSheetId
        }
        this.setState({selectedSheetId:sheetId});
        this.setState({reviewVer:event.target.value});
        let sheetName = this.state.selectedSheet1;
        this.selectReviewOpt(event.target.value,sheetId, sheetName);
    }
	
    selectReviewOpt=(reviewStatus, selectedSheetId, sheetName)=>{
		this.setState({selectedSheetId,selectedOpt:reviewStatus});
        if(reviewStatus == 'original'){
            window.location.reload(); 
        }else if(reviewStatus !== 'improved' && reviewStatus !== 'original'){
			this.setState({selectedSheet:reviewStatus,selDropOpt:'exported',exportedSelectedSheet:reviewStatus});
            var  singleSheetName = reviewStatus;
            let configSheets = this.state.configSheets;
			let configData = getConfigData(singleSheetName, configSheets);
			
			if(configData.length > 0){
				let allData = this.state.allData;
				this.setState({configData});
				allData.map((data,i)=>{
					if(configData[i]){
						if(data[0] == configData[i][0]){
							for (let j in allData) {
								if(j==10){
									data[j] = configData[i][7];
								}
								if(j==14){
									data[j] = configData[i][5];
								}
								if(j==15){
									data[j] = configData[i][6];
								}
								if(j==16){
									data[j] = configData[i].join();
								}
								if(j==37){
									data[j] = configData[i][8];
								}
							}
						}
					}else{
						data[10] = 0;
						data[14] = 'N';
					}
				})
				
				this.setState({allData});
				this._answer(allData)
			}
        }
    }
	
    selectKioskUsers=(event)=>{
        this.setState({kioskUsers: Array.from(event.target.selectedOptions, (item) => item.value)})
    }
	
    onSelect = (sheetsName, selectedSheetId) => {
		$("input[name='showAllForms']").prop( "checked",false );
		this.setState({viewResponse:'',sheetData:''});
        let spreadsheetId = config.spreadsheetId;
        var that = this;
        var  singleSheetName = sheetsName;
        gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: spreadsheetId,
                range:singleSheetName, 
            })
            .then(
                response => {
					this.emptyState();
					
                    const allData = response.result.values;
					if(allData.length > 0){
						let mdsGuid = allData[1][91];
						this.setState({mdsGuid});
						
						let kioskType = allData[1][119].split(',');
						let username = this.username;
						this.setState({kioskUserName:'Practitioner',kioskUsers: ['CPO'],showKioskBtn:false,setKiosk:false});
						kioskType.map((type,i)=>{
							type = type.split(':');
							let user = username.split('-');
							if(user[0] == type[1] && (username == 'CC-1' || username == 'Res-1' || username == 'Pat-1' || username == 'MD-1' || username == 'PT-1')){
								this.setState({kioskUserName:type[0],kioskUsers: [type[1]],showKioskBtn:true,setKiosk:true});
							}
						})
						
					}
                    allData.shift();
                    this.setState({allData});
                    this.reviewSheetNames(this.state.mod);
                    //this._answer(allData)
                },function(reason) {
					alert(reason.result.error.message);
				}
            );
        });
        this.setState({selectedSheet:sheetsName,mainSheet:sheetsName,selDropOpt:'original',reviewVer:'original',selectedSheetId,orginalSelectedSheet:sheetsName});
    }
	
	emptyState=()=>{
		
		let kioskResponse = this.state.kioskResponse;
		let userClassResponse = this.state.userClassResponse;
		let rResponse = this.state.rResponse;
		let checkedReq = this.state.checkedReq;
		this.setState({kioskResponse:new Map()});
		this.setState({userClassResponse:new Map()});
		this.setState({rResponse:new Map()});
		this.setState({checkedReq:new Map()});
		
	}
    
    handleSignIn=()=> {
        let password = this.refs.password.value
        if(password  == 'abc123'){
            let kioskUsers = this.state.kioskUsers;
            this.setState({errors:false,error:''});
            $('#loginModal').modal('hide');
            $('.card').css('backgroundColor','#fff')            
            let allData = this.state.allData;
            allData.map((rowDatas,i)=>{
                $('.headers-'+rowDatas[0]).find('i').removeClass("fa fa-minus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).find('i').addClass("fa fa-plus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).removeClass("collapsed");
                $('.inner-'+rowDatas[0]).removeClass("show");
                $('.pre-question').removeClass("show");
            })
            let t =true
            if(this.username == 'CPO-1' || this.username == 'CPO-2' || this.username == 'CPO-3' || this.username == 'CPO-4' || this.username == 'CPO-5' || this.state.setKiosk == true){
                t =false;
            }

            this.setState({setKiosk:t,kioskMode:!this.state.kioskMode,kioskUsers:['CPO'],showKioskBtn:t},()=>this._answer(allData));
            
        }else{
            this.refs.password.value = "";
            this.setState({errors:true,error:'Please enter a valid password'});
        }        
    }
	
    checkKey2(elements, parentId = '',ids ,pId, cId) {
        var branch = [];
        { elements.map((element,i) => {
            if(element[5] == 'Parent Header'){
                element[pId] = '';
            }  
            if (element[pId].toLowerCase() == parentId) {
                if(element[cId] == ''){
                    element[cId]='a';
                }
                var children = this.checkKey2(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children) {
                    element['sub'] = children; 
                }
                if(!ids.includes(element['0'])){
                    branch.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch;    
    }
       
    userDefined(){
        let kioskUsers = this.state.kioskUsers;
        if(this.username == 'CPO-1' || this.username == 'CPO-2' || this.username == 'CPO-3' || this.username == 'CPO-4' ||  this.username == 'CPO-5' || kioskUsers[0] == 'CPO'){
            this.setState({kioskMode:false})
        }else{
            this.setState({kioskMode:true});
        }
    }
	
    showKioskUserName=()=>{
        if(this.username == 'CC-1'){
            this.setState({kioskUserName: 'Care Coordinator'})
        }else if(this.username == 'Res-1'){
            this.setState({kioskUserName: 'Resident'})
        }else if(this.username == 'Pat-1'){
            this.setState({kioskUserName:'Practitioner Assistant'})
        }else if(this.username == 'MD-1'){
            this.setState({kioskUserName:'Physician'})
        }else if(this.username == 'PT-1'){
            this.setState({kioskUserName:'Physical Therapist'})
        }else{
            this.setState({kioskUserName:'Practitioner'})
        }
    }
	
    _answer=(allData)=>{
		let kioskUsers = this.state.kioskUsers
        let data = this.state.allData;
        let sampleData = this.deleteData(data);
        let mainDataN =[];
        let reqCmpCount =[];
        
        let checkedReq = this.state.checkedReq;
        sampleData.map((rowDatas,i)=>{
            let configDataRow = '';
            let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
            if(columnName){
                configDataRow = columnName.split(',');
                configDataRow.splice(0, 9);
            }
            if(rowDatas[5] == 'Parent Header'){
                mainDataN.push(rowDatas);
            }else if(rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3'){
                mainDataN.push(rowDatas);
            }else if(configDataRow.indexOf(kioskUsers[0]) != -1){
                mainDataN.push(rowDatas);
            }
			
			if(rowDatas[14] == 'Y' && rowDatas[15] == 'Y'){
				reqCmpCount.push(rowDatas[0]);
			}
   
        })
        
        //this.setState({reqCount:checkedReq.size})
        this.setState({reqCmpCount:reqCmpCount.length})

        let ids =[];
        let mainData =[];
        {mainDataN.map((data,i)=>{
            var key = data[10];
            mainData[key] = data; 
        })}
        
        this.setState({mainData});
        
        var dbArray = this.checkKey2(mainData, '', ids, 2, 1);
        
        let db3 =[];
        dbArray.map((rowDatas,i)=>{
            if( rowDatas['sub'].length != 0 ){
                db3.push(rowDatas);
            }
        })
		
        this.userDefined();
        //this.showKioskUserName();
        /* if(kioskUsers.toString() == 'PhT'){
            $('.card').css('backgroundColor','#FFCCE5')
        }else if(kioskUsers.toString() == 'CPO'){
            $('.card').css('backgroundColor','#FFFFFF')
        }else if(kioskUsers.toString() == 'Int' || kioskUsers.toString() == 'Res' || kioskUsers.toString() == 'PrA' || kioskUsers.toString() == 'PA' ){
            $('.card').css('backgroundColor','#B2FF66')
        }else if(kioskUsers.toString() == 'PhA' || kioskUsers.toString() == 'PmD'){
             $('.card').css('backgroundColor','#DCDCDC')
        }else if(kioskUsers.toString() == 'Pat'){
             $('.card').css('backgroundColor','#ccf5ff')
        }else if(kioskUsers.toString() == 'CaC'){
            $('.card').css('backgroundColor','#FFFF99')
        } */
        if(kioskUsers.toString() != 'CPO'){
            $('.btn-tag').hide();
        }
		
		if(this.state.mainSheet){
			let savedResponse = this.state.savedResponse;
			let selectedSheet = this.state.selectedSheet;
			let viewResponse = [];
			savedResponse.map((response,i)=>{
				if(response[1].indexOf(selectedSheet) != -1){
					let complatedFields = [];
					response[5].map((res,i)=>{
						if(res['response']){
							complatedFields.push(res['FieldID']);
						}
					})
					complatedFields = complatedFields.length;
					
					response[6]= complatedFields;
					response[7]= reqCmpCount.length
					viewResponse.push(response);
				}
			})
			
			this.setState({viewResponse});
		}
    }
	
    showKioskModal=()=>{
        let checkedKiosk = this.state.checkedKiosk;
        this.refs.password.value = "";
        this.setState({checkedKioskData:checkedKiosk})
        this.setState({errors:false,error:''});
        $('#loginModal').modal('show')
    }
	
    dataFilter=(event,val)=>{
		
		let optionVal = val;
		if(event){
			optionVal = event.target.value;
		}
        let allDatas = this.state.allData;
        
        if(optionVal == 'view_all'){
            $('.comments').find('.not-include'). removeClass("not-include");
			
			setTimeout(function(){
			
				{ allDatas.map((comment, i) => {
					
					if($('#'+comment[0]).find('.question').length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
						$('#'+comment[0]).addClass("not-include");
					}else{
						$('#'+comment[0]).removeClass("not-include");
					}
					
				})}
				
			}, 1000);
			
        }else if(optionVal == 'required_only'){
            
            { allDatas.map((rowDatas, i) => {
                
                $('#'+rowDatas[0]).addClass("not-include");
                
                if($('#'+rowDatas[0]).find('.req-only').length > 0 && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
                    $('#'+rowDatas[0]).removeClass("not-include");  
                }
                
            })}
            setTimeout(function(){
                $('.req-only').removeClass("not-include");
            }, 1000);   
            
        }
		
    }
	
    showPreview=(event)=>{
        let value = event.target.value;
		$('input[name=selOptions]').prop( "checked", false );
        if(value == 'open'){
            let data = this.state.allData;
            let mainDataS =[];
            data.map((rowDatas,i)=>{
                if(rowDatas[5] == 'Parent Header' || rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3' || rowDatas[5].toLowerCase() == 'title' || rowDatas[5].toLowerCase() == 'text'){
                    mainDataS.push(rowDatas);
                }
                let cRes = this.state.kioskResponse.get(rowDatas[0]);
                if(typeof cRes != "undefined" && cRes != ''){
                    mainDataS.push(rowDatas);
                }
				/* if(typeof rowDatas[99] != "undefined" && rowDatas[99] != '' && rowDatas[5] == 'video'){
                    mainDataS.push(rowDatas);
                } */
            })
            let ids =[];
			let mainData =[];
			
			{mainDataS.map((data,i)=>{
				var key = data[10];
				mainData[key] = data; 
			})}
			
            var dbArray2 = this.checkKey2(mainData, '', ids, 2, 1);
            let db4 =[];
            dbArray2.map((rowDatas,i)=>{
                if( rowDatas['sub'].length != 0 ){
                    db4.push(rowDatas);
                }
            })
			
            this.setState({kioskPreviewData:db4});
			
			let that = this;

            setTimeout(function(){
                $('.commentss').each(function( index ) {
                    if($(this).find('.question').length < 1){
                        $(this).remove();
                    }
                });
                
                $('.commentss input, .commentss select').attr('disabled', 'disabled');  
                $( "select option:selected" ).each(function() {
				  $(this).attr('selected', true);
				});
				$("h3, h4, h5, h6").trigger('click');
				
				let sOptions = $("input[name='selOptions']:checked").val();
				if(sOptions == 'required_only'){
					let value = 'required_only';
					that.dataFilter(event=null,value);
				}
            }, 3000);
			$('.rq-btn, .sv-btn').hide();
        }else{
            this.setState({kioskPreviewData:''});
        }
        
    }
    
    closePreview=()=>{
        $('input[name=kioskResponse]').prop( "checked", false );
		$('.rq-btn, .sv-btn').show();
        this.setState({kioskPreviewData:''});
		setTimeout(function(){
			$('.comments input, .comments select').attr('disabled', 'disabled');  
			$( "select option:selected" ).each(function() {
				$(this).attr('selected', true);
			});
			$("h3, h4, h5, h6").trigger('click');
		}, 2000);
    }
	
	createPdf=()=>{
		
		let url = config.API_URL+'pdf/pdf.php';
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		//let dateFormat = date.split(' ');
		let formPrefix = this.state.selectedSheet.split(' ');
		let formName = formPrefix[0]+'-'+this.state.kioskUserName+'-'+date;
		let con = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'multipart/form-data'
			}
		}
		//let header = '<h1>'+formName+'</h1>';
		let signName = $("input[name=signName]").val();
		let signTitle = $("input[name=signTitle]").val();
		let signCont = $("input[name=signCont]").val();
		let signDate = $("input[name=signDate]").val();
		let signdata = '';
		
		if(signName){
			signdata = signName;
		}
		if(signTitle){
			let str2 = ', '+signTitle;
			signdata = signdata.concat(str2);
		}
		if(signCont){
			let str3 = ', '+signCont;
			signdata = signdata.concat(str3);
		}
		
		let createdDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		let html = '<div style="display: block;margin-bottom:20px"><h2>Note from evaluation for e-signature and to be placed in records systems Generated on '+createdDate+'</h2><hr></div>';
		
		html = html.concat($(".pdf-data").html());
		
		let footer = '<table style="width: 100%;margin-top: 10px;"><tbody><tr><td style="width: 50%; padding: 0px 50px 0px 0px;"><span style="text-align: left;">X</span><hr></td><td style="width: 50%; padding: 0px 50px 0px 0px"><span style="text-align: left;">X</span><hr></td></tr><tr><td style="width: 50%; padding: 0px 50px 0px 0px;text-align: center;">'+signdata+'</td><td style="width: 50%; padding: 0px 50px 0px 0px;text-align: center;">Date</td></tr></tbody></table>';
		
		let form_data = new FormData();
		form_data.append('name', formName);
		//form_data.append('header', header);
		form_data.append('footer', footer);
		form_data.append('html', html);
		post(url, form_data, con)
		.then(response => {
			//console.log('response->', response);
			window.open(response.data, "_blank")
		})
	}
	
	keyPress(e){
        if(e.keyCode == 13){
			e.preventDefault();
        }
    }
	
	selectFormMod = (event) =>{
		let val = event.target.value;
		if(val == 'test_forms'){
			this.props.history.push('/testform');
		}else if(val == 'completed_forms'){
			this.props.history.push('/completedform');
		}else if(val == 'custom_config'){
			this.props.history.push('/configure');
		}
	}
    
	previewSavedData=(mds,configSheet,id)=>{
		if(!this.state.allData || !this.state.mainSheet){
			let spreadsheetId = config.spreadsheetId;
			let singleSheetName = mds;
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: spreadsheetId,
					range:singleSheetName, 
				})
				.then(
					response => {
						this.emptyState();
						const allData = response.result.values;
						if(allData.length > 0){
							let mdsGuid = allData[1][91];
							this.setState({mdsGuid});
						}
						allData.shift();
						this.setState({allData});
						this.selectReviewOpt(configSheet, null, null);
						let that = this;
						setTimeout(function(){
							that.previewResponse(id);
						}, 3000);
						
					},function(reason) {
						alert(reason.result.error.message);
					}
				);
			});
		
			
		}else{
			this.previewResponse(id);
		}
	}
	
	previewResponse=(id)=>{
		$("h3, h4, h5, h6").trigger('click');
		$(".view-response").removeClass('active');
		$(".select-view").prop('checked', false);
		$(".checkbox_"+id).prop('checked', true);
		$('input[name=selOptions]').prop( "checked", false );
		$("#"+id).addClass('active');
		$("#id-"+id).text(this.state.reqCmpCount);
		let kioskUsers = this.state.kioskUsers
		let data = this.state.allData;
		let mainDataN =[];
		let kioskResponse = this.state.kioskResponse;
		let userClassResponse = this.state.userClassResponse;
		let viewResponse = this.state.viewResponse;
		if(viewResponse){
			viewResponse.map((response,i)=>{
				if(response[2]== id){
					
					response[5].map((res,i)=>{
						if(res['response']){
							kioskResponse.set(res['FieldID'], res['response'])
							userClassResponse.set(res['FieldID'], [res['UserClass']])
						}else{
							kioskResponse.delete(res['FieldID'])    
							userClassResponse.delete(res['FieldID'])
						}
					})
					this.setState({kioskResponse:kioskResponse});
					this.setState({userClassResponse:userClassResponse});
				}
			})
		}

		data.map((rowDatas,i)=>{
			
            let configDataRow = '';
            let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
            if(columnName){
                configDataRow = columnName.split(',');
                configDataRow.splice(0, 9);
            }
            if(rowDatas[5] == 'Parent Header' || rowDatas[5] == 'Child Header 1' || rowDatas[5] == 'Child Header 2' || rowDatas[5] == 'Child Header 3' || rowDatas[5].toLowerCase() == 'title' || rowDatas[5].toLowerCase() == 'text'){
                mainDataN.push(rowDatas);
            }else if(configDataRow.indexOf(kioskUsers[0]) != -1){
				let cRes = kioskResponse.get(rowDatas[0]);
				if(typeof cRes != "undefined" && cRes != ''){
					mainDataN.push(rowDatas);
				}
            }
        })
        

        let ids =[];
        let mainData =[];
        {mainDataN.map((data,i)=>{
            var key = data[10];
            mainData[key] = data; 
        })}
        
        this.setState({mainData});
        
        var dbArray = this.checkKey2(mainData, '', ids, 2, 1);
        
        let db3 =[];
        dbArray.map((rowDatas,i)=>{
            if( rowDatas['sub'].length != 0 ){
                db3.push(rowDatas);
            }
        })
		
		this.setState({sheetData:db3});
		
		setTimeout(function(){
			$('.comments').each(function( index ) {
				if($(this).find('.question').length < 1){
					$(this).remove();
				}
			});
			
			$('.comments input, .comments select').attr('disabled', 'disabled');  
			$( "select option:selected" ).each(function() {
				$(this).attr('selected', true);
			});
			$("h3, h4, h5, h6").trigger('click');
		}, 3000);
		$('.rq-btn, .sv-btn').hide();
			
	}
	
	showAllForms = () => {
		if($("input[name='showAllForms']").prop("checked") == true){
			this.reviewSheetNames(this.state.mod);
			let savedResponse = this.state.savedResponse;
			let viewResponse = [];
			savedResponse.map((response,i)=>{
				let complatedFields = [];
				response[5].map((res,i)=>{
					if(res['response']){
						
						complatedFields.push(res['FieldID']);
					}
				})
				complatedFields = complatedFields.length;
				
				response[6]= complatedFields;
				viewResponse.push(response);
				
			})
			this.setState({viewResponse});
			this.setState({mainSheet:''});
		}else{
			this.setState({viewResponse:'',sheetData:''});
		}
	}
	
	getRfields = (singleSheetName) => {
		return new Promise((resolve, reject) => {
			gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			  .get({
					spreadsheetId: config.pahseII_spreadsheetId,
					range:singleSheetName, 
				})
				.then(
					response => {
						const allData = response.result.values;
						let reqCmpCount =[];
						allData.map((data,i)=>{
							if(data[14] == 'Y' && data[15] == 'Y'){
								reqCmpCount.push(data[0]);
							}
							
						})
						resolve(reqCmpCount.length);
					},function(reason) {
						alert(reason.result.error.message);
					}
				);
			});
		});
		
	}
	
    render(){
		const {allData,sheetData,moduleName,checkedKiosk,setKiosk, assoType,assoVisitType,assoDeviceType,showKioskBtn, kioskMode, kioskUserName,kioskResponse,errorMessage,successMessage, feedsResponse, userClassResponse, pdfArray, fileArray, mdsGuid, viewResponse} =this.state
		//console.log('viewResponse->', viewResponse);
		var optionsPdf = ''
		if(pdfArray){
            var optionsPdf = pdfArray.map(function(data,i) {
                return (
                    <option value={data.path} key={i}>{data.name}</option>
                );
            })
        }
		
		let kioskType = [];
		let uType = [];
		if(allData){
			kioskType = allData[0][119].split(',');
			
			kioskType.map(function(type,i) {
				type = type.split(':');
				if(type[1]){
					uType.push(type[1]);
				}
			})
		}
		if(uType.length == 0){
			uType = ['CPO'];
		}
		
        //var uType  = ['CaC','Pat','Int','Res','PrA','PhT','PhA','PmD','CPO'];
		
		
        if (this.state.test_forms.length) {
            return (
            <div className="container mt-5 mb-5">
				<div className="login-user-top">{'Logged in as: '+localStorage.getItem('username')}</div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                            
                                <div className="row">
									<div className="col-md-7">
                                        <h4>Review Form Submissions </h4>
                                    </div>
                            
                                    <div className="col-md-5">
											<select className="form-control select-new-mod" value={moduleName} name="forms" onChange={this.selectFormMod}>
												<option value="test_forms">Test Form</option>
												<option value="completed_forms">View Completed Forms</option>
												<option value="custom_config">Configure Layouts</option>
											</select>
											<a className="nav-link btn btn-outline-info sign-out-btn" onClick={this.logout}>Sign Out</a>
                                    </div>
                                </div>
                            </div> 

                            <div className="card-body">
                           
                            
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> Clinical Form </label>
                                            <Form.Control as="select" value={this.state.selectedSheet1} onChange={this.getSheetValue}>
                                                <option> Please Select</option>
                                                {this.state.sheetsName.map((sheet, i) => (
                                                    <option data-id={sheet.id} key={i}>{sheet.name}</option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> Test Form Version </label>
                                             
											<Form.Control id="test-form-select" as="select" value={this.state.reviewVer} onChange={this.getValue}>
												<option value="original">Master Data Set</option>
												{this.state.reviewSheetsName.map((sheet, i) => {
													let user = this.state.current_user;
													let fId = user[3];
													if(fId != 'All'){
														if(sheet.facilityId == fId){
															return <option data-id={sheet.configGuid} key={i}>{sheet.name}</option>
														}
													}else{
														return <option data-id={sheet.configGuid} key={i}>{sheet.name}</option>
													}
												})}
											</Form.Control>
                                        </div>
                                    </div>
                                    
                                    {sheetData.length>0 ? 
                                        <div className="col-md-2" style={{marginTop:'20px'}}>
                                            <div className="radio">
                                              <label><input type="radio" value="view_all" name="selOptions" onChange={ this.dataFilter } />View All(Default)</label>
                                            </div>
                                            <div className="radio">
                                              <label><input type="radio" value="required_only" name="selOptions" onChange={ this.dataFilter } />View Required </label>
                                            </div>
                                        </div>
									:null}
									<div className="col-md-4"  style={{marginTop:'20px'}}>
										<div className="form-filter">
											<label className="showAllSwitch">
											<input type="checkbox" name="showAllForms" onClick={this.showAllForms}/>
											<span className="slider round showAllSlide"></span>
											</label> Show all completed forms						
										</div>	
									{sheetData.length>0 ? 
										<div>
											<label><input type="radio" name="kioskResponse" value="open" onChange={this.showPreview}/>View completed Delegate Response</label>
											
										</div>
										
									:null}
									</div>
                                </div>
                                
                                <hr/>
								{viewResponse ?
									<div className="response-table view-table">
										<table className="table table-border">
											<thead>
												<tr>
													<th scope="col"></th>
													<th scope="col">Name</th>
													<th scope="col">Date Completed</th>
													<th scope="col">Users</th>
													<th scope="col"># of required fields</th>
													<th scope="col"># Total fields completed</th>
												</tr>
											</thead>
											<tbody >
											{viewResponse.map(res => (
												<tr className="view-response" id={res[2]} >
													<td><input className={"select-view checkbox_"+res[2]} type="checkbox" name="selectView" onClick={()=> this.previewSavedData(res[0],res[1],res[2])} /></td>
													<td>{res[1]}</td>
													<td>{res[4]}</td>
													<td>{res[3]}</td>
													<td id={"id-"+res[2]} style={{textAlign:'center'}} >{res[7]}</td> 
													<td style={{textAlign:'center'}}>{res[6]}</td>
												</tr>
											))}
											</tbody>
											<input type="hidden" id="fileId" value="" />
										</table>
									</div>
								:null}
                                {sheetData.length > 0 ? <hr/> :null}
								
                                {sheetData.length > 0 && this.state.kioskPreviewData.length < 1 ?
									<div>
									<div className="view-data">
                                    <ViewKioskData 
										sheetData = {sheetData}
										checkedReq = {this.state.checkedReq}
										moduleName={moduleName}
										kioskResponse={kioskResponse}
										userClassResponse={userClassResponse}
										allData = {allData}
									/>
									</div>
									<div className="pdf-data">
										<ViewPdfData 
											sheetData = {sheetData}
											checkedReq = {this.state.checkedReq}
											moduleName={moduleName}
											kioskResponse={kioskResponse}
											userClassResponse={userClassResponse}
											allData = {allData}
										/>
									</div> 
									</div>
                                :null}
								
                                {sheetData.length > 0 && this.state.kioskPreviewData.length > 0 ?
                                <div>
								<div className="kiosk-data">
                                {uType.map((type,i) => (
									<KioskData 
										sheetData = {sheetData}
										checkedReq = {this.state.checkedReq}
										kioskPreviewData = {this.state.kioskPreviewData}
										moduleName={moduleName}
										kioskResponse={kioskResponse}
										userClassResponse={userClassResponse}
										allData = {allData}
										type = {type}
									/>
                                ))}
                                </div>
								<div className="pdf-data">
                                {uType.map((type,i) => (
									<PdfData 
										sheetData = {sheetData}
										checkedReq = {this.state.checkedReq}
										kioskPreviewData = {this.state.kioskPreviewData}
										moduleName={moduleName}
										kioskResponse={kioskResponse}
										userClassResponse={userClassResponse}
										allData = {allData}
										type = {type}
									/>
                                ))}
                                </div>
								</div>
                                :null}
                                {sheetData.length>0 ? 
                                <div className="row">
                                    <div className="col-md-12">
                                    {sheetData.length > 0 && this.state.kioskPreviewData.length > 0 ?
                                        <div>
										<Button type="button" className="btn btn-danger float-right" onClick={()=> this.createPdf()}><i className="fas fa-file-pdf"></i> </Button>
                                        <Button type="button" className="float-right" onClick={()=> this.closePreview()} >Return to full form view </Button>
										</div>
                                    :
										<Button type="button" className="btn btn-danger float-right" onClick={()=> this.createPdf()}><i className="fas fa-file-pdf"></i> </Button>
									}
									</div>
                                </div>
                                :null}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="modal" id="loginModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Enter a password</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <form>
                                <div className="modal-body">      
                                    <input type="password" className="form-control" ref="password" placeholder="enter a password" onKeyDown={this.keyPress} />
                                    { this.state.errors ?
                                    <p className=" alert-danger"> { this.state.error } </p>:null }                                    
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary float-left" onClick={this.handleSignIn}>Ok</button>
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                
                
            </div>
        );
        }
        else{
            return (<div className="card-body">Loading...</div>)
        }
    }
}

export default CompletedForm;