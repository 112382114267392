import React,{Component} from 'react';
import $ from 'jquery';
import config from "../../config";
import { gapi } from 'gapi-script';
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {DebounceInput} from 'react-debounce-input';
class QAction extends Component{
    constructor(props) {
        super(props);
		this.state={
			newTrackingCode:'',
		}
    }
	
	addTracking=(event)=>{
        this.setState({addTracking:event.target.value});
	}
	
	addNewTracking=()=>{
		let trackingCode = this.state.addTracking;
		let spreadsheetID = config.spreadsheetId;
		
		if(trackingCode){
			var params = {
				spreadsheetId:  spreadsheetID,
				range:'Tracking Code!A:A', 
				valueInputOption: "RAW", 
				insertDataOption: "INSERT_ROWS",
				resource:{
					"values": 
					[[trackingCode]],
					"majorDimension": "ROWS"
				  }
			};
			let that = this;
			var request = gapi.client.sheets.spreadsheets.values.append(params);
			request.then(function(response) {
				that.setState({newTrackingCode:trackingCode});
				alert('Tracking Code added successfully!');
				$('#addTrackModal').modal('hide');
			}, function(reason) {
			console.error('error: ' + reason.result.error.message);
			});
		}
	}

    render(){
        const companies = this.props.companies;
        const trackingCode = this.props.trackingCode;
        const newTrackingCode = this.state.newTrackingCode;
		let pfb = '';
		if(this.props.defaultProps.rowAllData[111]){
			pfb = this.props.defaultProps.rowAllData[111];
		}else{
			pfb = this.props.defaultProps.rowAllData[9];
		}
		
		return(
            <div>
                <h5>Question Action:</h5>
                    <div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" value="Keep as is" checked={this.props.defaultProps.queAction === 'Keep as is'} onChange={this.props.handleChange} name="queAction" /> Keep As Is
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" value="Complex" checked={this.props.defaultProps.queAction === 'Complex'} onChange={this.props.handleChange} name="queAction"/>  Mark As Complex
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" value="Discard" checked={this.props.defaultProps.queAction === 'Discard'} onChange={this.props.handleChange} name="queAction" /> Mark To Discard Question
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" value="changeQueType" checked={this.props.defaultProps.queAction === 'changeQueType'} onChange={this.props.handleChange} name="queAction"/> Change Question Type
                            </label>
                            {this.props.defaultProps.changeQueType ? (
                                <div className="col-md-8 form-group">
                                    <label> Choose Question Type: </label>
                                    <select className="form-control" id="sel1" value={this.props.defaultProps.chooseAnsType} onChange={this.props.getValue} name="chooseAnsType">
                                        <option value="0">Please Select</option>
                                        <option value="q1">Q1-Predefined list of options</option>
                                        <option value="q2">Q2-User Defined Dropdown Menu (B+)</option>
                                        <option value="q3">Q3-Multi-select Dropdown</option>
                                        <option value="q4">Q4-Radio Buttons</option>
                                        <option value="q5">Q5-Single Checkbox</option>
                                        <option value="q6">Q6-Small Blank Field</option>
                                        <option value="q7">Q7-Large Blank Field</option>
                                        <option value="q8">Q8-Date Selection</option>
                                        <option value="q9">Q9-Extra Large Text Box</option>
                                        <option value="q10">Q10-Single Check Box Plus Details</option>
                                        <option value="q11">Q11-Multiple checkboxes select</option>
                                        <option value="q11b">Q11b-Multiple checkboxes+</option>
                                        <option value="Title">Title</option>
                                        <option value="Text">Text</option>
                                        <option value="timer">Timer</option>
                                        <option value="slider">Slider</option>
                                        <option value="video">Video Upload</option>
                                        <option value="file">File Upload</option>
                                        <option value="image">Image</option>
                                        <option value="sig">Sig</option>
                                    </select>
                                </div>
                            ):null}
                        </div>
                        
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" value="changeQueText" checked={this.props.defaultProps.queAction === 'changeQueText'} onChange={this.props.handleChange} name="queAction" /> Change Question Text
                            </label>
                        
                            {this.props.defaultProps.changeQueText ? 
                            <div className="col-md-8 form-group">
                                <label> New Question Text: </label>
                                
								<DebounceInput element="textarea" debounceTimeout={3000} className="form-control" rows={this.props.defaultProps.chooseAnsType == 'text' ? '9' : '5'} name="changeQueInpText" value={this.props.defaultProps.changeQueInpText} onChange={this.props.getValue} />
								
                            </div>
                            : null}
							
						</div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" value="changeAnsOpt" checked={this.props.defaultProps.queAction === 'changeAnsOpt'} onChange={this.props.handleChange} name="queAction" /> Change Answer Options
                            </label>
                        
                        {this.props.defaultProps.changeAnsOpt ?
                            <div className="col-md-8 form-group">
                                <label>Answer Options (separated by comma):</label>
                                <DebounceInput element="textarea" debounceTimeout={3000} className="form-control" rows="2" name="changeAnsInpText" value={this.props.defaultProps.changeAnsInpText} onChange={this.props.getValue} />
                            </div>
                        :null}
                        </div>
						
						
						
						<div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" value="changeValiType" checked={this.props.defaultProps.queAction === 'changeValiType'} onChange={this.props.handleChange} name="queAction" /> Change Validation Type
                            </label>
                        
                        {this.props.defaultProps.changeValiType ?
                            <div className="col-md-8 form-group">
                                <label>Choose Validation Type:</label>
                                
								<select className="form-control validation-box" value={this.props.defaultProps.validationType} name="validationType" onChange={this.props.getValue}>
									<option value="V1">V1 Text</option>
									<option value="V2">V2 Text Alphabet Only (no numbers)</option>
									<option value="V3">V3 Whole +Numbers and Zero</option>
									<option value="V4">V4 Whole +Numbers no Zero</option>
									<option value="V5">V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)</option>
									<option value="V6">V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11) </option>
									<option value="V7">V7 Percentage Positive Only</option>
									<option value="V8">V8 Fractions Positive Only</option>
									<option value="V9">V9 Dates Past</option>
									<option value="V10">V10	Dates Today Only</option>
									<option value="V11">V11	Dates Future</option>
									<option value="V12">V12	Time hh:mm:ss:ssss</option>
									<option value="V13">V13 Time ss:ssss</option>
								</select>
								
								{this.props.defaultProps.showMinMax ?
								<div>
								<div className="validation">
									<label>{"Min Value:"}</label>
									<input type="text" className="form-control min-val" name="minValue" value={this.props.defaultProps.minValue} onChange={this.props.getValue}/>
								</div>
								<div className="validation">
									<label>{"Max Value:"}</label>
									<input type="text" className="form-control max-val" name="maxValue" value={this.props.defaultProps.maxValue} onChange={this.props.getValue}/>
								</div>
								
								</div>
								:null}
                            </div>
                        :null}
                        </div>
						
						<div className="form-check">
							<label className="form-check-label">
                                <input type="radio" value="changeOutputText" checked={this.props.defaultProps.queAction === 'changeOutputText'} onChange={this.props.handleChange} name="queAction" /> Change Output Text
                            </label>
                        
                            {this.props.defaultProps.changeOutputText ? 
                            <div className="col-md-8 form-group">
                                <label> New Output Text: </label>
                                
								<DebounceInput element="textarea" debounceTimeout={3000} className="form-control" rows='3' name="changeOutInpText" value={this.props.defaultProps.changeOutInpText} onChange={this.props.getValue} />
								
                            </div>
                            : null}
						</div>
						
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" value="ChangeNotes" checked={this.props.defaultProps.queAction === 'ChangeNotes'} onChange={this.props.handleChange} name="queAction" /> Change Notes
                            </label>
                        
                            <div className="col-md-8 form-group" style={{ display: this.props.defaultProps.ChangeNotes ? 'block' : 'none'}}>
                                <label>Additional Notes:</label> &nbsp;&nbsp;<Button variant="danger" type="button" className="float-right clear-note" value="clearNote" onClick={() => { if(window.confirm('Are you sure you want to clear all Notes Content?')) this.props.handleClearNote() }}> Clear all notes contents </Button>
                                <Editor
                                    editorState={this.props.defaultProps.editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    wrapperStyle={{ border: "1px solid black", marginBottom: "20px" }}
                                    editorStyle={{ height: "300px", padding: "10px",maxWidth:'100%',maxHeight:'100%'}}
                                    editorClassName="editorClassName"
                                    onEditorStateChange={this.props.onEditorStateChange}
                                    toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                    image: { uploadCallback: this.props._uploadImageCallBack, alt: { present: true, mandatory: false },previewImage: true },
                                    inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
                                    }}
                                />
                            </div>
                        
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" value="ChangeTags" checked={this.props.defaultProps.queAction === 'ChangeTags'} onChange={this.props.handleChange} name="queAction" /> Change Tags
                            </label>
                        {this.props.defaultProps.ChangeTags ?
                            <div className="col-md-8 form-group">
                                <label>{'<<Tags>>'}</label>
                                <input type="text" className="form-control" name="addTagsText" onChange={this.props.getValue} value={this.props.defaultProps.addTagsText}/>
                            </div>
                        :null}
                        </div>
						<div className="form-check">
							<label className="form-check-label">
								<input type="radio" value="ChangeTracking" checked={this.props.defaultProps.queAction === 'ChangeTracking'} onChange={this.props.handleChange} name="queAction" /> Add/Change Tracking Code
							</label>
							{this.props.defaultProps.ChangeTracking ? (
								<div className="col-md-4 form-group">
									<div className="add-change-tracking">
										<select className="form-control company-select-1" value={this.props.defaultProps.chooseTracking} onChange={this.props.getValue} name="chooseTracking">
											<option value="">Please Select</option>
											{trackingCode.map((code, i) => (
												<option value={code}>{code}</option>
											))}
											{newTrackingCode ? 
											<option value={newTrackingCode}>{newTrackingCode}</option>
											:null}
										</select>
										<div className="col-md-company">
											<button type="button" data-target="#addTrackModal" data-toggle="modal" className="btn btn-primary mb-2">
												<i className="fas fa-plus"></i>
											</button>
										</div>
									</div>
								</div>
							):null}
						</div>
						
						{pfb == 'N' ?
							<div className="form-check">
								<label className="form-check-label">
									<input type="radio" value="ChangeCompany" checked={this.props.defaultProps.queAction === 'ChangeCompany'} onChange={this.props.handleChange} name="queAction" /> Change Company
								</label>
								{this.props.defaultProps.ChangeCompany ? (
									<div className="col-md-4 form-group">
										<select className="form-control" value={this.props.defaultProps.chooseCompany} onChange={this.props.getValue} name="chooseCompany">
											<option value="">Please Select</option>
											{companies.map((company, i) => (
												<option value={company}>{company}</option>
											))}
										</select>
										
									</div>
								):null}
							</div>
						
						:null}
						
                    </div>
					<div className="modal add-company" id="addTrackModal">
						<div className="modal-dialog modal-lg custom-modal">
							<div className="modal-content">
							  <div className="modal-header">
								<h5 className="modal-title">Add New Tracking Code</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							  </div>
							  <div className="modal-body">
									<input type="text" name="addCompany" onChange={this.addTracking} className="form-control" placeholder="Enter Tracking Code"/>
							  </div>
							  <div className="modal-footer">
									<div className="image-text" id="fileText"></div>
									<div className="popup-btn-com">
										<button type="button" className="btn btn-primary float-left" onClick={this.addNewTracking}>Save</button>
										<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									</div>
							  </div>

							</div>
						</div>
					</div>
                <div className="col-md-12">
                    <hr/>
                </div>
            </div>
        )
    }
}

export default QAction;