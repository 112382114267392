import React, { Component } from 'react';
import $ from 'jquery';
import 'bootstrap';
import config from "../config";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';

class updateUsers extends Component{
	constructor(props){
        super(props)
        this.state ={
            showMsg:false,
        }
    }
	
	componentDidMount(){
		gapi.load("client", this.initClient);
	}
	
	initClient = () => {
        gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: config.spreadsheetId,
				range:'Sidecar-Login', 
			})
			.then(
				response => {
					const data = response.result.values;
					let users = [];
					data.map((user,i)=>{
						if(i > 0 && i < 4){
							users.push(user);
						}
					});
					users = JSON.stringify(users);
					let apiUrl = 'https://sidecar-test.xyz/update-users-api';
					axios({
						method: 'POST',
						url: apiUrl,
						data: users,
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded'
						}
					}).then(response => {	
						this.setState({showMsg:true});
					})
					.catch(error => {
						console.log('error::', error);
					})
				},function(reason) {
					alert(reason.result.error.message);
				}
			);
		});
    };
	
	
	
	render(){
		const { mdsForms} = this.state;
		const { sheetConfigMod,sheetConfigId} = this.props;
		
		return(
			<div className="card-body manage-forms">
			<div className="container">
			{ this.state.showMsg ? 
				<div className="update-users-msg">Users Updated Successfully!</div>
			:null}	
			</div>
			</div>
		);
	}
}

export default updateUsers;