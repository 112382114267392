import React from 'react';

const Dropdown1 = (props) =>{
    var optionsHtml = props.mainQueKey.map(function(name,i) {
        var value = name.split('-');
        return (
            <option value={value[0]} field_id={value[1]+'-'+value[2] } key={i}>{i+1}-{value[1]+'-'+value[2] }</option>
        );
    })
    return <select className="form-control" id="selectionChamp1" value={props.queNo} onChange={props.addPrecondition}>
            <option value=""> Please Select</option>
            {optionsHtml}
        </select>
}

export { Dropdown1 }