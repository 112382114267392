import React from 'react';

const KioskDrop = (props) =>{
	 /*return  <select className="form-control kiosk_user" multiple={props.multiple == 'true' ? true :false} value={props.kioskUsers} id={props.QueUsersId} onChange={props.selectKioskUsers} style={{height:props.multiple == 'true' ? '210px' :'auto'}}>
                <option value="CaC">Care Coordinator (CC)</option>
                <option value="Pat">Patient (P)</option>
                <option value="Int">Intern (I)</option>
                <option value="Res">Resident (Res)</option>
                <option value="PrA">Practitioner Assistant (Pa)</option>
                <option value="PhT">Physical Therapist (PT)</option>
                <option value="CPO">Practitioner (CPO)</option>
                <option value="PhA">Physician Assistant (PA)</option>
                <option value="PmD">Physician (MD)</option>
            </select> */
	let kioskType = props.kioskType;
	if(kioskType){
		var optionsHtml = kioskType.map(function(type,i) {
			type = type.split(':');
			return (
				<option value={type[1]}>{type[0]}</option>
			);
		})	
	}
	return  <select className="form-control kiosk_user" multiple={props.multiple == 'true' ? true :false} value={props.kioskUsers} id={props.QueUsersId} onChange={props.selectKioskUsers} style={{height:props.multiple == 'true' ? '210px' :'auto'}}>
		{optionsHtml}
    </select>
}

export { KioskDrop }