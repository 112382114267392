import config from "../config";
import $ from 'jquery';
import { gapi } from 'gapi-script';

function removePrecondition(allData,QueSaveId,sheet, callback,selDropOpt) {
    console.log(callback);
    gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
    let spreadsheetID = "";
    if(selDropOpt == "original"){
        spreadsheetID = config.spreadsheetId;
    }else{
        spreadsheetID = config.pahseII_spreadsheetId;
    }
    var rowId ='';
    { allData.map((valu, i) => {  
        if(valu[0] === QueSaveId){
            rowId = (i+2);
        }
    })} 
    if ( !! gapi.auth.getToken()){
        var accessToken = gapi.auth.getToken().access_token;
        var params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
            range: sheet+'!H'+rowId+':I'+rowId,
        };
        var request = gapi.client.sheets.spreadsheets.values.clear(params);
        request.then(function(response) {
            callback();
            console.log(response.result);
        }, function(reason) {
        console.error('error: ' + reason.result.error.message);
        });
        
        $('#code').modal('hide');
    }else{
        console.log(gapi.auth.getToken())
    }
}
function showParentField(cond_id,allData,callback){
    let condit_id = cond_id;
    let parent = condit_id.split('-');
    let mainQues = [];
    let queNo1 = '';
    let ansRes = parent[2];
    { allData.map((answer, i) => {  
        if(answer[0] === parent[0]+'-'+parent[1]){
            queNo1=i;
            mainQues.push(answer);
        }
    })}
    var rowDatas = mainQues[0];
    if(rowDatas !== 'undefined'){
        var id = rowDatas[1]
        var location = rowDatas[9];
        var parents = rowDatas[2]
        var questions = rowDatas[4];
        var answerType = rowDatas[5];
        var options = rowDatas[6];
        var visibility = rowDatas[8];
        var visibility1= rowDatas[7];
        var action  = rowDatas[17];
        var field_id = rowDatas[0];
        let showMainFieldQue = 3;
        var rowAllData = [id,location,parents,questions,answerType,options,visibility,visibility1,field_id,showMainFieldQue,queNo1,ansRes];
        callback(rowAllData)         
    }
}
function editPrecondition(cond_id,allData,callback){
 
    let condit_id = cond_id;
    let parent = condit_id.split('-');
    let mainQues = [];
    let QueNoId = '';
    let preCondVal = parent[2];
    { allData.map((answer, i) => {  
        if(answer[0] === parent[0]+'-'+parent[1]){
            QueNoId = i;
            mainQues.push(answer);
        }
    })}
    var rowDatas = mainQues[0];
    if(rowDatas !== 'undefined'){
        var id = rowDatas[1]
        var location = rowDatas[9];
        var parents = rowDatas[2]
        var questions = rowDatas[4];
        var answerType = rowDatas[5];
        var options = rowDatas[6];
        var visibility = rowDatas[8];
        var visibility1 = rowDatas[7];
        var action  = rowDatas[17];
        var field_id = rowDatas[0];
        let editPreCond = 4;
        var rowAllData = [id,location,parents,questions,answerType,options,visibility,visibility1,field_id,editPreCond,QueNoId,preCondVal];
        callback(rowAllData)         
    }
}
   
export { removePrecondition, showParentField, editPrecondition }; 