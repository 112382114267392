import React, { Component } from 'react';
import $ from 'jquery';
import config from "../../config";
import { gapi } from 'gapi-script';
import Header from "../../component/Header/Header";
import { Dropdown1 } from "../../component/FormData/Dropdown";
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { Editor } from 'react-draft-wysiwyg';
import axios,{post,get} from 'axios';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';
import { removePrecondition, showParentField, editPrecondition} from "../../Helpers/Precondition";
import { generatehtmlfromlocation,handleAuthResult,newSheetDataSave, saveOriginalData } from "../../Helpers/Common";
import MainData from "../../component/MainData/MainData";
import QAction from "../../component/QAction/QAction";

class Review extends Component{
    
	constructor(props){
        super(props)
        this.state ={
            sheetData:[],
            rowAllData:'',
            queType:'',
            addNoteText:'',
            addTagsText:'',
            uploadedImages:[],
			pfbByCompany:'',
            editorState: EditorState.createEmpty(),
            OptSel:0,
            feedsResponse:new Map(),
            kioskResponse:new Map(),
            checkedNeeds:new Map(),
            checkedApprove:new Map(),
            checkedReq:new Map(),
            checkedKiosk:new Map(),
        }
    }
    
	subQuestions=(res)=>{
        this.setState({preCondVal:res})
    }
    
	getValue=(event)=>{
        this.setState({[event.target.name]:event.target.value});
    }
    
	setHeaderType=(event)=>{
        this.setState({chooseAnsType:event.target.value});
    }
    
	handleChange=(event)=>{
        let name = event.target.value;
        this.setState({queAction:event.target.value});
        this.setState({ChangeNotes:false,ChangeTags:false,changeAnsOpt:false,changeQueText:false,changeQueType:false,ChangeCompany:false});
        this.setState({[name]:true});
    }
	
	handleClearNote=()=>{
		this.setState({editorState:'',editorSourceHTML:''});
    }
    
	componentDidUpdate(){
        if(this.props.allData.length && this.state.checkedNeeds.size == 0){
            this.setDefaultCheckboxes();
        }
		if(this.props.hasModify){
			$('.comments').css('backgroundColor','#e0e0e0')
		}else{
			$('.comments').css('backgroundColor','#ffffff')
		}
    }

    handleNeeds = (e) => {
        const item = e.target.name;
        this.setState({name:''});
        $('#needs'+item).modal('show');
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedNeeds: prevState.checkedNeeds.set(item, isChecked)
        }));
    }
    
	handleApprove = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedApprove: prevState.checkedApprove.set(item, isChecked)
        }));
    }
    
	handleSubmit=()=>{
        var btnClicked = '';
        var functions = [this.state];
		let sheetConfigId = this.props.sheetConfigId;
        if(this.state.accessToken){
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true},handleAuthResult(sheetConfigId,btnClicked,functions,this.props.selDropOpt,this.props.updatedData,this.props.selectReviewOpt));
        }else{
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: false},handleAuthResult(sheetConfigId,btnClicked,functions,this.props.selDropOpt,this.props.updatedData,this.props.selectReviewOpt));
        }
    }
    
	saveNeedsNote=(id, data)=>{
		let spreadsheetID = this.props.sheetConfigId;
        gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: false});
        let accessToken = gapi.auth.getToken().access_token;
        //let spreadsheetID = config.spreadsheetId;
        
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        let rowId ='';
        { this.props.allData.map((valu, i) => {
            if(valu[0] === id){
                rowId = (i+2);
            }
        })}
        let allDatas = this.props.allData;
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: [{
                "range":this.props.selectedSheet+'!AB'+rowId,
                "majorDimension": "ROWS",
                "values": 
                [
                    [ data ]
                ]
            }], 
        };
        let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        request.then(function(response) {
            alert('Data saved successfully');
        }, function(reason) {
            alert(reason.result.error.message);
        });
        $('#needs'+id).modal('hide');
        this.props.updatedData(this.props.selectedSheet);
    }
    
	removeNeedsNote=(id)=>{
		let spreadsheetID = this.props.sheetConfigId;
        gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
        let accessToken = gapi.auth.getToken().access_token;
        //let spreadsheetID = config.spreadsheetId;
       
        let rowId ='';
            { this.props.allData.map((valu, i) => {
                if(valu[0] === id){
                    rowId = (i+2);
                }
            })}
        var params = {
            spreadsheetId: spreadsheetID,
            range: this.props.selectedSheet+'!AB'+rowId,
        };
        var request = gapi.client.sheets.spreadsheets.values.clear(params);
        request.then(function(response) {
            
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        }); 
        $('#needs'+id).modal('hide');
        this.props.updatedData(this.props.selectedSheet);
    }
    
	addPrecondition=(e)=>{
        var quesID = e.target.value;
        this.setState({queNo1:quesID});
        if(quesID !== 'undefined' ){
            var rowDatas = this.props.allData[quesID];
            if(rowDatas !== 'undefined'){

                this.setState({preCondID:rowDatas[0],preCondVal:''});
                var id = rowDatas[1]
                var location = rowDatas[9];
                var parents = rowDatas[2]
                var questions = rowDatas[4];
                var answerType = rowDatas[5];
                var options = rowDatas[6];
                var visibility1 = rowDatas[8];
                var visibility = rowDatas[7];
                var action  = rowDatas[17];
                var field_id = rowDatas[0];
                let editPreCond = 4;
                var rowAllData = [id,location,parents,questions,answerType,options,visibility1,visibility,field_id,editPreCond];
                this.generateQuestion(rowAllData)         
            }   
        }
    }
    
	dataFilterPfbCompany = (event) =>{
		let val = event.target.value;
		let allData = this.props.allData;
		let selOptions = this.state.selOptions;
		
		if($("input[name='includePFB']").prop("checked") == true){
			
			allData.map((rowDatas, i) => {
				if(val){
					
					
					if($("input[name='showPFB']").prop("checked") == true){
						if(rowDatas[115] != val && rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
							$('#'+rowDatas[0]).addClass("not-include");
						}
						
						if(rowDatas[115] == val && rowDatas[14] == 'Y'){
							$('#'+rowDatas[0]).removeClass("not-include");
						}
					}else{
						if(rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
							$('#'+rowDatas[0]).addClass("not-include");
						}
						
						if((rowDatas[14] == 'Y' && rowDatas[111] == 'Y') || (rowDatas[115] == val && rowDatas[14] == 'Y')){
							$('#'+rowDatas[0]).removeClass("not-include");
						}
					}
					
				}else{
					if($("input[name='showPFB']").prop("checked") == true){
						if(rowDatas[14] == 'Y' && rowDatas[111] == 'N'){
							$('#'+rowDatas[0]).removeClass("not-include");
						}
					}else{
						if(rowDatas[14] == 'Y'){
							$('#'+rowDatas[0]).removeClass("not-include");
						}
					}
				}
			})
			
			this.setState({pfbByCompany:val});
			
		}else{
			this.setState({pfbByCompany:''});
			alert('Please include PFB=N firstly!');
		}
		
		{ allData.map((rowDatas, i) => {
			if(rowDatas[14] == 'Y' && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
				let hasVisible=false;
				$('#'+rowDatas[0]).find('.question').each(function() {
					if(!$(this).hasClass("not-include")){
						hasVisible= true;
					}
				});
				if(hasVisible == false){
					$('#'+rowDatas[0]).addClass("not-include");
				}else{
					$('#'+rowDatas[0]).removeClass("not-include");
				}
			}
		})}
	}
	
	dataFilterPfb = (event, optionVal) => {
		let allData = this.props.allData;
		let selOptions = optionVal;
		if(optionVal == undefined){
			selOptions = this.state.OptSel;
		}
		
		if($("input[name='includePFB']").prop("checked") == true){
			allData.map((rowDatas, i) => {
				if(rowDatas[14] == 'Y' && rowDatas[111] == 'N'){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
            })
			
			if($("input[name='showPFB']").prop("checked") == true){
				allData.map((rowDatas, i) => {
					if(rowDatas[111] == 'Y' && rowDatas[5] != 'Parent Header' && rowDatas[5] != 'Child Header 1' && rowDatas[5] != 'Child Header 2' && rowDatas[5] != 'Child Header 3'){
						$('#'+rowDatas[0]).addClass("not-include");
					}
				})
			}else{
				allData.map((rowDatas, i) => {
					if(rowDatas[14] == 'Y'){
						$('#'+rowDatas[0]).removeClass("not-include");
					}
				})
			}
        }else{
			allData.map((rowDatas, i) => {
				if(rowDatas[14] == 'Y' && rowDatas[111] == 'N'){
					$('#'+rowDatas[0]).addClass("not-include");
				}
				if(rowDatas[14] == 'Y' && rowDatas[111] == 'Y'){
					$('#'+rowDatas[0]).removeClass("not-include");
				}
            })
			$("input[name='showPFB']").prop( "checked",false );
			this.setState({pfbByCompany:''});
		}
		
		{ allData.map((rowDatas, i) => {
			if(rowDatas[14] == 'Y' && (rowDatas[5].toLowerCase() == 'parent header' || rowDatas[5].toLowerCase() == 'child header 1' || rowDatas[5].toLowerCase() == 'child header 2' || rowDatas[5].toLowerCase() == 'child header 3')){
				let hasVisible=false;
				$('#'+rowDatas[0]).find('.question').each(function() {
					if(!$(this).hasClass("not-include")){
						hasVisible= true;
					}
				});
				if(hasVisible == false){
					$('#'+rowDatas[0]).addClass("not-include");
				}else{
					$('#'+rowDatas[0]).removeClass("not-include");
				}
			}
		})}
		
	}
	
	dataFilter =(event)=>{
        let optionVal = event.target.value;
        this.setState({OptSel:optionVal});
        let t
        let allDatas = this.props.allData;
        let appData = this.state.checkedApprove;
        let obj = Object.fromEntries(appData.entries());
        let needData = this.state.checkedNeeds;
        let obj1 = Object.fromEntries(needData.entries());
        let allData = [];
        let arr = [];
        let arr1 = [];
        for (let prop in obj) {
            arr.push(prop+':'+obj[prop]);
        }
        for (let prop1 in obj1) {
            arr1.push(prop1+':'+obj1[prop1]);
        }
        if(optionVal == '0'){
            t = 0;
            allData = this.props.allData;
        }else if(optionVal == '1'){
            { allDatas.map((rowDatas, i) => { 
                let id = rowDatas[0];
                let intersection = arr1.filter(x => !arr.includes(x));
                { intersection.map((val, lock) => { 
                    let interId = val.split(':');
                    if(interId[0] == id && interId[1] == 'true'){
                        allData.push(rowDatas);
                    } 
                })}
            })}
            t =1;
        }else if(optionVal == '2'){
            { allDatas.map((rowDatas, i) => { 
                let id = rowDatas[0];
                let intersection = arr.filter(x => !arr1.includes(x));
                { intersection.map((val, lock) => { 
                    let interId = val.split(':');
                    if(interId[0] == id && interId[1] == 'true'){
                        allData.push(rowDatas);
                    } 
                })}
            })}
            t =1;
        }else if(optionVal == '3'){
            { allDatas.map((rowDatas, i) => { 
                let id = rowDatas[0];
                let intersection = arr.filter(x => arr1.includes(x));
                { intersection.map((val, lock) => { 
                    let interId = val.split(':');
                    if(interId[0] == id && interId[1] == 'true'){
                        allData.push(rowDatas);
                    } 
                })}
            })}
            t =1;
        }
		
		this.props._answer(allData,t)
		
		let that = this;
		setTimeout(function(){
			if($("input[name='includePFB']").prop("checked") == true){
				that.dataFilterPfb(event, optionVal);
			}
		}, 2000);
    }
	
    generateQuestion=(rowAllData)=>{
        if(rowAllData[9] === 3){
            this.setState({showFieldText:!this.state.showFieldText,showPreDiv:!this.state.showPreDiv,queNo1:rowAllData[10],preCondVal:rowAllData[11]});
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.displayAddData = <div id={rowAllData[0]} key={rowAllData[0]} visibility1={rowAllData[6]}><h5>{question}</h5><p>{answer}</p></div>;

        }else if(rowAllData[9] === 4){
            this.setState({queNo1:rowAllData[10],preCondVal:rowAllData[11]});
            var question = rowAllData[3];
            var answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
            this.displayAddData = <div id={rowAllData[0]} key={rowAllData[0]} visibility1={rowAllData[6]}><h5>{question}</h5><p>{answer}</p></div>;
        }   
    }
    
	preConFunc=(cond_id)=>{
        this.setState({showPreDiv:true,preCondVal:''});
        if(cond_id){
            editPrecondition(cond_id,this.props.allData,this.generateQuestion);
        }
    }
    
	setDefaultCheckboxes=()=>{
        let checkedNeeds = this.state.checkedNeeds;
        { this.props.allData.map((valu, i) => {
            let isChecked ="";
            if(valu[27]){
                isChecked =true;
            }else{
                isChecked=false
            }
            checkedNeeds.set(valu[0], isChecked)
        })}
        this.setState({checkedNeeds: checkedNeeds});
    }
    
	showAllQues=()=>{
        if($("input[name='showQ']").prop("checked") == true){
            $('.pre-question').show();
        }else{
            $('.pre-question').hide();
        }
    }
    
	onEditorStateChange=(editorState)=>{
        var convertedData = editorState.getCurrentContent()
        let d =  convertToRaw(editorState.getCurrentContent()) 
        let editorSourceHTML = draftToHtml(d)
        this.setState({
            editorState,editorSourceHTML
        });
    }
    
	_uploadImageCallBack=(file)=>{
        let uploadedImages = this.state.uploadedImages;

        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        uploadedImages.push(imageObject);
        this.setState({uploadedImages: uploadedImages})
        let con = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'multipart/form-data'
			}
		}
		
		let url = config.noteImageApiUrl;
		return new Promise(
		(resolve, reject) => {
			var form_data = new FormData();
			form_data.append('file', file);
			post(url, form_data, con)
			.then(response => {
				console.log('response->',response.data);
				resolve({ data: { link: response.data } });
			})
		});
    }
    
	myFunction =(rowData)=>{
        this.setState({ChangeNotes:false,ChangeTags:false,changeAnsOpt:false,changeQueText:false,changeQueType:false,ChangeCompany:false});
        this.setState({showPreDiv:false,changeQformat:false,addTagsText:'',addNoteText:'',queAction:'',changeAnsInpText:'',changeQueInpText:'',chooseAnsType:'',chooseCompany:'',});
        this.setState({preCondVal:'',preCondID:'',queNo:'',preCondition:rowData[7]+'-'+rowData[8],showFieldText:true,allData:this.props.allData,selectedSheet:this.props.selectedSheet}); 
        let question   ='';
        let answerType ='';
        let option     ='';
        let addTagsText='';
        let addNoteText='';
        let action = rowData[17];
		let companyNames = rowData[115];
        if(this.props.selectedOpt == 'improved'){
            question = rowData[23] ? rowData[23] :rowData[4];
            answerType = rowData[24] ? rowData[24] :rowData[5];
            option = rowData[22]  ? rowData[22] :rowData[6]
            addNoteText=rowData[18] ? rowData[18] : rowData[12];
            addTagsText= rowData[19] ? rowData[19] :rowData[13];
        }else{
            question   = rowData[4];
            answerType = rowData[5];
            option     = rowData[6];
            addNoteText=rowData[12];
            addTagsText= rowData[13];
        }
        
        var id = rowData[1]
        var location = rowData[9];
        var parent = rowData[2]
        var visibility1 = rowData[8];
        var visibility = rowData[7];
        var field_id = rowData[0];
		var pfb = rowData[111];
        let rowAllData = [id,location,parent,question,answerType,option,visibility1,visibility,field_id,pfb];
		this.setState({rowAllData})
        this.props.description.map((answer, i) => {  
            if(answer[1] === rowData[5]){
               this.setState({queDescription:answer[2]}); 
            }
        })
        if(rowData[5].toLowerCase() == 'text' || rowData[5].toLowerCase() == 'title' ){
        
            this.setState({TextDiv:true,queDescription:'',chooseAnsType:answerType.toLowerCase(),chooseCompany:companyNames,changeQueInpText:question,changeAnsInpText:option,showQueAction:true,headerDiv:false,Question:question,queAction:'changeQformat'});  
            this.setState({QuestionId:rowData[0],Location:rowData[9],saveQueId:rowData[0],queType:answerType.toUpperCase(),addTagsText:addTagsText,addNoteText:addNoteText,QuestionShowDiv:false}); 
        } else if(rowData[5].toLowerCase() == 'child header 1' || rowData[5].toLowerCase() == 'child header 2' || rowData[5].toLowerCase() == 'child header 3'){
            
            this.setState({headerDiv:true,changeQueInpText:question,chooseAnsType:answerType.toLowerCase(),chooseCompany:companyNames,changeAnsInpText:option,showQueAction:false,TextDiv:false,Question:question,queDescription:'',queAction:'changeQformat'});
            this.setState({QuestionId:rowData[0],Location:rowData[9],saveQueId:rowData[0],queType:answerType.toUpperCase(),addTagsText:addTagsText,addNoteText:addNoteText,QuestionShowDiv:false}); 
        } else{
           
            this.setState({QuestionId:rowData[0],changeQueInpText:question,chooseAnsType:answerType.toLowerCase(),chooseCompany:companyNames,changeAnsInpText:option,Location:rowData[9],saveQueId:rowData[0],Question:question,queType:answerType.toUpperCase(),headerDiv:false,addTagsText:addTagsText,addNoteText:addNoteText,showQueAction:true,TextDiv:false,QuestionShowDiv:true});
        }
        var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
        var imgUrl  = addNoteText;
        const html = `<p>`+imgUrl+`</p>`;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
              editorState,
            });
        }
        this.setState({addNoteText1:imgUrl,addTagsText1:addTagsText,addNoteText:addNoteText,addTagsText:addTagsText});
        const imageType = rowData[5].toLowerCase();
        this.setState({showImage:'/'+imageType+'.png'});
        let answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
        this.setState({Answer:answer});
        if(rowData[7]){
            this.setState({showPreCon:true,preCondition:rowData[7]+'-'+rowData[8],QueSaveId:rowData[0]});
        }else{
            this.setState({showPreCon:false,preCondition:''});
        }
        $('#code').modal('show');
		
    }
    
	render(){
        const { QuestionId,Location,queType,showReword,showPreDiv,showPreCon,changeQformat,showImageBtn,rowAllData,TextDiv,queDescription,QuestionShowDiv,headerDiv,showQueAction,OptSel}= this.state;
        const { sheetData,allData,description,selectedSheet,showDataDiv,selDropOpt,selectedSheet1, clearHeaderFilter,clearAllFilter,handleHeaderFilter , assoType, assoVisitType, assoDeviceType,kioskResponse, getDirectoryFiles, apiPdfs,companies} =this.props;
		
		const Companydropdown = () =>{
			let companyName = [];
			if(allData){
				allData.map(function(val,i) {
					if(val[111] == 'N' && val[115]){
						companyName.push(val[115]);
					}
				})
			}	
			
			var uniqueNames = [];
			$.each(companyName, function(i, el){
				if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
			});
			
			var optionsHtml = uniqueNames.map(function(val,i) {
				
				return (
					<option value={val} key={i}>{val}</option>
				);
				
			})
			return <select className="form-control" value={this.state.pfbByCompany} onChange={this.dataFilterPfbCompany} >
					<option value=""> Please Select</option>
					{optionsHtml}
				</select>
			
        }
		
        let moduleName="review_approve";
		
        return(
            <div className="card-body">
                <Header 
                    reviewSheetsName= {this.props.reviewSheetsName}
                    sheetsName = {this.props.sheetsName} 
                    onSelect={this.props.onSelect}
                    mdsGuid={this.props.mdsGuid}
                    moduleName={moduleName}
                    reviewVer={this.props.reviewVer}
                    showDataDiv = {showDataDiv}
                    handleHeaderFilter={handleHeaderFilter}
                    clearAllFilter = {clearAllFilter}
                    selectedSheet1 ={selectedSheet1}
                    selectReviewOpt = {this.props.selectReviewOpt}
                    selectedOpt = {this.props.selectedOpt}
                    selectedSheet = {selectedSheet}
                    sheetData = {sheetData}
                    showAllQues = {this.showAllQues}
                    dataFilter = {this.dataFilter}
					dataFilterPfb = {this.dataFilterPfb}
					Companydropdown={<Companydropdown/>}
                    assoType = {assoType}
                    assoVisitType ={assoVisitType}
                    assoDeviceType ={assoDeviceType} 
                    OptSel ={this.state.OptSel}/>
                    {sheetData && showDataDiv? 
                        <div className="card-body1">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-4">
                                        Need Attention
                                    </div>
                                    <div className="col-md-4">
                                        Approved
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <MainData 
                            sheetData = {this.props.sheetData}
                            getDirectoryFiles={getDirectoryFiles}
                            moduleName= {moduleName}
                            myFunction= {this.myFunction}
                            allData = {allData}
                            showSign={this.props.showSign}
                            selDropOpt = {selDropOpt}
                            kioskResponse={kioskResponse}
                            selectedSheet = {selectedSheet}
                            saveNeedsNote = {this.saveNeedsNote}
                            feedsResponse={this.state.feedsResponse}
                            removeNeedsNote = {this.removeNeedsNote}
							apiPdfs = {this.props.apiPdfs}
                            handleNeeds={this.handleNeeds}
                            handleApprove={this.handleApprove}
                            selectedOpt = {this.props.selectedOpt}
                            checkedNeeds={this.state.checkedNeeds}
                            checkedApprove={this.state.checkedApprove}
                            checkedReq={this.state.checkedReq}
                            checkedKiosk={this.state.checkedKiosk}/>
                        <div className="row">
                            <div className="col-md-12">
                                <Button type="button" className="float-left" onClick={()=>newSheetDataSave(this.props.allData, this.props.selectedSheet, this.props.updatedData)}>Accept Improved Changes</Button>
                                <Button type="button" className="float-right" onClick={()=> saveOriginalData(this.state,this.props,moduleName)}>Save </Button>
                            </div>
                        </div>
                        </div>
                    :null}
                <div className="modal" id="SaveSheetName">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Enter Sheet Name</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <label className="label-control"> Enter a sheet name </label>
                                <input type="text" name="setSheetName" value ={this.state.setSheetName} onChange={this.getValue} maxLength={20} className="form-control"/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary float-left" onClick={()=>newSheetDataSave(this.state.setSheetName,this.props.selDropOpt,this.props.selectedSheetId, this.props.selectedSheet1)} data-dismiss="modal">Save</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="code" style={{display: "none"}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="col-md-12">
                                    <div className="row">
                                        <h5>Field Name: {QuestionId}</h5>
                                    </div>
                                    <div className="row">
                                        <p><b>Location:</b> { Location }</p>
                                    </div>
                                   
                                    <div className="row">
                                        
                                        {queDescription ? <button type="button" className="btn btn-primary mb-2" id='showimage' data-toggle="modal" data-target="#myImageModal">
                                              Show Image
                                        </button>:null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <p className="mb-0"><b>Preconditions:</b>  {this.state.preCondition} {showPreCon ? (<div> <button type="button" className="btn btn-primary mb-2 mt-2" data-toggle="modal" onClick={() => showParentField(this.state.preCondition,this.props.allData,this.generateQuestion)} data-target="#showMainField">{this.state.showFieldText? 'Show Field' : 'Hide Field'}</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => this.preConFunc(this.state.preCondition) }> Edit Precondition</button> <button type="button" className="btn btn-primary mb-2 mt-2" onClick={() => { removePrecondition(this.props.allData,this.state.QueSaveId,this.props.selectedSheet,this.props.updatedData,this.props.selDropOpt)}}> Remove Precondition</button> </div> ):<button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.preConFunc()}> 
                                            Add Precondition</button>}</p>
                                    </div>
                                </div>
                                <hr/>
                                {showPreDiv ? ( 
                                <div className="col-md-12">
                                    <div className="col-md-12">
                                        {this.displayAddData}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Field Name:</label>
                                                <Dropdown1 
                                                    mainQueKey={this.props.mainQueKey}
                                                    queNo={this.state.queNo1} 
                                                    addPrecondition={this.addPrecondition}/>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Conditional Option:</label>
                                                <textarea className="form-control" rows="4" col="4" id="comment" value ={this.state.preCondVal} name="text" readOnly></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>):null}
                                {TextDiv ? (
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5>{this.state.Question}</h5>
                                        <label>Edit Text:</label>
                                        <textarea value={this.state.changeQueInpText} name="changeQueInpText" rows="6" onChange={this.getValue} className="form-control" />
                                    </div>
                                    
                                </div>):null}
                                {QuestionShowDiv ? (
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5>{this.state.Question}</h5>
                                    </div>
                                    <div className="col-md-12">
                                        <p>{this.state.Answer}</p>
                                    </div>
                                    <div className="col-md-12">
                                        <hr/>
                                    </div>
                                </div>) :null }
                                
                                {headerDiv? (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5>Header : {this.state.Question}</h5>
                                            <label>Select Header Type:</label>
                                            <select className="form-control" value={this.state.chooseAnsType} onChange={this.setHeaderType}>
                                                <option value="">Please Select</option>
                                                <option value='Child Header 1'>Child Header 1</option>
                                                <option value='Child Header 2'>Child Header 2</option>
                                                <option value='Child Header 3'>Child Header 3</option>
                                            </select>
                                            <label>Edit Text:</label>
                                            <input value={this.state.changeQueInpText} name="changeQueInpText" rows="8" onChange={this.getValue} className="form-control" />
                                        </div>
                                    </div>) 
                                : null}
                               {showQueAction ? (
                                    <QAction
                                        defaultProps = {this.state} 
                                        handleChange={this.handleChange}
                                        handleClearNote={this.handleClearNote}
                                        rowAllData={rowAllData} 
                                        companies={companies} 
                                        getValue={this.getValue}
                                        mainQues={this.props.mainQues}
                                        _uploadImageCallBack = {this._uploadImageCallBack}
                                        onEditorStateChange ={this.onEditorStateChange}
                                        queActionDataClear={this.state.queActionDataClear}
                                        />
                                 ):null}
                                <div className="col-md-12 form-group">
                                    <label>Additional Notes:</label>
                                    <p>
                                        { ReactHtmlParser(this.state.addNoteText1) }
                                    </p>
                                </div>
                                <div className="col-md-12 form-group">
                                     <div className="col-md-8 form-group">
                                        <b>{'<<Tags>> : '}</b>{this.state.addTagsText1}
                                    </div>
                                </div>  
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary float-left" type="button" onClick={this.handleSubmit} style={{textAlign:'left'}} >Save </button>
                                <button type="button" className="btn btn-danger"  data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="myImageModal" style={{zIndex:'1051'}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Image</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <img src={this.state.showImage} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Review;