import $ from 'jquery';
import '../containers/App.css';
import 'bootstrap';
import config from "../config";
import Login from "./Login";
import MainHeader from "../component/Header/MainHeader";
import FormEditData from "../component/FormData/Edit_Form_Data";
import FormBuilder from "../component/FormBuilder/FormBuilder";
import FormatForm from "../component/FormatForm/FormatForm";
import TestForm from "../component/TestForm/TestForm";
import ManageForms from "../component/ManageForms/ManageForms";
import JsonFiles from "../component/ManageForms/JsonFiles";
import MdsForms from "../component/ManageForms/MdsForms";
import NoteTemplate from "../component/NoteTemplate/NoteTemplate";
import Review from "../component/ReviewApprove/Review";
import React, { Component } from "react";
import axios,{post,get} from 'axios';
import { load, getSheetsById, getSheetType, getSheetsIdByName, uploadFilePath, getPdfFromApi, getConfigData } from "../Helpers/Common";
import { gapi } from 'gapi-script';
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { Router, Route, Link, browserHistory, IndexRoute, Redirect} from 'react-router'

class ClinicalTracks extends Component {

    constructor(props) {
        super(props);
        this.displayData=[];
        this.state = {
            mainQueKey:[],
            mainQues:[],
            allData:[],
            mdsGuid:'',
            assoType:[],
            apiPdfs:[],
            assoDeviceType:[],
            assoVisitType:[],
            sheetfunc:true,
            companyFilter:'',
            pfbByCompany:'',
            sheetsName: [],
            showImpData:false,
            selectedOpt:'original',
            reviewSheetsName:[],
            configSheets:[],
            kioskResponse:new Map(),
            sheetsNameOrig:[],
            reviewSheetsNameOringn:[],
            setKiosk:false,
            hasModify:false,
            mod:'edit_form_data',
			companies:[],
			trackingCode:[],
			configData:[],
			//sheetConfigId:config.spreadsheetId,
			sheetConfigId:config.mdsSpreadsheetId,
			sheetConfigMod:'parentMds',
        }
        this.fileArray = [];
        this.childFormBuilder = React.createRef();
        this.reviewSheetNames = this.reviewSheetNames.bind(this);
		if( localStorage.getItem('username') && localStorage.getItem('username') != 'admin'){
            localStorage.removeItem('username');
			window.location = '/';
        }
		
    }
    
    componentDidMount() {
        gapi.load("client", this.initClient);
        
    }
	
    initClient = () => {
        gapi.client.init({
           apiKey: config.apiKey,
           'clientId': config.client_id,
           'scope': 'https://www.googleapis.com/auth/spreadsheets',
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: false});
            if (this.state.sheetsName.length < 1) {
                load(this.onLoad,this.state.sheetConfigId);
            }
        });
    };
	
	changeSheetId=(sheetMod)=>{
		//let sheetConfigId = config.spreadsheetId;
		let sheetConfigId = config.mdsSpreadsheetId;
		/* if(sheetMod == 'parentMds'){
			sheetConfigId = config.mdsSpreadsheetId;
		}else if(sheetMod == 'fieldSet'){
			sheetConfigId = config.childSpreadsheetId;
		} */
		if(sheetMod == 'fieldSet'){
			sheetConfigId = config.childSpreadsheetId;
		}
		this.setState({sheetConfigId,sheetConfigMod:sheetMod});
		
		gapi.client.init({
           apiKey: config.apiKey,
           'clientId': config.client_id,
           'scope': 'https://www.googleapis.com/auth/spreadsheets',
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
            load(this.onLoad,this.state.sheetConfigId);
        });
	}
	
    onLoad = (data, error) => {
		if (data) {
            const sheetsName = data.sheetsName.slice(0,3);
            const sheetsId = data.sheetsId.slice(0,3);
            this.getDescription();
            this.MultiLangSheetLoad();
            let sheetpro = [];
            let sheetsNames = data.sheetsName;
            { sheetsNames.map((sheet, i) => {
                let sh ={id:data.sheetsId[i], name:sheet, type:'', deviceType:'', VisitType:'',visibility:''};
                    //if(i <= config.showSheetInDrop){
                        data.sheetType.map((sheetName,j)=>{
                            if(sheetName.visibility == 'TRUE' && sheet == sheetName.name ){
                                sh = {id:data.sheetsId[i], name:sheet, type:data.sheetType[j].type, deviceType:data.sheetType[j].deviceType, visitType:data.sheetType[j].visitType, visibility:data.sheetType[j].visibility};
                            }
                        })
                        sheetpro.push(sh);
                    //}
            })};
			
			let visibileSheet = [];
			{ sheetpro.map((sheet, i) => {
				if(sheet.visibility == 'TRUE' && visibileSheet.length <= config.showSheetInDrop){
					visibileSheet.push(sheet);
				}
			})};
			
            this.setState({ sheetsName:visibileSheet });
            this.setState({ sheetsNameOrig:visibileSheet });
			let firstSheet = visibileSheet[0].name;
			this.loadFilter(firstSheet);
        } else {
            alert(error);
            this.setState({ error });
        }
		
		let that = this;
		getPdfFromApi().then(function(response) {
			that.setState({apiPdfs:response});
		}, function(error) {
		  console.error("Failed!", error);
		})
		
    };
	
    loadFilter(sheet){
		let sheetConfigId = this.state.sheetConfigId;
        let params1 =  {
            spreadsheetId: sheetConfigId,
            range:sheet+'!CW2:CY2',
        };
        var that = this;
        var assoType =[];
        var assoDeviceType =[];
        var assoVisitType =[];
            let getRequest = gapi.client.sheets.spreadsheets.values.get(params1);
            getRequest.then(function(response) {
                var getData= response.result.values;
                
                if(getData && getData[0][0]){
                    var arr = getData[0][0].split(',');
                    arr.map((t,i)=>{
                        assoType.push(t);
                    })
                    
                }
                if(getData && getData[0][1]){
                    var arr = getData[0][1].split(',');
                    arr.map((t,i)=>{
                        assoDeviceType.push(t);
                    })
                    
                    
                        
                }
                if(getData && getData[0][2]){
                    var arr = getData[0][2].split(',');
                    arr.map((t,i)=>{
                        assoVisitType.push(t);
                    })      
                }
                
                that.setState({assoType,assoVisitType,assoDeviceType});
            })
    }
	
    clearAllFilter=()=>{
        if(this.state.mod == 'format_forms'){
            this.setState({sheetsName:this.state.reviewSheetOrign,reviewSheetsName:this.state.reviewSheetsNameOringn})    
        }else{
            this.setState({sheetsName:this.state.sheetsNameOrig,reviewSheetsName:this.state.reviewSheetsNameOringn})
        }
    }
	
    showSign=(type,id)=>{
        $('#'+type+id).css('display','block')
    }
	
    reviewSheetNames(mod){
		let configName = 'TTEVAL-Config Files';
		gapi.client.init({
           apiKey: config.apiKey,
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
			gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: config.spreadsheetId,
					range:configName,
				})
				.then(response => {
					let sheetVal = response.result.values;
					let sheets = [];
					sheetVal[0].map((value, i) => {
						if(i > 0){
							let configId = value[4];
							let col = [];
							sheetVal.map((val, j) => {
								let column = val[i];
								if(column){
									col.push(column);
								}
							});
							
							sheets.push(col);
						}
					});
					
					let selectedSheet = this.state.selectedSheet;
					
					sheets.map((sheet, i) => {
						if(selectedSheet != sheet[1]){
							delete sheets[i];
						}
					})
					
					sheets = sheets.filter(function (el) {
					  return el != null;
					});
					
					let sheetpr = [];
					
					sheets.map((sheet, i) => {
						let sht = {name:sheet[0], type:sheet[6], deviceType:sheet[7], visitType:sheet[8], mdsGuid:sheet[2], configGuid:sheet[4], date:sheet[3]};
						
						sheetpr.push(sht);

					});
					
					/* let filters = that.state.filters;
					let sheetpro = [];
					
					if(filters){
						sheetpro = that.filterPlainArray(sheetpr, filters);
					}else{
						sheetpro = sheetpr;
					} */
					//console.log('sheetpro->',sheetpro);
					
					this.setState({configSheets:sheets, reviewSheetsName:sheetpr, reviewSheetsNameOringn:sheetpr});
					
					//console.log('sheetpr->',sheetpr);
				},
				);
			});	
		}); 
        
    }

    onSelect = (sheetsName, selectedSheetId) => {
		let spreadsheetId = config.spreadsheetId;
		let sheetConfigId = this.state.sheetConfigId;
        var that = this;
		this.setState({selectedSheet1:sheetsName,selectedSheet:sheetsName,selDropOpt:'original',selectedSheetId, reviewVer:'original',showImpData:false});
        var  singleSheetName = sheetsName;
        gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: sheetConfigId,
                range:singleSheetName, 
            })
            .then(
                response => {
                    const allData = response.result.values;
					if(allData.length > 0){
						let mdsGuid = allData[1][91];
						this.setState({mdsGuid});
					}
                    allData.shift();
                    this.setState({mainData:allData,showDataDiv:true});
                    this.setState({ allData });
                    this.setState({showLangData:false});
					if(this.state.mod != 'edit_form_data'){
						this.reviewSheetNames(this.state.mod);
					}
                    
                    this.mainQuestions(allData);
                    if(this.state.mod == 'form_builder' || this.state.mod == 'format_forms'){
                        this.childFormBuilder.current.setDefaultCheckboxes();
                    }
                    let t =0;
                    this._answer(allData,t)
					
                },function(reason) {
					alert(reason.result.error.message);
				}
            );
        });
		
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:'Company Name', 
			})
			.then(
				response => {
					const companyName = response.result.values;
					this.setState({ companies:companyName });
					
				},function(reason) {
					alert(reason.result.error.message);
				}
			);
		});
		
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:'Tracking Code', 
			})
			.then(
				response => {
					const trackingCode = response.result.values;
					this.setState({ trackingCode });
					
				},function(reason) {
					alert(reason.result.error.message);
				}
			);
		});
		
    }
	
    selectReviewOpt=(reviewStatus, selectedSheetId, sheetName, expandData=false)=>{
		//this.setState({selectedSheetId,selectedOpt:reviewStatus});
		this.setState({selectedOpt:reviewStatus,pfbByCompany:'',selDropOpt:''});
        let sheetN ='';
        let t ='';
        if(reviewStatus == 'original'){
            let allData = this.state.allData;
			this.setState({exportData:''});
			this.setState({configData:''});
            t =0;
            this.updatedData();
            this.setState({selectedOpt:reviewStatus,reviewVer:reviewStatus},()=>this._answer(allData,t));
        }else if(reviewStatus == 'improved' && this.state.mod == 'edit_form_data'){
			this.setState({reviewVer:'improved'})
		}else if(reviewStatus == 'improved' && this.state.mod == 'format_forms'){
            this.setState({reviewVer:'improved'})
            this.newUpdatedData();
        }else if((reviewStatus == 'improved' && this.state.mod == 'review_approve') || (reviewStatus == 'improved' && this.state.mod == 'form_builder') ){
			this.setState({showImpData:true,reviewVer:'improved'});
            this.updatedData(sheetName, expandData);
            let allData = this.state.allData;
            //this.setState({selectedOpt:reviewStatus},()=>this._answer(allData,t));
            this.setState({selectedOpt:reviewStatus});
        }else if(reviewStatus !== 'improved' && reviewStatus !== 'original'){
			$("input[name='includePFB']").prop( "checked",true );
			this.setState({selDropOpt:'exported',showImpData:false,exportSheet:true,reviewVer:reviewStatus});
            let  singleSheetName = reviewStatus;
			let configSheets = this.state.configSheets;
			let configData = getConfigData(singleSheetName, configSheets);
			if(configData.length > 0){
				let allData = this.state.allData;
				this.setState({configData});
				allData.map((data,i)=>{
					if(configData[i]){
						if(data[0] == configData[i][0]){
							for (let j in allData) {
								
								if(j==10 && this.state.mod != 'form_builder'){
									//data[j] = configData[i][7];
								}
								if(j==14){
									data[j] = configData[i][5];
								}
								if(j==15){
									data[j] = configData[i][6];
								}
								if(j==16){
									data[j] = configData[i].join();
								}
								if(j==37){
									data[j] = configData[i][8];
								}
								
							}
						}
					}else{
						data[14] = 'N';
						if(this.state.mod != 'form_builder'){
							//data[14] = 'N';
							//data[10] = 0;
						}
					}
				})
				
				this.setState({mainData:allData});
				this.setState({allData:allData});

				this.mainQuestions(allData);
				if(this.state.mod == 'form_builder' || this.state.mod == 'format_forms'){
					this.childFormBuilder.current.setDefaultCheckboxes();
				}
				t =0;
				this._answer(allData,t)
			}
        }
    }
	
    hideSection=(inc)=>{
        var that =this;
        setTimeout(function(){
            let allDatas = that.state.allData;
            let cl = '';
            
            allDatas.map((val, lock) => { 
                
                if(val[5] == 'Child Header 1' || val[5] == 'Child Header 2' || val[5] == 'Child Header 3'){
                    let checkedbox= false;
                    $('#'+val[0]).find('.'+inc+':checked').each(function() {
                        checkedbox= true;
                    });
                    if(checkedbox == false){
                        $('#'+val[0]). addClass("not-include");
                    }else{
                        $('#'+val[0]). removeClass("not-include");
                    }
                }
            })
        }, 1000);
    }
    
	getLangData=(sheetsName)=>{
        var  singleSheetName = sheetsName;
        gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: config.language_spreadsheetId,
                range:singleSheetName, 
            })
            .then(
                response => {
                    const languageData = response.result.values;
                    languageData.shift();
                    this.setState({languageData:languageData});
                    this.setState({showLangData:true});
                    let t =0;
                    this._answer(this.state.allData,t);
                },
            );
        });
    }
    
	mainQuestions(allData,company=''){
		var mainQues = [];
        var mainQueKey = [];
		let showPFB = false;
		let includePFB = false;
		if($("input[name='includePFB']").prop("checked") == true){
			includePFB = true;
        }
		if($("input[name='showPFB']").prop("checked") == true){
			showPFB = true;
        }
		/* 
		if(company == ''){
			company = this.state.pfbByCompany;
		} */
		
        { allData.map((answer, i) => {
			if((includePFB==false && answer[111] != 'N') || (includePFB==true && showPFB==false) || (includePFB==true && showPFB==true && answer[111] == 'N' && company=='') || (includePFB==true && (showPFB==true || showPFB==false) && answer[111] == 'N' && company == answer[115])){
				
				if(this.state.mod == 'edit_form_data'){
						mainQues[i] = answer;
						var key =  i+'-'+answer[0];
						mainQueKey.push(key)
				}else{
					
					if(answer[5] !== 'Child Header 1' &&  answer[5] !== 'Child Header 2' && answer[5] !== 'Child Header 3' &&  answer[5] !== 'Parent Header'){
						mainQues[i] = answer;
						var key =  i+'-'+answer[0];
						mainQueKey.push(key)
					}
					
				}
			}
        })}
		
        this.setState({mainQueKey,mainQues});
    }
	
	includePfbQues = () => {
		let allData = this.state.allData;
		if($("input[name='includePFB']").prop("checked") == false){
			$("input[name='showPFB']").prop( "checked",false );
			this.setState({pfbByCompany:''});
        }
		this.mainQuestions(allData);
	}
	
	showPfbQues = () => {
		let allData = this.state.allData;
		if($("input[name='includePFB']").prop("checked") == false){
			alert('Please include PFB=N firstly!');
			$("input[name='showPFB']").prop( "checked",false );
			return false;
        }
		this.mainQuestions(allData);
		if($("input[name='showPFB']").prop("checked") == false){
			this.setState({pfbByCompany:''});
        }
	}

	showPfbByCompany = (event) => {
		if($("input[name='includePFB']").prop("checked") == false || $("input[name='showPFB']").prop("checked") == false){
			alert('Please select the above filter firstly!');
			return false;
        }
		let val = event.target.value;
		let allData = this.state.allData;
		this.mainQuestions(allData,val);
		this.setState({pfbByCompany:val});
	}
	
	filterPlainArray(array, filters) {
      const filterKeys = Object.keys(filters);
      return array.filter(item => {
        return filterKeys.every(key => {
          
            if (!filters[key].length) return true;
            if (item[key] == undefined || item[key] == ''){
                item[key] = 'none'; 
            }
            
            var arr = item[key].split(',');
            var filVal = filters[key].find(filter => filter);
            if (filVal== undefined) return true;
          
            if(arr.indexOf(filVal) !== -1){
                return true;
            }
    
        });
      });
    }

    handleHeaderFilter=(event)=>{
        let name = event.target.name;
        let res = event.target.value;
        this.setState({[name]:event.target.value})
        var sheetpro = [];
        var sheetpro1 = [];
        
        let typeVal = '';
        
        let deviceVal = '';
        
        let visitVal = '';
        
        if(name == 'assoTypeName'){
            typeVal = event.target.value;
        }   
        else if(this.state.assoTypeName != 'undefined'){
            typeVal  = this.state.assoTypeName
        }
        
        if(name == 'assoDeviceTypeName'){
            deviceVal = event.target.value;
        }else if(this.state.assoDeviceTypeName != 'undefined'){
            deviceVal  = this.state.assoDeviceTypeName
        }
        
        if(name == 'assoVisitTypeName'){
            visitVal = event.target.value;
        }else if(this.state.assoVisitTypeName != 'undefined'){
            visitVal  = this.state.assoVisitTypeName
        }
        
        if(this.state.mod == 'format_forms'){
            var allSheet = this.state.reviewSheetOrign;
        }else{
            var allSheet = this.state.sheetsNameOrig;
        }
        var allSheetOringm = this.state.reviewSheetsNameOringn;  
        
        const filters = {
           type: [typeVal],
           deviceType: [deviceVal],
           visitType: [visitVal],
        };

        sheetpro = this.filterPlainArray(allSheet, filters);
        
        if(allSheetOringm){
            sheetpro1 = this.filterPlainArray(allSheetOringm, filters);
        }

        this.setState({sheetsName:sheetpro,reviewSheetsName:sheetpro1, filters});

    }
    
	updatedData=(sheetN,expandData=false)=>{
		
        this.setState({selectedSheetId:this.state.selectedSheetId});
        //let spreadsheetID = config.spreadsheetId;
		let spreadsheetID = this.state.sheetConfigId;
        let sheetName = this.state.selectedSheet;
        if(sheetN){
            sheetName = sheetN;
        }

        gapi.client.load("sheets", "v4", () => {
        gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: spreadsheetID,
                range:sheetName, 
            })
            .then(
                response => {
                    const allData = response.result.values;
                    allData.shift();
					if(this.state.configData.length > 0){
						let configData = this.state.configData;
						allData.map((data,i)=>{
							if(configData[i]){
								if(data[0] == configData[i][0]){
									for (let j in allData) {
										/* if(j==10){
											data[j] = configData[i][7];
										} */
										if(j==14){
											data[j] = configData[i][5];
										}
										if(j==15){
											data[j] = configData[i][6];
										}
										if(j==16){
											data[j] = configData[i].join();
										}
										if(j==37){
											data[j] = configData[i][8];
										}
									}
								}
								
							}else{
								//data[10] = 0;
								//data[14] = 'N'
							}
							
						})
					}
					
                    this.setState({ allData, sheetfunc:false});
                    this.reviewSheetNames(this.state.mod);
                    this.mainQuestions(allData);
                    let t=0;
                    if(expandData){this.setState({expandData:expandData})}
					$(".run-time").remove();
                    this._answer(allData,t)
                },
            );
        });
    }
	
	newUpdatedData=()=>{
		
		//let spreadsheetID = config.spreadsheetId;
		let spreadsheetID = this.state.sheetConfigId;
        let sheetName = this.state.selectedSheet;
        
        gapi.client.load("sheets", "v4", () => {
        gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: spreadsheetID,
                range:sheetName, 
            })
            .then(
                response => {
                    const allData = response.result.values;
					allData.shift();
                    allData.map((data,i)=>{
						data[2] = data[29] ? data[29]:data[2];
						data[10] = data[31] ? data[31]:data[10];
						data[16] = data[34] ? data[34]:data[16];
					}) 
					this.setState({mainData:allData});
					this.setState({allData:allData});
					this.mainQuestions(allData);
					
					let t=0;
					this._answer(allData,t)
                },
            );
        });
    } 
	
    MultiLangSheetLoad(callback=null) {
        let sheetpro=[];
        gapi.client.load("sheets", "v4", () => {
        gapi.client.sheets.spreadsheets.get({
                spreadsheetId: config.language_spreadsheetId, 
            })
            .then(
                response => {
                    const sheets = response.result.sheets;
                    const sheetsName = sheets.map((sheet) => sheet.properties.title);
                    const sheetsId = sheets.map((sheet) => sheet.properties.sheetId);
                    let sheetsNames = sheetsName;
                    sheetsNames.map((sheet, i) => {
                        let sh = {id:sheetsId[i], name:sheet};
                        sheetpro.push(sh);
                    })
                    this.setState({ langSheetsName:sheetpro });
                },
                response => {
                  callback(false, response.result.error);
                }
            );
        });
    }
    
	getDescription(){
        gapi.client.load("sheets", "v4", () => {
        gapi.client.sheets.spreadsheets.values
          .get({
                spreadsheetId: config.spreadsheetId,
                range:'Q Key', 
            })
            .then(
                response => {
                    const allLabelData = response.result.values;
                    this.setState({ allLabelData });
                },
            );
        });
    }
    
	checkKey(elements, parentId = 1) {
        let branch = [];
        {elements.map((element, i) => {
            branch.push(element)
        })}
        return branch;
    }
    
	checkKey2(elements, parentId = '',ids ,pId, cId) {
        var branch = [];
        { elements.map((element,i) => {
            if(element[5] == 'Parent Header'){
                element[pId] = '';
            }  
            if (element[pId].toLowerCase() == parentId) {
                if(element[cId] == ''){
                    element[cId]='a';
                }
                var children = this.checkKey2(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children) {
                    element['sub'] = children; 
                }
                if(!ids.includes(element['0'])){
                    branch.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch;    
    }
    
	_answer=(allData, t)=>{
		let mainData =[];
        let showData = this.state.showLangData;
        let temp = this.state.languageData;
        if(showData == true){
            allData.map((data,i)=>{
                if(temp[i]){
                    data[4] = temp[i][2]
                }
                mainData.push(data);
            }) 
        }
		let allData2 =[];
		
		if(this.state.mod == 'format_forms'){
			this.setState({hasModify:false});
		}
		
        if(t==0){
            if(this.state.selectedOpt == 'original'){
				allData.map((data,i)=>{
                    //if(data[35] == ''){
                        var key = data[10];
                        allData2[key] = data;
                   // }
					
					//if(data[17] != '' || data[18] != '' || data[19] != '' || data[35] != ''){
					if(data[17] != '' || data[18] != '' || data[19] != ''){
						this.setState({hasModify:true});
					}
					if(this.state.mod == 'format_forms' && data[34] != ''){
						this.setState({hasModify:true});
					}
					
                })
            }else{
				allData.map((data,i)=>{
                    var key = data[10];
                    allData2[key] = data;
                })
				
				this.setState({hasModify:false});
            }
            
            var ids =[];
            var db2 = this.checkKey2(allData2, '', ids, 2, 1);
            this.setState({sheetData:db2});
            this.setState({showDiv:true});    
        }else{
			
			{allData.map((data,i)=>{
                var key = data[10];
                allData2[key] = data;
            })}
            var ids =[];
            var db2 = this.checkKey(allData2, '', ids, 2, 1);
            this.setState({sheetData:db2});
        }
		
		if(this.state.mod == 'form_builder'){
			setTimeout(function(){
				let classN = '';
				allData2.map((data,i)=>{
					if(data[5]=='boxend' || data[5].toLowerCase() == 'parent header' || data[5].toLowerCase() == 'child header 1' || data[5].toLowerCase() == 'child header 2' || data[5].toLowerCase() == 'child header 3'){
						classN = '';
					}
					
					$('#'+data[0]).addClass(classN);
					
					if(data[5]=='boxbegin'){
						classN = 'in-box';
					}
				})
				
				allData2.map((data,i)=>{
					if(data[5].toLowerCase() == 'parent header' || data[5].toLowerCase() == 'child header 1' || data[5].toLowerCase() == 'child header 2' || data[5].toLowerCase() == 'child header 3'){
						let endlen = $( ".inner-"+data[0]+ ' .end-div').length;
						let len = $( ".inner-"+data[0]+ ' .in-box').length;
						let has = false;
						$( ".inner-"+data[0]+ ' .in-box').each(function( index ) {
							if (index === len - 1 && endlen == 0) {
								let lastChild = $( this ).attr('id');
								$('#'+lastChild).addClass('in-box-last');
							}
							
							if(!$(this).hasClass("not-include")){
								has = true;
							}
						  
						});
						
						if(has == false){
							$( ".inner-"+data[0]+ ' .begin-div').addClass('hide');
							$( ".inner-"+data[0]+ ' .end-div').addClass('hide');
						}else{
							$( ".inner-"+data[0]+ ' .begin-div').removeClass('hide');
							$( ".inner-"+data[0]+ ' .end-div').removeClass('hide');
						}
					}
				})

			}, 3000);
		}
		
		this.setState({exportSheet:false});
    }
	
    getModule=(mod)=>{
        this.setState({mod:mod,showDataDiv:false,selectedSheet1:'',selectedOpt:"original"});
        if(mod != 'edit_form_data'){
			this.reviewSheetNames(mod);
		}
        
		this.setState({ sheetsName:[] });
        this.setState({ sheetsName: this.state.sheetsNameOrig }); 
    }
    
	handleSignIn=()=> {

        let password = this.refs.password.value
        if(password  == 'abc123'){
            this.setState({errors:false,error:''});
            $('#loginModal').modal('hide');
            $('.card').css('backgroundColor','#fff')
            this.setState({setKiosk:!this.state.setKiosk,});
            let allData = this.state.allData;
            allData.map((rowDatas,i)=>{
				
				$('#'+rowDatas[0]).removeClass("not-include");
                $('.headers-'+rowDatas[0]).find('i').removeClass("fa fa-minus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).find('i').addClass("fa fa-plus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).removeClass("collapsed");
                $('.inner-'+rowDatas[0]).removeClass("show");
                $('.pre-question').removeClass("show");
				
				if(this.state.checkedKioskData.get(rowDatas[0]) == true){
                    //setTimeout(function(){ $('#userBtnId'+rowDatas[0]).show(); }, 2000);
                    //$('#userBtnId'+rowDatas[0]).show();
					$('#userBtnId'+rowDatas[0]).css("display", "inline-block");
                }
				
            })
			$('.comments').removeClass('not-modify');
            $('.req, .inc, .kiosk, .selCheck, .insert_field').removeClass("not-include");
        }else{
            this.refs.password.value = "";
            this.setState({errors:true,error:'Please enter a valid password'});
        }        
    }
    
	uploadFilePath=()=>{
        var path  =this.state.fileData;
        var fileId = this.state.fileId;
        uploadFilePath(path, fileId, this.state);
        $('#directoryFiles').modal('hide');
        this.setState({filePath:path});
    }
    
	getFilePath=(file)=>{
    
        this.setState({fileData:file})
        $( ".view-file" ).removeClass( "selected" );
        $( "#file"+file.id ).addClass( "selected" );
        $( "#fileId" ).val( file );
    }
    
	keyPress(e){
        if(e.keyCode == 13){
            e.preventDefault();
        }
    }
    
	showKioskModal=(checkedKiosk, sheet)=>{
       
        this.refs.password.value = "";
        this.setState({checkedKioskData:checkedKiosk, selectedSheet1:sheet})
        this.setState({errors:false,error:''});
        $('#loginModal').modal('show')
		
    }
    
	goKisko=(data,kioskUsers,selOptions,sheet)=>{
        this.setState({setKiosk:!this.state.setKiosk,selectedSheet1:sheet});
        if(this.state.setKiosk == false){
            this.setState({showUserBtn:false})
            for(let i = 0; i < data.length; i++){
                let rowDatas = data[i];
                $('#'+rowDatas[0]).addClass("not-include");
                $('.headers-'+rowDatas[0]).find('i').removeClass("fa fa-minus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).find('i').addClass("fa fa-plus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).removeClass("collapsed");
                $('.inner-'+rowDatas[0]).removeClass("show");
                $('.pre-question').removeClass("show");
                let columnName = rowDatas[34] ? rowDatas[34] : rowDatas[16];
                if(typeof columnName !== 'undefined' && columnName !== ''){
                
                    if(columnName.indexOf(kioskUsers) != -1){

                        $('#'+rowDatas[0]).find('.kiosk:checked').each(function() {
							let elementId = $(this).attr('name');
							
							if(selOptions == 'include_only'){
							    if ($('#'+elementId).find('.inc').is(":checked")==true) {
									$('#'+rowDatas[0]).removeClass("not-include");
								}
							}else if(selOptions == 'include_required'){
								if ($('#'+elementId).find('.inc').is(":checked")==true && $('#'+elementId).find('.req').is(":checked")==true) {
									$('#'+rowDatas[0]).removeClass("not-include");
								}
								
							}else if(selOptions == 'include_not_required'){
								if ($('#'+elementId).find('.inc').is(":checked")==true && $('#'+elementId).find('.req').is(":checked")==false) {
									$('#'+rowDatas[0]).removeClass("not-include");
								}
								
							}else if(selOptions == 'required_only'){
								
								if (rowDatas[15] == 'Y') {
									$('#'+rowDatas[0]).removeClass("not-include");
								}
								
							}else{
								$('#'+rowDatas[0]).removeClass("not-include");
							}
							
                        });
                        let elm = document.getElementById(rowDatas[0]);
                        let x = 0;
                        do {
                            if(elm){
                                let parentNode = elm.parentNode;
                                
                                if(parentNode.getAttribute('data-header') == 1 || parentNode.getAttribute('data-header') == 2 || parentNode.getAttribute('data-header') == 3 || parentNode.getAttribute('data-header') == 'parent' ){
                                    let name = parentNode.getAttribute('id');
									$('#'+name).addClass("not-include");
                                    $('.inner-'+name).find('.kiosk:checked').each(function() {
										let innerElement = $(this).attr('name');
										setTimeout(function(){ 
											if(!$('#'+innerElement).hasClass( "not-include" ) && $('#'+innerElement).hasClass( "question" )){
												$('#'+name).removeClass("not-include");
											}
										}, 2000);
                                    });
                                }
                                if(parentNode.className.indexOf('parent-header') != -1){
                                    x = 10; 
                                }
                                elm = parentNode;  
                            }
                            x++;
                        }
                        while(x < 10);
                    }
                }
            }
            if(kioskUsers.toString() == 'PhT'){
                this.setState({kioskUserName:'Physical Therapist'})
                $('.card').css('backgroundColor','#FFCCE5',)
            }else if(kioskUsers.toString() == 'CPO'){
                this.setState({kioskUserName:'Practitioner'})
                $('.card').css('backgroundColor','#FFFFFF')
            }else if(kioskUsers.toString() == 'Int'){
                this.setState({kioskUserName:'Intern'})
                $('.card').css('backgroundColor','#B2FF66')

            }else if(kioskUsers.toString() == 'Res'){
                this.setState({kioskUserName: 'Resident'})
                $('.card').css('backgroundColor','#B2FF66')
            }else if(kioskUsers.toString() == 'PrA'){
                this.setState({kioskUserName:'Practitioner Assistant'})
                $('.card').css('backgroundColor','#B2FF66')
            }else if(kioskUsers.toString() == 'PhA' ){
                this.setState({kioskUserName:'Physician Assistant'})
                $('.card').css('backgroundColor','#B2FF66')
            }else if(kioskUsers.toString() == 'PmD'){
                this.setState({kioskUserName:'Physician'})
                 $('.card').css('backgroundColor','#ececec')
            }else if(kioskUsers.toString() == 'Pat'){
                this.setState({kioskUserName:'Patient'})
                 $('.card').css('backgroundColor','#ccf5ff')
            }else if(kioskUsers.toString() == 'CaC'){
                this.setState({kioskUserName: 'Care Coordinator'})
                $('.card').css('backgroundColor','#FFFF99')
            }
            $('.req, .inc, .kiosk, .selCheck, .insert_field').addClass("not-include");
			
            $('#myModal').modal('hide');
        }else{
           
            data.map((rowDatas,i)=>{
                
                $('#'+rowDatas[0]).removeClass("not-include");
                $('.headers-'+rowDatas[0]).find('i').removeClass("fa fa-minus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).find('i').addClass("fa fa-plus-square-o fa-1x mr-2");
                $('.headers-'+rowDatas[0]).removeClass("collapsed");
                $('.inner-'+rowDatas[0]).removeClass("show");
                $('.pre-question').removeClass("show");
                //$('#userBtnId'+rowDatas[0]).css("display", "inline-block");
                ///this.setState({showUserBtn:true})
                
            }) 
        }
        $(".answer").find("input:radio:checked").prop('checked',false);
        $(".answer").find("option:selected").prop('selected',false);
        $(".answer").find("input:checkbox:checked").prop('checked',false);
        $(".answer").find("input:text").val('');
		$('.comments').addClass('not-modify');
    }
	
    getDirectoryFiles=(id)=>{
        let config1 = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url =config.API_URL+'uploadfile/get_files.php';
        axios.get(url, config1)
        .then(response => {
            var fileArray =response.data;
            this.setState({fileArray,fileId:id});
        })
        //this.setState({fileArray,fileId:id});
    }
	
	handleClearTempSave=(selectedSheet)=>{
		let accessToken = gapi.auth.getToken().access_token;
		let spreadsheetID = this.state.sheetConfigId;
        //let spreadsheetID = config.spreadsheetId;
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
		let rowId ='';
		let updateData=[];
		let allData = this.state.allData;
		allData.map((rowDatas,i)=>{
				
			rowId = (i+2);
			if(rowDatas[5] != 'image' || rowDatas[5] != 'link' || rowDatas[5] != 'inlineVideo'){
				let row1 = {
					range: selectedSheet+'!CV'+rowId,
					values: [
						[ '' ]
					]
				};
				updateData.push(row1)
			}
			
			let row2 = {
				range: selectedSheet+'!DD'+rowId+':DF'+rowId,
				values: [
					[ '','','' ]
				]
			};
			
			updateData.push(row2)
        })
		
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			
			that.setState({sheetData:[]});
			allData.map((rowDatas,i)=>{
				rowDatas[99] = '';
				rowDatas[107] = '';
				rowDatas[108] = '';
				rowDatas[109] = '';
			});
			
			if(that.state.selDropOpt == 'exported'){
				that.selectReviewOpt(that.state.reviewVer, that.state.selectedSheetId);
			}else{
				that.onSelect(that.state.selectedSheet, that.state.selectedSheetId);
			}
			alert('Data Clear Successfully');
        }, function(reason) {
            //alert(reason.result.error.message);
        });
	}
	
	validationType=(valType)=>{
		let typeText = '';
		if(valType){
			switch (valType.toLowerCase()) {
				case 'v1' :
					typeText = 'V1 Text'
					break;
				case 'v2' :
					typeText = 'V2 Text Alphabet Only (no numbers)'
					break;
				case 'v3' :
					typeText = 'V3 Whole +Numbers and Zero'
					break;
				case 'v4' :
					typeText = 'V4 Whole +Numbers no Zero'
					break;
				case 'v5' :
					typeText = 'V5 +Numbers and zero, 1 decimal (ex. 0, .1,1.1)'
					break;
				case 'v6' :
					typeText = 'V6 +Numbers and zero, 2 decimal (ex. 0, .01,1.11)'
					break;
				case 'v7' :
					typeText = 'V7 Percentage Positive Only'
					break;
				case 'v8' :
					typeText = 'V8 Fractions Positive Only'
					break;
				case 'v9' :
					typeText = 'V9 Dates Past'
					break;
				case 'v10' :
					typeText = 'V10	Dates Today Only'
					break;
				case 'v11' :
					typeText = 'V11	Dates Future'
					break;
				case 'v12' :
					typeText = 'V12	Time hh:mm:ss:ssss'
					break;
				case 'v13' :
					typeText = 'V13 Time ss:ssss'
					break;
				default:
				typeText = 'V1 Text';
			}
		}
		return typeText;
	}
	
    renderModule=(mod)=>{
		switch(mod) {
        case 'edit_form_data':
            return <FormEditData
					sheetConfigId= {this.state.sheetConfigId}
                    reviewSheetsName= {this.state.reviewSheetsName}
                    sheetsName = {this.state.sheetsName} 
                    description={this.state.allLabelData}
                    moduleName = {mod}
                    allData={this.state.allData}
                    showDataDiv = {this.state.showDataDiv}
                    mainQues = {this.state.mainQues}
                    showPfbQues={this.showPfbQues}
                    validationType={this.validationType}
                    includePfbQues={this.includePfbQues}
                    showPfbByCompany={this.showPfbByCompany}
                    pfbByCompany={this.state.pfbByCompany}
                    companyFilter={this.state.companyFilter}
                    onSelect={this.onSelect}
                    showSign={this.showSign}
                    reviewVer={this.state.reviewVer}
					apiPdfs = {this.state.apiPdfs}
                    kioskResponse={this.state.kioskResponse}
                    handleHeaderFilter={this.handleHeaderFilter}
                    showImpData = {this.state.showImpData}
                    mainQueKey = {this.state.mainQueKey}
                    selectReviewOpt = {this.selectReviewOpt}
                    selectedOpt = {this.state.selectedOpt}
                    selDropOpt = {this.state.selDropOpt}
                    updatedData = {this.updatedData}
                    clearAllFilter = {this.clearAllFilter}
                    selectedSheet = {this.state.selectedSheet}
                    selectedSheet1 ={this.state.selectedSheet1}
                    selectedSheetId = {this.state.selectedSheetId}
					companies = {this.state.companies}
					trackingCode = {this.state.trackingCode}
                    assoType = {this.state.assoType}
                    assoVisitType ={this.state.assoVisitType}
					assoDeviceType ={this.state.assoDeviceType}/>;
        break;
        case 'review_approve':
            return  <Review
						sheetConfigId= {this.state.sheetConfigId}
                        updatedData = {this.updatedData}
                        mainQueKey = {this.state.mainQueKey}
                        reviewSheetsName= {this.state.reviewSheetsName}
                        kioskResponse={this.state.kioskResponse}
                        sheetsName = {this.state.sheetsName}
                        showDataDiv = {this.state.showDataDiv} 
                        description={this.state.allLabelData}
                        onSelect={this.onSelect}
                        showSign={this.showSign}
						apiPdfs = {this.state.apiPdfs}
                        reviewVer={this.state.reviewVer}
						mdsGuid={this.state.mdsGuid}
                        getDirectoryFiles={this.getDirectoryFiles}
                        moduleName = {mod}
                        clearAllFilter = {this.clearAllFilter}
                        selectReviewOpt = {this.selectReviewOpt}
                        allData={this.state.allData}
                        showImpData = {this.state.showImpData}
                        mainQues = {this.state.mainQues}
                        sheetData={this.state.sheetData}
                        selectedOpt = {this.state.selectedOpt}
                        selectedSheet = {this.state.selectedSheet}
                        selDropOpt = {this.state.selDropOpt}
                        _answer = {this._answer}
						hasModify = {this.state.hasModify}
                        handleHeaderFilter={this.handleHeaderFilter}
                        selectedSheet1 ={this.state.selectedSheet1}
                        selectedSheetId = {this.state.selectedSheetId}
						companies = {this.state.companies}
						trackingCode = {this.state.trackingCode}
                        assoType = {this.state.assoType}
                        assoVisitType ={this.state.assoVisitType}
						assoDeviceType ={this.state.assoDeviceType}/>;
        break;
        case 'form_builder':
            return  <FormBuilder
						sheetConfigId= {this.state.sheetConfigId}			
                        expandData = {this.state.expandData}
                        selectedSheetId = {this.state.selectedSheetId}
                        updatedData = {this.updatedData}
                        selectedSheet1 ={this.state.selectedSheet1}
                        kioskResponse={this.state.kioskResponse}
                        mainQueKey = {this.state.mainQueKey}
                        reviewSheetsName= {this.state.reviewSheetsName}
                        reviewSheetNames={this.reviewSheetNames}
                        handleClearTempSave={this.handleClearTempSave}
                        sheetsName = {this.state.sheetsName}
						validationType={this.validationType}
                        showDataDiv = {this.state.showDataDiv} 
                        description={this.state.allLabelData}
                        apiPdfs = {this.state.apiPdfs}
						onSelect = {this.onSelect}
                        goKisko = {this.goKisko}
                        showSign={this.showSign}
                        ref={this.childFormBuilder}
                        reviewVer={this.state.reviewVer}
						mdsGuid={this.state.mdsGuid}
                        getDirectoryFiles={this.getDirectoryFiles}
                        moduleName = {mod}
                        langSheetsName = {this.state.langSheetsName}
                        getLangData ={this.getLangData}
                        showKioskModal = {this.showKioskModal}
                        setKiosk = {this.state.setKiosk}
                        showImpData = {this.state.showImpData}
                        exportSheet = {this.state.exportSheet}
                        mainQues = {this.state.mainQues}
                        selectReviewOpt = {this.selectReviewOpt}
                        allData={this.state.allData}
                        handleHeaderFilter={this.handleHeaderFilter}
                        clearAllFilter = {this.clearAllFilter}
                        mainData = {this.state.mainData}
                        sheetData={this.state.sheetData}
                        selectedOpt = {this.state.selectedOpt}
                        selectedSheet = {this.state.selectedSheet}
                        selDropOpt = {this.state.selDropOpt}
                        hideSection = {this.hideSection}
                        _answer = {this._answer}
                        hasModify = {this.state.hasModify}
                        companies = {this.state.companies}
                        trackingCode = {this.state.trackingCode}
                        assoType = {this.state.assoType}
                        assoVisitType ={this.state.assoVisitType}
                        assoDeviceType ={this.state.assoDeviceType} />;
        break;
        case 'format_forms':
            return  <FormatForm
						sheetConfigId= {this.state.sheetConfigId}
                        updatedData = {this.updatedData}
                        sheetsName = {this.state.sheetsName}
                        mainQueKey = {this.state.mainQueKey}
                        kioskResponse={this.state.kioskResponse}
                        reviewSheetsName= {this.state.reviewSheetsName} 
                        description={this.state.allLabelData}
						handleClearTempSave={this.handleClearTempSave}
                        onSelect={this.onSelect}
						validationType={this.validationType}
                        selectedSheet1 ={this.state.selectedSheet1}
                        showKioskModal = {this.showKioskModal}
                        getDirectoryFiles={this.getDirectoryFiles}
                        setKiosk = {this.state.setKiosk}
						apiPdfs = {this.state.apiPdfs}
                        goKisko = {this.goKisko}
                        showSign={this.showSign}
                        moduleName = {mod}
						ref={this.childFormBuilder}
                        reviewVer={this.state.reviewVer}
						mdsGuid={this.state.mdsGuid}
                        showImpData = {this.state.showImpData}
                        showDataDiv = {this.state.showDataDiv}
                        mainQues = {this.state.mainQues} 
                        allData={this.state.allData}
                        handleHeaderFilter={this.handleHeaderFilter}
                        clearAllFilter = {this.clearAllFilter}
                        selectReviewOpt = {this.selectReviewOpt}
                        sheetData={this.state.sheetData}
                        selectedOpt = {this.state.selectedOpt}
						companies = {this.state.companies}
						trackingCode = {this.state.trackingCode}
                        selectedSheetId = {this.state.selectedSheetId}
                        formatSheet_Id = {this.state.formatSheet_Id}
                        selectedSheet = {this.state.selectedSheet}
                        selDropOpt = {this.state.selDropOpt} 
                        assoType = {this.state.assoType}
						hasModify = {this.state.hasModify}
                        assoVisitType ={this.state.assoVisitType}
                        assoDeviceType ={this.state.assoDeviceType}/>;
        break;
        case 'test_forms':
            return  <Redirect to={{
				pathname: "/beta",
				state: { moduleName: mod }
				}}
			/>;
        break;
		case 'completed_forms':
            return  <Redirect to={{
				pathname: "/beta",
				state: { moduleName: mod }
				}}
			/>;
        break;
		case 'custom_config':
            return  <Redirect to={{
				pathname: "/beta",
				state: { moduleName: mod }
				}}
			/>;
        break;
        case 'build_note_template':
            return  <NoteTemplate 
                        sheetsName = {this.state.sheetsName}
                        selectedSheet1 ={this.state.selectedSheet1}
                        reviewSheetsName= {this.state.reviewSheetsName} 
                        description={this.state.allLabelData}
                        onSelect={this.onSelect}
                        moduleName = {mod}
                        handleHeaderFilter={this.handleHeaderFilter}
                        clearAllFilter = {this.clearAllFilter}
                        allData={this.state.allData}
                        sheetData={this.state.sheetData} 
                        assoType = {this.state.assoType}
                        assoVisitType ={this.state.assoVisitType}
                        assoDeviceType ={this.state.assoDeviceType}/>;
        break;
        case 'manage_forms':
            return  <ManageForms 
				sheetsName= {this.state.sheetsName}
			/>;
        break;
		
        case 'json_files':
            return  <JsonFiles 
				sheetsName= {this.state.sheetsName}
			/>;
        break;
		
		case 'mds_forms':
            return  <MdsForms 
				sheetConfigId= {this.state.sheetConfigId}
				sheetConfigMod= {this.state.sheetConfigMod}
			/>;
        break;
		
        default:
            return  null;
        }
    }
	
	render() {
        const { sheetsName,setKiosk, kioskUserName, fileArray} = this.state;
		return(
            <div className="container mt-5 mb-5">
				<div className="login-user-top">{'Logged in as: '+localStorage.getItem('username')}</div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <MainHeader
                                reviewSheetsName= {this.state.reviewSheetsName}
                                setKiosk = {setKiosk}
                                kioskUserName = {kioskUserName}
                                sheetsName = {this.state.sheetsName}
                                changeSheetId = {this.changeSheetId}
                                getModule = {this.getModule} />

                            { <div>{this.renderModule(this.state.mod)}</div>}
                        </div>
                    </div>
                </div>
                <div className="modal" id="loginModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Enter a password</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <form>
                                <div className="modal-body">      
                                    <input type="password" className="form-control" ref="password" placeholder="enter a password" onKeyDown={this.keyPress} />
                                    { this.state.errors ?
                                    <p className=" alert-danger"> { this.state.error } </p>:null }                                    
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary float-left" onClick={this.handleSignIn}>Ok</button>
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal" id="directoryFiles">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Upload File</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
                            <form>
                                <div className="row">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">File</th>
                                                <th scope="col">Path</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                        {(fileArray || []).map(url => (
                                            <tr className="view-file" id={'file'+url.id}  onClick = {()=>this.getFilePath(url)}>
                                                <td scope="row">{url.id}</td>
                                                <td scope="row">{url.name}</td>
                                                <td scope="row">{url.path}</td>
                                                <td>{url.description}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <input type="hidden" id="fileId" value="" />
                                    </table>
                                </div>
                            </form >
                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-primary float-left" onClick={this.uploadFilePath}>Save</button>
                            <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                          </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClinicalTracks;