import React from 'react';
import $ from 'jquery';
import 'bootstrap';
import Popup from "reactjs-popup";
import Overlay from 'react-bootstrap/Overlay';
import { KioskDrop } from "../../component/FormBuilder/KioskDrop";
import ReactHtmlParser from 'react-html-parser';
import { Form,Button,OverlayTrigger,Popover } from 'react-bootstrap';

const tagHtml =(tagData,tags) => {
	if(typeof tags !== 'undefined' && tags !== ''){
       return(
            <OverlayTrigger trigger={["click"]} placement="top" overlay={(
                <Popover>
                    <Popover.Title as="h3">Tags</Popover.Title>
                        <Popover.Content>
                          {tags}
                        </Popover.Content>
                </Popover>
             )}>
            <button type="button" style={{backgroundColor: "red"}} className="btn code-dialog btn-tag btnTag" ><i className="fa fa-tag "  aria-hidden="true"></i></button>
                
            </OverlayTrigger>
        )
    }
}
const noteHtml=(noteData, notes)=>{
    if(typeof notes !== 'undefined' && notes !== ''){
        return  <button type="button" style={{backgroundColor: "yellow"}}  className="btn code-dialog btn-sm btn-note" data-toggle="modal" data-target={'#note'+noteData[0]} ><i className="fa fa-sticky-note-o" aria-hidden="true" ></i>
        </button>
    }
}
const knoteHtml=(noteData, notes)=>{
    if(typeof notes !== 'undefined' && notes !== ''){
        return  <button type="button" style={{backgroundColor: "yellow"}}  className="btn code-dialog btn-sm btn-note" data-toggle="modal" data-target={'#knote'+noteData[0]} ><i className="fa fa-sticky-note-o" aria-hidden="true" ></i>
        </button>
    }
}

const addUsersHTML=(comment,showAddUsersModal, checkedKiosk)=>{ 
    if(typeof comment !== 'undefined' && comment !== ''){
        return  <button type="button" style={{display:checkedKiosk.get(comment[0]) ==true ? 'inline-block' : 'none' }} id = {'userBtnId'+comment[0]} onClick ={()=>showAddUsersModal(comment[0])} className="btn code-dialog btn-edit"><i className="fas fa-user-friends"></i></button>
    }
}
const addUsersModal=(comment,selectKioskUsers,kioskUsers,saveKioskUsersType,kioskType)=>{
    if(typeof comment !== 'undefined' && comment !== ''){
        return  <div className="modal" id={'kiosk'+comment[0]}>
                    <div className="modal-dialog modal-lg modal-sm select-modal">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delegate Mode</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <label> Select Users Type: </label>
                                <KioskDrop 
                                    multiple ='true'
                                    QueUsersId = {comment[0]}
                                    kioskType={kioskType}
                                    selectKioskUsers={selectKioskUsers}
                                    kioskUsers = {kioskUsers}/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary float-left" data-dismiss="modal" onClick={()=>saveKioskUsersType(comment[0])} > Save </button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>         
    }
}
const knoteModal = (noteData, notes)=>{
    if(typeof notes !== 'undefined' && notes !== ''){
        var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
        var imgUrl= notes;
        return <div className="modal note-modal" id={'knote'+noteData[0]} style={{zIndex:'1052'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">

                <div className="modal-header">
                    <h4 className="modal-title">Notes</h4>
                    <button type="button" className="close" onClick = {() => $('.note-modal').modal('hide')} >&times;</button>
                </div>
                <div className="modal-body" id="comment" style={{textAlign:'left'}}>
                    { ReactHtmlParser(imgUrl) }
                </div>
            </div>
            </div>
        </div>
    }
}
const noteModal = (noteData, notes)=>{
    if(typeof notes !== 'undefined' && notes !== ''){
        var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
        var imgUrl= notes;
        return <div className="modal" id={'note'+noteData[0]} style={{zIndex:'1052'}}>
            <div className="modal-dialog ">
                <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Notes <span className='noti-date small ml-2'>{noteData[98] !== undefined ? 'Field Last Updated ' + noteData[98] : null }</span></h4>
                    
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body" id="comment" style={{textAlign:'left'}}>
                    { ReactHtmlParser(imgUrl) }
                </div>
            </div>
            </div>
        </div>
    }
}
const includeModal=(comment)=>{
    return  <div className="modal" id={'include'+comment[0]}>
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">Precondition Note</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
                <div className="modal-body">
                    <p>This question is a precondition for other questions in the form.Click on the star icons for more details.</p>
                </div>
            </div>
        </div>
    </div>
}
const showImageModal=(type)=>{
    return(
       <div className="modal" id="myImageModal" style={{zIndex:'1051'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Image</h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <img src={type} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div> 
    )
}

const attentHTML= (attentData, attents,showNeedModal)=> {
    if(typeof attents !== 'undefined' && attents !== ''){        
        var attents = <button type="button" className="btn code-dialog btn-sm btn-note" data-toggle="modal" onClick={()=>showNeedModal(attentData[0])} data-target={'#needs'+attentData[0]}><i className="fa fa-info-circle" style={{color:'red'}}></i></button>
        return attents;        
    }
}

const feedBack= (attentData, attents,showFeedModal)=> {
    if(typeof attents !== 'undefined' && attents !== ''){        
        var attents = <button type="button" className={ 'btn code-dialog btn-sm btn-note btn-'+attentData[0] } data-toggle="modal" onClick={()=>showFeedModal(attentData[0])} data-target={'#feeds'+attentData[0]}><i className="fa fa-info-circle" style={{color:'green'}}></i></button>
        return attents;        
    }
}

export { tagHtml,noteHtml,knoteHtml,noteModal,knoteModal,showImageModal,attentHTML,includeModal, addUsersHTML, addUsersModal, feedBack  };