import React from 'react'; 
import $ from 'jquery';
import config from "../config";
import guid from "./GUID";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';

import {Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,Q11,Q12,Q13,Q14,Q15,Q16,Q11B,Timers,Sliders,Image,Sig,Video,Launchpad,Subjective,Assessment,Grid,BasicGrid,GridMultiCheck,GridSingleCheck,GridCheck,GridDropdown,Likert,Smart,Reduction,Weighted} from "../type/Type";
import {PQ1,PQ2,PQ3,PQ4,PQ5,PQ6,PQ7,PQ8,PQ9,PQ10,PQ11,PQ12,PQ13,PQ14,PQ15,PQ16,PQ11B,PTimers,PSliders,PImage,PSig,PSubjective,PAssessment,PGrid,PBasicGrid,PGridMultiCheck,PGridSingleCheck,PGridCheck,PGridDropdown,PLikert,PSmart,PReduction,PWeighted} from "../type/Type2";

async function load(callback, sheetConfigId) {
	//alert(sheetConfigId);
    await gapi.client.load("sheets", "v4", () => {
    gapi.client.sheets.spreadsheets.get({
            spreadsheetId: sheetConfigId, 
        })
        .then(
            response => {
                const sheets = response.result.sheets;
				//console.log('sheets->',sheets);
				let sheetsName = sheets.map((sheet) =>{
					if(sheet.properties.title != 'Temp MDS' && sheet.properties.gridProperties.rowCount > 1 && sheet.properties.gridProperties.columnCount > 120 && sheet.properties.gridProperties.columnCount < 150){
						return sheet.properties.title
					}
				});
                let sheetsId = sheets.map((sheet) =>{
					if(sheet.properties.title != 'Temp MDS' && sheet.properties.gridProperties.rowCount > 1 && sheet.properties.gridProperties.columnCount > 120 && sheet.properties.gridProperties.columnCount < 150){
						return sheet.properties.sheetId
					}
				});
				
				sheetsName = sheetsName.filter(function(x) {
					 return x !== undefined;
				});
				sheetsId = sheetsId.filter(function(x) {
					 return x !== undefined;
				});
				//console.log('sheetsName->',sheetsName);
				var sheetType =[];
				let rangeArray = [];
				sheetsName.map((sheet,i)=>{
					//if(i<=config.showSheetInDrop){
						let rArray = [sheet+'!CO2', sheet+'!CQ2', sheet+'!CR2', sheet+'!DY2'];
						rangeArray.push(rArray);
					//}
					
				})
				var myNewArray = [].concat.apply([], rangeArray);
				
				let params =  {
					spreadsheetId: sheetConfigId,
					majorDimension: "DIMENSION_UNSPECIFIED",
					ranges: myNewArray,
					valueRenderOption: "FORMATTED_VALUE"
				};
				
				let request1 = gapi.client.sheets.spreadsheets.values.batchGet(params);
				request1.then(function(response) {
					
					let typeVal = [];
					let typeVal2 = [];
					
					sheetsName.map((sheet,i)=>{
						//if(i<=config.showSheetInDrop){
							var response_val = response.result.valueRanges;
							
							typeVal = [];
							response_val.map((val,i)=>{
								
								var sheetN = val.range.split("!");
								let sheetN1 = sheetN[0].replaceAll("'", "");
								sheetN1 = sheetN1.replaceAll(" ", "");
								sheet = sheet.replaceAll(" ", "");
								
								if(sheetN1 == sheet){
									typeVal[sheetN[1]] = val;
									typeVal2[sheet] = typeVal;
								}
							})
						//}
					})
					
					sheetsName.map((sheet,i)=>{
						
						//if(i<=config.showSheetInDrop){
							
							let sheetA = sheet.replaceAll(" ", "");
							
							let sh = {name:sheet, type:'', deviceType:'', visitType:'', visibility:''}
							if(typeVal2[sheetA]['CO2'].values && typeVal2[sheetA]['CQ2'].values && typeVal2[sheetA]['CR2'].values && typeVal2[sheetA]['DY2'].values){
								sh = {name:sheet, type:typeVal2[sheetA]['CO2'].values[0][0], deviceType:typeVal2[sheetA]['CQ2'].values[0][0], visitType:typeVal2[sheetA]['CR2'].values[0][0],visibility:typeVal2[sheetA]['DY2'].values[0][0]}
							}
							sheetType.push(sh);
						//}
						
					})
					console.log('sheetType->',sheetType);
				}, function(reason) {
					console.error('error: ' + reason.result.error.message);
				});
				setTimeout(function(){
					callback({
						sheetsName,sheetsId, sheetType
					});
				},4000)
            },
            response => {
              callback(false, response.result.error);
            }
        );
    });
}

function getPdfFromApi(){
	
	return new Promise((resolve, reject) => {
		let url = config.API_PDF_URL+'/login';
		let formData = new FormData();
        formData.append('username', 'SOOGAP');
        formData.append('password', 'U?G}6!HU9~z}F');
		axios({
			method: 'POST',
			url: url,
			data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
		})
		.then(response => {
			let token = response.data.token;
			let url2 = config.API_PDF_URL+'/pdfs';
			axios({
				method: 'GET',
				url: url2,
				headers: {
					'Authorization': 'Bearer '+token
				}
			})
			.then(response => {
				let pdfs = response.data;
				resolve(pdfs.data.pdfs);
			}).catch(error => {
				console.log('error::', error);

			})
		}).catch(error => {
			console.log('error::', error);

		})
		
	});
}
	
function getSheetsById(spreadsheetId) {
	
	return new Promise((resolve, reject) => {
			
		gapi.client.init({
		   apiKey: config.apiKey,
		   discoveryDocs: config.discoveryDocs,
		})
		.then(() => {
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.get({
					spreadsheetId: spreadsheetId, 
				})
				.then(
					response => {
						let sheetpr = [];
						let sheetss = response.result.sheets;
                        sheetss.map((sheet, i) => {
							let sh = {id:sheet.properties.sheetId, name:sheet.properties.title};
							sheetpr.push(sh);
						});
						resolve(sheetpr);
					},
					response => {
					  console.log(response.result.error);
					}
				);
			});	
		});	 	  
	});
}

function getSheetType(sheetsName){
	let sheetType=[];
	let rangeArray = [];
	
	sheetsName = sheetsName.filter(function (el) {
	  return el != null;
	});
	
	sheetsName.map((sheet,i)=>{
		if(i!== 0 && i <= 30){
			let rArray = [sheet+'!CN2', sheet+'!CO2', sheet+'!CQ2', sheet+'!CR2'];
			rangeArray.push(rArray);
		}
		
	})
	var myNewArray = [].concat.apply([], rangeArray);
	
	let params =  {
		spreadsheetId: config.pahseII_spreadsheetId,
		majorDimension: "DIMENSION_UNSPECIFIED",
		ranges: myNewArray,
		valueRenderOption: "FORMATTED_VALUE"
	};
	
	let request1 = gapi.client.sheets.spreadsheets.values.batchGet(params);
	request1.then(function(response) {
		
		let typeVal = [];
		let typeVal2 = [];
		
		sheetsName.map((sheet,i)=>{
			if(i!== 0){
				var response_val = response.result.valueRanges;
				typeVal = [];
				response_val.map((val,i)=>{
					
					var sheetN = val.range.split("!");
					let sheetN1 = sheetN[0].replaceAll("'", "");
					sheetN1 = sheetN1.replaceAll(" ", "");
					sheet = sheet.replaceAll(" ", "");
					
					if(sheetN1 == sheet){
						typeVal[sheetN[1]] = val;
						typeVal2[sheet] = typeVal;
					}
				})
			}
		})
		
		sheetsName.map((sheet,i)=>{
			
			if(i!== 0){
				
				let sheetA = sheet.replaceAll(" ", "");
				let sh = {name:sheet, type:typeVal2[sheetA]['CO2'].values[0][0], deviceType:typeVal2[sheetA]['CQ2'].values[0][0], visitType:typeVal2[sheetA]['CR2'].values[0][0], mdsGuid:typeVal2[sheetA]['CN2'].values[0][0]}
				
				sheetType.push(sh)
			}
			
		})
		
	}, function(reason) {
		console.error('error: ' + reason.result.error.message);
	});

	return sheetType;
}

function getSheetTypeFacility(sheetsName){
	let sheetType=[];
	let rangeArray = [];
	
	sheetsName = sheetsName.filter(function (el) {
	  return el != null;
	});
	
	sheetsName.map((sheet,i)=>{
		if(i!== 0 && i <= 30){
			let rArray = [sheet+'!CN2', sheet+'!CO2', sheet+'!CQ2', sheet+'!CR2', sheet+'!DS2'];
			rangeArray.push(rArray);
		}
		
	})
	var myNewArray = [].concat.apply([], rangeArray);
	
	let params =  {
		spreadsheetId: config.pahseII_spreadsheetId,
		majorDimension: "DIMENSION_UNSPECIFIED",
		ranges: myNewArray,
		valueRenderOption: "FORMATTED_VALUE"
	};
	
	let request1 = gapi.client.sheets.spreadsheets.values.batchGet(params);
	request1.then(function(response) {
		
		let typeVal = [];
		let typeVal2 = [];
		
		sheetsName.map((sheet,i)=>{
			if(i!== 0){
				var response_val = response.result.valueRanges;
				typeVal = [];
				response_val.map((val,i)=>{
					
					var sheetN = val.range.split("!");
					let sheetN1 = sheetN[0].replaceAll("'", "");
					sheetN1 = sheetN1.replaceAll(" ", "");
					sheet = sheet.replaceAll(" ", "");
					
					if(sheetN1 == sheet){
						typeVal[sheetN[1]] = val;
						typeVal2[sheet] = typeVal;
					}
				})
			}
		})
		
		sheetsName.map((sheet,i)=>{
			
			if(i!== 0){
				
				let sheetA = sheet.replaceAll(" ", "");
				
				let fId = 'All';
				if(typeVal2[sheetA]['DS2'].values){
					fId = typeVal2[sheetA]['DS2'].values[0][0];
				}
				
				let sh = {name:sheet, type:typeVal2[sheetA]['CO2'].values[0][0], deviceType:typeVal2[sheetA]['CQ2'].values[0][0], visitType:typeVal2[sheetA]['CR2'].values[0][0], mdsGuid:typeVal2[sheetA]['CN2'].values[0][0], facilityId:fId}
				
				sheetType.push(sh)
			}
			
		})
		
	}, function(reason) {
		//console.error('error: ' + reason.result.error.message);
	});

	return sheetType;
}

function getSheetApiName(sheetsName){
	let sheetType=[];
	let rangeArray = [];
	
	sheetsName = sheetsName.filter(function (el) {
	  return el != null;
	});
	
	sheetsName.map((sheet,i)=>{
		if(i <= 30){
			let rArray = [sheet+'!DT2'];
			rangeArray.push(rArray);
		}
	})
	
	var myNewArray = [].concat.apply([], rangeArray);
	
	let params =  {
		spreadsheetId: config.spreadsheetId,
		majorDimension: "DIMENSION_UNSPECIFIED",
		ranges: myNewArray,
		valueRenderOption: "FORMATTED_VALUE"
	};
	
	let request1 = gapi.client.sheets.spreadsheets.values.batchGet(params);
	request1.then(function(response) {
		
		let typeVal = [];
		let typeVal2 = [];
		
		sheetsName.map((sheet,i)=>{
			
			var response_val = response.result.valueRanges;
			typeVal = [];
			response_val.map((val,i)=>{
				var sheetN = val.range.split("!");
				let sheetN1 = sheetN[0].replaceAll("'", "");
				sheetN1 = sheetN1.replaceAll(" ", "");
				sheet = sheet.replaceAll(" ", "");
				if(sheetN1 == sheet){
					typeVal[sheetN[1]] = val;
					typeVal2[sheet] = typeVal;
				}
			})
			
		})
		
		sheetsName.map((sheet,i)=>{
			let sheetA = sheet.replaceAll(" ", "");
			if(typeVal2[sheetA]['DT2'].values){
				let sh = {name:sheet, apiName:typeVal2[sheetA]['DT2'].values[0][0]}
				sheetType.push(sh)
			}
		})
		
	}, function(reason) {
		console.error('error: ' + reason.result.error.message);
	});
	return sheetType;
}

function getSheetsIdByName(spreadsheetId, formName) {
	
	return new Promise((resolve, reject) => {
			
		gapi.client.init({
		   apiKey: config.apiKey,
		   discoveryDocs: config.discoveryDocs,
		})
		.then(() => {
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.get({
					spreadsheetId: spreadsheetId, 
				})
				.then(
					response => {
						let sheet_Id = '';
						
						let sheets = response.result.sheets;
				
						sheets.filter(function(el){
							if(el.properties.title == formName){
								sheet_Id = el.properties.sheetId;
							}
						});
						resolve(sheet_Id);
					},
					response => {
					  console.log(response.result.error);
					}
				);
			});	
		});	 	  
	});
}

function generatehtmlfromlocation(rowAllData,callback = null,props){
    
    switch(rowAllData[4].toLowerCase()) {
        case 'a6':
            return  'A6';
        break;
        case 'q1':
            return  <Q1 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        rowAllData={rowAllData}
                        kioskResponse={props.kioskResponse}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q2':
            return  <Q2 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q3':
            return  <Q3 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q4':
            return  <Q4 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q5':
            return  <Q5 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q6':
            return  <Q6 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q7':
           return   <Q7 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q8':
           return   <Q8 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q9':
           return   <Q9 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q10':
           return   <Q10 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}
                        kioskPreview = {props.kioskPreviewData}/>;
        break;
        case 'q11':
           return   <Q11 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q11b':
           return   <Q11B func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        break;
        case 'title':
           return   '';
        break;
        case 'text':
            return  '';
        break;
        case 'timer':
            return  <Timers 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'slider':
            return  <Sliders 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'image':
            return  <Image 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'sig':
            return  <Sig 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}
                        kioskPreview = {props.kioskPreviewData}/>;
        break;
		case 'video':
            return  <Video 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		case 'launchpad':
            return  <Launchpad 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        kioskUserName={props.kioskUserName}
                        assoDeviceTypeName={props.assoDeviceTypeName}
                        assoVisitTypeName={props.assoVisitTypeName}
                        username={props.username}
                        rowAllData={rowAllData}
                        current_user={props.current_user}
                        moduleName= {props.moduleName}/>;
        break;
		case 'subjective':
            return  <Subjective 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        kioskUserName={props.kioskUserName}
                        assoDeviceTypeName={props.assoDeviceTypeName}
                        assoVisitTypeName={props.assoVisitTypeName}
                        username={props.username}
                        rowAllData={rowAllData}
                        current_user={props.current_user}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'assessment':
            return  <Assessment 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        kioskUserName={props.kioskUserName}
                        assoDeviceTypeName={props.assoDeviceTypeName}
                        assoVisitTypeName={props.assoVisitTypeName}
                        username={props.username}
                        rowAllData={rowAllData}
						current_user={props.current_user}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'grid5point':
           return   <Grid func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'basicgrid':
           return   <BasicGrid func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'grid-multicheck':
           return   <GridMultiCheck func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'grid-singlecheck':
           return   <GridSingleCheck func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'gridcheck':
           return   <GridCheck func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'grid-dropdown':
           return   <GridDropdown func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'likert':
           return   <Likert func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'smart':
           return   <Smart func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'reduction':
           return   <Reduction func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'weighted':
           return   <Weighted func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		
		
        default:
			return  '';
    }
}

function generatehtmlfrom(rowAllData,callback = null,props){ 
    
    switch(rowAllData[4].toLowerCase()) {
        case 'a6':
            return  'A6';
        break;
        case 'q1':
            return  <PQ1 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        rowAllData={rowAllData}
                        kioskResponse={props.kioskResponse}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q2':
            return  <PQ2 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q3':
            return  <PQ3 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q4':
            return  <PQ4 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q5':
            return  <PQ5 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q6':
            return  <PQ6 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q7':
           return   <PQ7 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt}
                        kioskResponse={props.kioskResponse} 
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q8':
           return   <PQ8 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q9':
           return   <PQ9 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q10':
           return   <PQ10 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q11':
           return   <PQ11 func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'q11b':
           return   <PQ11B func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'title':
           return   '';
        break;
        case 'text':
            return  '';
        break;
        case 'timer':
            return  <PTimers 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'slider':
            return  <PSliders 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'image':
            return  <Image 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
        case 'sig':
            return  <PSig 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		case 'subjective':
            return  <PSubjective 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        kioskUserName={props.kioskUserName}
                        assoDeviceTypeName={props.assoDeviceTypeName}
                        assoVisitTypeName={props.assoVisitTypeName}
                        username={props.username}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'assessment':
            return  <PAssessment 
                        func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        kioskUserName={props.kioskUserName}
                        assoDeviceTypeName={props.assoDeviceTypeName}
                        assoVisitTypeName={props.assoVisitTypeName}
                        username={props.username}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		case 'grid5point':
           return   <PGrid func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		case 'basicgrid':
           return   <PBasicGrid func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		case 'grid-multicheck':
           return   <PGridMultiCheck func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		case 'grid-singlecheck':
           return   <PGridSingleCheck func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		case 'gridcheck':
           return   <PGridCheck func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'likert':
           return   <PLikert func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'smart':
           return   <PSmart func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'reduction':
           return   <PReduction func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'weighted':
           return   <PWeighted func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
		case 'grid-dropdown':
           return   <PGridDropdown func={callback} 
                        allData={props.allData} 
                        selectedSheet = {props.selectedSheet} 
                        selDropOpt={props.selDropOpt} 
                        kioskResponse={props.kioskResponse}
                        rowAllData={rowAllData}
                        moduleName= {props.moduleName}/>;
        break;
		
        default:
			return  '';
    }
}

function handleAuthResult(sheetConfigId,btnClicked,functions,selDropOpt,callback,selectReviewOpt) {
    let rowId ='';
    let rowVal = [];
    { functions[0].allData.map((valu, i) => { 
        if(valu[0] == functions[0].saveQueId){
            rowId = (i+2);
			rowVal = valu;
        }
    })}
	//console.log('functions->',functions[0]);
	//return false;
    var preCondID = '';  
    var preCondVal = '';  
    if ( !! gapi.auth.getToken()){
        let accessToken = gapi.auth.getToken().access_token;
        //let spreadsheetID = config.spreadsheetId;
        let params = {
            access_token:accessToken,
            spreadsheetId: sheetConfigId,
        };
        if(functions[0].preCondID && functions[0].preCondVal){
            preCondID = functions[0].preCondID;
            preCondVal = functions[0].preCondVal;
        }

        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: [{
                "range": functions[0].selectedSheet+'!H'+rowId+':I'+rowId,
                "majorDimension": "ROWS",
                "values": 
                [ [ functions[0].preCondID,functions[0].preCondVal] ]
            }], 
        };
        let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        request.then(function(response) {
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
        let queAction = '';
        let row ='';
        let row1 ='';
        let row2 ='';
        let row3 ='';
        let row4 ='';
        let data = [];

		//let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		
		let qtype = functions[0].chooseAnsType;
		
		if(qtype == 'title' || qtype == 'text' || qtype == 'child header 1' ||  qtype == 'child header 2' || qtype == 'child header 3' ||  qtype == 'parent header'){
			functions[0].addTagsText = '';
			functions[0].chooseAnsType = '';
		}
		
		if (btnClicked == 'accept_improved') {
				
				
				let question = rowVal[23] ? rowVal[23] : rowVal[4];
				let answerType = rowVal[24] ? rowVal[24] : rowVal[5];
				let options = rowVal[22] ? rowVal[22] : rowVal[6];
				let tags = rowVal[19] ? rowVal[19] : rowVal[13];
				row1 = {
					range: functions[0].selectedSheet+'!R'+rowId+':Y'+rowId,   // Update single cell
					values: [
						[ '','','','','','','','' ]
					]
				};
				row2 = {
					range: functions[0].selectedSheet+'!M'+rowId+':N'+rowId,   // Update single cell
					values: [
						[ functions[0].editorSourceHTML,tags ]
					]
				};
				row3 = {
					range: functions[0].selectedSheet+'!E'+rowId+':G'+rowId,   // Update single cell
					values: [
						[ question,answerType,options ]
					]
				}
				
				row4 = {
					range: functions[0].selectedSheet+'!CU'+rowId,   // Update single cell
					values: [
						[ currentDate ]
					]
				};

				data.push(row1)
				data.push(row2)
				data.push(row3)
				data.push(row4)
			
		}else{
			if(functions[0].queAction == 'Keep as is' || functions[0].queAction =='Complex' || functions[0].queAction =='Discard'){
				row = {
					range: functions[0].selectedSheet+'!R'+rowId+':T'+rowId,   // Update single cell
					values: [
						[ functions[0].queAction,'',functions[0].addTagsText ]
					]
				};
				data.push(row)
			}else if(functions[0].queAction =='changeQueType'){
				row = {
					range: functions[0].selectedSheet+'!R'+rowId+':T'+rowId,   // Update single cell
					values: [
						[ 'changeQformat','',functions[0].addTagsText ]
					]
				};
				row1 = {
					range: functions[0].selectedSheet+'!Y'+rowId,   // Update single cell
					values: [
						[ functions[0].chooseAnsType ]
					]
				};
				data.push(row)
				data.push(row1)
			}else if(functions[0].queAction =="changeQueText"){
				row = {
					range: functions[0].selectedSheet+'!R'+rowId+':T'+rowId,   // Update single cell
					values: [
						[ 'changeQformat','',functions[0].addTagsText ]
					]
				};
				row1 = {
					range: functions[0].selectedSheet+'!X'+rowId,   // Update single cell
					values: [
						[ functions[0].changeQueInpText ]
					]
				};
				data.push(row)
				data.push(row1)
			}else if(functions[0].queAction == "changeAnsOpt"){
				row = {
					range: functions[0].selectedSheet+'!R'+rowId+':T'+rowId,   // Update single cell
					values: [
						[ 'changeQformat','',functions[0].addTagsText ]
					]
				};
				row1 = {
					range: functions[0].selectedSheet+'!W'+rowId,   // Update single cell
					values: [
						[ functions[0].changeAnsInpText ]
					]
				};
				data.push(row)
				data.push(row1)
			}else if(functions[0].queAction == "ChangeNotes"){
				row = {
					range: functions[0].selectedSheet+'!M'+rowId,   // Update single cell
					values: [
						[ functions[0].editorSourceHTML ]
					]
				};
				data.push(row)
			}else if(functions[0].queAction == "ChangeTracking"){
				row = {
					range: functions[0].selectedSheet+'!DV'+rowId,   // Update single cell
					values: [
						[ functions[0].chooseTracking ]
					]
				};
				data.push(row)
			}else if(functions[0].queAction == "ChangeCompany"){
				row = {
					range: functions[0].selectedSheet+'!DL'+rowId,   // Update single cell
					values: [
						[ functions[0].chooseCompany ]
					]
				};
				data.push(row)
			}else if(functions[0].queAction == "changeOutputText"){
				row = {
					range: functions[0].selectedSheet+'!J'+rowId,   // Update single cell
					values: [
						[ functions[0].changeOutInpText ]
					]
				};
				data.push(row)
			}else if(functions[0].queAction == "changeValiType"){
				row = {
					range: functions[0].selectedSheet+'!AW'+rowId,   // Update single cell
					values: [
						[ functions[0].validationType ]
					]
				};
				data.push(row)
				if(functions[0].minValue && functions[0].maxValue){
					row1 = {
						range: functions[0].selectedSheet+'!AY'+rowId+':AZ'+rowId,   // Update single cell
						values: [
							[ functions[0].minValue,functions[0].maxValue ]
						]
					};
					data.push(row1)
				}
			}else{ 
				row = {
					range: functions[0].selectedSheet+'!R'+rowId+':T'+rowId,   // Update single cell
					values: [
						[ 'changeQformat','',functions[0].addTagsText ]
					]
				};
				row1 = {
					range: functions[0].selectedSheet+'!X'+rowId+':Y'+rowId,   // Update single cell
					values: [
						[ functions[0].changeQueInpText,functions[0].chooseAnsType ]
					]
				};
				row2 = {
					range: functions[0].selectedSheet+'!CU'+rowId,   // Update single cell
					values: [
						[ currentDate ]
					]
				};
				
				let chooseAnsType = functions[0].chooseAnsType;
				
				if(chooseAnsType.toLowerCase() == 'parent header'){
					row3 = {
						range: functions[0].selectedSheet+'!DW'+rowId,   // Update single cell
						values: [
							[ functions[0].asGroup ]
						]
					};
				}else{
					row3 = {
						range: functions[0].selectedSheet+'!DX'+rowId,   // Update single cell
						values: [
							[ functions[0].asGroup ]
						]
					};
				}
				
				data.push(row)
				data.push(row1)
				data.push(row2)
				data.push(row3)
			}
		}
		
		let lastUpDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		let totalFields = functions[0].allData.length;
		let mds_name = rowVal[118];
		let mdsJson = 'MDS_'+ mds_name +'_'+lastUpDate+'_'+totalFields;
		let newRow = {
			range: functions[0].selectedSheet+'!DT2',
			values: 
			[
				[ mdsJson ]
			]
		};
		
		data.push(newRow);

        let batchUpdateValuesRequestBody1 = {
            valueInputOption: 'USER_ENTERED',
            data: data,
        };
		
        let request1 = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody1);
        request1.then(function(response) {
			setTimeout(function(){
				if (btnClicked == 'accept_improved') {
					alert('Data Improved Successfully');
				}else{
					alert('Data Saved Successfully');
				}
			}, 3000);
        }, function(reason) {
            alert(reason.result.error.message);
        });
        $('#code').modal('hide');
		
        let status = 'improved';
		setTimeout(function(){
            if(btnClicked){
				if (btnClicked === 'next') {
					functions[1]();
				} else if (btnClicked === 'previous'){
					functions[2]();
				}
			}
			
        }, 2000);
		setTimeout(function(){
			callback();
        }, 3000);
		if(selectReviewOpt){
			selectReviewOpt(status, functions[0].selectedSheetId, functions[0].selectedSheet);
		}
		
    }else{
        console.log(gapi.auth.getToken())
    }   
}

function newSheetDataSaveOld(sheetName,selDropOpt,selectedSheetId,selectedSheet1){
    let  spreadsheetID = config.spreadsheetId;
    
    var currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
    var guids= guid();
    var latestSheetName = "P2-"+selectedSheet1+"-"+sheetName;
    let params = {
        spreadsheetId: spreadsheetID,
        sheetId: selectedSheetId,
    };
    let copySheetToAnotherSpreadsheetRequestBody = {
        destinationSpreadsheetId: config.pahseII_spreadsheetId,
    };
    let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
        request.then(
            response => {
                let params1 =  {
                    spreadsheetId: config.pahseII_spreadsheetId,
                };
                let getRequest = gapi.client.sheets.spreadsheets.get(params1);
                getRequest.then(function(response) {
                    let length = response.result.sheets.length;
                    let lastSheetId = response.result.sheets[length-1].properties.sheetId;
                    let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
                    let requests ={
                        "requests": [
                            {
                              "updateSheetProperties": {
                                "properties": {
                                  "sheetId": lastSheetId,
                                  "title": latestSheetName+" "+currentDate+" "+guids,
                                },
                                "fields": "title"
                              }
                            }
                        ]
                    }
                    let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
                    renameRequest.then(function(response) {
                        let getRequest = gapi.client.sheets.spreadsheets.get(params1);
                        getRequest.then(function(response) {
                            let lastSheetName = response.result.sheets[length-1].properties.title;
                            let getsheetdata = gapi.client.sheets.spreadsheets.values.get({
                                    spreadsheetId: config.pahseII_spreadsheetId,
                                    range:lastSheetName, 
                                })
                                getsheetdata.then(
                                    response => {
                                    const allData = response.result.values;
                                    allData.shift();       
                                    
                                let accessToken = gapi.auth.getToken().access_token;
                                let params = {
                                    access_token:accessToken,
                                    spreadsheetId: config.pahseII_spreadsheetId,
                                };
                                let rowId ='';
                                let update_rows = [];
                                { allData.map((valu, i) => {
                                    let question = valu[23] ? valu[23] : valu[4];
                                    let answerType = valu[24]? valu[24] : valu[5];
                                    let options = valu[22]? valu[22] : valu[6]; 
                                    let notes = valu[18] ? valu[18]:valu[12];
                                    let tags = valu[19] ? valu[19]:valu[13];
                                    /*if(valu[35]){
                                        let guid1 = valu[35];
                                        let row4 = {
                                            range: lastSheetName+'!CN'+rowId,   // Update single cell
                                            values: [
                                                [ guid1 ]
                                            ]
                                        };
                                        update_rows.push(row4);
                                    } */  
                                       rowId = (i+2);
                                        let row1 = {
                                            range: lastSheetName+'!E'+rowId+':I'+rowId,   // Update single cell
                                            values: [
                                                [ question,answerType,options,valu[7],valu[8] ]
                                            ]
                                        };
                                        let row2 = {
                                            range: lastSheetName+'!M'+rowId+':N'+rowId,   // Update single cell
                                            values: [
                                                [ notes,tags ]
                                            ]
                                        };
                                        let row3 = {
                                            range: lastSheetName+'!R'+rowId+':AI'+rowId,   // Empty cells
                                            values: [
                                                [ '','','','','','','','','','','','','','','','','','',]
                                            ]
                                        };
                                        update_rows.push(row1);
                                        update_rows.push(row2); 
                                        update_rows.push(row3);  

                                })}
                                let batchUpdateValuesRequestBody = {
                                    valueInputOption: 'USER_ENTERED',
                                    data: update_rows
                                }; 
                                let request2 = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
                                request2.then(function(response) { 
                                    alert('Data Saved Successfully'); 
                                }, function(reason) {
                                    alert(reason.result.error.message);
                                });
                            })
                        });
                    }, function(reason) {
                        console.error('error: ' + reason.result.error.message);
                    });
                }, function(reason) {
                    console.error('error: ' + reason.result.error.message);
                });

            }, function(reason) {
            console.error('error: ' + reason.result.error.message);
    });  
}

function newSheetDataSave(allData,selectedSheet,callback){
    
	let accessToken = gapi.auth.getToken().access_token;
	let spreadsheetID = config.spreadsheetId;
	let params = {
		access_token:accessToken,
		spreadsheetId: spreadsheetID,
	};
    var currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
    var guids= guid();
	let rowId ='';
	let update_rows = [];
	{ allData.map((valu, i) => {
		
		let question = valu[23] ? valu[23] : valu[4];
		let answerType = valu[24]? valu[24] : valu[5];
		let options = valu[22]? valu[22] : valu[6]; 
		let notes = valu[18] ? valu[18]:valu[12];
		let tags = valu[19] ? valu[19]:valu[13];
		
		rowId = (i+2);
		let row1 = {
			range: selectedSheet+'!E'+rowId+':I'+rowId,   // Update single cell
			values: [
				[ question,answerType,options,valu[7],valu[8] ]
			]
		};
		let row2 = {
			range: selectedSheet+'!M'+rowId+':N'+rowId,   // Update single cell
			values: [
				[ notes,tags ]
			]
		};
		let row3 = {
			range: selectedSheet+'!R'+rowId+':Y'+rowId,   // Empty cells
			values: [
				[ '','','','','','','','' ]
			]
		};

		update_rows.push(row1);
		update_rows.push(row2); 
		update_rows.push(row3);  

	})}
	let batchUpdateValuesRequestBody = {
		valueInputOption: 'USER_ENTERED',
		data: update_rows
	}; 
	let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
	request.then(function(response) { 
		alert('Data Improved Successfully');
		callback();
		
		setTimeout(function(){
            $('.comments').css('backgroundColor','#ffffff')
        }, 3000);
		
		
	}, function(reason) {
		alert(reason.result.error.message);
	});
}

function saveOriginalData(state, props){
    let accessToken = gapi.auth.getToken().access_token;
    let allDatas = props.allData;
    let approvedArr = state.checkedApprove;
    let needArr = state.checkedNeeds;
    let params = {
        access_token:accessToken,
        spreadsheetId: config.spreadsheetId,
    };
    let updateData = [];
    let allData = [];
    let rowId='';
    let guids = '';
    for (let i in allDatas) {
        let approve ='';
        let need ='';
        if(approvedArr.get(allDatas[i][0]) == true && needArr.get(allDatas[i][0]) == true){
            approve = 'Approved';
            need = 'Need-attention';
        }else if(approvedArr.get(allDatas[i][0]) == true){
            approve = 'Approved';
        }else if(needArr.get(allDatas[i][0]) == true){
            need = 'Need-attention';
        }
        let rowDatas = allDatas[i];
        rowId = parseInt(i) + 2;
        if(rowDatas[35]){
                guids = rowDatas[35];
        }else{
            guids = rowDatas[91];
        }
        let row1 = {
            "range": props.selectedSheet+"!Z"+rowId+":AA"+rowId,   // Update single cell
            "majorDimension": "ROWS",
            "values": 
            [
                [ approve,need ]
            ]
        };
        let row2 = {
			"range": props.selectedSheet+'!CN'+rowId,
			"majorDimension": "ROWS",
			"values": 
			[
				[guids]
			]
		};
		updateData.push(row1) 
        updateData.push(row2)
		if(approve){
			let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
			let row3 = {
				range: props.selectedSheet+'!CU'+rowId, 
				values: [
					[ currentDate ]
				]
			};
			
			updateData.push(row3) 
			
			
		}

         
    }
	
	let lastUpDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
	let totalFields = allDatas.length;
	let mds_name = allDatas[0][118];
	let mdsJson = 'MDS_'+ mds_name +'_'+lastUpDate+'_'+totalFields;
	let row4 = {
		range: props.selectedSheet+'!DT2',
		values: 
		[
			[ mdsJson ]
		]
	};
	updateData.push(row4)
	
	
    let batchUpdateValuesRequestBody = {
        valueInputOption: 'USER_ENTERED',
        data: updateData, 
    };
    let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
    updateRequest.then(function(response) {
        alert('Data Saved Successfully');
    }, function(reason) {
        alert(reason.result.error.message);
    });
}

function idOf(i){
    return (i >= 26 ? idOf((i / 26 >> 0) - 1) : '') +  'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0];
}

function saveConfigFile (sheetName,guids,currentDate,mainData,facilityID,groupID){
    
    let accessToken = gapi.auth.getToken().access_token;
    let params1 = {
        spreadsheetId: config.spreadsheetId,
    };
	let SelLangSheet = $( "#SelLangSheet option:selected" ).text();
	
	if(!SelLangSheet){
		SelLangSheet = ' English';
	}

    var sheetVal = '';
    let updateData=[];
    gapi.client.load("sheets", "v4", () => {
        gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: config.spreadsheetId,
            range:'TTEVAL-Config Files',
        })
        .then(response => {
                sheetVal = response.result.values;
                let length = sheetVal[0].length;
				let firstRow = sheetName;
				let mdsName = mainData[0][118];
                let secondRow = mainData[0][91];
                let thirdRow = currentDate;
                let fourRow = guids;
                let fifthRow = mainData.length+' Fields';
				let sixthRow = mainData[0][92];
				let seventhRow = mainData[0][94];
				let eighthRow = mainData[0][95];
				let ninthRow = facilityID ? facilityID : 'All';
				let tenthRow = groupID ? groupID : '-';
				let elevenRow = SelLangSheet ? SelLangSheet : '-';
				
				let columnName = idOf(length);
				
                let row1 = {
                        "majorDimension": "ROWS",
                        "range": "TTEVAL-Config Files!"+columnName+1,
                        "values": [
                            [ firstRow ]
                        ]
                    }
                let row2 = {
                    "range": "TTEVAL-Config Files!"+columnName+'2',
                    "majorDimension": "ROWS",
                    "values": [
                        [ mdsName ]
                    ]
                }
				let row3 = {
                    "range": "TTEVAL-Config Files!"+columnName+'3',
                    "majorDimension": "ROWS",
                    "values": [
                        [ secondRow ]
                    ]
                }
                let row4 = {
                    "range": "TTEVAL-Config Files!"+columnName+'4',
                    "majorDimension": "ROWS",
                    "values": [
                        [ thirdRow ]
                    ]
                }
                let row5 = {
                    "range": "TTEVAL-Config Files!"+columnName+'5',
                    "majorDimension": "ROWS",
                    "values": [
                        [ fourRow ]
                    ]
                }
				
				let row6 = {
                    "range": "TTEVAL-Config Files!"+columnName+'6',
                    "majorDimension": "ROWS",
                    "values": [
                        [ fifthRow ]
                    ]
                }
				
				let row7 = {
                    "range": "TTEVAL-Config Files!"+columnName+'7',
                    "majorDimension": "ROWS",
                    "values": [
                        [ sixthRow ]
                    ]
                }
				
				let row8 = {
                    "range": "TTEVAL-Config Files!"+columnName+'8',
                    "majorDimension": "ROWS",
                    "values": [
                        [ seventhRow ]
                    ]
                }
				let row9 = {
                    "range": "TTEVAL-Config Files!"+columnName+'9',
                    "majorDimension": "ROWS",
                    "values": [
                        [ eighthRow ]
                    ]
                }
				let row10 = {
                    "range": "TTEVAL-Config Files!"+columnName+'10',
                    "majorDimension": "ROWS",
                    "values": [
                        [ ninthRow ]
                    ]
                }
				let row11 = {
                    "range": "TTEVAL-Config Files!"+columnName+'11',
                    "majorDimension": "ROWS",
                    "values": [
                        [ tenthRow ]
                    ]
                }
				
				let row12 = {
                    "range": "TTEVAL-Config Files!"+columnName+'12',
                    "majorDimension": "ROWS",
                    "values": [
                        [ elevenRow ]
                    ]
                }
				
				let row13 = {
                    "range": "TTEVAL-Config Files!"+columnName+'13',
                    "majorDimension": "ROWS",
                    "values": [
                        [ '{eoh}' ]
                    ]
                }
				
				updateData.push(row1);
                updateData.push(row2);
                updateData.push(row3);
                updateData.push(row4);
                updateData.push(row5);
                updateData.push(row6);
                updateData.push(row7);
                updateData.push(row8);
                updateData.push(row9);
                updateData.push(row10);
                updateData.push(row11);
                updateData.push(row12);
                updateData.push(row13);
                
                let j = 14;
				
                mainData.map((rowDatas,i)=>{
                    
					let configP = rowDatas[34] ? rowDatas[34] : rowDatas[16];
                    if(configP){
						let row14 = {
                            "range": "TTEVAL-Config Files!"+columnName+j,
                            "majorDimension": "ROWS",
                            "values": [
                                [ configP ]
                            ]
                        }
                        updateData.push(row14);
					}
					
					
                    j++;
                })
				let lastrow = {
                    "range": "TTEVAL-Config Files!"+columnName+j,
                    "majorDimension": "ROWS",
                    "values": [
                        [ '{eof}' ]
                    ]
                }
				updateData.push(lastrow);
				
                let batchUpdateValuesRequestBody = {
                    "valueInputOption": 'USER_ENTERED',
                    data: updateData
                }; 
                let request2 = gapi.client.sheets.spreadsheets.values.batchUpdate(params1,batchUpdateValuesRequestBody);
                request2.then(function(response) { 
                    alert('TEVAL-Config Files Data Saved Successfully');
					//window.location.reload(); 
                }, function(reason) {
                    alert(reason.result.error.message);
                }); 
            },
        );
    });
}

function handleSubmitReset(selectedSheet, selectedOpt, selectedSheetId, allData, selectReviewOpt){
	
	if ( !! gapi.auth.getToken()){
        let accessToken = gapi.auth.getToken().access_token;
		let spreadsheetID = config.spreadsheetId;
        /* let spreadsheetID = '';
        if(selectedOpt == "original" || selectedOpt == "improved"){
            spreadsheetID = config.spreadsheetId;
        }else{
            spreadsheetID = config.pahseII_spreadsheetId;
        } */
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        let rowId ='';
        let update_rows = [];
        allData.map((value, i) => {
            
			/* let configDataRow = '';
			if(value[16]){
				configDataRow = value[16].split(',');
			} */
			
            rowId = (i+2);
            let posId = (i+1);
			
			let positionConfig = value[16].split(',');
			positionConfig[7] = posId;
			let newPos = positionConfig.join();
			
			let row1 = {
				range: selectedSheet+'!K'+rowId,
				majorDimension: "ROWS",
				values: 
				[
					[ posId ]
				]
			};
			
            let row2 = {
				range: selectedSheet+'!Q'+rowId,
				majorDimension: "ROWS",
				values: 
				[
					[ newPos ]
				]
			};
            let row3 = {
                range: selectedSheet+'!R'+rowId+':AI'+rowId, 
                majorDimension: "ROWS",  // Update single cell
                values: [
                    [ '','','','','','','','','','','','','','','','','','' ]
                ]
            };
            update_rows.push(row1);   
            update_rows.push(row2);   
            update_rows.push(row3);   
               
        })

        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: update_rows
        }; 
        let request2 = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        request2.then(function(response) {
            clearSheetRows(spreadsheetID, selectedSheetId, selectedSheet, allData, selectReviewOpt)
        }, function(reason) {
            alert(reason.result.error.message);
        });
    }   
}

function uploadFilePath(file, fieldId, props){
        let fileType = file.path.split('.').pop();
        let embedCode='';
        if(fileType == 'stl'){
            embedCode = '<STLViewer model='+file.path+' width={400} height={400} modelColor="#B92C2C" backgroundColor="#EAEAEA" rotate={true} orbitControls={true}/>'
        }else{
            embedCode = '<iframe width="100%" height="100%" src='+file.path+'></iframe>'
        }
        let fileData = file.path+','+file.name+','+file.description+','+file.created_at;
        let htmlFileData = '<b>Name:</b> '+file.name+'<br><b>Description:</b> '+file.description+'<br><b>Date:</b> '+file.created_at;
        let updateData=[];
        let guids = '';
        let rowId ='';
        let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = config.spreadsheetId;
        /* if(props.selDropOpt == "original"){
            spreadsheetID = config.spreadsheetId;
        }else{
            spreadsheetID = config.pahseII_spreadsheetId;
        } */
        let allDatas = props.allData;
        
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        
        allDatas.map((rowDatas, i) => { 
            if(rowDatas[0] == fieldId){
                rowId = (i+2);
                let row1 = {
                    "range": props.selectedSheet+'!CV'+rowId,
                    "majorDimension": "ROWS",
                    "values": 
                    [
                        [ fileData ]
                    ]
                };
                updateData.push(row1)
            }

        })
        
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
            $( "#fileAns"+fieldId ).html(htmlFileData);
            $( "#fileHtmlDiv"+fieldId ).html(embedCode);
            $( "#fileDiv"+fieldId ).css('display','none');
            alert('File saved Successfully');
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
        //this.props.updatedData();
        //var path  =$( "#videoHtmlDiv" ).val(embedCode);
        
        //this.setState({videoPath:embedCode});
}

function clearSheetRows(spreadsheetId, selectedSheetId, sheetName, allData, selectReviewOpt){ 
    let rowId ='';
    let clear_rows = [];
    allData.map((valu, i) => {
        rowId = (i+1);  
        if(valu[35]){
            let row = rowId
            clear_rows.push(row);
            
        }
    })
    var lastEl = clear_rows[clear_rows.length-1]+1;
    //console.log(clear_rows.length)
    if(clear_rows.length > 0){
        let params =  {
            spreadsheetId: spreadsheetId,
        };
        let deleteRow = {
            "requests": [
            {
              "deleteDimension": {
                "range": {
                  "sheetId": selectedSheetId,
                  "dimension": "ROWS",
                  "startIndex": clear_rows[0],
                  "endIndex": lastEl
                }
              }
            }]
        }
        let request5 = gapi.client.sheets.spreadsheets.batchUpdate(params, deleteRow);
        request5.then(function(response) {
            let status = 'original';
            selectReviewOpt(status, selectedSheetId, sheetName);
            alert("Data clear Successfully!");
            window.location.reload();          
        }, function(reason) {
            alert(reason.result.error.message);
        });
    }
}

function handleSubmitResetInc(selectedSheet, allData){ 
    
        let spreadsheetID = config.spreadsheetId;
       
        let params = {
            spreadsheetId: spreadsheetID,
        };
        let rowId ='';
        let update_rows = [];
        allData.map((valu, i) => {
				
			rowId = (i+2);
			if(valu[35]){
				let row2 = {
					range: selectedSheet+'!O'+rowId,
					majorDimension: "ROWS",
					values: 
					[
						[ 'N' ]
					]
				};
				update_rows.push(row2);
			}
			let row3 = {
				range: selectedSheet+'!R'+rowId+':AI'+rowId, 
				majorDimension: "ROWS",  // Update single cell
				values: [
					[ '','','','','','','','','','','','','','','','','','' ]
				]
			};
			
			let row4 = {
				range: selectedSheet+'!CV'+rowId,
				values: [
					[ '' ]
				]
			};
 
			update_rows.push(row3);   
			//update_rows.push(row4);   
             
        })

        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: update_rows
        }; 
        let request2 = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        request2.then(function(response) {
            //clearSheetRows(spreadsheetID, selectedSheetId, selectedSheet, allData, selectReviewOpt);
			setTimeout(function(){
				window.location.reload();
			}, 5000);
        }, function(reason) {
            alert(reason.result.error.message);
        });
   // }
}

function getConfigResponse(){
	return new Promise((resolve, reject) => {
		let spreadsheetID = config.phase5spreadsheetId; 
		let params1 = {
			spreadsheetId: spreadsheetID,
		};
		
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetID,
				range:'Sheet1',
			})
			.then(response => {
				let data = response.result.values;
				let configResponse = [];
				//console.log('data->',data);
				
				for(let i in data){
					
					let file_no = 0;
					i = parseInt(i);

					for(let k in data[i]){
						let inner_data = { 'FieldID':'', 'response': '', 'UserID':'', 'UserClass':'', 'Date':''}
						k = parseInt(k);

						if(k % 6 ==  0){
							if(i < 5){
								if(typeof configResponse[file_no] == 'undefined' ){
									configResponse[file_no] = []
								}
								configResponse[file_no].push(data[i][k])
							}

							if(i > 6){
								if(typeof configResponse[file_no][5] == 'undefined' ){
									configResponse[file_no][5] = [];
								}
								let res_key = k+1;
								let user_id_key = k+3;
								let user_class = k+4;
								let date = k+5;
								inner_data['FieldID'] = data[i][k];
								if(typeof data[i][res_key] != 'undefined') {
									inner_data['response'] = data[i][res_key];
								}
								if(typeof data[i][user_id_key] != 'undefined') {
									inner_data['UserID'] = data[i][user_id_key];
								}
								if(typeof data[i][user_class] != 'undefined') {
									inner_data['UserClass'] = data[i][user_class];
								}
								if(typeof data[i][date] != 'undefined') {
									inner_data['Date'] = data[i][date];
								}
								configResponse[file_no][5].push(inner_data)
							}

							file_no++;
							
						}
						
					}
				   
				}
				resolve(configResponse);
			},
			)
		});
	});
}

function getConfigFacilityId(sheetName){
	return new Promise((resolve, reject) => {
		let spreadsheetId = config.spreadsheetId;
		let configName = 'TTEVAL-Config Files';
		let columnName = sheetName.split('-');
		let facilityId = '';
		if(columnName[0] == 'P4'){
			configName = 'TTEVAL-Config Phase-4';
		}
		
		let params = {
			spreadsheetId: spreadsheetId
		};
		let startIndex = '';
		
		gapi.client.load("sheets", "v4", () => {
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: config.spreadsheetId,
				range:configName,
			})
			.then(response => {
					let sheetVal = response.result.values;
					let configGuid = sheetName.split(' ');
					configGuid = configGuid[configGuid.length - 1];
					sheetVal[4].map((val, i) => {
						if(configGuid == val){
							startIndex = i;
							facilityId = sheetVal[8][i];
						}
					});
					resolve(facilityId);
				},
			);
		});	
	});
}

function handleClearTempSave(selectedSheet,allDatas){
	let accessToken = gapi.auth.getToken().access_token;
	let spreadsheetID = config.spreadsheetId;
	let params = {
		access_token:accessToken,
		spreadsheetId: spreadsheetID,
	};
	let rowId ='';
	let updateData=[];
	
	//let allDatas = this.state.allData;
	
	allDatas.map((rowDatas,i)=>{
			
		rowId = (i+2);
		if(rowDatas[5] != 'image'){
			let row1 = {
				range: selectedSheet+'!CV'+rowId,
				values: [
					[ '' ]
				]
			};
			updateData.push(row1)
		}
		
		let row2 = {
			range: selectedSheet+'!DD'+rowId+':DF'+rowId,
			values: [
				[ '','','' ]
			]
		};
		
		
		updateData.push(row2)
	})
	
	let batchUpdateValuesRequestBody = {
		valueInputOption: 'USER_ENTERED',
		data: updateData, 
	};
	let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
	updateRequest.then(function(response) {
		alert('Data Clear Successfully')
		setTimeout(function(){
			//window.location.reload();
		}, 3000);
	}, function(reason) {
		//alert(reason.result.error.message);
	});
}

function getConfigData(selectedSheet,configSheets){
	
	let configData = [];
	configSheets.map((sheet, i) => {
		
		if($.trim(selectedSheet) == $.trim(sheet[0])){
			//console.log(sheet);
			sheet.map((sh, i) => {
				if(i > 12 && sh != '{eof}'){
					let sht = sh.split(',');
					configData.push(sht);
				}
			})
		}
	})
	
	return 	configData;	
}

export { load, generatehtmlfromlocation, uploadFilePath, generatehtmlfrom, handleAuthResult, saveConfigFile, newSheetDataSave,saveOriginalData, getSheetsById, getSheetType, getSheetTypeFacility, getPdfFromApi, getSheetsIdByName, handleSubmitReset, handleSubmitResetInc, clearSheetRows, getConfigResponse, getConfigFacilityId, handleClearTempSave, getSheetApiName, getConfigData}; 
