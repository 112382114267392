import React, { Component } from "react";
import 'bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import Timer from 'react-timer';
import Slider from 'react-input-slider';
import axios,{post,get} from 'axios'; 
import SecondsTohhmmss from './SecondsTohhmmss'
import PropTypes from 'prop-types'
import {DebounceInput} from 'react-debounce-input';

class Q1 extends Component {
  constructor(props) {
    super(props);
    this.state={
        q1value:'',
    }
    this.shoot = this.shoot.bind(this); 
    }
    
    shoot(event) {
        this.setState({q1value:event.target.value})
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
 
    render() {
        const {moduleName,kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        var options = this.props.rowAllData[5];
        if(options){
            var res = options.split(',');
        }
        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
                return (
                    <option value={courthouse} key={i}> {courthouse} </option>
                );
            })
        }
        return (
            <select className="form-control" value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q1} onChange={this.shoot}>
                <option value=""> Please Select</option>
                    {optionsHtml}
            </select>
        );
    }
}

class Q2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: '',
            newoption:'',
        }
        this.shoot = this.shoot.bind(this); 
        this.saveOption = this.saveOption.bind(this); 
    }
    
    shoot(event) {
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    
    addUser = () => {
        this.setState({
          users: <User functin={this.saveOption} id={this.props.rowAllData[8]} kioskResponse={this.props.kioskResponse}/>
        })
    }
    saveOptionData=(userOption)=>{
        gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: false});
        let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = ''; 
        if(this.props.selDropOpt == "original"){
            spreadsheetID = config.spreadsheetId;
        }else{
            spreadsheetID = config.pahseII_spreadsheetId;
        }
        let updateData = [];
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        let rowId ='';
        { this.props.allData.map((valu, i) => { 
            if(valu[0] == this.props.rowAllData[8]){
                rowId = (i+2);
            }
        })}
        let row1 = {
            "range": this.props.selectedSheet+'!R'+rowId,   // Update single cell
            "majorDimension": "ROWS",
            "values": 
            [
                [ 'changeQformat']
            ]
        };
        let row2 = {
            "range": this.props.selectedSheet+'!W'+rowId+':Y'+rowId,   // Update single cell
            "majorDimension": "ROWS",
            "values": 
            [
                [ this.props.rowAllData[5]+','+userOption,this.props.rowAllData[3],this.props.rowAllData[4] ]
            ]
        };
        updateData.push(row1);
        updateData.push(row2);
        let batchUpdateValuesRequestBody1 = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let request1 = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody1);
        request1.then(function(response) {
            alert('data added successfully');
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
    }
    saveOption(event,newoption){
        this.saveOptionData(newoption)
        this.setState({newoption:newoption});
        event.preventDefault();  
    }
    render() {
        const {kioskResponse}=this.props
        const {newoption} = this.state;
        let id  = this.props.rowAllData[8];
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }
        if(newoption){
            $('#select1'+this.props.rowAllData[8]).append($("<option></option>").attr("value",newoption).text(newoption));
        }
        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
                return (
                    <option value={courthouse} key={i}>{courthouse}</option>
                );
            })
        }
        return (
            <div className="abc">
                <div className="input-group mb-3">
                    <select className="form-control" id={"select1"+this.props.rowAllData[8]} value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q1} onChange={this.shoot}>
                        <option value=""> Please Select</option>
                            {optionsHtml}
                    </select>
                    <div className="input-group-append">
                    <button type="button" className="btn btn-primary input-group-text" style={{ backgroundColor:'blue',color:'white'}} onClick={this.addUser}>
                        <span className="glyphicon glyphicon-plus"> + </span>
                    </button>
                    </div>
                </div>
                {this.state.users}
            </div>
        );
    }
}

class User extends Component{
    constructor(props) {
        super(props);
        this.optionArray =[];
        this.state = { 
            userOption:'', 
        }
        this.userInput = this.userInput.bind(this);
        this.addInput = this.addInput.bind(this); 
    }
    userInput(event){
        this.setState({userOption: event.target.value});
    }
    addInput(event){
        event.preventDefault();
        this.props.functin(event,this.state.userOption);
        this.setState({userOption: ''});
    }

    render(){
        const {id, kioskResponse}=this.props;
        return (
            <div className="input-group mb-3">
                <input type="text" className="form-control" value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.userOption} onChange={this.userInput}/>
                <div className="input-group-append">
                <button className="btn btn-primary input-group-text" onClick={this.addInput}>Add</button>
                </div>
            </div>
        )
    }
}
 
class Q3 extends Component {
    constructor(props) {
        super(props);
        this.state = { value: [''] }
        this.shoot = this.shoot.bind(this); 
    }

    shoot(event) {
        var resArray = Array.from(event.target.selectedOptions, (item) => item.value)
        var sh =resArray.toString();
        this.setState({value: Array.from(event.target.selectedOptions, (item) => item.value)});
        this.props.func(sh,this.props.rowAllData[2],this.props.rowAllData[8])
        this.setState({value:['']});
    }
   
    
    render() {
        const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        var newArray  =[];
        if(kioskResponse.size > 0){
            var multiRes = kioskResponse.get(id);
            if(multiRes !== undefined){
                newArray = multiRes.split(',')
            }
        }

        var options = this.props.rowAllData[5];
        if(options){
            var res = options.split(',');
        }
        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
                if(newArray.indexOf(courthouse) !== -1){
                    return (
                        <option value={courthouse} selected key={i}>{courthouse}</option>
                    );  
                }else{
                    return (
                        <option value={courthouse} key={i}>{courthouse}</option>
                    );
                }
            })
        }
        return (
            <select className="form-control" multiple={true} onChange={this.shoot}>
                {optionsHtml}
            </select>
         
      );
    }
}

class Q4 extends Component {
    constructor(props) {
    super(props);
        
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        this.setState({[event.target.name]:event.target.value})
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
        var ts = [];
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }

        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
                var name = 'action'+id;
                if(ts.indexOf(courthouse) !== -1){
                    return (
                        <div className="form-check mb-2" key={i}>
                            <label className="form-check-label">
                                <input type="radio" checked="checked" value={courthouse} name={name}/> {courthouse} 
                            </label>
                        </div>
                    );  
                }else{
                    return (
                        <div className="form-check mb-2" key={i}>
                            <label className="form-check-label">
                                <input type="radio" value={courthouse} name={name}/> {courthouse} 
                            </label>
                        </div>
                    );
                }
            })
        }
        return (
            <div onChange={this.shoot}>
               {optionsHtml}
            </div>
         
        );
    }
}

class Q5 extends Component {
    constructor(props) {
        super(props);
            this.state = {
                q5action:''   
            }
            this.shoot = this.shoot.bind(this); 
        }
        shoot(event) {
            var isChecked= event.target.checked;
            var value = '';
            if(isChecked == true){
                value = 'checked';
            }else{
                value = 'not-checked';
            }
            this.setState({q5action:isChecked});
            this.props.func(value,this.props.rowAllData[2],this.props.rowAllData[8]);
        }
        render(){
            const {kioskResponse}=this.props;
            var options = this.props.rowAllData[5];
            var id = this.props.rowAllData[8];
            var ts=[];
            if(kioskResponse.size > 0){
                var resAns = kioskResponse.get(id)
            }
        return (
            <input type="checkbox" checked={kioskResponse.get(id) == 'checked' ? true : this.state.q5action}  onChange={this.shoot}/>           
        );

    }
}

class Q6 extends Component {
    constructor(props) {
        super(props);
            this.state={
                q6:'',
            }
        this.shoot = this.shoot.bind(this); 
    }
    
    shoot(event) {
        this.setState({q6:event.target.value})
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    render(){
        const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        return (
            <DebounceInput type="text" debounceTimeout={4000} className={'form-control res_'+id } value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q6} onChange={this.shoot}/>
        );
    }
}

class Q7 extends Component {
    constructor(props) {
        super(props);
        this.state={
            q7:'',
        }
        this.shoot = this.shoot.bind(this); 
    }
    shoot(event) {
		this.setState({q7:event.target.value})
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    render() {
        const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        return (
       
            <div className="form-group">
                <DebounceInput element="textarea" debounceTimeout={4000} className={'form-control res_'+id } rows="2" id="comment" value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q7} onChange={this.shoot}/>
            </div>
        
        );
    }
}

class Q8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
        };
        this.handleChange = this.handleChange.bind(this); 
        
    }
    handleChange = date => {
        var newDate = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
        this.props.func(newDate,this.props.rowAllData[2],this.props.rowAllData[8]);
        this.setState({
          startDate: date,date
        });
      };

    render(){
        const {kioskResponse}=this.props;
        let id= this.props.rowAllData[8];
        if(kioskResponse.size > 0 ){
            var dateString = kioskResponse.get(id);
            if(dateString !== undefined){
                var dateParts = dateString.split("/");
                var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
        }
        return(
            <DatePicker className={'res_'+id}
              selected={dateObject ? dateObject : this.state.startDate} 
              onChange={this.handleChange}
            />
        );
    }
}

class Q9 extends Component {
    constructor(props) {
    super(props);
        this.state={
            q9:'',
        }
        this.shoot = this.shoot.bind(this); 
    }     
    shoot(event) {
        this.setState({q9:event.target.value})
       this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    render() {
        const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        return (
            <div className="form-group">
                <DebounceInput element="textarea" debounceTimeout={4000} className={'form-control res_'+id } rows="15" id="comment" value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q9} onChange={this.shoot}/>
            </div>
        
        );
    }
}

class Sig extends Component {
    constructor(props){
        super(props)
        this.state ={
            signName:'',
            signTitle:'',
            signCont:'',
            signDate:'',
            errorName:'',
        }
    }
    getvalue=(event)=>{
        let id = this.props.rowAllData[8];
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[event.target.name]:event.target.value})
        if(name == "signDate"){
            var nowDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}); 
            var date = nowDate;
            $('#signDate'+id).val(date);
            $('#signDate'+id).attr('readonly', true);
            this.setState({[event.target.name]:date})
        }
        if(name == 'signName' && value !==''){
            this.setState({errorName:''});
        }
    }
    handleSave=()=>{
        var nowDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        let id = this.props.rowAllData[8];
        let name =this.state.signName;
        let title =this.state.signTitle;
        let contId = this.state.signCont;
        let signDate = this.state.signDate ;
        let res = name+','+title+','+contId+','+nowDate;
        $('#signDate'+id).val(nowDate);
        $('#signDate'+id).attr('readonly', true);
        if(name !== ''){
            this.props.func(res,this.props.rowAllData[2],this.props.rowAllData[8]);
        }else{
            this.setState({errorName:'please enter your name'});
        }
    }
    render(){
        const {errorName} = this.state;
        const {kioskResponse,kioskPreview}=this.props;
        let id  = this.props.rowAllData[8];
        if(kioskResponse.size > 0){
            var multiRes = kioskResponse.get(id);
            if(multiRes !== undefined){
                var newArray = multiRes.split(',');
            }
        }
        return (
            <div className="form-horizontal" id={kioskPreview ? "presig"+id : "sig"+id} style={{display: newArray ? 'block':'none'}}>    
                <div className="form-group">
                    <label className="label-control">Name<span className="required">*</span></label>
                    <input type="text" name="signName" placeholder="enter your name" value={newArray !== undefined ? newArray[0] :this.state.signName} onChange={this.getvalue}/>
                    {errorName ? <p className="alert alert-danger"> { this.state.errorName }</p> : null}
                </div>
                <div className="form-group">
                    <label for="exampleFormControlInput1">Title</label>
                    <input type="text" name="signTitle" placeholder="enter your title" value={newArray !== undefined ? newArray[1]:this.state.signTitle} onChange={this.getvalue}/>
                </div>
                <div className="form-group">
                    <label for="exampleFormControlInput1">ID or Contact info</label>
                    <input type="text" name="signCont" placeholder="enter your id or contact info" value={newArray !== undefined ? newArray[2]:this.state.signCont} onChange={this.getvalue}/>
                </div>
                <div className="form-group">
                    <button onClick={this.handleSave}><i className="fa fa-save"></i>Save and Date</button>
                    <input type="text" name="signDate" id={"signDate"+id} value={newArray !== undefined ? newArray[3]+','+ newArray[4] :this.state.signDate} placeholder="fills date and time dd/mm/yyyy hhmmss" onClick={this.getvalue}/>
                </div>
            </div>
        );

    }
}

let offset = null, interval = null

class Timers extends Component {
  static get propTypes () {
    return {
      options: PropTypes.object
    }
  }

  constructor(props) {
    super(props)
    this.state = { clock: 0, time: '' }
    
  }

  componentWillUnmount() {
    this.pause()
  }

  pause() {
    if (interval) {
      clearInterval(interval)
      interval = null
    }
  }
  play() {
    const OPTIONS = { prefix: 'seconds elapsed!', delay: 100}
    if (!interval) {
      offset = Date.now()
      interval = setInterval(this.update.bind(this), OPTIONS.delay)
    }
  }

  reset() {
    let clockReset = 0
    this.setState({clock: clockReset })
    let time = SecondsTohhmmss(clockReset / 1000)
    this.setState({time: time })
  }
  stop(){
    if (interval) {
        this.props.func(this.state.time,this.props.rowAllData[2],this.props.rowAllData[8]);
        clearInterval(interval)
        interval = null
    }
  }
  update() {
    let clock = this.state.clock
    clock += this.calculateOffset()
    this.setState({clock: clock })
    let time = SecondsTohhmmss(clock / 1000)
    this.setState({time: time })
  }

  calculateOffset() {
    let now = Date.now()
    let newOffset = now - offset
    offset = now
    return newOffset
  }

  render() {
    const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        if(kioskResponse.size > 0){
            var multiRes = kioskResponse.get(id);
            if(multiRes !== undefined){
                var newArray = multiRes
            }
        }
    const timerStyle = {
      margin: "0px",
    };

    const buttonStyle = {
      background: "#fff",
      color: "#666",
      border: "1px solid #ddd",
      marginRight: "5px",
      padding: "10px",
      fontWeight: "200"
    };

    const secondsStyles = {
      fontSize: "200%",
      fontWeight: "200",
      lineHeight: "1.5",
      margin: "0",
      color: "#666"
    };

    return (
      <div style={timerStyle} className="react-timer">
        <h3 style={secondsStyles} className="seconds"> {this.state.time ? this.state.time : newArray} {this.props.prefix}</h3>
        <br />
        <button onClick={this.reset.bind(this)} style={buttonStyle} >reset</button>
        <button onClick={this.play.bind(this)} style={buttonStyle} >start</button>
        <button onClick={this.pause.bind(this)} style={buttonStyle} >pause</button>
        <button onClick={this.stop.bind(this)} style={buttonStyle} >stop/save</button>
      </div>
    )
  }
}

class Sliders extends Component {
    constructor(props){
        super(props)
        this.state = {
            x:1,
        }
        this.shoot = this.shoot.bind(this); 
    }
    shoot(x) {
        this.props.func(x,this.props.rowAllData[2],this.props.rowAllData[8]);
        this.setState({ x: parseFloat(x.toFixed(2))})
    }
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                var ts = resAns;
            }
        }
		console.log('kioskResponse->',kioskResponse);
        var options = this.props.rowAllData[9];
        if(options){
            var res = options.split(',');
        }
        const {x}=this.state;
        return (
            <div className="row">
                <table className="slider-table">
                  <tr>
                    <td>{ res !== undefined ? res[0]:null}</td>
                    <td className="slider-td">
					<div style={{width:x+'%'}} className="slider-x-value">{x}</div>
					<Slider
                    axis="x"
                    xstep={1}
                    xmin={0}
                    xmax={100}
                    x={ts ? ts : x}
                    onChange={({ x }) => this.shoot(x) }
                  /></td>
                    <td>{res !== undefined ? res[1]:null}</td>
                  </tr>
                </table>
          </div>
           
        );

    }
}

class Q10 extends Component {
    constructor(props) {
    super(props);
    this.state = {
        description:''
    }
    this.shoot = this.shoot.bind(this); 
    }
    
    shoot(event) {
        this.setState({description:event.target.value})
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    render(){
        const {kioskResponse, kioskPreview}=this.props;
        var id = this.props.rowAllData[8];
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                var ts = resAns;
            }
        }
        return (
            <div id={kioskPreview ? "preq10"+id : "q10"+id}  style={{display: ts ? 'block':'none'}}>
                 Description : <DebounceInput type="text" debounceTimeout={4000} className={'form-control res_'+id } value={ts ? ts : this.state.description} onChange={this.shoot}/>
            </div>
        );
    }

}

class Q11 extends Component {

    constructor(props) {
    super(props);
        this.state = { checkedItems: new Map(),itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const item = event.target.name;
        const isChecked = event.target.checked;
        const value =event.target.value;
        let checkedItems = this.state.checkedItems;
        let itemArray = this.state.itemArray
        let sh = {name:item, value:value, isChecked:isChecked};

        if(isChecked==true){
            itemArray.push(value)
            checkedItems.set(item, sh)
        }else{
            if(itemArray.indexOf(value) !==-1){
                itemArray = itemArray.filter(e => e !== value)
            }
            checkedItems.delete(item) 
        }
		let itemString = itemArray.toString();
		this.setState({checkedItems,itemArray});
		
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;
        const {checkedItems}= this.state;
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }

        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
            var name = 'action'+i;
            if(ts.indexOf(courthouse) !== -1){
                return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" checked='checked' value={courthouse}  name={name}/> {courthouse} 
                        </label>
                    </div>
                );  
            }else{
                return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" value={courthouse} name={name}/> {courthouse} 
                        </label>
                    </div>
                );
                }
            })
        }
        return (
            <div onChange={this.shoot}>
               {optionsHtml}
            </div>
         
        );
    }

}

class Q11B extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            checkedItems: new Map(),itemArray:[] 
        }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const item = event.target.name;
        const isChecked = event.target.checked;
        var id = this.props.rowAllData[8];
        const value =event.target.value;
        let checkedItems = this.state.checkedItems;
        let itemArray = this.state.itemArray
        itemArray.push(value)
        let itemString = itemArray.toString();
        let sh = {name:item, value:value, isChecked:isChecked};
        if(isChecked==true){
            $('#q11b'+item).css('display','block');
            checkedItems.set(item, sh)
        }else{
            $('#q11b'+item).css('display','none');
            checkedItems.delete(item) 
        }
        this.setState({checkedItems});
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;
        const {checkedItems}= this.state;
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }

        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
                if(courthouse ==' ' || courthouse ==''){
                    courthouse = 'Other(Please Describe)';
                }
                var name = 'action'+id+i;
				if(ts.indexOf(courthouse) !== -1){
					
					return (
						<div className="form-check mb-2" key={i}>
							<label className="form-check-label">
								<input type="checkbox" checked='checked' value={courthouse} name={name}/> {courthouse} 
							</label>
							<div id={"q11b"+name} style={{display:'none'}} >
								Description : <input text="type" className="form-control" />
							</div>
						</div>
					);
					
				}else{
					
					return (
						<div className="form-check mb-2" key={i}>
							<label className="form-check-label">
								<input type="checkbox" value={courthouse} name={name}/> {courthouse} 
							</label>
							<div id={"q11b"+name} style={{display:'none'}} >
								Description : <input text="type" className="form-control" />
							</div>
						</div>
					);
					
				}
                
            })
        }
        return (
            <div onChange={this.shoot}>
               {optionsHtml}
            </div>
         
        );
    }
}

class Q12 extends Component {
    
    render(){
      return(
        <div>
        </div>
      );
    }

}

class Q13 extends Component {
    
    render(){
      return(
        <div>
        </div>
      );
    }

}

class Q14 extends Component {
    
    render(){
      return(
        <div>
        </div>
      );
    }

}

class Video extends Component {
    
    render(){
      return(
        <div>
        </div>
      );
    }

}
 
class Image extends Component {

	constructor(props) {
		super(props);
		this.state = {
			images: [],
			imageUrls: [],
			message: ''
		}
	}

	selectImages = (event) => {
		let images = []
		for (var i = 0; i < event.target.files.length; i++) {
			images[i] = event.target.files.item(i);
		}
		images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
		let message = `${images.length} valid image(s) selected`
		this.setState({ images, message })
	}

	uploadImages = () => {

		const uploaders = this.state.images.map(image => {
			const data = new FormData();
			data.append("image", image, image.name); 
			// Make an AJAX upload request using Axios
			const url = '/public';
			//const formData = new FormData();
			//formData.append('file',file)
			const config = {
				headers: {
					"Access-Control-Allow-Origin": "*",
					'content-type': 'multipart/form-data'
				}
			}
			return  post(url, data,config)
				.then(response => {
					this.setState({
						imageUrls: [ response.data.imageUrl, ...this.state.imageUrls ]
					});
				})
			});
		 
			axios.all(uploaders).then(() => {
				alert('data uploaded successfully');
			}).catch(err => alert(err.message));
		}
	 
	render() {
    return (
    <div>
        <br/>
        <div className="col-sm-12">
            <div className="col-sm-4">
                <input className="form-control " type="file" onChange={this.selectImages} multiple/>
                <button className="btn btn-primary" value="Submit" onClick={this.uploadImages}>Submit</button>
            </div>
            <p className="text-info">{this.state.message}</p>
        </div>
        <div className="row col-lg-12">
            { this.state.imageUrls.map((url, i) => (
                <div className="col-lg-2" key={i}>
                    <img src={'/public' + url} className="img-rounded img-responsive" alt="not available"/><br/>
                </div>
            ))}
        </div>
    </div>
    )}
}

class Q15 extends Component {
    
    render(){
      return(
        <div>
        </div>
      );
    }

}

class Q16 extends Component {
    constructor(props) {
        super(props);
        this.myArray = [];
        this.state = {
            showdata : this.myArray,
            postVal : "",
            users: '',
            newoption:'',
        }
        this.shoot = this.shoot.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);  
    }
    
    shoot(event) {
        this.setState({postVal:event.target.value});
    }
    handleSubmit() {
        this.myArray.push(this.state.postVal);
        this.setState({postVal:''});
    }
  
    render() {
        var options = this.myArray;
        var optionsHtml = '';
        if(options.length !== 0 && options.length !== 1){
            var res = options.split(',')
        }else{
            optionsHtml = <option value={options}>{options}</option>   
        }
        if(res){
            optionsHtml = res.map(function(courthouse,i) {
                return (
                    <option value={courthouse} key={i}>{courthouse}</option>
                );
            })
        }
        return (
            <div className="abc">
                <div className="input-group mb-3">
                    <input type="text" className="form-control" id="select1sa" onChange={this.shoot}/>
                    <div className="input-group-append">
                    <button type="button" className="btn btn-primary input-group-text" onClick={this.handleSubmit}>
                        <span className="glyphicon glyphicon-plus" > Add </span>
                    </button>
                    </div>
                </div>
                <select className="form-control">
                <option value=""> Please Select</option>
                    {optionsHtml}
                </select>
            </div>
        );
    }
}

class Launchpad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempArray:[],
			tempId:1,
			tagId:1,
			selectPatient:'',
			tempData:'',
			preData:'',
			sub:'',
			tags:[],
			patient:[],
			patientData:[],
        }
        //this.shoot = this.shoot.bind(this);  
        this.handleTemp = this.handleTemp.bind(this);  
        this.handleTag = this.handleTag.bind(this);  
        this.handleAddData = this.handleAddData.bind(this);  
        this.handleAddTag = this.handleAddTag.bind(this);  
        this.handleSave = this.handleSave.bind(this);  
        this.handlePreview = this.handlePreview.bind(this);  
        this.handleSelectPatient = this.handleSelectPatient.bind(this);  
        this.handleTraslate = this.handleTraslate.bind(this);  
        this.getTemplate();  
        this.getTags();
    }
    
    /* shoot(event) {
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    } */
	
	handleTemp(event) {
        let tempId = event.target.value;
		this.setState({tempId});
    }
	
	handleTag(event) {
        let tagId = event.target.value;
		this.setState({tagId});
    }
	
	handleSelectPatient(event) {
        let selectPatient = event.target.value;
		selectPatient = selectPatient.replace(" ", "");
		this.setState({selectPatient});
    }
	
	getTemplate=()=>{
		
		let id = this.props.rowAllData[8];
		let res = id.split("-");
		let formType = res[0];
		
		let config1 = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = config.API_URL+'templates/get_templates.php?type='+formType;
        axios.get(url, config1)
        .then(response => {
            let tempArray = response.data;
            this.setState({tempArray});
        })
		
	}
	
    handleAddData() {
        let that = this;
		let id = this.props.rowAllData[8];
		this.state.tempArray.map(function(template,i) {
			if(template.id == that.state.tempId){
				$('#tempHtml_'+id).val(template.data);
				let preData = template.data;
				preData = preData.replaceAll("<<", "<span class='auto-tag'><<");
				preData = preData.replaceAll(">>", ">></span>");
				$('#tempHtmlPre_'+id).val(preData);
			}
		})
    }
	
	getTags=()=>{
		
		gapi.client.load("sheets", "v4", () => {
		gapi.client.sheets.spreadsheets.values
		  .get({
				spreadsheetId: config.spreadsheetId,
				range:'<<Tag>> Data and Templates', 
			})
			.then(
				response => {
					const tags = response.result.values;
					let patient = [];
					tags[0].map(function(val,i) {
						if(i > 1){
							patient[i] = val;
						}
						
					})
					let patientData = [];
					let strArr = [];
					patient.map(function(pat,i) {
						strArr = [];
						tags.map(function(val,j) {
							
							if(j > 0){
								strArr.push({
									 tag : val[1], 
									 value : val[i], 
								});
							}
						})
						pat = pat.replace(" ", "");
						patientData[pat] = strArr;
						
					})
					
					this.setState({ tags, patient, patientData});
				},
			);
		});
		
		
	}
	
	handleAddTag(type) {
        let that = this;
		let id = this.props.rowAllData[8];
		
		if(type == 'auto'){
			this.state.tags.map(function(tag,i) {
				if(tag[0] == that.state.tagId){
					let boxHtml = $('#tempHtml_'+id).val();
					$('#tempHtml_'+id).val(boxHtml +' '+tag[1]);
					let boxHtml2 = $('#tempHtmlPre_'+id).val();
					$('#tempHtmlPre_'+id).val(boxHtml2 +' <span class="auto-tag">'+tag[1]+'</span>');
				}
			})
		}else if(type == 'required'){
			let tag = that.state.tagId;
			if(tag){
				let boxHtml = $('#tempHtml_'+id).val();
				$('#tempHtml_'+id).val(boxHtml +' '+tag);
				let boxHtml2 = $('#tempHtmlPre_'+id).val();
				$('#tempHtmlPre_'+id).val(boxHtml2 +' <span class="req-tag">'+tag+'</span>');
				
			}
		}else if(type == 'non-required'){
			let tag = that.state.tagId;
			if(tag){
				let boxHtml = $('#tempHtml_'+id).val();
				$('#tempHtml_'+id).val(boxHtml +' '+tag);
				let boxHtml2 = $('#tempHtmlPre_'+id).val();
				$('#tempHtmlPre_'+id).val(boxHtml2 +' <span class="non-req-tag">'+tag+'</span>');
				
			}
		}
    }
	
	handleSave() {
		let id = this.props.rowAllData[8];
		let tempName = $('.temp-name-'+id).val();
		let res = id.split("-");
		let formType = res[0];
		let boxHtml = $('#tempHtml_'+id).val();
		if(tempName){
			let url = config.API_URL+'templates/save_templates.php';
			let con = {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'content-type': 'multipart/form-data'
				}
			}
			let form_data = new FormData();
			form_data.append('name', tempName);
			form_data.append('data', boxHtml);
			form_data.append('form_type', formType);
			post(url, form_data, con)
			.then(response => {
				alert('Template saved!');
			})
			
			this.setState({sub:boxHtml})
		}
		this.getTemplate();
		this.props.func(boxHtml,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
	
	handleTraslate() {
		let selectPatient = this.state.selectPatient;
		let kioskUserName = this.props.kioskUserName;
		let assoDeviceTypeName = this.props.assoDeviceTypeName;
		let assoVisitTypeName = this.props.assoVisitTypeName;
		let id = this.props.rowAllData[8];
		if (this.props.moduleName != 'test_forms'){
			alert('You can traslate the tags in phase 5.');
			return false;
		}
		let patientData = this.state.patientData;
		if(selectPatient){
			patientData = patientData[selectPatient];
			patientData.map(function(data,i) {
				let boxHtml = $('#tempHtml_'+id).val();
				let newData = '';
				let options = { timeZone: "America/New_York", year: 'numeric', month: 'long', day: 'numeric' };
				let today = new Date();
				
				switch (data.tag) {
					case '<<long date>>' :
						newData = boxHtml.replaceAll(data.tag, today.toLocaleDateString("en-US", options));
						break;
					case '<<short date>>' :
						newData = boxHtml.replaceAll(data.tag, today.toLocaleDateString("en-US", {timeZone: "America/New_York"}));
						break;
					case '<<time>>' :
						newData = boxHtml.replaceAll(data.tag, today.toLocaleTimeString("en-US", {timeZone: "America/New_York"}));
						break;
					case '<<date time>>' :
						newData = boxHtml.replaceAll(data.tag,today.toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',',''));
						break;
					case '<<current user full name>>' :
						newData = boxHtml.replaceAll(data.tag, kioskUserName);
						break;
					case '<<device type>>' :
						newData = boxHtml.replaceAll(data.tag, assoDeviceTypeName);
						break;
					case '<<visit type>>' :
						newData = boxHtml.replaceAll(data.tag, assoVisitTypeName);
						break;
					case '<<visit date>>' :
						newData = boxHtml.replaceAll(data.tag,today.toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',',''));
						break;	
					default:
					newData = boxHtml.replaceAll(data.tag, data.value);
				}
				
				$('#tempHtml_'+id).val(newData);
				
			})
			
		}
		let that = this;
		let kioskResponse = this.props.kioskResponse;
		let tagResponse = this.state.tagResponse;
		this.props.allData.map(function(tag,i) {
			if(tag[13]){
				let boxHtml2 = $('#tempHtml_'+id).val();
				let Value = kioskResponse.get(tag[0]);
				if(Value){
					let newData2 = boxHtml2.replaceAll(tag[13], Value);
					$('#tempHtml_'+id).val(newData2);
				}
			}
		})

	}
	
	handlePreview() {
		let id = this.props.rowAllData[8];
		let preData3 = $('#tempHtmlPre_'+id).val();
		preData3 = preData3.replaceAll('<<', '');
		preData3 = preData3.replaceAll('>>', '');
		$('#tagPreHtml_'+id).html(preData3);
	}
    render() {
        const {allData, kioskResponse, username, kioskUserName, moduleName, current_user}=this.props
		let user = this.props.current_user;
		let fId = 'All';
		if(typeof(user) != "undefined") {
			fId = user[3];
		}
		let fieldValue = [];
		let id = this.props.rowAllData[8];
		let optionsHtml = '';
		let tempHtml = '';
		let tagsHtml = '';
		let requiredTagsHtml = '';
		let nonRTagsHtml = '';
		let patientHtml = '';
		let patientOption = '';
		let that = this;
		
		allData.map(function(val,i) {
			if(id == val[0]){
				fieldValue = val;
			}
		});
		
		if(this.state.tempArray){
			
			optionsHtml = this.state.tempArray.map(function(template,i) {
                return (
                    <option value={template.id} key={i}>{template.name}</option>
                );
            })
			
			if(this.state.tempId !== undefined){
				tempHtml = this.state.tempArray.map(function(template,i) {
					if(template.id == that.state.tempId){
						return (
							<div>{template.data}</div>
						);
					}
				})
			}
			
		}
		if(this.state.tags){
			tagsHtml = this.state.tags.map(function(tag,i) {
				if(i > 0){
					return (
						<option value={tag[0]} key={i}>{tag[1]}</option>
					);
				}
            })
		}

		if(allData){
			requiredTagsHtml = allData.map(function(tag,i) {
				if(tag[14] == 'Y' && tag[15] == 'Y' && tag[13] && (tag[111] == 'Y' || fId == 'All' || fId == tag[115])){
					return (
						<option value={tag[13]} key={i}>{tag[13]}</option>
					);
				}
            })
			
			nonRTagsHtml = allData.map(function(tag,i) {
				
				if(tag[14] == 'Y' && tag[15] == 'N' && tag[13] && (tag[111] == 'Y' || fId == 'All' || fId == tag[115])){
					return (
						<option value={tag[13]} key={i}>{tag[13]}</option>
					);
				}
            })
		}
		
		if(this.state.patient){
			patientOption = this.state.patient.map(function(patt,i) {
				return (
					<option value={patt} key={i}>{patt}</option>
				);
            })
		}
		
        return (
            <div className="subjective">
				  
                <div className="row">
					<div className="col-md-5">
						<select className="form-control" onChange={this.handleTag}>
							<option value=""> Please Select</option> 
							{tagsHtml}							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick = {()=>this.handleAddTag('auto')}>Add Auto Tag</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-5">
						<select className="form-control" onChange={this.handleTag}>
							<option value=""> Please Select</option> 
							{requiredTagsHtml}							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick={()=>this.handleAddTag('required')}>Required Field Tags</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-10">
						<label>Enter text blow and Insert "Tag" by choosing from above drop down and click Add tag</label>
                        <textarea id={"tempHtml_"+id}className="form-control" rows="10">{ kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.sub }</textarea>
                        <textarea id={"tempHtmlPre_"+id}className="form-control previewTemp" rows="10">{ kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.sub }</textarea>
                    </div>
					
					<div className="col-md-5">
						<br />
                        <button className="btn btn-primary" data-target={"#tagTras_"+id} data-toggle="modal">Traslate Tags</button>&nbsp;&nbsp;
                        <button className="btn btn-primary" data-target={"#tagPreview_"+id} data-toggle="modal" onClick={this.handlePreview}>Preview</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-9">
						<button id={'post-'+fieldValue[117]} type="button" className="btn btn-primary" onClick={() =>this.launchpadApi(fieldValue[0],fieldValue[117],fieldValue[78],fieldValue[41])}>{fieldValue[40]}</button>
                    </div>
					
                </div>
				
				<div className="row">
                    <div className="modal" id={"subtemp_"+id}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
								{tempHtml}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button><button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleAddData}>Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
				<div className="row">
                    <div className="modal" id={"tagTras_"+id}>
                        <div className="modal-dialog modal-lg" style={{width:'360px'}}>
                            <div className="modal-content">
                                <div className="modal-body">
									<h5>Please select the Patient.</h5>
									<select className="form-control" onChange={this.handleSelectPatient}>
										<option value=""> Please Select</option> 
										{patientOption}							
									</select>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button><button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleTraslate}>Traslate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
				
				<div className="row">
                    <div className="modal" id={"tagPreview_"+id}>
                        <div className="modal-dialog modal-lg" style={{width:'660px'}}>
                            <div className="modal-content">
                                <div id={"tagPreHtml_"+id} className="modal-body">
								  
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Subjective extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempArray:[],
			tempId:1,
			tagId:1,
			selectPatient:'',
			tempData:'',
			preData:'',
			sub:'',
			tags:[],
			patient:[],
			patientData:[],
        }
        //this.shoot = this.shoot.bind(this);  
        this.handleTemp = this.handleTemp.bind(this);  
        this.handleTag = this.handleTag.bind(this);  
        this.handleAddData = this.handleAddData.bind(this);  
        this.handleAddTag = this.handleAddTag.bind(this);  
        this.handleSave = this.handleSave.bind(this);  
        this.handlePreview = this.handlePreview.bind(this);  
        this.handleSelectPatient = this.handleSelectPatient.bind(this);  
        this.handleTraslate = this.handleTraslate.bind(this);  
        this.getTemplate();  
        this.getTags();
    }
    
    /* shoot(event) {
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    } */
	
	handleTemp(event) {
        let tempId = event.target.value;
		this.setState({tempId});
    }
	
	handleTag(event) {
        let tagId = event.target.value;
		this.setState({tagId});
    }
	
	handleSelectPatient(event) {
        let selectPatient = event.target.value;
		selectPatient = selectPatient.replace(" ", "");
		this.setState({selectPatient});
    }
	
	getTemplate=()=>{
		
		let id = this.props.rowAllData[8];
		let res = id.split("-");
		let formType = res[0];
		
		let config1 = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = config.API_URL+'templates/get_templates.php?type='+formType;
        axios.get(url, config1)
        .then(response => {
            let tempArray = response.data;
            this.setState({tempArray});
        })
		
	}
	
    handleAddData() {
        let that = this;
		let id = this.props.rowAllData[8];
		this.state.tempArray.map(function(template,i) {
			if(template.id == that.state.tempId){
				$('#tempHtml_'+id).val(template.data);
				let preData = template.data;
				preData = preData.replaceAll("<<", "<span class='auto-tag'><<");
				preData = preData.replaceAll(">>", ">></span>");
				$('#tempHtmlPre_'+id).val(preData);
			}
		})
    }
	
	getTags=()=>{
		
		gapi.client.load("sheets", "v4", () => {
		gapi.client.sheets.spreadsheets.values
		  .get({
				spreadsheetId: config.spreadsheetId,
				range:'<<Tag>> Data and Templates', 
			})
			.then(
				response => {
					const tags = response.result.values;
					let patient = [];
					tags[0].map(function(val,i) {
						if(i > 1){
							patient[i] = val;
						}
						
					})
					let patientData = [];
					let strArr = [];
					patient.map(function(pat,i) {
						strArr = [];
						tags.map(function(val,j) {
							
							if(j > 0){
								strArr.push({
									 tag : val[1], 
									 value : val[i], 
								});
							}
						})
						pat = pat.replace(" ", "");
						patientData[pat] = strArr;
						
					})
					
					this.setState({ tags, patient, patientData});
				},
			);
		});
		
		
	}
	
	handleAddTag(type) {
        let that = this;
		let id = this.props.rowAllData[8];
		
		if(type == 'auto'){
			this.state.tags.map(function(tag,i) {
				if(tag[0] == that.state.tagId){
					let boxHtml = $('#tempHtml_'+id).val();
					$('#tempHtml_'+id).val(boxHtml +' '+tag[1]);
					let boxHtml2 = $('#tempHtmlPre_'+id).val();
					$('#tempHtmlPre_'+id).val(boxHtml2 +' <span class="auto-tag">'+tag[1]+'</span>');
				}
			})
		}else if(type == 'required'){
			let tag = that.state.tagId;
			if(tag){
				let boxHtml = $('#tempHtml_'+id).val();
				$('#tempHtml_'+id).val(boxHtml +' '+tag);
				let boxHtml2 = $('#tempHtmlPre_'+id).val();
				$('#tempHtmlPre_'+id).val(boxHtml2 +' <span class="req-tag">'+tag+'</span>');
				
			}
		}else if(type == 'non-required'){
			let tag = that.state.tagId;
			if(tag){
				let boxHtml = $('#tempHtml_'+id).val();
				$('#tempHtml_'+id).val(boxHtml +' '+tag);
				let boxHtml2 = $('#tempHtmlPre_'+id).val();
				$('#tempHtmlPre_'+id).val(boxHtml2 +' <span class="non-req-tag">'+tag+'</span>');
				
			}
		}
    }
	
	handleSave() {
		let id = this.props.rowAllData[8];
		let tempName = $('.temp-name-'+id).val();
		let res = id.split("-");
		let formType = res[0];
		let boxHtml = $('#tempHtml_'+id).val();
		if(tempName){
			let url = config.API_URL+'templates/save_templates.php';
			let con = {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'content-type': 'multipart/form-data'
				}
			}
			let form_data = new FormData();
			form_data.append('name', tempName);
			form_data.append('data', boxHtml);
			form_data.append('form_type', formType);
			post(url, form_data, con)
			.then(response => {
				alert('Template saved!');
			})
			
			this.setState({sub:boxHtml})
		}
		this.getTemplate();
		this.props.func(boxHtml,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
	
	handleTraslate() {
		let selectPatient = this.state.selectPatient;
		let kioskUserName = this.props.kioskUserName;
		let assoDeviceTypeName = this.props.assoDeviceTypeName;
		let assoVisitTypeName = this.props.assoVisitTypeName;
		let id = this.props.rowAllData[8];
		if (this.props.moduleName != 'test_forms'){
			alert('You can traslate the tags in phase 5.');
			return false;
		}
		let patientData = this.state.patientData;
		if(selectPatient){
			patientData = patientData[selectPatient];
			patientData.map(function(data,i) {
				let boxHtml = $('#tempHtml_'+id).val();
				let newData = '';
				let options = { timeZone: "America/New_York", year: 'numeric', month: 'long', day: 'numeric' };
				let today = new Date();
				
				switch (data.tag) {
					case '<<long date>>' :
						newData = boxHtml.replaceAll(data.tag, today.toLocaleDateString("en-US", options));
						break;
					case '<<short date>>' :
						newData = boxHtml.replaceAll(data.tag, today.toLocaleDateString("en-US", {timeZone: "America/New_York"}));
						break;
					case '<<time>>' :
						newData = boxHtml.replaceAll(data.tag, today.toLocaleTimeString("en-US", {timeZone: "America/New_York"}));
						break;
					case '<<date time>>' :
						newData = boxHtml.replaceAll(data.tag,today.toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',',''));
						break;
					case '<<current user full name>>' :
						newData = boxHtml.replaceAll(data.tag, kioskUserName);
						break;
					case '<<device type>>' :
						newData = boxHtml.replaceAll(data.tag, assoDeviceTypeName);
						break;
					case '<<visit type>>' :
						newData = boxHtml.replaceAll(data.tag, assoVisitTypeName);
						break;
					case '<<visit date>>' :
						newData = boxHtml.replaceAll(data.tag,today.toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',',''));
						break;	
					default:
					newData = boxHtml.replaceAll(data.tag, data.value);
				}
				
				$('#tempHtml_'+id).val(newData);
				
			})
			
		}
		let that = this;
		let kioskResponse = this.props.kioskResponse;
		let tagResponse = this.state.tagResponse;
		this.props.allData.map(function(tag,i) {
			if(tag[13]){
				let boxHtml2 = $('#tempHtml_'+id).val();
				let Value = kioskResponse.get(tag[0]);
				if(Value){
					let newData2 = boxHtml2.replaceAll(tag[13], Value);
					$('#tempHtml_'+id).val(newData2);
				}
			}
		})

	}
	
	handlePreview() {
		let id = this.props.rowAllData[8];
		let preData3 = $('#tempHtmlPre_'+id).val();
		preData3 = preData3.replaceAll('<<', '');
		preData3 = preData3.replaceAll('>>', '');
		$('#tagPreHtml_'+id).html(preData3);
	}
    render() {
        const {allData, kioskResponse, username, kioskUserName, moduleName, current_user}=this.props
		let user = this.props.current_user;
		let fId = 'All';
		if(typeof(user) != "undefined") {
			fId = user[3];
		}
		
		let id = this.props.rowAllData[8];
		let optionsHtml = '';
		let tempHtml = '';
		let tagsHtml = '';
		let requiredTagsHtml = '';
		let nonRTagsHtml = '';
		let patientHtml = '';
		let patientOption = '';
		let that = this;
		if(this.state.tempArray){
			
			optionsHtml = this.state.tempArray.map(function(template,i) {
                return (
                    <option value={template.id} key={i}>{template.name}</option>
                );
            })
			
			if(this.state.tempId !== undefined){
				tempHtml = this.state.tempArray.map(function(template,i) {
					if(template.id == that.state.tempId){
						return (
							<div>{template.data}</div>
						);
					}
				})
			}
			
		}
		if(this.state.tags){
			tagsHtml = this.state.tags.map(function(tag,i) {
				if(i > 0){
					return (
						<option value={tag[0]} key={i}>{tag[1]}</option>
					);
				}
            })
		}

		if(allData){
			requiredTagsHtml = allData.map(function(tag,i) {
				if(tag[14] == 'Y' && tag[15] == 'Y' && tag[13] && (tag[111] == 'Y' || fId == 'All' || fId == tag[115])){
					return (
						<option value={tag[13]} key={i}>{tag[13]}</option>
					);
				}
            })
			
			nonRTagsHtml = allData.map(function(tag,i) {
				
				if(tag[14] == 'Y' && tag[15] == 'N' && tag[13] && (tag[111] == 'Y' || fId == 'All' || fId == tag[115])){
					return (
						<option value={tag[13]} key={i}>{tag[13]}</option>
					);
				}
            })
		}
		
		if(this.state.patient){
			patientOption = this.state.patient.map(function(patt,i) {
				return (
					<option value={patt} key={i}>{patt}</option>
				);
            })
		}
		
        return (
            <div className="subjective">
				<div className="row">
					<div className="col-md-9">
						<select className="form-control"  onChange={this.handleTemp}>
							<option value=""> Please Select</option> 
							{optionsHtml}
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" data-target={"#subtemp_"+id} data-toggle="modal" >Preview</button>
                    </div>
                </div>    
                <div className="row">
					<div className="col-md-5">
						<select className="form-control" onChange={this.handleTag}>
							<option value=""> Please Select</option> 
							{tagsHtml}							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick = {()=>this.handleAddTag('auto')}>Add Auto Tag</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-5">
						<select className="form-control" onChange={this.handleTag}>
							<option value=""> Please Select</option> 
							{requiredTagsHtml}							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick={()=>this.handleAddTag('required')}>Required Field Tags</button>
                    </div>
                </div>
				
				
				<div className="row">
					<div className="col-md-5">
						<select className="form-control" onChange={this.handleTag}>
							<option value=""> Please Select</option> 
							{nonRTagsHtml}							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick={()=>this.handleAddTag('non-required')}>Non-Required Tags</button>
                    </div>
                </div>
				
				
				<div className="row">
					<div className="col-md-10">
						<label>Enter text blow and Insert "Tag" by choosing from above drop down and click Add tag</label>
                        <textarea id={"tempHtml_"+id}className="form-control" rows="10">{ kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.sub }</textarea>
                        <textarea id={"tempHtmlPre_"+id}className="form-control previewTemp" rows="10">{ kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.sub }</textarea>
                    </div>
					
					<div className="col-md-5">
						<br />
                        <button className="btn btn-primary" data-target={"#tagTras_"+id} data-toggle="modal">Traslate Tags</button>&nbsp;&nbsp;
                        <button className="btn btn-primary" data-target={"#tagPreview_"+id} data-toggle="modal" onClick={this.handlePreview}>Preview</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-9">
						<label>Enter name of template to save</label>
						<input type="text" className={"form-control temp-name-"+id} name="templateName"/>
                    </div>
					<div className="col-md-3 mt-4">
                        <button className="btn btn-primary" onClick={this.handleSave}>Save / add to list</button>
                    </div>
                </div>
				
				<div className="row">
                    <div className="modal" id={"subtemp_"+id}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
								{tempHtml}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button><button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleAddData}>Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
				<div className="row">
                    <div className="modal" id={"tagTras_"+id}>
                        <div className="modal-dialog modal-lg" style={{width:'360px'}}>
                            <div className="modal-content">
                                <div className="modal-body">
									<h5>Please select the Patient.</h5>
									<select className="form-control" onChange={this.handleSelectPatient}>
										<option value=""> Please Select</option> 
										{patientOption}							
									</select>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button><button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleTraslate}>Traslate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
				
				<div className="row">
                    <div className="modal" id={"tagPreview_"+id}>
                        <div className="modal-dialog modal-lg" style={{width:'660px'}}>
                            <div className="modal-content">
                                <div id={"tagPreHtml_"+id} className="modal-body">
								  
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Assessment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempArray:[],
			tempId:1,
			tagId:1,
			selectPatient:'',
			tempData:'',
			preData:'',
			sub:'',
			tags:[],
			patient:[],
			patientData:[],
        }
        //this.shoot = this.shoot.bind(this);  
        this.handleTemp = this.handleTemp.bind(this);  
        this.handleTag = this.handleTag.bind(this);  
        this.handleAddData = this.handleAddData.bind(this);  
        this.handleAddTag = this.handleAddTag.bind(this);  
        this.handleSave = this.handleSave.bind(this);  
        this.handlePreview = this.handlePreview.bind(this);  
        this.handleSelectPatient = this.handleSelectPatient.bind(this);  
        this.handleTraslate = this.handleTraslate.bind(this);  
        this.getTemplate();  
        this.getTags();
    }
    
    /* shoot(event) {
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    } */
	
	handleTemp(event) {
        let tempId = event.target.value;
		this.setState({tempId});
    }
	
	handleTag(event) {
        let tagId = event.target.value;
		this.setState({tagId});
    }
	
	handleSelectPatient(event) {
        let selectPatient = event.target.value;
		selectPatient = selectPatient.replace(" ", "");
		this.setState({selectPatient});
    }
	
	getTemplate=()=>{
		
		let id = this.props.rowAllData[8];
		let res = id.split("-");
		let formType = res[0];
		
		let config1 = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = config.API_URL+'templates/get_templates2.php?type='+formType;
        axios.get(url, config1)
        .then(response => {
            let tempArray = response.data;
            this.setState({tempArray});
        })
		
	}
	
    handleAddData() {
        let that = this;
		let id = this.props.rowAllData[8];
		this.state.tempArray.map(function(template,i) {
			if(template.id == that.state.tempId){
				$('#tempHtml_'+id).val(template.data);
				let preData = template.data;
				preData = preData.replaceAll("<<", "<span class='auto-tag'><<");
				preData = preData.replaceAll(">>", ">></span>");
				$('#tempHtmlPre_'+id).val(preData);
			}
		})
    }
	
	getTags=()=>{
		
		gapi.client.load("sheets", "v4", () => {
		gapi.client.sheets.spreadsheets.values
		  .get({
				spreadsheetId: config.spreadsheetId,
				range:'<<Tag>> Data and Templates', 
			})
			.then(
				response => {
					const tags = response.result.values;
					let patient = [];
					tags[0].map(function(val,i) {
						if(i > 1){
							patient[i] = val;
						}
						
					})
					let patientData = [];
					let strArr = [];
					patient.map(function(pat,i) {
						strArr = [];
						tags.map(function(val,j) {
							
							if(j > 0){
								strArr.push({
									 tag : val[1], 
									 value : val[i], 
								});
							}
						})
						pat = pat.replace(" ", "");
						patientData[pat] = strArr;
						
					})
					
					this.setState({ tags, patient, patientData});
				},
			);
		});
		
		
	}
	
	handleAddTag(type) {
        let that = this;
		let id = this.props.rowAllData[8];
		
		if(type == 'auto'){
			this.state.tags.map(function(tag,i) {
				if(tag[0] == that.state.tagId){
					let boxHtml = $('#tempHtml_'+id).val();
					$('#tempHtml_'+id).val(boxHtml +' '+tag[1]);
					let boxHtml2 = $('#tempHtmlPre_'+id).val();
					$('#tempHtmlPre_'+id).val(boxHtml2 +' <span class="auto-tag">'+tag[1]+'</span>');
				}
			})
		}else if(type == 'required'){
			let tag = that.state.tagId;
			if(tag){
				let boxHtml = $('#tempHtml_'+id).val();
				$('#tempHtml_'+id).val(boxHtml +' '+tag);
				let boxHtml2 = $('#tempHtmlPre_'+id).val();
				$('#tempHtmlPre_'+id).val(boxHtml2 +' <span class="req-tag">'+tag+'</span>');
				
			}
		}else if(type == 'non-required'){
			let tag = that.state.tagId;
			if(tag){
				let boxHtml = $('#tempHtml_'+id).val();
				$('#tempHtml_'+id).val(boxHtml +' '+tag);
				let boxHtml2 = $('#tempHtmlPre_'+id).val();
				$('#tempHtmlPre_'+id).val(boxHtml2 +' <span class="non-req-tag">'+tag+'</span>');
				
			}
		}
    }
	
	handleSave() {
		let id = this.props.rowAllData[8];
		let tempName = $('.temp-name-'+id).val();
		let res = id.split("-");
		let formType = res[0];
		let boxHtml = $('#tempHtml_'+id).val();
		if(tempName){
			let url = config.API_URL+'templates/save_templates2.php';
			let con = {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'content-type': 'multipart/form-data'
				}
			}
			let form_data = new FormData();
			form_data.append('name', tempName);
			form_data.append('data', boxHtml);
			form_data.append('form_type', formType);
			post(url, form_data, con)
			.then(response => {
				alert('Template saved!');
			})
			
			this.setState({sub:boxHtml})
		}
		this.getTemplate();
		this.props.func(boxHtml,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
	
	handleTraslate() {
		let selectPatient = this.state.selectPatient;
		let kioskUserName = this.props.kioskUserName;
		let assoDeviceTypeName = this.props.assoDeviceTypeName;
		let assoVisitTypeName = this.props.assoVisitTypeName;
		let id = this.props.rowAllData[8];
		if (this.props.moduleName != 'test_forms'){
			alert('You can traslate the tags in phase 5.');
			return false;
		}
		let patientData = this.state.patientData;
		if(selectPatient){
			patientData = patientData[selectPatient];
			patientData.map(function(data,i) {
				let boxHtml = $('#tempHtml_'+id).val();
				let newData = '';
				let options = { timeZone: "America/New_York", year: 'numeric', month: 'long', day: 'numeric' };
				let today = new Date();
				
				switch (data.tag) {
					case '<<long date>>' :
						newData = boxHtml.replaceAll(data.tag, today.toLocaleDateString("en-US", options));
						break;
					case '<<short date>>' :
						newData = boxHtml.replaceAll(data.tag, today.toLocaleDateString("en-US", {timeZone: "America/New_York"}));
						break;
					case '<<time>>' :
						newData = boxHtml.replaceAll(data.tag, today.toLocaleTimeString("en-US", {timeZone: "America/New_York"}));
						break;
					case '<<date time>>' :
						newData = boxHtml.replaceAll(data.tag,today.toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',',''));
						break;
					case '<<current user full name>>' :
						newData = boxHtml.replaceAll(data.tag, kioskUserName);
						break;
					case '<<device type>>' :
						newData = boxHtml.replaceAll(data.tag, assoDeviceTypeName);
						break;
					case '<<visit type>>' :
						newData = boxHtml.replaceAll(data.tag, assoVisitTypeName);
						break;
					case '<<visit date>>' :
						newData = boxHtml.replaceAll(data.tag,today.toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',',''));
						break;	
					default:
					newData = boxHtml.replaceAll(data.tag, data.value);
				}
				
				$('#tempHtml_'+id).val(newData);
				
			})
			
		}
		let that = this;
		let kioskResponse = this.props.kioskResponse;
		let tagResponse = this.state.tagResponse;
		this.props.allData.map(function(tag,i) {
			if(tag[13]){
				let boxHtml2 = $('#tempHtml_'+id).val();
				let Value = kioskResponse.get(tag[0]);
				if(Value){
					let newData2 = boxHtml2.replaceAll(tag[13], Value);
					$('#tempHtml_'+id).val(newData2);
				}
			}
		})

	}
	
	handlePreview() {
		let id = this.props.rowAllData[8];
		let preData3 = $('#tempHtmlPre_'+id).val();
		preData3 = preData3.replaceAll('<<', '');
		preData3 = preData3.replaceAll('>>', '');
		$('#tagPreHtml_'+id).html(preData3);
	}
    render() {
        const {allData, kioskResponse, username, kioskUserName, moduleName, current_user}=this.props
		let user = this.props.current_user;
		let fId = 'All';
		if(typeof(user) != "undefined") {
			fId = user[3];
		}
		
		let id = this.props.rowAllData[8];
		let optionsHtml = '';
		let tempHtml = '';
		let tagsHtml = '';
		let requiredTagsHtml = '';
		let nonRTagsHtml = '';
		let patientHtml = '';
		let patientOption = '';
		let that = this;
		if(this.state.tempArray){
			
			optionsHtml = this.state.tempArray.map(function(template,i) {
                return (
                    <option value={template.id} key={i}>{template.name}</option>
                );
            })
			
			if(this.state.tempId !== undefined){
				tempHtml = this.state.tempArray.map(function(template,i) {
					if(template.id == that.state.tempId){
						return (
							<div>{template.data}</div>
						);
					}
				})
			}
			
		}
		if(this.state.tags){
			tagsHtml = this.state.tags.map(function(tag,i) {
				if(i > 0){
					return (
						<option value={tag[0]} key={i}>{tag[1]}</option>
					);
				}
            })
		}

		if(allData){
			requiredTagsHtml = allData.map(function(tag,i) {
				
				if(tag[14] == 'Y' && tag[15] == 'Y' && tag[13] && (tag[111] == 'Y' || fId == 'All' || fId == tag[115])){
					return (
						<option value={tag[13]} key={i}>{tag[13]}</option>
					);
				}
            })
			
			nonRTagsHtml = allData.map(function(tag,i) {
				
				if(tag[14] == 'Y' && tag[15] == 'N' && tag[13] && (tag[111] == 'Y' || fId == 'All' || fId == tag[115])){
					return (
						<option value={tag[13]} key={i}>{tag[13]}</option>
					);
				}
            })
		}
		
		if(this.state.patient){
			patientOption = this.state.patient.map(function(patt,i) {
				return (
					<option value={patt} key={i}>{patt}</option>
				);
            })
		}
		
        return (
            <div className="subjective">
				<div className="row">
					<div className="col-md-9">
						<select className="form-control"  onChange={this.handleTemp}>
							<option value=""> Please Select</option> 
							{optionsHtml}
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" data-target={"#subtemp_"+id} data-toggle="modal" >Preview</button>
                    </div>
                </div>    
                <div className="row">
					<div className="col-md-5">
						<select className="form-control" onChange={this.handleTag}>
							<option value=""> Please Select</option> 
							{tagsHtml}							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick = {()=>this.handleAddTag('auto')}>Add Auto Tag</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-5">
						<select className="form-control" onChange={this.handleTag}>
							<option value=""> Please Select</option> 
							{requiredTagsHtml}							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick={()=>this.handleAddTag('required')}>Required Field Tags</button>
                    </div>
                </div>
				
				
				<div className="row">
					<div className="col-md-5">
						<select className="form-control" onChange={this.handleTag}>
							<option value=""> Please Select</option> 
							{nonRTagsHtml}							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick={()=>this.handleAddTag('non-required')}>Non-Required Tags</button>
                    </div>
                </div>
				
				
				<div className="row">
					<div className="col-md-10">
						<label>Enter text blow and Insert "Tag" by choosing from above drop down and click Add tag</label>
                        <textarea id={"tempHtml_"+id}className="form-control" rows="10">{ kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.sub }</textarea>
                        <textarea id={"tempHtmlPre_"+id}className="form-control previewTemp" rows="10">{ kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.sub }</textarea>
                    </div>
					
					<div className="col-md-5">
						<br />
                        <button className="btn btn-primary" data-target={"#tagTras_"+id} data-toggle="modal">Traslate Tags</button>&nbsp;&nbsp;
                        <button className="btn btn-primary" data-target={"#tagPreview_"+id} data-toggle="modal" onClick={this.handlePreview}>Preview</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-9">
						<label>Enter name of template to save</label>
						<input type="text" className={"form-control temp-name-"+id} name="templateName"/>
                    </div>
					<div className="col-md-3 mt-4">
                        <button className="btn btn-primary" onClick={this.handleSave}>Save / add to list</button>
                    </div>
                </div>
				
				<div className="row">
                    <div className="modal" id={"subtemp_"+id}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
								{tempHtml}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button><button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleAddData}>Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
				<div className="row">
                    <div className="modal" id={"tagTras_"+id}>
                        <div className="modal-dialog modal-lg" style={{width:'360px'}}>
                            <div className="modal-content">
                                <div className="modal-body">
									<h5>Please select the Patient.</h5>
									<select className="form-control" onChange={this.handleSelectPatient}>
										<option value=""> Please Select</option> 
										{patientOption}							
									</select>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button><button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleTraslate}>Traslate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
				
				<div className="row">
                    <div className="modal" id={"tagPreview_"+id}>
                        <div className="modal-dialog modal-lg" style={{width:'660px'}}>
                            <div className="modal-content">
                                <div id={"tagPreHtml_"+id} className="modal-body">
								  
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Grid extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        let name = event.target.name;
		let id = this.props.rowAllData[8];
		name = name.replace(id,'');
        let value = event.target.value;
		let itemArray = this.state.itemArray
		itemArray[name] = name+':'+value;
		let newItemArray = []
		Object.keys(itemArray).map(function(key) {
           newItemArray.push(itemArray[key]);
        });
        let itemString = newItemArray.toString();
		
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;

        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		//console.log('kioskResponse->',kioskResponse);
        var options = this.props.rowAllData[9];
		
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');
			
			
        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				return (	
				<div className="col-md-2">
				{headerText}
				</div>
					
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				var name = 'action'+i;
				return (	
					<div className="row">
						<div className="col-md-2">
						{rowText}
						</div>
						{
							headers.map(function(headerText,i) {
								if(ts.indexOf(rowText+':'+headerText) !== -1){
									return(	
									<div className="col-md-2">
										<input type="radio" value={headerText}  name={id+''+rowText} checked='checked' />
									</div>);
								}else{
									return(	
									<div className="col-md-2">
										<input type="radio" value={headerText}  name={id+''+rowText}/>
									</div>);
								}
							})
							
						}
					</div>
				);
            }) 
        }
		
        return (
            <div onChange={this.shoot}>
				<div className="row">
					<div className="col-md-2"></div>
				    {headerHtml}
				</div>
               {optionsHtml}
            </div>
         
        );
    }

}

class BasicGrid extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const name = event.target.name;
        const value = event.target.value;
		
		if(value){
			let isValid = this.validationValue(name, value);
			if(!isValid){
				return false;
			}
		}
		
		let itemArray = this.state.itemArray
		itemArray[name] = name+':'+value;
		let newItemArray = []
		Object.keys(itemArray).map(function(key) {
           newItemArray.push(itemArray[key]);
        });
		
		let itemString = newItemArray.toString();
		this.setState({itemArray});
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
	validationValue(name,value) {
		let id = this.props.rowAllData[8];
		let nameStr = name.split('-');
		let allData = this.props.allData;
		let valType = 'V1';
		let vmin = '';
		let vmax = '';
		allData.map(function(data,i) {
			if(data[0] == id){
				let str = data[48];
				if (str.indexOf(',') > -1) {
					str = str.split(',');
					str.map(function(tp,i) {
						str = tp.split(':');
						if(str[0] == nameStr[1]){
							valType = str[1];
							vmin = str[2];
							vmax = str[3];
						}
					})
					
				}else{
					str = str.split(':');
					if(str[0] == nameStr[1]){
						valType = str[1];
						vmin = str[2];
						vmax = str[3];
					}
				}
			}
		})
		
		let ex = '';
		let isValid = true;
		if(valType){
			switch (valType.toLowerCase()) {
				case 'v1' :
					ex = /^[ A-Za-z0-9_@./&+-,.]*$/;
					if(ex.test(value)==true){
						isValid = true;
						$('.'+name+'-'+id).removeClass('error');
					}else{
						isValid = false;
						let val = $('.'+name+'-'+id).val().slice(0,-1);
						$('.'+name+'-'+id).val(val);
						$('.'+name+'-'+id).addClass('error');
						alert('No special characters allowed, only alphanumeric.');
					}
					break;
				case 'v2' :
					ex = /^[ A-Za-z_,. ]*$/;
					if(ex.test(value)==true){
						isValid = true;
						$('.'+name+'-'+id).removeClass('error');
					}else{
						isValid = false;
						let val = $('.'+name+'-'+id).val().slice(0,-1);
						$('.'+name+'-'+id).val(val);
						$('.'+name+'-'+id).addClass('error');
						alert('No special characters and number allowed, only text.');
					}
					break;
				case 'v3' :
					ex = /^[0-9+()]*$/;
					if(ex.test(value)==true){
						isValid = true;
						$('.'+name+'-'+id).removeClass('error');
						if(vmin && vmax && (Number(value) < Number(vmin) || Number(value) > Number(vmax))){
							isValid = false;
							$('.'+name+'-'+id).val('');
							$('.'+name+'-'+id).addClass('error');
							alert('The value should be greater than '+vmin+' and less than '+vmax+'.');
						}
						
					}else{
						isValid = false;
						$('.'+name+'-'+id).val('');
						$('.'+name+'-'+id).addClass('error');
						alert('The value should be number and no negatives & no decimals.');
					}
					break;
				case 'v4' :
					ex = /^[0-9+()]*$/;
					if(ex.test(value)==true && value > 0){
						isValid = true;
						$('.'+name+'-'+id).removeClass('error');
						if(vmin && vmax && (Number(value) < Number(vmin) || Number(value) > Number(vmax))){
							isValid = false;
							$('.'+name+'-'+id).val('');
							$('.'+name+'-'+id).addClass('error');
							alert('The value should be greater than '+vmin+' and less than '+vmax+'.');
						}
						
					}else{
						isValid = false;
						$('.'+name+'-'+id).val('');
						$('.'+name+'-'+id).addClass('error');
						alert('The value should be number and no Zero & no decimals.');
					}
					break;
				case 'v5' :
					ex = /^\d{0,11}(\.\d{0,1})?$/;
					if(ex.test(Number(value))==true){
						isValid = true;
						$('.'+name+'-'+id).removeClass('error');
						if(vmin && vmax && (Number(value) < Number(vmin) || Number(value) > Number(vmax))){
							isValid = false;
							$('.'+name+'-'+id).val('');
							$('.'+name+'-'+id).addClass('error');
							alert('The value should be greater than '+vmin+' and less than '+vmax+'.');
						}
					}else{
						isValid = false;
						$('.'+name+'-'+id).val('');
						$('.'+name+'-'+id).addClass('error');
						alert('The value should be only 1 decimal place.');
					}
					break;
				case 'v6' :
					ex = /^\d{0,9}(\.\d{0,2})?$/;
					if(ex.test(Number(value))==true){
						isValid = true;
						$('.'+name+'-'+id).removeClass('error');
						if(vmin && vmax && Number(value) < Number(vmin) && Number(value) > Number(vmax)){
							isValid = false;
							$('.'+name+'-'+id).val('');
							$('.'+name+'-'+id).addClass('error');
							alert('The value should be greater than '+vmin+' and less than '+vmax+'.');
						}
					}else{
						isValid = false;
						$('.'+name+'-'+id).val('');
						$('.'+name+'-'+id).addClass('error');
						alert('The value should be only 2 decimal places.');
					}
					break;
				default:
				isValid = true;
			}
		}
		return isValid;
	}

    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
		var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
			if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		//console.log('kioskResponse->',kioskResponse);
		var options = this.props.rowAllData[9];
        var title = this.props.rowAllData[3];
		
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');

        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				//headerText = headerText.split(':');
				return (	
				<th>
				{headerText}
				</th>	
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				let r = i+1;
				return (	
					<tr>
						<td>
						{rowText}
						</td>
						{
							headers.map(function(headerText,j) {
								let c = j+1;
								//let headerStr = headerText.split(':');
								let response = '';
								if(ts){
									ts.map(function(resStr,n) {
										let rowCol = resStr.split(':');
										if(rowCol[0] == 'R'+r+'-C'+c){
											response = rowCol[1];
										}
									})
								}
								
								if(response){
									return(	
									<td>
										<input type="text" className={'R'+r+'-C'+c+'-'+id} value={response}  name={'R'+r+'-C'+c} maxLength={25} />
									</td>);
								}else{
									return(	
									<td>
										<input type="text" className={'R'+r+'-C'+c+'-'+id} name={'R'+r+'-C'+c} maxLength={25} />
									</td>);
								}
							})
							
						}
					</tr>
				);
            }) 
        }
		
        return (
            <table className="basic-grid table-bordered" onChange={this.shoot}>
				<tr>
					<th className="grid-table-th">Field names defined by SOOGAP</th>
				    {headerHtml}
				</tr>
               {optionsHtml}
            </table>
         
        );
    }

}

class GridMultiCheck extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const name = event.target.name;
        const value = event.target.value;
		const isChecked = event.target.checked;
		let itemArray = this.state.itemArray
		if(isChecked==true){
			itemArray[name] = name+':TRUE';
		}else{
			itemArray[name] = '';
		}
		let newItemArray = []
		Object.keys(itemArray).map(function(key) {
			if(itemArray[key] != ''){
				newItemArray.push(itemArray[key]);
			}
        });
		let itemString = newItemArray.toString();
		this.setState({itemArray});
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
		var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
			if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		//console.log('kioskResponse->',kioskResponse);
		var options = this.props.rowAllData[9];
        var title = this.props.rowAllData[3];
		
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');

        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				//headerText = headerText.split(':');
				return (	
				<th>
				{headerText}
				</th>	
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				let r = i+1;
				return (	
					<tr>
						<td>
						{rowText}
						</td>
						{
							headers.map(function(headerText,j) {
								let c = j+1;
								//let headerStr = headerText.split(':');
								let response = '';
								if(ts){
									ts.map(function(resStr,n) {
										let rowCol = resStr.split(':');
										if(rowCol[0] == 'R'+r+'-C'+c){
											response = rowCol[1];
										}
									})
								}
								
								if(response){
									return(	
									<td>
										<input type="checkbox" className={'R'+r+'-C'+c+'-'+id} value={response} name={'R'+r+'-C'+c} checked='checked'/>
									</td>);
								}else{
									return(	
									<td>
										<input type="checkbox" className={'R'+r+'-C'+c+'-'+id} name={'R'+r+'-C'+c} />
									</td>);
								}
							})
							
						}
					</tr>
				);
            }) 
        }
		
        return (
            <table className="basic-grid table-bordered" onChange={this.shoot}>
				<tr>
					<th className="grid-table-th">Question</th>
				    {headerHtml}
				</tr>
               {optionsHtml}
            </table>
         
        );
    }

}

class GridSingleCheck extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        let name = event.target.name;
		let id = this.props.rowAllData[8];
		name = name.replace(id,'');
        let value = event.target.value;
		let itemArray = this.state.itemArray
		itemArray[name] = name+':'+value;
		let newItemArray = []
		Object.keys(itemArray).map(function(key) {
           newItemArray.push(itemArray[key]);
        });
        let itemString = newItemArray.toString();
		
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;

        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		//console.log('kioskResponse->',kioskResponse);
        var options = this.props.rowAllData[9];
		
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');
			
			
        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				return (	
				<th>
				{headerText}
				</th>
					
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				//var name = 'action'+i;
				return (	
					<tr>
						<td>
						{rowText}
						</td>
						{
							headers.map(function(headerText,i) {
								if(ts.indexOf(rowText+':'+headerText) !== -1){
									return(	
									<td>
										<input type="radio" value={headerText}  name={id+''+rowText} checked='checked' />
									</td>);
								}else{
									return(	
									<td>
										<input type="radio" value={headerText}  name={id+''+rowText}/>
									</td>);
								}
							})
							
						}
					</tr>
				);
            }) 
        }
		
        return (
            <table className="basic-grid table-bordered" onChange={this.shoot}>
				<tr>
					<th className="grid-table-th">Question</th>
				    {headerHtml}
				</tr>
               {optionsHtml}
            </table>
         
        );
    }

}

class GridDropdown extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const name = event.target.name;
        const value = event.target.value;
		
		let itemArray = this.state.itemArray
		
		if(value){
			itemArray[name] = name+':'+value;
		}else{
			itemArray[name] = '';
		}
		let newItemArray = []
		Object.keys(itemArray).map(function(key) {
           newItemArray.push(itemArray[key]);
        });
		
		newItemArray = newItemArray.filter(function(x) {
			 return x !== '';
		});
		let itemString = newItemArray.toString();
		this.setState({itemArray});
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }

    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
		var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
			if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		//console.log('rowAllData->',this.props.rowAllData);
		var options = this.props.rowAllData[9];
        var title = this.props.rowAllData[3];
		
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split('|');

        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				//headerText = headerText.split(':');
				return (	
				<th>
				{headerText}
				</th>
					
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				let r = i+1;
				let row = rowText.split(':');
				
				let col1Html = '';
				let col2Html = '';
				
				if(row[1] !== undefined){
					let options = row[1].split(',');
							
					col1Html = options.map(function(opt,i) {
						return (
							<option value={opt} key={i}>{opt}</option>
						);
					})
				}
				
				if(row[2] !== undefined){
					let options = row[2].split(',');
							
					col2Html = options.map(function(opt,i) {
						return (
							<option value={opt} key={i}>{opt}</option>
						);
					})
				}
				
				let response1 = '';
				let response2 = '';
				if(ts){
					ts.map(function(resStr,n) {
						let rowCol = resStr.split(':');
						if(rowCol[0] == 'R'+r+'-C2' && rowCol[1]){
							response1 = rowCol[1];
						}
						if(rowCol[0] == 'R'+r+'-C3' && rowCol[1]){
							response2 = rowCol[1];
						}
					})
				}
				
				return (	
					<tr>
						<td>
						{row[0]}
						</td>
						{col1Html ?
						<td>
							<select className="form-control" value={response1} name={'R'+r+'-C2'}>
								<option value=""> Please Select</option> 
								{col1Html}							
							</select>
						
						</td>
						:null}
						{col2Html ?
						<td>
							<select className="form-control" value={response2} name={'R'+r+'-C3'}>
								<option value=""> Please Select</option> 
								{col2Html}							
							</select>
						</td>
						:null}
						
					</tr>
				);
            }) 
        }
		
        return (
            <table className="basic-grid table-bordered" onChange={this.shoot}>
				<tr>
					{headerHtml}
				</tr>
               {optionsHtml}
            </table>
         
        );
    }

}

class GridCheck extends Component {

    constructor(props) {
    super(props);
        this.state = { checkedItems: new Map(),itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const item = event.target.name;
        const isChecked = event.target.checked;
        const value =event.target.value;
        let checkedItems = this.state.checkedItems;
        let itemArray = this.state.itemArray
        let sh = {name:item, value:value, isChecked:isChecked};

        if(isChecked==true){
            itemArray.push(value)
            checkedItems.set(item, sh)
        }else{
            if(itemArray.indexOf(value) !==-1){
                itemArray = itemArray.filter(e => e !== value)
            }
            checkedItems.delete(item) 
        }
        let itemString = itemArray.toString();
        this.setState({itemArray,checkedItems});
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;
        const {checkedItems}= this.state;
        var id = this.props.rowAllData[8];
		let numberColumn = 2;
		//console.log('kioskResponse',kioskResponse);
		this.props.allData.map(function(data,i) {
			if(data[0] == id){
				numberColumn = data[136] ? data[136] : 2;
			}
		})
		
		let col = 6;
		
		if(numberColumn == 3){
			col = 4
		}else if(numberColumn == 4){
			col = 3
		}
		
		 var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }

        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
            var name = 'action'+i;
            if(ts.indexOf(courthouse) !== -1){
                return (
                    <div className={"border form-check col-md-"+col} key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" checked='checked' value={courthouse}  name={name}/> {courthouse} 
                        </label>
                    </div>
                );  
            }else{
                return (
                    <div className={"border form-check col-md-"+col} key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" value={courthouse} name={name}/> {courthouse} 
                        </label>
                    </div>
                );
                }
            })
        }
        return (
            <div className="row grid-check" onChange={this.shoot}>
               {optionsHtml}
            </div>
         
        );
    }

}

class Likert extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        let name = event.target.name;
		let id = this.props.rowAllData[8];
		name = name.replace(id,'');
        let value = event.target.value;
		let itemArray = this.state.itemArray
		itemArray[name] = name+':'+value;
		let newItemArray = []
		Object.keys(itemArray).map(function(key) {
           newItemArray.push(itemArray[key]);
        });
        let itemString = newItemArray.toString();
		
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;
		//console.log('rowAllData->',this.props.rowAllData);
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		//console.log('kioskResponse->',kioskResponse);
		var options = this.props.rowAllData[9];
		let header1 = '';
		let header2 = '';
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');
			header1 = headers[0];
			header2 = headers[1];
			
        }

        if(rows){
			
			let point = [1,2,3,4,5,6,7,8,9,10];
			
            var optionsHtml = rows.map(function(rowText,i) {
				//var name = 'action'+i;
				return (	
					<div className="row">
						<div className="col-md-2">
						{rowText}
						</div>
						{
							 point.map(function(val,i) {
								if(ts.indexOf(rowText+':'+val) !== -1){
									return(	
									<div className="col-md-1">
										<input type="radio" value={val}  name={id+''+rowText} checked='checked' />
									</div>);
								}else{
									return(	
									<div className="col-md-1">
										<input type="radio" value={val}  name={id+''+rowText}/>
									</div>);
								}
							})
						}
					</div>
				);
            }) 
        }
		
        return (
            <div onChange={this.shoot}>
				<div className="row">
					<div className="col-md-2"></div>
					<div className="col-md-3">{header1}</div>
					<div className="col-md-4"></div>
					<div className="col-md-3" style={{ textAlign:'right' }}>{header2}</div> 
				</div>
               {optionsHtml}
            </div>
         
        );
    }

}

class Smart extends Component {

    constructor(props) {
    super(props);
        this.state = {itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
		
        const item = event.target.name;
        const isChecked = event.target.checked;
        const value =event.target.value;
        let itemArray = this.state.itemArray
        let fieldId = this.props.rowAllData[8];
		
		if(isChecked==true && value == 'None of the Above'){
			itemArray = [];
			this.setState({itemArray});
			$('.smart-'+fieldId).prop('checked', false);
			$('.smart-'+fieldId).prop('disabled', true);
		}else{
			$('.smart-'+fieldId).prop('disabled', false);
		}
		
        if(isChecked==true){
            itemArray.push(value);
        }else{
            if(itemArray.indexOf(value) !==-1){
                itemArray = itemArray.filter(e => e !== value)
            }
        }
		
		if(isChecked==true && value != 'None of the Above'){
			itemArray = $.grep(itemArray, function(value) {
			  return value != 'None of the Above';
			});
		}
		
		let itemString = itemArray.toString();
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
    render(){
        const {kioskResponse}=this.props;
		var id = this.props.rowAllData[8];
		//console.log('kioskResponse->',kioskResponse);
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }
		
		//setTimeout(function(){
			if(ts.indexOf('None of the Above') !== -1){
				$('.smart-'+id).prop('disabled', true);
			}
		//}, 1000);
		
		

        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
            var name = 'action'+i;
			
            if(ts.indexOf(courthouse) !== -1){
				return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input className={ courthouse != 'None of the Above' ? "smart-"+id : ''} type="checkbox" checked='checked' value={courthouse}  name={name}/> {courthouse} 
                        </label>
                    </div>
                );  
            }else{
                return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input className={ courthouse != 'None of the Above' ? "smart-"+id : ''} type="checkbox" value={courthouse} name={name}/> {courthouse} 
                        </label>
                    </div>
                );
                }
            })
        }
        return (
            <div onChange={this.shoot}>
               {optionsHtml}
            </div>
         
        );
    }

}

class Weighted extends Component {

    constructor(props) {
    super(props);
        this.state = { checkedItems: new Map(),itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const item = event.target.name;
        const isChecked = event.target.checked;
        const value =event.target.value;
        //let checkedItems = this.state.checkedItems;
        let itemArray = this.state.itemArray
        let sh = {name:item, value:value, isChecked:isChecked};

        if(isChecked==true){
            itemArray.push(value)
            //checkedItems.set(item, sh)
        }else{
            if(itemArray.indexOf(value) !==-1){
                itemArray = itemArray.filter(e => e !== value)
            }
            //checkedItems.delete(item) 
        }
        let itemString = itemArray.toString();
		//console.log('itemString->',itemString);
        this.setState({itemArray});
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;
        //const {checkedItems}= this.state;
        var id = this.props.rowAllData[8];
		let numberColumn = 2;
		this.props.allData.map(function(data,i) {
			if(data[0] == id){
				numberColumn = data[136] ? data[136] : 2;
			}
		})
		
		let col = 6;
		
		if(numberColumn == 3){
			col = 4
		}else if(numberColumn == 4){
			col = 3
		}
		
		 var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        /* var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        } */
		
		var options = this.props.rowAllData[9];
		
		if(options){    
            var str = options.split('_');
            var res = str[0].split(',');
            var order = str[1];
			
			if(order == 'alphabetical'){
				res = res.sort();
			}else if(order == 'random'){
				res = res.reverse();
			}

        }
		

        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
            var name = 'action'+i;
			var txt = courthouse.split(':');
            if(ts.indexOf(courthouse) !== -1){
				
                return (
                    <div className={"border form-check col-md-"+col} key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" checked='checked' value={courthouse}  name={name}/> {txt[0]} 
                        </label>
                    </div>
                );  
            }else{
                return (
                    <div className={"border form-check col-md-"+col} key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" value={courthouse} name={name}/> {txt[0]} 
                        </label>
                    </div>
                );
                }
            })
        }
        return (
            <div className="row grid-check" onChange={this.shoot}>
               {optionsHtml}
            </div>
         
        );
    }

}

class Reduction extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        let name = event.target.name;
		let id = this.props.rowAllData[8];
		let value = event.target.value;
		//name = name.replace(id,'');
		let sReduction = this.state.sReduction
		if(name == 'sReduction'){
			if(value > 25){
				alert('The value should be less than or equal 25%.');
				return false;
			}
			sReduction = value;
			this.setState({sReduction});
		}

		let itemArray = this.state.itemArray
		
		itemArray[name] = value;
		
		this.setState({itemArray});
		
		var rows = [-8,-6,-4,-2,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
		let newItem = [];
		for(let i=1; i <= 21; i++){
			let mVal = itemArray['M'+i];
			let cVal = itemArray['C'+i];
			if(!mVal){
				mVal = $('#'+id+'-M'+i).val();
			}
			if(sReduction && mVal){
				cVal = sReduction;
				$('#'+id+'-C'+i).val(cVal);
			}
			
			if(!mVal){
				$('#'+id+'-C'+i).val('');
			}
			
			if(name == 'sReduction'){
				if(!value){
					$('#'+id+'-C'+i).val('');
				}
			}
			
			let rVal = Number(mVal)-(Number(cVal)/100)*Number(mVal);
			if(rVal){
				$('#'+id+'-R'+i).val(rVal);
				let res = rows[i-1]+','+mVal+','+cVal+','+rVal
				newItem.push(res);
			}else{
				$('#'+id+'-R'+i).val('');
			}
		}
		
		let newItemArray = []
		Object.keys(newItem).map(function(key) {
           newItemArray.push(newItem[key]);
        });
		
		let itemString = newItemArray.join('|');
		this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
	
    render(){
        const {kioskResponse}=this.props;
		let sReduction = this.state.sReduction
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split('|');
            }
        }
		var headers = ['Measurement','Reduction %','Goal'];
		var rows = [-8,-6,-4,-2,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				//headerText = headerText.split(':');
				return (	
				<th>
				{headerText}
				</th>	
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				let r = i+1;
				let response = '';
				if(ts){
					ts.map(function(resStr,n) {
						let rowCol = resStr.split(',');
						if(rowCol[0] == rowText){
							response = rowCol;
						}
					})
				}
				return (	
					<tr>
						<td>
						{rowText}
						</td>
						{
							headers.map(function(headerText,j) {
								let c = j+1;
								//let headerStr = headerText.split(':');
								let name = 'M'+r;
								if(c == 2){
									name = 'C'+r;
								}else if(c == 3){
									name = 'R'+r;
								}
								if(response){
									return(
									<td>
									{c == 3 ? 
										<input type="number" value={response[c]} id={id+'-'+name} name={name} disabled="disabled" />
										:
										<input type="number" id={id+'-'+name} value={response[c]} name={name} />
									}
									</td>
									);
								}else{
																		return(
									<td>
									{c == 3 ? 
										<input type="number" id={id+'-'+name} name={name} disabled="disabled" />
										:
										<input type="number" id={id+'-'+name} name={name} />
									}
									</td>
									);

								}
							})
							
						}
					</tr>
				);
            }) 
        }
		
        return (
            <table className="basic-grid table-bordered" onChange={this.shoot}>
				<tr><td colspan="4">Standard Reduction % <input type="number" value={sReduction} name='sReduction' /></td></tr>
				<tr>
					<th>At Level</th>
				    {headerHtml}
				</tr>
               {optionsHtml}
            </table>
         
        );
    }

}

export {Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,Q11,Q12,Q13,Q14,Q15,Q16,Q11B,Timers,Sliders,Image,Sig,Video,Launchpad,Subjective,Assessment,Grid,BasicGrid,GridMultiCheck,GridSingleCheck,GridCheck,GridDropdown,Likert,Smart,Reduction,Weighted};