import React, { Fragment, useState } from 'react';
import $ from 'jquery';
import { makeStyles }   from '@material-ui/core/styles';
import TextField        from '@material-ui/core/TextField';
import Button           from '@material-ui/core/Button';
import Grid             from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Snackbar         from '@material-ui/core/Snackbar';
import Box              from '@material-ui/core/Box';
import { Snackbar } from '@material-ui/core';

import AddIcon                from '@material-ui/icons/Add';
import FilterNoneIcon         from '@material-ui/icons/FilterNone';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';

import config from "../../config";
import axios  from 'axios';
import { CopyToClipboard }    from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '10px'
    },
    fileGrid: {
        border: '1px solid #c5c5c5',
        //borderRadius: '5px',
        margin: '10px -10px',
        //paddingBottom: '25px',
        //maxWidth: 345,
    },
    inputFile: {
        marginTop: '20px',
    },
    uploadButton: {
        borderRadius: '20px',
    },
    spinner: {
        marginLeft: '15px'
    }
}));

export default function FileUploadType(props) {
    
    const classes = useStyles();
    const [fileName, setFileName]       = useState('');
    const [file, setfile]     = useState('');
	const fieldId = props.fieldId;
	const name = props.dataName;
	const description = props.dataDes;
	const [fileUrl, setFileUrl] = useState(props.dataField);
	
    const uploadSubmit = () => {
        
        let formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        formData.append('description', description);
        let url = config.API_URL+'uploadfile/file_upload1.php';
        
        axios({
            method: 'POST',
            url: config.API_URL+'uploadfile/file_upload1.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            
            let data = response.data;
            
                let name =data[0]
                let description =data[1]
                let path =data[2]
                let date =data[3]
                setFileUrl(path);
                $( ".pdf-upload-form-"+fieldId ).hide();
                props.uploadFilePath(fieldId, name, description, path, date, fileUrl);
            
        })
        .catch(error => {
            console.log('error::', error);

        }) 
    }
   
    const handleChange = (files) => {
        setFileName(files[0].name);
        setfile(files[0]);
    }

    return (
        <Fragment>
            <form className={"makeStyles-root-2 pdf-upload-form-"+fieldId} noValidate autoComplete="off" style={{ display: "none" }}>
				<Grid container spacing={3} className={classes.fileGrid}>
                    <Grid item xs={12} className={classes.inputFile}>
                        <Button
                            variant="contained"
                            component="label"
                            color="secondary"
                            className={classes.uploadButton}
                            >
                            <AddIcon /> Select a File
                            <input
                                type="file"
                                name="file" 
                                style={{ display: "none" }}
                                onChange={ (e) => handleChange(e.target.files) }
                            />
                        </Button>
                        { fileName && (<Box component="span" display="block">{fileName}</Box>) }
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() =>uploadSubmit()}>Upload File</Button>
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    );
}
