import React,{ Component } from 'react';
import { CSVLink } from "react-csv";
import 'bootstrap';
import $ from 'jquery';
import TopFilter from './TopFilter'
import { Form,Button } from 'react-bootstrap';
import { KioskDrop } from "../../component/FormBuilder/KioskDrop";
import emailjs from 'emailjs-com';
import config from "../../config";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state ={
            emailMessage:'',
            emailId:'',
            errorMessage:'',
            successMessage:'',
            zoomLink:'',
        }
    }
    
	handleChange(event){
        this.setState({renderComponent:event.target.value});
        this.props.renderMod(event.target.value);
    }
    
	handleEmail=(event)=>{
        this.setState({[event.target.name]:event.target.value});
    }
    
	getValue=(event)=>{
        let sheetId = event.target.options[event.target.selectedIndex].dataset.id;
        if(sheetId){
           this.setState({selectedSheetId:sheetId});
        }else{
            sheetId = this.state.selectedSheetId
        }
        this.setState({selectedSheetId:sheetId});
        let sheetName = this.state.selectedSheet1;
        this.props.selectReviewOpt(event.target.value,sheetId, sheetName);

    }
    
	getLangData=(event)=>{
        this.setState({SelLangSheet:event.target.value});
        if(event.target.value == 'English'){
            var sheetsName = this.state.selectedSheet1 !== undefined ? this.state.selectedSheet1 : this.props.selectedSheet1;
            this.props.onSelect(sheetsName, this.state.selectedSheetId);
        }else{
            this.props.getLangData(event.target.value)
        }
    }
    
    getSheetValue=(event)=>{
        let sheetId = event.target.options[event.target.selectedIndex].dataset.id;
        this.setState({selectedSheet1:event.target.value});
        this.setState({selectedSheetId:sheetId});
        this.props.onSelect(event.target.value, sheetId);
    }
    
	sendEmail=()=> {
       var templateParams = {
            emailId : this.state.emailId, 
            emailMessage: this.state.emailMessage,
            zoomLink:config.zoomLink
        };
        if(this.state.emailId !== ''){
            emailjs.send('gmail','soogap',templateParams,'user_oCUNeAzhCYAWeHpvBs8kA')
            .then((result) => {
                $('#myEmailModal').modal('hide');
            }, (error) => {
                console.log(error.text);
            });
            this.setState({emailMessage:'',emailId:'',errorMessage:'',successMessage:'Email sent successfully'});
            var that =this;
            setTimeout(function(){ 
                that.setState({successMessage:''});
            }, 4000);
        }else{
            this.setState({errorMessage:'Please enter a email address'});
        }
    }
	
	pasteLink=()=>{
        var link = config.zoomLink
        $('#inputLink').val(link); 
    }
	
	copyLink=()=>{
        var copyText = document.getElementById("inputLink");
		copyText.select();
		copyText.setSelectionRange(0, 99999)
		document.execCommand("copy");
    }
	
	launchLink=()=>{
        var link = $('#inputLink').val();
        window.open(link);
    }

    render(){
        const { moduleName, selectedSheet, assoType,assoVisitType,assoDeviceType, clearAllFilter, selectedSheet1, selectedOpt, hasModify,sheetData, showDataDiv,selectKioskUsers,kioskUsers,errorMes,succesMes, handleHeaderFilter,mdsGuid,allData, handleClearTempSave} = this.props;
		//console.log('reviewSheetsName->',this.props.reviewSheetsName);
        const {errorMessage,successMessage} =this.state;
		const headers = [
		  { label: "First Name", key: "firstName" },
		  { label: "Last Name", key: "lastName" },
		  { label: "Email", key: "email" },
		  { label: "Age", key: "age" }
		];
		
		let kioskType = [];
		if(allData){
			//kioskType = allData[0][119].split(',');
		}
		
		const data = [
		  { firstName: "Warren", lastName: "Morrow", email: "sokyt@mailinator.com", age: "36" },
		  { firstName: "Gwendolyn", lastName: "Galloway", email: "weciz@mailinator.com", age: "76" },
		  { firstName: "Astra", lastName: "Wyatt", email: "quvyn@mailinator.com", age: "57" },
		  { firstName: "Jasmine", lastName: "Wong", email: "toxazoc@mailinator.com", age: "42" },
		  { firstName: "Brooke", lastName: "Mcconnell", email: "vyry@mailinator.com", age: "56" },
		  { firstName: "Christen", lastName: "Haney", email: "pagevolal@mailinator.com", age: "23" },
		  { firstName: "Tate", lastName: "Vega", email: "dycubo@mailinator.com", age: "87" },
		  { firstName: "Amber", lastName: "Brady", email: "vyconixy@mailinator.com", age: "78" },
		  { firstName: "Philip", lastName: "Whitfield", email: "velyfi@mailinator.com", age: "22" },
		  { firstName: "Kitra", lastName: "Hammond", email: "fiwiloqu@mailinator.com", age: "35" },
		  { firstName: "Charity", lastName: "Mathews", email: "fubigonero@mailinator.com", age: "63" }
		];
		 
		const csvReport = {
		  data: data,
		  headers: headers,
		  filename: 'Clue_Mediator_Report.csv'
		};
        return (
            <div className="card-body1">
                <TopFilter 
                    handleHeaderFilter={handleHeaderFilter} 
                    handleClearTempSave={handleClearTempSave} 
                    clearAllFilter = {clearAllFilter} 
                    assoType = {assoType}
                    selectedSheet = {selectedSheet}
                    sheetData = {sheetData}
                    allData = {allData}
                    moduleName = {moduleName}
                    assoVisitType ={assoVisitType}
                    assoDeviceType ={assoDeviceType}
                />
				<div className="row">
                    <div className="col-md-9">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label> Clinical Form </label>
									<Form.Control as="select" value={selectedSheet1 == undefined ? selectedSheet:selectedSheet1} onChange={this.getSheetValue}>
										<option> Please Select</option>
										{this.props.sheetsName.map((sheet, i) => (
											<option data-id={sheet.id} key={i}>{sheet.name}</option>
										))}
									</Form.Control>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label> {moduleName =='edit_form_data' ? 'Edit' : moduleName == 'review_approve' ? 'Review': moduleName == 'form_builder' ? 'Build From' :moduleName == 'format_forms' ? 'Format':'Review'} Version </label>
									 
										<Form.Control as="select" value={this.props.reviewVer} onChange={this.getValue}>
											<option value="original">Master Data Set</option>
											{moduleName =='format_forms' && hasModify ? <option value="improved">Improved</option> : moduleName != 'format_forms' ? <option value="improved">Improved</option> :null}
											{showDataDiv && moduleName !='edit_form_data' && moduleName !='review_approve'?
											this.props.reviewSheetsName.map((sheet, i) =>{ 
												if(sheet.name !== 'Sheet1' && sheet.mdsGuid == mdsGuid){
													//return <option data-id={sheet.id} key={i}>{sheet.name+' '+sheet.date+' '+sheet.configGuid}</option>
													return <option data-id={sheet.id} key={i}>{sheet.name}</option>
												}
											}):null}
										</Form.Control>
								</div>
							</div>
							{ moduleName == 'edit_form_data' ?
								<div className="col-md-4">
									<div className="form-group">
										<label> Field Name </label>
										{this.props.Dropdown}
									</div>
								</div>:null
							}
							
							{ moduleName == 'form_builder' && sheetData && selectedSheet ?
								<div className="col-md-3">
									<div className="form-group">
										<label> Select Language </label>
										<Form.Control as="select" id="SelLangSheet" value={this.state.SelLangSheet} onChange={this.getLangData}>
											<option> English</option>
											{/*this.props.langSheetsName.map((sheet, i) => (
												<option data-id={sheet.id} key={i}>{sheet.name}</option>
											))*/}
										</Form.Control>
									</div>
									
								</div>:null
							}
						</div>
						{ moduleName == 'form_builder' && sheetData && selectedSheet1 ?
							<div className="row">
								<div className="col-md-8">
									<KioskDrop 
										selectKioskUsers={selectKioskUsers}
										kioskType={kioskType}
										kioskUsers = {kioskUsers}
									/>
								</div>
								<div className="col-md-2 btn-box">
									<button type="button" className="btn btn-primary" onClick={this.props.showPreview}>Preview </button>
								</div>
							</div>:null
						}
						{ (moduleName == 'form_builder' && sheetData && selectedSheet1 ) || (moduleName == 'format_forms' && sheetData && selectedSheet1)  ?
							<div className="row">
								<div className="col-md-8">
									<div className="row">
										<div className="col-md-1">
											<button type="button" className="btn btn-primary mb-2" onClick={this.pasteLink} > <i className="fa fa-video-camera" aria-hidden="true"></i></button>
										</div>
										<div className="col-md-11">
											 <input id="inputLink" type="text" className="form-control"/>
										</div>
									</div>
									{successMessage ?   
										<div className="alert alert-success" role="alert">
											{this.state.successMessage}
										</div>
									:null}
								</div>
								<div className="col-md-3 btn-box">
									<button type="button" className="btn btn-primary mb-2" onClick={this.copyLink}><i class="fas fa-copy"></i> </button> &nbsp;
									<button type="button" className="btn btn-primary mb-2" onClick={this.launchLink}><i class="fas fa-external-link-alt"></i></button> &nbsp;
									<button type="button" className="btn btn-primary mb-2" data-toggle="modal" data-target="#myEmailModal"><i className="fa fa-envelope"></i></button>
								</div>
								
							</div>:null
						}
						{/* (moduleName == 'review_approve' && sheetData && selectedSheet1 ) || (moduleName == 'form_builder' && sheetData && selectedSheet1)  ?
							<div className="row">
								<div className="col-md-3">
									<label className="showAllSwitch">
									<input type="checkbox" name="includePFB" onClick={this.props.dataFilterPfb}/>
									<span className="slider round showAllSlide"></span>
									</label> Include PFB=N.  
								</div>
								<div className="col-md-3">
									<label className="showAllSwitch">
									<input type="checkbox" name="showPFB" onClick={this.props.dataFilterPfb}/>
									<span className="slider round showAllSlide"></span>
									</label> Show only PFB=N.  
								</div>
								<div className="col-md-3">
									<label className="showAllSwitch">
									<input type="checkbox" name="showQ" onClick={this.props.showAllQues}/>
									<span className="slider round showAllSlide"></span>
									</label> Show all questions with conditional requirements.
								</div>
								{<div className="col-md-3 form-filter-company">
									<label> Filter by Company Name </label>
									{this.props.Companydropdown}
								</div>}
							</div>:null
						*/}
						
					
					</div>
					
					
					<div className={moduleName == 'review_approve' || moduleName == 'form_builder' ? "col-md-3 filter-sidebar filter-sidebar-scroll" : "col-md-3 filter-sidebar"}>
						<div className="row">
						{/*<CSVLink {...csvReport}>Export to CSV</CSVLink>*/}
							{ moduleName == 'edit_form_data' && showDataDiv ?
								<div className="col-md-12">
									<div className="form-group-1">
										<label className="showAllSwitch">
										<input type="checkbox" name="includePFB" onClick={this.props.includePfbQues}/>
										<span className="slider round showAllSlide"></span>
										</label> Include PFB=N.  
									</div>
									<div className="form-group-1">
										<label className="showAllSwitch">
										<input type="checkbox" name="showPFB" onClick={this.props.showPfbQues}/>
										<span className="slider round showAllSlide"></span>
										</label> Show only PFB=N.  
									</div>
									{<div className="form-group-1">
										<label> Filter by Company Name </label>
										{this.props.Companydropdown}
									</div>}
								</div>:null
							}

							{ moduleName == 'review_approve' && sheetData && selectedSheet ?
								<div className="col-md-12">
									<div className="radio">
									  <label><input type="radio" defaultChecked={this.props.OptSel == 0} value="0" name="selOptions" onChange={this.props.dataFilter}/> View All(Default)</label>
									</div>
									<div className="radio">
									  <label><input type="radio" checked={this.props.OptSel == 1} value="1" name="selOptions" onChange={this.props.dataFilter} /> Need Attention Only</label>
									</div>
									<div className="radio">
									  <label><input type="radio" checked={this.props.OptSel == 2} value="2" name="selOptions" onChange={this.props.dataFilter}/> Approved Only</label>
									</div>
									<div className="radio">
									  <label><input type="radio" checked={this.props.OptSel == 3} value="3" name="selOptions" onChange={this.props.dataFilter} /> Need Attention and Approved</label>
									</div>
									
									<div className="form-filter">
										<label className="showAllSwitch">
										<input type="checkbox" name="includePFB" onClick={this.props.dataFilterPfb}/>
										<span className="slider round showAllSlide"></span>
										</label> Include PFB=N.  
									</div>
									<div className="form-filter">
										<label className="showAllSwitch">
										<input type="checkbox" name="showPFB" onClick={this.props.dataFilterPfb}/>
										<span className="slider round showAllSlide"></span>
										</label> Show only PFB=N.  
									</div>
									{<div className="form-filter-company">
										<label> Filter by Company Name </label>
										{this.props.Companydropdown}
									</div>}
									<div className="form-filter">
										<label className="showAllSwitch">
										<input type="checkbox" name="showQ" onClick={this.props.showAllQues}/>
										<span className="slider round showAllSlide"></span>
										</label> Show all questions with conditional requirements.  
									</div>
								</div>:null
							}
							
							{ moduleName == 'form_builder' && sheetData && selectedSheet1 ?
								<div className="col-md-12">
									<div className="radio">
									  <label><input type="radio" defaultChecked={this.props.selOptions == 'view_all'} value="view_all" name="selOptions" onChange={this.props.dataFilter}/> View All(Default)</label>
									</div>
									<div className="radio">
									  <label><input type="radio" checked={this.props.selOptions == 'view_all_pfb'} value="view_all_pfb" name="selOptions" onChange={this.props.dataFilter}/> View All PFB=Y</label>
									</div>
									<div className="radio">
									  <label><input type="radio" checked={this.props.selOptions == 'include_only'} value="include_only" name="selOptions" onChange={this.props.dataFilter} /> View All Included PFB=Y</label>
									</div>
									{/*<div className="radio">
									  <label><input type="radio" checked={this.props.selOptions == 'include_required'} value="include_required" name="selOptions" onChange={this.props.dataFilter}/> View Included and Required</label>
									</div>*/}
									<div className="radio">
									  <label><input type="radio" checked={this.props.selOptions == 'include_required'} value="include_required" name="selOptions" onChange={this.props.dataFilter}/> View Included and Required PFB=Y</label>
									</div>
									<div className="radio">
									  <label><input type="radio" checked={this.props.selOptions == 'include_not_required'} value="include_not_required" name="selOptions" onChange={this.props.dataFilter}/>  View Included But Not Required PFB=Y</label>
									</div>
									
									<div className="form-filter">
										<label className="showAllSwitch">
										<input type="checkbox" name="includePFB" onClick={this.props.dataFilterPfb}/>
										<span className="slider round showAllSlide"></span>
										</label> Include PFB=N.  
									</div>
									<div className="form-filter">
										<label className="showAllSwitch">
										<input type="checkbox" name="showPFB" onClick={this.props.dataFilterPfb}/>
										<span className="slider round showAllSlide"></span>
										</label> Show only PFB=N.  
									</div>
									{<div className="form-filter-company">
										<label> Filter by Company Name </label>
										{this.props.Companydropdown}
									</div>}
									<div className="form-filter">
									<label className="showAllSwitch">
									<input type="checkbox" name="showIncludeN" onClick={this.props.showNotInclude}/>
									<span className="slider round showAllSlide"></span>
									</label> Show Only Not Included
									</div>
									<div className="form-filter">
									<label className="showAllSwitch">
									<input type="checkbox" name="showQ" onClick={this.props.showAllQues}/>
									<span className="slider round showAllSlide"></span>
									</label> Show all questions with conditional requirements.
									</div>
									<div className="radio addNewQuestions-2">
									  <label className="addNewQuestions"><input type="radio" checked={this.props.selOptions == 'add_new_questions'} value="add_new_questions" name="selOptions" onChange={this.props.addNewField} /> Add New Questions,Headers,Titles,Text</label>
									</div>
									
								</div>:null
							}
							
							
							{ moduleName == 'format_forms' && sheetData && selectedSheet1 ?
								<div className="col-md-12">
									<div className="radio">
									  <label><input type="radio" defaultChecked={this.props.selOptions == 'view_all'} value="view_all" name="selOptions" onChange={ this.props.dataFilter } /> View All(Default)</label>
									</div>
									<div className="radio">
									  <label><input type="radio" value="required_only" name="selOptions" onChange={ this.props.dataFilter } /> View Required </label>
									</div>
									<div className="form-filter">
										<label className="showAllSwitch">
										<input type="checkbox" name="includePFB" onClick={this.props.dataFilterPfb}/>
										<span className="slider round showAllSlide"></span>
										</label> Include PFB=N.  
									</div>
									<div className="form-filter">
										<label className="showAllSwitch">
										<input type="checkbox" name="showPFB" onClick={this.props.dataFilterPfb}/>
										<span className="slider round showAllSlide"></span>
										</label> Show only PFB=N.  
									</div>
									{<div className="form-filter-company">
										<label> Filter by Company Name </label>
										{this.props.Companydropdown}
									</div>}
								</div>:null
							}

					
						</div>
					</div>
				</div>

                <div className="modal fade" id="myEmailModal" role="dialog">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Send Link</h5>
                        </div>
                        <div className="modal-body">
                            <div>
                                <label>Enter Email address to invite to telehealth visit:</label>
                                <input className="form-control" type="email" name="emailId" value={this.state.emailId} onChange={this.handleEmail}/>
                                { errorMessage ?
                                <div className="alert alert-danger" role="alert">
                                    {this.state.errorMessage}
                                </div>:null}

                            </div>
                            <div>
                                <label>Include message to send with link:</label>
                                <textarea className="form-control" type="text" name ="emailMessage" value={this.state.emailMessage} onChange={this.handleEmail}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary float-left" onClick={this.sendEmail}>Send Link</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                      
                    </div>
                </div>
            </div>              
        )
    }
    
}

export default Header;