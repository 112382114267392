import React from 'react';
import './App.css';
import ClinicalTracks from "../component/ClinicalTracks";
import { load } from "../Helpers/Common";
import { gapi } from 'gapi-script';
import config from "../config";

class App extends React.Component {

   	constructor(props) {
        super(props);
		if( localStorage.getItem('username') && localStorage.getItem('username') == 'admin'){
            this.username = localStorage.getItem('username');
        }else if(this.props.location.pathname == "/"){
			localStorage.removeItem('username');
            this.props.history.push({
			  pathname: '/beta',
			  state: { moduleName: 'edit_form_data' },
			});
        }    
    }
  	
  	componentDidMount() {
        gapi.load("client", this.initClient);
    }

  	initClient = () => {
        gapi.client.init({
           apiKey: config.apiKey,
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
            gapi.auth.authorize({client_id: config.client_id, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: true});
        });
    };

    render() {
		if(this.props.location.pathname == "/"){
   			return (
			    <div className="App">
				<div className="loader"></div>
			      <ClinicalTracks/>
			    </div>
			);
   		}
   		else{
   			return (<div className="App"><div className="loader"></div></div>)
   		}
	   	
   }
  
}

export default App;