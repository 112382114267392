import React, { Component, PropTypes } from 'react'
import 'bootstrap';
import $ from 'jquery';
import config from "../../config";
import { gapi } from 'gapi-script';
import { Form,Button } from 'react-bootstrap';
import { generatehtmlfromlocation } from "../../Helpers/Common";
import ReactHtmlParser from 'react-html-parser';
import STLViewer from 'ftn-stl-viewer'
import BasicTextFields from "./upload.js";
import FileUploadType from "./FileUploadType.js";
import PdfFileUpload from "./PdfFileUpload.js";
import axios  from 'axios';
import { tagHtml,noteHtml,noteModal,showImageModal,attentHTML,includeModal,addUsersHTML,addUsersModal,feedBack } from "../../component/ReviewApprove/Modal";
export default class MainData extends Component {
    constructor(props){
        super(props)
        this.state = {
            checkedNeeds:new Map(),
            checkedApprove:new Map(),
			existGuid:'',
        }
    }
	
    classTypes(comment){
        let notInclude = '';
        let parentHeader = '';
		if(comment[5] !== 'Parent Header' && comment[111] == 'N' && (this.props.selectedOpt == "original" || (this.props.selectedOpt == "improved" && comment[14] == 'N') || (this.props.selDropOpt == "exported" && comment[14] == 'N'))){
            notInclude = 'not-include';
        }
		if(comment[5] !== 'Parent Header' && comment[14] == 'N' && this.props.moduleName == 'review_approve'){
            notInclude = 'not-include';
        }
        if(comment[5] !== 'Parent Header' && comment[14] == 'N' && this.props.moduleName == 'format_forms'){
            notInclude = 'not-include';
        }
		if(comment[5] !== 'Parent Header' && comment[14] == 'N' && (this.props.selectedOpt != "improved" || comment[35] == '')){
            notInclude = 'not-include';
        }
		
		if(this.props.moduleName == 'custom_config'){
            notInclude = '';
        }
		
		if(comment[36] == 'Deleted'){
            notInclude = 'not-include';
        }
        if(comment[5].toLowerCase() == 'parent header'){
            parentHeader = ' parent-header ';
        }
        if(comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3'){
            
			if((this.props.moduleName == 'test_forms' || this.props.moduleName == 'format_forms') && comment[15] == 'Y'){
				parentHeader = ' question req-only';
			}else{
				parentHeader = ' question';
			}
			
        }
		
		if((this.props.moduleName == 'form_builder' || this.props.moduleName == 'test_forms') && (comment[5].toLowerCase() == 'boxbegin' || comment[5].toLowerCase() == 'boxend')){
			//notInclude = '';
			parentHeader = ' '+comment[5]+'-div';
		}

		if(comment[111] == 'N'){
            parentHeader = parentHeader+' pfbN';
        }
		
        let className = 'childDiv ' + notInclude + parentHeader;
        if(comment[7] && comment[8]){

            let combVis   = comment[7]+'-'+comment[8].toLowerCase();
            let visibility1= combVis.replace(/[^A-Z0-9]+/ig, "");
            className = comment[7]+" collapse "+visibility1+ " pre-question " + className; 
        }
		let that = this;
		//if(this.props.moduleName == 'test_forms' || this.props.moduleName == 'custom_config'){
		if(this.props.moduleName == 'custom_config'){
			setTimeout(function(){
				if($('#'+comment[0]).find('.question').length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				//}else if(comment[14] == 'Y' && that.props.selectedOpt == 'original'){
				}else if((comment[14] == 'Y' && comment[111] == 'Y' && that.props.selectedOpt == "original") || (that.props.selectedOpt != "original" && comment[14] == 'Y')){
					$('#'+comment[0]).removeClass("not-include");	
				}
			}, 2000);
		}
		setTimeout(function(){
			if($("input[name='showPFB']").prop("checked") == true){
				if(that.props.selDropOpt == "exported" && comment[14] == 'Y' && comment[111] == 'N'){
					//$('#'+comment[0]).removeClass("not-include");	
				}
			}
		}, 2000);
		
        return className;
    }
	
    getValue=(event)=>{
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
	
    showStar(comment){
        let iTag
        this.props.allData.map((valu, i) => {
            if(comment[0] === valu[7] &&  comment[7]){
                iTag = <span><i className="fa fa-star" style={{fontSize:'20px',color:'yellow'}} data-toggle="modal" data-target={"#showStarModalYellow"+comment[0]}></i><i className="fa fa-star" style={{fontSize:'20px',color:'blue'}} data-toggle="modal" data-target={"#showStarModalBlue"+comment[0]}></i></span>
            }else if(comment[0] === valu[0] && comment[7] === valu[7] && comment[8]){
                iTag = <i className="fa fa-star" style={{fontSize:'20px',color:'blue'}} data-toggle="modal" data-target={"#showStarModalBlue"+comment[0]} ></i>
            }else if(comment[0] === valu[7]){
                iTag = <i className="fa fa-star" style={{fontSize:'20px',color:'yellow'}} data-toggle="modal" data-target={"#showStarModalYellow"+comment[0]} ></i>
            }
        })
        return iTag
    }
    
	showNeedModal=(needIds)=>{
        this.setState({[needIds]:''});
        { this.props.allData.map((valu, i) => {
            if(valu[0] === needIds){
                this.setState({[needIds]:valu[27]});
            }
        })}
        $('#needs'+needIds).modal('show');
    }
	
	showFeedBack=(feedIds)=>{
        this.setState({name:''});
		let res = this.props.feedsResponse.get(feedIds);
		if(typeof res != "undefined"){
			this.setState({name:res})
		}
        $('#feeds'+feedIds).modal('show');
    }
	
    headerTypes(comment){
        let header = '';
        switch (comment[5].toLowerCase()) {
            case 'parent header':
                header = 'parent';
                break;
            case 'child header 1':
                header = 1;
                break;
            case 'child header 2':
                header = 2;
                break;
            case 'child header 3':
                header = 3;
                break;
            default:
                header = '';                   
        }
        return header;
    }
    
	toggle(id){
		if(!$('#parent'+id.toLowerCase()).prop('disabled')){
			$('#parent'+id.toLowerCase()).find('i:first').toggleClass('fa fa-plus-square-o fa-1x mr-2  fa fa-minus-square-o fa-1x mr-2');
		}
		$('#parent'+id.toLowerCase()).prop('disabled', true);
		setTimeout(function(){
			$('#parent'+id.toLowerCase()).prop('disabled', false);
		}, 1000);
    }
    
	subQuestions = (value,parent,id) => {
        if(this.props.moduleName =='test_forms'){
            this.props.getRsponseValue(value, parent, id )
        }
        let combVis1   = id+'-'+value;
        if(typeof  value !== 'number'){
            combVis1   = id+'-'+value.toLowerCase();
        }
        let kioskResponse = this.props.kioskResponse;
        let newString  = combVis1.replace(/[^A-Z0-9]+/ig, "");
        var divClass  = $("."+newString).attr('class');
        let subQustionData  = this.props.allData;
        let ids = [];
        $("."+id).each(function( index) {
            var ids= $(this).attr('id');
            $('.'+ids).addClass(id);
        });
        $("."+id).collapse('hide');
		
        $("."+id+" .answer").find("input:radio:checked").prop('checked',false);
        $("."+id+" .answer").find("option:selected").prop('selected',false);
        $("."+id+" .answer").find("input:checkbox:checked").prop('checked',false);
        $("."+id+" .answer").find("input:text").val('');
		
        { subQustionData.map((answers, i) => {
            let combVis   = answers[7]+'-'+answers[8].toLowerCase();
            let visibility1= combVis.replace(/[^A-Z0-9]+/ig, "");
            if(visibility1 == newString) {
                $("."+newString).collapse('show');
                $("."+newString).removeClass('not-include');
                //$("."+newString).css('display','block');
            }
        })}
    }
	
	replacePdfApi=(fieldId)=>{
		$( ".apiPdf-list-"+fieldId ).show();
	}
	
	replacePdfLocal=(fieldId)=>{
		$( ".pdf-upload-form-"+fieldId ).show();
	}
	
	uploadPdfApi=(fieldId)=>{
		let pdfId = $("."+fieldId+"_apiP:checked").val();
		let name = '';
		let description = '';
		let pdf = '';
		if(pdfId){
			let pdfs = this.props.apiPdfs;
			pdfs.map(function(val,i) {					
				if(pdfId == val.id){
					name = val.name;
					description = val.description;
					pdf = val.url;
				}
			})
			
			let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
			date = date.replaceAll('/','-');
			
			let embedCode = '<embed width="100%" height="100%" src='+pdf+' />';
			let htmlFileData = '<b>Name:</b> '+name+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+  date;
			$( "#fileHtmlDiv"+fieldId ).html('');
			
			let fileData = pdf+','+name+','+description+','+date;
			
			let updateData=[];
			let rowId ='';
			let accessToken = gapi.auth.getToken().access_token;
			let spreadsheetID = this.props.sheetConfigId;
			/* if(this.props.selDropOpt == "original" || this.props.moduleName != 'test_forms'){
				spreadsheetID = config.spreadsheetId;
			}else{
				spreadsheetID = config.pahseII_spreadsheetId;
			} */
			let allDatas = this.props.allData;
			
			let params = {
				access_token:accessToken,
				spreadsheetId: spreadsheetID,
			};
			
			allDatas.map((rowDatas, i) => { 
				if(rowDatas[0] == fieldId){
					rowId = (i+2);
					let row1 = {
						"range": this.props.selectedSheet+'!CV'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[
							[ fileData ]
						]
					};
					updateData.push(row1)
				}
			})
			
			let batchUpdateValuesRequestBody = {
				valueInputOption: 'USER_ENTERED',
				data: updateData, 
			};
			let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
			updateRequest.then(function(response) {
				$( "#fileAns"+fieldId ).html(htmlFileData);
				$( "#fileHtmlDiv"+fieldId ).html(embedCode);
				$( "#download-"+fieldId).attr("href", pdf);
				$( ".apiPdf-list-"+fieldId ).hide();
				$( ".api-pdf-"+fieldId ).show();
				alert('File upload Successfully');
			}, function(reason) {
				console.error('error: ' + reason.result.error.message);
			});
			let parent='';
			if(this.props.moduleName =='test_forms'){
				this.props.getRsponseValue(fileData, parent, fieldId )
			}
		}else{
			alert('Please select data from the list!');
		}
	}
    
	uploadFilePath=(fieldId, name, description, path, fileUrl)=>{
		
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		date = date.replaceAll('/','-');
			
		//console.log('fileUrl->',fileUrl);
        let fileType = path.split('.').pop();
        let embedCode='';
        let htmlFileData='';
        $( "#fileHtmlDiv"+fieldId ).html('');
		if(fileUrl){
			
			if(fileType == 'stl'){
				embedCode = '<iframe width="100%" height="100%" src="https://www.viewstl.com/?embedded&url='+path+'&edges=yes&noborder=yes"></iframe>'
			}else if(fileType.toLowerCase() === 'jpeg' || fileType.toLowerCase() === 'jpg' || fileType.toLowerCase() === 'png' || fileType == 'gif'){
				embedCode = '<img src="'+path+'" width="259px" height="200px"/>'
			}else if(fileType == 'pdf'){
				embedCode = '<embed width="100%" height="100%" src='+path+' />'
			}else{
				embedCode = '<iframe width="100%" height="100%" src='+path+'></iframe>'
			}
			htmlFileData = '<b>Name:</b> '+name+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+  date;
			
		}else{
		
			if(fileType == 'stl'){
				embedCode = '<div class="run-time"><iframe width="100%" height="100%" src="https://www.viewstl.com/?embedded&url='+path+'&edges=yes&noborder=yes"></iframe></div>'
			}else if(fileType.toLowerCase() === 'jpeg' || fileType.toLowerCase() === 'jpg' || fileType.toLowerCase() === 'png' || fileType == 'gif'){
				embedCode = '<div class="run-time"><img src="'+path+'" width="259px" height="200px"/></div>'
			}else if(fileType == 'pdf'){
				embedCode = '<embed width="100%" height="100%" src='+path+' />'
			}else{
				embedCode = '<div class="run-time"><iframe width="100%" height="100%" src='+path+'></iframe></div>'
			}
			htmlFileData = '<div class="run-time"><b>Name:</b> '+name+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+  date+'</div>';
		}
        let fileData = path+','+name+','+description+','+date;
        
        let updateData=[];
        let guids = '';
        let rowId ='';
        let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = this.props.sheetConfigId;
        /* if(this.props.selDropOpt == "original" || this.props.moduleName != 'test_forms'){
            spreadsheetID = config.spreadsheetId;
        }else{
            spreadsheetID = config.pahseII_spreadsheetId;
        } */
        let allDatas = this.props.allData;
        
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        
        allDatas.map((rowDatas, i) => { 
            if(rowDatas[0] == fieldId){
                rowId = (i+2);
                let row1 = {
                    "range": this.props.selectedSheet+'!CV'+rowId,
                    "majorDimension": "ROWS",
                    "values": 
                    [
                        [ fileData ]
                    ]
                };
                updateData.push(row1)
            }
        })
        
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
            $( "#fileAns"+fieldId ).html(htmlFileData);
            $( "#fileHtmlDiv"+fieldId ).html(embedCode);
			$( "#download-"+fieldId).attr("href", path);
			$( ".apiPdf-list-"+fieldId ).hide();
            alert('File saved Successfully');
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
        let parent='';
        if(this.props.moduleName =='test_forms'){
            this.props.getRsponseValue(fileData, parent, fieldId )
        }
        
    }
	
	uploadVideoPath=(fieldId, title, description, embedCode, dataField)=>{
		let videoData1 = '';
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		date = date.replaceAll('/','-');
		if(dataField){
			videoData1 = embedCode+'<br><b>Name:</b> '+title+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+date;
		}else{
			videoData1 = '<div class="run-time">'+embedCode+'<br><b>Name:</b> '+title+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+date+'</div>';
		}
		let videoData = embedCode+'<br><b>Name:</b> '+title+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+date;
		let updateData=[];
        let rowId ='';
		let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = this.props.sheetConfigId;
        /* if(this.props.selDropOpt == "original" || this.props.moduleName != 'test_forms'){
            spreadsheetID = config.spreadsheetId;
        }else{
            spreadsheetID = config.pahseII_spreadsheetId; 
        } */
        let allDatas = this.props.allData;
        
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        
        allDatas.map((rowDatas, i) => { 
            if(rowDatas[0] == fieldId){
                rowId = (i+2);
                let row1 = {
                    "range": this.props.selectedSheet+'!CV'+rowId,
                    "majorDimension": "ROWS",
                    "values": 
                    [
                        [ videoData ]
                    ]
                };
                updateData.push(row1)
            }

        })
        
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			$( "#ans"+fieldId ).html(videoData1);
            alert('Video saved Successfully');
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
		let parent='';
		if(this.props.moduleName =='test_forms'){
            this.props.getRsponseValue(videoData, parent, fieldId )
        }
        
    }
	
	deleteVideo=(fieldId)=>{
		
		let videoData = '';
        let updateData=[];
        let rowId ='';
		let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = this.props.sheetConfigId;
        /* if(this.props.selDropOpt == "original" || this.props.moduleName != 'test_forms'){
            spreadsheetID = config.spreadsheetId;
        }else{
            spreadsheetID = config.pahseII_spreadsheetId;
        } */
        let allDatas = this.props.allData;
        
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        
        allDatas.map((rowDatas, i) => { 
            if(rowDatas[0] == fieldId){
                rowId = (i+2);
                let row1 = {
                    "range": this.props.selectedSheet+'!CV'+rowId,
                    "majorDimension": "ROWS",
                    "values": 
                    [
                        [ videoData ]
                    ]
                };
                updateData.push(row1)
            }

        }) 
        
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			$( "#uploadbtn-"+fieldId ).text('Upload Video');
			$( "#deletebtn-"+fieldId ).hide();
			
			$( "#ans"+fieldId ).html('');
            alert('Video delete Successfully');
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
		let parent='';
		if(this.props.moduleName =='test_forms'){
            this.props.getRsponseValue(videoData, parent, fieldId )
        }
	}
	
	deleteFile=(fieldId)=>{
		let fileData = '';
        let updateData=[];
        let rowId ='';
		let accessToken = gapi.auth.getToken().access_token;
        let spreadsheetID = this.props.sheetConfigId;
        /* if(this.props.selDropOpt == "original" || this.props.moduleName != 'test_forms'){
            spreadsheetID = config.spreadsheetId;
        }else{
            spreadsheetID = config.pahseII_spreadsheetId;
        } */
		
		
        let allDatas = this.props.allData;
        
        let params = {
            access_token:accessToken,
            spreadsheetId: spreadsheetID,
        };
        
        allDatas.map((rowDatas, i) => { 
            if(rowDatas[0] == fieldId){
                rowId = (i+2);
                let row1 = {
                    "range": this.props.selectedSheet+'!CV'+rowId,
                    "majorDimension": "ROWS",
                    "values": 
                    [
                        [ fileData ]
                    ]
                };
                updateData.push(row1)
            }

        })
        
        let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			$( "#uploadbtn-"+fieldId ).text('Upload File');
			$( "#deletebtn-"+fieldId ).hide();
			
			$( "#fileHtmlDiv"+fieldId ).html('');
			$( "#fileAns"+fieldId ).html('');
			$(".apiPdf-list-"+fieldId).show();
			$('.api-pdf-'+fieldId).hide();
            alert('File delete Successfully');
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
		let parent='';
		if(this.props.moduleName =='test_forms'){
            this.props.getRsponseValue(fileData, parent, fieldId)
        }
	}
	
	handleNeeds2=(e)=>{
		const item = e.target.name;
        this.setState({[item]:''});
		this.props.handleNeeds(e);
	}
    renderActions(comment){
		switch (this.props.moduleName) {
            case 'review_approve':
				let AttentHTML = attentHTML(comment,comment[27],this.showNeedModal);
                return  <div className="row">
                            <div className="col-md-4">{AttentHTML}</div>
                            <div className="col-md-4"> <input type="checkbox" name ={comment[0]} checked={ comment[27] ? 'checked' : this.props.checkedNeeds.get(comment[0])} onChange={this.props.handleNeeds}/></div>
                            <div className="col-md-4"> <input type="checkbox" name ={comment[0]} checked={ this.props.checkedApprove.get(comment[0])} onChange={this.props.handleApprove}/></div>
                        </div>
            case 'form_builder':              
                return <div className="row">
                            <div className="col-md-3"> <input type="checkbox" className="inc" name ={comment[0]} checked={ this.props.checkedInc.get(comment[0])} onChange={this.props.handleInclude}/></div>
                            { comment[5].toLowerCase() == 'text' || comment[5].toLowerCase() == 'title' || comment[5].toLowerCase() == 'image' || comment[5].toLowerCase() == 'link' || comment[5].toLowerCase() == 'inlinevideo' ? <div className="col-md-3"> </div> : <div className="col-md-3"> <input type="checkbox" className="req" name ={comment[0]} checked={ this.props.checkedReq.get(comment[0])} onChange={this.props.handleRequire}/></div>}
                            <div className="col-md-3"> <input type="checkbox" className="kiosk" name ={comment[0]} checked={ this.props.checkedKiosk.get(comment[0])} onChange={this.props.handleKiosk}/></div>
                            <div className="col-md-3"> <input type="checkbox" className="insert_field" name ={comment[0]} onChange={this.props.handleInsertField}/></div>
                        </div>
            case 'format_forms':
                return <div className="row">
                        <div className="col-md-4"> <input type="checkbox" className="move-input selCheck" value={comment[0]} onChange={() => this.props.checkMove(comment[0])} /></div>
                        <div className="col-md-4"> <input type="checkbox" className="kiosk" name ={comment[0]} checked={ this.props.checkedKiosk.get(comment[0])} onChange={this.props.handleKiosk}/></div>
                    </div>;
            case 'test_forms':
			{/*let res = this.props.feedsResponse.get(comment[0]);
				
                let FeedBack = feedBack(comment,res,this.showFeedBack);
                return  <div className="row">
					<div className="col-md-4">{FeedBack}</div>
					<div className="col-md-4"> <input className="feedback-btn-ur" type="checkbox" name={comment[0]}  onChange={this.props.handleFeeds} /></div>
			</div>*/}
				let needHTML = attentHTML(comment,comment[27],this.showNeedModal);
                return  <div className="row">
					<div className="col-md-4">{needHTML}</div>
					<div className="col-md-4"> <input type="checkbox" name ={comment[0]} checked={ comment[27] ? 'checked' : this.props.checkedNeeds.get(comment[0])} onChange={this.handleNeeds2}/></div>
				</div>;
            case 'custom_config':              
                return <div className="row">
                            <div className="col-md-3"> <input type="checkbox" className="inc" name ={comment[0]} checked={ this.props.checkedInc.get(comment[0])} onChange={this.props.handleInclude}/></div>
                            { comment[5].toLowerCase() == 'text' || comment[5].toLowerCase() == 'title' || comment[5].toLowerCase() == 'image' ? <div className="col-md-3"> </div> : <div className="col-md-3"> <input type="checkbox" className="req" name ={comment[0]} checked={ this.props.checkedReq.get(comment[0])} onChange={this.props.handleRequire}/></div>}
                            <div className="col-md-3"> <input type="checkbox" className="kiosk" name ={comment[0]} checked={ this.props.checkedKiosk.get(comment[0])} onChange={this.props.handleKiosk}/></div>
							<div className="col-md-3"> <input type="checkbox" className="move-input selCheck" value={comment[0]} onChange={() => this.props.checkMove(comment[0])} /></div>
                        </div>        
            default:
                return null; 
        }      
    }
    
	showStarModalBlue=(comment)=>{
        let requiredQue;
        if(comment[7] && comment[8]){
            this.props.allData.map((valu, i) => {
                if(comment[7] === valu[0]){
                    requiredQue = valu[4];
                }
            })
        }
        return (
        <div className="modal showStarModalBlue" id={'showStarModalBlue'+comment[0]}>
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Precondition Requirements</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                    <div className="modal-body text-left">
                        <h6>Required Field</h6>
                        <p>{requiredQue}</p>
                        <h6>Required Answer</h6>
                        <p>{comment[8]}</p>
                    </div>
                </div>
            </div>
        </div>)
    }
    
	showStarModalYellow=(comment)=>{
        return (
        <div className="modal showStarModalYellow" id={'showStarModalYellow'+comment[0]}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Precondition Requirements</h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body text-left">
                    <h5>The following fields are hidden unless this Precondition is met</h5>
                    <table className="table table-bordered">
                        <tr >
                            <td><b>Field Text</b></td>
                            <td><b>Precondition</b></td>
                        </tr>
                        {this.props.allData.map((valu, i) => {
                                if(comment[0] === valu[7] ){
                                return  <tr key={i}>
                                            <td>{valu[4]}</td>
                                            <td>{valu[8]}</td>
                                        </tr>
                                    /*return <div>
                                            <p>{valu[8]}</p>
                                            <p></p>
                                        </div>*/
                                }
                            })}
                    </table>
                    </div>
                </div>
            </div>
        </div>) 
    }
    
	renderButtons=(comment)=>{
		let kioskType = this.props.allData[0][119].split(',');
        var showStarModalYellow = this.showStarModalYellow(comment);
        var showStarModalBlue = this.showStarModalBlue(comment);
        var AddUsersHTML = addUsersHTML(comment,this.props.showAddUsersModal,this.props.checkedKiosk);
        var AddUsersModal = addUsersModal(comment,this.props.selectKioskUsers,this.props.kioskUsers,this.props.saveKioskUsersType,kioskType);
        if(this.props.selectedOpt == 'improved'){ 
            var TagHTML = tagHtml(comment, comment[19] ? comment[19]: comment[13]);
            var NoteHTML = noteHtml(comment, comment[18] ? comment[18]: comment[12]);
            var NoteModal = noteModal(comment, comment[18]? comment[18]: comment[12]);
        }else{
            var TagHTML = tagHtml(comment, comment[13]);
            var NoteHTML = noteHtml(comment, comment[12]);
            var NoteModal = noteModal(comment, comment[12]);  
        }
		var NeedsModal = this.needsModal(comment, comment[0]);
		
        switch (this.props.moduleName) {
            case 'review_approve':
                return  <div className="row button-section btn-box-right">
                                <div className="col-md-12">
                                <button type="button" className="btn code-dialog btn-edit" onClick={()=>this.props.myFunction(comment)}><i className="fa fa-edit"></i></button>
                            
							
							{ NoteHTML }{ NoteModal }{showStarModalBlue}{showStarModalYellow}
							
							
							{ TagHTML }{ NeedsModal }
							</div>
                        </div>
            case 'form_builder':
                    var includeNote = includeModal(comment);
					
                    //let lastElementId = this.props.allData[this.props.allData.length - 1];
                    return <div className="row builder-button-section 12">
                                <div className="col-md-12 btn-box-right">
                                    {comment[35] && this.props.setKiosk !== 'true' ? <button type="button" className="btn code-dialog btn-edit" onClick={()=>this.props.myFunction(comment,'edit')}><i className="fa fa-edit"></i></button>:null} 
                                
								{NoteHTML }{ NoteModal }{showStarModalBlue}{showStarModalYellow}{includeNote}{ AddUsersModal}
								
								{ this.props.setKiosk ? null : TagHTML }{ NeedsModal }
								
									{ comment[35] ?  <button type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={()=>this.props.myFunction(comment,'delete')}><i className="fa fa-trash"></i></button>:null }
								
                                { this.props.setKiosk  ? null : AddUsersHTML }
                                </div>
                            </div>;
            case 'format_forms':
                return <div className="row button-section btn-box-right">
                            <div className="col-md-12">
                                <button type="button" className="btn code-dialog btn-edit" onClick={()=>this.props.myFunction(comment,'edit')}><i className="fa fa-edit"></i></button>
                            
							{ NoteHTML }{ NoteModal }{showStarModalBlue}{showStarModalYellow}{ AddUsersModal}
							
							 { TagHTML }{ NeedsModal }
							
                                { this.props.setKiosk  ? null : AddUsersHTML }
                            </div>
                        </div>;
            case 'test_forms':
                    var includeNote = includeModal(comment);
					var FeedsModal = this.feedsModal(comment, comment[0]);
                    return <div className="row builder-button-section 13">
                                <div className="col-md-12 btn-box-right">
                                {NoteHTML }{ NoteModal }{showStarModalBlue}{showStarModalYellow}{includeNote}
                                
                                { this.props.setKiosk ? null : TagHTML }{ NeedsModal } { FeedsModal }
                                </div>                               
                            </div>;
			case 'custom_config':
				var includeNote = includeModal(comment);
				
				//let lastElementId = this.props.allData[this.props.allData.length - 1];
				return <div className="row builder-button-section 12">
							<div className="col-md-12 btn-box-right">
							{NoteHTML }{ NoteModal }{showStarModalBlue}{showStarModalYellow}{includeNote}{ AddUsersModal}
							
							{ this.props.setKiosk ? null : TagHTML }{ NeedsModal }
							{ this.props.setKiosk  ? null : AddUsersHTML }
							</div>
						</div>;
            default:
                return null; 
        }   
    }
    
	needsModal=(needData, needIds)=>{
        if(typeof needIds !== 'undefined' && needIds !== ''){
            return (<div className="modal" id={'needs'+needIds}>
                <div className="modal-dialog">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Needs Attention Note <b>{needIds}</b></h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <textarea className="form-control" name={ needIds } value={this.state[needIds]} onChange={this.getValue}></textarea>
                    </div>
                    {needData[27] ?
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.props.removeNeedsNote(needIds)}> Remove Need Note</button>
                            <button type="button" className="btn btn-primary"  data-dismiss="modal" onClick={()=>this.props.saveNeedsNote(needIds,this.state[needIds])} style={{textAlign:'left'}}>Save</button>
                            <button type="button" className="btn btn-danger"  data-dismiss="modal">Close</button>
                        </div>
                    :
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>this.props.saveNeedsNote(needIds,this.state[needIds])} style={{textAlign:'left'}}>Save</button>
                            <button type="button" className="btn btn-danger" onClick={()=>this.props.saveNeedsNote(needIds,'')}  data-dismiss="modal">Close</button>
                        </div>
                    }
                </div>
                </div>
            </div>)
        }
    }
	
	showFile=(id)=>{
        $('#fileDiv'+id).css('display','block');
    }
    
	feedsModal=(feedData, feedIds)=>{
		let res = this.props.feedsResponse.get(feedIds);
		if(typeof feedIds !== 'undefined' && feedIds !== ''){
            return (<div className="modal" id={'feeds'+feedIds}>
                <div className="modal-dialog">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Feedback Note <b>{feedIds}</b></h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
					{typeof res != "undefined" ?
                        <textarea className="form-control" name={ feedIds } value={this.state[feedIds]} onChange={this.getValue}></textarea>
					:
						<textarea className="form-control" name={ feedIds } onChange={this.getValue}></textarea>
					}
					</div>
                    {typeof res != "undefined" ?
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.props.removeFeedsNote(feedIds)}> Remove Feedback</button>
                            <button type="button" className="btn btn-primary"  data-dismiss="modal" onClick={()=>this.props.saveFeedsNote(feedIds,this.state.name)} style={{textAlign:'left'}}>Save</button>
                            <button type="button" className="btn btn-danger"  data-dismiss="modal">Close</button>
                        </div>
                    :
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>this.props.saveFeedsNote(feedIds,this.state.name)} style={{textAlign:'left'}}>Save</button>
                            <button type="button" className="btn btn-danger"  data-dismiss="modal">Close</button>
                        </div>
                    }
                </div>
                </div>
            </div>)
        }
    }
	
    getData(comment){
        var questions ='';
        var answerType = '';
        var options = '';
        var id = comment[1]
        var location = comment[9];
        var parent = comment[2]
        questions = comment[4];
        var visibility1 = comment[8];
        var visibility = comment[7];
        var action  = comment[17];
        if(this.props.selectedOpt== 'improved' && action =='changeQformat'){
            questions =comment[23] ? comment[23] :comment[4];
            answerType = comment[24] ? comment[24] :comment[5];
            options = comment[22] ? comment[22] :comment[6];
        }else{
            answerType = comment[5];
            options = comment[6];
        }
        var field_id = comment[0];
        var label = comment[41];
        let rowAllData = [id,location,parent,questions,answerType,options,visibility1,visibility,field_id,label];
        return rowAllData;   
    }
    
	getQueType(type,id){
        switch(type){
            case 'sig':
                return <i className="fas fa-edit" onClick={()=>this.props.showSign(type,id)}></i>
            break;
            case 'q10':
                return <input type="checkbox" onClick={()=>this.props.showSign(type,id)}/>
            break;
            default:
                return null
        }
    }
    
	
	launchpadApi=(fieldId,fieldGuid,url,type)=>{
		
		let level = $( "#level-"+fieldId+" option:selected" ).text();
		let side = $( "#side-"+fieldId+" option:selected" ).text();
		//console.log('level->',level+'-'+side);
		
		let user = 'Sidecar-1';
		
		if(type == 'LP2'){
			user = 'Sidecar-2';
		}else if(type == 'LP3'){
			user = 'Sidecar-3';
		}
		
		
		let formData = new FormData();
        formData.append('guid', fieldGuid);
        formData.append('name', 'Fred Flintstone');
        formData.append('level', level);
        formData.append('side', side);
        formData.append('user', user);
		let that = this;
		
		
		let apiUrl = url+'/save-launching-pad-api';
        axios({
            method: 'POST',
            url: apiUrl,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
			
			//console.log("response->",response.data.message);
			let msg = response.data.message;
			if(msg == 'Success'){
				setInterval(function() {
					let existGuid = that.state.existGuid;
					if(existGuid != fieldGuid){
						that.launchpadGetPdf(fieldGuid,url);
					}
				}, 5000);
				let loader = '<div class="loader2" style="display: block;"></div>';
				$('#'+fieldGuid).html(loader);
            }
        })
        .catch(error => {
            console.log('error::', error);
        })
	}
	
	launchpadGetPdf=(fieldGuid,url)=>{
		let apiUrl = url+'/get-item?fieldGuid='+fieldGuid;
		axios({
            method: 'GET',
            url: apiUrl,
        })
        .then(response => {
			
			//console.log("response->",response);
			let pdf = response.data.pdfurl;
			console.log("pdf->",pdf);
			if(pdf){
				let html = '<div class="view-launch-pdf"><a class="launch-pdf" href='+pdf+' target="_blank"><span>View Report</span> <i class="fas fa-file-pdf" aria-hidden="true"></i></a></div>';
				$('#'+fieldGuid).html(html);
				this.setState({existGuid:fieldGuid});
			}
            
        })
        .catch(error => {
            console.log('error::', error);
        }) 	
	}
	
	render() {
        const { kioskResponse, getRsponseValue, getDirectoryFiles, sheetData,queId,comment,moduleName,myFunction,allData,handleNeeds,handleApprove,selectedOpt,checkedInc,checkedReq,handleInclude,handleRequire,selDropOpt,selectedSheet,handleKiosk,preview,setKiosk,setDefaultCheckboxes,checkedKiosk,selectKioskUsers,kioskUsers,saveKioskUsersType,showAddUsersModal, username, handleInsertField,showSign,handleFeeds,kioskUserName,assoDeviceTypeName,assoVisitTypeName,apiPdfs, current_user} = this.props;
		return (
          <div id={queId} className={queId ? 'inner-'+ comment[0] +' childMain col-11 offset-1 '+queId+' collapse' : 'comments'} >
            {sheetData.map((comment,i) =>
              <div id={comment[0]} data-pos={comment[10]} key={comment[0]} data-header={this.headerTypes(comment)} className={this.classTypes(comment)}>
                {(() => {
                    let rowAllData=this.getData(comment,selectedOpt);
                    let answer = '';
                    let question = '';
                    if(selectedOpt == 'improved'){
                        let action = comment[17];
                        if(action === 'changeQformat'){
                            question = comment[23] ? comment[23] : comment[4];
                            answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
                        }else{
                            question = comment[4];
                            answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
                        }
                    }else{
                        question = comment[4];
                        answer = generatehtmlfromlocation(rowAllData,this.subQuestions,this.props);
                    }
					
					if(this.props.outputText && this.props.moduleName == 'test_forms'){
						question = comment[9] ? comment[9] : comment[4];
					}
					
                    let kioskResponse = this.props.kioskResponse;
					let res = kioskResponse.get(comment[0]);
                    switch (comment[5].toLowerCase()) {
                        case 'parent header':
                            return <h3 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'headers-'+comment[0]}> <i className="fa fa-plus-square-o fa-1x mr-2"></i>{ question } {this.showStar(comment)}</h3>;
                        case 'child header 1':
                            return <div className="child-headers">
                                        <div className="row">
                                            {moduleName == 'review_approve' || moduleName == 'test_forms'? null :
                                                <div className="col-md-1 check-box">
                                                {moduleName == 'form_builder' ?
                                                     <div className="input_inc">
                                                        <input type="checkbox" name ={comment[0]} className="inc" checked={ this.props.checkedInc.get(comment[0])} onChange={this.props.handleInclude}/>
                                                        <input type="checkbox" className="kiosk kiosk_check" checked={ this.props.checkedKiosk.get(comment[0])} name ={comment[0]}  onChange={this.props.handleKiosk}/>
                                                        <input type="checkbox" className="insert_field" name ={comment[0]} onChange={this.props.handleInsertField}/>
                                                    </div>
                                                    :null}
												{moduleName == 'custom_config' ?
                                                     <div className="input_inc">
                                                        <input type="checkbox" name ={comment[0]} className="inc" checked={ this.props.checkedInc.get(comment[0])} onChange={this.props.handleInclude}/>
                                                        <input type="checkbox" className="kiosk kiosk_check" checked={ this.props.checkedKiosk.get(comment[0])} name ={comment[0]}  onChange={this.props.handleKiosk}/>
														<input type="checkbox" className="move-input selCheck" value={comment[0]} onChange={() => this.props.checkMove(comment[0])} />
                                                    </div>
                                                    :null}
                                                {moduleName == 'format_forms' ?
                                                    <div className="input_inc">
                                                        <input type="checkbox" className="move-input selCheck" value={comment[0]} onChange={() => this.props.checkMove(comment[0])} />
                                                        <input type="checkbox" className="kiosk" checked={ this.props.checkedKiosk.get(comment[0])} name ={comment[0]}  onChange={this.props.handleKiosk}/> 
                                                    </div>
                                                    :null}
                                                
                                                </div>
                                            }
                                            {moduleName == 'review_approve' ?
                                            <div className="col-md-9">
                                                <h4 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>
                                                    {question}{this.showStar(comment)}
                                                </h4>
                                            </div>:
                                            <div className={moduleName == 'test_forms' ? 'col-md-9' : 'col-md-8'}>
                                                <h4 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>
                                                    {question}{this.showStar(comment)}
                                                </h4>
                                            </div>}
                                            <div className="col-md-3">
                                                { this.renderButtons(comment) }
                                            </div> 
                                        </div>
                                    </div>
                        case 'child header 2':
                            return <div className="child-headers">
                                    <div className="row">
                                            {moduleName == 'review_approve' || moduleName == 'test_forms' ? null :
                                                <div className="col-md-1 check-box">
                                                {moduleName == 'form_builder' ?
                                                    <div className="input_inc">
                                                        <input type="checkbox" name ={comment[0]} className="inc" checked={ this.props.checkedInc.get(comment[0])} onChange={this.props.handleInclude}/>
                                                        <input type="checkbox" className="kiosk kiosk_check" checked={ this.props.checkedKiosk.get(comment[0])} name ={comment[0]}  onChange={this.props.handleKiosk}/>
                                                        <input type="checkbox" className="insert_field" name ={comment[0]} onChange={this.props.handleInsertField}/>
                                                </div>:null}
												{moduleName == 'custom_config' ?
                                                     <div className="input_inc">
                                                        <input type="checkbox" name ={comment[0]} className="inc" checked={ this.props.checkedInc.get(comment[0])} onChange={this.props.handleInclude}/>
                                                        <input type="checkbox" className="kiosk kiosk_check" checked={ this.props.checkedKiosk.get(comment[0])} name ={comment[0]}  onChange={this.props.handleKiosk}/>
														<input type="checkbox" className="move-input selCheck" value={comment[0]} onChange={() => this.props.checkMove(comment[0])} />
                                                    </div>
                                                    :null}
                                                {moduleName == 'format_forms' ?
                                                    <div className="input_inc">
                                                        <input type="checkbox" className="move-input selCheck" value={comment[0]} onChange={() => this.props.checkMove(comment[0])} />
                                                        <input type="checkbox" className="kiosk" checked={ this.props.checkedKiosk.get(comment[0])} name ={comment[0]}  onChange={this.props.handleKiosk}/> 
                                                    </div>
                                                    :null}
                                                
                                                </div>
                                            }
                                            {moduleName == 'review_approve' ?
                                            <div className="col-md-9">
                                                <h5 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question}{this.showStar(comment)}</h5>
                                            </div>:
                                            <div className={moduleName == 'test_forms' ? 'col-md-9' : 'col-md-8'}>
                                                <h5 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question}{this.showStar(comment)}</h5>
                                            </div>}
                                            <div className="col-md-3">
                                                { this.renderButtons(comment) }
                                            </div> 
                                    </div>  
                            </div>;
                        case 'child header 3':
                            return <div className="child-headers">
                             <div className="row">
                                    {moduleName == 'review_approve' || moduleName == 'test_forms' ? null :
                                        <div className="col-md-1 check-box">
                                        {moduleName == 'form_builder' ?
                                            <div className="input_inc">
                                                <input type="checkbox" name ={comment[0]} className="inc" checked={ this.props.checkedInc.get(comment[0])} onChange={this.props.handleInclude}/>
                                                <input type="checkbox" className="kiosk kiosk_check" checked={ this.props.checkedKiosk.get(comment[0])} name ={comment[0]}  onChange={this.props.handleKiosk}/>
                                                <input type="checkbox" className="insert_field" name ={comment[0]} onChange={this.props.handleInsertField}/>
                                        </div>:null}
										{moduleName == 'custom_config' ?
                                                     <div className="input_inc">
                                                        <input type="checkbox" name ={comment[0]} className="inc" checked={ this.props.checkedInc.get(comment[0])} onChange={this.props.handleInclude}/>
                                                        <input type="checkbox" className="kiosk kiosk_check" checked={ this.props.checkedKiosk.get(comment[0])} name ={comment[0]}  onChange={this.props.handleKiosk}/>
														<input type="checkbox" className="move-input selCheck" value={comment[0]} onChange={() => this.props.checkMove(comment[0])} />
                                                    </div>
                                                    :null}
                                        {moduleName == 'format_forms' ?
                                            <div className="input_inc">
                                                <input type="checkbox" className="move-input selCheck" value={comment[0]} onChange={() => this.props.checkMove(comment[0])} />
                                                <input type="checkbox" className="kiosk" checked={ this.props.checkedKiosk.get(comment[0])} name ={comment[0]}  onChange={this.props.handleKiosk}/> 
                                            </div>
                                            :null}
                                        </div>
                                    }
                                    {moduleName == 'review_approve' ?
                                    <div className="col-md-9">
                                        <h6 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question}{this.showStar(comment)}</h6>
                                    </div>:
                                    <div className={moduleName == 'test_forms' ? 'col-md-9' : 'col-md-8'}>
                                        <h6 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question}{this.showStar(comment)}</h6>
                                    </div>}
                                    <div className="col-md-3">
                                        { this.renderButtons(comment) }
                                    </div> 
                                </div>
                            </div>;
                        case 'text' :
                                return <div>
                                            <div className="row">
                                            
                                                <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                                    {this.renderActions(comment)}
                                                </div>
                                                <div className={moduleName == 'test_forms' ? 'col-md-7' : 'col-md-6'}>
                                                    <div className="row">
                                                    { answer == '' ?
                                                        <div className="col-md-12"><b>{question}</b> {this.showStar(comment)} { comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                                        : <div className="col-md-12">{question} {this.showStar(comment)}{ comment[15] == 'Y' || checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>
                                                    }
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    { this.renderButtons(comment) }
                                                </div> 
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row"> 
                                                        <div className={moduleName == 'test_forms' ? "col-md-12  answer" : "col-9 col-md-9 offset-md-3  answer"} >
                                                            { answer }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                        case 'file' :
                            let type= '';
                            let setUrl = '';
                            let getUrl ='';
							
							if((moduleName == 'test_forms' && res) || moduleName != 'test_forms'){
								if(typeof comment[99] != "undefined" && comment[99] != ''){
									getUrl = comment[99].split(',');
									setUrl = getUrl[0];
									type = getUrl[0].split('.').pop();
								}
							}
                            
                            return <div>
                                <div className="row">
                                    <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                        {this.renderActions(comment)}
                                    </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-6'} >
										{ question }
                                    </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                        { this.renderButtons(comment) }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                            <div className="row"> 
                                                <div className={moduleName == 'test_forms' ? "col-md-10 offset-2" : "col-md-9 offset-md-3"} >
													{ setUrl ?
                                                    <div>
													{ type.toLowerCase() === 'jpeg' || type === 'jpg' ||  type === 'png' || type === 'gif' ?
                                                            <div id={'fileHtmlDiv'+comment[0]}><img src={""+setUrl+""} width="259px" height="200px"/></div>
                                                        :
                                                     type == 'stl' ?
                                                            <div id={'fileHtmlDiv'+comment[0]}>
                                                                <iframe width="100%" height="100%" src={"https://www.viewstl.com/?embedded&url="+setUrl+"&edges=yes&noborder=yes"}></iframe>
                                                            </div>
                                                        :
                                                        <div id={'fileHtmlDiv'+comment[0]}><iframe width="100%" height="100%" src={setUrl}></iframe></div>
                                                    }

                                                    
                                                    <div id={'fileAns'+comment[0]}>
                                                        <b>Name: </b>{getUrl[1]}<br/>
                                                        <b>Description: </b>{getUrl[2]}<br/>
                                                        <b>Date: </b>{getUrl[3]}
                                                    </div>
													</div>
													: <div>
                                                        <div id={'fileHtmlDiv'+comment[0]}></div>
                                                        <div id={'fileAns'+comment[0]}></div>
                                                    </div>
                                                }
                                                </div> 
                                            </div>
                                        </div>
                                </div>
                                <div className="row">
                                    <div className={moduleName == 'test_forms' ? 'col-md-10 offset-2' : 'col-md-9 offset-3'}>
                                        <FileUploadType dataField={setUrl} fieldId={comment[0]} uploadFilePath={this.uploadFilePath} deleteFile={this.deleteFile}/>
                                    </div>
                                </div>
							</div> 
                        case 'video' :
							let data = '';
							if((moduleName == 'test_forms' && res) || moduleName != 'test_forms'){
								data = comment[99];
							}
						
                            return <div>
                                <div className="row">
                                    <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                        {this.renderActions(comment)}
                                    </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-7' : 'col-md-6'}>
                                        { question }
                                    </div>
                                    <div className="col-md-3">
                                        { this.renderButtons(comment) }
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-md-12">
                                            <div className="row"> 
                                                <div className={moduleName == 'test_forms' ? "col-md-12 answer offset-2" : "col-md-9 offset-md-3 offset-sm-0  answer"} >
													<div id={'ans'+comment[0]}>
														{ ReactHtmlParser(data) }
													</div>
													<div>
													<BasicTextFields dataField={data} fieldId={comment[0]} uploadVideoPath={this.uploadVideoPath} deleteVideo={this.deleteVideo}/>
													</div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        case 'image' :
                            return <div>
                                <div className="row">
                                    <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                        {this.renderActions(comment)}
                                    </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-7' : 'col-md-6'}>
                                        { comment[99] == '' ?
                                            <h5><b> <img className="in-image" src={comment[99]} /> {this.showStar(comment)} { comment[15] == 'Y'|| checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</b></h5>
                                        : <div className="col-md-12"><img className="in-image" src={comment[99]} /> {this.showStar(comment)}{ comment[15] == 'Y'|| checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>}
                                            
                                    </div>
                                    <div className="col-md-3">
                                        { this.renderButtons(comment) }
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-md-12">
                                            <div className="row"> 
                                                <div className={moduleName == 'test_forms' ? "col-md-12 answer offset-2" : "col-md-9 offset-md-3 offset-sm-0  answer"} >
                                                    { question }
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                            </div>
						case 'inlinevideo' :
                            return <div>
                                <div className="row">
                                    <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                        {this.renderActions(comment)}
                                    </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-7' : 'col-md-6'}>
										{ ReactHtmlParser(comment[99]) }  {this.showStar(comment)}
                                    </div>
                                    <div className="col-md-3">
                                        { this.renderButtons(comment) }
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-md-12">
                                            <div className="row"> 
                                                <div className={moduleName == 'test_forms' ? "col-md-12 answer offset-2" : "col-md-9 offset-md-3 offset-sm-0  answer"} >
                                                    { question }
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        case 'title' :
                            return <div>
                                <div className="row">
										<div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-7' : 'col-md-6'}>
                                        { answer == '' ?
                                            <h5><b> { question } {this.showStar(comment)} { comment[15] == 'Y'|| checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</b></h5>
                                        : <div className="col-md-12">{question} {this.showStar(comment)}{ comment[15] == 'Y'|| checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>}
                                            
                                    </div>
                                    <div className="col-md-3">
                                        { this.renderButtons(comment) }
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-md-12">
                                            <div className="row"> 
                                                <div className={moduleName == 'test_forms' ? "col-md-12 answer" : "col-md-9 offset-md-3 offset-sm-0  answer"} >
                                                    { answer }
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            case 'q5' :
                            return <div>
                                <div className="row">
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-8 answer' : 'col-md-6'}>
                                        { answer == '' ?
                                            <h5><b> { answer } { question } {this.showStar(comment)} { comment[15] == 'Y'|| checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</b></h5>
                                        : <div className="col-md-12">{ answer } {question} {this.showStar(comment)}{ comment[15] == 'Y'|| checkedReq.get(comment[0]) == true ? <span className="required">*</span> : ''}</div>}
                                            
                                    </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                        { this.renderButtons(comment) }
                                    </div>
                                </div>
                                {/*<div className="row">
                                    <div className="col-md-12">
                                        <div className="row"> 
                                            <div className={moduleName == 'test_forms' ? "col-md-12 answer" : "col-md-9 offset-md-3 offset-sm-0  answer"} >
                                                { answer }
                                            </div> 
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
							case 'calculation':
							case 'wcalculation':
							return (
                                <div className="full-width">
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-5'}>
                                            <div className="row">
                                                <div className="col-md-5 cal-res-box">
													<div className={"cal-res cal-res-" +comment[0] }>{res ? res : ''}</div>
													<div className="user-text">
													{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{" "+question } {this.showStar(comment)}{ comment[15] == 'Y' || checkedReq.get(comment[0]) == true? <span className="required">*</span> : ''}
													</div>
												</div>
                                            
                                            </div>
                                        </div>
										<div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-4'}>
                                            { this.renderButtons(comment) }
                                        </div>  
                                    </div>
                                     
                                </div>)
							case 'subjective':
							return (
                                <div className="full-width">
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-5'}>
                                            <div className="row">
												<div className="col-md-12"><h5>Subjective</h5></div>
                                                <div className="col-md-12">{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{" "+question } {this.showStar(comment)}{ comment[15] == 'Y' || checkedReq.get(comment[0]) == true? <span className="required">*</span> : ''}</div>
                                            
                                            </div>
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-4'}>
                                            { this.renderButtons(comment) }
                                        </div>  
                                    </div>
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? "col-md-8 answer offset-2" : "col-9  offset-3 answer"} >
                                            { answer }
                                        </div>   
                                    </div> 
                                </div>)
							case 'assessment':
							return (
                                <div className="full-width">
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-5'}>
                                            <div className="row">
												<div className="col-md-12"><h5>Assessment</h5></div>
                                                <div className="col-md-12">{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{" "+question } {this.showStar(comment)}{ comment[15] == 'Y' || checkedReq.get(comment[0]) == true? <span className="required">*</span> : ''}</div>
                                            
                                            </div>
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-4'}>
                                            { this.renderButtons(comment) }
                                        </div>  
                                    </div>
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? "col-md-8 answer offset-2" : "col-9  offset-3 answer"} >
                                            { answer }
                                        </div>   
                                    </div> 
                                </div>)	
							case 'pdfapi':
							
                            let setpdfUrl = '';
                            let getpdfUrl ='';
                            if((typeof comment[99] != "undefined" && comment[99] != '') && ((moduleName == 'test_forms' && res) || moduleName != 'test_forms')){
                                getpdfUrl = comment[99].split(',');
                                setpdfUrl = getpdfUrl[0];
								$(".apiPdf-list-"+comment[0]).hide();
								$('.api-pdf-'+comment[0]).show();
                            }else{
								$("apiPdf-list-"+comment[0]).show();
								$('.api-pdf-'+comment[0]).hide();
							}
							
							let pdfs = this.props.apiPdfs;
							const TableApiPdfs = () =>{
								if(pdfs.length > 0){
									var optionsHtml = pdfs.map(function(val,i) {
										return (
											<tr><td width="80px"><input type='radio' name="apiP" className={comment[0]+"_apiP"} value={val.id} /><i class="fas fa-file-pdf" aria-hidden="true"></i></td><td>{val.name}</td><td>{val.description}</td></tr>
										);
										
									})
								}
								return <table className="table table-bordered">
										{optionsHtml}
									</table>
								
							}
							
                            return <div>
                                <div className="row">
                                    <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                        {this.renderActions(comment)}
                                    </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-6'} >
										{ question }
                                    </div>
                                    <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                        { this.renderButtons(comment) }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                            <div className="row"> 
                                                <div className={moduleName == 'test_forms' ? "col-md-10 offset-2" : "col-md-9 offset-md-3"} >
													{ setpdfUrl ?
                                                    <div>
													<div id={'fileHtmlDiv'+comment[0]}><embed width="100%" height="100%" src={setpdfUrl} /></div>

                                                    
                                                    <div id={'fileAns'+comment[0]}>
                                                        <b>Name: </b>{getpdfUrl[1]}<br/>
                                                        <b>Description: </b>{getpdfUrl[2]}<br/>
                                                        <b>Date: </b>{getpdfUrl[3]}
                                                    </div>
													</div>
													: <div>
                                                        <div id={'fileHtmlDiv'+comment[0]}></div>
                                                        <div id={'fileAns'+comment[0]}></div>
                                                    </div>
													}
													
													<div className={"apiPdf-list apiPdf-list-"+comment[0]}>
													<div className="apiPdf-table">
													<TableApiPdfs/>
													</div>
													<button type="button" className="btn btn-primary" onClick={() =>this.uploadPdfApi(comment[0])}>Load file into form from list above</button>
													</div>
                                                </div> 
                                            </div>
                                        </div>
                                </div>
                                <div className="row">
                                    <div className={moduleName == 'test_forms' ? 'col-md-10 offset-2' : 'col-md-9 offset-3'}>
                                        <div className={"api-pdf-"+comment[0]}><button type="button" className="btn btn-primary " onClick={() =>this.replacePdfApi(comment[0])}>Replace with new file from list</button>
										&nbsp;<a id={"download-"+comment[0]} href={setpdfUrl} className="file-link btn code-dialog" target="_blank"><i className="fa fa-cloud-download" aria-hidden="true"></i></a>
										<button type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() =>this.deleteFile(comment[0])}><i className="fa fa-trash"></i></button>
										&nbsp;
										<button type="button" className="btn btn-primary " onClick={() =>this.replacePdfLocal(comment[0])}>Upload and Replace</button>
										<PdfFileUpload dataName={getpdfUrl[1]} dataDes={getpdfUrl[2]} dataField={setpdfUrl} fieldId={comment[0]} uploadFilePath={this.uploadFilePath}/>
										</div>										
                                    </div>
                                </div>
							</div>
							case 'link':
                            return (
                                <div className="full-width default-div">
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-5'}>
                                            <div className="row">
                                                <div className="col-md-12"><a href={comment[78]} target="_blank">{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{" "+question } {this.showStar(comment)}  <i className="fas fa-external-link-alt" aria-hidden="true"></i></a></div>
                                            
                                            </div>
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-4'}>
                                            { this.renderButtons(comment) }
                                        </div>  
                                    </div>
                                    
                                </div>)
							case 'boxbegin':
                            return (
                                <div className="full-width">
                                </div>)
							case 'boxend':
                            return (
                                <div className="full-width">
                                </div>)
							case 'grid':
                            return (
                                <div className="full-width default-div">
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-5'}>
                                            <div className="row">
                                                <div className="col-md-12">{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{" "+question } {this.showStar(comment)}{ comment[15] == 'Y' || checkedReq.get(comment[0]) == true? <span className="required">*</span> : ''}</div>
                                            
                                            </div>
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-4'}>
                                            { this.renderButtons(comment) }
                                        </div>  
                                    </div>
                                    <div className="row grid-type">
                                        <div className={moduleName == 'test_forms' ? "col-md-10 answer offset-2" : "col-9  offset-3 answer"} >
                                            { answer }
                                        </div>   
                                    </div> 
                                </div>)
							case 'gridcheck':
                            return (
                                <div className="full-width default-div">
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-5'}>
                                            <div className="row">
                                                <div className="col-md-12">{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{" "+question } {this.showStar(comment)}{ comment[15] == 'Y' || checkedReq.get(comment[0]) == true? <span className="required">*</span> : ''}</div>
                                            
                                            </div>
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-4'}>
                                            { this.renderButtons(comment) }
                                        </div>  
                                    </div>
                                    <div className="row grid-type">
                                        <div className={moduleName == 'test_forms' ? "col-md-10 answer offset-2" : "col-9  offset-3 answer"} >
                                            { answer }
                                        </div>   
                                    </div> 
                                </div>)
							case 'launchpad':
							//console.log('comment->',comment);
                            return (
                                <div className="full-width default-div">
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-5'}>
                                            <div className="row">
                                                <div className="col-md-12">{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{" "+question } {this.showStar(comment)}{ comment[15] == 'Y' || checkedReq.get(comment[0]) == true? <span className="required">*</span> : ''}</div>
                                            
                                            </div>
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-4'}>
                                            { this.renderButtons(comment) }
                                        </div>  
                                    </div>
                                    <div className="row grid-type">
                                        <div className={moduleName == 'test_forms' ? "col-md-10 answer offset-2" : "col-9  offset-3 answer"} >
											
											<div id={comment[117]} className="launchpad-section">
												<div className="select-tags-box">
													<label>Amputation Level</label>
													<select id={'level-'+comment[0]} className="form-control">
														<option value=""> Please Select</option> 
														<option value="Transtibial">Transtibial</option>
														<option value="Transfemoral">Transfemoral</option>
													</select>
												</div>
												<div className="select-tags-box">
													<label>Amputation Side</label>
													<select id={'side-'+comment[0]} className="form-control">
														<option value=""> Please Select</option> 
														<option value="Left">Left</option> 
														<option value="Right"> Right</option> 
														<option value="Bilateral"> Bilateral</option> 
													</select>
												</div>
												<div className="btn-launch">
													<div><button id={'post-'+comment[117]} type="button" className="btn btn-primary" onClick={() =>this.launchpadApi(comment[0],comment[117],comment[78],comment[138])}>{comment[40]}</button>
													</div>
												</div>
											</div>
											
                                        </div>   
                                    </div> 
                                </div>)
							default:
                            return (
                                <div className="full-width default-div">
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-3'}>
                                            {this.renderActions(comment)}
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-8' : 'col-md-5'}>
                                            <div className="row">
                                                <div className="col-md-12">{this.getQueType(rowAllData[4].toLowerCase(),rowAllData[8])}{" "+question } {this.showStar(comment)}{ comment[15] == 'Y' || checkedReq.get(comment[0]) == true? <span className="required">*</span> : ''}</div>
                                            
                                            </div>
                                        </div>
                                        <div className={moduleName == 'test_forms' ? 'col-md-2' : 'col-md-4'}>
                                            { this.renderButtons(comment) }
                                        </div>  
                                    </div>
                                    <div className="row">
                                        <div className={moduleName == 'test_forms' ? "col-md-9 answer offset-2" : "col-9  offset-3 answer"} >
                                            { answer }
                                        </div>   
                                    </div> 
                                </div>)                         
                    }
                })()}
                {comment.sub 
                    && 
                    <MainData 
                        sheetData={comment.sub} 
                        queId={comment[1].toLowerCase()} 
                        myFunction={myFunction} 
                        moduleName={moduleName} 
                        comment= {comment}
                        checkMove = {this.props.checkMove}
                        movePostion = {this.props.movePostion}
                        allData ={allData}
                        showSign = {showSign}
                        getDirectoryFiles={getDirectoryFiles}
                        username = {username}
                        saveNeedsNote ={this.props.saveNeedsNote}
                        saveFeedsNote ={this.props.saveFeedsNote}
                        removeNeedsNote = {this.props.removeNeedsNote}
                        removeFeedsNote = {this.props.removeFeedsNote}
                        feedsResponse = {this.props.feedsResponse}
                        apiPdfs = {this.props.apiPdfs}
                        current_user = {this.props.current_user}
                        handleApprove={handleApprove}
                        handleNeeds={handleNeeds}
                        handleFeeds={handleFeeds}
                        selectedOpt = {selectedOpt}
                        setDefaultCheckboxes = {setDefaultCheckboxes}
                        preview = {preview}
                        kioskResponse={kioskResponse}
                        kioskUserName={this.props.kioskUserName}
                        assoDeviceTypeName={this.props.assoDeviceTypeName}
                        assoVisitTypeName={this.props.assoVisitTypeName}
                        setKiosk = {setKiosk}
                        handleInsertField={handleInsertField}
                        getRsponseValue ={getRsponseValue}
                        selectKioskUsers={selectKioskUsers}
                        saveKioskUsersType = {saveKioskUsersType}
                        kioskUsers = {kioskUsers}
                        selDropOpt ={selDropOpt}
                        checkedKiosk = {checkedKiosk}
                        selectedSheet = {selectedSheet}
                        checkedNeeds={this.props.checkedNeeds}
                        checkedApprove={this.props.checkedApprove}
                        checkedInc= {this.props.checkedInc}
                        checkedReq = {this.props.checkedReq}
                        handleInclude = {handleInclude}
                        handleRequire = {handleRequire}
                        handleKiosk = {handleKiosk}
						sheetConfigId={this.props.sheetConfigId}
						outputText={this.props.outputText}
                        showAddUsersModal ={showAddUsersModal}/>
                }
              </div>
            )}
          </div>
        )
    }
}